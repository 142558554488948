@import "../../../assets/css/mixin";

@import "../../../assets/css/reset";

@import "../../../assets/css/variables";

/******************************************************/

.footerSection {
    height: 60px;
    padding: 0 15px;
    background-color: #333;
    @include flex();
    align-items: center;
    justify-content: center;
    ul{
        margin: 0;
        padding: 0;
        display: inline-flex;
        li{
            margin-right: 10px;
            list-style: none;
            a{
                padding:10px 10px;
                color: $white;
                cursor: pointer;
                text-decoration: none;
                &:not([href]):not([tabindex]){
                    color: $white;
                }
            }
        }
    }
}