@import "../../../../../../../../assets/css/variables";
@import "../../../../../../../../assets/css/mixin";
@import "../../../../../../../../assets/css/reset";

.plan-checkout-sec {
    background: #F6F6F6;
    padding: 60px 0;
    margin-top: 40px;
    .plan-checkout-row {
        margin-bottom: 35px;
        .title {
            @include font(24);
            font-weight: 400;
            color:$blackColor;
            text-transform: capitalize;
            margin-bottom: 20px;
        }
        .plan-checkout-card {
            background: $white;
            padding: 30px;
            border-radius: 6px;
            position: relative;
            &.plan-game-card {
                padding-right: 260px;
            } 
            .title {
                @include font(24);
                font-weight: 400;
                color:$black;
                text-transform: capitalize;
                margin-bottom: 20px;
            }
            .planInfo-flexbox {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
            }
            .checklist {
                @include flex();
                align-items: flex-start;
                justify-content: space-between;
                flex-wrap: wrap;
                list-style: none;
                padding: 0 20px 0 0;
                margin: 0;
                li {
                    width: calc(50% - 30px);
                    position: relative;
                    padding-left: 32px;
                    margin-bottom: 18px;
                    @include font(18);
                    font-weight: 400;
                    color:$black;
                    min-height: 18px;
                    white-space: break-spaces;
                    &::after {
                        content:'';
                        background: url(../img/check-icon.svg) no-repeat;
                        background-position: center center;
                        background-size: 18px 18px;
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        left: 0;
                        top: 2px;
                        z-index: 1;
                    }
                }
            }
            .plan-typeInfo {
                position: absolute;
                width: 200px;
                right: 30px;
                top:0;
                z-index:1;
                .plan-monthly-type {
                    @include flex();
                    align-items: center;
                    background: $green;
                    width:200px;
                    padding:15px;
                    @include font(16);
                    font-weight: 400;
                    color: $white;
                    line-height:18px;
                    border-radius: 0px 0px 6px 6px;
                    margin-bottom: 15px;
                    .plan-title {
                        @include font(50);
                        font-weight: 600;
                        margin-right:10px;
                    }
                }
                .plan-info {
                    text-align: right;
                    .price {
                        @include font(30);
                        font-weight:500;
                        color:$baseColor;
                        margin-bottom:12px;
                    }
                    .plan-total-price {
                        @include font(18);
                        font-weight:500;
                        color:$black;
                        span {
                            display: inline;
                            font-weight:400;
                            color:rgba($blackColor, .5);
                        }
                    }
                }
            }
        }
    }
    form {
        .fieldBox {
            margin-bottom: 20px;
            &.flexBox {
                @include flex();
                align-items: flex-start;
                margin-left: -8px;
                margin-right: -8px;
                .column {
                    width: 25%;
                    padding-left: 8px;
                    padding-right: 8px;
                }
            }
            label {
                display: block;
                @include font(15);
                font-weight: 400;
                color: $black;
                line-height: 1.4;
                margin-bottom: 10px;
                sup {
                  display: inline-block;
                  vertical-align: -6px;
                  font-weight: 600;
                  color: $baseColor;
                  margin-left: 3px;
                }
            }
            input {
                padding: 10px 10px;
                width: 100%;
                @include font(15);
                color: $black;
                border: 1px solid rgba($black, .2);
                outline: none;
                box-shadow: none;
                @include radius(4px);
                &::placeholder {
                    color: rgba($black, .3);
                }
            }
            select {
                padding: 13px 45px 13px 10px;
                background-image: url(../img/arrowdown.png);
                background-repeat: no-repeat;
                background-position: right 15px center;
                background-size: 16px;
                @include font(15);
                color: $black;
                width: 100%;
                border: 1px solid rgba($black, .2);
                -webkit-appearance: none;
                appearance: none;
                @include radius(4px);
            }
            textarea {
                padding: 10px 10px;
                width: 100%;
                min-height: 90px;
                @include font(15);
                color: $black;
                line-height: 1.5;
                border: 1px solid rgba($black, .2);
                outline: none;
                box-shadow: none;
                @include radius(4px);
                &::placeholder {
                    color: rgba($black, .3);
                }
            }
            .checkbox-list {
                @include flex();
                flex-wrap: wrap;
                align-items: center;
                li {
                    display: block;
                    position: relative;
                    @include font(15);
                    font-weight: normal;
                    color: rgba($black, .60%);
                    line-height: 22px;
                    padding-left: 30px;
                    a {
                        display: inline;
                        color: rgba($black, .60%);
                        text-decoration: underline;
                        cursor: pointer;
                        &:hover {
                            text-decoration: none;
                            color: $lightBlue;
                        }
                    }
                    .checkbox-sf {
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index:1;
                    }
                    input[type="checkbox"] {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: 1;
                        @include radius(4px);
                        cursor: pointer;
                        &:checked {
                            + label {
                                &::before {
                                    border-color: $lightBlue;
                                }
                                &::after {
                                    content: "";
                                    display: inline-block;
                                    background: url(../img/checkBlue.png) no-repeat;
                                    background-size: cover;
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    left: 3px;
                                    top: 7px;
                                }
                            }
                        }
                    }
                    label {
                        padding-left: 18px;
                        position: relative;
                        margin-bottom: 0;
                        cursor: pointer;
                        &::before {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            left: 0;
                            top: 2px;
                            border: 1px solid rgba($black, .2);
                            @include radius(4px);
                        }
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            .btn {
                padding: 10px 15px;
                min-width: 160px;
                @include font(18);
                font-weight: 400;
                @include radius(4px);
                margin-top: 10px;
                cursor: pointer;
                &.blue {
                    background:$lightBlue;
                }
            }
        }
    }
}
.checkout-testimonial {
    .sec-title {
        @include font(24);
        font-weight: 400;
        color:$blackColor;
        text-transform: capitalize;
        padding-bottom: 25px;
        padding-right: 100px;
    }
    .checkout-testimonial-list {
        position: relative;
        .item {
            .success-info-box {
                @include flex();
                align-items: flex-start;
                .pick-box {
                    width: 100px;
                    margin-right: 15px;
                    .pick-img {
                        display: block;
                        max-width: 100%;
                        height: 100px;
                        object-fit: cover;
                        box-shadow: 0px 0px 15px #00000029;
                        border: 2px solid $white;
                        @include radius(6px);
                    }
                    .icon-box {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        background: $white;
                        width: 45px;
                        height: 45px;
                        box-shadow: 0px 0px 15px #00000029;
                        border: 2px solid $white;
                        @include radius(160px);
                        margin: -22px auto 0 auto;
                        position: relative;
                        img {
                            display: block;
                            max-width: 100%;
                            width: auto;
                            height: 30px;
                            object-fit: contain;
                        }
                    }
                }
                .detail-info-box {
                    width: calc(100% - 115px);
                    @include font(15);
                    font-weight: 400;
                    color:$black;
                    .name {
                        @include font(16);
                        font-weight: 600;
                        margin-bottom: 8px;
                    }
                    p {
                        line-height: 26px;
                        margin-bottom:10px;
                        &.star-rating-icons {
                            svg {
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 2px;
                            }
                        }
                    }
                }
            }
        }
    }
    .owl-nav {
        display: block;
        margin: 0 auto;
        text-align: center;
        position: absolute;
        right: 0;
        top:-45px;
        z-index: 1;
        button {
            background: $white;
            width: 30px;
            height: 30px;
            outline: none;
            margin: 0 8px;
            @include radius(50px);
            box-shadow: 0px 0px 6px #CBCBCB;
            cursor: pointer!important;
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
            &.owl-next {
                span {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    right: 2px;
                    left: auto;
                }
            }
            span {
                content: "";
                display: inline-block;
                position: relative;
                width: 10px;
                height: 10px;
                font-size: 0;
                border-right: 2px solid #BBBBBB;
                border-bottom: 2px solid #BBBBBB;
                transform: rotate(-0deg);
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                left: 2px;
                top: -6px;
                @include transition();
            }
        }
    }
}
@media screen and (max-width:991px) {
    .plan-checkout-sec {
        .plan-checkout-row {
            .title {
                @include font(22);
            }
            .plan-checkout-card {
                padding:20px;
                &.plan-game-card {
                    padding-right: 230px;
                }
                .title {
                    @include font(20);
                }
                .checklist {
                    padding-right: 0;
                    li {
                        width: calc(50% - 10px);
                        padding-left: 28px;
                        @include font(15);
                        line-height:22px;
                        top: 0;
                        margin-bottom: 15px;
                    }
                }
                .plan-typeInfo {
                    width: 185px;
                    right: 20px;
                    .plan-monthly-type {
                        width: 185px;
                        @include font(15);
                        .plan-title {
                            @include font(40);
                        }
                    }
                    .plan-info {
                        .price {
                            @include font(30);
                        }
                        .plan-total-price {
                            @include font(16);
                        }
                    }
                }
            }
        }
        form {
            .fieldBox {
                margin-bottom:0;
                &.flexBox  {
                    flex-wrap: wrap;
                    .column {
                        width: 50%;
                        margin-bottom: 15px;
                    }
                }
                .btn {
                    @include font(16);
                }
            }
        }
    }
    .checkout-testimonial {
        .sec-title {
            @include font(18);
        }
    }
}
@media screen and (max-width:767px) {
    .plan-checkout-sec {
        padding: 40px 0;
        .plan-checkout-row {
            .plan-checkout-card {
                .checklist {
                    display: block;
                    li {
                        width: 100%;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

    }
}
@media screen and (max-width:639px) {
    .plan-checkout-sec {
        .plan-checkout-row {
            margin-bottom: 30px;
            .title {
                @include font(18);
                margin-bottom: 15px;
            }
            .plan-checkout-card{
                .title {
                    @include font(18);
                    margin-bottom: 15px;
                }
            }
        }
    }
    .checkout-testimonial {
        .sec-title {
            @include font(18);
        }
        .checkout-testimonial-list {
            position: relative;
            .item {
                .success-info-box {
                    .detail-info-box {
                        @include font(14);
                        .name {
                            @include font(15);
                        }
                        p {
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:567px) {
    .plan-checkout-sec {
        .plan-checkout-row {
            .plan-checkout-card {
                padding:20px 15px;
                &.plan-game-card {
                    padding-right: 15px;
                }
                .planInfo-flexbox {
                    display: block;
                }
                .checklist {
                    margin-bottom: 20px;
                    li {
                        margin-bottom: 10px;
                    }
                }
                .plan-typeInfo {
                    position: static;
                    width: 100%;
                    right: auto;
                    top: auto;
                    .plan-monthly-type {
                        width: 100%;
                        border-radius: 6px;
                        .plan-title {
                            @include font(35);
                        }
                    }
                    .plan-info {
                        .price {
                            @include font(24);
                        }
                        .plan-total-price {
                            @include font(15);
                        }
                    }
                }
            }
        }
        form {
            .fieldBox {
                margin-bottom:0;
                &.flexBox  {
                    display: block;
                    flex-wrap: wrap;
                    margin:0;
                    .column {
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
                .btn {
                    @include font(16);
                    margin-top:20px;
                }
            }
        }
    }
}