@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";
@import "../../../../../../../assets/css/variables";

.searchDataSection {
  background-color: $bgLightGray;
  padding: 15px 0;
  margin-top: 60px;
  .searchDataFlexBox {
    @include flex();
    flex-wrap: wrap;
    max-width: 1470px;
    margin: 0 auto;
    .searchSideMenu {
      width: 250px;
      padding: 15px 15px;
      background-color: $white;
      @include radius(4px);
      transition: all 0.5s ease-in-out;
      .closeBtnBox {
        display: none;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $gray;
        h5 {
          @include font(15);
          color: $blackColor;
          font-weight: 600;
        }
        button {
          background-color: transparent;
          padding: 5px;
          border: none;
          outline: none;
          cursor: pointer;
          box-shadow: none;
          svg {
            .a {
              fill: $baseColor;
            }
          }
        }
      }
      .searchBox {
        @include flex();
        border: 1px solid $gray;
        @include radius(4px);
        padding: 3px 5px;
        input {
          padding: 5px;
          @include font(14);
          color: $black;
          width: calc(100% - 30px);
          border: none;
          box-shadow: none;
          outline: none;
          &::placeholder {
            color: rgba(62, 62, 62, 0.5);
          }
        }
        .searchBtn {
          button {
            background-color: transparent;
            padding: 5px;
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            outline: none;
            border: none;
            box-shadow: none;
            cursor: pointer;
            svg {
              .a {
                opacity: 0.3;
              }
              .b {
                fill: none;
                stroke: $blackColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
              }
            }
          }
        }
      }
      .filterActionBox {
        padding: 10px 0;
        margin-top: 10px;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
        .flexBox {
          @include flex();
          button {
            border: none;
            outline: none;
            cursor: pointer;
            &.saveFilterBtn {
              background-color: $green;
              padding: 10px 15px;
              width: calc(100% - 70px);
              @include font(14);
              color: $white;
              @include radius(4px);
              cursor: pointer;
              margin-right: 10px;
            }
          }
          .filterBox {
            background-color: #eeeeee;
            @include flex();
            @include radius(4px);
            position: relative;
            button {
              background-color: transparent;
              width: 30px;
              padding: 5px;
              margin-right: 0;
              &.filterBtn {
                svg {
                  width: 20px;
                  .a {
                    fill: $black;
                  }
                }
              }
              svg {
                width: 12px;
                height: auto;
              }
            }
            .dropdown-menu {
              transform: none !important;
              right: 0 !important;
              left: auto !important;
              top: 35px !important;
              border: none;
              @include shadow(0, 3px, 11px, #00000029);
              ul {
                padding: 0 10px;
                li {
                  margin: 0;
                  &:last-child {
                    border-bottom: none;
                  }
                  h5 {
                    @include font(14);
                    font-weight: normal;
                    color: $black;
                    padding: 0 0 10px 0;
                  }
                  a {
                    display: inline-flex;
                    text-decoration: none;
                    width: 100%;
                    padding: 10px 0;
                    @include font(14);
                    color: $black;
                    font-weight: normal;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .lockedBox {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $gray;
        .heading {
          @include flex();
          justify-content: space-between;
          margin: 10px 0;
          @include font(14);
          color: $gray;
          font-weight: normal;
          span {
            &.icon {
              display: inline-block;
              svg {
                .a {
                  fill: $gray;
                  fill-rule: evenodd;
                }
              }
            }
          }
        }
        span {
          display: block;
          @include font(14);
          color: $gray;
          font-weight: normal;
        }
      }
      h1 {
        @include font(15);
        font-weight: 400;
        color: $blackColor;
        word-break: break-word;
        line-height: 22px;
        margin-bottom: 10px;
      }
      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          border-bottom: 1px solid $gray;
          .MuiOutlinedInput-input {
            padding-top: 12px;
            padding-bottom: 12px;
          }
          &:last-child {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          > button {
            width: 100%;
            @include flex();
            justify-content: space-between;
            background-color: transparent;
            padding: 10px;
            box-shadow: none;
            outline: none;
            cursor: pointer;
            &:active {
              background-color: transparent;
              outline: none;
            }
            &:focus {
              box-shadow: none !important;
              outline: none !important;
            }
            h5 {
              @include font(14);
              color: $black;
              font-weight: 500;
            }
            span {
              &.icon {
                display: inline-flex;
                padding-top: 3px;;
                svg {
                  width: 12px;
                  height: auto;
                  fill: $black;
                  @include rotate(180deg);
                  transition: transform 300ms;
                }
              }
            }
            &.collapsed {
              span {
                svg {
                  @include rotate(0deg);
                  transition: transform 300ms;
                }
              }
            }
          }
          .card {
            padding: 10px;
            border: none;
            @include radius(0);
            &.confrence-search {
              padding-top: 0!important;
            }
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                margin-bottom: 10px;
              }
              &.checkGroup {
                max-height: 160px;
                overflow-y: auto;
                li {
                  border-bottom: none;
                  span {
                    display: inline-flex;
                    position: relative;
                    input {
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                      z-index: 1;
                      cursor: pointer;
                      &:checked + label {
                        &::before {
                          border-color: $baseColor;
                        }
                        &::after {
                          content: "";
                          display: inline-block;
                          position: absolute;
                          left: 3px;
                          top: 3px;
                          background-image: url(../img/tick.svg);
                          background-repeat: no-repeat;
                          background-size: 10px;
                          width: 12px;
                          height: 12px;
                        }
                      }
                    }
                    label {
                      @include font(14);
                      color: $black;
                      line-height: 18px;
                      display: inline-flex;
                      position: relative;
                      padding-left: 30px;
                      cursor: pointer;
                      &::before {
                        content: "";
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        border: 1px solid $black;
                        @include radius(4px);
                      }
                    }
                  }
                }
              }
              &.radioGroup {
                max-height: 160px;
                overflow-y: auto;
                li {
                  border-bottom: none;

                  span {
                    display: inline-flex;
                    position: relative;
                    input {
                      opacity: 0;
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 100%;
                      height: 100%;
                      z-index: 1;
                      cursor: pointer;
                      &:checked + label {
                        &::before {
                          border-color: $baseColor;
                        }
                        &::after {
                          content: "";
                          display: inline-block;
                          background-color: $baseColor;
                          position: absolute;
                          left: 4px;
                          top: 4px;
                          width: 8px;
                          height: 8px;
                          @include radius(50%);
                        }
                      }
                    }
                    label {
                      @include font(14);
                      color: $black;
                      display: inline-flex;
                      position: relative;
                      padding-left: 30px;
                      line-height: 18px;
                      cursor: pointer;
                      &::before {
                        content: "";
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        border: 1px solid $black;
                        position: absolute;
                        left: 0;
                        top: 0;
                        @include radius(50%);
                      }
                    }
                  }
                }
              }
            }
          }
          .checkbox-group {
            li {
                margin-bottom: 18px;
                padding-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                    border-bottom: none;
                }
                .card-body{
                    border: 0;
                    padding-left: 15px;
                    ul {
                      li {
                        padding-bottom: 0;
                      }
                    }
                }
                .level {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    padding-right: 35px;
                    .icon {
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 1;
                        transform: rotate(180deg);
                        svg {
                            width: 15px;
                            height: 15px;
                        }
                    }
                    &.collapsed{
                        .icon{
                            transform: rotate(0);
                        }
                    }
                    span {
                      display: inline-flex;
                      position: relative;
                      input {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        z-index: 1;
                        cursor: pointer;
                        &:checked + label {
                          &::before {
                            border-color: $baseColor;
                          }
                          &::after {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            left: 3px;
                            top: 3px;
                            background-image: url(../img/tick.svg);
                            background-repeat: no-repeat;
                            background-size: 10px;
                            width: 12px;
                            height: 12px;
                          }
                        }
                      }
                      label {
                        display: inline-flex;
                        @include font(14);
                        color: $black;
                        line-height: 18px;
                        position: relative;
                        padding-left: 30px;
                        cursor: pointer;
                        &::before {
                          content: "";
                          display: inline-block;
                          width: 16px;
                          height: 16px;
                          border: 1px solid $black;
                          position: absolute;
                          left: 0;
                          top: 0;
                          @include radius(4px);
                        }
                      }
                    }
                } 
            }
            .checkbox {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                input[type="checkbox"] {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  z-index: 1;
                  @include radius(4px);
                  cursor: pointer;
                  &:checked {
                    + label {
                      &::before {
                        border-color: $lightBlue;
                      }
                      &::after {
                        content: "";
                        display: inline-block;
                        background: url(../img/checkBlue.png) no-repeat;
                        background-size: cover;
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 3px;
                        top: 5px;
                      }
                    }
                  }
                }
                label {
                  padding-left: 30px;
                  position: relative;
                  @include font(15);
                  font-weight: 300;
                  color: $black;
                  line-height: 18px;
                  cursor: pointer;
                  margin-bottom: 0;
                  &::before {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    left: 0;
                    top: 0;
                    border: 1px solid $black;
                    @include radius(2px);
                  }
                }
            }
        }
          .confrence-search{
            .selectBox {
              input {
                display: block;
                width: 100%;
                @include font(14);
                color: $black;
                border: 1px solid $gray;
                @include radius(4px);
                padding: 10px;
                outline: none;
                height: 40px;
                &::placeholder {
                  color: rgba(62, 62, 62, 0.5);
                }
              }
            }
          }
          .heading {
            @include font(14);
            color: $black;
            margin: 0 0 15px 0;
            padding-left: 10px;
          }
          .searchBox {
            @include flex();
            border: 1px solid $gray;
            @include radius(4px);
            padding: 3px 5px;
            margin-bottom: 15px;
            input {
              @include font(14);
              color: $black;
              padding: 5px;
              width: calc(100% - 30px);
              border: none;
              box-shadow: none;
              outline: none;
              &::placeholder {
                color: rgba(62, 62, 62, 0.5);
              }
            }
            .searchBtn {
              button {
                background-color: transparent;
                padding: 5px;
                width: 30px;
                height: 30px;
                min-width: 30px;
                min-height: 30px;
                border: none;
                outline: none;
                box-shadow: none;
                cursor: pointer;
                svg {
                  .a {
                    opacity: 0.3;
                  }
                  .b {
                    fill: none;
                    stroke: $blackColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }
                }
              }
            }
          }
          .selectBox {
            select {
              padding: 10px 30px 10px 10px;
              width: 100%;
              @include font(14);
              color: $black;
              appearance: none;
              background: url(../img/arrowdown.png) no-repeat;
              background-size: 16px;
              background-position: 95% center;
              line-height: 18px;
              outline: none;
              @include radius(4px);
              border: 1px solid $border;
            }
          }
          .checkGroup {
            li {
              border-bottom: none;
              margin-bottom: 10px;
              span {
                display: inline-flex;
                position: relative;
                input {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  z-index: 1;
                  cursor: pointer;
                  &:checked + label {
                    &::before {
                      border-color: $baseColor;
                    }
                    &::after {
                      content: "";
                      display: inline-block;
                      position: absolute;
                      left: 3px;
                      top: 3px;
                      background-image: url(../img/tick.svg);
                      background-repeat: no-repeat;
                      background-size: 10px;
                      width: 12px;
                      height: 12px;
                    }
                  }
                }
                label {
                  display: inline-flex;
                  @include font(14);
                  color: $black;
                  line-height: 18px;
                  position: relative;
                  padding-left: 30px;
                  cursor: pointer;
                  &::before {
                    content: "";
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border: 1px solid $black;
                    position: absolute;
                    left: 0;
                    top: 0;
                    @include radius(4px);
                  }
                }
              }
            }
            &.study {
              max-height: 350px;
              overflow-y: auto;
              &::-webkit-scrollbar {
                border-radius: 10px;
                width: 8px;
                height: 10px;
                background: rgba($gray, 0.3%);
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba($gray, 0.8%);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .searchDataBox {
      width: calc(100% - 270px);
      margin-left: 20px;
      .headerFlex {
        @include flex();
        flex-wrap: wrap;
        justify-content: space-between;
        .tabs {
          width: calc(100% - 176px);
          ul {
            @include flex();
            padding: 0 15px;
            background: $white;
            justify-content: space-around;
            @include radius(4px);
            border: 1px solid $gray;
            width: 100%;
            height: 100%;
            margin: 0;
            li {
              list-style: none;
              margin-right: 20px;
              a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                position: relative;
                padding: 10px;
                @include font(18);
                color: $black;
                height: 100%;
                span {
                  &.icon {
                    display: inline-flex;
                    position: absolute;
                    right: -5px;
                    top: 5px;
                    svg {
                      fill: $black;
                      .a {
                        fill: $black;
                      }
                    }
                  }
                }
                &:hover {
                  color: $baseColor;
                  &::after {
                    content: "";
                    background: $baseColor;
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                  }
                }
              }
              .active {
                color: $baseColor;
                &::after {
                  content: "";
                  background: $baseColor;
                  display: inline-block;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  height: 1px;
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .mobileMenuBox {
            display: none;
            align-items: center;
            background: $white;
            @include radius(4px);
            border: 1px solid #00000038;
            padding: 0 10px 0 0;
            div {
              &.menuBtn {
                .btn {
                  padding: 5px;
                  min-height: 20px;
                  min-width: 20px;
                  @include flex();
                  align-items: center;
                  justify-content: center;
                  background: transparent;
                  margin: 10px 15px 10px 0;
                  border: none;
                  outline: none;
                  svg {
                    .a {
                      fill: #343434;
                    }
                  }
                }
              }
              &.label {
                span {
                  display: inline-block;
                  @include font(14);
                  color: $baseColor;
                }
              }
              &.mobileMenu {
                background: $white;
                position: fixed;
                top: 0;
                height: 100%;
                left: -300px;
                width: 100%;
                max-width: 250px;
                overflow-y: auto;
                z-index: 999;
                border-right: 1px solid $gray;
                transition: all 0.5s ease-in-out;
                .closeBtnBox {
                  padding: 15px;
                  text-align: right;
                  button {
                    &.closebtn {
                      background: transparent;
                      padding: 5px;
                      border: none;
                      outline: none;
                      svg {
                        .a {
                          fill: $black;
                          opacity: 0.7;
                        }
                      }
                    }
                  }
                }
                ul {
                  display: block;
                  padding: 0 15px;
                  height: auto;
                  max-height: calc(100% - 60px);
                  border: none;
                  @include radius(0);
                  overflow-y: auto;
                  li {
                    list-style: none;
                    padding-bottom: 10px;
                    margin-bottom: 15px;
                    border-bottom: 1px solid $gray;
                    margin-right: 0;
                    a {
                      @include flex();
                      justify-content: flex-start;
                      text-decoration: none;
                      border: none;
                      span {
                        display: inline-flex;
                        position: relative;
                        line-height: 22px;
                        &.icon {
                          display: inline-flex;
                          margin-top: -10px;
                        }
                      }
                      &.selected {
                        color: $baseColor;
                      }
                      &::after {
                        display: none;
                      }
                      &:hover {
                        color: $baseColor;
                      }
                    }
                    &:last-child {
                      margin-bottom: 0;
                      border-bottom: none;
                    }
                  }
                }
                &::after {
                  content: "";
                  background-color: rgba(0, 0, 0, 0.5);
                  display: inline-block;
                  position: fixed;
                  width: 0;
                  left: 0;
                  top: 0;
                  height: 100%;
                  transition: all 0.5s ease-in-out;
                }
                &.expandMenu {
                  left: 0;
                  &::after {
                    content: "";
                    background-color: rgba(0, 0, 0, 0.5);
                    display: inline-block;
                    position: fixed;
                    width: calc(100% - 250px);
                    left: 250px;
                    width: 100%;
                    height: 100%;
                    top: 0;
                  }
                }
              }
            }
          }
        }
        .prefer {
          a {
            background-color: $white;
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            padding: 15px;
            @include font(18);
            color: $black;
            @include radius(4px);
            border: 1px solid $gray;
            margin-left: 10px;
            cursor: pointer;
            span {
              display: inline-block;
              &.icon {
                margin-right: 10px;
                svg {
                  fill: $black;
                  .a {
                    fill: $black;
                  }
                }
              }
            }
          }
        }
        .filterbtnBox {
          display: none;
          button {
            @include flex();
            align-items: center;
            justify-content: center;
            background-color: $white;
            padding: 5px;
            width: 40px;
            height: 40px;
            margin-left: 10px;
            @include radius(4px);
            border: 1px solid #00000038;
            svg {
              .a {
                fill: $black;
              }
            }
          }
        }
      }
      .dataSection {
        background: $white;
        padding: 20px;
        border: 1px solid $gray;
        @include radius(4px);
        margin: 20px auto 20px auto;
        .sortFilter {
          padding: 0 0 10px 0;
          form {
            ul {
              @include flex();
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                margin-right: 10px;
                margin-bottom: 10px;
                position: relative;
                &.total {
                  margin-right: auto;
                  span {
                    display: inline-flex;
                    @include font(14);
                    font-weight: 600;
                    color: $blackColor;
                    min-width: 160px;
                  }
                }
                button {
                  padding: 10px;
                  background-color: $white;
                  @include font(14);
                  color: $black;
                  border: 1px solid #00000038;
                  outline: none;
                  @include radius(4px);
                  box-shadow: none;
                  cursor: pointer;
                }
                &:last-child {
                  margin-right: 0;
                }
                .saveFilterCount {
                  display: inline-block;
                  vertical-align: middle;
                  position: absolute;
                  background: $baseColor;
                  padding: 4px 3px 3px;
                  @include font(12);
                  color: $white;
                  right: 5px;
                  top: -10px;
                  @include radius(2px);
                  z-index: 1;
                }
              }
            }
            select {
              @include font(14);
              color: $black;
              background-image: url(../img/arrowdown.png);
              background-repeat: no-repeat;
              background-size: 16px;
              background-position: 95% center;
              padding: 7px 25px 7px 8px;
              min-width: 130px;
              @include radius(4px);
              appearance: none;
              border: 1px solid $gray;
              outline: none;
              line-height: 20px;
            }
          }
        }
        .dataBox {
          .dataItem {
            padding: 15px;
            border: 1px solid $border;
            margin-bottom: 20px;
            @include radius(5px);
            .dataFlex {
              @include flex();
              flex-wrap: wrap;
              align-items: flex-start;
              .leftData {
                width: 50%;
                @include flex();
                align-items: center;
                div {
                  &.data {
                    width: calc(100% - 0px);
                    padding-right: 20px;
                    .headFlex {
                      @include flex();
                      div {
                        &.icon {
                          @include flex();
                          align-items: center;
                          justify-content: center;
                          max-width: 60px;
                          width: 60px;
                          height: 60px;
                          margin-right: 10px;
                          img {
                            max-width: 100%;
                            max-height: 100%;
                            height: auto;
                          }
                        }
                        h5, a {
                          color: $blackColor;
                          font-weight: 600;
                          margin: 0 0 5px 0;
                        }
                        a {
                          display: block;
                          text-decoration: none;
                          color:#007bff;
                          font-weight: 600;
                          line-height: 1.2;
                          margin: 0 0 5px 0;
                          &:hover {
                            color:#0056b3;
                          }
                        }
                        ul {
                          @include flex();
                          &.college-info-text {
                            display: block;
                            li {
                              margin-bottom: 0;
                            }
                          }
                          li {
                            list-style: none;
                            margin-bottom: 5px;
                            span {
                              display: inline-block;
                              @include font(12);
                              color: $black;
                            }
                          }
                        }
                        button {
                          &.targetBtn {
                            background-color: $white;
                            padding: 5px 15px;
                            color: $green;
                            border: 1px solid $green;
                            @include radius(3px);
                            outline: none;
                            box-shadow: none;
                            margin-top: 10px;
                            @include transition();
                            cursor: pointer;
                            &:hover {
                              background: $green;
                              color: $white;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .rightData {
                width: 50%;
                .actionBox {
                  @include flex();
                  flex-direction: column;
                  justify-content: space-between;
                  .actionFlexBox {
                    width: 100%;
                    ul {
                      @include flex();
                      justify-content: flex-end;
                      margin: 0;
                      li {
                        list-style: none;
                        @include flex();
                        align-items: center;
                        border-right: 1px solid $bgLightGray;
                        span {
                          display: inline-block;
                          @include font(16);
                          font-weight: 600;
                          color: $blackColor;
                          &.percentValue {
                            display: inline-flex;
                            align-items: center;
                          }
                          &.icon {
                            margin-left: 10px;
                            margin-top: -4px;
                            margin-right: 20px;
                            &.iconlock {
                              margin-left: 0!important;
                              margin-right:10px;
                            }
                            svg {
                              fill: $black;
                              .a {
                                fill: $black;
                              }
                            }
                          }
                          label {
                            display: inline-flex;
                          }
                        }
                        a {
                          display: inline-block;
                          text-decoration: none;
                          padding: 0 10px;
                          text-align: center;
                          svg {
                            width: 40px;
                          }
                          &.powerLinkFilled {
                            svg {
                              .a {
                                fill: $baseColor;
                                stroke: $baseColor;
                                stroke-width: 1.5px;
                              }
                              .b {
                                filter: url(#a);
                              }
                            }
                          }
                          &.powerLinkUnfilled {
                            svg {
                              .a {
                                fill: $white;
                                stroke: $baseColor;
                                stroke-width: 1.5px;
                              }
                              .b {
                                filter: url(#a);
                              }
                              g {
                                &:hover {
                                  .a {
                                    fill: $baseColor;
                                    stroke: $baseColor;
                                    stroke-width: 1.5px;
                                  }                              
                                }
                              }
                            }
                          }
                          &.directionLink {
                            svg {
                              .a {
                                fill: #59a7ff;
                              }
                              .b {
                                fill: #0082ff;
                              }
                            }
                          }
                          &.locationLink {
                            svg {
                              .a {
                                fill: #109882;
                              }
                            }
                          }
                        }

                        &.badgeBox {
                          text-align: center;
                          a {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;
                            cursor: pointer;
                            svg {
                              width: 32px;
                              padding-left: 10px;
                              .a {
                                fill: $orange;
                              }
                            }
                          }
                          &.hollow {
                            a {
                              svg {
                                .a {
                                  fill: none;
                                  stroke: $orange;
                                }
                              }
                            }
                          }
                        }
                        &:last-child {
                          border-right: none;
                        }
                      }
                    }
                  }
                }
              }
            }
            .collapseBox {
              margin-top: 20px;
              .btn {
                @include flex();
                align-items: center;
                background: #e7e7e7;
                justify-content: space-between;
                width: 100%;
                h5 {
                  @include font(14);
                  font-weight: 600;
                  color: $blackColor;
                }
                .closeIcon {
                  transform: rotate(90deg);
                  transition: all 0.3s ease-in-out;
                  svg {
                    fill: rgba(0, 0, 0, 0.5);
                    .a {
                      fill: rgba(0, 0, 0, 0.5);
                      opacity: 0.5;
                    }
                    width: 10px;
                    height: auto;
                  }
                }
                &.expand {
                  @include radius(4px 4px 0 0);
                  .closeIcon {
                    transform: rotate(270deg);
                  }
                }
              }
              .collapse {
                .card {
                  background: #cccccc26;
                  border: none;
                  @include radius(0 0 4px 4px);
                  padding: 15px;
                  .actionOverView {
                    .heading {
                      @include font(14);
                      font-weight: 600;
                      color: $blackColor;
                      padding-bottom: 15px;
                      border-bottom: 1px solid $gray;
                    }
                    .flexBox {
                      ul {
                        @include flex();
                        flex-wrap: wrap;
                        margin: 0;
                        padding: 0;
                        li {
                          width: 33.3%;
                          margin: 15px 0;
                          label {
                            display: block;
                            @include font(14);
                            font-weight: 600;
                            color: $black;
                            margin: 0 0 10px 0;
                          }
                          span {
                            display: block;
                            @include font(14);
                            color: $black;
                            font-weight: normal;
                          }
                        }
                      }
                    }
                  }
                  .collegeOverView {
                    .heading {
                      @include font(14);
                      color: $blackColor;
                      font-weight: 600;
                      border-bottom: 1px solid $gray;
                      padding-bottom: 15px;
                    }
                    .flexBox {
                      @include flex();
                      flex-wrap: wrap;
                      justify-content: space-between;
                      .academicData {
                        width: calc(50% - 10px);
                        ul {
                          margin: 0;
                          padding: 0;
                          li {
                            list-style: none;
                            @include flex();
                            align-items: flex-start;
                            margin: 15px 0;
                            label {
                              display: inline-block;
                              @include font(14);
                              color: $black;
                              font-weight: 600;
                              min-width: 160px;
                              margin: 0 10px 0 0;
                            }
                            span {
                              display: inline-block;
                              @include font(14);
                              color: $black;
                              font-weight: normal;
                            }
                          }
                        }
                      }
                      .tutionData {
                        width: calc(50% - 10px);
                        ul {
                          margin: 0;
                          padding: 0;
                          li {
                            @include flex();
                            align-items: flex-start;
                            list-style: none;
                            margin: 15px 0;
                            label {
                              display: inline-block;
                              @include font(14);
                              color: $black;
                              font-weight: 600;
                              margin: 0 10px 0 0;
                              min-width: 120px;
                            }
                            span {
                              display: inline-block;
                              @include font(14);
                              color: $black;
                              font-weight: normal;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .paginationBox {
          @include flex();
          flex-wrap: wrap;
          align-items: center;
          div {
            &.perPage {
              @include flex();
              align-items: center;
              width: 130px;
              input {
                padding: 5px;
                @include font(14);
                color: $black;
                width: 55px;
                height: 26px;
                text-align: center;
                border: 1px solid $gray;
                @include radius(3px);
                outline: none;
                box-shadow: none;
              }
              label {
                display: inline-flex;
                @include font(14);
                color: $black;
                margin-left: 10px;
              }
            }
            &.pageNav {
              width: calc(100% - 260px);
              text-align: center;
              ul {
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                width: auto;
                border: 1px solid $gray;
                @include radius(3px);
                margin: 0;
                padding: 0;
                li {
                  @include flex();
                  align-items: center;
                  justify-content: center;
                  list-style: none;
                  width: 32px;
                  height: 26px;
                  border-right: 1px solid $gray;
                  a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    @include font(14);
                    color: #626262;
                    width: 32px;
                    height: 26px;
                    cursor: pointer;
                    &:hover {
                      background-color: $lightBlue;
                      color: $white;
                      svg {
                        fill: $white;
                      }
                    }
                  }
                  &.backLink {
                    svg {
                      width: 10px;
                      height: auto;
                      fill: #626262;
                      transform: rotate(180deg);
                    }
                  }
                  &.forLink {
                    svg {
                      width: 10px;
                      height: auto;
                      fill: #626262;
                    }
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
                .disabled {
                  a {
                    &:hover {
                      background-color: $white;
                      color: #626262;
                      svg {
                        fill: #626262;
                      }
                    }
                  }
                }
                .active {
                  a {
                    background-color: $lightBlue;
                    color: $white;
                    svg {
                      fill: $white;
                    }
                  }
                }
              }
            }
            &.goBox {
              @include flex();
              align-items: center;
              justify-content: flex-end;
              width: 130px;
              input {
                padding: 5px;
                width: 55px;
                height: 26px;
                @include font(14);
                color: $black;
                border: 1px solid $gray;
                outline: none;
                box-shadow: none;
                text-align: center;
                @include radius(3px);
              }
              a {
                display: inline-flex;
                align-items: center;
                @include font(14);
                color: $black;
                padding: 5px;
                margin-left: 10px;
                text-decoration: none;
                cursor: pointer;
                span {
                  display: inline-flex;
                  &.icon {
                    margin-left: 5px;
                    svg {
                      fill: $black;
                      .a {
                        opacity: 1;
                        fill: $black;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .mapSection {
        width: 100%;
        .mapFrame {
          width: 100%;
          @include radius(4px);
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
      
    }
  }
}

/************************ headerSearchModal css***************/
.modal {
  &.headerSearchModal {
    .modal-dialog {
      width: 100%;
      max-width: 100% !important;
      margin: 0;
      .modal-content {
        flex-direction: row;
        padding: 15px;
        border: none;
        .modal-body {
          padding: 0 10px 0 0;
          .flexBox {
            @include flex();
            height: 100%;
            padding: 5px 15px;
            border: 1px solid $gray;
            @include radius(30px);
            input {
              width: calc(100% - 30px);
              height: 100%;
              border: none;
              outline: none;
            }
            .searchIcon {
              @include flex();
              align-items: center;
              justify-content: center;
              width: 30px;
              min-width: 30px;
              a {
                display: inline-flex;
                cursor: pointer;
                svg {
                  width: 20px;
                  height: auto;
                  .a {
                    opacity: 0.3;
                  }
                  .b {
                    fill: none;
                    stroke: $blackColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }
                }
              }
            }
          }
        }
        .modal-footer {
          padding: 0;
          border-top: none;
          .btn {
            background: transparent;
            padding: 5px;
            svg {
              width: 20px;
              height: auto;
              fill: $black;
            }
          }
        }
      }
    }
  }
}
/***************************************/
.swal2-actions {
  margin-top: 0 !important;
  .swal2-styled {
    &.swal2-confirm {
      border: none;
      box-shadow: none !important;
      outline: none;
    }
  }
}

/*****************************saveFilerModal css********************/
.modal {
  &.saveFilerModal {
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
      .modal-dialog {
        margin: 15px 15px;
        width: 100%;
        max-height: calc(100% - 30px);
        // overflow-y: auto;
        .modal-header {
          padding: 15px 15px 10px 15px;
          position: relative;
          border-bottom: none;
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            left: 15px;
            width: calc(100% - 30px);
            height: 1px;
            background-color: $gray;
            bottom: 0;
          }
          .modal-title {
            @include font(18);
            color: $blackColor;
            font-weight: 600;
          }
          button {
            outline: none !important;
            box-shadow: none !important;
            &.close {
              svg {
                fill: $black;
                .a {
                  fill: $black;
                }
              }
            }
          }
        }
        .modal-body {
          padding: 15px;
          p {
            @include font(14);
            color: $black;
            font-weight: normal;
            line-height: 20px;
            margin-bottom: 8px;
          }
          div {
            &.formSection {
              margin: 20px 0 10px;
              form {
                label {
                  display: block;
                  color: $black;
                  font-weight: normal;
                  margin-bottom: 10px;
                }
                input {
                  padding: 10px;
                  width: 100%;
                  @include radius(4px);
                  border: 1px solid $gray;
                }
              }
            }
            &.exist {
              background: #ffb1a7;
              padding: 18px 10px;
              margin-top: 20px;
              margin-bottom: 10px;
              p {
                @include font(13);
                color: $blackColor;
                margin:0;
              }
              @include radius(4px);
            }
          }
        }
        .modal-footer {
          @include flex();
          align-items: center;
          justify-content: center;
          flex-direction: row;
          border-top: none;
          padding-bottom: 40px;
          button {
            &.btn {
              width: auto;
              &.saveBtn {
                padding: 10px 15px;
                background-color: $lightBlue;
                color: $white;
                min-width: 100px;
                margin-right: 15px;
              }
              &.cancelBtn {
                padding: 10px 15px;
                background-color: $baseColor;
                color: $white;
                min-width: 100px;
              }
            }
          }
        }
        .modal-content{
            width: 500px;
            margin: auto;
            @media screen and (max-width: 576px){
                width: 100%;
            }
        }
      }
    }
  }
}

/********************************************************************/
/************************ headerSearchModal css***************/
.modal {
  &.headerSearchModal {
    .modal-dialog {
      width: 100%;
      max-width: 100% !important;
      margin:0;
      .modal-content {
        flex-direction: row;
        padding: 15px;
        border: none;
        .modal-body {
          padding: 0 10px 0 0;
          .flexBox {
            @include flex();
            height: 100%;
            padding: 5px 15px;
            border: 1px solid $gray;
            @include radius(30px);
            input {
              height: 100%;
              width: calc(100% - 30px);
              border: none;
              outline: none;
            }
            .searchIcon {
              @include flex();
              align-items: center;
              justify-content: center;
              width: 30px;
              min-width: 30px;
              a {
                cursor: pointer;
                display: inline-flex;
                svg {
                  width: 20px;
                  height: auto;
                  .a {
                    opacity: 0.3;
                  }
                  .b {
                    fill: none;
                    stroke: $blackColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }
                }
              }
            }
          }
        }
        .modal-footer {
          padding: 0;
          border-top: none;
          .btn {
            background: transparent;
            padding: 5px;
            svg {
              width: 20px;
              height: auto;
              fill: $black;
            }
          }
        }
      }
    }
  }
  &.interest-modal {
    padding-right: 0 !important;
    .modal-dialog {
      max-width: 400px;
    }
    .modal-content {
        width: 400px;
        margin: 20px auto;
    }
    .modal-header {
      .close {
        @include font(30);
        color: $black;
        font-weight: 300;
        line-height: 20px;
        outline: none;
      }
    }
    .modal-body {
      @include font(15);
      color: $black;
      .interest-lavel-list {
        .item {
          @include font(14);
          font-weight: 600;
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba($gray, 0.3%);
          position: relative;
          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
          }
          input {
            top: 0;
            position: absolute;
            opacity: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
          label {
            @include flex();
            align-items: center;
            cursor: pointer;
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
              svg {
                path {
                  fill: $black;
                }
              }
            }
          }
          input:checked[type="checkbox"] {
            + label {
              color: $baseColor;
              .icon {
                svg {
                  path {
                    fill: $baseColor;
                  }
                }
              }
            }
          }
        }
      }
    }
    .modal-footer {
      padding-top: 20px;
      padding-bottom: 20px;
      justify-content: center;
      .btn {
        padding: 10px 20px;
        min-width: 100px;
      }
    }
  }
}
/***************************************/
/*************************savedFilersModal css******************/

.modal {
  &.savedFilersModal {
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
      .modal-dialog {
        margin: 15px 15px;
        width: 100%;
        @include radius(5px);
        .modal-header {
          padding: 15px 15px 10px 15px;
          border-bottom: none;
          position: relative;
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            left: 15px;
            width: calc(100% - 30px);
            height: 1px;
            background-color: $gray;
            bottom: 0;
          }
          .modal-title {
            @include font(18);
            color: $blackColor;
            font-weight: 600;
          }
          button {
            outline: none !important;
            box-shadow: none !important;
            &.close {
              svg {
                fill: $black;
                .a {
                  fill: $black;
                }
              }
            }
          }
        }
        .modal-body {
          padding: 15px;
          > h5 {
            &.head {
              font-weight: normal;
              color: $black;
              margin-bottom: 20px;
              margin-top: 0;
            }
          }
          div {
            &.savedFilterData {
              .item {
                @include radius(4px);
                border: 1px solid $gray;
                margin-bottom: 10px;
                padding: 10px;
                h5 {
                  &.heading {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;
                    button {
                      padding: 5px;
                      border: none;
                      outline: none;
                      background-color: transparent;
                      margin-bottom: 10px;
                      svg {
                        .a {
                          fill: $baseColor;
                        }
                      }
                    }
                    span {
                      display: inline-block;
                      margin-bottom: 10px;
                    }
                  }
                }
                > button {
                  &.btn {
                    @include flex();
                    justify-content: space-between;
                    align-items: center;
                    background: $gray;
                    padding: 10px 45px 10px 15px;
                    outline: none !important;
                    box-shadow: none !important;
                    position: relative;
                    h5 {
                      @include font(14);
                      color: $blackColor;
                      font-weight: normal;
                    }
                    span {
                      &.icon {
                        position: absolute;
                        transform: rotate(90deg);
                        right: 18px;
                        svg {
                          width: 10px;
                          height: auto;
                          fill: $black;
                          .a {
                            fill: $black;
                          }
                        }
                      }
                    }
                  }
                }
                .btn-wrap {
                  padding: 15px 0 20px;
                  .btn {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 8px 15px;
                    @include font(15);
                    font-weight: 300;
                    min-width: 100px;
                  }
                }
                .collapse {
                  &.show {
                    .card {
                      background: #cccccc26;
                      border: none;
                      @include radius(0 0 4px 4px);
                      margin-top: -5px;
                      p {
                        @include font(14);
                        color: $black;
                        line-height: 20px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .modal-footer {
          @include flex();
          align-items: center;
          justify-content: center;
          flex-direction: row;
          border-top: none;
          padding-bottom: 20px;
          padding-top: 0;
          button {
            &.btn {
              width: auto;
              &.saveBtn {
                padding: 10px 15px;
                background-color: $lightBlue;
                color: $white;
                min-width: 100px;
                margin-right: 15px;
              }
              &.cancelBtn {
                padding: 10px 15px;
                background-color: $baseColor;
                color: $white;
                min-width: 100px;
              }
            }
          }
        }
      }
    }

    .modal-dialog{
        .modal-content{
            width: 500px;
            @media screen and (max-width: 600px){
                width: 100%;
                margin: auto;
            }
        }
    }
  }
}
/*****************************************************************/

@media screen and (min-width: 1500px) {
  .searchDataSection {
    > .container {
      max-width: 1300px;
    }
  }
}
@media screen and (min-width: 1700px) {
  .searchDataSection {
    > .container {
      max-width: 1600px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .searchDataSection {
    .searchDataFlexBox {
      .searchDataBox {
        .headerFlex {
          .tabs {
            ul {
              li {
                a {
                  @include font(16);
                }
              }
            }
          }
        }
        .dataSection {
          .dataBox {
            .dataItem {
              .dataFlex {
                .leftData {
                  width: 45%;
                  div {
                    &.data {
                      .headFlex {
                        div {
                          &.icon {
                            width: 50px;
                            height: 50px;
                          }
                        }
                      }
                    }
                  }
                }
                .rightData {
                  width: 55%;
                  .actionBox {
                    .actionFlexBox {
                      ul {
                        li {
                          span {
                            &.percentValue {
                              span {
                                @include font(14);
                              }
                              label {
                                @include font(14);
                              }
                            }
                          }
                          a {
                            padding: 0 7px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .searchDataSection {
    .searchDataFlexBox {
      .searchSideMenu {
        left: -500px;
        position: fixed;
        height: 100%;
        z-index: 999;
        top: 0;
        overflow-y: scroll;
        padding-bottom: 45px;
        .closeBtnBox {
          @include flex();
        }
        &::after {
          content: "";
          display: inline-block;
          transition: all 0.3s ease-in-out;
        }
        &.slideMenuFilter {
          left: 0;
          top: 0;
          &::after {
            content: "";
            display: inline-block;
            left: 250px;
            width: calc(100% - 250px);
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            position: fixed;
            top: 0;
            z-index: 1;
          }
          .backDrop {
            left: 250px;
            width: calc(100% - 250px);
            height: 100%;
            position: fixed;
            top: 0;
            z-index: 2;
          }
        }
        > ul {
          max-height: calc(100% - 277px);
          overflow-y: auto;
        }
      }
      .searchDataBox {
        width: 100%;
        margin-left: 0;
        .headerFlex {
          .tabs {
            width: calc(100% - 100px);
            ul {
              display: none;
              li {
                a {
                  padding: 5px 0;
                  @include font(16);
                }
              }
            }
            .mobileMenuBox {
              @include flex();
              div {
                &.mobileMenu {
                  ul {
                    li {
                      margin-bottom: 10px;
                      a {
                        span {
                          .icon {
                            margin-left: 5px;
                          }
                        }
                      }
                    }
                  }
                }
                &.menuBtn {
                  .btn {
                    margin: 8px 10px;
                  }
                }
              }
            }
          }
          .prefer {
            a {
              padding: 12px 10px;
              width: 40px;
              height: 40px;
              @include flex();
              align-items: center;
              justify-content: center;
              span {
                display: none;
                &.icon {
                  display: inline-flex;
                  margin-right: 0;
                }
              }
            }
          }
          .filterbtnBox {
            display: block;
          }
        }
        .dataSection {
          .sortFilter {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .searchDataSection {
    .searchDataFlexBox {
      .searchDataBox {
        .dataSection {
          .dataBox {
            .dataItem {
              .dataFlex {
                .leftData {
                  width: 100%;
                  flex-wrap: wrap;
                  position: relative;
                  div {
                    &.data {
                      width: calc(100% - 0);
                      padding-right: 0;
                      .headFlex {
                        width: calc(100% - 0);
                      }
                    }
                  }
                }
                .rightData {
                  width: 100%;
                  margin-top: 20px;
                  .actionBox {
                    .actionFlexBox {
                      ul {
                        justify-content: flex-start;
                      }
                    }
                  }
                }
              }
              .collapseBox {
                .collapse {
                  .card {
                    .collegeOverView {
                      .flexBox {
                        .academicData {
                          width: 100%;
                          ul {
                            li {
                              label {
                                min-width: 90px;
                                max-width: 90px;
                                margin-right: 40px;
                              }
                              &:last-child {
                                margin-bottom: 0;
                              }
                            }
                          }
                        }
                        .tutionData {
                          width: 100%;
                          ul {
                            li {
                              label {
                                min-width: 90px;
                                max-width: 90px;
                                margin-right: 40px;
                              }
                              &:last-child {
                                margin-bottom: 0;
                              }
                            }
                          }
                        }
                        ul {
                          li {
                            margin: 10px 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .paginationBox {
            div {
              &.perPage {
                order: 1;
              }
              &.goBox {
                order: 2;
                margin-left: auto;
              }
              &.pageNav {
                order: 3;
                width: 100%;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .searchDataSection {
    .searchDataFlexBox {
      .searchDataBox {
        .dataSection {
          .dataBox {
            .dataItem {
              .dataFlex {
                .leftData {
                  div {
                    &.data {
                      .headFlex {
                        div {
                          &.icon {
                            width: 40px;
                            height: 40px;
                          }
                        }
                      }
                    }
                  }
                }
                .rightData {
                  flex-wrap: wrap;
                  flex-direction: column-reverse;
                  .flexBox {
                    width: 100%;
                    margin-right: 0;
                  }
                  .actionBox {
                    width: 100%;
                    position: relative;
                    margin-bottom: 0;
                    .actionFlexBox {
                      width: 100%;
                      ul {
                        justify-content: flex-start;
                        li {
                          border-right: none;
                          margin-right: 10px;
                          span {
                            &.percentValue {
                              @include font(14);
                              .icon {
                                margin-right: 10px;
                              }
                            }
                          }
                          > a {
                            width: 40px;
                            height: 40px;
                            @include radius(50%);
                            border: 1px solid $gray;
                            @include flex();
                            align-items: center;
                            justify-content: center;
                            padding: 0 5px;
                            svg {
                              max-width: 30px;
                              height: auto;
                            }
                          }
                          &.badgeBox {
                            a {
                              svg {
                                width: 20px;
                              }
                            }
                          }
                          &:last-child {
                            margin-right: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .collapseBox {
                .collapse {
                  .card {
                    .actionOverView {
                      .flexBox {
                        ul {
                          li {
                            width: 50%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .paginationBox {
            div {
              &.perPage {
                input {
                  width:40px;
                }
              }
              &.goBox {
                input {
                  width:40px;
                }
              }
            }
          }
        }
      }
    }
  }
  .collegeListing {
    .dataSection {
      .dataBox {
        .blankList {
          h2 {
            @include font(16);
            line-height: 1.3;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .searchDataSection {
    .searchDataFlexBox {
      .searchDataBox {
        .dataSection {
          padding: 10px;
          .sortFilter {
            form {
              ul {
                justify-content: space-between;
                li {
                  margin-right: 10px;
                  &.total {
                    margin-right: 10px;
                  }
                  button {
                    padding: 10px 8px;
                  }
                  &:nth-child(2) {
                    margin-right: 4px;
                  }
                  &:last-child {
                    select {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
          .dataBox {
            .dataItem {
              padding: 10px 5px;
              .dataFlex {
                .rightData {
                  .actionBox {
                    .actionFlexBox {
                      ul {
                        li {
                          margin-right: 8px;
                          span {
                            &.percentValue {
                              @include font(14);
                              span {
                                @include font(14);
                              }
                              label {
                                @include font(14);
                              }
                              .icon {
                                margin-left: 5px;
                                margin-right: 5px;
                              }
                            }
                          }
                          a {
                            width: 30px;
                            height: 30px;
                            &.powerLink, &.powerLinkUnfilled, &.powerLinkFilled {
                              padding-top: 5px;
                              svg {
                                transform: scale(1.5);
                              }
                            }
                            &.directionLink {
                              svg {
                                transform: scale(1.1);
                              }
                            }
                            &.locationLink {
                              svg {
                                max-width: 15px;
                              }
                            }
                          }
                          &.badgeBox {
                            text-align: center;
                            a {
                              @include flex();
                              align-items: center;
                              justify-content: center;
                              cursor: pointer;
                              svg {
                                width: 20px;
                                padding-left: 0;
                                .a {
                                  fill: #ff6624;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .collapseBox {
                .collapse {
                  .card {
                    .actionOverView {
                      .flexBox {
                        ul {
                          li {
                            &:nth-child(1) {
                              order: 1;
                            }
                            &:nth-child(2) {
                              order: 2;
                            }
                            &:nth-child(3) {
                              order: 5;
                              width: 100%;
                            }
                            &:nth-child(4) {
                              order: 3;
                            }
                            &:nth-child(5) {
                              order: 4;
                            }
                            &:nth-child(6) {
                              order: 6;
                              width: 100%;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .modal {
    &.saveFilerModal {
      &.show {
        .modal-dialog {
          .modal-body {
            div {
              &.formSection {
                margin-bottom: 0;
              }
            }
          }
          .modal-footer {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
}
