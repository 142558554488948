@import "../../../../assets/css/mixin";
@import "../../../../assets/css/reset";
@import "../../../../assets/css/variables";

.signup-container {
  .signup-action {
    .active.MuiButton-containedPrimary {
      color: $white;
      background-color: $green !important;
    }
    .active.MuiButton-containedPrimary:hover {
      background-color: $green !important;
    }
    .inactive.MuiButton-containedPrimary {
      color: #433e3e;
      background-color: #f6f6f6 !important;
    }
    .inactive.MuiButton-containedPrimary:hover {
      background-color: #f6f6f6 !important;
    }
  }
}

/***********************signupContainerSection css ******************************/
.signupContainerSection {
  min-height: calc(100vh - 120px);
  padding: 20px 15px;
  .signupContainer {
    max-width: 560px;
    margin: 0px auto;
    border: 1px solid $lightGray;
    background-color: $white;
    padding: 20px 30px 30px 30px;
    .signupHeader {
      margin-bottom: 30px;
      text-align: center;
      margin-top: 10px;
      h1 {
        @include font(22);
        color: $blackColor;
        font-weight: 600;
      }
    }
    .signuAction {
      margin-bottom: 30px;
      .title {
        margin-bottom: 15px;
        h2 {
          @include font(16);
          color: $blackColor;
          font-weight: normal;
          @include flex();
          align-items: center;
          sup {
            color: $redColor;
            margin-bottom: -8px;
          }
        }
      }
      .tabsBtnBox {
        @include flex();
        button {
          margin-right: 15px;
          outline: none;
          text-transform: none;
          width: 145px;
          height: 90px;
          @include radius(5px);
          background: #e9e9e9;
          @include font(14);
          color: $blackColor;
          box-shadow: none;
          span {
            &.MuiButton-label {
              flex-direction: column;
            }
            &.label {
              display: block;
            }
            &.icon {
              display: block;
              svg {
                fill: $blackColor;
              }
            }
          }
          &.active {
            background: $green;
            color: $white;
            @include shadow(0px, 6px, 15px, #3e3e3e1a);
            span {
              &.icon {
                svg {
                  fill: $white;
                }
              }
            }
          }
        }
      }
    }
    .subHead {
      @include flex();
      align-items: center;
      margin-bottom: 20px;
      @include font(16);
      color: $black;
      font-weight: 600;
      .checkIcon {
        display: inline-flex;
        margin-right: 10px;
        svg {
          .a {
            fill: #00b9ff;
          }
          .b {
            fill: #fafafa;
          }
        }
      }
    }
    .signup-athlete {
      form {
        > div {
          margin-bottom: 20px;
          &.emailBoxField {
            h2 {
              margin-bottom: 10px;
              @include font(16);
              color: $blackColor;
              font-weight: 500;
              @include flex();
              align-items: center;
              sup {
                color: $redColor;
                margin-bottom: -8px;
              }
            }
            p {
              margin-bottom: 4px;
              @include font(12);
              color: $blackColor;
              font-weight: normal;
            }
            input {
              border: 1px solid $lightGray;
              @include radius(6px);
            }
          }
          &.graduateYearBox {
            h2 {
              margin-bottom: 10px;
              @include font(16);
              color: $blackColor;
              font-weight: 500;
              @include flex();
              align-items: center;
              sup {
                color: $redColor;
                margin-bottom: -8px;
              }
              span {
                &.icon {
                  margin-left: 10px;
                  svg {
                    .a {
                      fill: #00b9ff;
                    }
                    .b {
                      fill: #fafafa;
                    }
                  }
                }
              }
            }
          }
          &.genderBox {
            h2 {
              margin-bottom: 10px;
              @include font(16);
              color: $blackColor;
              font-weight: 500;
              @include flex();
              align-items: center;
              sup {
                color: $redColor;
                margin-bottom: -8px;
              }
            }
            fieldset {
              &.MuiFormControl-root {
                .MuiFormControlLabel-root {
                  .MuiRadio-colorSecondary {
                    &.Mui-checked {
                      color: $lightBlue;
                    }
                  }
                }
              }
            }
          }
          &.sportBox {
            h2 {
              margin-bottom: 10px;
              @include font(16);
              color: $blackColor;
              font-weight: 500;
              @include flex();
              align-items: center;
              sup {
                color: $redColor;
                margin-bottom: -8px;
              }
            }
          }
          &.continueBtnBox {
            margin-bottom: 60px;
            margin-top: 40px;
            text-align: center;
            button {
              @include radius(4px);
              background-color: $green;
              color: $white;
              text-transform: none;
              cursor: pointer;
              box-shadow: none;
              outline: none;
            }
          }
          &.signInLinkBox {
            margin-bottom: 0px;
            p {
              text-align: center;
              font-weight: 600;
              a {
                color: $redColor;
              }
            }
          }
        }
      }
    }
  }
}

/*********************************************************************************/

/*****************************createPasswordSection css*************************/

.createPasswordSection {
  min-height: calc(100vh - 120px);
  padding: 20px 15px;
  @include flex();
  align-items: center;
  justify-content: center;
  .formSection {
    max-width: 560px;
    margin: 0px auto;
    padding: 30px 30px 20px 30px;
    border: 1px solid $lightGray;
    @include radius(6px);
    width: 100%;
    form {
      > div {
        margin-bottom: 25px;
        &.heading {
          h1 {
            text-align: center;
            @include font(22);
            color: $black;
            font-weight: 600;
          }
        }
        &.subHeading {
          margin-bottom: 35px;
          h2 {
            @include font(18);
            color: $black;
            font-weight: 600;
            text-align: center;
          }
        }
        &.passfieldBox {
          .MuiFormLabel-root {
            margin-bottom: 10px;
            p {
              @include font(16);
              color: $black;
              @include flex();
              align-items: center;
              font-weight: 600;
              sup {
                margin-bottom: -7px;
                color: $redColor;
              }
            }
          }
          .MuiFormControl-root {
            input {
              color: $blackColor;
              &::placeholder {
                color: $black;
                opacity: 1;
              }
            }
          }
          margin-bottom: 15px;
        }
        &.submitBtnBox {
          text-align: center;
          margin-top: 50px;
          margin-bottom: 20px;
          button {
            outline: none;
            text-transform: none;
            @include font(16);
            color: $white;
            background-color: $green;
            box-shadow: none;
            min-width: 120px;
            cursor: pointer;
          }
        }
      }
      .hint {
        @include font(14);
        color: $black;
      }
    }
  }
}
.fakeInput {
  position: absolute !important;
  top: 0;
  left: -999px;
  width: 20px;
  opacity: 0;
}

/****************************************************************************/
@media screen and (max-width:639px) {
  .signupContainerSection {
    .signupContainer {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .createPasswordSection { 
    .formSection {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}