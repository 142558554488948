/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.onBoardAddProfileSection {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: calc(100vh - 120px);
  padding: 40px 15px;
  margin-top: 60px;
}

.onBoardAddProfileSection .addProfileBlock {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.onBoardAddProfileSection .addProfileBlock .formSection {
  padding: 30px 30px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.onBoardAddProfileSection .addProfileBlock .formSection .heading {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  color: #3E3E3E;
  margin: 0 0 30px 0;
}

.onBoardAddProfileSection .addProfileBlock .formSection .heading span.icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 10px;
}

.onBoardAddProfileSection .addProfileBlock .formSection .heading span.icon .a {
  fill: #00b9ff !important;
  stroke: none;
}

.onBoardAddProfileSection .addProfileBlock .formSection .heading span.icon .b {
  fill: #fafafa;
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: rgba(233, 233, 233, 0.3);
  padding: 10px;
  width: 100%;
  max-width: 286px;
  min-height: 175px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  margin: 100px auto 20px auto;
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox .imgUploadBox {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  background-color: #ffffff;
  width: 160px;
  height: 160px;
  margin: -80px auto 40px auto;
  border: 2px solid #00B9FF;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  cursor: pointer;
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox .imgUploadBox input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox .imgUploadBox img {
  max-width: 100%;
  max-height: 160px;
  height: auto;
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox .imgUploadBox span.checkIcon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  bottom: -22px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox .imgUploadBox span.checkIcon svg .a {
  fill: #00b9ff;
  stroke: #fff;
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox .imgUploadBox span.checkIcon svg .b {
  fill: #fafafa;
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox h5 {
  padding: 0 10px;
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin: 0 0 10px 0;
}

.onBoardAddProfileSection .addProfileBlock .formSection .imageBox .detailBox p {
  padding: 0 10px;
  font-size: 0.875rem;
  font-weight: 300;
  color: #000;
  text-align: center;
  margin: 0 0 10px 0;
}

.onBoardAddProfileSection .addProfileBlock .formSection .info {
  font-size: 0.875rem;
  color: #3E3E3E;
  line-height: 20px;
  text-align: center;
}

.onBoardAddProfileSection .addProfileBlock .formSection .skip {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3E3E3E;
  text-align: center;
}

.onBoardAddProfileSection .addProfileBlock .formSection .skip a {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #C0321F;
  cursor: pointer;
}

.onBoardAddProfileSection .addProfileBlock .formSection .skip a:hover {
  text-decoration: underline;
}

.onBoardAddProfileSection .addProfileBlock .continueBtnBox {
  text-align: center;
  margin-top: 40px;
}

.onBoardAddProfileSection .addProfileBlock .continueBtnBox a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  background-color: #1CB79E;
  padding: 10px 15px;
  min-width: 100px;
  min-height: 45px;
  font-size: 1rem;
  font-weight: 300;
  color: #ffffff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
}

.modal {
  padding-right: 0 !important;
}

.modal.skip-popup .modal-dialog {
  max-width: 580px;
}

.modal.skip-popup .modal-content {
  border: none;
}

.modal.skip-popup .modal-body {
  padding: 50px 45px;
  text-align: center;
  position: relative;
}

.modal.skip-popup .modal-body button.close {
  outline: none;
  position: absolute;
  top: 15px;
  right: 20px;
  opacity: 1;
}

.modal.skip-popup .modal-body button.close svg {
  fill: #3E3E3E;
}

.modal.skip-popup .modal-body button.close svg path {
  stroke: none;
}

.modal.skip-popup .modal-body button.close svg .a, .modal.skip-popup .modal-body button.close svg .b {
  fill: #3E3E3E;
  stroke: #3E3E3E;
}

.modal.skip-popup .modal-body .modal-title {
  font-size: 1.875rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}

.modal.skip-popup .modal-body .modal-title + p {
  margin-bottom: 25px;
}

.modal.skip-popup .modal-body p {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3E3E3E;
  margin-bottom: 15px;
}

.modal.skip-popup .modal-body .btn {
  padding: 12px 25px;
  font-size: 0.875rem;
  font-weight: 400;
}

.modal.skip-popup .modal-body .skip {
  margin-top: 15px;
  margin-bottom: 0;
}

.modal.skip-popup .modal-body .skip a {
  display: inline-block;
  vertical-align: top;
  text-decoration: underline;
  font-size: 0.875rem;
  color: #C0321F;
  cursor: pointer;
}

.modal.skip-popup .modal-body .skip a:hover {
  text-decoration: none;
}

@media screen and (max-width: 639px) {
  .onBoardAddProfileSection {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .onBoardAddProfileSection .addProfileBlock .continueBtnBox {
    margin-top: 25px;
  }
}

@media screen and (max-width: 567px) {
  .modal.skip-popup .modal-body {
    padding: 45px 25px 35px;
  }
  .modal.skip-popup .modal-body .modal-title {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 479px) {
  .onBoardAddProfileSection .addProfileBlock .formSection {
    padding: 20px 20px;
  }
  .onBoardAddProfileSection .addProfileBlock .continueBtnBox a {
    width: 100%;
  }
}
