@import "../../../../../../../../assets/css/variables";
@import "../../../../../../../../assets/css/mixin";
@import "../../../../../../../../assets/css/reset";

.account-main-sec {
    background: $bgLightGray;
    padding:25px 0 35px;
    margin-top: 60px;
    .account-with-sidebar {
        @include flex();
        .account-sidebar {
            width: 250px;
            margin-right: 20px;
            padding: 20px;
            background: $white;
            @include radius(4px);
            box-shadow: 0px 5px 15px #0000000d;
            height: 100%;
            ul {
                display: block;
                li {
                    display:block;
                    margin-bottom: 25px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: block;
                        text-decoration: none;
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        letter-spacing: 0.21px;
                        padding-left: 30px;
                        position: relative;
                        @include transition();
                        .icon {
                            display: inline-block;
                            vertical-align: middle;
                            position: absolute;
                            left: 0;
                            top: -2px;
                            z-index: 1;
                            svg {
                                @include transition();
                                path {
                                    @include transition();
                                }
                            }
                        }
                    }
                    &:hover, &.active {
                        a {
                            color: $baseColor;
                            .icon {
                                svg {
                                    path {
                                        stroke: $baseColor;
                                    }
                                }
                                &.lock {
                                    svg {
                                        path {
                                            fill: $baseColor;
                                            stroke: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            + .account-data-box {
                width: calc(100% - 270px);
            }
        }
        .account-data-box {
            width: 100%;
            padding: 20px 25px 30px;
            background: $white;
            @include radius(4px);
            box-shadow: 0px 5px 15px #0000000d;
            .title {
                @include font(18);
                font-weight: 600;
                color: $black;
                padding-bottom: 15px;
                margin-bottom: 22px;
                border-bottom: 1px solid $gray;
            }
            .account-form-box {
              h4 {
                @include font(18);
                font-weight: 500;
                color:rgba($black, .8);
                margin-bottom: 20px;
              }
                .form-group {
                    margin-bottom: 35px;
                    width: 60%;
                    label {
                        display: block;
                        @include font(14);
                        font-weight: 500;
                        color: rgba($black, .60%);
                        margin-bottom: 10px;
                    }
                    input {
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        padding: 0 10px;
                        width: 100%;
                        height: 35px;
                        border: 1px solid $gray;
                        @include radius(4px);
                        &::placeholder {
                            color: $black; 
                        }
                    }
                    .btn {
                        @include font(14);
                        padding: 12px 20px;
                        margin-top: -15px;
                    }
                }
                .fieldBox {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &.flexBox {
                      @include flex();
                      align-items: center;
                      justify-content: space-between;
                      .half {
                        width: calc(50% - 10px);
                      }
                    }
                    &.phone {
                      .flexBox {
                        @include flex();
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 15px;
                        border: 1px solid $gray;
                        @include radius(4px);
                        .countryFlag {
                          width: 35px;
                          img {
                            max-width: 100%;
                            height: auto;
                          }
                        }
                        select {
                          padding: 5px 15px 5px 5px;
                          background: none;
                          outline: none;
                          border: none;
                          color: $black;
                          font-size: 14px;
                          -webkit-appearance: button;
                          appearance: button;
                          width: 70px;
                        }
                        input {
                          width: calc(100% - 125px);
                          border: none;
                        }
                      }
                    }
                    label {
                      @include font(15);
                      font-weight: 400;
                      color: rgba($black, .5);
                      line-height: 1.4;
                      margin-bottom: 10px;
                      .checkIcon {
                        margin-left: 10px;
                      }
                    }
                    input {
                      padding: 15px;
                      width: 100%;
                      @include font(14);
                      color: $black;
                      border: 1px solid $gray;
                      outline: none;
                      box-shadow: none;
                      @include radius(4px);
                      &::placeholder {
                        color: $black;
                      }
                    }
                    .checkbox-gorup {
                        span {
                          display: inline-flex;
                          align-items: center;
                          position: relative;
                          input[type="checkbox"] {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            opacity: 0;
                            z-index: 1;
                            @include radius(4px);
                            cursor: pointer;
                            &:checked {
                              + label {
                                &::before {
                                  border-color: $lightBlue;
                                }
                                &::after {
                                  content: "";
                                  display: inline-block;
                                  background: url(../img/checkBlue.png) no-repeat;
                                  background-size: cover;
                                  position: absolute;
                                  width: 12px;
                                  height: 12px;
                                  left: 3px;
                                  top: 5px;
                                }
                              }
                            }
                          }
                          label {
                            padding-left: 30px;
                            position: relative;
                            @include font(15);
                            font-weight: normal;
                            color: rgba($black, .5);
                            line-height: 18px;
                            margin-bottom: 0;
                            cursor: pointer;
                            &::before {
                              content: "";
                              display: inline-block;
                              position: absolute;
                              width: 18px;
                              height: 18px;
                              left: 0;
                              top: 0;
                              border: 1px solid $gray;
                              @include radius(4px);
                            }
                          }
                        }
                    }
                    .info-text {
                        padding-top: 10px;
                        @include font(14);
                        color: rgba($black, .5);
                        position: relative;
                        .icon-box {
                            margin-left: 5px;
                        }
                    }
                    .btn {
                        margin-top:15px;
                        @include font(15);
                        padding: 12px 20px;
                        min-width: 120px;
                    }
                }
            }
        }
    }
    .coach-notification-setting {
        .title-box {
          @include flex();
          justify-content: space-between;
          align-items: center;
          padding-bottom: 15px;
          margin-bottom: 22px;
          border-bottom: 1px solid $gray;
          .item {
            .title {
              @include font(18);
              font-weight: 600;
              color: $black;
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }
          }
          .form-check {
            &.form-switch {
              .form-check-input {
                background-image: none;
                background: $black;
                width: 32px;
                height: 18px;
                border-width: 0;
                @include radius(50px);
                border: none;
                margin-left: 0;
                appearance: none;
                position: relative;
                @include transition();
                cursor: pointer;
                margin-top: 0;
                &:after {
                  content: "";
                  background: $white;
                  position: absolute;
                  width: 13px;
                  height: 13px;
                  left: 2px;
                  top: 2.1px;
                  border: none;
                  z-index: 2;
                  border-radius: 50%;
                  @include transition();
                }
              }
              input:checked[type="checkbox"] {
                background: $green;
                &:after {
                  left: auto !important;
                  right: 2px !important;
                  background: $white;
                }
              }
            }
          }
        }
        .notification-list-row {
          .notification-item {
              margin-bottom: 35px;
              &:last-child {
                margin-bottom: 0;
              }
              h4 {
                @include font(15);
                font-weight: 500;
                color: $lightBlue;
                margin-bottom: 15px;
              }
            .flexbox-row {
              @include flex();
              justify-content: space-between;
              align-items: center;
              @include font(14);
              font-weight: 400;
              line-height: 1.3;
              color: #666666;
              margin-bottom: 25px;
              &:last-child {
                margin-bottom: 0;
              }
              .radioflexBox {
                @include flex();
                align-items: center;
                justify-content: flex-end;
                padding-left: 20px;
                .option {
                  @include flex();
                  align-items: center;
                  position: relative;
                  margin-right: 20px;
                  margin-bottom: 0;
                  &:last-child {
                    margin-right: 0;
                  }
                  input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    cursor: pointer;
                    opacity: 0;
                    &:checked+label {
                      &::before {
                        border-color: $lightBlue;
                      }
                      &::after {
                        content: "";
                        display: inline-block;
                        background-color: $lightBlue;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        left: 4px;
                        top: 4px;
                        @include radius(50%);
                      }
                    }
                  }
                  label {
                    position: relative;
                    @include font(14);
                    font-weight: 300;
                    color: #666666;
                    padding-left: 25px;
                    margin-bottom: 0;
                    cursor: pointer;
                    &::before {
                      content: "";
                      position: absolute;
                      width: 16px;
                      height: 16px;
                      left: 0;
                      top: 0;
                      border: 1px solid #707070;
                      @include radius(50%);
                    }
                  }
                }
              }
            }
          }
        }
    }
}
@media screen and (max-width:991px) {
    .account-main-sec {
        .account-with-sidebar {
            .account-data-box {
                .account-form-box {
                    .form-group {
                        width: 100%;
                        margin-bottom: 20px;
                        .btn {
                            margin-top: 0;
                        }
                    }
                    .fieldBox {
                        margin-bottom: 0;
                        &.flexBox {
                            display: block;
                            .half {
                                width: 100%;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
        .coach-notification-setting {
            .notification-list-row {
              .notification-item {
                .flexbox-row {
                  li {
                    width: 50%;
                  }
                  .radioflexBox {
                    padding-left: 10px;
                    .option {
                      margin-right: 15px;
                      label {
                        @include font(13);
                      }
                    }
                  }
                }
              }
            }
        }
    }
}
@media screen and (max-width:767px) {
    .account-main-sec {
        .account-with-sidebar {
            display: block;
            .account-sidebar {
                width: 100%;
                margin-bottom: 20px;
                min-height: 1px;
                ul {
                    li {
                        margin-bottom: 20px;
                    }
                }
                + .account-data-box {
                    width: 100%;
                }
            }
        }
        .coach-notification-setting {
            .notification-list-row {
              .notification-item {
                margin-bottom: 25px;
                .flexbox-row {
                  display: block;
                  margin-bottom: 15px;
                  li {
                    width: 100%;
                    margin-bottom: 12px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                  .radioflexBox {
                    justify-content: start;
                    padding-left: 0;
                  }
                }
              }
            }
        }
    }
}
@media screen and (max-width:639px) {
    .account-main-sec {
        padding-top: 25px;
        padding-bottom: 25px;
        .account-with-sidebar {
            .account-data-box {
                padding: 20px 15px 25px;
            }
            .account-data-box {
                .title {
                    @include font(17);
                    margin-bottom: 18px;
                    padding-bottom: 12px;
                }
                .account-form-box {
                    .form-group {
                        margin-bottom: 15px;
                    }
                }
            }
        }
        .coach-notification-setting {
            .title-box {
              .item {
                .title {
                  @include font(16);
                }
              }
            }
            .notification-list-row {
              .notification-item {
                .flexbox-row {
                  .radioflexBox {
                    .option {
                      label {
                        @include font(14);
                      }
                    }
                  }
                }
              }
            }
        }
    }
}