@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 16px;
}

body {
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-family: 'Poppins', sans-serif;
}

.transition {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

::-webkit-scrollbar {
  width: 10px;
  background: rgba(33, 119, 214, 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(33, 119, 214, 0.4);
  cursor: pointer;
}

.btn {
  font-size: 1rem;
  background: #c0321f;
  color: #ffffff;
  border: 0px;
  text-align: center;
  padding: 12px 20px;
  line-height: 1.5;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.btn.medium {
  font-size: 0.875rem;
  padding: .5em 1em;
}

.btn.blue {
  background: #2177D6;
  color: #ffffff;
}

.btn.blue-light {
  background: rgba(0, 185, 255, 0.3);
  color: #ffffff;
}

.btn.green {
  background: #1CB79E;
  color: #ffffff;
}

.btn.outlined {
  background: none;
  border: 1px solid transparent;
  color: #3e3e3e;
}

.btn.outlined.green {
  border-color: #1CB79E;
  color: #1CB79E;
}

.btn.outlined.green:hover {
  background: #1CB79E;
  color: #ffffff;
}

.btn.outlined.blue {
  border-color: #2177D6;
  color: #2177D6;
}

.btn.outlined.blue:hover {
  background: #2177D6;
  color: #ffffff;
}

.btn.rounded {
  border-radius: 30px !important;
}

.btn:before {
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  left: -50%;
  top: -50%;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.btn:focus, .btn:hover {
  outline: 0px !important;
  color: #ffffff;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.btn:focus:before, .btn:hover:before {
  -webkit-animation: hoverEffect .75s ease-in-out forwards;
          animation: hoverEffect .75s ease-in-out forwards;
}

[class*="fx-reveal"],
[class^="fx-reveal"] {
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 1.5s;
  transition-duration: 1.5s;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
}

[class*="fx-reveal"].up,
[class^="fx-reveal"].up {
  transform: translateY(40px);
  -webkit-transform: translateY(40px);
  -moz-transform: translateY(40px);
}

[class*="fx-reveal"].down,
[class^="fx-reveal"].down {
  transform: translateY(-40px);
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
}

[class*="fx-reveal"].animate,
[class^="fx-reveal"].animate {
  -webkit-transform: translate(0px, 0px) scale(1);
  transform: translate(0px, 0px) scale(1);
  opacity: 1;
}

.pageContent {
  margin-top: 60px;
  padding: 40px 0;
  background: #F5F5F5;
  min-height: 450px;
}

@media screen and (max-width: 767px) {
  .pageContent {
    padding: 1em 0;
  }
}
