/* Mixin */
:-webkit-input-placeholder {
    color: #ccc;
}

::-webkit-input-placeholder {
    color: #ccc;
}

:-ms-input-placeholder {
    color: #ccc;
}

::-ms-input-placeholder {
    color: #ccc;
}

::placeholder {
    color: #ccc;
}

:-moz-placeholder {
    color: #ccc;
}

:-moz-placeholder {
    color: #ccc;
}

:-ms-input-placeholder {
    color: #ccc;
}

.flex {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
}

@-webkit-keyframes hoverEffect {
    0% {
        transform: scaleX(0);
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
    }
    50% {
        transform: scaleX(1);
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
    }
    100% {
        opacity: 0;
    }
}

@keyframes hoverEffect {
    0% {
        transform: scaleX(0);
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
    }
    50% {
        transform: scaleX(1);
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes animArrow {
    0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }
}

@keyframes animArrow {
    0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }
    50% {
        transform: translateX(5px);
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
    }
    100% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }
}

@-webkit-keyframes animArrowRev {
    0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }
    50% {
        transform: translateX(-5px);
        -webkit-transform: translateX(-5px);
        -moz-transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }
}

@keyframes animArrowRev {
    0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }
    50% {
        transform: translateX(-5px);
        -webkit-transform: translateX(-5px);
        -moz-transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

*:focus, *:hover {
    outline: none;
}

a {
    text-decoration: none;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
}

a:hover {
    text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul, li {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after {
    content: '';
    content: none;
}

q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
.activity-data-sec {
    margin-top: 60px;
    background: #F5F5F5;
    padding: 25px 0px;
}

.activity-data-sec .headerFlex {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0px auto 20px;
}

.activity-data-sec .headerFlex .tabs {
    width: calc(100% - 176px);
}

.activity-data-sec .headerFlex .tabs ul {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0px 0px;
    padding: 0px 15px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    background: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
}

.activity-data-sec .headerFlex .tabs ul li {
    list-style: none;
    margin-right: 20px;
}

.activity-data-sec .headerFlex .tabs ul li a {
    text-decoration: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    font-size: 18px;
    color: #3E3E3E;
    height: 100%;
    padding: 10px 10px;
}

.activity-data-sec .headerFlex .tabs ul li a span.icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: absolute;
    right: -5px;
    top: 5px;
}

.activity-data-sec .headerFlex .tabs ul li a span.icon svg {
    fill: #3E3E3E;
}

.activity-data-sec .headerFlex .tabs ul li a span.icon svg .a {
    fill: #3E3E3E;
}

.activity-data-sec .headerFlex .tabs ul li a:hover {
    color: #C0321F;
}

.activity-data-sec .headerFlex .tabs ul li a:hover::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background: #C0321F;
}

.activity-data-sec .headerFlex .tabs ul li a.selected {
    color: #C0321F;
}

.activity-data-sec .headerFlex .tabs ul li a.selected::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background: #C0321F;
}

.activity-data-sec .headerFlex .tabs ul li a.selected span svg path {
    fill: #C0321F !important;
}

.activity-data-sec .headerFlex .tabs ul li:last-child {
    margin-right: 0px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox {
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #00000038;
    padding: 0px 10px 0px 0px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.menuBtn .btn {
    border: none;
    outline: none;
    padding: 5px 5px;
    min-height: 20px;
    min-width: 20px;
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: transparent;
    margin: 10px 15px 10px 0px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.menuBtn .btn svg .a {
    fill: #343434;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.label span {
    color: #C0321F;
    display: inline-block;
    font-size: 14px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 0;
    height: 100%;
    left: -300px;
    width: 100%;
    max-width: 250px;
    overflow-y: auto;
    background: #ffffff;
    z-index: 999;
    border-right: 1px solid #ccc;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu .closeBtnBox {
    text-align: right;
    padding: 15px 15px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu .closeBtnBox button.closebtn {
    background: transparent;
    border: none;
    outline: none;
    padding: 5px 5px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu .closeBtnBox button.closebtn svg .a {
    opacity: 0.7;
    fill: #3E3E3E;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul {
    display: block;
    padding: 0px 15px;
    border: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    height: auto;
    max-height: calc(100% - 60px);
    overflow-y: auto;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li {
    list-style: none;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    margin-right: 0px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a {
    text-decoration: none;
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    border: none;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    line-height: 22px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a span.icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-top: -10px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a.selected {
    color: #C0321F;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a::after {
    display: none;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a:hover {
    color: #C0321F;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li:last-child {
    margin-bottom: 0px;
    border-bottom: none;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu::after {
    content: "";
    display: inline-block;
    position: fixed;
    width: 0px;
    left: 0px;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu.expandMenu {
    left: 0px;
}

.activity-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu.expandMenu::after {
    content: "";
    display: inline-block;
    position: fixed;
    width: calc(100% - 250px);
    left: 250px;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.activity-data-sec .headerFlex .prefer a {
    text-decoration: none;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    color: #3E3E3E;
    padding: 15px 15px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    margin-left: 10px;
}

.activity-data-sec .headerFlex .prefer a span {
    display: inline-block;
}

.activity-data-sec .headerFlex .prefer a span.icon {
    margin-right: 10px;
}

.activity-data-sec .headerFlex .prefer a span.icon svg {
    fill: #3E3E3E;
}

.activity-data-sec .headerFlex .prefer a span.icon svg .a {
    fill: #3E3E3E;
}

.activity-data-sec .activity-box-wrap {
    background: #ffffff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

.activity-data-sec .activity-box-wrap .activity-main-title {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
}

.activity-data-sec .activity-box-wrap .activity-gray-box {
    background: #F5F5F5;
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 20px;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-check-box p.checkbox-list span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-check-box p.checkbox-list span input[type="checkbox"] {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-check-box p.checkbox-list span input[type="checkbox"]:checked + label::before {
    border-color: #00B9FF;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-check-box p.checkbox-list span input[type="checkbox"]:checked + label::after {
    width: 12px;
    height: 12px;
    background: url(../../payment/assets/img/checkBlue.png) no-repeat;
    background-size: cover;
    content: "";
    display: inline-block;
    position: absolute;
    left: 3px;
    top: 5px;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-check-box p.checkbox-list span label {
    position: relative;
    font-size: 14px;
    color: #3E3E3E;
    font-weight: normal;
    padding-left: 30px;
    cursor: pointer;
    line-height: 18px;
    color: #3E3E3E;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-check-box p.checkbox-list span label::before {
    width: 18px;
    height: 18px;
    border: 1px solid #3E3E3E;
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li {
    padding: 0 20px;
    border-right: 1px solid #ccc;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li:last-child {
    border-right: none;
    padding-right: 0;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li span {
    display: block;
    margin-bottom: 5px;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li span.count {
    font-size: 1.125rem;
    font-weight: 500;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li span:last-child {
    margin-bottom: 0;
}

.activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li:hover {
    color: #C0321F;
}

.activity-data-sec .activity-box-wrap .filter-wrap {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li {
    margin-right: 12px;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li:nth-child(3) {
    margin-right: 25px;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li.title {
    font-size: 0.875rem;
    color: #000;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li button {
    padding: 10px 10px;
    background: #ffffff;
    font-size: 0.8125rem;
    font-weight: 300;
    color: #3E3E3E;
    border: 1px solid #00000038;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    outline: none;
    cursor: pointer;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter form {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter form label {
    font-size: 0.875rem;
    color: #000;
    margin-right: 10px;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter form label span.icon {
    margin-right: 10px;
}

.activity-data-sec .activity-box-wrap .filter-wrap .sortFilter form select {
    background-color: #ffffff;
    padding: 7px 25px 7px 8px;
    font-size: 0.875rem;
    color: #3E3E3E;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../../editUserProfile/assets/img/arrowdown.png);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 95% center;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    min-width: 130px;
    line-height: 20px;
    outline: none;
}

.activity-data-sec .activity-box-wrap .activity-listing {
    padding-bottom: 15px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px 15px 15px 5px;
    background: #ffffff;
    border: 1px solid #ccc;
    -webkit-box-shadow: 0px 5px 15px #CCCCCC26;
    box-shadow: 0px 5px 15px #CCCCCC26;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    margin-bottom: 20px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 65%;
    margin-right: 15px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .wishlist-icon {
    margin-right: 10px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .wishlist-icon svg .a {
    fill: transparent;
    stroke: #C0321F;
    stroke-width: 1.5px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .wishlist-icon svg.favorate .a {
    fill: #C0321F;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .activity-logo {
    margin-right: 10px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box {
    font-size: 0.6875rem;
    color: #3E3E3E;
    font-weight: 300;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .title-box-flex {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .title-box-flex ul li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    background: #ccc;
    padding: 5px 10px;
    font-size: 0.8125rem;
    font-weight: 500;
    color: #000;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box h3 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #000;
    margin-right: 10px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .address-info {
    margin-bottom: 6px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .address-info li {
    display: inline-block;
    vertical-align: middle;
    padding-left: 15px;
    margin-right: 10px;
    position: relative;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .address-info li:first-child {
    padding-left: 0;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .address-info li:first-child:after {
    display: none;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .address-info li:after {
    content: '';
    background: #3E3E3E;
    width: 5px;
    height: 5px;
    position: absolute;
    left: 0;
    top: 3px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    z-index: 1;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: calc(100% - 65%);
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li {
    padding: 0 10px;
    font-size: 0.75rem;
    font-weight: 300;
    color: #3E3E3E;
    text-align: center;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li:last-child {
    padding-right: 0;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li:nth-child(2) {
    margin-right: 15px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li.percentage span {
    font-size: 1rem;
    font-weight: 500;
    color: #1CB79E;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li span {
    display: block;
    margin-bottom: 5px;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li span.count {
    font-size: 0.875rem;
    font-weight: 600;
    color: #000;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li span:last-child {
    margin-bottom: 0;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li:hover {
    color: #C0321F;
}

.activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li:hover span.count {
    color: #C0321F;
}

.activity-data-sec .activity-box-wrap .paginationBox {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.activity-data-sec .activity-box-wrap .paginationBox div.perPage {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 120px;
}

.activity-data-sec .activity-box-wrap .paginationBox div.perPage input {
    border: 1px solid #ccc;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 5px 5px;
    width: 35px;
    height: 26px;
    font-size: 14px;
    color: #3E3E3E;
    text-align: center;
}

.activity-data-sec .activity-box-wrap .paginationBox div.perPage label {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #3E3E3E;
    font-size: 14px;
    margin-left: 10px;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav {
    width: calc(100% - 240px);
    text-align: center;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul {
    margin: 0px 0px;
    padding: 0px 0px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border: none;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li {
    list-style: none;
    border-right: 1px solid #ccc;
    width: 26px;
    height: 26px;
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    color: #626262;
    cursor: pointer;
    width: 26px;
    height: 26px;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li span:hover {
    background-color: #00B9FF;
    color: #ffffff;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li span:hover svg {
    fill: #ffffff;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li.backLink svg {
    width: 10px;
    height: auto;
    fill: #626262;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li.forLink svg {
    width: 10px;
    height: auto;
    fill: #626262;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li:last-child {
    border-right: none;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li.active span {
    background-color: #00B9FF;
    color: #ffffff;
}

.activity-data-sec .activity-box-wrap .paginationBox div.pageNav ul li.active span svg {
    fill: #ffffff;
}

.activity-data-sec .activity-box-wrap .paginationBox div.goBox {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 110px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.activity-data-sec .activity-box-wrap .paginationBox div.goBox input {
    border: 1px solid #ccc;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 5px 5px;
    width: 35px;
    height: 26px;
    font-size: 14px;
    color: #3E3E3E;
    text-align: center;
}

.activity-data-sec .activity-box-wrap .paginationBox div.goBox .inline-box {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #3E3E3E;
    font-size: 14px;
    margin-left: 10px;
    padding: 5px 5px;
    text-decoration: none;
    cursor: pointer;
}

.activity-data-sec .activity-box-wrap .paginationBox div.goBox .inline-box span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.activity-data-sec .activity-box-wrap .paginationBox div.goBox .inline-box span.icon {
    margin-left: 5px;
}

.activity-data-sec .activity-box-wrap .paginationBox div.goBox .inline-box span.icon svg {
    fill: #3E3E3E;
}

.activity-data-sec .activity-box-wrap .paginationBox div.goBox .inline-box span.icon svg .a {
    opacity: 1;
    fill: #3E3E3E;
}

@media screen and (max-width: 1199px) {
    .activity-data-sec .activity-box-wrap {
        padding: 15px;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .title-box-flex ul li {
        font-size: 0.6875rem;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .title-box-flex ul li:last-child {
        margin-right: 0;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box h3 {
        font-size: 0.875rem;
    }
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li span.count {
        font-size: 0.9375rem;
    }
}

@media screen and (max-width: 991px) {
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li {
        margin-right: 10px;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li:nth-child(3) {
        margin-right: 10px;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info {
        width: 58%;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info p {
        line-height: 1.4;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .wishlist-icon {
        margin-right: 5px;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .title-box-flex ul li {
        margin-bottom: 5px;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box {
        width: calc(100% - 58%);
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li {
        padding-left: 5px;
        padding-right: 5px;
    }
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li {
        padding: 0 10px;
    }
}

@media screen and (max-width: 767px) {
    .activity-data-sec .headerFlex .tabs ul {
        display: none;
    }
    .activity-data-sec .headerFlex .tabs .mobileMenuBox {
        display: -webkit-box;
        display: -ms-box;
        display: -ms-flexbox;
        display: flex;
    }
    .activity-data-sec .headerFlex .prefer a {
        padding: 12px 10px;
    }
    .activity-data-sec .activity-box-wrap .activity-listing {
        padding-bottom: 0;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item {
        display: block;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info {
        width: 100%;
        margin-bottom: 10px;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box {
        display: block;
        width: 100%;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-info-box .flexBox li:nth-child(2) {
        margin-right: 0;
    }
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li {
        padding: 0 10px;
        font-size: 12px;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap {
        display: block;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter {
        margin-bottom: 15px;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter form select {
        width: calc(100% - 85px);
    }
}

@media screen and (max-width: 579px) {
    .activity-data-sec .headerFlex .tabs {
        width: calc(100% - 55px);
    }
    .activity-data-sec .headerFlex .tabs .mobileMenuBox div.menuBtn .btn {
        margin: 9px 15px 9px 10px;
    }
    .activity-data-sec .headerFlex .prefer a {
        padding: 10px 10px;
        min-width: 42px;
        width: 42px;
        height: 42px;
        min-height: 42px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .activity-data-sec .headerFlex .prefer a span {
        display: none;
    }
    .activity-data-sec .headerFlex .prefer a span.icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin-right: 0px;
    }
    .activity-data-sec .activity-box-wrap {
        padding: 15px 10px;
    }
    .activity-data-sec .activity-box-wrap .activity-gray-box {
        display: block;
        padding: 15px 15px;
    }
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-check-box {
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
    }
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-check-box p.checkbox-list span label {
        padding-left: 28px;
        font-size: 0.75rem;
    }
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox {
        flex-wrap: wrap;
        justify-content: center;
    }  
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li {
        margin-bottom: 10px;
    }
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li:first-child {
        padding-left: 0;
    }
    .acti
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box .title-box-flex {
        display: block;
    }
    .activity-data-sec .activity-box-wrap .activity-listing .list-item .activity-detail-info .detail-box h3 {
        font-size: 0.8125rem;
        margin-right: 0;
        margin-bottom: 5px;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li {
        margin-right: 5px;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li:nth-child(3) {
        margin-right: 5px;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li button {
        padding: 8px;
    }
    .activity-data-sec .activity-box-wrap .paginationBox div.perPage {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .activity-data-sec .activity-box-wrap .paginationBox div.goBox {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin-left: auto;
    }
    .activity-data-sec .activity-box-wrap .paginationBox div.pageNav {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        width: 100%;
        margin-top: 10px;
    }
}

@media screen and (max-width: 479px) {
    .activity-data-sec .activity-box-wrap .activity-gray-box .activity-info-box .flexBox li {
        padding-left: 5px;
        padding-right: 5px;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul {
        flex-wrap: wrap;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li {
        margin-bottom: 10px;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li.title {
        display: block;
        width: 100%;
    }
    .activity-data-sec .activity-box-wrap .filter-wrap .sortFilter ul li:last-child {
        margin-bottom: 0;
    }
}