@import "../../../../../../../../assets/css/variables";
@import "../../../../../../../../assets/css/mixin";
@import "../../../../../../../../assets/css/reset";

.plan-first-sec {
  background: $white;
  padding: 60px 0 45px;
  margin-top: 60px;
  .top-block {
    margin-bottom: 35px;
    text-align: center;
    .tagline {
      @include font(18);
      font-weight: 400;
      color: $black;
      margin-bottom: 20px;
    }
    .sec-title {
      @include font(54);
      font-weight: 700;
      color: $blackColor;
      letter-spacing: -1.62px;
      line-height: 1.1;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    p {
      @include font(24);
      color: rgba($blackColor, .6);
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 0;
    }
    .pricing-unlock-feature {
      @include flex();
      justify-content: center;
      align-items: center;
      max-width: 700px;
      margin: 0 auto;
      li {
        padding:30px 20px 0;
        a {
          display: block;
          text-decoration: none;
          @include font(18);
          font-weight: 500;
          color: rgba($blackColor, .6);
          line-height: 1.4;
          @include transition();
          span {
            display: block;
            &.icon-box {
              margin-bottom: 15px;
            }
          }
          &:hover {
            color: $baseColor;
          }
        }
      }
    }
  }
}
.plans-list-sec {
  background:#FAFAFA;
  padding: 60px 0 45px;
  .top-block {
    max-width: 1020px;
    margin: 0 auto 20px;
    text-align: center;
    .title {
      @include font(24);
      font-weight: 600;
      color:$blackColor;
      line-height: 40px;
      margin-bottom: 15px;
    }
    .sub-title {
      @include font(18);
      font-weight: 500;
      color:$lightBlue;
      line-height: 25px;
      margin-bottom: 15px;
    }
    .number-payment-type {
      @include flex();
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
      .item {
        display: inline-block;
        vertical-align: middle;
        padding: 10px 20px;
        @include font(16);
        font-weight: 400;
        color: rgba($blackColor, .6);
        box-shadow: 0px 5px 8px #A8A8A83B;
        border: 1px solid #8E8E8E33;
        @include radius(50px);
        margin: 5px 6px;
        &.active {
          background:$lightBlue;
          color:$white;
          border-color: $lightBlue;
          box-shadow: 0px 5px 8px #A8A8A83B;
        }
      }
    }
    .plans-listing {
      .item {
        padding: 25px 15px 25px;
        .plan-detail {
          background:$white;
          padding: 30px;
          box-shadow: 0px 20px 20px #B4B4B466;
          @include radius(12px);
          .plan-type {
            @include font(20);
            font-weight: 600;
            color:$black;
            margin-bottom: 20px;
          }
          .price {
            @include font(42);
            font-weight: 800;
            color:#242222;
            margin-bottom: 10px;
            sub {
              display: inline-block;
              vertical-align: 8px;
              @include font(18);
              color:$black;
            }
          }
          .total-price {
            @include font(20);
            font-weight: 600;
            color:$black;
          }
          .short-text {
            padding-top: 15px;
            margin: 20px 0 25px;
            @include font(16);
            font-weight: 400;
            color:#737373;
            line-height: 30px;
            border-top: 1px solid rgba($gray, .4);
            p {
              margin-bottom: 0;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .plan-button {
            margin-bottom: 20px;
            .btn {
              display: block;
              padding: 10px 15px;
              @include font(16);
              font-weight: 400;
              color: $white;
              @include radius(4px);
              cursor: pointer;
            }
          }
          .view-feature-text {
            @include font(15);
            color:$black;
            text-align: center;
          }
        }
        &.active {
          .plan-detail {
            margin-top: -20px;
            background:#F1FBFF;
            padding-top: 50px;
            padding-bottom: 50px;
            .plan-type, .price {
              color:$baseColor;
            }
          }
        }
      }
      .owl-nav {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 1;
        margin-top: -15px;
        button {
          background: $white;
          width: 30px;
          height: 30px;
          @include radius(50px);
          box-shadow: 0 4px 10px #00000082;
        }
        button.owl-next {
          float: right;
          position: absolute;
          right: 0;
          outline: none;
          span {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            right: 2px;
            left: auto;
            top: -6px;
          }
        }
        button.owl-prev {
          float: left;
          position: absolute;
          left:0;
          outline: none;
        }
        button span {
          content: '';
          display: inline-block;
          position: relative;
          width: 8px;
          height: 8px;
          font-size: 0;
          border-right: 2px solid $black;
          border-bottom: 2px solid $black;
          transform: rotate(-0deg);
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          left: 2px;
          top: -6px;
          @include transition();
        }
      }
    }
  }
}
.plan-feature-sec {
  background: $white;
  padding: 60px 0;
  .sec-title {
    @include font(42);
    font-weight: 700;
    color:$blackColor;
    margin-bottom: 30px;
    text-align: center;
  }
  .plan-feature-table {
    .table {
      @include font(16);
      font-weight: 400;
      color: #737373;
      line-height: 1.5;
      border-color: rgba($gray, .4)!important;
      td, th {
        width: 22%;
        border-color: rgba($gray, .4)!important;
        text-align: center;
        &:first-child {
          text-align: left;
          width: 34%;
        }
        &.title {
          font-weight: 600;
          color:$baseColor;
          text-transform: uppercase;
        }
        .btn {
          display: block;
          width: 100%;
          padding: 10px 15px;
          background:#F5F5F5;
          @include font(16);
          font-weight: 500;
          color:$black;
          border: 1px solid #F9F9F9;
          @include radius(4px);
          cursor: pointer;
        }
        &.active {
          background:#F1FBFF;
          .btn {
            background: $baseColor;
            border-color:$baseColor;
            color:$white;
          }
        }

      }
      th {
        @include font(18);
        font-weight: 600;
        color:$black;
      }
    }
  }
}
.plans-main-sec {
  background: $white;
  padding: 60px 0;
  margin-top: 60px;

  .form-check {
    @include flex();
    align-items: center;
    justify-content: center;
    padding-left: 0;
    .form-check-label {
      display: inline-block;
      vertical-align: middle;
      @include font(18);
      font-weight: 500;
      color: $black;
      margin:0 8px;
      cursor: pointer;
      span {
        display: block;
        @include font(14);
        font-weight: 400;
        color: $green;
        margin-top: 5px;
      }
    }
    &.form-switch {
      .form-check-input {
        background-image: none;
        background: rgba($gray, .50%);
        position: relative;
        width: 38px;
        height: 15px;
        border: none;
        margin: 0;
        appearance: none;
        border-width: 0;
        @include radius(50px);
        @include transition();
        cursor: pointer;
        &:after {
          content: "";
          background:$green;
          position: absolute;
          width: 20px;
          height: 20px;
          right: 0;
          top: -3px;
          border: none;
          border-radius: 50%;
          z-index: 2;
          @include transition();
        }
      }
      input:checked[type="checkbox"]:after {
        left: 0 !important;
        right: auto !important;
      }
    }
  }
  .plans-list-with-content {
    @include flex();
    justify-content: space-between;
    padding: 20px 15px;
    background: url(../img/plan_bg.png) no-repeat;
    background-size: cover;
    background-position: top center;
    overflow: hidden;
    @include radius(5px);
    .plan-text-box {
      width: 23%;
      margin-right: 2%;
      @include font(14);
      font-weight: 300;
      color: $white;
      line-height: 1.6;
    }
    .plans-listing {
      width: calc(100% - 25%);
      .plan-item {
        position: relative;
        background: $white;
        border-top: 25px solid #10567A;
        box-shadow: 0 3px 6px #00000017;
        text-align: center;
        @include radius(10px);
        .plan-type {
          position: absolute;
          @include font(13);
          font-weight: 400;
          color: $white;
          left: 15px;
          top:-19px;
          z-index: 1;
        }
        .plan-detail {
          padding: 10px 20px 15px;
          @include font(13);
          color: rgba($black, .60%);
          line-height: 1.4;
          .title {
            @include font(17);
            color: $blackColor;
            font-weight: 600;
            margin-bottom: 12px;
            min-height: 40px;
          }
          .sub-title {
            max-width: 132px;
            margin: 0 auto 15px;
          }
          .price {
            @include font(14);
            font-weight: 400;
            color: rgba($black, .50%);
            margin-bottom: 12px;
            span {
              display: inline-block;
              vertical-align: middle;
              @include font(40);
              font-weight: 500;
              color: $blackColor;
              line-height: 35px;
            }
            sup {
              display: inline-block;
              vertical-align: 8px;
            }
            sub {
              display: inline-block;
              vertical-align: -5px;
            }
          }
          .short-text {
            margin-bottom: 10px;
          }
          .expert-button-box {
            a {
              display: inline-block;
              vertical-align: middle;
              text-decoration: none;
              padding: 5px;
              @include font(14);
              font-weight: 500;
              color: #10567A;
              border: 1px solid #10567A;
              @include radius(4px);
              @include transition();
              &:hover {
                border-color:$lightBlue;
                color:$lightBlue;
              }
            }
          }
        }
        .plan-info {
          h4 {
            @include font(15);
            font-weight: 500;
            color: $black;
            margin-bottom: 10px;
          }
          ul {
            border-bottom: 1px solid rgba($gray, .40%);
            li {
              padding: 8px 10px;
              @include font(13);
              color: rgba($black, .50%);
              line-height: 1.4444;
              border-top: 1px solid rgba($gray, .40%);
            }
          }
        }
        .plan-bottom-box {
          padding: 15px;
          .plan-btn {
            display: block;
            text-decoration: none;
            background: $bgLightGray;
            padding: 12px 15px;
            @include font(14);
            font-weight: 500;
            color: rgba($black, .60%);
            border: 1px solid $gray;
            @include radius(4px);
            @include transition();
            &:hover {
              background: $lightBlue;
              border-color:$lightBlue;
              color: $white;
            }
          }
        }
      }
      .owl-stage {
        .owl-item {
          &:nth-child(2n+2) {
            .plan-item {
              border-color:#49883C;
              .plan-detail {
                .expert-button-box {
                  a {
                    border-color:#49883C;
                    color: #49883C;
                    &:hover {
                      border-color:#49883C;
                      color: #49883C;
                    }
                  }
                }
              }
              .plan-bottom-box {
                .plan-btn {
                  background: #49883C;
                  border-color:#49883C;
                  color:$white;
                  &:hover {
                    background: #448237;
                    border-color:#448237;
                  }
                }
              }
            }
          }
          &:nth-child(3n+3) {
            .plan-item {
              border-color:#d1af4a;
              .plan-detail {
                .expert-button-box {
                  a {
                    border-color:#d1af4a;
                    color: #d1af4a;
                    &:hover {
                      border-color:$yellow;
                      color:$yellow;
                    }
                  }
                }
              }
              .plan-bottom-box {
                .plan-btn {
                  background: #d1af4a;
                  border-color:#d1af4a;
                  color:$white;
                  &:hover {
                    background: $yellow;
                    border-color:$yellow;
                  }
                }
              }
            }
          }
        }
      }
      .owl-dots {
        margin-top: 20px;
        text-align: center;
        button {
          display: inline-block;
          vertical-align: middle;
          border: none;
          outline: none;
          span {
            display: block;
            background: $white;
            width: 12px;
            height: 12px;
            border: 1px solid $white;
            border-radius: 50%;
            opacity: 0.6;
            margin: 3px 5px;
            -webkit-transition: opacity .2s ease;
            transition: opacity .2s ease;
          }
          &.active {
            span {
              opacity: 1;
            }
          }
        }
      }
      .owl-nav {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
        z-index: 1;
        margin-top: -15px;
        button {
          background: $white;
          width: 30px;
          height: 30px;
          @include radius(50px);
          box-shadow: 0 4px 10px #00000082;
        }
        button.owl-next {
          float: right;
          position: absolute;
          right: -15px;
          outline: none;
          span {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            right: 2px;
            left: auto;
            top: -5px;
          }
        }
        button.owl-prev {
          float: left;
          position: absolute;
          left: -15px;
          outline: none;
        }
        button span {
          content: '';
          display: inline-block;
          position: relative;
          width: 8px;
          height: 8px;
          font-size: 0;
          border-right: 2px solid $black;
          border-bottom: 2px solid $black;
          transform: rotate(-0deg);
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          left: 2px;
          top: -5px;
          @include transition();
        }
      }
    }
  }
  .plan-feature-sec {
    padding-top: 30px;
    padding-bottom: 30px;
    .sec-title {
      @include font(42);
      font-weight: 700;
      color:$blackColor;
      margin-bottom: 30px;
      text-align: center;
    }
    h4 {
      @include font(20);
      font-weight: 500;
      color: $blackColor;
      margin-bottom: 10px;
    }
    .plan-feature-flex {
      @include flex();
      align-items: center;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      ul {
        background: #10567A;
        width: 23.875%;
        margin-right: 1.5%;
        @include font(15);
        font-weight: 400;
        color: $white;
        line-height: 1.4;
        text-align: center;
        border: 1px solid rgba($gray, .20%);
        @include radius(4px);
        &:first-child {
          background: $white;
          color: $black;
          text-align: left;
          li {
            border-bottom: 1px dotted rgba($gray, .30%);
            justify-content: flex-start;
            &:last-child {
              border-bottom: none;
            }
            svg {
              display: inline-block;
              vertical-align: middle;
              margin-left: 5px;
              path {
                fill:$blue;
                fill-rule:evenodd;
              }
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &:nth-child(3) {
          background: #49883C;
        }
        &:nth-child(4) {
          background: #d1af4a;
        }
        li {
          @include flex();
          justify-content: center;
          align-items: center;
          padding: 6px 10px;
          border-bottom: 1px dotted rgba($white, .30%);
          min-height: 60px;
          &:last-child {
            border-bottom: none;
          }
          svg {
            path {
              fill:$white;
              fill-rule:evenodd;
            }
          }
        }
      }
    }
  }
}
.testimonial-sec {
  background: url(../img/testimonial-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 60px;
  text-align: center;
  .top-block {
    max-width: 580px;
    @include font(16);
    font-weight: 300;
    color: $white;
    line-height: 1.5;
    margin: 0 auto 30px;
    .title {
      @include font(24);
      font-weight: 500;
      color: $white;
      margin-bottom: 15px;
    }
    p {
      margin-bottom: 0;
    }
  }
  .slide-item {
    padding: 20px 25px;
    background: $white;
    box-shadow: 0 0 4px #00000029;
    @include radius(5px);
    .testimonial-img {
      @include flex();
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border: 1px solid #F2F2F2;
      @include radius(50%);
      margin: 0 auto 10px;
      img {
        display: block;
        width: 100px;
        height: 100px;
        object-fit: cover;
        @include radius(50%);
      }
    }
    .testimonial-detail {
      @include font(14);
      font-weight: 300;
      color: $black;
      letter-spacing: 0.21px;
      line-height: 1.5;
      .short-text {
        position: relative;
        margin-bottom: 20px;
        &:after {
          content:"";
          background: url(../img/quote-iocn-left.png) no-repeat;
          position: absolute;
          width: 80px;
          height: 65px;
          left: 0;
          top: -15px;
          z-index: 1;
        }
        &:before {
          content:"";
          background: url(../img/quote-iocn-right.png) no-repeat;
          background-size: 31px 21px;
          position: absolute;
          width: 31px;
          height: 21px;
          right: 0;
          bottom: -10px;
          z-index: 1;
        }
        p {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .name {
        @include font(15);
        font-weight: 500;
        letter-spacing: 0.23px;
        margin-bottom: 5px;
      }
      .designation {
        @include font(12);
        font-weight: 300;
        color: #ABABAB;
        letter-spacing: 0.18px;
      }
    }
  }
  .testimonial-slider {
    .owl-nav {
      display: none;
    }
    .owl-dots {
      display: block;
      text-align: center;
      margin-top: 20px;
      button {
        display: inline-block;
        vertical-align: middle;
        border: none;
        outline: none;
        span {
          display: block;
          background: $white;
          width: 12px;
          height: 12px;
          margin: 3px 5px;
          border: 1px solid $white;
          border-radius: 50%;
          opacity: 0.16;
          transition: opacity .2s ease;
        }
        &.active {
          span {
            opacity: 1;
          }
        }
      }
    }
  }
}
.athlete-faq-sec {
  padding: 60px 0;
  &.gray-bg {
    background:#FAFAFA;
  }
  .sec-title {
    @include font(42);
    font-weight: 700;
    color: $blackColor;
    margin-bottom: 30px;
  }
  .faq-listing {
    counter-reset: faq;
    li {
      @include transition();
      margin-bottom: 15px;
      border-bottom: 1.5px solid rgba($gray, .50%);
      padding-bottom: 20px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .faq-title {
        @include font(18);
        color: $blackColor;
        position: relative;
        padding-right: 30px;
        line-height: 1.4;
        cursor: pointer;
        span {
          display: inline-block;
          vertical-align: middle;
          color:$baseColor;
          margin-right: 5px;
        }
        &:after {
          content: '+';
          font-size: 35px;
          color: $blackColor;
          display: block;
          width: 28px;
          height: 28px;
          line-height: 28px;
          position: absolute;
          right: -5px;
          top: -5px;
          text-align: center;
          opacity: .15;
        }
        &.active {
          color:$baseColor;
          &:after {
            content: "";
            font-size: 0;
          }
          &:before {
            content: "";
            width: 18px;
            position: absolute;
            background:$baseColor;
            height: 2.8px;
            right: 0;
            top: 7px;
          }
        }
      }
      .faq-data {
        @include font(16);
        color: $black;
        display: none;
        padding-top: 15px;
        line-height: 1.6;
      }
    }
  }
}
.content-and-recruiting-sec {
  padding: 60px 0;
}
.content-with-video-sec {
  @include flex();
  justify-content: space-between;
  padding-bottom: 50px;
  .help-box, .video-box {
    padding: 20px;
    background: $white;
    box-shadow: 0 0 4px #00000029;
    @include radius(10px);
    img {
      max-width: 100%;
    }
  }
  .help-box {
    position: relative;
    width: 325px;
    margin-right: 20px;
    .help-img {
      @include radius(4px);
      margin-bottom: 15px;
    }
    h3 {
      @include font(20);
      font-weight: 500;
      color: $black;
      line-height: 1.5;
      margin-bottom: 20px;
    }
    .button-box {
      .btn {
        background:$baseColor;
        padding: 10px 25px;
        @include font(18);
        color:$white;
        font-weight: 400;
        @include radius(4px);
        box-shadow: 1px 2px 5px #00000017;
      }
    }
    .owl-nav {
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 1;
      margin-top: -15px;
      button {
        background: $white;
        width: 30px;
        height: 30px;
        @include radius(50px);
        box-shadow: 0 4px 10px #00000082;
      }
      button.owl-next {
        float: right;
        position: absolute;
        right: -15px;
        outline: none;
        span {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          right: 2px;
          left: auto;
          top: -5px;
        }
      }
      button.owl-prev {
        float: left;
        position: absolute;
        left: -15px;
        outline: none;
      }
      button span {
        content: '';
        display: inline-block;
        position: relative;
        width: 8px;
        height: 8px;
        font-size: 0;
        border-right: 2px solid $black;
        border-bottom: 2px solid $black;
        transform: rotate(-0deg);
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        left: 2px;
        top: -5px;
        @include transition();
      }
    }
  }
  .video-box {
    width: calc(100% - 345px);
    h3 {
      @include font(20);
      font-weight: 500;
      color: $black;
      line-height: 1.5;
      margin-bottom: 20px;
    }
    .img-box {
      position: relative;
      height: 350px;
      @include radius(5px);
      video {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include radius(5px);
      }
      .video-play-button {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        cursor: pointer;
        .video-play-inner {
          @include flex();
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        button {
          @include flex();
          justify-content: center;
          align-items: center;
          background:$baseColor;
          width: 60px;
          height: 60px;
          @include radius(50%);
          outline: none;
          border: none;
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
  }
}
.recruiting-guidance-sec {
  @include flex();
  justify-content: space-between;
  align-items: center;
  background: $blue;
  padding: 40px 40px;
  @include radius(10px);
  .icon-box {
    flex: 0 0 150px;
    width: 150px;
    margin-right: 30px;
    img {
      max-width: 100%;
    }
  }
  .content-box {
    width:  calc(100% - 180px);
    @include font(16);
    font-weight: 300;
    color: $white;
    line-height: 1.5;
    .title {
      @include font(30);
      font-weight: 600;
      color: $white;
      margin-bottom: 15px;
    }
    .short-text {
      p {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          display: inline-block;
          vertical-align: middle;
          text-decoration: none;
          color: $white;
          font-weight: 500;
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }
}
.video-modal {
  .modal-content {
    border: none;
    -webkit-box-shadow: 0px 3px 20px #00000080;
    box-shadow: 0px 3px 20px #00000080;
  }
  .modal-body {
    padding: 15px;
    iframe {
      width: 100% !important;
      display: inline-block;
      vertical-align: middle;
      height: 255px !important;
      overflow: hidden;
    }
  }
}

@media screen and (max-width:1439px) {
  .plan-first-sec {
      .top-block {
          .sec-title {
              @include font(42);
          }
      }
  }
  .athlete-faq-sec {
      .sec-title {
          @include font(36);
      }
  }
}
@media screen and (max-width:1365px) {
  .plan-first-sec {
      .top-block {
          .sec-title {
              @include font(36);
          }
          p {
              @include font(18);
          }
          .pricing-unlock-feature {
              li {
                  a {
                      @include font(16); 
                  }
              }
          }
      }
  }
  .plan-feature-sec {
      .sec-title {
          @include font(36);
      }
  }
}
@media screen and (max-width:1199px) {
  .plan-first-sec {
      .top-block {
          .sec-title {
              @include font(30);
          }
      }
  }
  .plans-list-sec {
      .top-block {
          .plans-listing {
              .item {
                  .plan-detail {
                      .price {
                          @include font(30);
                      }
                  }
              }
          }
      }
  }
  .recruiting-guidance-sec {
      .content-box {
          .title {
              @include font(24);  
          }
      }
  }
  .plan-feature-sec {
      .sec-title {
          @include font(30);
      }
  }
  .athlete-faq-sec {
      .sec-title {
          @include font(30);
      }
  }
}
@media screen and (max-width: 991px) {
  .plan-first-sec {
      padding-top: 45px;
      .top-block {
          margin-bottom: 0;
          .sec-title {
              @include font(25);
          }
          .pricing-unlock-feature {
              li {
                  a {
                      span {
                          &.icon-box {
                              margin-bottom: 10px; 
                              svg {
                                  height: 100px;
                              }
                          }
                      }
                  }
              }
          }
      }
  }
  .plans-list-sec {
      padding: 45px 0;
      .top-block {
          .title {
              @include font(22); 
              line-height: 32px; 
          }
          .plans-listing {
              .item {
                  &.active, &:hover {
                      .plan-detail {
                          padding: 30px;
                          margin-top: 0;
                      }
                  }
              }
             
          }
      }
  }
  .plan-feature-sec {
      padding: 45px 0;
      .plan-feature-table {
          .table {
              @include font(15);
              th {
                  @include font(16);
              }
          }
      }
  }
  .testimonial-sec {
      padding: 35px 30px;
  }
  .content-with-video-sec {
      .video-box {
          h3 {
              @include font(18);
          }
          .img-box {
              height: 280px;
          }
      }
      .help-box {
          h3 {
              @include font(18); 
          }
      }
  }
  .recruiting-guidance-sec {
      padding: 30px 25px;
      .content-box {
          @include font(15);
          .title {
              line-height: 1.3;
          }
          .short-text {
              p {
                  margin-bottom: 10px;
              }
          }
      }
  }
  .athlete-faq-sec {
      padding: 45px 0;
      .sec-title {
          @include font(26);
      }
  }
}
@media screen and (max-width:767px) {
  .plan-first-sec {
      .top-block {
          .sec-title {
              @include font(22);
          }
          .pricing-unlock-feature {
              li {
                  padding-left: 10px;
                  padding-right: 10px;
                  a {
                      span {
                          &.icon-box {
                              svg {
                                  height: 90px;
                              }
                          }
                      }
                  }
              }
          }
      }
  }
  .plans-list-sec{
      .top-block {
          .title {
              @include font(20);
              line-height: 30px;
          }
      }
  }
  .plan-feature-sec {
      .sec-title {
          @include font(20); 
      }
  }
  .testimonial-sec {
      .top-block {
          .title {
              @include font(22);
          }
      }
  }
  .content-with-video-sec {
      display: block;
      text-align: center;
      .help-box {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          .button-box {
              .btn {
                  @include font(15);
              }
          }
          .owl-nav {
              button {
                  &.owl-next {
                      right: 5px;
                  }
                  &.owl-prev {
                      left: 5px;
                  }
              }
          }
      }
      .video-box {
          width: 100%;
      }
  }
  .recruiting-guidance-sec {
      display: block;
      text-align: center;
      .icon-box {
          width: auto;
          margin: 0 auto 15px;
      }
      .content-box {
          width: 100%;
          @include font(14);
          .title {
              @include font(22);
              margin-bottom: 12px;
          }
      }
  }
}
@media screen and (max-width:639px) {
  .plan-first-sec {
      padding: 40px 0;
      .top-block {
          .sec-title {
              @include font(20); 
              margin-bottom:10px;
          }
          p {
              @include font(15);  
          }
          .pricing-unlock-feature {
              li {
                  padding:20px 5px 0;
                  a {
                      @include font(13);
                      span {
                          &.icon-box {
                              svg {
                                  height: 60px;
                              }
                          }
                      }
                  }
              }
          }
      }
  }
  .plans-list-sec {
      padding: 35px 0 40px;
      .top-block {
          margin-bottom: 0;
          .title {
              @include font(18); 
          }
          .sub-title {
              @include font(16);
          }
          .number-payment-type {
              margin-bottom: 20px;
          }
          .plans-listing {
              .item {
                  padding-top: 0;
                  .plan-detail {
                      .price {
                          @include font(24); 
                      }
                  }
              }
          }
      }
  }
  .plan-feature-sec {
      padding: 35px 0 40px;
      .sec-title {
          @include font(20); 
          margin-bottom: 20px;
      }
      .plan-feature-table {
          overflow-x: scroll;
          &::-webkit-scrollbar {
              border-radius: 10px;
              width: 8px;
              height: 12px;
              background: rgba($gray, 0.3%);
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: rgba($gray, 0.8%);
              cursor: pointer;
          }
          .table {
              width: 760px;
          }
      }
  }
  .testimonial-sec {
      padding: 40px 15px;
      margin-bottom: 0;
      .top-block {
          margin-bottom: 20px;
          .title {
              @include font(20);
              line-height: 1.333;
              margin-bottom: 10px;
          }
          p {
              @include font(15);
          }
      }
  }
  .content-and-recruiting-sec {
      padding:40px 0;
  }
  .content-with-video-sec {
      padding-bottom: 35px;
      .help-box, .video-box {
          padding:20px 15px;
          h3 {
              @include font(16);
              max-width:350px;
              margin-left: auto;
              margin-right: auto;
          }
      }
      .video-box {
          .img-box {
              height: auto;
              .video-play-button {
                  button {
                      width: 40px;
                      height: 40px;
                      svg {
                          width: 12px;
                      }
                  }
              }
          }
      }
  }
  .recruiting-guidance-sec {
      padding: 25px 15px;
      .icon-box {
          svg {
              width:100px;
              height: 100px;
          }
      }
      .content-box {
          .title {
              @include font(18);
          }
      }
  }
  .athlete-faq-sec {
      padding: 35px 0;
      .sec-title {
          @include font(20);
      }
      .faq-listing {
          li {
              .faq-title {
                  @include font(17);
              }
          }
      }
  }
  .video-modal {
    .modal-body {
      iframe, video {
        object-fit: cover;
        height: 160px!important;
      }
    }
  }
}