// Variable
@import "./variables";
// Mixin
@import "./mixin";

.signIn{
    width: 100vw;
    height: 100vh;
    height:calc(var(--vh, 1vh) * 100);
    //background: url('../../assets/img/loginBG.jpg') center no-repeat;
    background-size: cover;
    overflow: auto;
    font-family: 'Montserrat', sans-serif !important;
    .flex{
        background: $white;
        height: 100vh;
        height:calc(var(--vh, 1vh) * 100);
        max-width: 560px;
        margin: 0 auto;
        padding:30px 70px;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
    }
    .home{
        @include flex();
        flex-direction: column;
        line-height: 1.5em;
        @include font(16);
        margin-bottom: auto;
     
        a{
            color: $baseColor;
            @include transition();
            display: inline-flex;
            align-items: center;
            svg{
                margin-right: 10px;
                path{
                    @include transition();
                }
            }
            &:hover{
                color: $black;
                svg{
                    path{
                        stroke: $black;
                    }
                }
            }
        }
   
        .logo{
            text-align: center;
            margin-top: 1em;
            margin-bottom: 1.3em;
            img{
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }
    }
    h1{
        @include font(24);
        color: $black;
        margin-bottom: 0.8em;
        font-weight: 600;
    }
    li{
        margin-bottom: 1.2em;
        position: relative;
        input[type=email],input[type=password],input[type=text]{
            border:1px solid $border;
            padding:15px 20px;
            width: 100%;
            @include font(18);
            @include radius(5px);
            &::placeholder{
                font-family: 'Montserrat', sans-serif;
            }
        }
        
        #showPwd{
            position: absolute;
            right: 10px;
            top:10px;
            border:0px;
            background: none;
            padding:10px;
            color:$baseColor;
            @include transition();
            @include radius(5px);
            @include font(16);
            font-weight: 400;
            &:focus, &:hover{
                outline: 0px;
                background: #eee;
                color:$black;
            }
        }
        input[type=checkbox]{
            position: absolute;
            opacity: 0;
            visibility: hidden;
            + label{
                position: relative;
                padding-left: 56px;
                cursor: pointer;
                @include transition();
                @include font(18);
                line-height: 1.5em;
                &:before{
                    content: '';
                    position: absolute;
                    left:0px;
                    top:50%;
                    @include translateY(-50%);
                    width: 46px;
                    height: 16px;
                    @include radius(10em);
                    background: $border;
                    @include transition();
                }
                &:after{
                    content: '';
                    position: absolute;
                    left:0px;
                    top:50%;
                    @include translateY(-50%);
                    width: 24px;
                    height: 24px;
                    @include radius(10em);
                    background: $black;
                    @include transition();
                }
            }
            &:checked{
                +label{
                    color:$black;
                    
                    &:before{
                        background: rgba($lightBlue,.5%);
                    }
                    &:after{
                        background: $lightBlue;
                        left:22px;
                    }
                }
            }
        }
        .btn{
            @include font(20);
            width: 100%;
        }
        &.forgotPwd{
            text-align: center;
            margin-top: 1.5em;
            a{
                color:$baseColor;
                @include font(20);
                font-weight: 600;
            }
        }
        &:nth-child(2) {
            input {
                border-right: none;
                border-radius: 5px 0 5px 0;
            }
        }
    }
    .link{
        margin-top: auto;
        p{
            text-align: center;
            font-weight: 600;
            @include font(18);
            color:$black;
            a{
                color:$baseColor;
            }
        }
    }
}
.modal {
    &.message-link-modal {
        .modal-content {
            padding: 20px;
            border: none;
            position: relative;
        }
        .modal-header {
            padding: 0 35px 10px 0;
            position: relative;
            border-bottom: 1px solid $border;
            margin-bottom: 20px;
            .modal-title {
                @include font(16);
                font-weight: 600;
                color: $black;
            }
        }
        .close {
            padding: 0;
            @include font(32);
            font-weight: 300;
            color: $black;
            outline: none;
            position: absolute;
            right: 0;
            top: 0;
            line-height: 15px;
            margin: 0;
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: 15px;
            }
        }
        .modal-body {
            padding: 0 0 20px;
            .fieldBox {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                label {
                  display: block;
                  @include font(14);
                  font-weight: 400;
                  color: $black;
                  margin-bottom: 12px;
                  sup {
                    top: 8px;
                  }
                }
                input {
                  @include font(14);
                  font-weight: 300;
                  color: $black;
                  width: 100%;
                  border: 1px solid $border;
                  @include radius(4px);
                  padding: 0 1em;
                  height: 45px;
                  box-shadow: none;
                }
            }
            p {
                @include font(14);
                font-weight: 400;
                color: $black;
                line-height: 26px;
                text-align: center;
                margin-bottom: 0;  
            }
        }
        .modal-footer {
            padding: 0;
            border: none;
            justify-content: center;
            text-align: center;
            .btn {
                background:$lightBlue;
                padding: 8px 15px;
                @include font(15);
                font-weight: 300;
                color:$white;
                min-width: 120px;
                margin:0;
            }
        }
    }
    &.thanksyou {
        .modal-content {
            padding:30px 40px;
            .close {
                right: 15px;
                top:20px;
            }
        }
    }
}
@media only screen and (max-width:580px){
    .signIn{
        .container{
            padding: 0px;
        }
        .flex{
            display: block;
            padding:40px;
        }
        .home {
            .logo {
                max-width: 160px;
                margin: 1em auto 1.2em;
            }
        }
        h1{
            @include font(20);
            margin-bottom: 20px;
        }
        li{
            margin-bottom: 1em;
            input[type=email], input[type=password], input[type=text], input[type=checkbox] + label {
                @include font(16);
            }
            &.forgotPwd{
                margin: 1.6em 0;
                a {
                    @include font(16);
                }
            }
            .btn {
                @include font(18);
            }
        }
        .link {
            p {
               @include font(15); 
            }
        }
    }
    .modal {
        &.thanksyou {
            .modal-content {
                padding:30px 25px;
                .close {
                    right: 10px;
                    top:15px;
                }
            }
        }
    }
}
@media only screen and (max-width:480px){
    .signIn{
        .flex{
            padding:20px;
        }
    }
}