/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.signUpAthleteInfoSection {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 40px 15px;
  min-height: calc(100vh - 120px);
  margin-top: 60px;
}

.signUpAthleteInfoSection .athleteInfoBlock {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection {
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info h1 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 25px;
  text-align: center;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul li {
  width: 165px;
  margin-right: 15px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul li:last-child {
  margin-right: 0;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul li a {
  display: block;
  text-decoration: none;
  background: rgba(233, 233, 233, 0.4);
  padding: 12px 10px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #3E3E3E;
  letter-spacing: 0.21px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  text-align: center;
  min-height: 90px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul li a span {
  display: block;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul li a span.icon {
  margin-bottom: 8px;
  min-height: 45px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul li.active a {
  background: #1CB79E;
  color: #ffffff;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul li.active a svg path {
  fill: #ffffff;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .heading {
  font-size: 1rem;
  color: #3E3E3E;
  font-weight: 500;
  margin-bottom: 20px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .heading .checkIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection .subHead {
  font-size: 1.125rem;
  font-weight: 600;
  color: #3E3E3E;
  text-align: center;
  margin: 0 0 30px 0;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div {
  margin-bottom: 20px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div input {
  padding: 10px 10px;
  font-size: 0.875rem;
  color: #3E3E3E;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div input::-webkit-input-placeholder {
  color: #3E3E3E;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div input:-ms-input-placeholder {
  color: #3E3E3E;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div input::-ms-input-placeholder {
  color: #3E3E3E;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div input::placeholder {
  color: #3E3E3E;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox label {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1rem;
  font-weight: 400;
  color: #3E3E3E;
  margin-bottom: 10px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox label sup {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #C0321F;
  margin-bottom: -10px;
  margin-left: 5px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox label + p {
  font-size: 0.8125rem;
  font-weight: 400;
  color: #3E3E3E;
  line-height: 1.3;
  margin-bottom: 10px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox select {
  padding: 14px 15px 14px 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  background-image: url(../Img/arrowdown.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 16px;
  font-size: 0.875rem;
  color: #3E3E3E;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox.flexBox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox.flexBox .half {
  width: calc(50% - 15px);
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox.flexBox .half input {
  width: 100%;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox.phone .flexBox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox.phone .flexBox .countryFlag {
  width: 35px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox.phone .flexBox .countryFlag img {
  max-width: 100%;
  height: auto;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox.phone .flexBox select {
  padding: 5px 15px 5px 5px;
  background: none;
  outline: none;
  border: none;
  color: #3E3E3E;
  font-size: 0.875rem;
  -webkit-appearance: button;
  -moz-appearance: button;
       appearance: button;
  width: 70px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox.phone .flexBox input {
  width: calc(100% - 125px);
  border: none;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox .shortText {
  font-size: 0.8125rem;
  font-weight: 400;
  color: #3E3E3E;
  line-height: 1.5;
  margin-bottom: -8px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox .shortText a {
  display: inline-block;
  vertical-align: middle;
  color: #C0321F;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.fieldBox .shortText a:hover {
  text-decoration: underline;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox h5 {
  font-weight: normal;
  font-size: 16px;
  color: #3E3E3E;
  line-height: 22px;
  margin: 0px 0px 15px 0px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox h5 span {
  display: inline-block;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox h5 sup {
  color: #C0321F;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: -10px;
  margin-left: 3px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox .radioflexBox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox .radioflexBox .option {
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox .radioflexBox .option input {
  opacity: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox .radioflexBox .option input:checked + label::before {
  border-color: #00B9FF;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox .radioflexBox .option input:checked + label::after {
  content: "";
  display: inline-block;
  background-color: #00B9FF;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 5px;
  top: 5px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox .radioflexBox .option label {
  position: relative;
  padding-left: 30px;
  font-size: 1rem;
  font-weight: normal;
  color: #3E3E3E;
  cursor: pointer;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.radioBox .radioflexBox .option label::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  border: 1px solid #3E3E3E;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.pleaseCheck {
  display: block;
  position: relative;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.pleaseCheck input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.pleaseCheck input:checked + label::before {
  border-color: #00B9FF;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.pleaseCheck input:checked + label::after {
  content: "";
  display: inline-block;
  background-image: url(../Img/checkBlue.png);
  background-repeat: no-repeat;
  background-size: 13px;
  position: absolute;
  width: 13px;
  height: 10px;
  left: 3px;
  top: 7px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.pleaseCheck label {
  display: block;
  position: relative;
  padding-left: 30px;
  line-height: 22px;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #3E3E3E;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.pleaseCheck label a {
  display: inline-block;
  vertical-align: middle;
  color: #C0321F;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.pleaseCheck label a:hover {
  text-decoration: underline;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.pleaseCheck label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 2px;
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.BtnBox {
  margin-top: 35px;
  margin-bottom: 20px;
  text-align: center;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.BtnBox .btn {
  background-color: #00B9FF;
  padding: 12px 35px;
  font-size: 0.8125rem;
  font-weight: 300;
  color: #ffffff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  cursor: pointer;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.bottomText {
  font-size: 1rem;
  font-weight: 500;
  color: #3E3E3E;
  text-align: center;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.bottomText a {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  color: #C0321F;
}

.signUpAthleteInfoSection .athleteInfoBlock .formSection form > div.bottomText a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 575px) {
  .signUpAthleteInfoSection {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection {
    padding: 20px 15px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info h1 {
    margin-bottom: 20px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection .athleteTab-info ul li a {
    font-size: 0.75rem;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection .heading {
    font-size: 0.9375rem;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.continueBtnBox a {
    width: 100%;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.BtnBox {
    margin-top: 20px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.bottomText {
    font-size: 0.9375rem;
    margin-bottom: 5px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.bottomText a {
    vertical-align: top;
  }
}

@media screen and (max-width: 479px) {
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.fieldBox.flexBox {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.fieldBox.flexBox .half {
    width: 100%;
    margin-bottom: 20px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.fieldBox.flexBox .half:last-child {
    margin-bottom: 0px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.fieldBox.phone .flexBox .countryFlag {
    width: 25px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.fieldBox.phone .flexBox select {
    width: 70px;
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.fieldBox.phone .flexBox input {
    width: calc(100% - 95px);
  }
  .signUpAthleteInfoSection .athleteInfoBlock .formSection form div.fieldBox label {
    font-size: 0.875rem;
  }
}
/*# sourceMappingURL=CoachSignUp.css.map */