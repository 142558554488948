@import "../../../../../../../../assets/css/variables";
@import "../../../../../../../../assets/css/mixin";
@import "../../../../../../../../assets/css/reset";

.account-main-sec {
    background: $bgLightGray;
    padding:25px 0 35px;
    margin-top: 60px;
    .account-with-sidebar {
        @include flex();
        .account-sidebar {
            width: 250px;
            margin-right: 20px;
            padding: 20px;
            background: $white;
            @include radius(4px);
            box-shadow: 0px 5px 15px #0000000d;
            height: 100%;
            min-height: 210px;
            ul {
                display: block;
                li {
                    display:block;
                    margin-bottom: 25px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: block;
                        text-decoration: none;
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        letter-spacing: 0.21px;
                        padding-left: 30px;
                        position: relative;
                        @include transition();
                        .icon {
                            display: inline-block;
                            vertical-align: middle;
                            position: absolute;
                            left: 0;
                            top: -2px;
                            z-index: 1;
                            svg {
                                @include transition();
                                path {
                                    @include transition();
                                }
                            }
                        }
                    }
                    &:hover, &.active {
                        a {
                            color: $baseColor;
                            .icon {
                                svg {
                                    path {
                                        stroke: $baseColor;
                                    }
                                }
                                &.lock {
                                    svg {
                                        path {
                                            fill: $baseColor;
                                            stroke: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            + .account-data-box {
                width: calc(100% - 270px);
            }
        }
        .account-data-box {
            width: 100%;
            padding: 20px 25px 30px;
            background: $white;
            @include radius(4px);
            box-shadow: 0px 5px 15px #0000000d;
            &.payment {
                width: 100%;
                max-width: 800px;
                margin-left: auto;
                margin-right: auto;
            }
            .title {
                @include font(18);
                font-weight: 600;
                color: $blackColor;
                padding-bottom: 15px;
                margin-bottom: 22px;
                border-bottom: 1px solid $gray;
            }
            .account-form-box {
                .form-group {
                    margin-bottom: 35px;
                    width: 60%;
                    label {
                        display: block;
                        @include font(14);
                        font-weight: 500;
                        color: rgba($black, .60%);
                        margin-bottom: 10px;
                    }
                    input {
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        padding: 0 10px;
                        width: 100%;
                        height: 35px;
                        border: 1px solid $gray;
                        @include radius(4px);
                        &::placeholder {
                            color: $black; 
                        }
                    }
                    .btn {
                        @include font(14);
                        padding: 12px 20px;
                        margin-top: -15px;
                    }
                    .MuiTextField-root {
                        position: relative;
                        input {
                           padding-right: 45px;
                        }
                        .MuiInputBase-root {
                            padding-right: 0;
                        }
                        .MuiOutlinedInput-notchedOutline {
                            border: none;
                            padding: 0!important;
                        }
                        .MuiIconButton-root {
                            padding: 5px 10px;
                            position: absolute;
                            right: 12px;
                            z-index:1;
                        }
                        .MuiInputAdornment-positionEnd {
                            margin-left: 0;
                        }
                    }
                    .MuiIconButton-root {
                        &:hover, &:focus {
                            background: none!important;
                        }
                    }
                    .MuiTouchRipple-rippleVisible {
                        opacity: 0!important;
                    }
                }
            }
        }
    }
    .notification-settings-box {
        .form-check {
            padding-left: 0;
            margin-bottom: 30px;
            .form-check-label {
              display: inline-block;
              vertical-align: middle;
              @include font(15);
              font-weight: 600;
              color: rgba($black, .80%);
              margin-right: 20px;
              cursor: pointer;
            }
            &.form-switch {
              @include flex();
              align-items: center;
              .form-check-input {
                background-image: none;
                border-width: 0;
                @include radius(50px);
                width: 32px;
                height: 18px;
                background: rgba($gray, 0.5%);
                border: none;
                margin-left: 0;
                appearance: none;
                position: relative;
                @include transition();
                cursor: pointer;
                margin-top: 0;
                &:after {
                  content: "";
                  position: absolute;
                  left: 2px;
                  top: 2.1px;
                  border: none;
                  z-index: 2;
                  border-radius: 50%;
                  width: 13px;
                  height: 13px;
                  background: rgba($black, 0.5);
                  @include transition();
                }
              }
              input:checked[type="checkbox"] {
                background: $green;
                &:after {
                  left: auto !important;
                  right: 2px !important;
                  background: $white;
                }
              }
            }
        }

        .notification-content {
            .flexbox-row {
                display: flex;
                justify-content: space-between;
                align-items: top;
                margin-bottom: 25px;
                @include font(14);
                color: rgba($black, .80%);
                line-height: 1.5;
                &:first-child {
                  @include font(14);
                  font-weight: 600;
                  line-height: 1.3;
                  border-bottom: 1px solid $gray;
                  padding-bottom: 10px;
                }
                .item {
                    width: 12%;
                    text-align: center;
                    &:first-child {
                        width: 52%;
                        text-align: left;
                    }
                    .checkbox-list {
                        @include flex();
                        align-items: center;
                        position: relative;
                        justify-content: center;
                        margin-left: 15px;
                        input[type="checkbox"] {
                            position: absolute;
                            left: 0px;
                            top: 0px;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            z-index: 1;
                            cursor: pointer;
                            &:checked+label {
                                &::before {
                                    border-color: $green;
                                    background: $green;
                                }
                                &::after {
                                    width: 16px;
                                    height: 12px;
                                    background: url(../img/checked_icon.png) no-repeat center center;
                                    background-size: 16px 12px;
                                    content: "";
                                    display: inline-block;
                                    position: absolute;
                                    left: 5px;
                                    top: 7px;
                                }
                            }
                        }
                        label {
                            position: relative;
                            @include font(14);
                            color: $black;
                            font-weight: normal;
                            padding-left: 30px;
                            cursor: pointer;
                            line-height: 18px;
                            color: $black;
                            &::before {
                                width: 25px;
                                height: 25px;
                                border: 1px solid $gray;
                                @include radius(4px);
                                content: "";
                                display: inline-block;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .membership-detail-box {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
        .membership-top-block {
            @include flex();
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            .title-box {
                @include flex();
                align-items: center;
                .title-bg {
                    background: $baseColor;
                    @include font(15);
                    font-weight: 500;
                    color: $white;
                    padding: 5px ;
                    @include radius(4px);
                    margin-bottom: 0;
                    margin-right: 8px;
                }
                .second-text {
                    @include font(15);
                    font-weight: 400;
                    color: rgba($black, .50%);
                    strong {
                        color: $blackColor;
                        font-weight: 500;
                        margin-right: 5px;
                    }
                }
            }
            .price-box {
                @include font(16);
                font-weight: 500;
                color: rgba($black, .50%);
                span {
                    display: inline-block;
                    vertical-align: middle;
                    @include font(42);
                    font-weight: 500;
                    color: $blackColor;
                    padding: 0 2px;
                    line-height: 30px;
                }
                sup {
                   display: inline-block;
                   vertical-align: middle;
                   @include font(22);
                }
                sub {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        .membership-info-box {
            padding: 20px;
            border: 1px solid $gray;
            @include radius(4px);
            .membership-flexbox {
                @include flex();
                justify-content: space-between;
                margin-bottom: 15px;
                .item {
                    margin-right: 15px;
                    &:last-child {
                        margin-right: 0;
                        text-align: right;
                    }
                    p {
                        @include font(17);
                        color: rgba($black, .80%);
                        margin-bottom: 12px;
                        strong {
                            font-weight: 600;
                        }
                    }
                    .btn {
                        display: inline-block;
                        vertical-align: middle;
                        padding: 10px 20px;
                        @include font(14);
                        min-width: 160px;
                        margin-top: 5px;
                    }
                    .plan-benefit {
                        padding-top: 20px;
                        h4 {
                            @include font(15);
                            font-weight: 500;
                            color: $blackColor;
                            margin-bottom: 15px;
                        }
                        ul {
                            li {
                                display: block;
                                padding-left: 22px;
                                @include font(14);
                                font-weight: 400;
                                color: rgba($black, .70%);
                                margin-bottom: 22px;
                                position: relative;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                &::after {
                                    content: "";
                                    width: 14px;
                                    height: 15px;
                                    background: url(../../assets/img/checkmark-circle.svg) no-repeat;
                                    background-position: center center;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .manage-access-box {
        .manage-access-item {
            margin-bottom: 45px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h3 {
            @include font(18);
            font-weight: 500;
            color: $black;
            margin-bottom: 10px;
        }
        .manage-access-top-block {
            @include flex();
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            .item {
                @include font(15);
                font-weight: 500;
                color: rgba($black, .80%);
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
                .btn {
                    padding: 10px 15px;
                    @include font(15);
                }
            }
        }
        .manage-access-table {
            table {
                @include font(14);
                color: rgba($black, .75%);
                margin-bottom: 0;
                th, td {
                    border-color: $gray;
                    border-left: 0;
                    border-right: 0;
                    vertical-align: middle;
                    a {
                        display: inline-block;
                        vertical-align: middle;
                        text-decoration: none;
                        color: $lightBlue;
                        @include transition();
                        &:hover {
                            color: $baseColor;
                        }
                    }
                    .close-button {
                        padding: 0;
                        background: none;
                        border: none;
                        outline: none;
                        @include transition();
                        svg {
                            width: 14px;
                        }
                        &:hover {
                            opacity:.8;
                        }
                    }
                    &:nth-child(5) {
                        a {
                            color: rgba($black, .75%); 
                            &:hover {
                                color: $baseColor;
                            }
                        }
                    }
                } 
            }
        }
    }
    .payment-detial-box {
       h3 {
        @include font(16);
        font-weight: 500;
        color: rgba($black, .80%);
        margin-bottom: 10px;
       }
       .payment-listing {
           overflow: hidden;
           .item {
                float: left;
                width: 48.75%;
                margin-right: 2.5%;
                padding: 15px;
                border: 1px solid $gray;
                @include radius(4px);
                margin-bottom: 20px;
                &:nth-child(2n+2) {
                    margin-right: 0;
                }
                &:nth-child(2n+2) {
                    margin-right: 0;
                }
                .payment-info-box {
                    @include flex();
                    justify-content: space-between;
                    align-items: flex-start;
                    .detial-box {
                        width: calc(100% - 120px);
                        margin-right: 10px;
                        .payment-icon {
                            @include flex();
                            justify-content: center;
                            align-items: center;
                            width: 80px;
                            height: 48px;
                            padding:10px 5px;
                            border: 1px solid $gray;
                            @include radius(4px);
                            margin-bottom: 15px;
                            img {
                                display: block;
                                object-fit: contain;
                                height: 20px;
                            }
                        }
                        p {
                            @include flex();
                            align-items: center;
                            @include font(12);
                            color: rgba($black, .70%);
                            margin-bottom: 10px;
                            span {
                                display: inline-block;
                                vertical-align: middle;
                                margin-right: 5px;
                                padding-right: 5px;
                                border-right: 1px solid $gray;
                                &:last-child {
                                    margin-right: 0;
                                    padding-right: 0;
                                    border-right: 0;
                                }
                            }
                        }
                        h4 {
                            @include font(14);
                            font-weight: 600;
                            color: $blackColor;
                        }
                    }
                    .link-box {
                        @include flex();
                        align-items: center;
                        a {
                            display: inline-block;
                            vertical-align: middle;
                            text-decoration: none;
                            margin-right: 8px;
                            padding-right: 8px;
                            @include font(14);
                            font-weight: 500;
                            color: $lightBlue;
                            border-right: 2px solid $lightBlue;
                            line-height: 1;
                            @include transition();
                            &:last-child {
                                margin-right: 0;
                                padding-right: 0;
                                border-right: 0;
                            }
                            &:hover {
                                color: $baseColor;
                            }
                        }
                    }
                }
                &.add-new-payment {
                    background: #daeffe;
                    border: 1px solid #a3ceea;
                    padding: 0;
                    .addnew-paymentMethod {
                        display: block;
                        text-align: center;
                        text-decoration: none;
                        @include font(13);
                        font-weight: 400;
                        color: $lightBlue;
                        padding: 45px 20px;
                        img {
                            display: block;
                            margin: 0 auto 10px;
                        }
                    }
                }
            }
        }
    }
}
.account-common-modal {
    .modal-dialog{
        margin: auto;
        @media screen and (max-width: 576px){
            width: 95%;
        }
    }
    .modal-content {
        border: none;
        width: 500px;
    }
    .modal-header {
        border-bottom: none;
        padding: 20px 30px;
        position: relative;
        .modal-title {
            @include font(20);
            font-weight: 400;
            color: $black;
        }
        .close {
            padding: 0;
            @include font(35);
            font-weight: 300;
            color: $black;
            outline: none;
            position: absolute;
            right: 30px;
            top: 15px;
            line-height: 15px;
            margin: 0;
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: 15px;
            }
        }
    }
    .modal-body {
        padding: 0 30px 25px;
        .fieldBox {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            label {
              display: block;
              @include font(14);
              font-weight: 400;
              color: $black;
              margin-bottom: 10px;
              sup {
                top: 8px;
              }
            }
            input {
              @include font(14);
              font-weight: 300;
              color: $black;
              width: 100%;
              border: 1px solid $border;
              @include radius(4px);
              padding: 0 1em;
              height: 40px;
              box-shadow: none;
            }
            select {
              width: 100%;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              padding: 0 35px 0 10px;
              @include font(14);
              font-weight: 300;
              color:$black;
              background-image: url(../../../../../../../../assets/img/arrowdown.png);
              background-repeat: no-repeat;
              background-position: bottom 14px right 12px;
              background-size: 12px;
              height: 40px;
              border: 1px solid $border;
              @include radius(4px);
            }
            textarea {
              @include font(14);
              font-weight: 300;
              border: 1px solid $border;
              @include radius(4px);
            }
        }
        .MuiSelect-selectMenu {
            height: 40px;
            line-height: 40px;
        }
        .MuiOutlinedInput-input {
            padding-top: 0;
            padding-bottom: 0;
        }
        div.countryPhoneFlexBox {
            height: 40px;
        }
        .css-yk16xz-control {
            background: none;
        }
        .css-1pahdxg-control {
            background: none!important;
           border: none;
           box-shadow: none;
        }
    }
    .modal-footer {
        padding: 0 30px 25px;
        border: none;
        justify-content: center;
        text-align: center;
        .btn {
            margin: 0;
        }
    }
    &.add-payment-modal {
        .modal-dialog {
            max-width: 560px;
        }
        .modal-header {
            padding: 15px 25px;
            border-bottom: 1px solid rgba(204, 204, 204, 0.4);
            .close {
                top: 18px;
                right: 25px;
            }
            .modal-title {
                @include font(18);
                color: $blackColor;
                font-weight: 500;
            }
        }
        .modal-body {
            padding: 25px 25px 25px;
            .payment-icons {
                margin-bottom: 20px;
                text-align: center;
                img {
                    max-width: 100%;
                }
            }
            .fieldBox {
                margin-bottom: 20px;
                &.flexBox {
                    @include flex();
                    justify-content: space-between;
                    align-items: flex-start;
                    .half {
                        width: calc(50% - 8px);
                        .fieldBox {
                            margin-bottom: 0;
                        }
                    }
                }
                label {
                    @include font(16);
                    color: $blackColor;
                }
                select, input {
                    @include font(16);
                    height: 48px;
                    color: rgba($black, .6);
                    &::placeholder {
                        color: rgba($black, .6);
                    }
                }
                select {
                    background-position: bottom 16px right 12px;
                }
                .cvv-flexbox {
                    @include flex();
                    .cvv-input-box {
                        width: 130px;
                    }
                    .cvv-icon {
                        width: 50px;
                        opacity: .2;
                        margin-left: 15px;
                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.manage-success-modal {
    .modal-dialog {
        max-width: 450px; 
    }
    .modal-content {
        border: 0;
        border-radius: 0;
        padding: 15px 50px;
        border-left: 5px solid #0eae19;
        position: relative;
        &:after {
            content:"";
            background: url(../../assets/img/checkmark-circle-sharp.svg) no-repeat;
            background-size: 20px 20px;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 15px;
            top: 40px;
            z-index: 1;
        }
        h3 {
            @include font(15);
            color: $black;
            margin-bottom: 2px;
        }
        p {
            @include font(14);
            color: rgba($black, .70%);
            line-height: 1.4;
        }
        .close {
            padding: 0;
            @include font(30);
            font-weight: 300;
            color: $black;
            outline: none;
            position: absolute;
            right: 15px;
            top: 50%;
            line-height: 15px;
            margin: -8px 0 0;
            span {
                display: inline-block;
                vertical-align: middle;
                line-height: 15px;
            }
        }
    }
}
@media screen and (max-width:1199px) {  
    .account-main-sec {
        .notification-settings-box {
            .notification-content {
                width: 100%;
                overflow-x: scroll;
                // Window Scrolbar
                &::-webkit-scrollbar {
                    border-radius: 10px;
                    width: 10px;
                    height:12px;
                    background: rgba($gray, 0.3%);
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    height: 6px;
                    background: rgba($gray, 0.8%);
                    cursor: pointer;
                }
                .flexbox-row {
                    @include font(14);
                    width: 600px;
                    margin-bottom: 15px;
                    &:first-child {
                        @include font(13);
                    }
                    .item {
                        width: 12.5%;
                        &:first-child {
                            width: 50%;
                        }
                        .checkbox-list {
                            label {
                                &:before {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            input[type="checkbox"]:checked {
                                + label {
                                    &::after {
                                        width: 12px;
                                        height: 8px;
                                        background-size: 12px 8px;
                                        left: 4px;
                                        top: 6px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .membership-detail-box {
            .membership-top-block {
                display: block;
                .title-box {
                    margin-bottom: 25px;
                }
            }
        }
        .manage-access-box {
            .manage-access-table {
                table {
                    @include font(12);
                }
            }
        }
    }
}
@media screen and (max-width:991px) {
    .account-main-sec {
        .account-with-sidebar {
            .account-data-box {
                .account-form-box {
                    .form-group {
                        width: 100%;
                        margin-bottom: 20px;
                        .btn {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .notification-settings-box {
            .form-check {
                margin-bottom: 20px;
            }
        }
        .membership-detail-box {
            .membership-top-block {
                text-align: center;
                .title-box {
                    display: block;
                    .title-bg {
                        margin-bottom: 15px;
                    }
                    span {
                        display: block;
                    }
                }
                .price-box {
                    span {
                        @include font(30);
                    }
                }
            }
            .membership-info-box {
                padding-left: 15px;
                padding-right: 15px;
                .membership-flexbox {
                    display: block;
                    margin-bottom: 0;
                    .item {
                        margin-bottom: 25px;
                        margin-right: 0;
                        &:last-child {
                            margin-bottom: 0;
                            text-align: left;
                        }
                        p {
                            @include font(15);
                        }
                        .plan-benefit {
                            padding-top: 10px;
                        }
                    }
                }
            }
        }
        .manage-access-box {
            h3 {
                @include font(16);
            }
            .manage-access-top-block {
                .item {
                    @include font(13);
                    line-height: 1.3;
                    .btn {
                        @include font(13);
                    }
                }
            }
            .manage-access-item {
                margin-bottom: 25px;
            }
            .manage-access-table {
                width: 100%;
                overflow-x: scroll;
                padding-bottom: 15px;
                table {
                    width: 650px;
                    @include font(13);
                    tr {
                        th, td {
                            &:first-child {
                                min-width: 120px;
                            }
                            &:nth-child(2) {
                                min-width: 120px;
                            }
                        }
                    }
                }
                &::-webkit-scrollbar {
                    border-radius: 10px;
                    width: 10px;
                    height:12px;
                    background: rgba($gray, 0.3%);
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    height: 6px;
                    background: rgba($gray, 0.8%);
                    cursor: pointer;
                }
            }
        }
    }
}
@media screen and (max-width:767px) {
    .account-main-sec {
        .account-with-sidebar {
            display: block;
            .account-sidebar {
                width: 100%;
                margin-bottom: 20px;
                min-height: 1px;
                ul {
                    li {
                        margin-bottom: 20px;
                    }
                }
                + .account-data-box {
                    width: 100%;
                }
            }
        }
        .payment-detial-box {
            h3 {
                @include font(15);
            }
            .payment-listing {
                .item {
                    float: none;
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .payment-info-box {
                        .detial-box {
                            .payment-icon {
                                margin-bottom: 12px;
                            }
                            p {
                                margin-bottom: 8px;
                            }
                        }
                        .link-box {
                            a {
                                @include font(13);
                            }
                        }
                    }
                    &.add-new-payment {
                        .addnew-paymentMethod {
                            padding: 30px 15px;
                        }
                    }
                }
                
            }
        }
    }
    .account-common-modal {
        .modal-header {
            .modal-title {
                @include font(18);
            }
        }
        .modal-body {
            .fieldBox {
                margin-bottom: 15px;
            }
        }
    }
}
@media screen and (max-width:639px) {
    .account-main-sec {
        padding-top: 25px;
        padding-bottom: 25px;
        .account-with-sidebar {
            .account-data-box {
                padding: 20px 15px 25px;
            }
            .account-data-box {
                .title {
                    @include font(17);
                    margin-bottom: 18px;
                    padding-bottom: 12px;
                }
                .account-form-box {
                    .form-group {
                        margin-bottom: 15px;
                    }
                }
            }
        }
        .notification-settings-box {
            .form-check {
                .form-check-label {
                    @include font(14);
                }
            }
        }
        .membership-detail-box {
            .membership-top-block {
                .title-box {
                    margin-bottom: 15px;
                }
            }
        }
    }
    .account-common-modal {
        .modal-header {
            padding: 15px 45px 15px 15px;
            .close {
                right: 15px;
            }
        }
        .modal-body {
            padding-left: 15px;
            padding-right: 15px;
        }
        .modal-footer {
            .btn {
                @include font(14);
            }
        }
    }
}
@media screen and (max-width:567px) {
    .account-common-modal {
        &.add-payment-modal {
            .modal-header {
                padding: 12px 15px;
            }
            .modal-body {
                padding: 20px;
                .fieldBox {
                    margin-bottom: 15px;
                    &.flexBox {
                        display: block;
                        .half {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                    label {
                        @include font(15);
                    }
                }
            }
        }
    }
}
@media screen and (max-width:479px) {
    .account-main-sec {
        .membership-detail-box {
            margin-bottom: 30px;
        }
        .manage-access-box {
            .manage-access-top-block {
                display: block;
                .item {
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}