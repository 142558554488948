@import  "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";


.onBoardBatThrowSection{
    min-height: calc(100vh - 120px);
    padding: 20px 15px;
    @include flex();
    justify-content: center;
    margin-top: 0;
    .batThrowBlock{
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
        @include radius(6px);
        .formSection{
            padding: 30px 30px;
            border: 1px solid $gray;
            width: 100%;
            @include radius(6px);
            .heading{
                font-size: 20px;
                font-weight: 600;
                margin: 0 0 30px 0;
                font-weight: 600;
                text-align: center;
            }
            .fieldBox{
                margin-bottom: 30px;
                label{
                    font-size: 16px;
                    margin: 0 0 10px 0;
                    display: block;
                    color: $black;
                    font-weight: 600;
                    @include flex();
                    align-items: center;
                    span{
                        &.checkIcon{
                            margin-left: 10px;
                            svg{
                                .a {
                                    fill: #00b9ff;
                                  }
                                  .b {
                                    fill: #fafafa;
                                  }
                            }
                        }
                    }
                }
                select{
                    border: 1px solid $gray;
                    @include radius(4px);
                    outline: none;
                    width: 100%;
                    padding: 12px 10px;
                    color: $black;
                    -webkit-appearance: none;
                    background-image:url(../../assets/img/arrowdown.png) ;
                    background-repeat: no-repeat;
                    background-position: 95% center;
                    background-size: 16px;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .skip{
                text-align: center;
                margin-top: 15px;
                .sikpBtnBox{
                    display: inline-block;
                }
                a{
                    text-decoration: none;
                    display: inline-block;
                    cursor: pointer;
                    color: $baseColor;
                }
            }
        }
        .continueBtnBox{
            text-align: center;
            margin-top: 40px;
            a{
                outline: none;
                box-shadow: none;
                cursor: pointer;
                background-color: $green;
                color: $white;
                padding: 10px 15px;
                @include radius(4px);
                border: none;
                font-size: 16px;
                min-width: 100px;
                min-height: 45px;
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width:479px) {
    .onBoardBatThrowSection{
        .batThrowBlock{
            .formSection{
                padding: 20px 20px;
            }
            .continueBtnBox {
                a{
                    width: 100%;
                }
            }
        }
    }  
}