@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/reset";

.forgot-pass-sec {
  background: url("https://cdn.sportsforce.io/loginBG.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;

  .forgot-box {
    background: $white;
    padding: 20px 45px 35px;
    max-width: 520px;
    margin: 0 auto;
    height: 100vh;

    .back-link-box {
      margin-bottom: 15px;
      padding-bottom: 0 !important;
      border-bottom: none !important;

      a {
        @include flex-inline();
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        @include font(15);
        color: $baseColor;
        @include transition();

        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;

          path {
            fill: none;
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .detail-text-box {
      padding: 45px 0 25px;
      @include font(15);
      color: rgba($black, 0.5%);

      .logo-box {
        text-align: center;
        margin: 0 auto 60px;
      }

      h1 {
        @include font(18);
        font-weight: 600;
        color: rgba($black, 0.7%);
        margin-bottom: 10px;
      }

      h2 {
        @include font(18);
        font-weight: 600;
        color: rgba($black, 0.7%);
        margin-bottom: -15px;
      }

      p {
        line-height: 1.5;
        color: $black;
      }
    }

    .bottom-text-box {
      @include font(15);
      color: rgba($black, 0.5%);
      text-align: center;

      a {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        color: $baseColor;
        @include transition();

        &:hover {
          color: $green;
        }
      }
    }

    .form-box {
      margin-bottom: 60px;

      .form-group {
        margin-bottom: 15px;

        input {
          @include font(14);
          font-weight: 300;
          color: $black;
          width: 100%;
          border: 1px solid $black;
          @include radius(4px);
          height: 45px;
          padding: 0 1em;
          box-shadow: none;

          &::placeholder {
            color: $black;
          }
        }

        &:last-child {
          margin-top: 25px;
        }

        #password {
          border: none;
          border-radius: 4px 0 0 4px;
        }
      }

      .btn {
        @include font(15);
        color: rgba($white, 0.7%);
        padding: 10px 20px;
        display: block;
        width: 100%;
      }
    }
  }
}

.forget-error-toast {
  top: 0% !important
}

@media screen and (max-width: 991px) {
  .forgot-pass-sec {
    .forgot-box {
      .detail-text-box {
        padding-top: 35px;
      }

      .form-box {
        margin-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: 639px) {
  .forgot-pass-sec {
    height: auto;

    .forgot-box {
      padding-left: 20px;
      padding-right: 20px;
      height: auto;

      .detail-text-box {
        padding-top: 20px;

        .logo-box {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .forgot-pass-sec {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}