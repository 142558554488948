/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.notification-with-sidebar {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  background: #ffffff;
}

.notification-with-sidebar .notification-sidebar {
  width: 200px;
}

.notification-with-sidebar .notification-sidebar ul li {
  display: block;
  padding: 20px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
}

.notification-with-sidebar .notification-sidebar ul li a {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-decoration: none;
  font-size: 1rem;
  color: rgba(62, 62, 62, 0.4);
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-sidebar ul li a:hover {
  color: #2177D6;
}

.notification-with-sidebar .notification-sidebar ul li.active a {
  color: #2177D6;
}

.notification-with-sidebar .notification-sidebar ul li.button-link {
  padding-top: 30px;
  padding-bottom: 30px;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .btn {
  display: block;
  width: 100%;
  background-image: linear-gradient(115deg, #00B9FF, #19D7CD);
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  color: #ffffff;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .btn:after {
  display: none;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu {
  background: #ffffff;
  padding: 15px;
  min-width: 1px;
  width: auto;
  left: 20px !important;
  right: 20px;
  top: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
  border: 0;
  border-radius: 6;
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  -webkit-box-shadow: -2px 3px 11px #00000029;
          box-shadow: -2px 3px 11px #00000029;
  margin: 0;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu li {
  padding: 0;
  font-size: 0.9375rem;
  font-weight: 500;
  color: #3E3E3E;
  margin-bottom: 12px;
  border: none;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu li:last-child {
  margin-bottom: 0;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu li a {
  display: block;
  padding: 0 5px;
  background: none;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #3E3E3E;
  white-space: inherit;
  line-height: 1.4;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu li a:hover {
  color: #C0321F;
}

.notification-with-sidebar .notification-detail-box {
  width: calc(100% - 200px);
  border-left: 1px solid rgba(204, 204, 204, 0.4);
  min-height: 460px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 15px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box label {
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
  margin-right: 10px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info input[type="checkbox"]:checked + label::before {
  border-color: #00B9FF;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info input[type="checkbox"]:checked + label::after {
  content: "";
  display: inline-block;
  background: url(../img/checkBlue.png) no-repeat;
  background-size: cover;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3px;
  top: 5px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info input[type="checkbox"]:checked + label.singleSelect::after {
  content: "";
  display: inline-block;
  background: #00B9FF;
  position: absolute;
  width: 10px;
  height: 2px;
  left: 4px;
  top: 8px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info label {
  position: relative;
  font-size: 14px;
  color: #3E3E3E;
  font-weight: normal;
  padding-left: 18px;
  cursor: pointer;
  line-height: 18px;
  color: #3E3E3E;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #3E3E3E;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .delete-foreverBtn {
  padding: 6px 8px;
  background: rgba(204, 204, 204, 0.2);
  font-size: 0.875rem;
  font-weight: 400;
  color: #000;
  border: none;
  outline: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  margin-right: 15px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .EmptyTrashNowBtn {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 400;
  color: #C0321F;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .EmptyTrashNowBtn:hover {
  opacity: .8;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box h1 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #3E3E3E;
  margin-right: 10px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner {
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner input {
  padding: 10px 15px 10px 35px;
  width: 100%;
  height: 40px;
  font-size: 0.875rem;
  font-weight: 300;
  color: #ccc;
  border: 1px solid #ccc;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner button {
  display: inline-block;
  vertical-align: middle;
  background: none;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  top: 0;
  z-index: 11;
  border: 0;
  outline: none;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner button svg .a {
  opacity: 0.3;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner button svg .b {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .back-link {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  padding-left: 22px;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(62, 62, 62, 0.8);
}

.notification-with-sidebar .notification-detail-box .notification-pagination .back-link::after {
  content: "<";
  position: absolute;
  font-family: monospace;
  font-size: 1.625rem;
  color: rgba(62, 62, 62, 0.8);
  left: 0;
  top: -7px;
  z-index: 1;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .back-link:hover {
  color: #C0321F;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .back-link:hover::after {
  color: #C0321F;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li {
  font-size: 0.9375rem;
  color: #3E3E3E;
  margin-right: 10px;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li:last-child {
  margin-right: 0;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li button {
  background: transparent;
  font-family: monospace;
  font-size: 1.5625rem;
  color: rgba(0, 0, 0, 0.55);
  border: none;
  outline: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li button:hover {
  color: #C0321F;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info {
  display: block;
  text-align: right;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li {
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-toggle {
  display: block;
  padding: 1px 6px 6px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-toggle img {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-toggle:after {
  display: none;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu {
  padding: 15px;
  background: #ffffff;
  width: auto;
  top: 100% !important;
  right: 0;
  left: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
  border: 0;
  -webkit-box-shadow: -2px 3px 11px #00000029;
          box-shadow: -2px 3px 11px #00000029;
  border-radius: 6;
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li {
  margin-bottom: 15px;
  margin-right: 0;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li:last-child {
  margin-bottom: 0;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li strong {
  font-weight: 500;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  background: none;
  width: 100%;
  padding: 0 0 0 25px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  white-space: inherit;
  line-height: 1.4;
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a .icon-box {
  position: absolute;
  left: 0;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a .icon-box svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a:hover {
  color: #C0321F;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a:hover svg path {
  fill: #C0321F;
}

.notification-with-sidebar .notification-detail-box .notification-item {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 20px;
  background: rgba(204, 204, 204, 0.09);
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  cursor: pointer;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 70px;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item {
  width: 22px;
  margin-right: 15px;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item:first-child {
  margin-right: 10px;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .button {
  background: none;
  outline: none;
  border: none;
  padding: 0 5px;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info input[type="checkbox"]:checked + label::before {
  border-color: #00B9FF;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info input[type="checkbox"]:checked + label::after {
  content: "";
  display: inline-block;
  background: url(../img/checkBlue.png) no-repeat;
  background-size: cover;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3px;
  top: 5px;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info label {
  position: relative;
  font-size: 14px;
  color: #3E3E3E;
  font-weight: normal;
  padding-left: 18px;
  cursor: pointer;
  line-height: 18px;
  color: #3E3E3E;
}

.notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #3E3E3E;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding-right: 100px;
  width: 100%;
  font-size: 1rem;
  color: rgba(62, 62, 62, 0.6);
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .item:last-child {
  position: absolute;
  right: 0;
  top: auto;
  z-index: 1;
  margin-right: 0;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .name {
  margin-bottom: 5px;
  font-weight: 500;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box .subject-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: 200px;
  margin-right: 10px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.3;
  overflow: hidden;
  text-transform: capitalize;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: calc(100% - 200px);
  font-size: 0.9375rem;
  line-height: 1.4;
  overflow: hidden;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .time-text {
  font-size: 0.8125rem;
  color: #3E3E3E;
  text-align: right;
}

.notification-with-sidebar .notification-detail-box .notification-item.whitebg {
  background: #ffffff;
}
.notification-with-sidebar .notification-detail-box .notification-item.whitebg .user-detial-box {
  color: rgba(62, 62, 62, 0.6);
}
.notification-with-sidebar .notification-detail-box .notification-item .user-profile .initial {
  color: rgba(62, 62, 62, 0.6);
}
.notification-with-sidebar .notification-detail-box .notification-item:hover, 
.notification-with-sidebar .notification-detail-box .notification-item.active {
  background: #ffffff;
}
.notification-with-sidebar .notification-detail-box .notification-item.active .user-detial-box {
  color:#000;
  font-weight: 700;
}
.notification-with-sidebar .notification-detail-box .notification-item.active .user-detial-box .text-box .subject-line {
  color:#000;
  font-weight: 700;
}
.notification-with-sidebar .notification-detail-box .notification-item.active .user-profile .initial {
  color:#000;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .notification-with-sidebar .notification-sidebar {
    width: 180px;
  }
  .notification-with-sidebar .notification-detail-box {
    width: calc(100% - 180px);
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box {
    padding-left: 15px;
    padding-right: 15px;
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box .search-box label {
    font-size: 1rem;
  }
  .notification-with-sidebar .notification-detail-box .notification-pagination > ul li {
    margin-right: 5px;
  }
  .notification-with-sidebar .notification-detail-box .notification-pagination > ul li button {
    padding: 0;
  }
  .notification-with-sidebar .notification-detail-box .notification-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box {
    font-size: 0.9375rem;
    padding-right: 85px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box {
    display: block;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box .subject-line {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box p {
    font-size: 0.8125rem;
    width: 100%;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .item:last-child {
    top: 22px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .button-box .button {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .pageContent {
    min-height: 1px;
  }
  .notification-with-sidebar {
    display: block;
  }
  .notification-with-sidebar .notification-sidebar {
    width: 100%;
  }
  .notification-with-sidebar .notification-sidebar ul li {
    padding: 15px;
  }
  .notification-with-sidebar .notification-sidebar ul li.button-link {
    padding: 20px 15px;
  }
  .notification-with-sidebar .notification-detail-box {
    width: 100%;
    border-left: none;
    min-height: 1px;
  }
}

@media screen and (max-width: 567px) {
  .notification-with-sidebar .notification-sidebar ul li {
    padding: 10px 15px;
  }
  .notification-with-sidebar .notification-sidebar ul li a {
    font-size: 0.9375rem;
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box {
    display: block;
    position: relative;
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box .search-box {
    display: block;
    margin-right: 0;
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box .search-box label {
    margin-bottom: 12px;
  }
  .notification-with-sidebar .notification-detail-box .notification-pagination {
    display: block;
  }
  .notification-with-sidebar .notification-detail-box .notification-pagination > ul {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 8px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box {
    font-size: 0.875rem;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box p {
    -webkit-line-clamp: 2;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .item:last-child {
    top: 5px;
  }
  .notification-with-sidebar .notification-detail-box.with-back .notification-search-box {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
  }
  .notification-with-sidebar .notification-detail-box.with-back .notification-search-box .notification-pagination {
    position: static;
    top: auto;
  }
}
