@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.payment-main-sec {
  background: $bgLightGray;
  padding: 25px 0 35px;
  margin-top: 60px;
  .payment-data-box {
    width: 100%;
    padding: 20px 25px 30px;
    background: $white;
    @include radius(4px);
    box-shadow: 0px 5px 15px #0000000d;
    .title {
      @include font(18);
      font-weight: 600;
      color: $black;
      padding-bottom: 15px;
      margin-bottom: 22px;
      border-bottom: 1px solid $gray;
    }
    .account-form-box {
      .form-group {
        margin-bottom: 35px;
        width: 60%;
        label {
          display: block;
          @include font(14);
          font-weight: 500;
          color: rgba($black, 0.6%);
          margin-bottom: 10px;
        }
        input {
          @include font(14);
          font-weight: 400;
          color: $black;
          padding: 0 10px;
          width: 100%;
          height: 35px;
          border: 1px solid $gray;
          @include radius(4px);
          &::placeholder {
            color: $black;
          }
        }
        .btn {
          @include font(14);
          padding: 12px 20px;
          margin-top: -15px;
        }
      }
    }
  }
  .payment-detial-box {
    padding: 25px 20px;
    box-shadow: 0px 0px 5px #0000001a;
    @include radius(5px);
    h3 {
      @include font(16);
      font-weight: 500;
      color: rgba($black, 0.8%);
      margin-bottom: 10px;
    }
    .payment-listing {
      overflow: hidden;
      .item {
        float: left;
        width: 31.333%;
        margin-right: 3%;
        padding: 15px;
        border: 1px solid $gray;
        @include radius(4px);
        margin-bottom: 35px;
        position: relative;
        cursor: pointer;
        input {
          opacity: 0;
          position: absolute;
          left: 0px;
          top: 0px;
          cursor: pointer;
          width: 100%;
          height: 100%;
          z-index: 1;
          &:checked + label {
            &:after {
              content: "";
              width: 18px;
              height: 18px;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              border: 1px solid $green;
              position: absolute;
              right: 0;
              top: 0;
            }
            &:before {
              content: "";
              display: inline-block;
              position: absolute;
              width: 10px;
              height: 10px;
              @include radius(50%);
              background-color: $green;
              right: 4px;
              top: 4px;
            }
          }
        }
        label {
          &:after {
            content: "";
            width: 18px;
            height: 18px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border: 1px solid $gray;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
        &:nth-child(3n + 4) {
          clear: both;
        }
        .payment-info-box {
          @include flex();
          justify-content: space-between;
          align-items: flex-start;
          position: relative;
          cursor: pointer;
          .detial-box {
            width: calc(100% - 30px);
            margin-right: 10px;
            .payment-icon {
              @include flex();
              justify-content: center;
              align-items: center;
              width: 80px;
              height: 48px;
              padding: 10px 5px;
              border: 1px solid rgba($gray, 0.7);
              @include radius(4px);
              margin-bottom: 15px;
              img {
                display: block;
                object-fit: contain;
                height: 20px;
              }
            }
            p {
              @include flex();
              align-items: center;
              @include font(12);
              color: rgba($black, 0.7%);
              margin-bottom: 10px;
              span {
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                padding-right: 5px;
                border-right: 1px solid $gray;
                &:last-child {
                  margin-right: 0;
                  padding-right: 0;
                  border-right: 0;
                }
              }
            }
            h4 {
              @include font(14);
              font-weight: 600;
              color: $black;
            }
          }
          .link-box {
            @include flex();
            align-items: center;
            a {
              display: inline-block;
              vertical-align: middle;
              text-decoration: none;
              margin-right: 8px;
              padding-right: 8px;
              @include font(14);
              font-weight: 500;
              color: $lightBlue;
              border-right: 2px solid $lightBlue;
              line-height: 1;
              @include transition();
              &:last-child {
                margin-right: 0;
                padding-right: 0;
                border-right: 0;
              }
              &:hover {
                color: $baseColor;
              }
            }
          }
        }
      }
    }
    .payment-button-box {
      .btn {
        margin-right: 20px;
        font-weight: 300;
        min-width: 100px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .payment-detail-new-box {
    padding-top: 30px;
    .title {
      @include font(16);
      font-weight: 500;
    }
    .payment-flexbox {
      @include flex();
      justify-content: space-between;
      align-items: center;
      .lightblue {
        background: $lightBlue;
        @include flex();
        align-items: center;
        svg {
          margin-right: 5px;
          path {
            fill: $white;
          }
        }
      }
      img {
        max-width: 100%;
      }
    }
    .payment-detial-box {
      display: none;
      margin-top: 30px;
    }
    .payment-form-box {
      .fieldBox {
        margin-bottom: 20px;
        &.flexBox {
          @include flex();
          justify-content: space-between;
          align-items: flex-start;
          .half {
            width: calc(50% - 8px);
            .fieldBox {
              margin-bottom: 0;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        label {
          display: block;
          @include font(16);
          font-weight: 400;
          color: $blackColor;
          margin-bottom: 10px;
          sup {
            display: inline-block;
            vertical-align: -5px;
            @include font(15);
            color: $baseColor;
            font-weight: 500;
          }
        }
        input {
          @include font(16);
          font-weight: 400;
          color: rgba($black, 0.6);
          width: 100%;
          border: 1px solid $border;
          @include radius(4px);
          padding: 0 1em;
          height: 48px;
          box-shadow: none;
          &::placeholder {
            color: rgba($black, 0.6);
          }
        }
        select {
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 0 35px 0 10px;
          @include font(16);
          font-weight: 400;
          color: rgba($black, 0.6);
          background-image: url(../../../../../../../assets/img/arrowdown.png);
          background-repeat: no-repeat;
          background-position: bottom 16px right 12px;
          background-size: 12px;
          height: 48px;
          border: 1px solid $border;
          @include radius(4px);
          &::placeholder {
            color: rgba($black, 0.6);
          }
        }
        textarea {
          @include font(16);
          color: rgba($black, 0.6);
          font-weight: 400;
          border: 1px solid $border;
          @include radius(4px);
          &::placeholder {
            color: rgba($black, 0.6);
          }
        }
        .cvv-flexbox {
          @include flex();
          .cvv-input-box {
            width: 130px;
          }
          .cvv-icon {
            width: 50px;
            opacity: 0.2;
            margin-left: 15px;
            img {
              max-width: 100%;
            }
          }
        }
        .checkbox-list {
          span {
            display: inline-flex;
            align-items: center;
            position: relative;
            input[type="checkbox"] {
              position: absolute;
              left: 0px;
              top: 0px;
              width: 100%;
              height: 100%;
              opacity: 0;
              z-index: 1;
              cursor: pointer;
              &:checked + label {
                &::before {
                  border-color: $lightBlue;
                }
                &::after {
                  width: 12px;
                  height: 12px;
                  background: url(../img/checkBlue.png) no-repeat;
                  background-size: cover;
                  content: "";
                  display: inline-block;
                  position: absolute;
                  left: 3px;
                  top: 5px;
                }
              }
            }
            label {
              position: relative;
              font-size: 14px;
              color: $black;
              font-weight: normal;
              padding-left: 30px;
              cursor: pointer;
              line-height: 18px;
              color: $black;
              &::before {
                width: 18px;
                height: 18px;
                border: 1px solid $gray;
                @include radius(4px);
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                @include radius(4px);
              }
            }
          }
        }
      }
    }
  }
}

.paymentCartPopup {
  .MuiDialog-paperWidthSm{
    max-width: 560px;
    margin: 15px;
  }
  .payment-detial-box {
      .payment-form-box {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: none;
        border-radius: 0.3rem;
        outline: 0;
      .modal-header{
        padding: 15px 25px;
        border-bottom: 1px solid rgba(204, 204, 204, 0.4);
        position: relative;
        margin-bottom: 0;
        .modal-title{
          font-size: 1.125rem;
          color: #000;
          font-weight: 500;
        }
        .close{
          padding: 0;
          font-size: 2.1875rem;
          font-weight: 300;
          color: #3e3e3e;
          outline: none;
          position: absolute;
          right: 25px;
          top: 18px;
          line-height: 15px;
          margin: 0;
        }
      }
      .modal-body{
        padding: 25px 25px 25px;
        margin-bottom: 0;
        .payment-icons{
          max-width: 100%;
          margin-bottom: 20px;
          text-align: center;
          img {
            max-width: 100%;
          }
        }
      .fieldBox {
        margin-bottom: 20px;
        &.flexBox {
          @include flex();
          justify-content: space-between;
          align-items: flex-start;
          .half {
            width: calc(50% - 8px);
            .fieldBox {
              margin-bottom: 0;
            }
          }
        }
        label {
          display: block;
          @include font(16);
          font-weight: 400;
          color: $blackColor;
          margin-bottom: 10px;
          sup {
            top: 8px;
          }
        }
        input {
          @include font(16);
          font-weight: 400;
          color: rgba($black, 0.6);
          width: 100%;
          border: 1px solid $border;
          @include radius(4px);
          padding: 0 1em;
          height: 48px;
          box-shadow: none;
          &::placeholder {
            color: rgba($black, 0.6);
          }
        }
        select {
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 0 35px 0 10px;
          @include font(16);
          font-weight: 400;
          color: rgba($black, 0.6);
          background-image: url(../../../../../../../assets/img/arrowdown.png);
          background-repeat: no-repeat;
          background-position: bottom 16px right 12px;
          background-size: 12px;
          height: 48px;
          border: 1px solid $border;
          @include radius(4px);
          &::placeholder {
            color: rgba($black, 0.6);
          }
        }
        textarea {
          @include font(16);
          color: rgba($black, 0.6);
          font-weight: 400;
          border: 1px solid $border;
          @include radius(4px);
          &::placeholder {
            color: rgba($black, 0.6);
          }
        }
        .cvv-flexbox {
          @include flex();
          .cvv-input-box {
            width: 130px;
          }
          .cvv-icon {
            width: 50px;
            opacity: 0.2;
            margin-left: 15px;
            img {
              max-width: 100%;
            }
          }
        }
        .checkbox-list {
          span {
            display: inline-flex;
            align-items: center;
            position: relative;
            input[type="checkbox"] {
              position: absolute;
              left: 0px;
              top: 0px;
              width: 100%;
              height: 100%;
              opacity: 0;
              z-index: 1;
              cursor: pointer;
              &:checked + label {
                &::before {
                  border-color: $lightBlue;
                }
                &::after {
                  width: 12px;
                  height: 12px;
                  background: url(../img/checkBlue.png) no-repeat;
                  background-size: cover;
                  content: "";
                  display: inline-block;
                  position: absolute;
                  left: 3px;
                  top: 5px;
                }
              }
            }
            label {
              position: relative;
              font-size: 14px;
              color: $black;
              font-weight: normal;
              padding-left: 30px;
              cursor: pointer;
              line-height: 18px;
              color: $black;
              &::before {
                width: 18px;
                height: 18px;
                border: 1px solid $gray;
                @include radius(4px);
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                @include radius(4px);
              }
            }
          }
        }
      }
    }
      .payment-button-box {
        padding: 0 25px 25px;
        border: none;
        justify-content: center;
        text-align: center;
        margin-bottom: 0;
        .btn {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .payment-main-sec {
    .payment-detial-box {
      .payment-listing {
        .item {
          width: 48.5%;
          &:nth-child(3n + 3) {
            margin-right: 3%;
          }
          &:nth-child(3n + 4) {
            clear: none;
          }
          &:nth-child(2n + 2) {
            margin-right: 0;
          }
          &:nth-child(2n + 3) {
            clear: both;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .payment-main-sec {
    .payment-data-box {
      padding-left: 15px;
      padding-right: 15px;
    }
    .payment-detial-box {
      padding: 20px 15px;
      h3 {
        @include font(15);
      }
      .payment-listing {
        .item {
          margin-bottom: 20px;
          .payment-info-box {
            .detial-box {
              .payment-icon {
                margin-bottom: 12px;
              }
              p {
                margin-bottom: 8px;
              }
            }
            .link-box {
              a {
                @include font(13);
              }
            }
          }
        }
      }
      .payment-button-box {
        .btn {
          @include font(15);
          padding: 10px 15px;
          margin-right: 10px;
        }
      }
    }
    .payment-detail-new-box {
      .payment-flexbox {
        .lightblue {
          padding: 10px 15px;
          @include font(15);
        }
        img {
          max-width: 245px;
        }
      }
      .payment-form-box {
        .fieldBox {
          label {
            @include font(15);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 639px) {
  .payment-main-sec {
    padding-top: 25px;
    padding-bottom: 25px;
    .payment-data-box {
      padding: 20px 15px 25px;
      .title {
        @include font(17);
        margin-bottom: 18px;
        padding-bottom: 12px;
      }
      .payment-form-box {
        .form-group {
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 567px) {
  .payment-main-sec {
    .payment-detial-box {
      .payment-listing {
        padding-bottom: 5px;
        .item {
          margin-bottom: 15px;
          .payment-info-box {
            .detial-box {
              width: 100%;
            }
          }
        }
      }
    }
    .payment-detail-new-box {
      padding-top: 25px;
      .payment-flexbox {
        .lightblue {
          @include font(14);
        }
      }
      .payment-form-box {
        .fieldBox {
          margin-bottom: 15px;
          &.flexBox {
            display: block;
            .half {
              width: 100%;
              margin-bottom: 15px;
              .fieldBox {
                margin-bottom: 0;
                > .half {
                  margin-bottom: 0;
                }
                &.flexBox {
                  @include flex();
                  .half {
                    width: calc(50% - 8px);
                  }
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          input,
          select {
            height: 45px;
            @include font(15);
          }
          select {
            background-position: bottom 14px right 12px;
          }
        }
      }
    }
  }
  .paymentCartPopup {
    .payment-detial-box {
      .payment-form-box {
        .modal-header {
          padding-left: 15px;
          padding-right: 15px;
        }
        .modal-body {
          padding-left: 15px;
          padding-right: 15px;
        }
        .payment-button-box {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .payment-main-sec {
    .payment-data-box {
      .title {
        text-align: center;
      }
    }
    .payment-detial-box {
      .payment-listing {
        .item {
          float: none;
          width: 100%;
          margin-right: 0;
        }
      }
      .payment-button-box {
        .btn {
          display: block;
          margin-bottom: 10px;
          min-width: 100%;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .payment-detail-new-box {
      .payment-detial-box {
        margin-top: 25px;
      }
      .payment-flexbox {
        display: block;
        .lightblue {
          margin: 0 auto;
        }
        .item {
          margin-bottom: 15px;
          text-align: center;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
