@import  "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";

.emailSentNotification{
    // min-height: 100vh;
    // background: rgba(0,0,0,0.6);
    // @include flex();
    // align-items: center;
    // justify-content: center;
    .emailExists,
    .emailSent{
        width: 100%;
        background: $white;
        @include radius(5px);
        @include shadow(0, 3px, 20px, #00000087);
        padding: 15px;
        // margin: 15px 15px;
        // max-height: calc(100% - 30px);
        // width: calc(100% - 30px);
        .closeButton{
            position: absolute;
            right: 15px;
            top: 11px;
            button{
                background: transparent;
                border:none;
                outline: none;
            }
        }
        .heading{
            @include font(30);
            color: $blackColor;
            text-align: center;
            margin: 0 0 30px 0;
        }
        .icon{
            text-align: center;
            margin-bottom: 15px;
        }
        .profileBtn{
            text-align: center;
            margin-bottom: 32px;
            margin-top: 15px;
            
            button{
                padding: 15px 40px;
                background: $green;
                text-transform: none;
                @include font(14);
                color: $white;
                outline: none;
                border: none;
                @include radius(4px);
                cursor: pointer;
            }
        }
        p{
            &.info{
                max-width: 440px;
                text-align: center;
                margin: 0 auto 30px auto;
                @include font(14);
                line-height: 22px;
            }
            &.skip{
                text-align: center;
                margin: 0 0 30px 0;
                a{
                    display: inline-block;
                    text-decoration: underline;
                    @include font(14);
                    color: $green;
                }
            }
        }
    }
    .emailSent{
        max-width: 540px;   
        padding-top: 30px;
        padding-bottom: 30px;
        .profileBtn{
            margin-bottom: 0;
        }
    }
    .emailExists{
        max-width: 540px;
        max-height: 220px;
        padding-top: 30px ;
        &.second {
            max-width: inherit;
        }
    }

}
.swal-text {
    text-align: center!important;
}

@media  screen and (max-width:540px) {
    .emailSentNotification{
        .popUpAthleteBlock{
            .heading{
                @include font(20);
            }
        }
    } 
}