@import '../../../../../../assets/css/mixin';
@import '../../../../../../assets/css/variables';
@import '../../../../../../assets/css/reset';

.settingAccountSection{
    @include flex();
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 120px);
    padding: 50px 15px;
    margin-top: 60px;
    .settingAccount{
        max-width: 736px;
        width: 100%;
        padding: 30px;
        border: 1px solid $gray;
        margin: 0 auto;
        .heading{
            margin: 40px 0 30px 0;
            h1{
                @include font(20);
                color: $black;
                font-weight: 600;
                text-align: center;
                margin: 0;
            }
        }
        .progressBar{
            margin-bottom: 30px;
            text-align: center;
            a{
                display: inline-block;
                text-decoration: none;
                background-color: $green;
                width: 100%;
                max-width: 520px;
                margin: 0 auto;
                padding: 5px 10px;
                text-align: center;
                @include font(14);
                color: $white;
                @include radius(13px); 
                cursor: pointer;
            }
        }
        .info{
            margin-bottom: 40px;
            p{
                @include font(14);
                color: $black;
                text-align: center;
                line-height: 22px;
            }
        }
        .MuiLinearProgress-root{
            &.settingProgressBar{
                max-width: 520px;
                margin: 0 auto 25px auto;
                height: 25px;
                @include radius(13px);
                .MuiLinearProgress-barColorPrimary{
                    background-color: $green;
                }
            }
        }
        .MuiBox-root{
            &.progressCount{
                max-width: 520px;
                margin: -47px auto 25px auto;
                text-align: center;
                position: relative;
                z-index: 1;
                p{
                    color: $white;
                }
            }
        } 
    } 
}
@media screen and (max-width:575px) {
    .settingAccountSection{
        .settingAccount{
            padding: 20px;
            .heading{
                margin: 30px 0 30px 0;
                h1{
                    @include font(18);
                }
            }
            .info{
                margin-bottom: 30px;
            }
        }
    } 
}