@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/reset";

.rightDataBox {
  .verificationBox {
    .collapse {
      .card {
        .verificationSteps {
          li {
            &.active {
              span {
                &.icon {
                  svg {
                    // .a {
                    .a {
                      fill: #00b9ff !important;
                    }
                    .b {
                      fill: #fafafa !important;
                    }
                    // }
                  }
                }
              }
            }
            &.unactive {
              span {
                &.icon {
                  svg {
                    .a {
                      fill: #ccc !important;
                    }
                    b {
                      fill: #fafafa !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .sticky-box {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    z-index: 1;
  }
  .trendingProfileBox {
    background-color: $white;
    @include radius(2px);
    @include shadow(1px, 2px, 2px, #00000017);
    margin-bottom: 10px;
    .head {
      padding: 10px 10px;
      .title {
        color: $blackColor;
        font-weight: 600;
        font-size: 16px;
      }
    }
    ul {
      li {
        padding: 10px 10px;
        border-bottom: 1px solid $gray;
        @include flex();
        align-items: flex-start;
        .imgBox {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          @include radius(50%);
          position: relative;
          .initialLetter{
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $border;
            @include radius(50%);
            text-transform: uppercase;
          }
          img {
            max-width: 100%;
            max-height: 100%;
            height: auto;
            @include radius(50%);
          }
          .icon {
            position: absolute;
            bottom: 0px;
            right: -5px;
            &.blueCheck {
              svg {
                width: 15px;
                height: auto;
                .a {
                  fill: #00b9ff;
                }
              }
            }
            &.redAlert {
              svg {
                width: 15px;
                height: auto;
                .a {
                  fill: #ff4a31;
                }
                .b {
                  fill: #fff;
                }
              }
            }
          }
        }
        .info {
          width: calc(100% - 60px);
          h5 {
            font-size: 14px;
            line-height: 18px;
            margin: 0px 0px 5px 0px;
            color: rgb(0, 123, 255);
            font-weight: 600;
            cursor: pointer;
            a:hover {
              color:#0056b3;
            }
          }
          a {
            text-decoration: none;
          }
          a {
            text-decoration: none;
          }
          span {
            display: block;
            color: $black;
            font-size: 13px;
            margin: 0px 0px 5px 0px;
            line-height: 1.33;
          }
          p {
            @include font(13);
            font-weight: 400;
            color: $black;
            line-height: 1.33;
          }
          button {
            outline: none;
            cursor: pointer;
            border: none;
            &.followBtn {
              background-color: transparent;
              @include radius(10px);
              border: 1px solid $lightBlue;
              color: $lightBlue;
              margin-top: 5px;
              font-size: 13px;
              padding: 2px 10px;
              line-height: 16px;
            }
          }
        }
      }
    }
    .showMoreBox {
      text-align: center;
      padding: 10px 10px;
      a {
        text-decoration: none;
        display: inline-block;
        color: $baseColor;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          color: #b12c1a;
        }
      }
    }
  }
  .suggestCollegeBox {
    background-color: $white;
    @include radius(2px);
    @include shadow(1px, 2px, 2px, #00000017);
    margin-bottom: 10px;
    .head {
      padding: 10px 10px;
      .title {
        color: $blackColor;
        font-weight: 600;
        font-size: 16px;
      }
    }
    ul {
      li {
        padding: 10px 10px;
        border-bottom: 1px solid $gray;
        @include flex();
        align-items: flex-start;
        .imgBox {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          position: relative;
          img {
            max-width: 100%;
            max-height: 100%;
            height: auto;
          }
          .icon {
            position: absolute;
            bottom: 0px;
            right: 0px;
            svg {
              width: 15px;
              height: auto;
            }
          }
        }
        .info {
          width: calc(100% - 60px);
          h5 {
            font-size: 14px;
            line-height: 18px;
            margin: 0px 0px 5px 0px;
            color: rgb(0, 123, 255);
            font-weight: 600;
            cursor: pointer;
            a:hover {
              color:#0056b3;
            }
          }
          a {
            color: rgb(0, 123, 255);
            text-decoration: none;
            &:hover {
              h5 {
                color:#0056b3;
              }
            }
          }
          a {
            color: rgb(0, 123, 255);
            text-decoration: none;
            &:hover {
              h5 {
                color:#0056b3;
              }
            }
          }
          span {
            display: block;
            color: $black;
            font-size: 13px;
            margin: 0px 0px 5px 0px;
            line-height: 1.33;
          }
          p {
            @include font(13);
            font-weight: 400;
            color: $black;
            line-height: 1.33;
          }
          button {
            outline: none;
            cursor: pointer;
            border: none;
            &.followBtn {
              background-color: transparent;
              @include radius(10px);
              border: 1px solid $green;
              color: $green;
              margin-top: 5px;
              font-size: 13px;
              padding: 2px 10px;
              line-height: 16px;
            }
          }
        }
        &.link-text {
          &:last-child {
            border-bottom: none;
          }
          a {
            font-size: 14px;
            line-height: 18px;
            margin: 0px 0px 5px 0px;
            color: rgb(0, 123, 255);
            font-weight: 600;
            cursor: pointer;
            a:hover {
              color:#0056b3;
            }
          }
        }
      }
    }
    .showMoreBox {
      text-align: center;
      padding: 10px 10px;
      a {
        text-decoration: none;
        display: inline-block;
        color: $baseColor;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          color: #b12c1a;
        }
      }
    }
  }
}
