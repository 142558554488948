/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
header.Coachdashboard {
  display: block;
  background: #343434;
  padding: 8px 30px;
  position: fixed;
  width: 100%;
  height: 60px;
  left: 0;
  top: 0;
  z-index: 1050;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header.Coachdashboard div.logoPart {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header.Coachdashboard div.logoPart a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  cursor: pointer;
}

header.Coachdashboard div.logoPart a img {
  max-width: 100%;
  height: auto;
}

header.Coachdashboard div.logoPart a img.mobile {
  display: none;
}

header.Coachdashboard nav {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

header.Coachdashboard nav > ul {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header.Coachdashboard nav > ul li {
  list-style: none;
  position: relative;
}

header.Coachdashboard nav > ul li a {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  padding: 10px 1em;
  font-size: 0.8125rem;
  color: #ffffff;
  line-height: 35px;
}

header.Coachdashboard nav > ul li a.upgradeLink {
  background: #C0321F;
  padding: 0 1em;
  color: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  margin: 0 10px;
}

header.Coachdashboard nav > ul li a.upgradeLink.second {
  background: #ffffff;
  color: #C0321F;
  margin-right: 0;
}

header.Coachdashboard nav > ul li a.upgradeLink:hover {
  background: #00B9FF;
  color: #ffffff;
}

header.Coachdashboard nav > ul li .infoDetailsformBox .signBtn-onMobile {
  display: none;
}

header.Coachdashboard nav > ul li .infoDetailsformBox .signBtn-onMobile .btn {
  background: #C0321F;
  padding: 0 1em;
  min-width: 75px;
  line-height: 35px;
  font-size: 13px;
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

header.Coachdashboard nav > ul li .infoDetailform {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header.Coachdashboard nav > ul li .infoDetailform .item {
  margin-right: 15px;
}

header.Coachdashboard nav > ul li .infoDetailform .item:last-child {
  margin-right: 10px;
}

header.Coachdashboard nav > ul li .infoDetailform .item label {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.625rem;
  color: #ffffff;
  margin-bottom: 5px;
}

header.Coachdashboard nav > ul li .infoDetailform .item label a {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  padding: 0;
  height: auto;
  font-size: 0.625rem;
  color: #FDB944;
  line-height: 1;
}

header.Coachdashboard nav > ul li .infoDetailform .item label a:hover {
  color: #1CB79E;
}

header.Coachdashboard nav > ul li .infoDetailform .item input {
  display: block;
  padding: 5px 10px;
  width: 100%;
  height: 28px;
  font-size: 0.625rem;
  font-weight: 300;
  color: #3E3E3E;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

header.Coachdashboard nav > ul li .infoDetailform .item input::-webkit-input-placeholder {
  color: #3E3E3E;
}

header.Coachdashboard nav > ul li .infoDetailform .item input:-ms-input-placeholder {
  color: #3E3E3E;
}

header.Coachdashboard nav > ul li .infoDetailform .item input::-ms-input-placeholder {
  color: #3E3E3E;
}

header.Coachdashboard nav > ul li .infoDetailform .item input::placeholder {
  color: #3E3E3E;
}

header.Coachdashboard nav > ul li .infoDetailform .item .btn {
  background: #C0321F;
  padding: 0 1em;
  line-height: 35px;
  font-size: 0.8125rem;
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

@media screen and (max-width: 1599px) {
  header.Coachdashboard nav > ul li a {
    padding: 10px 0.5em;
  }
}

@media screen and (max-width: 1299px) {
  header.Coachdashboard {
    padding: 8px 1em;
  }
  header.Coachdashboard div.logoPart a img {
    display: none;
  }
  header.Coachdashboard div.logoPart a img.mobile {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  header.Coachdashboard nav > ul li a {
    line-height: 38px;
  }
  header.Coachdashboard nav > ul li a.upgradeLink {
    margin: 0 5px;
  }
  header.Coachdashboard nav > ul li .infoDetailform .item .btn {
    line-height: 38px;
  }
}

@media screen and (max-width: 639px) {
  header.Coachdashboard nav > ul li .infoDetailsformBox {
    position: relative;
  }
  header.Coachdashboard nav > ul li .infoDetailsformBox .signBtn-onMobile {
    display: block;
    margin-right: 10px;
  }
  header.Coachdashboard nav > ul li .infoDetailsformBox .signBtn-onMobile .btn {
    line-height: 38px;
    min-width: 75px;
  }
  header.Coachdashboard nav > ul li .infoDetailform {
    display: none;
  }
  header.Coachdashboard nav > ul li .infoDetailform.active {
    display: block;
    background: #343434;
    padding: 15px;
    position: absolute;
    width: 300px;
    right: 0;
    top: 45px;
    z-index: 11;
    -webkit-box-shadow: 0 5px 5px #00000085;
            box-shadow: 0 5px 5px #00000085;
  }
  header.Coachdashboard nav > ul li .infoDetailform .item {
    margin-bottom: 15px;
    margin-right: 0;
  }
  header.Coachdashboard nav > ul li .infoDetailform .item:last-child {
    margin-bottom: 0;
  }
  header.Coachdashboard nav > ul li .infoDetailform .item .btn {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  header.Coachdashboard nav > ul li .infoDetailform.active {
    width: 250px;
  }
}
/*# sourceMappingURL=header.css.map */