@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.athleteDashSection {
  margin-top: 60px;
  background-color: $bgLightGray;
  padding: 15px 0;
  > .container {
    > .flexBox {
      @include flex();
      flex-wrap: wrap;
      .leftDataBox {
        width: 250px;
        .AthleteInfoBox {
          .AthleteInfo {
            padding: 15px 15px;
            position: relative;
            background-image: url(../img/profileBg.png);
            background-repeat: no-repeat;
            background-size: auto;
            .profileBox {
              margin-top: 10px;
              .imgBoxBlock {
                background: $white;
                width: 100px;
                height: 100px;
                @include radius(50%);
                margin: 0 auto;
                position: relative;
                div {
                  &.imgBox {
                    width: 100px;
                    height: 100px;
                    @include radius(50%);
                    border: 2px solid $white;
                    overflow: hidden;
                    .image {
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                      }
                    a {
                      text-decoration: none;
                      @include flex();
                      justify-content: center;
                      align-items: center;
                      @include radius(50%);
                      img {
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                      }
                    }
                  }
                  &.icon {
                    position: absolute;
                    right: -10px;
                    bottom: 0;
                    cursor: pointer;
                    svg {
                      .a {
                        fill: #ff4a31;
                      }
                      .b {
                        fill: $white;
                      }
                    }
                  }
                }
                .browseEditProfile {
                  &.browse {
                    position: absolute;
                    left: 80%;
                    top:5px;
                    bottom: auto;
                    transform: none;
                    padding: 12px;
                    cursor: pointer;
                    label {
                      background: $black;
                      @include font(15);
                      font-weight: bold;
                      line-height: 24px;
                      text-align: center;
                      color: $white;
                      position: absolute;
                      width: 24px;
                      height: 24px;
                      right: 0;
                      top: 0;
                      @include radius(100%);
                      z-index: 11;
                      cursor: pointer;
                    }
                    input[type="file"] {
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 0;
                      z-index: 1;                      
                      opacity: 0;
                      cursor: pointer;
                    }
                  }
                }
              }
              .playerName {
                margin: 10px 0;
                text-align: center;
                @include font(18);
                color: $white;
                position: relative;
                &.dropdown-toggle {
                  cursor: pointer;
                  &::after {
                    position: relative;
                    top: 2px;
                  }
                }
              }
              .athlete-dropdown-info {
                position: relative;
                .athlete-dropdown-option {
                  display: none;
                  background:$white;
                  position: absolute;
                  min-width: 150px;
                  left: 0;
                  right: 0;
                  top: 100%;
                  z-index:11;
                  @include radius(5px);
                  box-shadow: 1px 2px 2px #00000017;
                  margin: 0 auto;
                  text-align: left;
                  &.show {
                    display: block;
                  }
                  li {
                    display: block;
                    margin: 0;
                    text-align: left;
                    a {
                      display: block;
                      cursor: pointer;
                      padding: 10px 15px;
                      border-bottom: 1px solid $gray;
                      @include font(16);
                      font-weight: 400;
                      color: $black;
                      &:hover {
                        color: $baseColor;
                      }
                    }
                    &:last-child {
                      a {
                        border-bottom: none;
                      }
                    }
                  }
                }
              }
              ul {
                margin: 0 0 20px 0;
                padding: 0;
                li {
                  list-style: none;
                  margin: 0 0 10px 0;
                  text-align: center;
                  @include flex();
                  align-items: flex-start;
                  justify-content: center;
                  a {
                    text-decoration: none;
                  }
                  span {
                    display: inline-block;
                    @include font(14);
                    color: $white;
                    line-height: normal;
                    &.label {
                      margin: 0 3px 0 0;
                    }
                  }
                  &:last-child {
                    margin: 0;
                  }
                }
              }
            }
            .flexBox {
              @include flex();
              justify-content: space-between;
              border-top: 1px solid rgba(255, 255, 255, 0.5);
              padding-top: 20px;
              margin: 0;
              li {
                list-style: none;
                width: calc(33.3% - 10px);
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
                a {
                  text-decoration: none;
                }
                span {
                  display: block;
                  text-align: center;
                  &.label {
                    font-weight: normal;
                    color: $white;
                    margin: 5px 0 0 0;
                    @include font(13);
                  }
                  &.value {
                    @include font(14);
                    font-weight: 600;
                    color: $white;
                  }
                }
              }
            }
            .profileLink {
              position: absolute;
              left: 10px;
              top: 10px;
              cursor: pointer;
              display: inline-flex;
              svg {
                .a {
                  fill: $yellow;
                }
              }
            }
          }
          .activityCollegeBox {
            background-color: $white;
            padding: 15px;
            @include shadow(1px, 2px, 2px, #00000017);
            @include radius(0 0 2px 2px);
            div {
              &.recuringActivity {
                border-bottom: 1px solid rgba(112, 112, 112, 0.3);
                .heading {
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                  margin: 0;
                }
                ul {
                  margin: 20px 0 10px 0;
                  padding: 0;
                  @include flex();
                  flex-wrap: wrap;
                  justify-content: space-between;
                  li {
                    list-style: none;
                    width: calc(25% - 10px);
                    margin: 0 10px 10px 0;
                    span {
                      display: block;
                      text-align: center;
                      &.value {
                        @include font(14);
                        color: $black;
                        font-weight: 600;
                      }
                      &.label {
                        @include font(12);
                        color: $black;
                        font-weight: normal;
                        margin-top: 5px;
                      }
                    }
                    &:last-child {
                      margin: 0 10px 0 0;
                    }
                  }
                }
              }
              &.myCollege {
                .heading {
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                  margin: 0 0 15px 0;
                }
                ul {
                  margin: 0;
                  padding: 0;
                  @include flex();
                  flex-wrap: wrap;
                  justify-content: space-between;
                  li {
                    list-style: none;
                    width: calc(25% - 10px);
                    margin: 0 10px 0 0;
                    span {
                      display: block;
                      text-align: center;
                      &.value {
                        @include font(14);
                        color: $black;
                        font-weight: 600;
                      }
                      &.label {
                        @include font(11);
                        color: $black;
                        font-weight: normal;
                        letter-spacing: -0.3px;
                        margin-top: 5px;
                      }
                    }
                    &:last-child {
                      margin: 0 10px 0 0;
                    }
                  }
                }
              }
            }
          }
        }
        .profileDataBox {
          background-color: $white;
          padding: 15px 15px;
          @include shadow(1px, 2px, 2px, #00000017);
          margin: 10px 0;
          div {
            &.head {
              @include flex();
              align-items: center;
              justify-content: space-between;
              .title {
                @include font(16);
                color: $black;
                font-weight: 600;
                margin: 0 0 15px 0;
              }
              .editLink {
                text-decoration: none;
                @include font(14);
                color: $baseColor;
                margin: 0 0 15px 0;
                cursor: pointer;
                &:hover {
                  color: #b12c1a;
                }
              }
            }
            &.porgress {
              @include flex();
              align-items: center;
              justify-content: space-between;
              margin-bottom: 15px;
              label {
                margin-right: 10px;
                display: inline-block;
                @include font(14);
                color: $green;
                font-weight: 600;
              }
              .bar {
                display: inline-flex;
                width: calc(100% - 45px);
                @include radius(7px);
                border: 1px solid $green;
                height: 8px;
                position: relative;
                .barprogress {
                  display: inline-flex;
                  height: 100%;
                  background: $green;
                  @include radius(7px);
                }
              }
            }
          }
          .level {
            li {
              list-style: none;
              margin: 0 0 15px 0;
              &:last-child {
                margin: 0;
              }
              &.passed {
                a {
                  color: $green;
                  &.checkList {
                    span {
                      &.icon {
                        position: relative;
                        svg {
                          .a {
                            fill: $green;
                          }
                        }
                        .count {
                          position: absolute;
                          right: 0;
                          top: 0;
                          width: 12px;
                          height: 12px;
                          @include radius(50%);
                          background-color: $baseColor;
                          @include font(8);
                          color: $white;
                          align-items: center;
                          justify-content: center;
                        }
                      }
                    }
                  }
                  span {
                    &.icon {
                      svg {
                        .a {
                          fill: none;
                          stroke: $green;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 1.5px;
                        }
                      }
                    }
                    &.label {
                      color: $green;
                    }
                  }
                }
              }
              a {
                display: inline-flex;
                cursor: pointer;
                text-decoration: none;
                // &.video {
                span {
                  &.icon {
                    svg {
                      .a,
                      .b {
                        fill: none;
                        stroke: $black;
                        stroke-linecap: round;
                        stroke-width: 1.5px;
                      }
                      .a {
                        stroke-linejoin: round;
                      }
                    }
                  }
                }
                // }

                span {
                  display: inline-flex;
                  &.icon {
                    width: 20px;
                    margin-right: 10px;
                  }
                  &.label {
                    @include font(16);
                    color: $black;
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }
        .teamOfferBox {
          background-color: $white;
          @include radius(2px);
          padding: 15px 15px;
          margin-bottom: 10px;
          div {
            &.myTeam {
              padding-bottom: 15px;
              .head {
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title {
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                  margin: 0 0 15px 0;
                }
                .editLink {
                  text-decoration: none;
                  display: inline-flex;
                  cursor: pointer;
                  color: $baseColor;
                  margin: 0 0 15px 0;
                  &:hover {
                    color: #b12c1a;
                  }
                }
              }
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  @include flex();
                  align-items: center;
                  margin-bottom: 15px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  div {
                    &.imgBox {
                      width: 40px;
                      height: 40px;
                      margin-right: 10px;
                      img {
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                      }
                      .image{
                        max-width: 100%;
                        width: 100px;
                        height: auto;
                      }
                    }
                    &.info {
                      width: calc(100% - 50px);
                      display: flex;
                      align-items: center;
                      line-height: 18px;
                      span {
                        display: inline-block;
                        @include font(14);
                        color: rgba(0, 0, 0, 0.7);
                      }
                    }
                  }
                }
              }
              .viewMore {
                margin-top: 5px;
                text-align: center;
                a {
                  display: inline-block;
                  text-decoration: none;
                  @include font(14);
                  color: $baseColor;
                  cursor: pointer;
                }
              }
            }
            &.offer {
              padding-bottom: 15px;
              .head {
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title {
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                  margin: 0 0 15px 0;
                }
                .editLink {
                  text-decoration: none;
                  display: inline-flex;
                  cursor: pointer;
                  color: $baseColor;
                  margin: 0 0 15px 0;
                  &:hover {
                    color: #b12c1a;
                  }
                }
              }
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  @include flex();
                  margin-bottom: 15px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  div {
                    &.imgBox {
                      width: 40px;
                      height: 40px;
                      margin-right: 10px;
                      img {
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                      }
                      .image{
                        max-width: 100%;
                        height: auto;
                      }
                    }
                    &.info {
                      width: calc(100% - 50px);
                      .title {
                        @include font(14);
                        color: rgb(0, 123, 255);
                        cursor: pointer;
                        font-weight: 600;
                        margin: 0 0 10px 0;
                      }
                      a {
                        text-decoration: none;
                        &:hover {
                          .title {
                            color:#0056b3;
                          }
                        }
                      }
                      .date {
                        @include flex();
                        label {
                          @include font(12);
                          color: $blackColor;
                          display: inline-block;
                          margin-right: 3px;
                        }
                        span {
                          display: inline-block;
                          @include font(12);
                          color: $blackColor;
                        }
                      }
                    }
                  }
                }
              }
              .viewMore {
                margin-top: 10px;
                text-align: center;
                a {
                  display: inline-block;
                  color: $baseColor;
                  text-decoration: none;
                  @include font(14);
                  cursor: pointer;
                }
              }
            }
            &.commitment {
              .head {
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title {
                  margin: 0 0 15px 0;
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                }
                .editLink {
                  text-decoration: none;
                  display: inline-flex;
                  cursor: pointer;
                  color: $baseColor;
                  margin: 0 0 15px 0;
                  &:hover {
                    color: #b12c1a;
                  }
                }
              }
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  @include flex();
                  margin-bottom: 15px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  div {
                    &.imgBox {
                      width: 40px;
                      height: 40px;
                      margin-right: 10px;
                      img {
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                      }
                      .image{
                        max-width: 100%;
                        height: auto;
                      }
                    }
                    &.info {
                      width: calc(100% - 50px);
                      .title {
                        @include font(14);
                        color: rgb(0, 123, 255);
                        cursor: pointer;
                        font-weight: 600;
                        margin: 0 0 10px 0;
                      }
                      a {
                        text-decoration: none;
                        &:hover {
                          .title {
                            color:#0056b3;
                          }
                        }
                      }
                      span {
                        display: inline-block;
                        @include font(12);
                        color: $blackColor;
                      }
                    }
                  }
                }
              }
              .viewMore {
                margin-top: 10px;
                text-align: center;
                a {
                  display: inline-block;
                  @include font(14);
                  color: $baseColor;
                  text-decoration: none;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .targetCollege {
          background-color: $white;
          @include radius(2px);
          @include shadow(1px, 2px, 2px, #00000017);
          .head {
            padding: 20px 20px 0 20px;
            @include flex();
            align-items: center;
            justify-content: space-between;
            h5 {
              @include font(16);
              color: $blackColor;
              font-weight: 600;
              margin: 0 0 15px 0;
            }
            a {
              @include font(14);
              color: $baseColor;
              font-weight: normal;
              margin: 0 0 15px 0;
              &:hover {
                color: #b12c1a;
              }
            }
          }
          ul {
            margin: 0;
            padding: 0;
            li {
              list-style: none;
              padding: 15px;
              border-bottom: 1px solid $gray;
              @include flex();
              align-items: flex-start;
              .count {
                display: inline-block;
                margin-right: 10px;
                @include font(14);
                color: $blackColor;
                width: 30px;
              }
              .infoBox {
                width: calc(100% - 40px);
                @include flex();
                align-items: flex-start;
                .imgBox {
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                  img {
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                  }
                  .image{
                    max-width: 100%;
                    height: auto;
                    width: 100px;
                  }
                }
                .data {
                  width: calc(100% - 40px);
                  .title {
                    @include font(13);
                    font-weight: 600;
                    margin: 0 0 10px 0;
                    color: rgb(0, 123, 255);
                    cursor: pointer;
                  }
                  a {
                    text-decoration: none;
                    &:hover {
                      .title {
                        color:#0056b3;
                      }
                    }
                  }
                  .flexBox {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;
                    span {
                      display: inline-block;
                      @include font(13);
                      color: $black;
                      line-height: 1.4;
                      width: calc(100% - 30px);
                    }
                    .forwardLink {
                      text-decoration: none;
                      display: inline-block;
                      padding: 5px;
                      svg {
                        .a {
                          fill: $lightBlue;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .manageLink {
            text-align: center;
            padding: 15px;
            a {
              text-decoration: none;
              @include font(14);
              color: $baseColor;
              display: inline-block;
              cursor: pointer;
              &:hover {
                color: #b12c1a;
              }
            }
          }
          &.sticky-box {
            position: -webkit-sticky;
            position: sticky;
            top: 65px;
            z-index: 1;
          }
        }
        .showMoreless {
          text-align: center;
          margin: 15px 0;
          display: none;
          a {
            text-decoration: none;
            display: inline-block;
            @include font(14);
            color: $baseColor;
            span {
              display: inline-block;
              &.icon {
                margin-left: 10px;
                svg {
                  .a {
                    fill: $baseColor;
                  }
                }
              }
            }
          }
        }
      }
      .dataBox {
        width: calc(100% - 520px);
        margin: 0 10px;

        .postBox {
          padding: 10px 15px;
          background-color: $white;
          margin-bottom: 15px;
          @include shadow(1px, 2px, 2px, #00000017);
          @include radius(4px);
          div {
            &.postInputBox {
              @include flex();
              align-items: center;
              .imgBox {
                @include flex();
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border: 1px solid $border;
                overflow: hidden;
                margin-right: 10px;
                @include radius(50%);
                .image{
                  max-width: 100%;
                  height: auto;
                  width: 100px;
                }
                img {
                  max-width: 100%;
                  height: auto;
                  &.defaultUserImg {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    border: 1px solid $border;
                    text-align: center;
                    padding: 10px;
                  }
                }
              }
              input {
                width: calc(100% - 50px);
                border: 1px solid $gray;
                outline: none;
                box-shadow: none;
                padding: 10px;
                @include radius(30px);
                @include font(16);
                color: $black;
                &::placeholder {
                  color: $black;
                }
              }
            }
            &.flexBox {
              width: calc(100% - 50px);
              margin-left: auto;
              margin-top: 15px;
              ul {
                @include flex();
                align-items: center;
                li {
                  list-style: none;
                  margin-right: 20px;
                  &:last-child {
                    margin-right: 0;
                  }
                  a {
                    text-decoration: none;
                    padding: 5px 10px;
                    display: inline-flex;
                    align-items: center;
                    color: $black;
                    @include font(13);
                    span {
                      display: inline-flex;
                      cursor: pointer;
                      &.icon {
                        cursor: pointer;
                        margin-right: 10px;
                        label {
                          cursor: pointer;
                        }
                        & + label {
                          cursor: pointer;
                        }
                      }
                    }
                    &.videoLink {
                      span {
                        &.icon {
                          svg {
                            .a {
                              fill: #19d7cd;
                            }
                          }
                        }
                      }
                    }
                    &.linkingLink {
                      span {
                        &.icon {
                          svg {
                            .a {
                              fill: none;
                              stroke: $baseColor;
                              stroke-linecap: round;
                              stroke-linejoin: round;
                              stroke-width: 2px;
                            }
                          }
                        }
                      }
                    }
                    &.photoLink {
                      span {
                        &.icon {
                          svg {
                            .a {
                              fill: $yellow;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .tabsBox {
          .tabFlex {
            @include flex();
            .tab {
              margin-right: 10px;
              a {
                padding: 10px 10px;
                display: inline-flex;
                @include font(16);
                color: $black;
                text-decoration: none;
                position: relative;
                &:hover {
                  color: $baseColor;
                }
              }
              &:last-child {
                margin-right: 0;
              }
              &.selected {
                a {
                  color: $baseColor;
                  border-bottom: 1px solid $baseColor;
                }
              }
            }
          }
          .filterBox {
            margin: 10px 0;
            @include flex();
            align-items: center;
            justify-content: space-between;
            .half {
              width: calc(50% - 10px);
              select {
                padding: 13px 10px;
                background-color: $white;
                outline: none;
                @include radius(4px);
                border: 1px solid $gray;
                width: 100%;
                appearance: none;
                background-image: url(../img/PolygonArrow.svg);
                background-repeat: no-repeat;
                background-position: 95% center;
                color: $black;
                line-height: 18px;
                @include font(14);
              }
            }
          }
          .shareDataBox {
            .item {
              background-color: $white;
              padding: 20px 15px;
              @include shadow(1px, 2px, 2px, #00000017);
              margin-bottom: 15px;
              .imgDataFlexBox {
                @include flex();
                align-items: flex-start;
                margin-bottom: 10px;
                .imgBox {
                  @include flex();
                  justify-content: center;
                  align-items: center;
                  width: 50px;
                  height: 50px;
                  border: 1px solid $border;
                  overflow: hidden;
                  margin-right: 10px;
                  @include radius(50%);
                  .image{
                    max-width: 100%;
                    height: auto;
                    width: 100%;
                  }
                  img {
                    max-width: 100%;
                    height: auto;
                    &.defaultUserImg {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      border: 1px solid $border;
                      text-align: center;
                      padding: 10px;
                    }
                  }
                }
                .dataFlexBox {
                  width: calc(100% - 60px);
                  @include flex();
                  align-items: flex-start;
                  justify-content: space-between;
                  div {
                    width: calc(100% - 65px);
                    h5 {
                      margin: 0 0 5px 0;
                      @include font(15);
                      color: $blackColor;
                      line-height: 24px;
                      &.bluehighLight {
                        @include transition();
                        &:hover {
                          color:#0056b3 !important;
                        }
                      }
                      span {
                        display: inline-block;
                        color: $baseColor;
                      }
                      a {
                        text-decoration: none;
                      }
                      .redhighLight {
                        a {
                          &:hover {
                            color: #b12c1a !important;
                          }
                        }
                      }
                      .bluehighLight {
                        @include transition();
                        &:hover {
                          color:#0056b3 !important;
                        }
                      }
                    }
                    > span {
                      display: block;
                      color: rgba(62, 62, 62, 0.7);
                      @include font(13);
                    }
                    &.action {
                      @include flex();
                      align-items: center;
                      width: auto;
                      margin-top: -5px;
                      position: relative;
                      a {
                        &.pinIcon {
                          display: inline-block;
                          cursor: pointer;
                          text-decoration: none;
                          margin-right: 20px;
                          padding: 5px;
                          svg {
                            .a {
                              fill: $baseColor;
                            }
                          }
                        }
                        &.timelinePinIcon{
                          display: inline-block;
                          text-decoration: none;
                          margin-right: 20px;
                          padding: 5px;
                          svg {
                            .a {
                              fill: $baseColor;
                            }
                          }
                        }
                      }
                      button {
                        background-color: transparent;
                        border: none;
                        padding: 5px;
                        svg {
                          .a {
                            fill: $black;
                          }
                        }
                        &::before {
                          display: none;
                        }
                        &::after {
                          display: none;
                        }
                      }
                      .dropdown-menu {
                        width: auto;
                        left: auto !important;
                        right: 0;
                        transform: none !important;
                        top: 25px !important;
                        z-index: 2;
                        max-width: 142px;
                        @include radius(4px);
                        border: none;
                        @include shadow(0, 3px, 11px, #00000029);
                        min-width: auto;
                        a {
                          padding: 5px 10px;
                          span {
                            display: inline-block;
                            @include font(14);
                            color: $black;
                            &.icon {
                              margin-right: 10px;
                              svg {
                                .a {
                                  fill: $black;
                                }
                              }
                            }
                          }
                          &.deleteLink {
                            span {
                              &.icon {
                                svg {
                                  .a {
                                    fill: none;
                                    stroke: $black;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 1.5px;
                                  }
                                }
                              }
                            }
                            &:hover {
                              span {
                                &.icon {
                                  svg {
                                    .a {
                                      fill: none;
                                      stroke: $baseColor;
                                      stroke-linecap: round;
                                      stroke-linejoin: round;
                                      stroke-width: 1.5px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &:hover,
                          &.active {
                            background: #f8f9fa;
                            color: $baseColor;
                            span {
                              color: $baseColor;
                              &.icon {
                                svg {
                                  .a {
                                    fill: $baseColor;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .description {
                width: calc(100% - 60px);
                @include font(14);
                color: $black;
                line-height: 18px;
                margin: 0 0 20px auto;
              }
              .shareLinkBox a:hover span {
                color: $baseColor !important;
              }
              .shareLinkBox a:hover {
                color: $baseColor !important;
              }
              .shareLinkBox a:hover svg .a {
                fill: $baseColor !important;
                stroke: $baseColor !important;
              }
              .shareLinkBox {
                @include flex();
                align-items: center;
                justify-content: space-around;
                padding-top: 10px;
                border-top: 1px solid $gray;
                width: calc(100% - 60px);
                margin-left: auto;
                > div {
                  margin-left: 10px;
                  width: calc(100% - 40px);
                  text-align: center;
                }
                a {
                  text-decoration: none;
                  cursor: pointer;
                  display: inline-block;
                  color: $black;
                  padding: 5px;
                  &.shareLink {
                    text-decoration: none;
                    cursor: pointer;
                    display: inline-block;
                    padding: 5px;
                    span {
                      display: inline-block;
                      @include font(14);
                      color: $black;
                      &.icon {
                        margin-left: 10px;
                        svg {
                          .a {
                            fill: $black;
                          }
                        }
                      }
                    }
                  }

                  &.fireGray {
                    margin-right: 0;
                    display: inline-block;
                    @include font(14);
                    color: $black;
                    position: relative;
                    span {
                      padding-left: 10px;
                    }
                    &:after {
                      content: "";
                      position: absolute;
                      left: -2px;
                      top: -2px;
                      height: 30px;
                      width: 30px;
                      background: url(../img/black-fire.png) no-repeat;
                      background-size: 12px;
                    }
                    &:hover {
                      &:after {
                        background-image: url(../img/color-fire.png);
                      }
                    }
                    svg {
                      display: none;
                      margin-right: 10px;
                      .a {
                        fill: $black;
                        stroke: $black;
                        stroke-width: 0.5px;
                      }
                    }
                  }
                  &.fireRed {
                    position: relative;
                    span {
                      padding-left: 10px;
                    }
                    &:after {
                      content: "";
                      position: absolute;
                      left: -2px;
                      top: 0;
                      height: 30px;
                      width: 30px;
                      background: url(../img/color-fire.png) no-repeat;
                      background-size: 12px;
                    }
                    svg {
                      display: none;
                      margin-right: 10px;
                      .a {
                        fill: $baseColor;
                        stroke: $baseColor;
                        stroke-width: 0.5px;
                      }
                      .b {
                        fill: $baseColor;
                      }
                    }
                  }
                }
              }

              .videoSection {
                width: calc(100% - 60px);
                margin-left: auto;
                margin-bottom: 10px;
                //.slider-wrapper{
                //  height: auto!important;
                //}
                .swiper-button-next:after, .swiper-button-prev:after{
                  @include font(23);
                  color: #888888;
                }
                .video {
                  @include font(13);
                  margin-bottom: 10px;
                  overflow: hidden;
                  .carousel {
                    &:empty {
                      display: none;
                    }
                    .slider-wrapper {
                      .slider {
                        .slide {
                          &:empty {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                  .videoinnerWrap {
                    position: relative;
                  }

                  a {
                    text-decoration: none;
                    display: block;
                    color:$lightBlue;
                    position: relative;
                    line-height:18px;
                    height: auto;
                    &:hover {
                      color:#0056b3;
                    }
                  }
                  img {
                    max-width: 100%;
                    width: auto;
                    height: auto;
                    margin: auto;
                    margin-right: auto;
                    text-align: center;
                    display: block;
                  }
                   video {
                    max-width: 100%;
                    width: 100%;
                    object-fit: contain;
                    height: 250px;
                    margin: auto;
                    margin-right: auto;
                    text-align: center;
                    display: block;
                  }
                  iframe {
                    max-width: 100%;
                    width: 100%;
                    object-fit: contain;
                    height: 250px;
                    margin: auto;
                    margin-right: auto;
                    text-align: center;
                    display: block;
                  }
                  .video-play-button {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    cursor: pointer;
                    .video-play-inner {
                      @include flex();
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    }
                    button {
                      @include flex();
                      justify-content: center;
                      align-items: center;
                      background: $baseColor;
                      width: 32px;
                      height: 32px;
                      @include radius(50%);
                      outline: none;
                      border: none;

                      svg path {
                        fill: $white;
                      }
                    }
                  }
                  .control-arrow{
                    top: 70px;
                    bottom: 70px;
                    &:hover {
                      background: none;
                    }
                  }
                  .carousel {
                    .control-next, 
                    .control-prev {
                      &.control-arrow {
                        &:before {
                          border-right-color:$gray;
                        }
                      }
                    }
                    .carousel {
                      .control-arrow {
                        opacity:.8;
                      }
                    }
                  }
                }
                .info {
                  h5 {
                    @include font(15);
                    color: $black;
                    margin: 0 0 10px 0;
                  }
                  span {
                    display: block;
                    @include font(13);
                    color: rgba(62, 62, 62, 0.5);
                  }
                }
              }
            }
          }
          .loadMoreBox {
            margin-top: 10px;
            text-align: center;
            padding: 15px;
            background-color: $white;
            @include shadow(1px, 2px, 2px, #00000017);
            @include radius(4px);
            a {
              display: inline-block;
              text-decoration: none;
              @include font(14);
              color: $black;
              cursor: pointer;
            }
          }
        }
      }
      .rightDataBox {
        width: 250px;
        .verificationBox {
          margin-bottom: 15px;
          @include radius(4px);
          background-color: $white;
          @include shadow(1px, 2px, 2px, #00000017);
          .head {
            @include flex();
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 0 10px;
            span {
              &.icon {
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;
                svg {
                  .a {
                    fill: #ff4a31;
                  }
                  .b {
                    fill: $white;
                  }
                }
              }
            }
            .title {
              color: $black;
              @include font(14);
              font-weight: 600;
              margin: 0 auto 0 0;
            }
            button {
              &.btn {
                background-color: transparent;
                padding: 5px 5px;
                min-width: auto;
                max-width: 30px;
                svg {
                  .a {
                    fill: $black;
                  }
                }
              }
            }
          }
          .stepInfo {
            padding: 10px;
            .steps {
              @include flex();
              margin-bottom: 10px;
              margin-left: 5px;
              span {
                height: 9px;
                border: 1px solid $white;
                @include radius(7px);
                background: $gray;
                display: inline-block;
                width: calc(20% + 5px);
                margin-left: -5px;
                &.passed {
                  background: $lightBlue;
                  &.active {
                    position: relative;
                    z-index: 1;
                  }
                }
              }
            }
            .flexBox {
              @include flex();
              align-items: center;
              justify-content: space-between;
              p {
                @include font(13);
                color: $black;
                width: calc(100% - 35px);
              }
              .icon {
                svg {
                  .a {
                    fill: $lightBlue;
                  }
                }
              }
            }
          }
          .collapse {
            .card {
              border-top: 1px solid $gray;
              @include radius(0);
              padding: 10px;
              border-left: none;
              border-right: none;
              border-bottom: none;
              .verificationSteps {
                margin: 0 0 5px 0;
                padding: 0;
                li {
                  list-style: none;
                  margin-bottom: 10px;
                  @include flex();
                  align-items: center;
                  justify-content: space-between;
                  span {
                    display: inline-block;
                    @include font(13);
                    color: $black;
                    &.icon {
                      svg {
                        width: 18px;
                        height: auto;
                        .a {
                          fill: $lightBlue;
                        }
                        .b {
                          fill: #fafafa;
                        }
                      }
                    }
                  }
                  &.completeStep {
                    span {
                      color: $baseColor;
                      &.icon {
                        svg {
                          width: 18px;
                          height: auto;
                          .a {
                            fill: $baseColor;
                          }
                        }
                      }
                    }
                  }
                  &.passed {
                    span {
                      color: #999999;
                      position: relative;
                      &::before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                        height: 1px;
                        background-color: #999;
                      }
                      &.icon {
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
              .whyverified {
                background-color: $bgLightGray;
                padding: 10px;
                .title {
                  @include flex();
                  align-items: center;
                  @include font(13);
                  color: $black;
                  font-weight: 600;
                  margin: 0 0 10px 0;
                  span {
                    display: inline-block;
                    &.icon {
                      margin-left: 10px;
                      svg {
                        width: 22px;
                        height: auto;
                        .a {
                          fill: $lightBlue;
                        }
                      }
                    }
                  }
                }

                .info {
                  @include font(12);
                  color: $black;
                  line-height: 16px;
                  .learnMore {
                    text-decoration: none;
                    display: inline-block;
                    color: $lightBlue;
                    font-weight: 600;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .scoutRate {
          margin-bottom: 15px;
          background-color: $white;
          @include radius(4px);
          @include shadow(1px, 2px, 2px, #00000017);
          padding: 15px 15px;
          .headFlex {
            @include flex();
            align-items: center;
            margin-bottom: 10px;
            div {
              &.imgBox {
                flex: 0 0 50px;
                width: 50px;
                height: 50px;
                margin-right: 10px;
                overflow: hidden;
                @include radius(50%);
                img {
                  max-width: 100%;
                  width: 100%;
                  height:100%;
                  object-fit: cover;
                  @include radius(50%);
                }
                .image{
                  max-width: 100%;
                  height: auto;
                }
              }
              .scoutRate-detail{
                width: calc(100% - 60px);
              }
              h5 {
                @include font(16);
                color: $black;
                font-weight: 600;
              }
            }
          }
          .desc {
            @include font(13);
            color: $black;
            line-height: 16px;
            margin-bottom: 10px;
          }
          button {
            &.scoutBtn {
              @include radius(4px);
              padding: 15px;
              background-color: $yellow;
              cursor: pointer;
              outline: none;
              box-shadow: none;
              border: none;
              @include font(14);
              color: $white;
            }
          }
        }
        .recruitAdvisor {
          margin-bottom: 15px;
          background-color: $white;
          @include radius(4px);
          @include shadow(1px, 2px, 2px, #00000017);
          padding: 15px;
          .headFlex {
            @include flex();
            align-items: center;
            margin-bottom: 10px;
            div {
              &.imgBox {
                flex: 0 0 50px;
                width: 50px;
                height: 50px;
                margin-right: 10px;
                @include radius(50%);
                img {
                  max-width: 100%;
                  width:100%;
                  height: 100%;
                  object-fit: cover;
                  @include radius(50%);
                }
                .image{
                  max-width: 100%;
                  height: auto;
                }
              }
              .recruitAdvisor-detail {
                width: calc(100% - 50px);

              }
              h5 {
                @include font(16);
                color: $black;
                font-weight: 600;
              }
            }
          }
          .desc {
            @include font(13);
            color: $black;
            line-height: 16px;
            margin-bottom: 10px;
          }
          button {
            &.questionBtn {
              @include radius(4px);
              padding: 15px;
              background-color: $green;
              cursor: pointer;
              outline: none;
              box-shadow: none;
              border: none;
              @include font(14);
              color: $white;
            }
          }
         
        }
        .importNotice {
          margin-bottom: 15px;
          background-color: $white;
          @include radius(4px);
          @include shadow(1px, 2px, 2px, #00000017);
          padding: 15px;
          .headFlex {
            @include flex();
            align-items: center;
            margin-bottom: 10px;
            div {
              &.imgBox {
                margin-right: 10px;
                svg {
                  .a {
                    fill: url(#a);
                  }
                  .b {
                    fill: url(#b);
                  }
                  .c {
                    fill: url(#c);
                  }
                  .d {
                    fill: url(#d);
                  }
                  .e {
                    fill: url(#e);
                  }
                  .f {
                    fill: $white;
                  }
                }
              }
              h5 {
                @include font(16);
                font-weight: 600;
                color: $black;
              }
            }
          }
          .desc {
            @include font(13);
            color: $black;
            line-height: 16px;
            margin-bottom: 10px;
          }
          .knowMoreLink {
            display: inline-block;
            text-decoration: underline;
            @include font(14);
            color: $lightBlue;
            cursor: pointer;
          }
          &.sticky-box {
            position: -webkit-sticky;
            position: sticky;
            top: 65px;
            z-index: 1;
          }
        }
        .verificationProcess {
          background-color: #343434;
          position: fixed;
          right: 20px;
          bottom: 20px;
          width: 320px;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          padding: 15px;
          box-shadow: 1px 2px 2px #00000017;
          -webkit-box-shadow: 1px 2px 2px #00000017;
          -moz-box-shadow: 1px 2px 2px #00000017;
          transition: all 0.5s ease-in;
          -webkit-transition: all 0.5s ease-in;
          -moz-transition: all 0.5s ease-in;
          transform: translateX(120%);
          -webkit-transform: translateX(120%);
          -moz-transform: translateX(120%);
          z-index: 1;
          &.active {
            transform: translateX(0%);
            -webkit-transform: translateX(0%);
            -moz-transform: translateX(0%);
          }
          .head {
            @include flex();
            align-items: center;
            margin-bottom: 10px;
            justify-content: space-between;
            .title {
              @include flex();
              align-items: center;
              span {
                display: inline-block;
                @include font(16);
                font-weight: 600;
                color: $white;
                &.icon {
                  margin-right: 10px;
                  svg {
                    .a {
                      fill: url(#a);
                    }
                    .b {
                      fill: url(#b);
                    }
                    .c {
                      fill: url(#c);
                    }
                    .d {
                      fill: url(#d);
                    }
                    .e {
                      fill: url(#e);
                    }
                    .f {
                      fill: $white;
                    }
                  }
                }
              }
            }
            button {
              &.closeBtn {
                outline: none;
                border: none;
                background-color: transparent;
                cursor: pointer;
                svg {
                  .a {
                    fill: $white;
                    opacity: 0.5;
                  }
                }
              }
            }
          }
          .steps {
            @include flex();
            margin-right: 5px;
            margin-bottom: 10px;
            span {
              width: 15%;
              height: 9px;
              display: inline-block;
              @include radius(7px);
              border: 1px solid #343434;
              background-color: #dddddd;
              margin-right: -5px;
              &.passed {
                background-color: $green;
              }
            }
          }
          .setpsInfo {
            display: block;
            @include font(12);
            color: $white;
            line-height: 16px;
            margin-bottom: 10px;
          }
          .info {
            display: block;
            @include font(12);
            color: $white;
            line-height: 16px;
            margin-bottom: 10px;
          }
          .verifyProfileLink {
            text-decoration: underline;
            display: inline-block;
            cursor: pointer;
            @include font(14);
            color: $yellow;
          }
        }
      }
    }
  }
}
/*************************getVerifiedModal css*******************************************/
.modal {
  &.getVerifiedModal {
    .modal-dialog {
      max-width: 400px;
      margin: 15px;
      max-height: calc(100% - 30px);
      width: calc(100% - 30px);
      .modal-content {
        .modal-header {
          padding: 0 10px;
          border-bottom: none;
          button {
            &.close {
              min-width: auto;
              padding: 5px;
              float: none;
              margin: 0 0 0 auto;
              outline: none;
              svg {
                .a {
                  opacity: 0.7;
                  fill: $black;
                }
              }
            }
          }
        }
        .modal-body {
          padding: 0 15px 15px 15px;
          .heading {
            margin: 0 auto 10px auto;
            max-width: 230px;
            @include font(18);
            color: $blackColor;
            font-weight: 600;
            text-align: center;
          }
          .profileBox {
            .profileImgBox {
              position: relative;
              width: 100px;
              height: 100px;
              @include radius(50%);
              margin: 0 auto;
              .imgBox {
                width: 100px;
                height: 100px;
                @include radius(50%);
                margin: 0 auto;
                img {
                  max-width: 100%;
                  height: auto;
                }
                .image{
                  max-width: 100%;
                  height: auto;
                }
              }
              .icon {
                position: absolute;
                bottom: 0;
                right: -10px;
                svg {
                  .a {
                    fill: #ff4a31;
                  }
                  .b {
                    fill: $white;
                  }
                }
              }
            }
            .name {
              margin: 10px 0 10px 0;
              @include font(16);
              color: $black;
              font-weight: 600;
              text-align: center;
            }
            ul {
              margin: 0 0 5px 0;
              li {
                list-style: none;
                margin-bottom: 10px;
                @include flex();
                align-items: flex-start;
                justify-content: center;
                span {
                  display: inline-block;
                  @include font(14);
                  color: $black;
                }
                label {
                  display: inline-block;
                  @include font(14);
                  color: $black;
                  margin-right: 5px;
                }
              }
            }
          }
          p {
            &.info {
              @include font(14);
              color: $black;
              line-height: 18px;
              text-align: center;
              max-width: 300px;
              margin: 0 auto 10px auto;
            }
          }
          .btnBox {
            margin: 20px 0;
            text-align: center;
            button {
              &.verifyBtn {
                background: $lightBlue;
                @include radius(4px);
                border: none;
                @include font(14);
                color: $white;
                padding: 15px 20px;
                outline: none;
              }
            }
          }
        }
      }
    }
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}
/*************************shareUpdateModal css****************************************/
.modal {
  &.shareUpdateModal {
    .modal-dialog {
      max-width: 400px;
      margin: 15px;
      max-height: calc(100% - 30px);
      width: calc(100% - 30px);
      .modal-content {
        .modal-header {
          padding: 0 10px;
          border-bottom: none;
          button {
            &.close {
              min-width: auto;
              padding: 5px;
              float: none;
              margin: 0 0 0 auto;
              outline: none;
              svg {
                .a {
                  opacity: 0.7;
                  fill: $black;
                }
              }
            }
          }
        }
        .modal-body {
          padding: 0 15px 15px 15px;
          .heading {
            margin: 0 auto 15px auto;
            max-width: 230px;
            @include font(18);
            color: $blackColor;
            font-weight: 600;
            text-align: center;
          }
          .socialFlexBox {
            @include flex();
            max-width: 260px;
            margin: 0 auto;
            justify-content: space-between;
            .twitter {
              button {
                width: 100px;
                height: 100px;
                @include flex();
                align-items: center;
                justify-content: center;
                border: 1px solid $lightBlue;
                @include radius(50%);
                color: $lightBlue;
                text-decoration: none;
                cursor: pointer;
                background-color: transparent;
                outline: none;
                div {
                  span {
                    @include font(14);
                    display: block;
                    text-align: center;
                    &.icon {
                      margin-bottom: 5px;
                      svg {
                        width: 30px;
                        height: auto;
                        fill: $lightBlue;
                      }
                    }
                  }
                }
              }
            }
            .fbook {
              button {
                width: 100px;
                height: 100px;
                @include flex();
                align-items: center;
                justify-content: center;
                border: 1px solid $blue;
                @include radius(50%);
                color: $blue;
                text-decoration: none;
                cursor: pointer;
                background-color: transparent;
                outline: none;
                div {
                  span {
                    @include font(14);
                    display: block;
                    text-align: center;
                    &.icon {
                      margin-bottom: 5px;
                      svg {
                        width: 30px;
                        height: auto;
                        fill: $blue;
                        path {
                          fill: $blue;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          p {
            &.info {
              @include font(14);
              line-height: 18px;
              color: $black;
              text-align: center;
              max-width: 300px;
              margin: 20px auto;
            }
          }
          .linkBox {
            width: 100%;
            @include flex();
            margin-bottom: 10px;
            p {
              color: $black;
              @include font(13);
              padding: 10px;
              border: 1px solid $gray;
              @include radius(4px 0 0 4px);
              @include flex();
              align-items: center;
              width: calc(100% - 78px);
            }
            button {
              &.copyBtn {
                background: $lightBlue;
                color: $white;
                @include radius(4px);
                border: none;
                @include font(14);
                padding: 15px 20px;
                outline: none;
                @include radius(0 4px 4px 0);
              }
            }
          }
        }
      }
    }
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

/**********************************************************/

.modal {
  &.statusUpdateModal {
    .modal-dialog {
      max-width: 540px;
      margin: 15px;
      max-height: calc(100% - 30px);
      width: calc(100% - 30px);
      .modal-content {
        .modal-header {
          padding: 20px;
          border-bottom: none;
          align-items: center;
          position: relative;
          button {
            &.close {
              min-width: auto;
              padding: 5px;
              float: none;
              margin: 0 0 0 auto;
              outline: none;
              svg {
                .a {
                  opacity: 0.7;
                  fill: $black;
                }
              }
            }
          }
          &::after {
            content: "";
            display: inline-block;
            width: calc(100% - 30px);
            bottom: 0;
            left: 15px;
            position: absolute;
            height: 1px;
            background-color: $gray;
          }
        }
        .modal-body {
          padding: 20px;
          .profileFlexBox {
            @include flex();
            align-items: flex-start;
            margin-bottom: 15px;
            .imgBox {
              width: 50px;
              height: 50px;
              @include radius(50%);
              margin-right: 10px;
              @include flex();
              align-items: center;
              justify-content: center;
              img {
                max-width: 100%;
                height: auto;
              }
              .image{
                max-width: 100%;
                height: auto;
              }
            }
            .info {
              width: calc(100% - 60px);
              h5 {
                &.name {
                  @include font(16);
                  margin: 0 0 10px 0;
                  color: $blackColor;
                }
              }
              .selectField {
                select {
                  width: 100px;
                  @include radius(30px);
                  border: 1px solid #707070;
                  @include font(14);
                  padding: 3px 15px 3px 10px;
                  color: $black;
                  appearance: none;
                  background: url(../img/arrowdown.png) no-repeat;
                  background-size: 10px;
                  background-position: 95% center;
                }
              }
            }
          }
          .textBox {
            margin-bottom: 15px;
            textarea {
              padding: 10px;
              width: 100%;
              height: 150px;
              overflow-x: hidden;
              overflow-y: auto;
              background-color: #f9f9f9;
              @include radius(4px);
              @include font(16);
              line-height: 22px;
              color: $black;
              border: none;
              outline: none;
              resize: none;
            }
          }
          .flexBox {
            @include flex();
            align-items: center;
            justify-content: space-between;
            ul {
              @include flex();
              align-items: center;
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                margin-right: 15px;
                @include flex();
                align-items: center;
                a {
                  text-decoration: none;
                  display: inline-block;
                  cursor: pointer;
                  padding: 5px;
                  &.videoLink {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: #19d7cd;
                          }
                        }
                      }
                    }
                  }
                  &.attachLink {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: none;
                            stroke: $baseColor;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 2px;
                          }
                        }
                      }
                    }
                  }
                  &.photoLink {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: $yellow;
                          }
                        }
                      }
                    }
                  }
                  span {
                    @include font(14);
                    display: inline-block;
                    color: $black;
                    &.icon {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
            button {
              outline: none;
              cursor: pointer;
              &.postBtn {
                @include font(14);
                color: $white;
                background-color: $lightBlue;
                @include radius(4px);
                padding: 15px 20px;
                border: none;
              }
            }
          }
        }
      }
    }
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

/************************************************************/
@media screen and (max-width: 1199px) {
  .athleteDashSection {
    > .container {
      > .flexBox {
        .dataBox {
          .postBox {
            div {
              &.flexBox {
                ul {
                  li {
                    margin-right: 0;
                    a {
                      padding-left: 5px;
                      padding-right: 5px;
                      span {
                        &.icon {
                          margin-right: 5px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .athleteDashSection {
    > .container {
      max-width: 100%;
      .flexBox {
        .leftDataBox {
          width: 230px;
          .AthleteInfoBox {
            .AthleteInfo {
              padding: 15px 10px;
            }
            .activityCollegeBox {
              padding: 15px 10px;
            }
          }
          .profileDataBox {
            padding: 15px 10px;
          }
          .teamOfferBox {
            padding: 15px 10px;
          }
          .targetCollege {
            .head {
              padding: 15px 10px 0 10px;
            }
            ul {
              li {
                padding: 10px;
              }
            }
            .manageLink {
              padding: 10px;
            }
          }
        }
        .dataBox {
          width: calc(100% - 240px);
          margin-right: 0;
          margin-bottom: 15px;
          .postBox {
            padding: 10px;
            div {
              &.postInputBox {
                input {
                  @include font(14);
                }
              }
              &.flexBox {
                width: 100%;
                overflow: hidden;
                ul {
                  li {
                    a {
                      padding: 5px;
                      @include font(11);
                    }
                  }
                }
              }
            }
          }
          .tabsBox {
            .shareDataBox {
              .item {
                padding: 10px;
                .imgDataFlexBox {
                  .imgBox {
                    width: 40px;
                    height: 40px;
                  }
                  .dataFlexBox {
                    width: calc(100% - 50px);
                    div {
                      h5 {
                        @include font(13);
                      }
                      span {
                        @include font(11);
                      }
                    }
                  }
                }
                .description {
                  width: 100%;
                  @include font(12);
                }
                .shareLinkBox {
                  width: 100%;
                }
                .videoSection {
                  width: 100%;
                }
              }
            }
          }
        }
        .rightDataBox {
          width: calc(100% - 240px);
          margin-left: auto;
          .scoutRate {
            padding: 10px;
            button {
              &.scoutBtn {
                padding: 10px 15px;
              }
            }
          }
          .recruitAdvisor {
            padding: 10px;
            button {
              &.questionBtn {
                padding: 10px 15px;
              }
            }
          }
          .importNotice {
            padding: 10px;
          }
          .verificationProcess {
            right: 15px;
            width: 320px;
            padding: 10px;
            .steps {
              span {
                width: 20%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .athleteDashSection {
    > .container {
      max-width: 540px;
      .flexBox {
        .leftDataBox {
          order: 1;
          width: 100%;
          margin: 0 auto 0 auto;
          .AthleteInfoBox {
            .AthleteInfo {
              background-size: cover;
              .profileBox {
                margin-top: 0;
                .playerName {
                  margin-bottom: 5px;
                }
                ul {
                  margin-bottom:0;
                  li {
                    margin-bottom: 5px;
                    &:last-child {
                      display: none;
                      margin-bottom: 0;
                    }
                  }
                }
                .imgBoxBlock {
                  width:80px;
                  height: 80px;
                  div {
                    &.imgBox {
                      width:80px;
                      height: 80px;
                      .image {
                        width: 80px!important;;
                        height: 80px;
                      }
                    }
                  }
                }
              }
              .flexBox {
                margin-top:10px;
                padding-top:12px;
                display: none;
              }
            }
            .activityCollegeBox {
              display: none;
            }
            &.show {
              .AthleteInfo {
                .profileBox {
                  ul {
                    li {
                      &:last-child {
                        display: block;
                      }
                    }
                  }
                }
                .flexBox {
                  display: block;
                }
              }
              .activityCollegeBox {
                display: block;
              }
            }
          }
          .profileDataBox, .teamOfferBox, .targetCollege {
            display: none;
            &.show {
              display: block;
            }
          }
          .showMoreless {
            display: block;
          }
          .targetCollege {
            &.sticky-box {
              position: static;
            }
          }
        }
        .dataBox {
          order: 2;
          width: 100%;
          margin: 0 auto 15px;
        }
        .rightDataBox {
          padding-left: 0;
          order: 3;
          width: 100%;
          margin: 0 auto 10px auto;
          .importNotice {
            &.sticky-box {
              position: static;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .athleteDashSection {
    > .container {
      .flexBox {
        .leftDataBox {
          .AthleteInfoBox {
            .AthleteInfo {
              padding: 15px;
              background-size: calc(100% + 5px);
            }
            .activityCollegeBox {
              padding: 15px;
            }
          }
          .profileDataBox {
            padding: 15px;
          }
          .teamOfferBox {
            padding: 15px;
          }
        }
        .dataBox {
          .tabsBox {
            .shareDataBox {
              .item {
                .videoSection {
                  .video {
                    img {
                      height: auto;
                      max-height: 100%;
                    }
                    video {
                      height: 170px;
                      max-height: 100%;
                      background: #FFFFFF;
                      opacity: 0.5;
                    }
                    iframe {
                      height: 170px;
                      max-height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
