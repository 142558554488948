@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.my-recruiting-box {
    background: $white;
    padding: 30px 20px;
    border: 1px solid $gray;
    border-radius: 5px;
    .top-block {
        margin-bottom: 20px;
        h1 {
            @include font(18);
            font-weight: 500;
            color: $blackColor;
            margin-bottom: 10px;
            + p {
                @include font(14);
                font-weight: 400;
                line-height: 1.5;
            }
            .save-button {
                padding: 4px 10px;
                background: $lightBlue;
                @include font(13);
                font-weight: 300;
                color: $white;
                @include radius(5px);
                margin-left: 10px;
                cursor: pointer; 
            }
        }
    }
    .tabBox {
        @include radius(4px);
        background-color: $white;
        border: 1px solid #00000038;
        padding: 0 15px;
        margin: 0 auto 25px auto;
        .tabs {
            @include flex();
            align-items: center;
            border: none;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                margin-right: 30px;
                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-decoration: none;
                    padding: 15px 10px;
                    @include font(16);
                    font-weight: 500;
                    color: rgba($black, .5);
                    border: none;
                    .icon{
                        width: 20px;
                        height: auto;
                        margin-left: 5px;
                    }
                    .icon-box {
                        margin-left: 5px;
                        svg {
                            height: 15px;
                        }
                    }
                    &:hover {
                        color: $baseColor;
                        border: 0;
                        border-radius:0;
                    }
                }
                &.current {
                    a {
                        color:$baseColor;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        &::after {
                            content: '';
                            background-color: $baseColor;
                            display: inline-block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 2px;
                        }
                        .icon{
                            width: 20px;
                            height: auto;
                            margin-left: 5px;
                        }
                    }
                }
                &.closeBtnBox {
                    display:none;
                    text-align: right;
                    button {
                        background-color: transparent;
                        padding: 5px;
                        outline: none;
                        border:none;
                        cursor:pointer;
                        svg {
                            .a {
                                opacity:0.7; 
                                fill:$black;
                            }
                        }
                    }
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
        .flexBox {
            display:none;
            div {
                &.menuBtnBox {
                    margin-right: 10px;
                    width:auto;
                    display:block;
                    .tabMenuBtn {
                        border:none;
                        padding:5px 5px;
                        background:transparent;
                        outline:none;
                        cursor:pointer;
                        svg {
                            .a {
                                fill:#343434;
                            }  
                        }
                    }      
                }
                width:calc(100% - 36px);
                @include flex();
                align-items: center;
                p {
                    font-size: 15px;
                    color:$baseColor;
                }
            }
        }
    }
    .tabs-contents {
        .tab-content {
            display: none;
            &.current {
                display: block;
            }
            .top-box {
                margin-bottom: 30px;
                h2 {
                    @include font(16);
                    font-weight: 500;
                    color: $blackColor;
                    margin-bottom: 18px;
                }
            }
            .recruiting-detail-box {
                .recruiting-info-box {
                    @include flex();
                    justify-content: space-between;
                    align-items: center;
                    max-width: 700px;
                    margin-bottom: 20px;
                    .item {
                        width: 48%;
                        margin-right: 4%;
                        padding-right: 4%;
                        @include font(13);
                        color:#9D9D9D;
                        border-right: 1px solid #DEDEDE;
                        &:last-child {
                            margin-right: 0;
                            padding-right: 0;
                            border-right: none;
                        }
                        .icon-box {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 10px;
                        }
                        h4 {
                            @include flex();
                            align-items: center;
                            @include font(14);
                            font-weight: 400;
                            color: $blackColor;
                            margin-bottom: 8px;
                        }
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
                .table-recruiting {
                    padding: 15px 10px;
                    max-width: 700px;
                    width: 100%;
                    border: 1px solid #DEDEDE;
                    @include radius(4px);
                    margin-bottom: 30px;
                    .table {
                        margin-bottom: 0;
                        tr {
                            @include font(16);
                            font-weight: 400;
                            td {
                                padding:8px 10px;
                                color: $black;
                                line-height: 1.4;
                                border: none;
                                vertical-align: middle;
                                &:last-child {
                                    text-align: right;
                                }
                                .btn-recruiting {
                                    background: none;
                                    padding: 8px 12px;
                                    @include font(14);
                                    color: $black;
                                    line-height: 18px;
                                    border: 1px solid #DEDEDE;
                                    @include radius(4px);
                                    min-width: 105px;
                                    outline: none;
                                    .icon-box {
                                        margin-right: 8px;
                                    }
                                    .icon-arrow {
                                        margin-left: 8px;
                                    }
                                }
                            } 
                        }
                    }
                    &.second {
                        padding: 0;
                        .table {
                            thead {
                                tr {
                                    background:#EEEEEE80;
                                    th {
                                        @include font(16);
                                        font-weight: 600;
                                        color: $blackColor;
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    &:first-child {
                                        border-bottom: 1px solid $gray;
                                        td {
                                            padding-bottom: 15px;
                                            @include font(15);
                                            span {
                                                margin-right: 15px;
                                                svg {
                                                    margin-right: 8px;
                                                }
                                                &:last-child {
                                                    margin-right: 0;
                                                }
                                            }
                                        }
                                    }
                                    td {
                                        @include font(16);
                                        color: $blackColor;
                                        padding-top: 15px;
                                        padding-bottom: 0;
                                    }
                                    &:last-child {
                                        td {
                                            padding-bottom: 20px;
                                        }
                                    }
                                }
                            }
                            th, td {
                                padding:15px 20px;
                                color: $black;
                                line-height: 1.4;
                                border: none;
                                vertical-align: middle;
                                text-align: center;
                                &:first-child {
                                    text-align: left;
                                }
                                &:last-child {
                                    text-align: center;
                                }
                                .btn {
                                    background:#f6f6f6;
                                    padding: 8px 12px;
                                    @include font(15);
                                    color: $black;
                                    line-height: 18px;
                                    border: 1px solid #DEDEDE;
                                    @include radius(4px);
                                    min-width: 100px;
                                    text-transform: capitalize;
                                    outline: none;
                                    &.green {
                                        background: $green;
                                        color: $white;
                                    }
                                }
                            } 
                        }
                    }
                }
                .field-box-item {
                    margin-bottom: 25px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    h4, h5 {
                        @include font(18);
                        font-weight: 500;
                        color: $blackColor;
                        margin-bottom: 20px;
                    }
                    h5 {
                        @include font(15);
                        margin-bottom: 15px;
                    }
                    .fieldBox {
                        margin-bottom: 15px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &.flexBox {
                            @include flex();
                            align-items: center;
                            justify-content: space-between;
                            .column {
                              width: calc(33.33% - 10px);
                            }
                            .half {
                                width: calc(50% - 10px);
                            }
                        }
                        label {
                          display: block;
                          @include font(14);
                          font-weight: 400;
                          color: $black;
                          margin-bottom: 12px;
                          sup {
                            top: 8px;
                          }
                        }
                        input {
                          @include font(14);
                          font-weight: 300;
                          color: $black;
                          width: 100%;
                          border: 1px solid $border;
                          @include radius(4px);
                          padding: 0 1em;
                          height: 50px;
                          box-shadow: none;
                          &::placeholder {
                            color: $black;
                          }
                        }
                        select {
                          width: 100%;
                          -webkit-appearance: none;
                          -moz-appearance: none;
                          appearance: none;
                          padding: 14px 35px 14px 10px;
                          @include font(14);
                          font-weight: 300;
                          color:$black;
                          background-image: url(../../../../../athlet/dashboard/homepage/assets/img/arrowdown.png );
                          background-repeat: no-repeat;
                          background-position: bottom 17px right 12px;
                          background-size: 12px;
                          height: 50px;
                          border: 1px solid $border;
                          @include radius(4px);
                        }
                        textarea {
                            padding: 10px 10px;
                            width: 100%;
                            min-height: 120px;
                            @include font(14);
                            color: $black;
                            line-height: 1.5;
                            border: 1px solid $gray;
                            outline: none;
                            box-shadow: none;
                            @include radius(4px);
                            &::placeholder {
                              color: $black;
                            }
                        }
                        .search-box-wrap {
                            max-width: 325px;
                            position: relative;
                            input {
                                padding-right: 40px;
                                background: url(../../../../../../layouts/assets/Img/search-icon.svg) no-repeat;
                                background-position: bottom 17px right 12px;
                            }
                        }
                        .checkbox-group {
                            li {
                                margin-bottom: 15px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                            .checkbox {
                                display: inline-block;
                                vertical-align: middle;
                                position: relative;
                                input[type="checkbox"] {
                                  position: absolute;
                                  width: 100%;
                                  height: 100%;
                                  left: 0;
                                  top: 0;
                                  opacity: 0;
                                  z-index: 1;
                                  @include radius(4px);
                                  cursor: pointer;
                                  &:checked {
                                    + label {
                                      &::before {
                                        border-color: $lightBlue;
                                      }
                                      &::after {
                                        content: "";
                                        display: inline-block;
                                        background: url(../../../../../athlet/dashboard/account/accountSetting/assets/img/checkBlue.png) no-repeat;
                                        background-size: cover;
                                        position: absolute;
                                        width: 12px;
                                        height: 12px;
                                        left: 3px;
                                        top: 5px;
                                      }
                                    }
                                  }
                                }
                                label {
                                  padding-left: 30px;
                                  position: relative;
                                  @include font(15);
                                  font-weight: 300;
                                  color: $black;
                                  line-height: 18px;
                                  cursor: pointer;
                                  margin-bottom: 0;
                                  &::before {
                                    content: "";
                                    display: inline-block;
                                    position: absolute;
                                    width: 18px;
                                    height: 18px;
                                    left: 0;
                                    top: 0;
                                    border: 1px solid $black;
                                    @include radius(2px);
                                  }
                                }
                            }
                        }
                    }
                }
                .btn-wrap {
                    @include flex();
                    align-items: center;
                    padding-top: 10px;
                    .btn {
                        padding: 10px 15px;
                        @include font(18);
                        font-weight: 400;
                        letter-spacing: 0.27px;
                        line-height: 26px;
                        min-width: 120px;
                        &.blue {
                            background: $lightBlue;
                        }
                    }
                    .next-prev-button {
                        margin-left: 45px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        button {
                            display: flex;
                            vertical-align: middle;
                            background: none;
                            border: none;
                            @include font(16);
                            font-weight: 400;
                            color:rgba($black, .6);
                            padding: 0;
                            margin-right: 10px;
                            @include transition();
                            cursor: pointer;
                            svg {
                                width: 18px;
                                height: 18px;
                                margin-top: -1px;
                              }
                              &:last-child {
                                margin-right: 0;
                              }
                              &:hover {
                                color: $baseColor;
                              }                        
                        }
                    }
                }
            }
        }
    }
}
.recruting-modal {
    .modal-dialog {
        max-width: 540px;
    }
    .modal-content {
        border: 1px solid $border;
        box-shadow: 0px 3px 15px #000000A1;
    }
    .modal-header {
        padding: 15px 20px;
        border-bottom: 1px solid rgba($border, .4%);
        .modal-title {
          @include font(18);
          font-weight: 600;
          color: $blackColor;
        }
        .close {
            @include font(30);
            font-weight: 300;
            color: $black;
            opacity: 1;
            outline: none;
        }
    }
    .modal-body {
        padding: 20px 20px 25px;
        &.bottom-border {
          margin-bottom: 25px;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba($border, .4%);
        }
        .fieldBox {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            h6 {
                @include font(16);
                font-weight: 500;
                color:$black;
                margin-bottom: 15px;
                + p {
                    @include font(14);
                    font-weight: 300;
                    color:$black;
                    line-height: 24px;
                    margin-bottom: 10px;
                    margin-top: -5px;
                }
            }
            textarea {
              @include font(14);
              font-weight: 300;
              border: 1px solid $border;
              width: 100%;
              min-height: 100px;
              @include radius(4px);
            }
            .radioBox {
                .option {
                    display: block;
                    position: relative;
                    margin-bottom: 16px;
                    input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: 1;
                        cursor: pointer;
                        &:checked {
                            + label {
                                &::before {
                                    border-color: $lightBlue;
                                }
                                &::after {
                                    content: "";
                                    background-color: $lightBlue;
                                    display: inline-block;
                                    position: absolute;
                                    width: 8px;
                                    height: 8px;
                                    left: 5px;
                                    top: 4px;
                                    @include radius(50%);
                                }
                            }
                        }
                    }
                    label {
                        display: block;
                        position: relative;
                        @include font(14);
                        color: $black;
                        font-weight: normal;
                        padding-left: 30px;
                        margin-bottom: 0;
                        cursor: pointer;
                        &::before {
                        content: "";
                        position: absolute;
                        width: 18px;
                        height: 18px;
                        left: 0;
                        top: -1px;
                        border: 1px solid $black;
                        @include radius(50%);
                        }
                    }
                }
            }
        }
    }
    .modal-footer {
        border-top: none;
        justify-content: center;
        padding: 0 20px 25px;
        .btn {
            @include font(13);
            font-weight: 300;
            padding: 10px 15px;
            min-width: 100px;
            letter-spacing: 0.2px;
            margin: 0 0.65rem;
            &.lightblue {
                background: $lightBlue;
            }
        }
    }
    &.unsupport-modal {
        .modal-body {
            padding: 30px 60px 25px;
            text-align: center;
        }
        .close {
            @include font(30);
            font-weight: 300;
            color: rgba($black, .3);
            opacity: 1;
            position: absolute;
            right: 15px;
            top: 10px;
            outline: none;
        }
        .icon-box {
            margin-bottom: 15px;
            img {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .content-box {
            @include font(16);
            font-weight: 300;
            color: $black;
            .title {
                @include font(16);
                font-weight: 500;
                color: $blackColor;
                letter-spacing: 0.24px;
                line-height: 24px;
                margin-bottom: 0;
                + p {
                    margin-top: 15px;
                    margin-bottom: 0;
                }
            }
        }
        
    }
}

@media screen and (max-width: 991px) { 
    .my-recruiting-box {
        .tabBox {
            .tabs {
                li {
                    margin-right: 10px;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) { 
    .my-recruiting-box {
        .tabs-contents {
            .tab-content {
                overflow-x: scroll;
                padding-bottom: 15px;
                &::-webkit-scrollbar {
                    background: rgba($gray, 0.3%);
                    width: 6px;
                    height: 12px;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background: rgba($gray, 0.8%);
                    border-radius: 10px;
                    cursor: pointer;
                }
                .table-recruit {
                    max-width: 992px;
                    width: 992px;
                }
            }
        }
    } 
}
@media screen and (max-width:567px) {
    .my-recruiting-box {
        .tabBox {
            padding-left: 5px;
            padding-right: 5px;
            .tabs {
                display: block;
                overflow: hidden;
                li {
                    float: left;
                    width: 50%;
                    margin-right: 0;
                    a {
                        @include font(14);
                        padding: 10px;
                    }
                }
            }
        }
        .tabs-contents {
            .tab-content {
                h2 {
                    margin-bottom: 5px;
                }
                .table-recruit {
                    tr {
                        th, td {
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .my-recruiting-box {
        .top-block {
            h1 {
                @include font(15);
            }
        }
    }
}