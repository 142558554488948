// Font Imported
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
// font-family: 'Montserrat', sans-serif;

// Variable
@import "./variables";

// Mixin
@import "./mixin";

// Reset
@import "./reset";

// Global themeing
html {
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
    min-height: 100vh;
    height:calc(var(--vh, 1vh) * 100);
    font-size: 16px;
   
}
body {
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    min-height: 100vh;
    height:calc(var(--vh, 1vh) * 100);
    font-family: 'Poppins', sans-serif;
}

.transition {
    transition: (all 0.2s ease-in-out);
}

.flex {
    @include flex();
}

// Window Scrolbar
::-webkit-scrollbar {
    width: 10px;
    background: rgba($blue, 0.2%);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba($blue, 0.4%);
    cursor: pointer;
}
//   Button
.btn {
    @include font(16);
    background: $baseColor;
    color:$white;
    border:0;
    text-align: center;
    padding:12px 20px;
    line-height: 1.5;
    @include radius(5px);
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    @include transition();
    &.medium{
        @include font(14);
        padding:.5em 1em;
    }
    &.blue {
        background:$blue;
        color:$white;
    }
    &.lightblue {
        background:$lightBlue;
        color:$white;
    }
    &.blue-light{
        background:rgba($lightBlue, .3%);
        color:$white;
    }
    &.green{
        background:$green;
        color:$white;
    }
    &.outlined{
        background: none;
        border:1px solid transparent;
        color:$black;
        &.green{
            border-color:$green;
            color:$green;
            &:hover{
                background: $green;
                color:$white;
            }
        }
        &.blue{
            border-color:$blue;
            color:$blue;
            &:hover{
                background: $blue;
                color:$white;
            }
        }
    }
    &.rounded{
        border-radius: 30px !important;
    }
    &:before{
        content: '';
        width: 200%;
        height: 200%;
        position: absolute;
        background: rgba($white, .3%);
        left: -50%;
        top: -50%;
        @include scaleX(0);
        @include transition();
    }
    &:focus, &:hover{
        outline: 0px !important;
        color:$white;
        box-shadow: none !important;
        &:before{
            animation: hoverEffect .75s ease-in-out forwards;
        }
    }
}
// FX Reveal Animation
[class*="fx-reveal"],
[class^="fx-reveal"] {
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    -webkit-transition-duration: 1.5s;
    transition-duration: 1.5s;
    -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    &.up {
        @include translateY(40px);
    }
    &.down {
        @include translateY(-40px);
    }
    &.animate {
        @include transform(translate(0px, 0px) scale(1));
        opacity: 1;
    }
}
.pageContent{
    margin-top:60px;
    padding:40px 0;
    background: $bgLightGray;
    min-height: 450px;
}
.jss11 {
    position: absolute;
    z-index: -1;
}
.BeaconFabButtonFrame{
    right: 10px!important;
    bottom: 6px!important;
}
fieldset .MuiOutlinedInput-notchedOutline {
    border: none!important;
}
input {
    + .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}
input[type="date"] {
    padding-top: 10px!important;
    padding-bottom:10px!important;
    line-height:15px;
}
.sf-radio-global {
    .MuiFormControlLabel-root {
        margin-left: 0;
        .MuiIconButton-root {
            padding: 0 !important;
            background: none !important;
            border-radius:0 !important;
        }
        svg {
            display:none !important;
        }
        .MuiIconButton-label {
            display: block;
            position: relative;
            cursor: pointer;
            margin-right: 12px;
        }
        .MuiTouchRipple-root {
            &::after, &::before {
                display: none;
                opacity: 1;
            }
            
        }
        .MuiTouchRipple-root, .PrivateRadioButtonIcon-root-6 {
            display:none;
        }
        input {
          appearance: none;
          position: relative !important;
          padding:0 !important;
          width: 18px !important;
          height: 18px !important;
          top: auto!important;
          left: 0 !important;
          background: transparent;
          border: 1px solid $black !important;
          border-radius: 50% !important;
          opacity:1  !important;
          z-index: 1;
          cursor: pointer;
          &::after {
            content: "";
            background-color: transparent;
            display: inline-block;
            position: absolute;
            width: 8px;
            height: 8px;
            left: 4px;
            top: 4px;
            @include radius(50%);
          }
          &:checked {
            &::after {
                background-color: $lightBlue;
            }
            border-color: #00B9FF !important;
          }
        }
    }
}
@media screen and (max-width:767px){
    .pageContent{
        padding:1em 0;
    }
}

// @media screen and (min-width:1366px) {
//     .container{
//         max-width: 1180px;
//     }
// }
// @media screen and (min-width:1600px) {
//     .container{
//         max-width: 1420px;
//     }
// }

