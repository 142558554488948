
@charset "UTF-8";
/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.coach-banner-sec {
  background: url(../Img/coach-profile-banner.png) no-repeat;
  background-position: center right;
  background-size: cover;
  padding: 60px 0;
  /*margin-top: 60px;*/
}

.coach-banner-sec .banner-flex-wrapper {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 960px;
  margin: 0 auto;
}

.coach-banner-sec .banner-flex-wrapper .banner-content-box {
  width: 60%;
  margin-right: 5%;
}

.coach-banner-sec .banner-flex-wrapper .banner-content-box .banner-title {
  font-size: 2.125rem;
  font-weight: 600;
  color: #FF5D47;
  margin-bottom: 10px;
}

.coach-banner-sec .banner-flex-wrapper .banner-content-box p {
  font-size: 1.125rem;
  font-weight: 300;
  color: #ffffff;
  letter-spacing: 0.24px;
  line-height: 1.6;
}

.coach-banner-sec .banner-flex-wrapper .banner-content-box .buttonWrap {
  padding-top: 20px;
}

.coach-banner-sec .banner-flex-wrapper .banner-content-box .buttonWrap .btn {
  background: #C0321F;
  padding: 10px 20px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  letter-spacing: -0.17px;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box {
  background: rgba(0, 0, 0, 0.2);
  padding: 40px 30px;
  max-width: 360px;
  border: 1px solid rgba(106, 106, 106, 0.2);
  border-radius: 65px;
  -webkit-border-radius: 65px;
  -moz-border-radius: 65px;
  text-align: center;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .title {
  font-size: 1.25rem;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: -0.24px;
  margin-bottom: 10px;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box p {
  font-size: 0.875rem;
  font-weight: 300;
  color: #ffffff;
  letter-spacing: -0.17px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .form-item {
  margin-bottom: 20px;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .form-item input {
  background: rgba(255, 255, 255, 0.46);
  padding: 10px 15px;
  width: 100%;
  height: 46px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #1C0502;
  letter-spacing: 0.17px;
  border: 1px solid transparent;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .form-item input::-webkit-input-placeholder {
  color: #1C0502;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .form-item input:-ms-input-placeholder {
  color: #1C0502;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .form-item input::-ms-input-placeholder {
  color: #1C0502;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .form-item input::placeholder {
  color: #1C0502;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .form-item:last-child {
  margin-bottom: 0;
}

.coach-banner-sec .banner-flex-wrapper .banner-form-box .form-item .btn {
  background: #00B9FF;
  font-size: 0.875rem;
  font-weight: 300;
  color: #ffffff;
  letter-spacing: -0.17px;
  font-size: 0.1875pxrem;
}

.coach-main-sec {
  background: #ffffff;
  padding: 60px 0;
}

.coach-main-sec .content-with-img-listing {
  max-width: 900px;
  margin: 0 auto;
}

.coach-main-sec .content-with-img-listing .item {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 40px;
}

.coach-main-sec .content-with-img-listing .item:nth-child(2n+2) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.coach-main-sec .content-with-img-listing .item:nth-child(2n+2) .img-box {
  margin-left: 6%;
  margin-right: 0;
}

.coach-main-sec .content-with-img-listing .item:last-child {
  margin-bottom: 0;
}

.coach-main-sec .content-with-img-listing .item .img-box {
  width: 47%;
  margin-right: 6%;
}

.coach-main-sec .content-with-img-listing .item .img-box img {
  max-width: 100%;
}

.coach-main-sec .content-with-img-listing .item .content-box {
  width: 47%;
  font-size: 0.9375rem;
  font-weight: 400;
  letter-spacing: -0.19px;
  text-transform: capitalize;
}

.coach-main-sec .content-with-img-listing .item .content-box .title {
  font-size: 1.75rem;
  font-weight: 500;
  color: #000;
  letter-spacing: -0.34px;
  margin-bottom: 12px;
}

.coach-main-sec .content-with-img-listing .item .content-box p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.coach-main-sec .content-with-img-listing .item .content-box .btn {
  background: #00B9FF;
  font-size: 0.875rem;
  letter-spacing: 0.17px;
}

.coach-get-started-sec {
  background: #00B9FF;
  padding: 50px 0 60px;
  color: #ffffff;
  text-align: center;
}

.coach-get-started-sec .content-box-inner {
  max-width: 800px;
  margin: 0 auto;
}

.coach-get-started-sec .content-box-inner .title {
  font-size: 2.125rem;
  font-weight: 600;
  letter-spacing: -0.41px;
  margin-bottom: 15px;
}

.coach-get-started-sec .content-box-inner p {
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: -0.19px;
  line-height: 1.7;
  margin-bottom: 20px;
}

.coach-get-started-sec .content-box-inner .button-box .btn {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.17px;
}

.coach-get-started-sec .content-box-inner .button-box .btn.outlined {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.coach-get-started-sec .content-box-inner .button-box .btn.outlined:hover {
  background: #C0321F;
  border-color: #C0321F;
}

.coach-feature-sec {
  background: #ffffff;
  padding: 60px 0 30px;
}

.coach-feature-sec .top-block {
  max-width: 815px;
  margin: 0 auto 30px;
  text-align: center;
}

.coach-feature-sec .top-block .sec-title {
  font-size: 1.75rem;
  font-weight: 500;
  color: #000;
  letter-spacing: 0.34px;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.coach-feature-sec .top-block p {
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.19px;
  line-height: 1.6;
}

.coach-feature-sec .feature-listing {
  padding: 10px 10px 0;
  max-width: 960px;
  margin: 0 auto;
  overflow: hidden;
}

.coach-feature-sec .feature-listing .item {
  float: left;
  width: 31.666%;
  margin-right: 2.5%;
  padding: 30px 15px;
  -webkit-box-shadow: 0px 3px 6px #D9D9D985;
          box-shadow: 0px 3px 6px #D9D9D985;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.coach-feature-sec .feature-listing .item:nth-child(3n+3) {
  margin-right: 0;
}

.coach-feature-sec .feature-listing .item:nth-child(3n+4) {
  clear: both;
  text-align: center;
}

.coach-feature-sec .feature-listing .item .icon-box {
  margin-bottom: 20px;
}

.coach-feature-sec .feature-listing .item .title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
  letter-spacing: -0.24px;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.coach-feature-sec .feature-listing .item p {
  font-size: 0.875rem;
  font-weight: 300;
  color: #000;
  letter-spacing: 0.18px;
  line-height: 1.6;
  margin-bottom: 10px;
}

@media screen and (max-width: 1199px) {
  .coach-banner-sec {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .coach-main-sec {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .coach-get-started-sec {
    padding-top: 45px;
    padding-bottom: 55px;
  }
  .coach-feature-sec {
    padding-top: 50px;
  }
}

@media screen and (max-width: 991px) {
  .coach-banner-sec .banner-flex-wrapper .banner-content-box .banner-title {
    font-size: 1.5625rem;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-content-box p br {
    display: none;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-form-box {
    padding: 30px 20px 35px;
    max-width: 300px;
  }
  .coach-main-sec .content-with-img-listing .item .content-box .title {
    font-size: 1.5rem;
  }
  .coach-get-started-sec .content-box-inner .title {
    font-size: 1.5625rem;
  }
  .coach-get-started-sec .content-box-inner p {
    font-size: 0.9375rem;
  }
  .coach-feature-sec {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .coach-feature-sec .top-block .sec-title {
    font-size: 1.5625rem;
  }
  .coach-feature-sec .feature-listing {
    padding: 5px;
  }
  .coach-feature-sec .feature-listing .item {
    min-height: 360px;
  }
  .coach-feature-sec .feature-listing .item .title {
    font-size: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .coach-banner-sec .banner-flex-wrapper {
    display: block;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-content-box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-form-box {
    max-width: 100%;
  }
  .coach-main-sec .content-with-img-listing .item {
    display: block;
    text-align: center;
    margin-bottom: 45px;
  }
  .coach-main-sec .content-with-img-listing .item .img-box {
    width: 100%;
    margin: 0 auto 35px !important;
  }
  .coach-main-sec .content-with-img-listing .item .content-box {
    width: 100%;
  }
  .coach-main-sec .content-with-img-listing .item .content-box .title {
    font-size: 1.375rem;
  }
  .coach-feature-sec .feature-listing .item {
    padding: 30px ​15px 20px;
    width: 48.5%;
    min-height: 1px;
    margin-right: 3%;
  }
  .coach-feature-sec .feature-listing .item:nth-child(3n+3) {
    margin-right: 3%;
  }
  .coach-feature-sec .feature-listing .item:nth-child(3n+4) {
    clear: none;
  }
  .coach-feature-sec .feature-listing .item:nth-child(2n+2) {
    margin-right: 0;
  }
  .coach-feature-sec .feature-listing .item:nth-child(2n+3) {
    clear: both;
  }
}

@media screen and (max-width: 567px) {
  .coach-banner-sec {
    padding: 30px 0 35px;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-content-box {
    margin-bottom: 20px;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-content-box .banner-title {
    font-size: 1.25rem;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-content-box p {
    font-size: 0.9375rem;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-form-box {
    padding: 25px 20px 30px;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-form-box .title {
    font-size: 1.125rem;
  }
  .coach-banner-sec .banner-flex-wrapper .banner-form-box p {
    margin-bottom: 15px;
  }
  .coach-main-sec {
    padding: 35px 0;
  }
  .coach-main-sec .content-with-img-listing .item {
    max-width: 300px;
    margin: 0 auto 30px;
  }
  .coach-main-sec .content-with-img-listing .item .img-box {
    margin-bottom: 30px !important;
  }
  .coach-main-sec .content-with-img-listing .item .content-box {
    font-size: 0.875rem;
  }
  .coach-main-sec .content-with-img-listing .item .content-box .title {
    font-size: 1.125rem;
  }
  .coach-get-started-sec {
    padding: 30px 0 35px;
  }
  .coach-get-started-sec .content-box-inner .title {
    font-size: 1.25rem;
    margin-bottom: 12px;
  }
  .coach-feature-sec {
    padding-top: 35px;
    padding-bottom: 0;
  }
  .coach-feature-sec .top-block .sec-title {
    font-size: 1.25rem;
  }
  .coach-feature-sec .feature-listing .item {
    float: none;
    width: 100%;
    margin-right: 0;
    padding: 25px 15px 15px;
  }
}
/*# sourceMappingURL=coach-profile-home.css.map */