@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.suggestCollegeSection {
  margin-top: 60px;
  background-color: $bgLightGray;
  padding: 15px 0;
  > .container {
    > .flexBox {
      @include flex();
      flex-wrap: wrap;
      .leftDataBox {
        width: 250px;
        .AthleteInfoBox {
          .AthleteInfo {
            padding: 15px;
            position: relative;
            background-image: url(../img/profileBg.png);
            background-repeat: no-repeat;
            background-size: auto;
            .profileBox {
              margin-top: 10px;
              .imgBoxBlock {
                width: 100px;
                height: 100px;
                @include radius(50%);
                margin: 0px auto;
                position: relative;
                div {
                  &.imgBox {
                    width: 100px;
                    height: 100px;
                    @include radius(50%);
                    border: 2px solid $white;
                    overflow: hidden;
                    cursor: pointer;
                    a {
                      text-decoration: none;
                      display: inline-block;
                      width: 100px;
                      height: 100px;
                      @include radius(50%);
                      img {
                        max-width: 100%;
                        height: auto;
                      }
                    }
                  }
                  &.icon {
                    position: absolute;
                    right: -10px;
                    bottom: 0;
                    cursor: pointer;
                  }
                }
              }
              .playerName {
                margin: 10px 0px;
                text-align: center;
                @include font(18);
                color: $white;
              }
              ul {
                margin: 0 0 20px 0;
                padding: 0;
                li {
                  list-style: none;
                  margin: 0 0 10px 0;
                  text-align: center;
                  @include flex();
                  align-items: flex-start;
                  justify-content: center;
                  span {
                    display: inline-block;
                    @include font(14);
                    color: $white;
                    line-height: normal;
                    &.label {
                      margin: 0px 3px 0px 0px;
                    }
                  }
                  &:last-child {
                    margin: 0px 0px 0px 0px;
                  }
                }
              }
            }
            .flexBox {
              @include flex();
              justify-content: space-between;
              border-top: 1px solid rgba(255, 255, 255, 0.5);
              padding-top: 20px;
              margin: 0;
              li {
                list-style: none;
                width: calc(33.3% - 10px);
                margin-right: 10px;
                &:last-child {
                  margin-right: 0px;
                }
                span {
                  display: block;
                  text-align: center;
                  &.label {
                    @include font(13);
                    color: $white;
                    font-weight: normal;
                    margin: 5px 0px 0px 0px;
                  }
                  &.value {
                    @include font(14);
                    font-weight: 600;
                    color: $white;
                  }
                }
              }
            }
            .profileLink {
              position: absolute;
              left: 10px;
              top: 10px;
              cursor: pointer;
              display: inline-flex;
              svg {
                .a {
                  fill: $yellow;
                }
              }
            }
          }
          .activityCollegeBox {
            background-color: $white;
            padding: 15px;
            @include shadow(1px, 2px, 2px, #00000017);
            @include radius(0px 0px 2px 2px);
            div {
              &.recuringActivity {
                border-bottom: 1px solid rgba(112, 112, 112, 0.3);
                .heading {
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                  margin: 0;
                }
                ul {
                  margin: 20px 0 10px 0;
                  padding: 0;
                  @include flex();
                  flex-wrap: wrap;
                  justify-content: space-between;
                  li {
                    list-style: none;
                    width: calc(25% - 10px);
                    margin: 0 10px 10px 0;
                    span {
                      display: block;
                      text-align: center;
                      &.value {
                        @include font(14);
                        color: $black;
                        font-weight: 600;
                      }
                      &.label {
                        margin-top: 5px;
                        @include font(12);
                        color: $black;
                        font-weight: normal;
                      }
                    }
                    &:last-child {
                      margin: 0px 10px 0px 0px;
                    }
                  }
                }
              }
              &.myCollege {
                .heading {
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                  margin: 0 0 15px 0;
                }
                ul {
                  margin: 0;
                  padding: 0;
                  @include flex();
                  flex-wrap: wrap;
                  justify-content: space-between;
                  li {
                    list-style: none;
                    width: calc(25% - 10px);
                    margin: 0 10px 0 0;
                    span {
                      display: block;
                      text-align: center;
                      &.value {
                        @include font(14);
                        color: $black;
                        font-weight: 600;
                      }
                      &.label {
                        margin-top: 5px;
                        @include font(12);
                        color: $black;
                        font-weight: normal;
                      }
                    }
                    &:last-child {
                      margin: 0px 10px 0px 0px;
                    }
                  }
                }
              }
            }
          }
        }
        .profileDataBox {
          background-color: $white;
          padding: 15px;
          @include shadow(1px, 2px, 2px, #00000017);
          margin: 10px 0px;
          div {
            &.head {
              @include flex();
              align-items: center;
              justify-content: space-between;
              .title {
                @include font(16);
                color: $black;
                font-weight: 600;
                margin: 0px 0px 15px 0px;
              }
              .editLink {
                text-decoration: none;
                cursor: pointer;
                @include font(14);
                color: $baseColor;
                margin: 0px 0px 15px 0px;
              }
            }
            &.porgress {
              @include flex();
              align-items: center;
              justify-content: space-between;
              margin-bottom: 15px;
              label {
                margin-right: 10px;
                display: inline-block;
                @include font(14);
                color: $green;
                font-weight: 600;
              }
              .bar {
                display: inline-flex;
                width: calc(100% - 45px);
                @include radius(7px);
                border: 1px solid $green;
                height: 8px;
                position: relative;
                &::before {
                  content: "";
                  display: inline-flex;
                  width: 70%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  background: $green;
                  @include radius(7px);
                }
              }
            }
          }
          .level {
            li {
              list-style: none;
              margin: 0px 0px 15px 0px;
              &:last-child {
                margin: 0px 0px 0px 0px;
              }
              &.passed {
                a {
                  color: $green;
                  &.checkList {
                    span {
                      &.icon {
                        position: relative;
                        svg {
                          .a {
                            fill: $green;
                          }
                        }
                        .count {
                          position: absolute;
                          right: 0;
                          top: 0;
                          width: 12px;
                          height: 12px;
                          @include radius(50%);
                          background-color: $baseColor;
                          color: $white;
                          font-size: 8px;
                          align-items: center;
                          justify-content: center;
                        }
                      }
                    }
                  }
                  span {
                    &.icon {
                      svg {
                        .a {
                          fill: none;
                          stroke: $green;
                          stroke-linecap: round;
                          stroke-linejoin: round;
                          stroke-width: 1.5px;
                        }
                      }
                    }
                    &.label {
                      color: $green;
                    }
                  }
                }
              }
              a {
                display: inline-flex;
                cursor: pointer;
                text-decoration: none;
                &.video {
                  span {
                    &.icon {
                      svg {
                        .a,
                        .b {
                          fill: none;
                          stroke: $black;
                          stroke-linecap: round;
                          stroke-width: 1.5px;
                        }
                        .a {
                          stroke-linejoin: round;
                        }
                      }
                    }
                  }
                }

                span {
                  display: inline-flex;
                  &.icon {
                    width: 20px;
                    margin-right: 10px;
                  }
                  &.label {
                    @include font(16);
                    color: $black;
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }
        .teamOfferBox {
          background-color: $white;
          @include radius(2px);
          padding: 15px;
          margin-bottom: 10px;
          div {
            &.myTeam {
              padding-bottom: 15px;
              margin-bottom: 15px;
              border-bottom: 1px solid $gray;
              .head {
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title {
                  margin: 0px 0px 15px 0px;
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                }
                .editLink {
                  text-decoration: none;
                  display: inline-flex;
                  cursor: pointer;
                  color: $baseColor;
                  margin: 0px 0px 15px 0px;
                }
              }
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  @include flex();
                  margin-bottom: 15px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  div {
                    &.imgBox {
                      width: 40px;
                      height: 40px;
                      margin-right: 10px;
                      img {
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                      }
                    }
                    &.info {
                      width: calc(100% - 50px);
                      span {
                        display: inline-block;
                        @include font(14);
                        color: rgba(0, 0, 0, 0.7);
                      }
                    }
                  }
                }
              }
              .viewMore {
                margin-top: 5px;
                text-align: center;
                a {
                  display: inline-block;
                  color: $baseColor;
                  text-decoration: none;
                  @include font(14);
                  cursor: pointer;
                }
              }
            }
            &.offer {
              padding-bottom: 15px;
              margin-bottom: 15px;
              border-bottom: 1px solid $gray;
              .head {
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title {
                  margin: 0px 0px 15px 0px;
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                }
                .editLink {
                  text-decoration: none;
                  display: inline-flex;
                  cursor: pointer;
                  color: $baseColor;
                  margin: 0px 0px 15px 0px;
                }
              }
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  @include flex();
                  margin-bottom: 15px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  div {
                    &.imgBox {
                      width: 40px;
                      height: 40px;
                      margin-right: 10px;
                      img {
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                      }
                    }
                    &.info {
                      width: calc(100% - 50px);
                      .title {
                        @include font(14);
                        color:#007bff;
                        margin: 0px 0px 10px 0px;
                        font-weight: 600;
                      }
                      a {
                        text-decoration: none;
                        &:hover {
                          .title {
                            color:#0056b3;
                          }
                        }
                      }
                      a {
                        text-decoration: none;
                        &:hover {
                          .title {
                            color:#0056b3;
                          }
                        }
                      }
                      .date {
                        @include flex();
                        label {
                          @include font(12);
                          color: $blackColor;
                          display: inline-block;
                          margin-right: 3px;
                        }
                        span {
                          display: inline-block;
                          @include font(12);
                          color: $blackColor;
                        }
                      }
                    }
                  }
                }
              }
              .viewMore {
                margin-top: 10px;
                text-align: center;
                a {
                  display: inline-block;
                  color: $baseColor;
                  text-decoration: none;
                  @include font(14);
                  cursor: pointer;
                }
              }
            }
            &.commitment {
              .head {
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title {
                  margin: 0px 0px 15px 0px;
                  @include font(16);
                  color: $blackColor;
                  font-weight: 600;
                }
                .editLink {
                  text-decoration: none;
                  display: inline-flex;
                  cursor: pointer;
                  color: $baseColor;
                  margin: 0px 0px 15px 0px;
                }
              }
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  @include flex();
                  margin-bottom: 15px;
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  div {
                    &.imgBox {
                      width: 40px;
                      height: 40px;
                      margin-right: 10px;
                      img {
                        max-width: 100%;
                        max-height: 100%;
                        height: auto;
                      }
                    }
                    &.info {
                      width: calc(100% - 50px);
                      .title {
                        @include font(14);
                        color: #007bff;
                        margin: 0px 0px 10px 0px;
                        font-weight: 600;
                      }
                      a {
                        text-decoration: none;
                        &:hover {
                          .title {
                            color:#0056b3;
                          }
                        }
                      }
                      a {
                        text-decoration: none;
                        &:hover {
                          .title {
                            color:#0056b3;
                          }
                        }
                      }
                      span {
                        display: inline-block;
                        @include font(12);
                        color: $blackColor;
                      }
                    }
                  }
                }
              }
              .viewMore {
                margin-top: 10px;
                text-align: center;
                a {
                  display: inline-block;
                  color: $baseColor;
                  text-decoration: none;
                  @include font(14);
                  cursor: pointer;
                }
              }
            }
          }
        }
        .targetCollege {
          background-color: $white;
          @include radius(2px);
          @include shadow(1px, 2px, 2px, #00000017);
          .head {
            padding: 20px 15px 0 15px;
            @include flex();
            align-items: center;
            justify-content: space-between;
            h5 {
              @include font(16);
              color: $blackColor;
              font-weight: 600;
              margin: 0 0 15px 0;
            }
            a {
              @include font(14);
              color: $baseColor;
              font-weight: normal;
              margin: 0px 0px 15px 0px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              &.addBtn {
                width: 20px;
                height: 20px;
                color: $white;
                background-color: $baseColor;
                @include radius(2px);
                @include font(20);
              }
            }
          }
          ul {
            margin: 0;
            padding: 0;
            li {
              list-style: none;
              padding: 15px;
              border-bottom: 1px solid $gray;
              @include flex();
              align-items: flex-start;
              .count {
                display: inline-block;
                margin-right: 10px;
                @include font(14);
                color: $blackColor;
                width: 30px;
              }
              .infoBox {
                width: calc(100% - 40px);
                @include flex();
                align-items: flex-start;
                .imgBox {
                  width: 30px;
                  height: 30px;
                  margin-right: 10px;
                  img {
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                  }
                }
                .data {
                  width: calc(100% - 40px);
                  .title {
                    @include font(14);
                    color: #007bff;
                    font-weight: 600;
                    margin: 0px 0px 10px 0px;
                  }
                  a {
                    text-decoration: none;
                    &:hover {
                      .title {
                        color:#0056b3;
                      }
                    }
                  }
                  .flexBox {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;
                    span {
                      @include font(13);
                      color: $black;
                      display: inline-block;
                      width: calc(100% - 30px);
                      line-height: 1.33;
                    }
                    .forwardLink {
                      text-decoration: none;
                      display: inline-block;
                      padding: 5px;
                      svg {
                        .a {
                          fill: $lightBlue;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .manageLink {
            text-align: center;
            padding: 15px;
            a {
              text-decoration: none;
              @include font(14);
              color: $baseColor;
              display: inline-block;
              cursor: pointer;
              &:hover {
                color: #b12c1a;
              }
            }
          }
          &.sticky-box {
            position: -webkit-sticky;
            position: sticky;
            top: 65px;
            z-index: 1;
          }
        }
        .showMoreless {
          text-align: center;
          margin: 15px 0px;
          display: none;
          a {
            text-decoration: none;
            display: inline-block;
            @include font(14);
            color: $baseColor;
            span {
              display: inline-block;
              &.icon {
                margin-left: 10px;
                svg {
                  .a {
                    fill: $baseColor;
                  }
                }
              }
            }
          }
        }
      }
      .dataBox {
        width: calc(100% - 520px);
        margin: 0 10px;

        .backHome {
          padding-bottom: 10px;
          border-bottom: 1px solid $gray;
          margin-bottom: 15px;
          a {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
            padding: 5px;
            @include font(14);
            color: $black;
            span {
              display: inline-block;
              &.icon {
                margin-right: 10px;
                svg {
                  fill: $black;
                  width: 15px;
                  height: auto;
                }
              }
            }
          }
        }
        .tabsBox {
          .heading {
            @include font(20);
            color: $black;
            font-weight: 600;
            margin: 0 0 15px 0;
          }
          .tabFlex {
            @include flex();
            margin-bottom: 15px;
            .tab {
              margin-right: 10px;
              a {
                padding: 10px;
                display: inline-flex;
                @include font(16);
                color: $black;
                text-decoration: none;
                position: relative;
                &:hover {
                  color: $baseColor;
                }
              }
              &:last-child {
                margin-right: 0px;
              }
              &.selected {
                a {
                  color: $baseColor;
                  border-bottom: 1px solid $baseColor;
                }
              }
            }
          }

          .followingDataBox {
            background-color: $white;
            padding: 15px 15px 40px 15px;
            @include shadow(1px, 2px, 2px, #00000017);
            height: 100%!important;
            .item {
              border-bottom: 1px solid $gray;
              padding: 15px 0;
              .imgDataFlexBox {
                @include flex();
                align-items: flex-start;
                margin-bottom: 0;
                .imgBox {
                  flex: 0 0 50px;
                  width: 50px;
                  height: 50px;
                  margin-right: 10px;
                  position: relative;
                  img {
                    max-width: 100%;
                    width: 50px!important;
                    height: 50px!important;
                    object-fit: cover;
                  }
                  .initialLetter{
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $border;
                    @include radius(50%);
                    text-transform: uppercase;
                  }
                  span {
                    &.icon {
                      display: inline-block;
                      position: absolute;
                      bottom: 0px;
                      right: -4px;
                      &.blueCheck {
                        background-color: $white;
                        @include radius(50%);
                        svg {
                          width: 18px;
                          height: auto;
                          .a {
                            fill: $lightBlue;
                          }
                        }
                      }
                      &.redAlert {
                        svg {
                          width: 18px;
                          height: auto;
                          .a {
                            fill: #ff4a31;
                          }
                          .b {
                            fill: $white;
                          }
                        }
                      }
                    }
                  }
                }
                .dataFlexBox {
                  width: calc(100% - 60px);
                  @include flex();
                  align-items: flex-start;
                  justify-content: space-between;
                  div {
                    width: calc(100% - 75px);
                    padding-right: 10px;
                    h5 {
                      margin: 0 0 5px 0;
                      @include font(16);
                      color: $blackColor;
                      span {
                        display: inline-block;
                        @include font(12);
                        color: $green;
                      }
                    }
                    a {
                      text-decoration: none;
                    }
                    ul {
                      margin: 0;
                      padding: 0;
                      @include flex();
                      flex-wrap: wrap;
                      li {
                        list-style: none;
                        margin-right: 0;
                        margin-bottom: 0;
                        position: relative;
                        padding-right: 0;
                        span {
                          display: inline-block;
                          @include font(13);
                          color: $black;
                          line-height: 16px;
                        }
                      }
                    }
                    p {
                      @include font(13);
                      color: $black;
                      line-height: 16px;
                      margin-top: 5px;
                    }
                    &.action {
                      @include flex();
                      align-items: center;
                      width: auto;
                      position: relative;
                      padding-right: 0;
                      margin-top: 10px;
                      a {
                        text-decoration: none;
                        &.unFollowingLink {
                          @include font(13);
                          color: $white;
                          background-color: $baseColor;
                          padding: 2px 10px;
                          @include radius(10px);
                          line-height: 16px;
                        }
                        &.followingLink {
                          @include font(13);
                          color: $white;
                          background-color: $lightBlue;
                          padding: 3px 10px;
                          @include radius(10px);
                          line-height: 16px;
                        }
                        &.followerLink {
                          @include font(12);
                          min-width: 107px;
                          color: $green;
                          border: 1px solid $green;
                          padding: 2px 10px;
                          @include radius(10px);
                          line-height: 16px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .loadMoreBox {
            text-align: center;
            padding: 10px;
            background-color: $white;
            @include shadow(1px, 2px, 2px, #00000017);
            @include radius(4px);
            a {
              display: inline-block;
              text-decoration: none;
              @include font(14);
              color: $black;
              cursor: pointer;
            }
          }
        }
      }
      .rightDataBox {
        width: 250px;
        .verificationBox {
          margin-bottom: 15px;
          @include radius(4px);
          background-color: $white;
          @include shadow(1px, 2px, 2px, #00000017);
          .head {
            @include flex();
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 0 10px;
            cursor: pointer;
            span {
              &.icon {
                display: inline-block;
                margin-right: 10px;
                svg {
                  .a {
                    fill: #ff4a31;
                  }
                  .b {
                    fill: $white;
                  }
                }
              }
            }
            .title {
              color: $black;
              @include font(14);
              font-weight: 600;
              margin: 0px auto 0px 0px;
            }
            button {
              &.btn {
                background-color: transparent;
                padding: 5px;
                min-width: auto;
                max-width: 30px;
                svg {
                  .a {
                    fill: $black;
                  }
                }
              }
            }
          }
          .stepInfo {
            padding: 10px;
            .steps {
              @include flex();
              margin-bottom: 10px;
              margin-left: 5px;
              span {
                height: 9px;
                border: 1px solid $white;
                @include radius(7px);
                background: $gray;
                display: inline-block;
                width: calc(20% + 5px);
                margin-left: -5px;
                &.passed {
                  background: $lightBlue;
                  &.active {
                    position: relative;
                    z-index: 1;
                  }
                }
              }
            }
            .flexBox {
              @include flex();
              align-items: center;
              justify-content: space-between;
              p {
                @include font(13);
                color: $black;
                width: calc(100% - 35px);
              }
              .icon {
                svg {
                  .a {
                    fill: $lightBlue;
                  }
                }
              }
            }
          }
          .collapse {
            .card {
              border-top: 1px solid $gray;
              @include radius(0px);
              padding: 10px;
              border-left: none;
              border-right: none;
              border-bottom: none;
              .verificationSteps {
                margin: 0 0 5px 0;
                padding: 0;
                li {
                  list-style: none;
                  margin-bottom: 10px;
                  @include flex();
                  align-items: center;
                  justify-content: space-between;
                  span {
                    display: inline-block;
                    @include font(13);
                    color: $black;
                    &.icon {
                      svg {
                        width: 18px;
                        height: auto;
                        .a {
                          fill: $lightBlue;
                        }
                        .b {
                          fill: #fafafa;
                        }
                      }
                    }
                  }
                  &.completeStep {
                    span {
                      color: $baseColor;
                      &.icon {
                        svg {
                          width: 18px;
                          height: auto;
                          .a {
                            fill: $baseColor;
                          }
                        }
                      }
                    }
                  }
                  &.passed {
                    span {
                      color: #999999;
                      position: relative;
                      &::before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                        height: 1px;
                        background-color: #999;
                      }
                      &.icon {
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                  &.active {
                    span {
                      &.icon {
                        svg {
                          .a {
                            .a {
                              fill: $lightBlue;
                            }
                            .b {
                              fill: #fafafa;
                            }
                          }
                        }
                      }
                    }
                  }
                  &.unactive {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: $gray;
                          }
                          b {
                            fill: #fafafa;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .whyverified {
                background-color: $bgLightGray;
                padding: 10px;
                .title {
                  @include flex();
                  align-items: center;
                  @include font(13);
                  color: $black;
                  font-weight: 600;
                  margin: 0 0 10px 0;
                  span {
                    display: inline-block;
                    &.icon {
                      margin-left: 10px;
                      svg {
                        width: 22px;
                        height: auto;
                        .a {
                          fill: $lightBlue;
                        }
                      }
                    }
                  }
                }

                .info {
                  @include font(12);
                  color: $black;
                  line-height: 16px;
                  .learnMore {
                    text-decoration: none;
                    display: inline-block;
                    color: $lightBlue;
                    font-weight: 600;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .trendingProfileBox {
          background-color: $white;
          @include radius(2px);
          @include shadow(1px, 2px, 2px, #00000017);
          margin-bottom: 10px;
          .head {
            padding: 10px;
            .title {
              @include font(16);
              color: $blackColor;
              font-weight: 600;
            }
          }
          ul {
            li {
              padding: 10px;
              border-bottom: 1px solid $gray;
              @include flex();
              align-items: flex-start;
              .imgBox {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                @include radius(50%);
                position: relative;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  height: auto;
                }
                .icon {
                  position: absolute;
                  bottom: 0px;
                  right: -5px;
                  svg {
                    width: 15px;
                    height: auto;
                    .a {
                      fill: $lightBlue;
                    }
                  }
                }
              }
              .info {
                width: calc(100% - 60px);
                h5 {
                  @include font(14);
                  color: $black;
                  font-weight: 600;
                  line-height: 18px;
                  margin: 0px 0px 5px 0px;
                }
                span {
                  display: block;
                  @include font(13);
                  color: $black;
                  margin: 0px 0px 5px 0px;
                }
                button {
                  outline: none;
                  cursor: pointer;
                  border: none;
                  &.followBtn {
                    background-color: transparent;
                    @include radius(10px);
                    border: 1px solid $lightBlue;
                    @include font(13);
                    color: $lightBlue;
                    margin-top: 5px;
                    padding: 2px 10px;
                    line-height: 16px;
                  }
                }
              }
            }
          }
          .showMoreBox {
            text-align: center;
            padding: 10px;
            a {
              text-decoration: none;
              display: inline-block;
              @include font(14);
              color: $baseColor;
              cursor: pointer;
              &:hover {
                color: #b12c1a;
              }
            }
          }
        }
        .suggestCollegeBox {
          background-color: $white;
          @include radius(2px);
          @include shadow(1px, 2px, 2px, #00000017);
          margin-bottom: 10px;
          .head {
            padding: 10px;
            .title {
              @include font(16);
              color: $blackColor;
              font-weight: 600;
            }
          }
          ul {
            li {
              padding: 10px;
              border-bottom: 1px solid $gray;
              @include flex();
              align-items: flex-start;
              .imgBox {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                @include radius(50%);
                position: relative;
                img {
                  max-width: 100%;
                  height: auto;
                }
                .icon {
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  svg {
                    width: 15px;
                    height: auto;
                  }
                }
              }
              .info {
                width: calc(100% - 60px);
                h5 {
                  @include font(14);
                  color: $black;
                  font-weight: 600;
                  line-height: 18px;
                  margin: 0px 0px 5px 0px;
                }
                span {
                  display: block;
                  @include font(13);
                  color: $black;
                  margin: 0px 0px 5px 0px;
                }
                button {
                  outline: none;
                  cursor: pointer;
                  border: none;
                  &.followBtn {
                    background-color: transparent;
                    @include radius(10px);
                    border: 1px solid $green;
                    @include font(13);
                    color: $green;
                    margin-top: 5px;
                    padding: 2px 10px;
                    line-height: 16px;
                  }
                }
              }
            }
          }
          .showMoreBox {
            text-align: center;
            padding: 10px;
            a {
              text-decoration: none;
              display: inline-block;
              @include font(14);
              color: $baseColor;
              cursor: pointer;
              &:hover {
                color: #b12c1a;
              }
            }
          }
        }
      }
    }
  }
}

/*************************getVerifiedModal css*******************************************/

.modal {
  &.getVerifiedModal {
    .modal-dialog {
      max-width: 400px;
      margin: 15px 15px;
      max-height: calc(100% - 30px);
      width: calc(100% - 30px);
      .modal-content {
        .modal-header {
          padding: 0 10px;
          border-bottom: none;
          button {
            &.close {
              min-width: auto;
              padding: 5px;
              float: none;
              margin: 0 0 0 auto;
              outline: none;
              svg {
                .a {
                  opacity: 0.7;
                  fill: $black;
                }
              }
            }
          }
        }
        .modal-body {
          padding: 0 15px 15px 15px;
          .heading {
            margin: 0 auto 10px auto;
            max-width: 265px;
            @include font(18);
            color: $blackColor;
            font-weight: 600;
            text-align: center;
          }
          .profileBox {
            .profileImgBox {
              position: relative;
              width: 100px;
              height: 100px;
              @include radius(50%);
              margin: 0px auto;
              .imgBox {
                width: 100px;
                height: 100px;
                @include radius(50%);
                margin: 0px auto;
                img {
                  max-width: 100%;
                  height: auto;
                }
              }
              .icon {
                position: absolute;
                bottom: 0;
                right: -10px;
                svg {
                  .a {
                    fill: #ff4a31;
                  }
                  .b {
                    fill: $white;
                  }
                }
              }
            }
            .name {
              margin: 10px 0px 10px 0px;
              @include font(16);
              color: $black;
              font-weight: 600;
              text-align: center;
            }
            ul {
              margin: 0px 0px 5px 0px;
              li {
                list-style: none;
                margin-bottom: 10px;
                @include flex();
                align-items: flex-start;
                justify-content: center;
                span {
                  display: inline-block;
                  @include font(14);
                  color: $black;
                }
                label {
                  margin-right: 5px;
                  display: inline-block;
                  @include font(14);
                  color: $black;
                }
              }
            }
          }
          p {
            &.info {
              @include font(14);
              color: $black;
              line-height: 18px;
              text-align: center;
              max-width: 300px;
              margin: 0px auto 10px auto;
            }
          }
          .btnBox {
            margin: 20px 0px;
            text-align: center;
            button {
              &.verifyBtn {
                background: $lightBlue;
                @include font(14);
                color: $white;
                @include radius(4px);
                border: none;
                padding: 15px 20px;
                outline: none;
              }
            }
          }
          .infoUsers {
            @include flex();
            align-items: flex-end;
            justify-content:space-between;
            .infouseritem {
              @include flex();
              align-items: center;
              justify-content:space-between;
              width: 40%;
              margin-right: 5px;
              .infouseritem-img {
                width: 33.333%;
                padding: 2px;
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .info{
              font-size: 12px;
            } 
          }
        }
      }
    }
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

/*************************shareUpdateModal css****************************************/

.modal {
  &.shareUpdateModal {
    .modal-dialog {
      max-width: 480px;
      margin: 15px 15px;
      max-height: calc(100% - 30px);
      width: calc(100% - 30px);
      .modal-content {
        .modal-header {
          padding: 0 10px;
          border-bottom: none;
          button {
            &.close {
              min-width: auto;
              padding: 5px;
              float: none;
              margin: 0 0 0 auto;
              outline: none;
              top: 10px;
              position: relative;
              svg {
                .a {
                  opacity: 0.7;
                  fill: $black;
                }
              }
            }
          }
        }
        .modal-body {
          padding: 0 15px 15px 15px;
          .heading {
            margin: -18px auto 20px auto;
            max-width: 446px;
            @include font(18);
            color: $blackColor;
            font-weight: 600;
            text-align: left;
          }
          //new share popup css
          .shareupdatelinks {
            padding:10px 0;
            max-width: 260px;
            margin: 0 auto;
            li {
              display: block;
              margin-bottom: 12px;
              &:last-child {
                margin-bottom: 0;
              }
              a {
                display: inline-block;
                vertical-align: middle;
                text-decoration: none!important;
                @include font(15);
                font-weight: 700;
                color: $blackColor;
                .icon {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 10px;
                  svg {
                    display: inline-block;
                    vertical-align: middle;
                  }
                }
              }
            }
          }
          .socialFlexBox {
            @include flex();
            max-width: 383px;
            margin: 0px auto;
            justify-content: space-between;
            .twitter {
              a {
                width: 70px;
                height: 70px;
                @include flex();
                align-items: center;
                justify-content: center;
                border: 1px solid $lightBlue;
                @include radius(50%);
                color: $lightBlue;
                text-decoration: none;
                cursor: pointer;
                background-color: transparent;
                outline: none;
                text-decoration: none;
                div {
                  span {
                    @include font(10);
                    display: block;
                    text-align: center;
                    &.icon {
                      margin-bottom: 4px;
                      svg {
                        width: 25px;
                        height: auto;
                        fill: $lightBlue;
                      }
                    }
                  }
                }
                &:hover{
                  background:$lightBlue;
                  box-shadow: 0 0 15px rgba(145, 92, 182, .4);
                  div span.icon{
                    svg{
                      fill: $white;
                    }
                  }
                  span{
                    color: $white;
                  }
                }
              }
            }
            .fbook {
              a {
                width: 70px;
                height: 70px;
                @include flex();
                align-items: center;
                justify-content: center;
                border: 1px solid $blue;
                @include radius(50%);
                color: $blue;
                text-decoration: none;
                cursor: pointer;
                background-color: transparent;
                outline: none;
                text-decoration: none;
                div {
                  span {
                    @include font(10);
                    display: block;
                    text-align: center;
                    &.icon {
                      margin-bottom: 4px;
                      svg {
                        width: 22px;
                        height: auto;
                        fill: $blue;
                        path {
                          fill: $blue;
                        }
                      }
                    }
                  }
                }
                &:hover{
                  background:$blue;
                  box-shadow: 0 0 15px rgba(145, 92, 182, .4);
                  div span.icon{
                    svg{
                      fill: $white;
                      path{
                        fill: $white;
                      }
                    }
                  }
                  span{
                    color: $white;
                  }
                }
              }
            }
            .text {
              a {
                width: 70px;
                height: 70px;
                @include flex();
                align-items: center;
                justify-content: center;
                border: 1px solid #1BA997;
                @include radius(50%);
                color: #1BA997;
                text-decoration: none;
                cursor: pointer;
                background-color: transparent;
                outline: none;
                text-decoration: none;
                div {
                  span {
                    @include font(10);
                    display: block;
                    text-align: center;
                    &.icon {
                      margin-bottom: 4px;
                      svg {
                        width: 30px;
                        height: auto;
                        fill: #1BA997;
                        path {
                          fill: #1BA997;
                        }
                      }
                    }
                  }
                }
                &:hover{
                  background:#1BA997;
                  box-shadow: 0 0 15px rgba(145, 92, 182, .4);
                  div span.icon{
                    svg{
                      fill:$white;
                      path{
                        fill: $white;
                      }
                    }
                  }
                  span{
                    color: $white;
                  }
                }
              }
            }
            .mail {
              a {
                width: 70px;
                height: 70px;
                @include flex();
                align-items: center;
                justify-content: center;
                border: 1px solid #CA5F42;
                @include radius(50%);
                color: #CA5F42;
                text-decoration: none;
                cursor: pointer;
                background-color: transparent;
                outline: none;
                text-decoration: none;
                div {
                  span {
                    @include font(10);
                    display: block;
                    text-align: center;
                    &.icon {
                      margin-bottom: 4px;
                      svg {
                        width: 30px;
                        height: auto;
                        fill: #CA5F42;
                        path {
                          fill: #CA5F42;
                        }
                      }
                    }
                  }
                }
                &:hover{
                  background:#CA5F42;
                  box-shadow: 0 0 15px rgba(145, 92, 182, .4);
                  div span.icon{
                    svg{
                      fill: $white;
                      path{
                        fill: $white;
                      }
                    }
                  }
                  span{
                    color: $white;
                  }
                }
              }
            }
          }
          p {
            &.info {
              @include font(14);
              color: $black;
              line-height: 18px;
              text-align: center;
              max-width: 415px;
              margin: 20px auto 20px auto;
            }
          }
          .linkBox {
            width: 100%;
            @include flex();
            margin-bottom: 10px;
            p {
              color: $black;
              @include font(13);
              padding: 10px;
              border: 1px solid $gray;
              @include radius(4px 0px 0px 4px);
              @include flex();
              align-items: center;
              width: calc(100% - 110px);
            }
            button {
              &.copyBtn {
                background: $lightBlue;
                @include radius(4px);
                border: none;
                @include font(14);
                color: $white;
                padding: 15px 20px;
                outline: none;
                @include radius(0px 4px 4px 0px);
                cursor: pointer;
              }
            }
          }
          .share-post-modal-info {
            display: block;
            margin-bottom: 20px;
            li {
              @include flex();
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;
              label {
                position: relative;
                @include font(14);
                font-weight: 400;
                color: $black;
                line-height: 1.4;
                width: 100px;
                margin-right: 10px;
                sup {
                  color: $baseColor;
                }
              }
              .field-wrap {
                width: calc(100% - 110px);
                input {
                  padding: 10px 10px;
                  width: 100%;
                  @include font(14);
                  color: $black;
                  border: 1px solid $gray;
                  outline: none;
                  box-shadow: none;
                  @include radius(4px);
                  &::placeholder {
                    color: $black;
                  }
                }
                .MuiAutocomplete-root {
                  padding: 10px 10px;
                  width: 100%;
                  @include font(14);
                  color: $black;
                  border: 1px solid $gray;
                  outline: none;
                  box-shadow: none;
                  @include radius(4px);
                  input {
                    border: none;
                    padding: 0;
                  }
                }
                .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, 
                .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
                  padding-right: 45px;
                }
              }
            }
          }
          .field-data-modal {
            .row {
              align-items: center;
              margin-bottom: 10px;
              .col-md-5 {
                p {
                  position: relative;
                  @include font(14);
                  font-weight: 400;
                  color: $black;
                  line-height: 1.4;
                  sup {
                    color: $baseColor;
                  }
                }
              }
              input {
                padding: 10px 10px;
                width: 100%;
                @include font(14);
                color: $black;
                border: 1px solid $gray;
                outline: none;
                box-shadow: none;
                @include radius(4px);
                &::placeholder {
                  color: $black;
                }
              }
              .countryPhoneFlexBox {
                > div {
                  &.countrySelect {
                    width:125px;
                  }
                }
                .textBox {
                  input {
                    border: none;
                    @include font(12);
                  }
                }
              }
            }
            .share-btn-wrap {
              margin: 20px auto 10px;
            }
          }
          
          .share-btn-wrap {
            margin-left: 110px;
            .btn {
              padding: 10px 20px;
              min-width: 140px;
            }
          }
        }
      }
    }
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: -moz-fit-content;
      width: fit-content;
      height: max-content;
      position: fixed !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      @media screen and (max-width: 576px){
        width: 100%;
      }
    }
  }
}

/**********************************************************/

.modal {
  &.statusUpdateModal {
    .modal-dialog {
      max-width: 580px;
      margin: 15px 15px;
      max-height: calc(100% - 30px);
      width: calc(100% - 30px);
      .modal-content {
        .modal-header {
          padding: 10px 20px;
          border-bottom: none;
          align-items: center;
          position: relative;
          button {
            &.close {
              min-width: auto;
              padding: 5px;
              float: none;
              margin: 0px 0px 0px auto;
              outline: none;
              svg {
                .a {
                  opacity: 0.7;
                  fill: $black;
                }
              }
            }
          }
          &::after {
            content: "";
            display: inline-block;
            width: calc(100% - 30px);
            bottom: 0;
            left: 15px;
            position: absolute;
            height: 1px;
            background-color: $gray;
          }
        }
        .modal-body {
          padding: 20px;
          .profileFlexBox {
            @include flex();
            align-items: flex-start;
            margin-bottom: 15px;
            .imgBox {
              width: 50px;
              height: 50px;
              border: 1px solid $gray;
              @include radius(50%);
              margin-right: 10px;
              @include flex();
              align-items: center;
              justify-content: center;
              overflow: hidden;
              img {
                max-width: 100%;
                height: auto;
              }
            }
            .info {
              width: calc(100% - 60px);
              h5 {
                &.name {
                  @include font(16);
                  color: $blackColor;
                  margin: 0px 0px 8px 0px;
                }
              }
              .selectField {
                select {
                  padding: 5px 15px 5px 10px;
                  width: 100px;
                  @include radius(30px);
                  border: 1px solid $gray;
                  @include font(14);
                  color: $black;
                  appearance: none;
                  background: url(../img/arrowdown.png) no-repeat;
                  background-size: 10px;
                  background-position: 95% center;
                }
              }
            }
          }
          .textBox {
            margin-bottom: 0;
            textarea {
              padding: 10px;
              width: 100%;
              height: 150px;
              overflow-x: hidden;
              overflow-y: auto;
              background-color: $white;
              @include radius(4px);
              @include font(14);
              line-height: 22px;
              color: black;
              border: 1px solid $gray;
              outline: none;
              resize: none;
              margin-bottom: 15px;
              &::placeholder {
                color: rgba($black, .50%);
              }
            }
          }
          .flexBox {
            @include flex();
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
            ul {
              @include flex();
              align-items: center;
              margin:0;
              padding:0;
              li {
                list-style: none;
                margin-right: 15px;
                @include flex();
                align-items: center;
                span {
                  display: inline-block;
                  vertical-align: middle;
                }
                a {
                  text-decoration: none;
                  @include flex();
                  align-items: center;
                  cursor: pointer;
                  padding: 5px;
                  &.videoLink {
                    span {
                      margin-right: 0!important;
                      &.icon {
                        svg {
                          .a {
                            fill: #19d7cd;
                          }
                        }
                      }
                    }
                    label {
                      display: inline-block;
                      vertical-align: middle;
                      span {
                        display: block;
                        margin-left: 10px!important;
                      }
                    }
                  }
                  &.attachLink {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: none;
                            stroke: $baseColor;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 2px;
                          }
                        }
                      }
                    }
                  }
                  &.photoLink {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: $yellow;
                          }
                        }
                      }
                    }
                  }
                  span {
                    display: inline-block;
                    @include font(14);
                    color: $black;
                    &.icon {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
            button {
              outline: none;
              cursor: pointer;
              &.postBtn {
                background-color: $lightBlue;
                padding: 15px 20px;
                @include font(14);
                color: $white;
                @include radius(4px);
                border: none;
              }
            }
          }
        }
      }
    }
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
  .MuiIconButton-root {
    padding: 5px;
    outline: none;
    background: rgba(0, 0, 0, 0.04);
    margin-bottom: 5px;
  }
}

/************************************************************/

/**************************Check in Modal(Popup)***********/
.checkInModalOverlay{
  .MuiDialog-paperWidthSm{
    max-width: 500px;
  }
  .modal-dialog{
    width: 500px;
    height: auto;
    margin: 0;
    padding: 0;
    max-width: 100%;
    .modal-content{
      height: 100%;
    }
    .form-info-box{
      .item{
        .search{
          position: relative;
          margin: 20px;
          span{
            &.searchIcon{
              position: absolute;
              left: 7px;
              top: 8px;
              padding-right: 2px;
              border-right: 1px solid $border;
              color: #999;
              padding-top: 1px;
            }
            
          }
          input{
            height: 40px;
            width: 100%;
            padding: 5px 50px 5px 40px;
            box-sizing: border-box;
            border: 1px solid $border;
            border-radius: 3px;
          }
          button.locationLock{
            border: 1px solid $border;
            border-radius: 3px;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            cursor: pointer;
            &:hover, &:focus, &:active{
              border-color: $border;
              background: $border;
              outline: none;
            }
          }
        }
      }
    }
  }
  & + .pac-container{
    z-index: 1300;
  }
  button{
    &:hover, &:focus, &:active{
      outline: none;
    }
  }
}
.searchLocation{
  .search{
    position: relative;
    margin:0;
    span{
      &.searchIcon{
        position: absolute;
        left: 7px;
        top: 11px;
        padding-right: 2px;
        border-right: 1px solid $border;
        color: #999;
        padding-top: 1px;
      }
    }
    input{
      height: 46px;
      width: 100%;
      padding: 5px 50px 5px 40px !important;
      box-sizing: border-box;
      border: 1px solid $border;
      border-radius: 3px;
      font-size: 0.875rem;
      color: $black;
    }
    button.locationLock{
      border: 1px solid $border;
      border-radius: 3px;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      cursor: pointer;
      width: 46px;
      &:hover, &:focus, &:active {
        border-color: $border;
        background: $border;
        outline: none;
      }
    }
  }
  &.contactPage{
    .search{
      margin-bottom: 20px;
      span{
        &.searchIcon{
          top: 9px;
        }
      }
      input{
        height: 43px;
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .suggestCollegeSection {
    > .container {
      max-width: 1600px;
    }
  }
}

@media screen and (max-width: 991px) {
  .suggestCollegeSection {
    > .container {
      max-width: 100%;
      .flexBox {
        .leftDataBox {
          width: 230px;
          .AthleteInfoBox {
            .AthleteInfo {
              padding: 15px 10px;
            }
            .activityCollegeBox {
              padding: 15px 10px;
            }
          }
          .profileDataBox {
            padding: 15px 10px;
          }
          .teamOfferBox {
            padding: 15px 10px;
          }
          .targetCollege {
            .head {
              padding: 15px 10px 0px 10px;
            }
            ul {
              li {
                padding: 10px;
              }
            }
            .manageLink {
              padding: 10px;
            }
          }
        }
        .dataBox {
          width: calc(100% - 240px);
          margin-right: 0;
          margin-bottom: 15px;
          .tabsBox {
            .tabFlex {
              .tab {
                margin-right: 8px;
                a {
                  @include font(12);
                  padding: 10px 5px;
                }
              }
            }
            .followingDataBox {
              padding: 10px;
              .item {
                padding: 10px 0;
                .imgDataFlexBox {
                  .imgBox {
                    flex: 0 0 40px;
                    width: 40px;
                    height: 40px;
                    img {
                      width: 40px!important;
                      height: 40px!important;
                    }
                  }
                  .dataFlexBox {
                    width: calc(100% - 50px);
                    flex-wrap: wrap;
                    div {
                      width: 100%;
                      padding-right: 0;
                      h5 {
                        @include font(13);
                      }
                      span {
                        @include font(11);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .rightDataBox {
          width: calc(100% - 240px);
          margin-left: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .suggestCollegeSection {
    > .container {
      max-width: 540px;
      .flexBox {
        .leftDataBox {
          order: 1;
          width: 100%;
          margin: 0 auto 0 auto;
          .AthleteInfoBox {
            .AthleteInfo {
              background-size: cover;
              .profileBox {
                margin-top: 0;
                .playerName {
                  margin-bottom: 5px;
                }
                ul {
                  margin-bottom:0;
                  li {
                    margin-bottom: 5px;
                    &:last-child {
                      display: none;
                      margin-bottom: 0;
                    }
                  }
                }
                .imgBoxBlock {
                  width:80px;
                  height: 80px;
                  div {
                    &.imgBox {
                      width:80px;
                      height: 80px;
                      .image {
                        width: 80px!important;;
                        height: 80px;
                      }
                    }
                  }
                }
              }
              .flexBox {
                margin-top:10px;
                padding-top:12px;
                display: none;
              }
            }
            .activityCollegeBox {
              display: none;
            }
            &.show {
              .AthleteInfo {
                .profileBox {
                  ul {
                    li {
                      &:last-child {
                        display: block;
                      }
                    }
                  }
                }
                .flexBox {
                  display: block;
                }
              }
              .activityCollegeBox {
                display: block;
              }
            }
          }
          .profileDataBox, .teamOfferBox, .targetCollege {
            display: none;
            &.show {
              display: block;
            }
          }
          .showMoreless {
            display: block;
          }
          .targetCollege {
            &.sticky-box {
              position: static;
            }
          }
        }
        .dataBox {
          order: 2;
          width: 100%;
          margin: 0 auto 15px;
          .tabsBox {
            .heading {
              @include font(16);
            }
            .tabFlex {
              .tab {
                a {
                  @include font(14);
                  padding: 10px;
                }
              }
            }
            .followingDataBox {
              padding:10px;
              height: 470px!important;
            }
          }
        }
        .rightDataBox {
          padding-left: 0;
          order: 3;
          width: 100%;
          margin: 0 auto 10px auto;
          .importNotice {
            &.sticky-box {
              position: static;
            }
          }
        }
      }
    }
  }
  .modal {
    &.statusUpdateModal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .textBox {
              .row {
                .col-sm-10 {
                  width: 80%;
                  padding-right: 0;
                }
                .col-sm-2 {
                  width: 20%;
                }
              }
            }
            .flexBox {
              ul {
                li {
                  margin-right:4px;
                }
              }
            }
          }
        }
      }
    }
    &.shareUpdateModal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .field-data-modal {
              padding-left: 0;
              padding-right: 0;
              .row {
                margin-bottom: 12px;
                .col-md-5 {
                  p {
                    margin-bottom: 10px;
                  }
                }
                .countryPhoneFlexBox {
                  .textBox {
                    input {
                      border: none;
                      @include font(13);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .suggestCollegeSection {
    > .container {
      .flexBox {
        .leftDataBox {
          .AthleteInfoBox {
            .AthleteInfo {
              padding: 15px;
              background-size: calc(100% + 5px);
            }
            .activityCollegeBox {
              padding: 15px;
            }
          }
          .profileDataBox {
            padding: 15px;
          }
          .teamOfferBox {
            padding: 15px;
          }
        }
      }
    }
  }
  .athleteDashSection > {
    .container {
      .flexBox {
        .dataBox {
          .postBox {
            div {
              &.flexBox {
                ul {
                  li {
                    margin-right: 2px;
                  }
                }
              }
            }
          }
        }
        .rightDataBox {
          .verificationProcess {
            right: 15px;
            width:92%;
          }
        }
      }
    }
  }
  .modal {
    &.statusUpdateModal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .flexBox {
              display: block;
              button {
                &.postBtn {
                  margin-top: 10px;
                }
              }
              ul {
                display: block;
                li {
                  display: inline-block;
                  vertical-align: middle;
                  a {
                    > span {
                      margin-left: 0!important;
                    }
                    label {
                      span {
                        margin-left: 5px;
                      }
                    }
                  }
                  &:first-child {
                    a {
                      padding-left: 0;
                    }
                  }
                  &:last-child {
                    a {
                      padding-left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .suggestCollegeSection {
    > .container {
      .flexBox {
        .dataBox {
          .tabsBox {
            .tabFlex {
              .tab {
                a {
                  @include font(14);
                  padding: 10px 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
