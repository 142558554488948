@import '../../../../../../assets/css/variables';
@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/reset";

.popUpClaimProfileSection{
    min-height: 100vh;
    background: rgba(0,0,0,0.6);
    @include flex();
    align-items: center;
    justify-content: center;
    .popUpClaimBlock{
        max-width: 1023px;
        width: 100%;
        background: $white;
        @include radius(5px);
        @include shadow(0px, 3px, 20px, #00000087);
        padding: 15px;
        margin: 100px 15px;
        max-height: calc(100% - 30px);
        width: calc(100% - 30px);
       
        .closeBtn{
            // text-align: right;
            // margin-bottom: 20px;
            // background-color: blue;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            button{
                border:none;
                outline: none;
                background: transparent;
            }
        }
        .heading{
            font-size: 21px;
            color: $blackColor;
            margin: 0px 0px 0px 0px;
            // text-align: left;
        }
        .profileBtn{
            text-align: center;
            margin-bottom: 15px;
            button{
                @include radius(4px);
                color: $white;
                background-color: $lightBlue;
                font-size: 14px;
                text-transform: none;
                cursor: pointer;
                outline: none;
                border: none;
                padding: 15px 20px;
            }
        }
        p{
            &.info{
                max-width: 440px;
                text-align: center;
                margin: 0px auto 30px auto;
                font-size: 14px;
                line-height: 22px;
            }
            &.skip{
                text-align: center;
                margin: 0px 0px 30px 0px;
                a{
                    text-decoration: underline;
                    color: $baseColor;
                    font-size: 14px;
                    display: inline-block;
                }
            }
        }
    }
    &.popUpClaimBlock{
        .popUpClaimBlock{
            max-width: 1200px;
            margin: 60px 15px 0 15px;
            padding-bottom: 30px;
            p{
               &.info{
                   text-align: left;
               }
            }
        }
    }
}



.actionBox{
    margin-top: 15px;
    @include flex();
    justify-content: center;
    align-items: center;
    position: relative;
    button{
        @include font(14);
        outline: none;
        border: none;
        padding: 15px 20px;
        box-shadow: none;
        cursor: pointer;
        &.yesClaimProfileBtn{
            @include flex();
            align-items: center;
            padding: 10px 15px;
            background-color: $lightBlue;
            @include font(14);
            color: $white;
            @include radius(4px);
            margin-right: 10px;
            span{
                display: inline-flex;
            }
        }
        &.noBtn{
            background-color:$baseColor;
            padding: 10px 15px;
            color: $white;
            @include font(14);
            @include flex();
            align-items: center;
            margin-right: 10px;
            @include radius(4px);
            span{
                display: inline-flex;
            }
        }
    }
}
@media  screen and (max-width:479px) {
    .popUpClaimProfileSection{
        .popUpClaimBlock{
            .heading{
                @include font(20);
            }
        }
    } 
}