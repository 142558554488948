@import '../../../../../../../assets/css/mixin';
@import '../../../../../../../assets/css/reset';
@import '../../../../../../../assets/css/variables';

.trendingProfileSection{
    background-color: $bgLightGray;
    padding: 15px 0;
    margin-top: 60px;
    > .container{
       >  .flexBox{
            @include flex();
            flex-wrap: wrap;
            .leftDataBox{
                width: 250px;
                .AthleteInfoBox{            
                    .AthleteInfo{
                        padding: 15px;
                        position: relative;
                        background-image: url(../img/profileBg.png);
                        background-repeat: no-repeat;
                        background-size: auto;
                        .profileBox{
                            margin-top: 10px;
                            .imgBoxBlock{
                                width: 100px;
                                height: 100px;
                                @include radius(50%);
                                margin: 0 auto;
                                position: relative;
                                div{
                                    &.imgBox{
                                        width: 100px;
                                        height: 100px;
                                        @include radius(50%);
                                        border: 2px solid $white;
                                        overflow:hidden;
                                        .image {
                                            width: 100px;
                                            height: 100px;
                                            object-fit: cover;
                                          }
                                        a{
                                            text-decoration: none;
                                            display: inline-block;
                                            width: 100px;
                                            height: 100px;
                                            @include radius(50%);
                                            img{
                                                max-width: 100%;
                                                height: auto;
                                            }
                                        }
                                    
                                    }
                                    &.icon{
                                        position: absolute;
                                        right: -10px;
                                        bottom: 0;
                                        cursor: pointer;
                                        background-color: $white;
                                        @include radius(50%);
                                        svg{
                                            .a{fill:$lightBlue}.b{fill:$white;}
                                        }
                                    }
                                }
                                .browseEditProfile {
                                    &.browse {
                                        position: absolute;
                                        left: 80%;
                                        top:5px;
                                        bottom: auto;
                                        transform: none;
                                        padding: 12px;
                                        cursor: pointer;
                                        label {
                                          background: $black;
                                          @include font(15);
                                          font-weight: bold;
                                          line-height: 24px;
                                          text-align: center;
                                          color: $white;
                                          position: absolute;
                                          width: 24px;
                                          height: 24px;
                                          right: 0;
                                          top: 0;
                                          @include radius(100%);
                                          z-index: 11;
                                          cursor: pointer;
                                        }
                                        input[type="file"] {
                                          position: absolute;
                                          left: 0;
                                          right: 0;
                                          top: 0;
                                          z-index: 1;                      
                                          opacity: 0;
                                          cursor: pointer;
                                        }
                                    }
                                  }
                            }
                            .playerName{
                                margin: 10px 0px;
                                text-align: center;
                                @include font(18);
                                color: $white;
                            }
                            ul{
                                margin: 0px 0px 20px 0px ;
                                padding: 0px 0px;
                                li{
                                    list-style: none;
                                    margin: 0px 0px 10px 0px;
                                    text-align: center;
                                    @include flex();
                                    align-items: flex-start;
                                    justify-content: center;
                                    span{
                                        display: inline-block;
                                        @include font(14);
                                        color: $white;
                                        line-height: normal;
                                        &.label{
                                            margin: 0px 3px 0px 0px;
                                        }
                                    }
                                    &:last-child{
                                        margin: 0px 0px 0px 0px;  
                                    }
                                }
                            }
                        }
                    .flexBox{
                        @include flex();
                        justify-content: space-between;
                        border-top: 1px solid rgba(255, 255, 255,.5);
                        padding-top: 20px;
                        margin: 0;
                        li{
                            list-style: none;
                            width: calc(33.3% - 10px);
                            margin-right: 10px;
                            &:last-child{
                                margin-right: 0;
                            }
                            span{
                                display: block;
                                text-align: center;
                                &.label{
                                    @include font(13);
                                    font-weight: normal;
                                    color: $white;
                                    margin: 5px 0 0 0;
                                }
                                &.value{
                                    @include font(14);
                                    font-weight: 600;
                                    color: $white;
                                }
                            }
                        }
                    }
                    .profileLink{
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        cursor: pointer;
                        display: inline-flex;
                        svg{
                            .a{fill:$yellow;}
                        }
                    }
                    }
                    .activityCollegeBox{
                        background-color: $white;
                        padding: 15px;
                        @include shadow(1px, 2px, 2px, #00000017);
                        @include radius(0 0 2px 2px);
                        div{
                            &.recuringActivity{
                                border-bottom: 1px solid rgba(112, 112, 112,0.3);
                                .heading{
                                    @include font(16);
                                    color: $blackColor;
                                    font-weight: 600;
                                    margin: 0;
                                }
                                ul{
                                    margin: 20px 0 10px 0;
                                    padding: 0;
                                    @include flex();
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    li{
                                        list-style: none;
                                        width: calc(25% - 10px);
                                    margin: 0 10px 10px 0; 
                                    span{
                                        display: block;
                                        text-align: center;
                                        &.value{
                                            @include font(14);
                                            color: $black;
                                            font-weight: 600;
                                        }
                                        &.label{
                                            @include font(12);
                                            color:$black;
                                            font-weight: normal;
                                            margin-top: 5px;
                                        }
                                    }
                                    &:last-child{
                                        margin: 0 10px 0 0;  
                                    }
                                    }
                                }
                            }
                            &.myCollege{
                                .heading{
                                    @include font(16);
                                    color: $blackColor;
                                    font-weight: 600;
                                    margin: 0 0 15px 0;
                                }
                                ul{
                                    margin: 0;
                                    padding: 0;
                                    @include flex();
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    li{
                                        list-style: none;
                                    width: calc(25% - 10px);
                                    margin: 0 10px 0 0; 
                                    span{
                                        display: block;
                                        text-align: center;
                                        &.value{
                                            @include font(14);
                                            color: $black;
                                            font-weight: 600;
                                        }
                                        &.label{
                                                @include font(12);
                                                color:$black;
                                                font-weight: normal;
                                                margin-top: 5px;
                                        }
                                    }
                                    &:last-child{
                                        margin: 0 10px 0 0;  
                                    }
                                }
                            }
                        }
                    }
                }
}
.profileDataBox{
    background-color: $white;
    padding: 15px 15px;
    @include shadow(1px, 2px, 2px, #00000017);
    margin: 10px 0;
    div{
        &.head{
            @include flex();
            align-items: center;
            justify-content: space-between;
            .title{
                @include font(16);
                color: $black;
                font-weight: 600;
                margin: 0 0 15px 0;
            }
            .editLink{
                @include font(14);
                color: $baseColor;
                margin: 0 0 15px 0;
                text-decoration: none;
                cursor: pointer;
                &:hover {
                    color: #b12c1a;
                }
            }
        }
        &.porgress{
            @include flex();
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            label{
                display: inline-block;
                @include font(14);
                color: $green;
                font-weight: 600;
                margin-right: 10px;
            }
            .bar{
                display: inline-flex;
                width: calc(100% - 45px);
                @include radius(7px);
                border:1px solid $green;
                height: 8px;
                position: relative;
                &::before{
                    content: '';
                    display: inline-flex;
                    width: 70%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: $green;
                    @include radius(7px);
                }
            }
        }
        
    }
 .level{
     li{
         list-style: none;
         margin: 0 0 15px 0;
         &:last-child{
            margin: 0 0 0 0;    
         }
         &.passed{
            a{
                color: $green;
                &.checkList{
                  
                    span{
                        &.icon{
                            position: relative;
                            svg{
                                .a{fill:$green;}
                            }
                            .count{
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 12px;
                                height: 12px;
                                @include radius(50%);
                                background-color: $baseColor;
                                @include font(8);
                                color: $white;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                     
                    }
                       }
                               span{
                                   &.icon{
                                    svg{
                                        .a{fill:none;stroke:$green;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}
                                    }
                                   }
                                   &.label{
                                    color: $green;
                                }
                               } 
                                   
            }
         }
         a{
             display: inline-flex;
             cursor: pointer;
             text-decoration: none;
            &.video{
                span{
                    &.icon{
                        svg{
                            .a,.b{fill:none;stroke:$black;stroke-linecap:round;stroke-width:1.5px;}.a{stroke-linejoin:round;}
                        }
                    }
                }
            }
             
            span{
                display: inline-flex;
                &.icon{
                    width: 20px;
                    margin-right: 10px;
                }
                &.label{
                    @include font(16);
                    color: $black;
                    font-weight: normal;
                }
            }
         }
     }
 }
}
.teamOfferBox{
    background-color: $white;
    @include radius(2px);
    padding: 15px 15px;
    margin-bottom: 10px;
    div{
        &.myTeam{
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $gray;
            .head{
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title{
                    @include font(14);
                    color: $blackColor;
                    font-weight: 600;
                    margin: 0 0 15px 0;
                }
                .editLink{
                    text-decoration: none;
                    display: inline-flex;
                    cursor: pointer;
                    color: $baseColor;
                    margin: 0 0 15px 0;
                    &:hover {
                        color: #b12c1a;
                    }
                }
            }
            ul{
                margin: 0 0;
            padding: 0 0;
            li{
                list-style: none;
                @include flex();
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                div{
                    &.imgBox{
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                        .image {
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                          }
                        img{
                            max-width: 100%;
                            max-height: 100%;
                            height: auto;
                        }
                    }
                    &.info{
                        width: calc(100% - 50px);
                        span{
                            @include font(14);
                            display: inline-block;
                            color: rgba(0,0,0,0.7);
                        }
                    }
                }
                
            }
            }
            .viewMore{
                margin-top: 5px;
                text-align: center;
                a{
                    display: inline-block;
                    @include font(14);
                    color: $baseColor;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        &.offer{
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid $gray;
            .head{
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title{
                    margin: 0 0 15px 0;
                    @include font(16);
                    color: $blackColor;
                    font-weight: 600;
                }
                .editLink{
                    text-decoration: none;
                    display: inline-flex;
                    cursor: pointer;
                    color: $baseColor;
                    margin: 0 0 15px 0;
                    &:hover {
                        color: #b12c1a;
                    }
                }
            }
            ul{
                margin: 0 0;
            padding: 0 0;
            li{
                list-style: none;
                @include flex();
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                div{
                    &.imgBox{
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                        .image {
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                          }
                        img{
                            max-width: 100%;
                            max-height: 100%;
                            height: auto;
                        }
                    }
                    &.info{
                        width: calc(100% - 50px);
                        .title{
                            @include font(14);
                            font-weight: 600;
                            color: #007bff;
                            margin: 0 0 10px 0;
                        }
                        a {
                            text-decoration: none;
                            &:hover {
                                .title {
                                    color:#0056b3;
                                }
                            }
                        }
                        .date{
                            @include flex();
                            label{
                                display: inline-block;
                                @include font(12);
                                color: $blackColor;
                                margin-right: 3px;
                            }
                            span{
                                display: inline-block;
                                @include font(12);
                                color: $blackColor;
                            }
                        }
                        
                    }
                }
                
            }
            }
            .viewMore{
                margin-top: 10px;
                text-align: center;
                a{
                    display: inline-block;
                    text-decoration: none;
                    @include font(14);
                    color: $baseColor;
                    cursor: pointer;
                }
            }
        }
        &.commitment{
            .head{
                @include flex();
                align-items: center;
                justify-content: space-between;
                .title{
                    margin: 0 0 15px 0;
                    @include font(16);
                    color: $blackColor;
                    font-weight: 600;
                }
                .editLink{
                    text-decoration: none;
                    display: inline-flex;
                    cursor: pointer;
                    color: $baseColor;
                    margin: 0 0 15px 0;
                    &:hover {
                        color: #b12c1a;
                    }
                }
            }
            ul{
                margin: 0 0;
            padding: 0 0;
            li{
                list-style: none;
                @include flex();
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                div{
                    &.imgBox{
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                        .image {
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                          }
                        img{
                            max-width: 100%;
                            max-height: 100%;
                            height: auto;
                        }
                    }
                    &.info{
                        width: calc(100% - 50px);
                        .title{
                            @include font(14);
                            color: #007bff;
                            text-decoration: none;
                            font-weight: 600;
                            margin: 0 0 10px 0;
                        }
                        a {
                            text-decoration: none;
                            &:hover {
                                .title {
                                    color:#0056b3;
                                }
                            }
                        }
                        span{
                            display: inline-block;
                            @include font(12);
                            color: $blackColor;
                        }
                        
                    }
                }
                
            }
            }
            .viewMore{
                margin-top: 10px;
                text-align: center;
                a{
                    display: inline-block;
                    text-decoration: none;
                    @include font(14);
                    color: $baseColor;
                    cursor: pointer;
                }
            }
        }
    }
}
.targetCollege{
    background-color: $white;
    @include radius(2px);
    @include shadow(1px, 2px, 2px ,#00000017);
    .head{
        padding: 20px 15px 0 15px;
        @include flex();
        align-items: center;
        justify-content: space-between;
        h5{
            @include font(16);
            font-weight: 600;
            color: $blackColor;
            margin: 0 0 15px 0;
        } 
        a{
            @include font(14);
            color: $baseColor;
            font-weight: normal;
            margin: 0 0 15px 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        &.addBtn{
            width: 20px;
            height: 20px;
            @include font(20);
            color: $white;
            background-color: $baseColor;
            @include radius(2px);
        }
       }
    }
    ul{
        margin: 0 0;
        padding: 0 0;
        li{
            list-style: none;
            padding: 15px 15px;
            border-bottom: 1px solid $gray;
            @include flex();
            align-items: flex-start;
           .count{
               display: inline-block;
               margin-right: 10px;
               @include font(14);
               color: $blackColor;
               width: 30px;
           }
           .infoBox{
               width: calc(100% - 40px);
               @include flex();
               align-items: flex-start;
               .imgBox{
                   width: 30px;
                   height: 30px;
                   margin-right: 10px;
                   .image {
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                    }
                    img{
                       max-width: 100%;
                       max-height: 100%;
                       height: auto;
                    }
                }
               .data{
                width: calc(100% - 40px);
                .title{
                    @include font(14);
                    color: #007bff;
                    font-weight: 600;
                    margin: 0 0 10px 0;
                }
                a {
                    text-decoration: none;
                    &:hover {
                        color:#0056b3;
                        .title {
                            color:#0056b3;
                        }
                    }
                }
                .flexBox{
                    @include flex();
                    align-items: center;
                    justify-content: space-between;
                    span{
                        display: inline-block;
                        @include font(13);
                        color: $black;
                        width: calc(100% - 30px);
                    }
                    .forwardLink{
                        text-decoration: none;
                        display: inline-block;
                        padding: 5px 5px;
                        svg{
                            .a{fill:$lightBlue;} 
                        }
                    }
                }
               }
           }
        }
    }
    .manageLink{
        text-align: center;
        padding: 15px 15px;
        a{
            display: inline-block;
            text-decoration: none;
            @include font(14);
            color: $baseColor;
            cursor: pointer;
            &:hover {
                color: #b12c1a;
            }
        }
    }
    &.sticky-box {
        position: -webkit-sticky;
        position: sticky;
        top: 65px;
        z-index: 1;
    }
}  
.showMoreless{
    text-align: center;
    margin: 15px 0;
    display: none;
    a{
        text-decoration: none;
        display: inline-block;
        @include font(14);
        color: $baseColor;
        span{
            display: inline-block;
            &.icon{
                margin-left: 10px;
                svg{
                 .a{
                     fill: $baseColor;
                 }   
                }
            }
        }
    }
}
}
            .dataBox{
                width: calc(100% - 520px);
                margin: 0 10px;
             
                   .backHome{
                    padding-bottom: 10px;
                    border-bottom:1px solid $gray;
                    margin-bottom:15px;
                    a{
                        display:inline-flex;
                        align-items: center;
                        text-decoration:none;
                        cursor:pointer;
                        padding:5px 5px;
                        @include font(14);
                        color:$black;
                        span{
                            display:inline-block;
                            &.icon{
                                margin-right:10px;
                                svg{
                                    fill:$black;
                                    width:15px;
                                    height:auto;
                                }
                            }
                        }
                    }
                }
                    .tabsBox{
                        .heading{
                            @include font(20);
                            color: $black;
                            font-weight: 600;
                            margin: 0 0 15px 0;
                        }
                            .tabFlex{
                                @include flex();
                                margin-bottom: 15px;
                                .tab{
                                    margin-right: 10px;
                                    a{
                                        padding: 10px;
                                        display: inline-flex;
                                        @include font(16);
                                        color: $black;
                                        text-decoration: none;
                                        position: relative;
                                        &:hover{
                                            color: $baseColor;
                                        }
                                    }
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    &.selected{
                                        a{
                                            color:$baseColor;
                                            border-bottom:1px solid $baseColor;
                                        }
                                    }
                                }
                            }
              
                            .followingDataBox{
                                background-color: $white;
                                    padding: 15px;
                                    @include shadow(1px, 2px, 2px, #00000017);
                                    height: 100%!important;
                                .item{
                                    border-bottom:1px solid $gray;
                                    padding:15px 0;
                                    .imgDataFlexBox{
                                        @include flex();
                                        align-items: flex-start;
                                        margin-bottom: 0;
                                        .imgBox{
                                            flex: 0 0 50px;
                                            width: 50px;
                                            height: 50px;
                                            margin-right: 10px;
                                            @include radius(50%);
                                            position:relative;
                                            .initialLetter{
                                                width: 50px;
                                                height: 50px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                border: 1px solid $border;
                                                @include radius(50%);
                                                text-transform: uppercase;
                                            }
                                            .image {
                                                width: 100px;
                                                height: 100px;
                                                object-fit: cover;
                                            }
                                            img{
                                                width: 50px!important;
                                                height: 50px!important;
                                                object-fit: fill;
                                                max-width: 100%;
                                                @include radius(50%);
                                            }
                                            span{
                                                &.icon{
                                                    display: inline-block;
                                                    position: absolute;
                                                    bottom:0;
                                                    right:-4px;
                                                    &.blueCheck{
                                                        background-color: $white;
                                                        @include radius(50%);
                                                        svg{
                                                            width:18px;
                                                            height:auto;
                                                            .a{
                                                                fill: $lightBlue;
                                                            }
                                                        }
                                                    }
                                                    &.redAlert{
                                                        svg{
                                                              width:18px;
                                                            height:auto;
                                                            .a{
                                                                fill: #ff4a31;
                                                            }
                                                            .b{
                                                                fill: $white;  
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .dataFlexBox{
                                            width: calc(100% - 60px);
                                            @include flex();
                                            align-items: flex-start;
                                            justify-content: space-between;
                                            div{
                                                width: calc(100% - 75px);
                                                padding-right:10px;
                                                h5{
                                                    @include font(16);
                                                    color: $blackColor;
                                                    margin: 0 0 5px 0;
                                                    span{
                                                        display: inline-block;
                                                        @include font(12);
                                                        color: $green;
                                                    }
                                                }
                                                a {
                                                    text-decoration: none;
                                                }
                                                ul{
                                                    margin:0 0;
                                                    padding:0 0;
                                                    @include flex();
                                                    flex-wrap:wrap;
                                                    li{
                                                        list-style:none;
                                                        margin-right:0;
                                                        margin-bottom:0;
                                                        position:relative;
                                                        padding-right: 0;
                                                        border-right: 1px solid $black;
                                                        span{
                                                            display:inline-block;
                                                            @include font(13);
                                                            color: $black;
                                                            line-height:16px;
                                                        }
                                                    }
                                                }
                                                p{
                                                    @include font(13);
                                                    color:$black;
                                                    line-height:16px;
                                                    margin-top:5px;
                                                }
                                                &.action{
                                                    @include flex();
                                                    align-items: center;
                                                    width: auto;
                                                    position: relative;
                                                    padding-right:0;
                                                    margin-top:10px;
                                                    a{
                                                        text-decoration:none;
                                                        &.unFollowingLink{
                                                          @include font(13);
                                                          color:$white;
                                                          background-color: $baseColor;
                                                          padding:2px 10px;
                                                          @include radius(10px);
                                                            line-height:16px;
                                                        }
                                                        &.followingLink{
                                                            font-size:13px;
                                                            color:$white;
                                                            background-color: $lightBlue;
                                                            padding:3px 10px;
                                                            @include radius(10px); 
                                                            line-height:16px;
                                                        }
                                                        &.followerLink{
                                                            font-size:13px;
                                                            color:$lightBlue;
                                                           border:1px solid $lightBlue;
                                                            padding:2px 10px;
                                                            @include radius(10px); 
                                                            line-height:16px;  
                                                        }
                                                    }
                                               
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        .loadMoreBox{
                            text-align: center;
                            padding: 10px;
                            background-color: $white;
                            @include shadow(1px, 2px, 2px, #00000017);
                            @include radius(4px);
                            a{
                                display: inline-block;
                                text-decoration: none;
                                font-size: 14px;
                                color: $black;
                                cursor: pointer;
                            }
                        }
                    }
                
            }
            .rightDataBox{
                width: 250px;
                .verificationBox{
                    margin-bottom: 15px;
                    @include radius(4px);
                    background-color: $white;
                    @include shadow(1px, 2px, 2px, #00000017);
                    cursor: pointer;
                    .head{
                        @include flex();
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px 10px 0 10px;
                        span{
                            &.icon{
                                display: inline-block;
                                margin-right: 10px;
                                svg{
                                    .a{fill:#ff4a31;}.b{fill:$white;}
                                }
                            }
                        }
                     .title{
                         color: $black;
                         font-size: 14px;
                         font-weight: 600;
                         margin: 0 auto 0 0; 
                     }
                     button{
                         &.btn{
                             background-color: transparent;
                             padding: 5px;
                             min-width: auto;
                             max-width: 30px;
                             svg{
                                 .a{
                                     fill: $black;
                                 }
                             }
                         }
                     }   
                    }
                    .stepInfo{
                        padding: 10px;
                        .steps{
                            @include flex();
                            margin-bottom: 10px;
                            margin-left: 5px;
                            span{
                                height: 9px;
                                border: 1px solid $white;
                                @include radius(7px);
                                background: $gray;
                                display: inline-block;
                                width: calc(20% + 5px);
                                margin-left: -5px;
                                &.passed{
                                    background: $lightBlue;
                                    &.active{
                                        position: relative;
                                        z-index: 1;
                                    }
                                }
                            }
                        }
                        .flexBox{
                            @include flex();
align-items: center;
justify-content: space-between;
p{
    font-size: 13px;
    color: $black;
    width: calc(100% - 35px);
}
   .icon{
       svg{
        .a{fill:$lightBlue;}
       }
   }                     }
                    }
                    .collapse{
                        .card{
                            border-top: 1px solid $gray;
                            @include radius(0);
                            padding: 10px;
                            border-left: none;
                            border-right: none;
                            border-bottom: none;
                            .verificationSteps{
                                margin: 0 0 5px 0;
                                padding: 0 0;
                                li{
                                    list-style: none;
                                    margin-bottom: 10px;
                                    @include flex();
                                    align-items: center;
                                    justify-content: space-between;
                                    span{
                                        display: inline-block;
                                        font-size: 13px;
                                        color: $black;
                                        &.icon{
                                            svg{
                                                width: 18px;
                                                height: auto;
                                                .a{fill:$lightBlue;}.b{fill:#fafafa;}
                                            }
                                        }
                                    }
                                    &.completeStep{
                                        span{
                                            color: $baseColor;
                                            &.icon{
                                                svg{
                                                    width: 18px;
                                                    height: auto;
                                                    .a{fill:$baseColor;}
                                                }
                                            }
                                        }
                                    }
                                    &.passed{
                                        span{
                                            color: #999999;
                                            position: relative;
                                            &::before{
                                                content: "";
                                                display: inline-block;
                                                position: absolute;
                                                left: 0;
                                                top: 50%;
                                                transform: translateY(-50%);
                                                width: 100%;
                                                height: 1px ;
                                                background-color: #999;
                                            }
                                            &.icon{
                                                &::before{
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                    &.active{
                                        
                                        span{
                                            &.icon{
                                                svg{
                                                    .a{
                                                        .a{fill:$lightBlue;}.b{fill:#fafafa;}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &.unactive{
                                        span{
                                            &.icon{
                                                
                                                svg{
                                                    .a{
                                                        fill:$gray;
                                                    }
                                                    b{fill:#fafafa;}
                                                }
                                            }
                                        } 
                                    }
                                }
                               
                            }
                            .whyverified{
                             background-color: $bgLightGray;
                             padding: 10px;
                             .title{
                                 @include flex();
                                 align-items: center;
                                 font-size: 13px;
                                 color: $black;
                                 font-weight: 600;
                                 margin: 0 0 10px 0;
                                 span{
                                     display: inline-block;
                                     &.icon{
                                         margin-left: 10px;
                                         svg{
                                             width: 22px;
                                             height: auto;
                                            .a{fill:$lightBlue;}
                                         }
                                     }
                                 }
                             }
                             
                             .info{
                                 font-size: 12px;
                                 color: $black;
                                 font-size: 12px;
                                 line-height: 16px;
                                 .learnMore{
                                     text-decoration: none;
                                     display: inline-block;
                                     color: $lightBlue;
                                     font-weight: 600;
                                     cursor: pointer;
                                 }
                             }   
                            }
                        }
                    }
                }
               
              .trendingProfileBox{
                  background-color: $white;
                  @include radius(2px);
                  @include shadow(1px, 2px, 2px, #00000017);
                  margin-bottom: 10px;
                  .head{
                    padding: 10px;
                    .title{
                        color: $blackColor;
                        font-weight: 600;
                        font-size: 16px;
                    }
                  }
                ul{
                    li{
                        padding: 10px;
                        border-bottom: 1px solid $gray;
                        @include flex();
                        align-items: flex-start;
                        .imgBox{
                            width: 50px;
                            height: 50px;
                            margin-right: 10px;
                            @include radius(50%);
                            position: relative;
                            .initialLetter{
                                width: 50px;
                                height: 50px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid $border;
                                @include radius(50%);
                                text-transform: uppercase;
                            }
                            .image {
                                width: 100px;
                                height: 100px;
                                object-fit: cover;
                              }
                            img{
                                max-width: 100%;
                                height: auto;
                                @include radius(50%);
                            }
                            .icon{
                                position: absolute;
                                bottom: 0;
                                right: -5px;
                                svg{
                                    width: 15px;
                                    height: auto;
                                    //.a{fill:$lightBlue;}
                                        .a{
                                            fill: #ff4a31;
                                        }
                                        .b{
                                            fill: $white;
                                        }
                                }
                            }
                        }
                        .info{
                            width: calc(100% - 60px);
                            h5{
                                font-size: 14px;
                                line-height: 18px;
                                margin: 0 0 5px 0;
                                color: $black;
                                font-weight: 600;
                            }
                            span{
                                display: block;
                                @include font(13);
                                color: $black;
                                margin: 0 0 5px 0;
                            }
                            button{
                                outline: none;
                                cursor: pointer;
                                border: none;
                                &.followBtn{
                                    background-color: transparent;
                                    @include radius(10px);
                                    border: 1px solid $lightBlue;
                                    @include font(13);
                                    color:$lightBlue;
                                    margin-top: 5px;
                                    padding: 2px 10px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }
                }
               .showMoreBox{
                   text-align: center;
                   padding: 10px;
                   a{
                       text-decoration: none;
                       display: inline-block;
                       @include font(14);
                       color: $baseColor;
                       cursor: pointer;
                       &:hover {
                        color: #b12c1a;
                       }
                   }
                   
               } 
              }
               .suggestCollegeBox{
                background-color: $white;
                @include radius(2px);
                @include shadow(1px, 2px, 2px, #00000017);
                margin-bottom: 10px;
                .head{
                  padding: 10px;
                  .title{
                      @include font(16);
                      color: $blackColor;
                      font-weight: 600;
                  }
                }
              ul{
                  li{
                      padding: 10px;
                      border-bottom: 1px solid $gray;
                      @include flex();
                      align-items: flex-start;
                      .imgBox{
                          width: 50px;
                          height: 50px;
                          margin-right: 10px;
                          position: relative;
                          .image {
                            width: 100px;
                          }
                          img{
                              max-width: 100%;
                              max-height: 100%;
                              height: auto;
                          }
                          .icon{
                              position: absolute;
                              bottom: 0;
                              right: 0;
                              svg{
                                  width: 15px;
                                  height: auto;
                              }
                          }
                      }
                      .info{
                          width: calc(100% - 60px);
                          h5{
                              @include font(14);
                              color: #007bff;
                              font-weight: 600;
                              line-height: 18px;
                              margin: 0 0 5px 0;
                          }
                          a {
                            text-decoration: none;
                            &:hover {
                                h5  {
                                    color:#0056b3;
                                }
                            }
                          }
                          span{
                              display: block;
                              @include font(13);
                              color: $black;
                              margin: 0 0 5px 0;
                          }
                          button{
                              outline: none;
                              cursor: pointer;
                              border: none;
                              &.followBtn{
                                background-color: transparent;
                                @include radius(10px);
                                border: 1px solid $green;
                                @include font(13);
                                color:$green;
                                margin-top: 5px;
                                padding: 2px 10px;
                                line-height: 16px;
                            }
                          }
                      }
                  }
              }
             .showMoreBox{
                    text-align: center;
                    padding: 10px;
                    a{
                        text-decoration: none;
                        display: inline-block;
                        @include font(14);
                        color: $baseColor;
                        cursor: pointer;
                        &:hover {
                            color: #b12c1a;
                        }
                    }
                 
                } 
               }
                
            }
        }
    }
}

/*************************getVerifiedModal css*******************************************/

.modal{
    &.getVerifiedModal{
        .modal-dialog{
            max-width: 400px;
            margin: 15px;
            max-height: calc(100% - 30px);
            width: calc(100% - 30px);
            .modal-content{
                .modal-header{
                    padding: 0 10px ;
                    border-bottom: none;
                    button{
                        &.close{
                            min-width: auto;
                            padding: 5px 5px;
                            float: none;
                            margin: 0 0 0 auto;
                            outline: none;
                            svg{
                                .a{opacity:0.7; fill: $black;}   
                            }
                        }
                    }
                }
                .modal-body{
                    padding: 0 15px 15px 15px;
                    .heading{
                        margin: 0 auto 10px auto;
                        max-width: 230px;
                        @include font(18);
                        color: $blackColor;
                        font-weight: 600;
                        text-align: center;
                    }
                    .profileBox{
                        .profileImgBox{
                            position: relative;
                            width: 100px;
                            height: 100px;
                            @include radius(50%);
                            margin: 0 auto;
                            .imgBox{
                                width: 100px;
                                height: 100px;
                                @include radius(50%);
                                margin: 0 auto;
                                .image {
                                    width: 100px;
                                    height: 100px;
                                    object-fit: cover;
                                  }
                                img{
                                    max-width: 100%;
                                    height: auto;
                                }  
                            }
                            .icon{
                                position: absolute;
                                bottom: 0;
                                right: -10px;
                                svg{
                                    .a{
                                        fill: #ff4a31;
                                    }
                                    .b{
                                        fill: $white;
                                    }
                                }
                            }
                        }
                        .name{
                            margin: 10px 0 10px 0;
                            @include font(16);
                            color: $black;
                            font-weight: 600;
                            text-align: center;
                        }
                        ul{
                            margin: 0 0 5px 0;
                            li{
                                list-style: none;
                                margin-bottom: 10px;
                                @include flex();
                                align-items: flex-start;
                                justify-content: center;
                                span{
                                    display: inline-block;
                                    @include font(14);
                                    color:$black;
                                }
                                label{
                                    margin-right: 5px;
                                    display: inline-block;
                                    @include font(14);
                                    color:$black;
                                }
                            }
                        }
                    }
                    p{
                        &.info{
                            @include font(14);
                            color: $black;
                            line-height: 18px;
                            text-align: center;
                            max-width: 300px;
                            margin: 0 auto 10px auto;
                        }
                    }
                    .btnBox{
                        margin: 20px 0;
                        text-align: center;
                        button{
                            &.verifyBtn{
                                background: $lightBlue;
                                @include font(14);
                                color: $white;
                                @include radius(4px);
                                border: none;
                                padding: 15px 20px;
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
        &.show{
            display: flex !important;
             align-items: center;
             justify-content: center;
         }
    }
    
} 


/*************************shareUpdateModal css****************************************/

.modal{
    &.shareUpdateModal{
        .modal-dialog{
            max-width: 400px;
            margin: 15px;
            max-height: calc(100% - 30px);
            width: calc(100% - 30px);
            .modal-content{
                .modal-header{
                    padding: 0 10px ;
                    border-bottom: none;
                    button{
                        &.close{
                            min-width: auto;
                            padding: 5px;
                            float: none;
                            margin: 0 0 0 auto;
                            outline: none;
                            svg{
                                .a{opacity:0.7; fill: $black;}   
                            }
                        }
                    }
                }
                .modal-body{
                    padding: 0 15px 15px 15px;
                    .heading{
                        margin: 0 auto 15px auto;
                        max-width: 230px;
                        @include font(18);
                        color: $blackColor;
                        font-weight: 600;
                        text-align: center;
                    }
                  .socialFlexBox{
                      @include flex();
                      max-width: 260px;
                      margin: 0 auto;
                      justify-content: space-between;
                      .twitter{
                        button{
                              width: 100px;
                              height: 100px;
                              @include flex();
                              align-items: center;
                              justify-content: center;
                              border: 1px solid $lightBlue;
                              @include radius(50%);
                              color: $lightBlue;
                              text-decoration: none;
                              cursor: pointer;
                              background-color: transparent;
                              outline: none;
                              div{
                                  span{
                                      @include font(14);
                                      display: block;
                                      text-align: center;
                                      &.icon{
                                          margin-bottom: 5px;
                                          svg{
                                              width: 30px;
                                              height: auto;
                                              fill: $lightBlue
                                          }
                                      }
                                  }
                              }
                          }
                      }
                      .fbook{
                        button{
                            width: 100px;
                            height: 100px;
                            @include flex();
                            align-items: center;
                            justify-content: center;
                            border: 1px solid $blue;
                            @include radius(50%);
                            color:$blue;
                             text-decoration: none;
                              cursor: pointer;
                              background-color: transparent;
                              outline: none;
                              div{
                                span{
                                    display: block;
                                    @include font(14);
                                    text-align: center;
                                    &.icon{
                                        margin-bottom: 5px;
                                        svg{
                                            width: 30px;
                                            height: auto;
                                            fill: $blue;
                                            path{
                                                fill: $blue;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                  }
                    p{
                        &.info{
                            @include font(14);
                            color: $black;
                            line-height: 18px;
                            text-align: center;
                            max-width: 300px;
                            margin: 20px auto 20px auto;
                           
                        }
                    }
                    .linkBox{
                     width: 100%;
                     @include flex();
                     margin-bottom: 10px;
                     p{
                         @include font(13);
                         color:$black;
                         padding: 10px;
                         border: 1px solid $gray;
                         @include radius(4px 0 0 4px);
                         @include flex();
                         align-items: center;
                         width: calc(100% - 78px);
                     }
                        button{
                            &.copyBtn{
                                background: $lightBlue;
                                @include font(14);
                                color: $white;
                                @include radius(4px);
                                border: none;
                                padding: 15px 20px;
                                outline: none;
                                @include radius(0 4px 4px 0);
                            }
                        }
                    }
                }
            }
        }
        &.show{
            display: flex !important;
             align-items: center;
             justify-content: center;
         }
    }
    
} 


/**********************************************************/


.modal{
    &.statusUpdateModal{
        .modal-dialog{
            max-width: 540px;
            margin: 15px;
            max-height: calc(100% - 30px);
            width: calc(100% - 30px);
            .modal-content{
                .modal-header{
                    padding: 20px;
                    border-bottom: none;
                    align-items: center;
                    position: relative;
                    button{
                        &.close{
                            min-width: auto;
                            padding: 5px;
                            float: none;
                            margin: 0 0 0 auto;
                            outline: none;
                            svg{
                                .a{opacity:0.7; fill: $black;}   
                            }
                        }
                    }
                    &::after{
                        content: '';
                        display: inline-block;
                        width: calc(100% - 30px);
                        bottom: 0;
                        left: 15px;
                        position: absolute;
                        height: 1px;
                        background-color: $gray;
                    }
                }
                .modal-body{
                    padding: 20px;
               .profileFlexBox{
                   @include flex();
                   align-items: flex-start;
                   margin-bottom: 15px;
                   .imgBox{
                       width: 50px;
                       height: 50px;
                       @include radius(50%);
                       margin-right: 10px;
                       @include flex();
                       align-items: center;
                       justify-content: center;
                       .image {
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                      }
                       img{
                           max-width: 100%;
                           height: auto;
                       }
                   }
                   .info{
                       width: calc(100% - 60px);
                       h5{
                           &.name{
                               @include font(16);
                               margin: 0 0 10px 0;
                               color: $blackColor;
                           }
                       }
                       .selectField{
                           select{
                               width: 100px;
                               @include radius(30px);
                               border: 1px solid #707070;
                               padding: 3px 15px 3px 10px;
                               @include font(14);
                               color: $black;
                               appearance:none;
                               background:url(../img/arrowdown.png) no-repeat;
                               background-size:10px;
                               background-position: 95% center;
                           }
                       }
                   }
               }    
               .textBox{
                margin-bottom: 15px;
                   textarea{
                       padding: 10px;
                       width: 100%;
                       height: 150px;
                       overflow-x: hidden;
                       overflow-y: auto;
                       background-color: #F9F9F9;
                       @include radius(4px);
                       @include font(16);
                       color: $black;
                       line-height: 22px;
                       border: none;
                       outline: none;
                       resize: none;
                   }
               }
               .flexBox{
                   @include flex();
                   align-items: center;
                   justify-content:space-between;
                   ul{
                       @include flex();
                       align-items: center;
                       margin: 0;
                       padding: 0;
                       li{
                           list-style: none;
                           margin-right: 15px;
                           @include flex();
                           align-items: center;
                           a{
                               text-decoration:none;
                               display: inline-block;
                               cursor: pointer;
                               padding:5px 5px;
                               &.videoLink{
                                   span{
                                       &.icon{
                                           svg{
                                               .a{
                                                 fill:#19d7cd;
                                               }
                                           }
                                       }
                                   }
                               }
                               &.attachLink{
                                span{
                                    &.icon{
                                        svg{
                                            .a{
                                                fill: none;
                                                stroke: $baseColor;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-width: 2px;
                                            }
                                        }
                                    }
                                }
                            }
                            &.photoLink{
                                span{
                                    &.icon{
                                        svg{
                                            .a{
                                                fill: $yellow;
                                            }
                                        }
                                    }
                                } 
                            }
                           span{
                               display: inline-block;
                               @include font(14);
                               color: $black;
                               &.icon{
                                   margin-right: 10px;
                               }
                           }
                        }
                       }
                   }
                   button{
                       outline:none;
                       cursor:pointer;
                       &.postBtn{
                           @include font(14);
                           color:$white;
                           background-color: $lightBlue;
                           @include radius(4px);
                           padding: 15px 20px;
                           border: none;
                       }
                   }
               }
                }
            }
        }
        &.show{
            display: flex !important;
             align-items: center;
             justify-content: center;
         }
    }
    
} 

/************************************************************/

@media screen and (min-width:1700px) {
    .trendingProfileSection{
        > .container{
            max-width: 1600px;
        }
    }
}
@media screen and (max-width:991px) {
    .trendingProfileSection{
        > .container{
            max-width: 100%;
            .flexBox{
                .leftDataBox{
                    width: 230px;
                    .AthleteInfoBox{
                        .AthleteInfo{
                            padding: 15px 10px;
                        }
                        .activityCollegeBox{
                            padding: 15px 10px;
                        }
                    }
                    .profileDataBox{
                        padding: 15px 10px;
                    }
                    .teamOfferBox{
                        padding: 15px 10px;
                    }
                    .targetCollege{
                        .head{
                            padding: 15px 10px 0 10px;
                        }
                        ul{
                            li{
                                padding: 10px;
                            }
                        }
                        .manageLink{
                            padding: 10px;
                        }
                    }
                }
                .dataBox{
                    width: calc(100% - 240px);
                    margin-right: 0;
                    margin-bottom: 15px;
                    .tabsBox{
                        .tabFlex{
                            .tab{
                                margin-right: 8px;
                                a{
                                    padding: 10px 5px;
                                    @include font(12);
                                }
                            }
                        }
                        .followingDataBox{
                            padding:10px;
                            .item{
                                padding:10px 0;
                                .imgDataFlexBox{
                                    .imgBox{
                                        flex: 0 0 40px;
                                        width: 40px;
                                        height: 40px;
                                        img {
                                            width: 40px!important;
                                            height: 40px!important;
                                        }
                                        .initialLetter {
                                            @include font(14);
                                            width: 40px;
                                            height: 40px;
                                        }
                                        span {
                                            &.icon {
                                                svg {
                                                    width: 15px!important;;
                                                }
                                            }
                                        }
                                    }
                                    .dataFlexBox{
                                        width: calc(100% - 50px);
                                        flex-wrap:wrap;
                                        div{
                                            width:100%;
                                            padding-right: 0;
                                            h5{
                                                @include font(13);
                                            }
                                            span{
                                                @include font(11);
                                            }
                                        }
                                    }
                                }
                              
                            }
                        }
                    }
                }
                .rightDataBox{
                    width: calc(100% - 240px);
                    margin-left: auto;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .trendingProfileSection{
        > .container{
            max-width: 540px;
            .flexBox{
                .leftDataBox{
                    order: 1;
                    width: 100%;
                    margin: 0 auto 0 auto;
                    .AthleteInfoBox {
                        .AthleteInfo {
                          background-size: cover;
                          .profileBox {
                            margin-top: 0;
                            .playerName {
                              margin-bottom: 5px;
                            }
                            ul {
                              margin-bottom:0;
                              li {
                                margin-bottom: 5px;
                                &:last-child {
                                  display: none;
                                  margin-bottom: 0;
                                }
                              }
                            }
                            .imgBoxBlock {
                              width:80px;
                              height: 80px;
                              div {
                                &.imgBox {
                                  width:80px;
                                  height: 80px;
                                  .image {
                                    width: 80px!important;;
                                    height: 80px;
                                  }
                                }
                              }
                            }
                          }
                          .flexBox {
                            margin-top:10px;
                            padding-top:12px;
                            display: none;
                          }
                        }
                        .activityCollegeBox {
                          display: none;
                        }
                        &.show {
                          .AthleteInfo {
                            .profileBox {
                              ul {
                                li {
                                  &:last-child {
                                    display: block;
                                  }
                                }
                              }
                            }
                            .flexBox {
                              display: block;
                            }
                          }
                          .activityCollegeBox {
                            display: block;
                          }
                        }
                    }
                    .profileDataBox, .teamOfferBox, .targetCollege {
                        display: none;
                        &.show {
                          display: block;
                        }
                    }
                    .showMoreless{
                        display:block;
                    }
                    .targetCollege {
                        &.sticky-box {
                          position: static;
                        }
                    }
                }
                .dataBox{
                    order: 2;
                    width: 100%;
                    margin: 0 auto 15px;
                    .tabsBox{
                        .heading {
                            @include font(16);
                        }
                        .tabFlex{
                            .tab{
                                a{
                                    padding: 10px;
                                    @include font(14);
                                }
                            }
                        }
                        .followingDataBox{
                            padding:10px;
                            height: 470px!important;
                        }
                    }
                }
                .rightDataBox {
                    padding-left: 0;
                    order: 3;
                    width: 100%;
                    margin: 0 auto 10px auto;
                    .importNotice {
                      &.sticky-box {
                        position: static;
                      }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:575px){
    .trendingProfileSection{
        > .container{
            .flexBox{
                .leftDataBox{
                    .AthleteInfoBox{
                        .AthleteInfo{
                            padding: 15px 15px;
                            background-size: calc(100% + 5px);
                        }
                        .activityCollegeBox{
                            padding: 15px 15px;
                        }
                    }
                    .profileDataBox{
                        padding: 15px 15px;
                    }
                    .teamOfferBox{
                        padding: 15px 15px;
                    }
                }
            }
        }
    }
    

}


@media  screen and (max-width:479px) {
    .trendingProfileSection{
        > .container{
            .flexBox{
                .dataBox{
                    .tabsBox{
                       .tabFlex{
                           .tab{
                               a{
                                   padding: 10px 5px;
                                   @include font(14);
                               }
                           }
                       }
                    }
                }
            }
        }
    }
}