@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/reset";

.activity-data-sec {
    margin-top: 60px;
    background:$bgLightGray;
    padding: 25px 0;
    .activity-box-wrap {
        background: $white;
        padding: 20px;
        border:1px solid $gray;
        @include radius(5px);
        //This class only added to show coming soon , Later it can be removed once the coming soon text removed.
        .comingsoon-title {
            @include font(20);
            font-weight: 700;
            color: $blackColor;
            text-align: center;
        }
        .activity-main-title {
            @include font(20);
            font-weight: 700;
            color: $blackColor;
            padding-bottom: 20px;
            margin-bottom:20px;
            border-bottom: 1px solid $gray;
        }
        .filter-wrap {
            @include flex();
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .heading {
                @include font(18);
                font-weight: 500;
                color: $black;
                line-height: 1.4;
                margin-bottom: 0;
            }
            .sortFilter {
                select {
                    background-color:$white;
                    padding:7px 25px 7px 8px;
                    @include font(14);
                    color:$black;
                    appearance: none;
                    background-image: url(../../../../../../assets/img/arrowdown.png);
                    background-repeat: no-repeat;
                    background-size: 16px;
                    background-position: 95% center;
                    border:1px solid $gray;
                    @include radius(4px);
                    min-width:150px; 
                    line-height: 20px;
                    outline:none;
                }
            }
        }
        .heading {
            @include font(18);
            font-weight: 500;
            color: $black;
            line-height: 1.4;
            margin-bottom: 20px;
        }
        .overview-box-wrap {
            @include flex();
            justify-content: space-between;
            align-items: flex-start;
            .overview-item {
                background: $bgLightGray;
                padding: 25px 20px;
                width: calc(50% - 15px);
                margin-bottom: 30px;
                min-height: 260px;
                .flexbox {
                    @include flex();
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    border-bottom: 1px solid rgba($gray, .5);
                    .info-box {
                        @include flex();
                        align-items: center;
                        .icon-box {
                            @include flex();
                            justify-content: center;
                            align-items: center;
                            width: 45px;
                            height: 45px;
                            margin-right: 15px;
                            border: 1px solid $black;
                            @include radius(50px);
                            svg {
                                height: 25px;
                            }
                        }
                        .detail-box {
                            @include font(15);
                            color: $black;
                            h4 {
                                @include font(16);
                                font-weight:500;
                                margin-bottom: 3px;
                            }
                            p {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .switch-box {
                        .switch-button {
                            @include flex();
                            align-items: center;
                            .checkbox {
                              background-image: none;
                              background: rgba($black, .1);
                              width: 45px;
                              height: 15px;
                              border-width: 0;
                              @include radius(50px);
                              border: none;
                              margin-left: 0;
                              appearance: none;
                              position: relative;
                              @include transition();
                              cursor: pointer;
                              margin-top: 0;
                              &:after {
                                content: "";
                                background: $black;
                                position: absolute;
                                width: 24px;
                                height: 24px;
                                left: 0;
                                top: -5px;
                                border: none;
                                z-index: 2;
                                border-radius: 50%;
                                @include transition();
                              }
                            }
                            input:checked[type="checkbox"] {
                                background: #c7ecfa;
                                &:after {
                                    left: auto !important;
                                    right: 0 !important;
                                    background: $lightBlue;
                                }
                            }
                            label {
                                @include font(18);
                                padding-left: 10px;
                                cursor: pointer
                            }
                        }  
                    }
                }
                .overview-data {
                    .flexbox-row {
                        @include flex();
                        justify-content: space-between;
                        align-items: center;
                        @include font(15);
                        color: $black;
                        line-height: 1.4;
                        margin-bottom: 15px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        li {
                            width:30%;
                            &:first-child {
                                padding-right: 15px;
                            }
                            &.progresbar {
                                width:60%; 
                                span {
                                    display: inline-block;
                                    vertical-align: middle;
                                    background: $black;
                                    min-width: 10px;
                                    height: 6px;
                                    @include radius(20px);
                                }
                            }
                            &:last-child {
                                width:10%; 
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
        .map-box-sec {
            padding-bottom: 30px;
            .flexbox {
                @include flex();
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
                .nav-tabs {
                    @include flex();
                    align-items: center;
                    border: none;
                    li {
                        margin-right: 20px;
                        a {
                            padding: 0;
                            @include font(16);
                            font-weight: 500;
                            color: $black;
                            padding-bottom: 10px;
                            border: none;
                            position: relative;
                            &.active {
                                color: $baseColor;
                                &::after {
                                    content: "";
                                    background: $baseColor;
                                    position: absolute;
                                    width: 100%;
                                    height: 2px;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    z-index: 1;
                                }
                            }
                        }
                       
                    }
                }
                .btn-wrap {
                    .btn {
                        padding: 6px 15px;
                        @include font(15);
                    }
                }
            }
            .map-box-info {
                @include flex();
                align-items: center;
                background: #3a3b3b;
                .map-img {
                    width: calc(100% - 400px);
                    img {
                        height: 100%;
                        width: 100%;
                        vertical-align: middle;
                    }
                }
                .info-box {
                    background: #3a3b3b;
                    padding: 20px;
                    width: 400px;
                    h5 {
                        @include font(16);
                        color: $lightBlue;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }
                    .items {
                        margin-bottom: 25px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .flexbox-row {
                        @include flex();
                        justify-content: space-between;
                        align-items: center;
                        @include font(15);
                        color: #b7b7b7;
                        margin-bottom: 15px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        li {
                            width:30%;
                            &:first-child {
                                padding-right: 15px;
                            }
                            &.progresbar {
                                width:60%; 
                                span {
                                    display: inline-block;
                                    vertical-align: middle;
                                    background: $gray;
                                    min-width: 1px;
                                    height: 6px;
                                    @include radius(20px);
                                }
                            }
                            &:last-child {
                                width:10%; 
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
        .activity-table-box {
            .heading {
                @include font(18);
                font-weight: 500;
                color: $black;
                line-height: 1.4;
                margin-bottom: 20px;
            }
            .table {
                border: 1px solid #dee2e6;
                tr {
                    &:first-child {
                        td {
                            border-bottom: 1px solid #dee2e6;
                        }
                    }
                    td {
                        @include font(15);
                        color: $black;
                        line-height: 1.4;
                        border-top: none;
                        padding: 18px 14px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:1199px) { 
    .activity-data-sec {
        .activity-box-wrap {
            padding: 15px;
            .overview-box-wrap {
                .overview-item {
                    .overview-data {
                        .flexbox-row {
                            li {
                                width: 40%;
                                &.progresbar {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:991px) {
    .activity-data-sec {
        .activity-box-wrap {
            .filter-wrap {
                .heading {
                    @include font(16);
                }
            }
            .heading {
                @include font(16);
            }
            .map-box-sec {
                .map-box-info {
                    .map-img {
                        width: calc(100% - 300px);
                    }
                    .info-box {
                        width: 300px;
                        .flexbox-row {
                            li {
                                width: 40%;
                                &.progresbar {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
            .activity-table-box {
                .heading {
                    @include font(16);
                }
            }
        }
    }
}
@media screen and (max-width:767px) { 
    .activity-data-sec {
        .activity-box-wrap {
            .overview-box-wrap {
                display: block;
                .overview-item {
                    width: 100%;
                }
            }
            .map-box-sec {
                .map-box-info {
                    display: block;
                    .map-img {
                        width: 100%;
                    }
                    .info-box {
                        width: 100%;
                    }
                }
            }
            .activity-table-box {
                overflow-x: scroll;
                &::-webkit-scrollbar {
                    border-radius: 10px;
                    width: 8px;
                    height: 8px;
                    background: rgba($gray, 0.3%);
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background: rgba($gray, 0.8%);
                    cursor: pointer;
                }
                .table {
                    width: 860px;
                }
            }
        }
    }
}
@media screen and (max-width:639px) {
    .activity-data-sec {
        .activity-box-wrap {
            .activity-main-title {
                @include font(18);
                padding-bottom: 15px;
                margin-bottom: 20px;
            }
            .overview-box-wrap {
                .overview-item {
                    padding: 20px 15px;
                    min-height: 1;
                }
            }
        }
    }
}