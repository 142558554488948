@import  "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";

.popUpSection{
    @include flex();
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    @include shadow(0, 3px, 20px, #00000087);
    .popUpBlock{
        max-width: 540px;
        max-height: 237px;
        width: 100%;
        background: $white;
        padding: 40px 15px;
        position: relative;
        @include radius(5px);
        @include shadow(0, 3px, 20px, #00000087);
        // margin: 15px 15px;
        // max-height: calc(100% - 30px);
        // width: calc(100% - 30px);
        .closeButton{
            position: absolute;
            right: 15px;
            top: 11px;
            button{
                background: transparent;
                border:none;
                outline: none;
            }
        }
        .heading{
            @include font(30);
            color: $blackColor;
            text-align: center;
            margin: 0;
        }
        .icon2{
            text-align: center;
        }
        .circle{
            border: 0.1875em solid #0F1C3F;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 3.5em;
            width: 3.5em;
            margin: 0 auto;
        }
        .profileBtn{
            text-align: center;
            margin-bottom: 32px;
            margin-top: 15px;
            button{
                background: $green;
                padding: 15px 40px;
                @include font(14);
                color: $white;
                text-transform: none;
                outline: none;
                border: none;
                @include radius(4px);
                cursor: pointer;
            }
        }
        p {
            &.info{
                max-width: 440px;
                @include font(14);
                line-height: 22px;
                text-align: center;
                margin: 15px auto 20px auto;
            }
            &.skip{
                text-align: center;
                margin: 0 0 30px 0;
                a{
                    display: inline-block;
                    text-decoration: underline;
                    @include font(14);
                    color: $green;
                }
            }
            // .btns{
            //     justify-content: center;
            //     @include flex();
            //     border-top:1px solid $border;
            //     margin:1em;
            //     padding:1.5em 0;
            //     button{
            //         margin: 0 .5em
            //     }
            // }
            .btns{
                flex-wrap: wrap;
                .btn{
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 1em;
                    }
                }
            }
        }
        .btns {
            @include flex();
            justify-content: center;
            align-items: center;
            .btn {
                margin: 0 8px!important;
            }
        }
    }
}
@media  screen and (max-width:540px) {
    .popUpAthleteSection{
        .popUpAthleteBlock{
            .heading{
                @include font(20);
            }
        }
    } 
}