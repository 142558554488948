@import "../../../../../assets/css/mixin";



/*****************parentSkipModal css*********/


.parentSkipModal{
    .MuiDialog-paperWidthSm{
        max-width: 730px;
        margin: 15px 15px;
        max-height: calc(100% - 30px);
        width: 100%;
    }
}