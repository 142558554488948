@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/**************************************************************************************/


.MuiButtonBase-root{
  font-family: 'Poppins', sans-serif !important;
}

.MuiInputBase-input{
  font-family: 'Poppins', sans-serif !important;
}

.MuiTypography-root{
  font-family: 'Poppins', sans-serif !important;
}

.MuiFormLabel-root{
  font-family: 'Poppins', sans-serif !important;
}