@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.viewCommitment {
  .flex {
    @include flex();
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width:100%;
  }
  .filterBtn{
    width: 100%;
    margin-bottom: 1em;
    display: none;
    button{
      border:1px solid $border;
      display: block;
      background: $white;
      width: 100%;
      @include flex();
      justify-content: space-between;
      align-items: center;
      @include font(16);
      padding:.75em 1em;
      @include radius(4px);
      font-weight: 700;
      &:focus{
        outline: 0px;
      }
    }
  }
  .sidebar{
    width: 300px;
    margin-right: 20px;
    padding:20px;
    background: $white;
    border:1px solid $border;
    @include radius(4px);
    h3{
      display: none;
    }
    .sideClearWrap{
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      .btnWrap{
        button{
        width: 100px;
        height: 40px;
        margin-left: 5px;
        color: $baseColor;
        padding: 10px;
        text-decoration: underline;
        &:hover{
          color: $baseColor;
          box-shadow: none;
        }
      }
      }
    }
    li{
      &:not(:last-of-type){
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $border;
      }
      label{
        display: block;
        @include font(14);
        margin-bottom: 10px;
      }
      input[type=text],input[type=search]{
        background: $white url(../img/searchDark.svg) 95% center no-repeat;
        border:1px solid $border;
        width: 100%;
        height: 40px;
        padding:0 40px 0 10px;
        @include radius(4px);
        @include font(14);
      }
      select{
        border:1px solid $border;
        width: 100%;
        height: 40px;
        padding:0 40px 0 10px;
        @include font(14);
        @include radius(4px);
        -webkit-appearance: none;
        appearance: none;
        color:$gray;
        @include transition();
        background: $white url('..//img/dropdown.svg') 96% center no-repeat;
        &:focus{
          color:$black;
          @include transition();
        }
      }
      .MuiInputLabel-formControl {
        @include font(14);
        color:$black;
        margin-top: -7px;
        &.MuiFormLabel-filled {
          margin-top: 0;
        }
        &.Mui-focused {
          margin-top: 0;
        }
      }
      .MuiOutlinedInput-input {
        padding-top: 11px;
        padding-bottom: 10px;
        @include font(14);
        color:$black;
      }
      .MuiOutlinedInput-root {
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: $gray;
          }
        }
      }
    }
  }
  .commitments{
    width: auto;
    flex-grow: 1;
    max-width: calc(100% - 320px);
    background: $white;
    padding:20px;
    border:1px solid $border;
    @include radius(4px);
    .mainTitle{
      @include flex();
      align-items: center;
      justify-content: space-between;
      padding:.25em 0 1.25em;
      h1{
        @include font(18);
        font-weight: 600;
      }
      .count{
        @include font(14);
        font-weight: 300;
      }
    }
    .data{
      @include flex();
      justify-content: space-between;
      padding:1.65em 0;
      border-top: 1px solid $border;
      .flex{
        width: 50%;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        .pic{
          padding-right: 20px;
          a {
            display: block;
            text-decoration: none;
          }
          img{
            width: 80px;
            height: 80px;
            @include radius(50%);
            object-fit: cover;
            object-position: center;
          }
          .initialLetter{
            @include flex();
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            @include font(18);
            color:$black;
            @include radius(50px);
            text-transform: uppercase;
          }
        }
        &:not(:first-of-type){
          .pic{
            img{
              width: 50px;
              height: 50px;
              border-radius: none;
            }
          }
          .title{
            font-weight: normal;
            @include font(15);
          }
        }
        .col{
          flex-grow: 1;
          padding:0px;
          line-height: 1.1;
          &:not(:last-of-type){
            flex-grow: 0;
            width: auto;
          }
        }
        .title{
          @include font(16);
          font-weight: 700;
          margin-bottom: .55em;
          a{
            color:$baseColor;
            font-weight: 700;
            text-decoration: none;
            cursor: pointer;
            &:hover{
              color:$baseColor;
            }
          }
        }
        .gen{
          margin-bottom: .5em;
          @include font(14);
        }
        .location{
          margin-bottom: .5em;
          @include font(14);
        }
        .note{
          @include font(14);
          color:$lightBlue;
          margin-top: 1em;
          line-height: 1.4;
        }
      }
    }

    .pagination{
      border-top:1px solid $border;
      padding-top:1.25em;
      .flex {
        align-items: center;
        flex-wrap: nowrap;
      }
      .item{
        align-items: center;
        @include flex();
        justify-content:space-between;
        label{
          @include font(14);
        }
        button{
          font-weight: 600;
          background: none;
          border:0px;
          @include transition();
          @include radius(3px);
          padding:0 6px;
          height: 30px;
          &:hover{
            background: rgba($border, .3%);
          }
        }
      }
      input[type=text]{
        max-width: 40px;
        @include font(14);
        text-align: center;
        height: 30px;
        padding:0 6px;
        margin-right: 10px;
        border:1px solid $border;
        @include transition();
        &:focus{
          border-color:$black;
        }
      }
      .paging{
        @include flex();
        border:1px solid $border;
        @include radius(4px);
        overflow: hidden;
        a, span {
          display: block;
          @include font(14);
          padding:10px;
          @include flex();
          align-items: center;
          height: 30px;
          min-width: 36px;
          justify-content: center;
          color:$black;
          &.active{
            background: $lightBlue;
            color:$white;
            font-weight: 700;
            margin:-1px;
            height: 32px;
            min-width: 38px;
          }
          &:hover{
            background: rgba($lightBlue,.1%);
            text-decoration: none;
          }
        }
      }
    }
  }

  .commitmentPagination {
    &.searchDataSection{
      margin-top: 0;
      padding: 0;
      .searchDataFlexBox{
        width: 100%;
        max-width: 100%;
        display: block;
        .searchDataBox{
          margin: 0;
          width: 100%;
        }
      .dataSection{
        padding: 0;
        width: 100%;
        border: none;
      }
      }

    }
  }
}


@media screen and (max-width: 992px) {
  .viewCommitment {
    .filterBtn{
      @include flex();
    }
    .sidebar{
      position: fixed;
      opacity: 0;
      visibility: hidden;
      overflow-y: scroll;
      background: $white;
      z-index: 1050;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      @include radius(0px);
      padding: 30px 20px !important;
      &.show{
        opacity: 1;
        visibility: visible;
      }
      h3{
        display: block;
        @include font(18);
        max-width: 400px;
        margin: 0 auto 1em;
      }
      .closebtn-wrap {
        display:block;
        text-align: right;
        margin-bottom: 5px;
        .closebtn {
          display: inline-block;
          vertical-align: middle;
          @include font(24);
          line-height: 1;
          color:rgba($black, .5);
          cursor: pointer;
        }
      }
      .btnWrap{
        @include flex();
        justify-content: space-between;
        max-width: 400px;
        margin: 0 auto;
        button{
          width: calc(50% - 10px);
          &.btn-secondary{
            background: $lightBlue;
          }
          &:focus{
            outline: 0px !important;
            box-shadow: none !important;
          }
        }
      }
      ul{
        max-width: 400px;
        margin: 0 auto 1em;
      }
      li{
        &:not(:last-of-type){
          border:0px;
          padding-bottom: 0px;
          margin-bottom: 1em;
        }
        select, input[type=text], input[type=search]{
          height: 45px;
          padding:0 40px 0 1em;
        }
      }
    }
    .commitments{
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .viewCommitment {
    .commitments {
      padding-left: 15px;
      padding-right: 15px;
      .data {
        flex-wrap: wrap;
        .flex{
          width: 100%;
          align-items: flex-start;
          &:last-of-type{
            margin-top: 1em;
          }
          .col{
            .pic{
              width: 80px;
              img{
                width: 60px;
                height: 60px;
              }
              .initialLetter{
                width: 60px;
                height: 60px;
                @include font(16);
              }
            }
          }
        }
      }
      .pagination {
        .flex{
          flex-wrap: wrap;
          justify-content: space-between;
          .item{
            &.perPage{
              order:1;
            }
            &.goto{
              order:2;
            }
            &.pages{
              order:3;
              width: 100%;
              .paging{
                margin:0 auto 1em;
                width: 100%;
                height: 36px;
                li{
                  width: 100%;
                  a{
                    height: 36px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}