@import  "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";

.popUpAthleteSection{
    .popUpAthleteBlock{
        max-width: 730px;
        width: 100%;
        background: $white;
        @include radius(5px);
        @include shadow(0, 3px, 20px, #00000087);
        padding: 15px;
        .closeBtn{
            text-align: right;
            margin-bottom: 20px;
            button{
                border:none;
                outline: none;
                background: transparent;
                svg{
                    fill: $black;
                    .a{
                        fill: $black;  
                    }
                }
            }
        }
        .heading{
            font-size: 20px;
            color: $blackColor;
            margin: 0 auto 30px auto;
            text-align: center;
            max-width: 480px;
        }
        .profileBtn{
            text-align: center;
            margin-bottom: 15px;
            button{
                @include radius(4px);
                color: $white;
                background: $green;
                font-size: 14px;
                text-transform: none;
                cursor: pointer;
                outline: none;
                border: none;
                padding: 15px 20px;
            }
        }
        p{
            &.info{
                max-width: 440px;
                text-align: center;
                margin: 0 auto 30px auto;
                font-size: 14px;
                line-height: 22px;
            }
            &.skip{
                text-align: center;
                margin: 0 0 30px 0;
                a{
                    text-decoration: underline;
                    color: $baseColor;
                    font-size: 14px;
                    display: inline-block;
                }
            }
        }
    }
}