@import "../../../../../../assets/css/_mixin.scss";
@import "../../../../../../assets/css/_reset.scss";
@import "../../../../../../assets/css/_variables.scss";

header {
  &.claimProfileHead {
    &.dashboard {
      background-color: #333;
      padding: 0 15px;
      height: 60px;
      @include flex();
      align-items: center;
      position: sticky;
      left: 0;
      top: 0;
      z-index: 999;
      .logoPart {
        a {
          cursor: pointer;
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
      &.searchBar {
        @include flex();
        align-items: center;
        padding: 0 10px 0 20px;
        @media screen and (max-width: 1024px){
            padding: 0 10px;
        }
        .searchBox {
          @include flex();
          background: $white;
          padding: 0 10px;
          max-width: 195px;
          min-width: 195px;
          align-items: center;
          @include radius(30px);
          .icon {
            a {
              display: inline-flex;
              svg {
                .a {
                  opacity: 0.3;
                }
                .b {
                  fill: none;
                  stroke: $blackColor;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1.5px;
                }
              }
            }
          }
          input {
            padding: 6px 10px;
            width: calc(100% - 0px);
            @include radius(10px);
            border: none;
            outline: none;
            @include font(14);
            color: $black;
          }
        }
      }
    }
  }
}
header {
  &.claimProfileHead {
    &.dashboard {
      background: #040404;
      @include flex();
      display: block;
      padding: 0 30px;
      position: fixed;
      height: 60px;
      left: 0;
      width: 100%;
      top: 0;
      z-index: 999;
      // align-items: center;
      div {
        &.flex {
          height: 100%;
        }
        &.logoPart {
          @include flex();
          align-items: center;
          a {
            display: inline-flex;
            text-decoration: none;
            cursor: pointer;
            img {
              max-width: 100%;
              height: auto;
              &.mobile {
                display: none;
              }
            }
          }
        }
        &.searchBar {
          @include flex();
          align-items: center;
          padding: 0 10px 0 20px;
          @media screen and (max-width: 1024px){
            padding: 0 10px;
          }
          input {
            padding: 9px 10px 9px 32px;
            background: rgba($white, 0.9%)
              url("../../../../../layouts/assets/Img/searchDark.svg") 12px center
              no-repeat;
            @include font(14);
            color: $black;
            height: 34px;
            width: 200px;
            @include transition();
            @include radius(30px);
            border: none;
            outline: none;
            border: 1px solid transparent;
            &:focus {
              background: rgba($white, 1%)
                url("../../../../../layouts/assets/Img/searchDark.svg") 12px
                center no-repeat;
              border-color: $lightBlue;
            }
          }
          .viewProfileLink {
            background:$lightBlue;
            @include flex();
            align-items: center;
            text-decoration: none;
            padding: 10px 20px;
            @include font(16);
            color: $white;
            line-height: 16px;
            margin-left: 15px;
            @include radius(50px);
            text-align: center;
            white-space: nowrap;
            @media screen and (max-width: 1024px){
                margin-left: 10px;
            }
          }
        }
      }
      nav {
        @include flex();
        margin-left: auto;
        > ul {
          @include flex();
          align-items: center;
          @include flex();
          li {
            list-style: none;
            position: relative;
            // &:after {
            //   content: "";
            //   position: absolute;
            //   right: 0;
            //   top: 21px;
            //   height: 18px;
            //   width: 2px;
            //   background: #505050;
            // }
            &:last-child {
              a {
                padding-left: 0;
                padding-right: 0;
              }
            }
            &:last-child,
            &:nth-last-child(2),
            &:nth-last-child(3) {
              &:after {
                display: none;
              }
            }
            &:hover {
              &:after {
                display: none;
              }
            }
            &.dropdown {
              .dropdown-menu {
                padding: 0;
                width: auto;
                top: 90% !important;
                margin-right: 0;
                //display: block !important;
                left: inherit !important;
                right: 0 !important;
                //height: 90px!important;
                @include transition();
                @include scaleY(0);
                overflow: hidden;
                height: auto;
                a {
                  padding: 10px 15px;
                  white-space: nowrap;
                  color: $black;
                  line-height: 24px;
                  font-weight: 400;
                  &:hover {
                    background: rgba($text, 0.05%);
                    color: $baseColor;
                  }
                }
              }
            }
            &.dropdown {
              &:hover {
                .dropdown-menu {
                  display: block;
                  @include scaleY(1);
                }
              }
            }
            a {
              @include flex();
              align-items: center;
              text-decoration: none;
              padding: 10px 12px;
              @include font(16);
              color: $white;
              line-height: 40px;
              white-space: nowrap;
              img {
                display: block;
                height: 40px;
                margin: 0 10px;
              }
              &:hover {
                background: $baseColor;
              }
              &.upgradeLink {
                padding: 0px 1em;
                text-align: center;
                text-transform: uppercase;
                background: $baseColor;
                @include radius(20px);
                margin: 0 10px;
                &:hover {
                  background: $lightBlue;
                  color:$white;
                }
              }
  
              &.callLink {
                padding: 0 1em;
                background: $white;
                text-transform: uppercase;
                text-align: center;
                @include radius(20px);
                margin: 0 10px;
                &:hover {
                  background: $lightBlue;
                }
              }
            }
            &.icon {
              @include flex();
              a {
                position: relative;
                padding: 10px 0.25em;
                height: 60px;
              }
              &:hover {
                .count {
                  background: $white;
                  color: $baseColor;
                }
              }
              &.notifyLink {
                img {
                  height: 24px;
                }
              }
              span {
                background-color: $baseColor;
                display: inline-flex;
                @include font(12);
                color: $white;
                font-weight: bold;
                position: absolute;
                right: 10px;
                top: 10px;
                width: 15px;
                height: 15px;
                align-items: center;
                justify-content: center;
                @include radius(2px);
                @include transition();
              }
              &.notifyLink {
                svg {
                  height: 30px;
                }
              }
            }
            .avtaar {
              width: 40px;
              height: 40px;
              background: $white;
              @include flex();
              align-items: center;
              justify-content: center;
              display: inline-flex;
              @include font(16);
              color: $baseColor;
              font-weight: bold;
              margin: 10px 0px 10px 10px;
              cursor: pointer;
              @include radius(50%);
              @include transition();
              &:hover {
                background: $baseColor;
                color: $white;
              }
            }
            &.viewProfileLink {
              display: none;
              a {
                background: $lightBlue;
                color:$white;
              }
            }
            &.mobileSearchIcon {
              display: none;
              .searchBtn {
                background: transparent;
                padding: 5px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: none;
                outline: none;
                @include radius(0);
                &::before {
                  display: none;
                }
              }
            }
            &.sideBarMenu {
              display: none;
              width: 48px;
              height: 60px;
              position: relative;
              button {
                @include flex();
                align-items: center;
                justify-content: center;
                padding: 12px;
                background: none;
                position: absolute;
                width: 100%;
                height: 100%;
                border: 0;
                cursor: pointer;
                &:hover {
                  background: $baseColor;
                  outline: 0;
                }
                &:focus {
                  outline: 0;
                }
                span {
                  background: $white;
                  position: relative;
                  width: 24px;
                  height: 2px;
                  @include transition();
                  &:before,
                  &:after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: $white;
                    display: block;
                    left: 0;
                    position: absolute;
                    @include transition();
                  }
                  &:before {
                    top: -8px;
                    @include rotate(0deg);
                  }
                  &:after {
                    top: 8px;
                    @include rotate(0deg);
                  }
                }
              }
            }
            &.sign-in-link {
              .upgradeLink  {
                // margin-left: 0;
                margin-right: 0;
              }
            }
          }
        }
        .claimprofile-mobile-menu {
          display: none;
        }
      }
    }
  }
}
@media screen and(max-width:1199px) {
  header {
    &.claimProfileHead {
      &.dashboard {
        padding: 0 1em;
        div {
          &.searchBar {
            .viewProfileLink {
              @include font(13);
            }
          }
        }
        nav {
          > ul {
            li {
              a {
                padding-left: .5em;
                padding-right: .5em;
                @include font(15);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:1023px) {
  #searchModal {
    padding-right: 0 !important;
    bottom: auto !important;
    overflow: inherit!important;
    &.show {
      display: block;
    }
    .modal-dialog {
      max-width: 100%;
      margin: 0;
    }
    .modal-content {
      @include radius(0);
      background: $blackBg;
      .modal-body {
        @include flex();
        padding: 0.517em 1em;
        width: 100%;
        max-width: 100%;
        .close {
          float: none;
          display: block;
          padding: 10px;
          margin-right: 10px;
          svg {
            height: 18px;
            width: auto;
          }
        }
        input[type="search"] {
          border: 0;
          flex-grow: 1;
          padding: 0 1em;
          @include radius(30px);
          background: rgba($white, 0.9%);
          width: 100%;
          max-width: 100%;
          @include transition();
          &:focus {
            background: $white;
          }
        }
        .dropdown-menu {
          background: $white;
          width: 280px;
          padding: 15px 12px;
          box-shadow: 0px 5px 15px #0000003b;
          @include radius(4px);
          position: absolute !important;
          left: 0 !important;
          right: auto !important;
          top: 100% !important;
          min-width: 100%;
          transform: none !important;
          min-height: 1px;
          margin-right: 0;
          margin-top: 0;
          &.dropdownopen {
            display: block;
          }
          .title {
            @include font(15);
            font-weight: 400;
            color: $blackColor;
            padding-bottom: 12px;
          }
          .search-list {
            max-height: 520px;
            overflow-y: scroll;
            padding-right: 12px;
            &::-webkit-scrollbar {
              border-radius: 10px;
              width: 8px;
              background: rgba($gray, 0.3%);
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: rgba($gray, 0.8%);
              cursor: pointer;
            }
            a {
              @include flex();
              align-items: center;
              text-decoration: none;
              cursor: pointer;
            }
            li {
              @include flex();
              align-items: center;
              padding: 15px 10px;
              border-bottom: 1px solid $gray;
              @include radius(5px 5px 0 0);
              &:last-child {
                margin-bottom: 0;
                border-bottom: 0;
              }
              .pic {
                @include flex();
                justify-content: center;
                align-items: center;
                flex: 0 0 55px;
                width: 55px;
                height: 55px;
                @include radius(50px);
                margin-right: 10px;
                border: 1px solid $border;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                }
                span {
                  @include font(18);
                  font-weight: 500;
                  color:$black;
                  text-align: center;
                  text-transform: capitalize;
                }
              }
              .desgination {
                @include font(14);
                font-weight: 300;
                color: $black;
                line-height: 1.4;
                strong {
                  display: block;
                  font-weight: 600;
                  color: $blackColor;
                }
              }
              &:hover {
                background: rgba($lightBlue, 0.2);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:992px) {
  header {
    &.claimProfileHead {
      div {
        &.searchBar {
          display: none!important;
        }
      }
      &.dashboard {
        padding-right: 0;
        nav {
          > ul {
            &:first-of-type {
              position: fixed;
              flex-direction: column;
              justify-content: flex-start;
              @include translateX(-110%);
              visibility: hidden;
              left: 0;
              top: 0;
              right: 48px;
              bottom: 0;
              opacity: 0;
              @include transition();
              li {
                width: calc(100% - 2em);
                border-bottom: 1px dotted rgba($white, 0.2%);
                margin: 0;
                a {
                  display: block;
                  padding: 0.5em 1em;
                  @include radius(4px);
                  margin: 0.5em 0;
                  &.upgradeLink {
                    background: $lightBlue;
                    &:hover {
                      background: $green;
                    }
                  }
                }
                &.viewProfileLink {
                  display: block;
                }
                &.icon {
                  a {
                    width: 100%;
                  }
                }
                &.sign-in-link {
                  display: none;
                }
                &.phone-link {
                  display: none;
                }
                &:after {
                  display: none;
                }
              }
            }
            li {
              &.sideBarMenu {
                display: block;
                margin-left: 10px;
                button {
                  &.btn {
                    padding: 10px 1em;
                    height: 60px;
                  }
                }
              }
              &.mobileSearchIcon {
                &::after {
                  display: none
                }
              }
            }
          }
          .claimprofile-mobile-menu {
            @include flex();
            li {
              img {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .menu-open {
    overflow: hidden;
    header {
      &.dashboard {
        nav {
          > ul {
            &:first-of-type {
              background: #040404;
              @include translateX(0);
              visibility: visible;
              overflow-y: auto;
              opacity: 1;
              z-index: 99;
            }
            li {
              &.sideBarMenu {
                button {
                  background: $baseColor;
                  span {
                    background: none;
                    &:before {
                      top: 0;
                      @include rotate(45deg);
                    }
                    &:after {
                      top: 0;
                      @include rotate(-45deg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:639px) {
  header {
    &.claimProfileHead {
      &.dashboard {
        div {
          &.logoPart {
            a {
              img {
                display: none;
                &.mobile {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}