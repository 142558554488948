@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";

.onBoardTwitterSection {
  min-height: calc(100vh - 120px);
  padding: 20px 15px;
  @include flex();
  justify-content: center;
  margin-top: 0;
  .twitterBlock {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    @include radius(6px);
    .formSection {
      padding: 30px 30px;
      border: 1px solid $gray;
      width: 100%;
      @include radius(6px);
      .heading {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 20px 0;
        font-weight: 600;
        line-height: 22px;
        color: $black;
      }

      .recruitProcessBox {
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            margin: 0 0 15px 0;
            span {
              display: inline-flex;
              align-items: center;
              position: relative;
              input[type="radio"] {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
                cursor: pointer;
                &:checked + label {
                  &::before {
                    border-color: $lightBlue;
                  }
                  &::after {
                    width: 12px;
                    height: 12px;
                    background: url(../../assets/img/checkBlue.png) no-repeat;
                    background-size: cover;
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: 3px;
                    top: 4px;
                  }
                }
              }

              label {
                position: relative;
                font-size: 14px;
                color: $black;
                font-weight: normal;
                padding-left: 30px;
                cursor: pointer;
                line-height: 18px;
                &::before {
                  width: 18px;
                  height: 18px;
                  border: 1px solid $black;
                  content: "";
                  display: inline-block;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
              }
            }
          }
          &:last-child {
            margin: 0;
          }
        }
      }
      .fieldBox {
        margin-top: 30px;
        label {
          font-size: 16px;
          margin: 0 0 10px 0;
          display: block;
          color: $black;
          font-weight: 600;
        }
        .twitter-itemfield {
          .MuiFormControl-root {
            border: 1px solid $gray;
            @include radius(4px);
          }
        }
        input {
          outline: none;
          // border: 1px solid $gray;
          @include radius(4px);
          padding: 10px 10px;
          width: 100%;
          color: $black;
          font-size: 14px;
          &::placeholder {
            color: $black;
          }
        }
        &.hide {
          display: none;
        }
        > div {
          .MuiFormControl-fullWidth {
            margin-bottom: 20px;
           fieldset{
             border-color: rgba(0, 0, 0, 0.23);
             border-width: 1px;
           }
          }
          &:last-child {
            .MuiFormControl-fullWidth {
              margin-bottom: 0;
              fieldset{
                border-color: rgba(0, 0, 0, 0.23);
                border-width: 1px;
              }
            }
          }
        }
      }
      .sportGoals {
        margin-top: 30px;
        h4 {
          font-size: 16px;
          font-weight: 600;
          margin: 0 0 20px 0;
          font-weight: 600;
          line-height: 22px;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            margin: 0 0 15px 0;
            span {
              display: inline-flex;
              align-items: center;
              position: relative;
              input[type="radio"] {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
                cursor: pointer;
                &:checked + label {
                  &::before {
                    border-color: $lightBlue;
                  }
                  &::after {
                    width: 12px;
                    height: 12px;
                    background: url(../../assets/img/checkBlue.png) no-repeat;
                    background-size: cover;
                    content: "";
                    display: inline-block;
                    position: absolute;
                    left: 3px;
                    top: 4px;
                  }
                }
              }

              label {
                position: relative;
                font-size: 14px;
                color: $black;
                font-weight: normal;
                padding-left: 30px;
                cursor: pointer;
                line-height: 18px;
                &::before {
                  width: 18px;
                  height: 18px;
                  border: 1px solid $black;
                  content: "";
                  display: inline-block;
                  position: absolute;
                  left: 0;
                  top: 0;
                }
              }
            }
          }
          &:last-child {
            margin:0;
          }
        }
        &.hide {
          display: none;
        }
      }
    }
    .skip {
      text-align: center;
      margin-top: 20px;
      .sikpBtnBox{
        display: inline-block;
      }
    }
    .skip a {
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      color: #c0321f;
    }
    .continueBtnBox {
      text-align: center;
      margin-top: 35px;
      a {
        outline: none;
        box-shadow: none;
        cursor: pointer;
        background-color: $green;
        color: $white;
        padding: 10px 15px;
        @include radius(4px);
        border: none;
        font-size: 16px;
        min-width: 100px;
        min-height: 45px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .onBoardTwitterSection {
    .twitterBlock {
      .formSection {
        padding: 20px 20px;
      }
      .continueBtnBox {
        a {
          width: 100%;
        }
      }
    }
  }
}
