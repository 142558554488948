@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/reset";

.editProfile {
    .flex {
        @include flex();
        justify-content: space-between;
        flex-wrap: wrap;
        background: $white; 
        width:100%;
        overflow: hidden;
        position: relative;
        justify-content: flex-end;
    }
    .subHeader{
        width:100%;
        background: $blackBg;
        @include flex();
        justify-content: space-between;
        align-items: center;
        padding:.7em 1em;
        margin-bottom: 1px;
        min-height: 60px;
        .menu{
            button{
                background: none;
                border:0;
                color:$white;
                @include flex();
                align-items: center;
                @include font(18);
                font-weight: 500;
                img{
                    display: block;
                    margin-right:.8em;
                }
                &:focus{
                    outline: none;
                }
            }
            span {
                background-image:url(../img/menu-back-icon.svg);
                background-repeat: no-repeat;
                background-position: center center;
                height: 30px;
                width: 32px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 1em;
                position: relative;
            }
        }
        .save{
            button{
                @include flex();
                align-items: center;
                @include font(16);
                font-weight: 500;
                background: $white;
                border:0;
                @include radius(3px);
                padding:.6758em 1em;
                @include transition();
                svg{
                    margin-right: 10px;
                    @include translateY(-1px);
                    path{
                        @include transition();
                    }
                    rect{
                        @include transition();
                    }
                }
                &:hover{
                    background: $lightBlue;
                    color:$white;
                    svg{
                        path{
                            fill:$white;
                        }
                        rect{
                            fill:$white;
                        }
                    }
                }
                &:focus{
                    outline: none;
                }
            }
        }
    }
    .sidebar{
        background: $white;
        width: 300px;
        overflow: hidden;
        border:1px solid $border;
        border-top:0;
        @include transition(all, .5s, cubic-bezier(.41,.18,.26,.99));
        position: absolute;
        left: 0;
        top: 61px;
        bottom: 0;
        .progressbar{
            background: rgba($green, .2%);
            @include radius(20px);
            height: 24px;
            position: relative;
            overflow: hidden;
            .progress{
                background: $green;
                @include radius(20px);
                @include flex();
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width:65%;
                @include flex();
                align-items: center;
                justify-content: center;
                @include font(14);
                font-weight: 800;
                color: $white;
            }
            &.small{
                height: 10px;
                width: 46px;
            }
        }
        .profileImg{
            background: url('../img/profileBg.png') center no-repeat;
            background-size:cover;
            min-height: 350px;
            padding:2em;
            text-align: center;
            @include flex();
            justify-content: center;
            flex-wrap: wrap;
            .profile{
                position: relative;
            }
            .pic{
                position: relative;
                display: inline-block;
                width: 140px;
                height: 140px;
                @include flex();
                align-items: center;
                justify-content: center;
                background: $white;
                @include radius(100%);
                border:3px solid rgba($black, .9%);
                @include font(35);
                font-weight: 800;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    &.verified{
                        position: absolute;
                        max-width: 100%;
                        height: auto;
                        width: auto;
                        right: 0;
                        bottom: 0;
                        @include translate(50%, 0);    
                    }
                }
            }
            .browse{
                position: absolute;
                left: 80%;
                top:5px;
                bottom: auto;
                transform: none;
                padding: 12px;
                cursor: pointer;
                label {
                    background: $black;
                    @include font(15);
                    font-weight: bold;
                    line-height: 24px;
                    text-align: center;
                    color: $white;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    right: 0;
                    top: 0;
                    @include radius(100%);
                    z-index: 11;
                    cursor: pointer;
                }
                input[type="file"] {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    z-index: 3;
                    opacity: 0;          
                    cursor: pointer;
                }
            }
            .name{
                width: 100%;
                margin: 1.5em 0 .75em;
                h2{
                    color:$white;
                    @include font(18);
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                h3{
                    color:$white;
                    @include font(16);
                    font-weight: 500;
                    margin-bottom: 5px;
                    + h3 {
                        margin-bottom: 0;
                    }
                }
            }
            .back{
                a{
                    background: $yellow;
                    display: block;
                    @include radius(30px);
                    color:$white;
                    padding:1em 2em;
                    @include transition();
                    img{
                        margin-right: 10px;
                    }
                    &:hover{
                        background: $lightBlue;
                        text-decoration: none;
                        img{
                            animation: animArrowRev infinite .5s;
                        }
                    }
                }
            }
        }
        .profileCompletion{
            padding:1.25em 1em;
            border-bottom: 1px solid $border;
            small{
                display: block;
                color:#9D9D9D;
                @include font(13);
            }
            .completion{
                @include font(15);
                font-weight: bold;
                margin: .25em 0 1em;
            }
        }
        .checklist{
            padding: .75em 0;
            li{
                @include flex();
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;
                padding:0 1em;
                position: relative;
                @include transition();
                &.active{
                    background: rgba($green, .1%);
                    &::before{
                        content:'';
                        border: 0;
                        height: 60%;
                        width: 4px;
                        @include radius(2px);
                        background: $green;
                        position: absolute;
                        left: 0;
                    }
                }
                &:hover{
                    background: rgba($green, .1%);
                }
            }
            .title{
                flex-grow: 1;
            }
            a{
                color:$black;
                text-decoration: none;
                padding:1em 0;
                @include flex();
                align-items: center;
                span{
                    position: relative;
                    margin-left: 10px;
                    display: inline-block;
                    em{
                        font-style: normal;
                        background:$yellow;
                        width: 16px;
                        height: 16px;
                        position: absolute;
                        @include radius(50%);
                        @include font(12);
                        font-weight: bold;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        top:0;
                        right: 0;
                        @include translate(60%, -40%)
                    }
                }
                &:hover{
                    color: $green;
                }
            }
        }
    }
    .editSection{
        width: calc(100% - 300px);
        min-height: 1260px;
        @include transition(all, .5s, cubic-bezier(.41,.18,.26,.99));
        .subTitle{
            background: rgba($border,.2%);
            position: relative;
            padding:15px 20px;
            margin:20px 20px .5em;
            @include font(18);
            color:$green;
            &:before{
                content: '';
                position: absolute;
                width:4px;
                height: 100%;
                background: $green;
                left: 0;
                top: 0;
                @include radius(4px);
            }
        }
        .flexRow{
            padding:.5em 1em;
            @include flex();
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            &:first-of-type{
                border-bottom: 1px solid $border;
                padding:.75em 1em;
                align-items: center;
            }
            .title{
                @include font(20);
                font-weight: 500;
            }
            .btnWrap{
                @include flex();
                .btn{
                    margin-left: 1em;
                }
            }
            .col{
                flex-grow: 1;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
            label{
                display: block;
                margin-bottom: .5em;
            }
            input[type=text],input[type=date]{
                width: 100%;
                border:1px solid $border;
                @include radius(4px);
                padding:0 1em!important;
                min-height: 46px;
            }
            .MuiOutlinedInput-input {
                @include font(14);
            }
            .MuiOutlinedInput-root {
                &:hover {
                  .MuiOutlinedInput-notchedOutline {
                    border-color: $gray;
                    outline: none;
                    box-shadow: none;
                  }
                }
                .Mui-disabled{
                    color: black;
                }
            }
            .note{
                margin-top: .5em;
                color:rgba($black,.7%);
            }
            &.subscription{
                background: $green;
                margin: 5em 1em 1em;
                @include radius(4px);
            }
            input[type=checkbox]{
                position: absolute;
                opacity: 0;
                + label{
                    position: relative;
                    color:$white;
                    margin: 0;
                    padding:.65em 1em .65em 2em;
                    line-height: 18px;
                    cursor: pointer;
                    display: inline-block;
                    &:before{
                        content: '';
                        border:.1em solid $white;
                        @include radius(2px);
                        position: absolute;
                        left:0;
                        top:50%;
                        width: 18px;
                        height: 18px;
                        @include translateY(-50%);
                    }
                    &:after{
                        content: '';
                        width: 18px;
                        height: 18px;
                        background: url('../img/check.svg') center no-repeat;
                        left:0;
                        top:50%;
                        @include translateY(-50%);
                        position: absolute;
                        @include transition();
                        opacity: 0;
                    }
                }
                &:checked{
                    +label{
                        &:after{
                            opacity: 1;
                        }
                    }
                }
            }
            &.link{
                justify-content: flex-start;
                a{
                    color:$baseColor;
                    display: inline-block;
                    margin-left: 4px;
                }
            }
        }
        .btnRow{
            justify-content: center;
            @include flex();
            border-top:1px solid $border;
            margin:1em;
            padding:1.5em 0;
            button{
                margin: 0 .5em
            }
        }
    }
    .inactive{
        .sidebar{
            @include translateX(-100%);
        }
        .subHeader{
            .menu{
                span {
                    background-image:url(../img/menu-back-icon.svg);
                }
            }
        }
        .editSection{
            width: 100%;
        }
    }
}
.MuiInput-underline {
    &:before, &:after {
        border: none!important;
    }
}
@media screen and (max-width: 1199px) {
    .editProfile {
        .editSection {
            .subTitle{
                padding:1em;
            }
            .flexRow{
                flex-wrap: wrap;
                padding:0 1em;
                &.subscription{
                    margin: 1em;
                }
                .col{
                    flex-basis: auto;
                    padding:0;
                    &:first-of-type{
                        padding-right: 0;
                    }
                    &:last-of-type{
                        padding-left: 0;
                    }
                }
            }
        }
    }
}
 
@media screen and (max-width: 767px) {
    .editProfile {
        .sidebar{
            position: absolute;
            z-index: 2;
            top:61px;
            overflow-y: auto;
        }
        .editSection{
            width: 100%;
            min-height: auto;
        }
    }
}

@media screen and (max-width: 579px) {
    .editProfile {
        .editSection {
            .subTitle{
                margin-top: 0;
            }
            .flexRow{
                &:first-of-type{
                    flex-wrap: wrap;
                    border: 0;
                    padding:2em 1em;
                    .title{
                        text-align: center;
                        width: 100%;
                    }
                    .btnWrap{
                        width: 100%;
                        justify-content: center;
                        button{
                            margin: 1em .5em 0;
                        }
                    }
                }
            }
            .btnRow{
                flex-wrap: wrap;
                .btn{
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 1em;
                    }
                }
            }
        }
    }
}

