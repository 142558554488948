.text-field {
  width: 100%;
  background: white;
}

.text-field .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ddd;
}

.text-field .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ddd;
}

.text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ddd;
}
