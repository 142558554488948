@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.commitmentStory {
    background: $white;
    border:1px solid rgba(#707070, .2%);
    padding:1.5em;
    @include radius(4px);
    .success{
        padding:1.375em 1em;
        background: $green;
        color:$white;
        @include font(16);
        @include radius(4px);
        line-height: 1.5;
    }
    .flex {
        @include flex();
        justify-content: space-between;
        flex-wrap: wrap;
        width:100%;
        .item{
            @include flex();
            align-items: center;
            p{
                line-height: 1.4;
                margin-left: 1em;
                strong {
                    display: block;
                    &.sfbluelink {
                        @include transition();
                        color: #007bff!important;
                        &:hover {
                            color:#0056b3!important;
                        }
                    }
                }
                small{
                    display: block;
                    @include font(14);
                    opacity: .7;
                }
                a {
                    color: #007bff!important;
                    &:hover {
                        color:#0056b3!important;
                    }
                }
            }
            .initialLetter{
                width: 66px;
                height: 66px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #cfcfcf;
                border-radius: 50%;
            }
        }
        .pic{
            img{
                height: 66px;
                width: auto;
            }
        }
        &.commit{
            border-bottom: 1px solid $border;
            padding: 1.5em 0;
            margin-bottom: 1.5em;
        }
        &.share{
            flex-wrap: wrap;
            margin-bottom: 1.25em;
            .item{
                width: 100%;
                .title {
                    @include flex();
                    align-items: center;
                    font-weight: 700;
                    button {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 8px;
                    }
                }
                ul{
                    justify-content: flex-start;
                    margin-left: -10px;
                    a {
                        padding:10px;
                        display: block;
                        opacity: .5;
                        @include radius(50%);
                        img {
                            height: 16px;
                            width: auto;
                        }
                        &:hover{
                            opacity: 1;
                            @include scale(1.3);
                        }
                    }
                }
            }
        }
        &.content{
            flex-direction: column;
            p, li{
                color:$text;
                line-height: 1.7;
                margin-bottom: 1.5em;
                a {
                    color: #007bff !important;
                    &:hover {
                        color:#0056b3 !important;
                    }
                }
            }
            ul{
                margin-bottom: 1.5em;
                li{
                    margin-bottom: .25em;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .commitmentStory {
        .flex {
            &.commit{
                .item{
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 1em;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 579px) {
    .editProfile {
        .editSection {
            .subTitle{
                margin-top: 0;
            }
            .flexRow{
                &:first-of-type {
                    flex-wrap: wrap;
                    border: 0;
                    padding:2em 1em;
                    .title{
                        text-align: center;
                        width: 100%;
                    }
                    .btnWrap{
                        width: 100%;
                        justify-content: center;
                        button{
                            margin: 1em .5em 0;
                        }
                    }
                }
            }
            .btnRow{
                flex-wrap: wrap;
                .btn{
                    width: 100%;
                    &:first-of-type{
                        margin-bottom: 1em;
                    }
                }
            }
        }
    }
}