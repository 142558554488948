@import  "../../assets/css/mixin";
@import "../../assets/css/variables";
@import "../../assets/css/reset";

.onBoardCoachTeamSection{
    min-height: calc(100vh - 120px);
    padding: 50px 15px;
    @include flex();
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    .coachTeamBlock{
        width: 100%;
        max-width: 420px;
        margin: 0 auto;   
        @include radius(6px);
        .formSection{
            padding: 30px;
            width: 100%;
            border: 1px solid $gray;
            @include radius(6px);
            .heading{
                @include flex();
                align-items: center;
                @include font(20);
                font-weight: 600;
                color:$black;
                margin: 0 0 10px 0;
                span{
                    &.checkIcon{
                        display:inline-flex;
                        margin-left:10px;
                        svg{
                            .a{
                                fill:#00b9ff;
                            }
                            .b{
                                fill:#fafafa;
                            }
                        }
                    }
                }
            }
            .coachSUbHead{
                @include font(14);
                font-weight: normal;
                color:$black;
                margin: 0 0 30px 0;
            }
            .checkbox{
                margin-bottom: 20px;
                > span{
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    input{
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        cursor: pointer;
                        &:checked {
                            + label {
                                &::before{
                                    border-color:$lightBlue ;
                                }
                                &::after{
                                    width: 12px;
                                    height: 12px;
                                    background: url(../../assets/img/checkBlue.png) no-repeat;
                                    background-size: cover;
                                    content: "";
                                    display: inline-block;
                                    position: absolute;
                                    left: 3px;
                                    top: 4px;
                                }
                            }
                        }
                    }
                    label{
                        display: inline-block;
                        padding-left: 30px;
                        position: relative;
                        color: $black;
                        cursor: pointer;
                        &::before{
                            content: "";
                            display: inline-block;
                            position: absolute;
                            width: 18px;
                            height: 18px;
                            left: 0;
                            top: 0;
                            border: 1px solid $black;
                            @include radius(2px);
                        }
                    }
                }
            }
            .skip {
                @include font(14);
                font-weight: 400;
                color: $black;
                text-align: center;
                a {
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: none;
                    color: $baseColor;
                    cursor: pointer;
                }
            }
            div{
                select{
                    padding: 8px 10px;
                    -webkit-appearance: none;
                    background-image:url(../../assets/img/arrowdown.png) ;
                    background-repeat: no-repeat;
                    background-position: 95% center;
                    background-size: 16px;
                    width: 100%;
                    @include font(14);
                    color: $black;
                    line-height: 20px;
                    border: 1px solid $gray;
                    @include radius(4px);
                    outline: none;
                }
                input{
                    padding: 8px 10px;
                    width: 100%;
                    @include font(14);
                    color: $black;
                    outline: none;
                    border: 1px solid $gray;
                    @include radius(4px);
                    &::placeholder{
                        color: $black;
                    }
                }
                &.coachFormSeciton{
                    form{
                        .fieldBox{
                            margin-bottom: 25px;
                            p {
                                @include font(15);
                                font-weight: 400;
                                color: $black;
                                line-height: 1.5;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                            label{
                                display: block;
                                @include font(16);
                                font-weight: 400;
                                color: $black;
                                line-height: 22px;
                                margin: 0 0 10px 0;
                                sup {
                                    display: inline-block;
                                    vertical-align: -8px;
                                    font-weight: 500;
                                    color: $baseColor;
                                }
                            }
                           .flexBox{
                                @include flex();
                                align-items: center;
                                border:1px solid $gray;
                                @include radius(4px);
                                .flag{
                                    padding: 0 4px 0 10px;
                                    width: 50px;
                                    min-width: 50px;
                                    img{
                                        max-width: 100%;
                                        height: auto;
                                    }
                                }
                                select{
                                    width: 60px;
                                    min-width: 60px;
                                    background-size: 10px;
                                    outline: none;
                                    border: none;
                                    @include radius(0);
                                }
                                input{
                                    width: calc(100% - 110px);
                                    border: none;
                                    @include radius(4px);
                                }
                            }
                        }
                    }
                }
            }
        }
        .continueBtnBox{
            text-align: center;
            margin-top: 40px;
            a{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                background-color: $green;
                padding: 10px 15px;
                min-width: 100px;
                min-height: 45px;
                @include font(16);
                color: $white;
                border: none;
                outline: none;
                box-shadow: none;
                @include radius(4px);
                cursor: pointer;
            }
        }
    }
}
.modal {
    padding-right: 0!important;
    &.skip-popup {
        .modal-dialog {
            max-width: 580px;
        }
        .modal-content {
            border: none;
        }
        .modal-body {
            padding: 50px 45px;
            text-align: center;
            position: relative;
            button {
                &.close {
                    outline: none;
                    position: absolute;
                    top: 15px;
                    right:20px;
                    opacity: 1;
                }
            }
            .modal-title {
                @include font(30);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 15px;
                + p {
                    margin-bottom: 25px
                }
            }
            p {
                @include font(14);
                font-weight: 400;
                color: $black;
                margin-bottom: 15px;
            }
            .btn {
                padding: 12px 25px;
                @include font(14);
                font-weight: 400;
            }
            .skip {
                margin-top: 15px;
                margin-bottom: 0;
                a {
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: underline;
                    @include font(14);
                    color: $baseColor;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:567px) {
    .modal {
        &.skip-popup {
            .modal-body {
                padding: 45px 25px 35px;
                .modal-title {
                    @include font(20);
                }
            }
        }
    }
}
@media screen and (max-width:479px) {
    .onBoardCoachTeamSection{
        .coachTeamBlock{
            .formSection{
                padding: 20px;
            }
            .continueBtnBox{
                a{
                    width: 100%;
                }
            }
        }
    }  
}