@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/reset";

.page-banner {
  margin-top: 58px;
  padding: 15px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include flex();
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 600px;
  .banner-content-box {
    max-width: 810px;
    margin: 0 auto;
    color: $white;
    .banner-title {
      @include font(50);
      font-weight: 500;
      margin-bottom: 15px;
    }
    .banner-short-text {
      @include font(18);
      font-weight: 300;
      margin-bottom: 30px;
      line-height: 1.6;
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.common-padding {
  padding: 50px 0;
}
.error{
  @include font(13);
  color: $redColor!important;
  margin-top: 8px;
  margin-bottom: 0!important;
  text-align: left;
  &:empty {
    display: none;
  }
}
.common-padding {
  .sec-title {
    @include font(36);
    font-weight: 500;
    color: $blackColor;
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-align: center;
    position: relative;
    &:before {
      content: '';
      background: $baseColor;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 140px;
      height: 2px;
      margin: 0 auto;
      z-index: 11;
    }
  }
}
.how-it-work-sec {
  background: transparent linear-gradient(180deg, $white 0%, #f3f3f3 100%) 0% 0% no-repeat padding-box;
  opacity: 1;

  .hw-work-listing {
    @include flex();
    justify-content: center;
    padding: 0 15px;
    li {
      background: $white;
      padding: 30px 20px;
      width: 33.333%;
      margin: 1%;
      @include radius(6px);
      box-shadow: 0px 0px 18px #0000001a;
      text-align: center;
      transition: all 0.5s ease-in-out;
      .icon-box {
        @include flex();
        align-items: center;
        justify-content: center;
        background: $white;
        width: 120px;
        height: 120px;
        margin: 0 auto 25px;
        @include radius(60px);
        text-align: center;
        box-shadow: 0px 0px 14px #0000001a;
        img {
          width: 56px;
        }
      }
      .content-box {
        h3 {
          @include font(20);
          font-weight: 500;
          line-height: 24px;
          color: $black;
          margin-bottom: 15px;
        }
        p {
          @include font(18);
          font-weight: 300;
          line-height: 27px;
          color: $black;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &:hover {
        box-shadow: 4px 4px 35px #0000001a;
      }
    }
  }
}
.dream-college-sec {
  text-align: center;
  .dream-college-logos {
    padding: 30px 100px;
    position: relative;
    .owl-stage-outer {
      margin: 0 100px;
      max-width: 1000px;
      margin: 0 auto;
    }
    .item img {
      @include flex();
      align-items: center;
      justify-content: center;
      max-width: 100%;
      width: auto;
      height: 52px;
      object-fit: contain;
      margin: 0 auto;
    }
    .owl-nav {
      display: block;
      position: absolute;
      width: 100%;
      top: 35px;
      left: 0;
      right: 0;
      z-index: 1;
      button {
        &.owl-next {
          float: right;
          position: absolute;
          right: 50px;
          outline: none;
          span {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            right: 3px;
            top: 1px;
          }
        }
        &.owl-prev {
          float: left;
          position: absolute;
          left: 50px;
          outline: none;
        }
        span {
          font-size: 0;
          content: "";
          width: 14px;
          height: 14px;
          border-right: 2px solid $black;
          border-bottom: 2px solid $black;
          display: inline-block;
          position: relative;
          transform: rotate(-0deg);
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          left: 2px;
          top: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }
    }
  }
}
.tabs-data-wrapper {
  input {
    &::placeholder {
      color: $white;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: $white;
    }
    &::-ms-input-placeholder {
      color: $white;
    }
  }
}
.create-profile-sec {
  background: url("../img/create-profile-bg.png") no-repeat;
  background-position: center bottom;
  padding-bottom: 120px;
  .sec-title {
    color: $white;
    margin-bottom: 30px;
    &:before {
      background: $white;
    }
  }
  .create-profile-inner {
    width: 450px;
    margin: 0 auto;
    .tabs {
      @include flex();
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;
      border-radius: 2px;
      overflow: hidden;
      li {
        width: 50%;
        a {
          display: block;
          text-decoration: none;
          background: $white;
          @include font(15);
          font-weight: 300;
          color: $blackColor;
          border: 1px solid $white;
          padding: 15px 10px;
          text-align: center;
          &:hover {
            background: $blue;
            color: $white;
          }
        }
        &.current a {
          background: $blue;
          color: $white;
        }
      }
    }
    .tabs-data-wrapper {
      width: 450px;
      margin: 0 auto;
      .tab-content {
        display: none;
        &.current {
          display: block;
        }
        .form-group {
          margin-bottom: 15px;
          .form-control {
            background-color: transparent;
            @include font(14);
            font-weight: 300;
            color: $white;
            padding: 10px 15px;
            height: 45px;
            border: 1px solid $white;
            &.custom-select {
              padding-right: 30px;
              appearance: none;
              background-image: url("../img/down-arrow-icon.svg");
              background-repeat: no-repeat;
              background-position: 97% center;
              option {
                color: $blackColor;
              }
            }
          }
        }
      }
      .button-box {
        text-align: center;
        padding-top: 15px;
        .btn {
          padding: 10px 30px;
          background: $baseColor;
        }
      }
    }
  }
}
.play-sport-sec {
  background: $white;
  position: relative;
  &:after {
    content: "";
    background: transparent linear-gradient(180deg, $white 0%, $lightBlue 100%) 0% 0% no-repeat;
    opacity: 0.08;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .container {
    position: relative;
    z-index: 11;
    opacity: 1;
  }
  .sec-title {
    margin-bottom: 30px;
  }
  .play-sport-listing {
    @include flex();
    justify-content: center;
    li {
      width: 203px;
      margin: 0 15px;
      min-height: 250px;
      a {
        text-decoration: none;
        display: block;
        padding: 25px 20px;
        background: $white;
        box-shadow: 0px 0px 34px #0000001a;
        @include radius(5px);
        @include transition();
        &:hover {
          background: $lightBlue;
        }
        &:hover .title {
          color: $white;
        }
        &:hover .link-get-started {
          display: inline-block;
          visibility: middle;
          opacity: 1;
        }
        &:hover svg {
          path {
            fill: $white;
          }
          rect {
            fill: $white;
          }
        }
        svg {
          display: block;
          margin-bottom: 20px;
          min-height: 130px;
          @include transition();
        }
        span {
          display: block;
          @include transition();
          &.title {
            @include font(20);
            font-weight: 600;
            color: $blackColor;
            letter-spacing: 0.3px;
          }
          &.link-get-started {
            background: url("../img/arrow-white-icon.svg") no-repeat;
            background-position: right 11px;
            padding: 10px 35px 0 0;
            @include font(13);
            font-weight: 400;
            color: $white;
            letter-spacing: 0.2px;
            display: none;
            margin-top: 5px;
            opacity: 0;
          }
        }
      }
    }
  }
}
.college-plan-listing {
  @include flex();
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  li {
    background: $white;
    width: calc(32% - 10px);
    margin: 10px;
    padding: 40px;
    text-align: center;
    @include transition();
    &:hover {
      box-shadow: 0px 3px 16px #00000012;
      border-radius: 6px;
    }
    .title {
      @include font(106);
      font-weight: 400;
      color: $baseColor;
      margin-bottom: 20px;
      line-height: 1;
    }
    .sub-title {
      @include font(22);
      font-weight: 500;
      color: $black;
      margin-bottom: 15px;
    }
    .short-content {
      @include font(16);
      font-weight: 400;
      color: $black;
      line-height: 24px;
      margin-bottom: 20px;
      p:last-child {
        margin-bottom: 0;
      }
    }
    .link-box a {
      display: block;
      text-decoration: none;
      color: $baseColor;
      &:hover {
        color: $blue;
        svg {
          animation: animArrowRev infinite 0.5s;
          path {
            stroke: $blue;
          }
        }
      }
      svg {
        margin-left: 10px;
        path {
          fill: none;
          stroke: $baseColor;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px;
        }
      }
    }
  }
}
.success-stories-sec {
  background: $bgLightGray;
  position: relative;
  &:after {
    content: "";
    background: transparent linear-gradient(180deg, $white 0%, $lightBlue 100%) 0% 0% no-repeat;
    opacity: 0.08;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }
  .container {
    position: relative;
    z-index: 11;
  }
  .success-stories-listing {
    padding-top: 20px;
    @include flex();
    justify-self: center;
    align-items: center;
    li {
      width: 26%;
      margin-right: 3%;
      background: $white;
      box-shadow: 0px 11px 13px #00000029;
      @include radius(6px);
      padding: 60px 20px 25px;
      margin-top: 50px;
      position: relative;
      text-align: center;
      &:hover {
        box-shadow: 0px 15px 18px #00000029;
      }
      &:nth-child(2) {
        width: 38%;
        .success-stories-content {
          max-width: 280px;
          padding-left: 30px;
          padding-right: 30px;
          margin: 0 auto;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .success-stories-box {
        .img-box {
          position: absolute;
          width: 100px;
          left: 0;
          right: 0;
          top: -50px;
          margin: 0 auto;
          img {
            display: block;
            width: 100px;
            height: 100px;
            @include radius(50px);
          }
        }
        .success-stories-content {
          position: relative;
          padding-top: 45px;
          @include font(16);
          font-weight: 300;
          color: #999999;
          line-height: 24px;
          &:after {
            content: "";
            background: url("../img/quote-icon.svg") no-repeat;
            background-position: center center;
            width: 28px;
            height: 23px;
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            margin: 0 auto;
            z-index: 1;
          }
          p {
            margin-bottom: 5px;
          }
          .title {
            color: #7b7b7b;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.pageContent {
  &.claimprofile-main-sec {
    min-height: 1px;
  }
}
.claimprofile-sec {
  .claimprofile-box {
    background: none!important;
    box-shadow: none!important;
    border-radius: 0 !important;
    min-height: 1px !important;
    .claimprofile-title {
      padding: 0.9em 0!important;
      @include font(18);
      font-weight: 600;
      color: $blackColor;
      @include flex();
      justify-content: space-between;
      align-items: center;
      position: relative;
      border-bottom: 1px solid rgba($border, 0.4%);
    }
    .claimprofile-detail {
      padding: 0.9em 0!important;
      .flexbox {
        overflow: hidden;
        li {
          float: left;
          width: 20.5% !important;
          margin-right: 2%;

          &:last-child {
            width: 10% !important;
            margin-right: 0;
            button {
              @include font(14);
              background: $baseColor!important;
              margin-top: 20px;
              width: 100% !important;
              height: 45px;
            }
          }
          label {
            display: block;
            @include font(14);
            font-weight: 400;
            color: $black;
            margin-bottom: 0.5em;
          }
          .form-control {
            @include font(14);
            font-weight: 400;
            color: $black;
            width: 100%;
            border: 1px solid $border;
            @include radius(4px);
            padding: 0 1em;
            height: 45px;
          }
        }
      }
      .MuiOutlinedInput-input {
        padding: 13.5px 14px;
      }
      .MuiInputLabel-formControl {
        top: -4px;
      }
    }
    .claim-profile-filter-data {
      padding-top: 1em;
      h3 {
        @include font(16);
        font-weight: 500;
        color: $blackColor;
        margin-bottom: 15px;
        text-transform: capitalize;
      }
      .athlete-profile-item {
        li {
          background: rgba($gray, 0.1%);
          padding: 0.6em 0.8em;
          @include flex();
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
          @include radius(4px);
          &:last-child {
            margin-bottom: 0;
          }
          .athlete-profile-info {
            @include flex();
            align-items: center;
            .img-box {
              margin-right: 15px;
              img {
                display: block;
                width: 55px;
                height: 55px;
                @include radius(50px);
              }
            }
            .content-box {
              @include font(16);
              font-weight: 400;
              color: $black;
            }
          }
          .btn {
            @include font(14);
          }
        }
      }
    }
    .paginationBox {
      @include flex();
      flex-wrap: wrap;
      align-items: center;
      div {
        &.perPage {
          @include flex();
          align-items: center;
          width: 110px;
          input {
            padding: 5px;
            width: 35px;
            height: 26px;
            @include font(14);
            color: $black;
            border: 1px solid $gray;
            outline: none;
            box-shadow: none;
            @include radius(3px);
          }
          label {
            display: inline-flex;
            @include font(14);
            color: $black;
            margin-left: 10px;
          }
        }
        &.pageNav {
          width: calc(100% - 220px);
          text-align: center;
          ul {
            margin: 0;
            padding: 0;
            display: inline-flex;
            justify-content: center;
            width: auto;
            @include radius(3px);
            border: 1px solid $gray;
            li {
              list-style: none;
              border-right: 1px solid $gray;
              width: 32px;
              height: 26px;
              @include flex();
              align-items: center;
              justify-content: center;
              a {
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                @include font(14);
                color: #626262;
                cursor: pointer;
                width: 32px;
                height: 26px;
                &:hover {
                  background-color: $lightBlue;
                  color: $white;
                  svg {
                    fill: $white;
                  }
                }
              }
              &.backLink {
                svg {
                  width: 10px;
                  height: auto;
                  fill: #626262;
                  transform: rotate(180deg);
                }
              }
              &.forLink {
                svg {
                  width: 10px;
                  height: auto;
                  fill: #626262;
                }
              }
              &:last-child {
                border-right: none;
              }
            }
            .disabled {
              a {
                &:hover {
                  background-color: $white;
                  color: #626262;
                  svg {
                    fill: #626262;
                  }
                }
              }
            }
            .active {
              a {
                background-color: $lightBlue;
                color: $white;
                svg {
                  fill: $white;
                }
              }
            }
          }
        }
        &.goBox {
          @include flex();
          align-items: center;
          width: 110px;
          justify-content: flex-end;
          input {
            border: 1px solid $gray;
            outline: none;
            box-shadow: none;
            @include radius(3px);
            padding: 5px;
            width: 35px;
            height: 26px;
            @include font(14);
            color: $black;
          }
          a {
            display: inline-flex;
            align-items: center;
            @include font(14);
            color: $black;
            padding: 5px;
            text-decoration: none;
            margin-left: 10px;
            cursor: pointer;
            span {
              display: inline-flex;
              &.icon {
                margin-left: 5px;
                svg {
                  fill: $black;
                  .a {
                    opacity: 1;
                    fill: $black;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .form-group {
    .error {
      line-height: 1.3;
    }
  }
}
.banner-claim-profile {
    //background: url(../img/banner-claim-profile.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 60px 0;
    margin-top: 60px;
    .banner-flexbox {
        @include flex();
        justify-content: space-between;
        align-items: center;
        .banner-content {
            width: 45%;
            margin-right: 10%;
            color: $white;
            .title {
                @include font(50);
                font-weight: 700;
                color:$white;
                line-height: 1.333;
                margin-bottom: 20px;
                span {
                    display: block;
                    color: $lightBlue;
                }
            }
            .brand-logo {
                margin: -10px 0 10px;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 100%;
                }
            }
            .short-text {
                @include font(20);
                font-weight: 500;
                color:$white;
                margin-bottom: 25px;
                p {
                    line-height: 36px;
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .button-wrap {
                .btn {
                    padding: 10px 15px;
                    @include font(20);
                    font-weight: 500;
                    color:$white;
                    min-width: 240px;
                    @include radius(4px);
                    cursor: pointer!important;
                    &.lightblue {
                        background:$lightBlue;
                        min-width: 180px;
                    }
                }
            }
        }
        .video-box-wrap {
            width: 45%;
            .video-img {
                position: relative;
                img {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                }
                .video-play-button {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    cursor: pointer;
                    .video-play-inner {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        .play-btn {
                            @include flex();
                            justify-content: center;
                            align-items: center;
                            background: $white;
                            width: 75px;
                            height: 75px;
                            @include radius(50px);
                            box-shadow: 0px 10px 30px #000000;
                            outline: none;
                            border: none;
                            cursor: pointer;
                            svg {
                                .a {
                                    fill: $baseColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.claimprofile-sec {
    .claimprofile-box {
        // background: $white;
        // box-shadow: 1px 2px 2px #00000017;
        // @include radius(4px);
        // min-height: 350px;
        .claimprofile-title {
            padding: .9em 0;
            @include font(18);
            font-weight: 600;
            color: $blackColor;
            @include flex();
            justify-content: space-between;
            align-items: center;
            position: relative;
            border-bottom: 1px solid rgba($border, .4%);
        }
        .claimprofile-detail {
            padding: .9em 0;
            .flexbox {
                overflow: hidden;

                li {
                    float: left;
                    width: 20.5%;
                    margin-right: 2%;

                    &:last-child {
                        width: 10%;
                        margin-right: 0;

                        button {
                            @include font(14);
                            margin-top: 20px;
                            width: 100%;
                            height: 45px;
                        }
                    }

                    label {
                        display: block;
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        margin-bottom: .5em;
                    }

                    .form-control {
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        width: 100%;
                        border: 1px solid $border;
                        @include radius(4px);
                        padding: 0 1em;
                        height: 45px;
                    }
                }
            }
        }
        .claim-profile-filter-data {
            padding-top: 1em;
            h3 {
                @include font(16);
                font-weight: 500;
                color: $baseColor;
                margin-bottom: 15px;
                text-transform: capitalize;
            }

            .athlete-profile-item {
                li {
                    background: rgba($gray, .1%);
                    padding: .6em .8em;
                    @include flex();
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    @include radius(4px);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .athlete-profile-info {
                        @include flex();
                        align-items: center;

                        .img-box {
                            margin-right: 15px;

                            img {
                                display: block;
                                width: 55px;
                                height: 55px;
                                @include radius(50px);

                            }
                        }

                        .content-box {
                            @include font(16);
                            font-weight: 400;
                            color: $black;
                        }

                    }

                    .btn {
                        @include font(14);
                        &.blue {
                            background: $lightBlue;
                        }
                    }
                }
            }
        }
    }
    .sec-title {
        @include font(36);
        font-weight: 700;
        color: $blackColor;
        margin-bottom: 20px;
        &::before {
            display: none;
        }
    }
    h3 {
        @include font(22);
        font-weight: 700;
        color: $blackColor;
        margin-bottom: 15px;
    }
    .list-type-bullet {
        @include font(18);
        font-weight: 400;
        color:$black;
        line-height: 30px;
        list-style: none;
        padding-left: 0;
        padding-bottom: 10px;
        li {
            position: relative;
            padding-left: 40px;
            margin-bottom: 15px;
            &::after {
                content:'';
                background: url(../img/check-blue-icon.svg) no-repeat;
                background-position: center center;
                position: absolute;
                width: 24px;
                height: 22px;
                left: 0;
                top: 5px;
                z-index: 1;
            }
        }
    }
}
.get-started-sec {
    //background: url(../img/get-started-bg.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 60px 0;
    text-align: center;
    .sec-title {
        @include font(42);
        font-weight: 700;
        color:$white;
        margin-bottom: 20px;
        padding-bottom: 0;
        &::before {
            display: none;
        }
    }
    .short-text {
        @include font(18);
        font-weight: 400;
        color:$white;
        line-height: 32px;
        margin-bottom: 35px;
        p {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .button-wrap {
        .btn {
            display: inline-block;
            vertical-align: middle;
            background: $white;
            padding: 10px 20px;
            @include font(18);
            font-weight: 500;
            color:$blackColor;
            @include radius(4px);
            outline: none;
            cursor: pointer;
            + p {
                @include font(14);
                font-weight: 400;
                color:$white;
                padding-top: 20px;
                margin-bottom: 0;
            }
        }
    }
}
.claim-profile-sec {
    padding: 60px 0;
    .top-block {
        text-align: center;
        margin-bottom: 40px;
        .title {
            @include font(42);
            font-weight: 700;
            color:$blackColor;
            margin-bottom: 0;
        }
    }
    .flex-box {
        @include flex();
        align-items:center;
        .infobox {
            background: $baseColor;
            padding: 20px;
            width: 300px;
            margin-right: 50px;
            @include radius(15px);
            text-align: center;
            color:$white;
            margin: 80px 50px 0 0;
            .pick-box {
                width: 180px;
                margin: -105px auto 20px;
                img {
                    width: 180px;
                    height: 180px;
                    box-shadow: 0px 0px 15px #41414133;
                    border: 3px solid #FFFFFF;
                    @include radius(100px);
                }
            }
            .name {
                @include font(24);
                font-weight: 600;
                margin-bottom: 35px;
                position: relative;
                &::after {
                    content:'';
                    background: $white;
                    position: absolute;
                    width: 40px;
                    height: 3px;
                    left: 0;
                    right: 0;
                    bottom: -15px;
                    margin: 0 auto;
                    z-index: 1;
                }
                span {
                    display: block;
                    @include font(16);
                    font-weight: 400;
                    padding-top: 8px;
                }
            }
            .about-info {
                @include flex();
                justify-content: center;
                align-items: center;
                @include font(12);
                font-weight: 400;
                .item {
                    padding:0 10px;
                    border-left: 1px solid rgba($white, .3);
                    &:first-child {
                        border-left: none;
                    }
                    .text {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .text:last-child {
                        @include font(28);
                        font-weight: 600;
                        margin-left: 6px;
                    }
                }
            }
        }
        .detail-info {
            width: calc(100% - 350px);
            @include font(18);
            font-weight: 400;
            color:$black;
            h3 {
                @include font(30);
                font-weight: 700;
                color:$blackColor;
                margin-bottom: 15px;
            }
            p {
                line-height: 35px;
                margin-bottom: 15px;
            }
        }
    }
}
.claim-profile-benefit {
    background: $white;
    padding: 60px 0;
    .top-block {
        max-width: 1000px;
        margin: 0 auto;
        text-align: center;
        .title {
            @include font(42);
            font-weight: 700;
            color:$blackColor;
            margin-bottom: 15px;
        }
        .short-text {
            @include font(18);
            font-weight: 400;
            color:$black;
            line-height: 36px;
            p {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .benefit-list {
        @include flex();
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        li {
            background: rgba(#EFEFEF, .3);
            padding: 20px;
            width: calc(50% - 35px);
            @include radius(15px);
            margin-top: 40px;
            margin-left: 15px;
            position: relative;
            .benefit-info {
                background:$white;
                padding:20px;
                @include font(18);
                font-weight: 400;
                color: $black;
                line-height: 34px;
                box-shadow: 0px 0px 15px #30303029;
                border: 1px solid #EFEFEF;
                @include radius(10px);
                .info-flexbox {
                    @include flex();
                    align-items: center;
                    margin-bottom: 15px;
                    margin-left: -55px;
                }
                .icon-box {
                    background: transparent linear-gradient(125deg, #C0321F 0%, #851B0D 100%) 0% 0% no-repeat padding-box;
                    padding: 10px;
                    display: inline-block;
                    vertical-align: middle;
                    min-width: 135px;
                    text-align: center;
                    height: 80px;
                    @include radius(0 100px 100px 0);
                    position: relative;
                    margin-right: 20px;
                    &::after {
                        content: "";
                        display: inline-block;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 16px 16px 0;
                        border-color: transparent $baseColor transparent transparent;
                        position: absolute;
                        left: 0;
                        bottom: -16px;
                        z-index: 1;
                    }
                }
                h3 {
                    @include font(24);
                    font-weight: 600;
                    color:$baseColor;
                    line-height: 30px;
                    margin-bottom: 0;
                }
                p {
                    line-height: 34px;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.how-it-work-sec {
    background: rgba(#3E3E3E, 0.08);
    padding: 60px 0;
    .top-block {
        max-width: 1000px;
        margin: 0 auto 40px;
        text-align: center;
        .title {
            @include font(42);
            font-weight: 700;
            color:$blackColor;
            margin-bottom: 15px;
        }
        .short-text {
            @include font(18);
            font-weight: 400;
            color:$black;
            line-height: 36px;
            p {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .benefit-list {
        li {
            @include flex();
            align-items: center;
            background:$white;
            padding:20px;
            @include font(18);
            font-weight: 400;
            color: $black;
            line-height: 34px;
            border: 1px solid #EFEFEF;
            @include radius(15px 100px 100px 15px);
            margin-bottom: 40px;
            margin-left: 25px;
            position: relative;
            .icon-box {
                @include flex();
                justify-content: center;
                align-items: center;
                background:#28BBDD;
                padding: 20px;
                display: inline-block;
                vertical-align: middle;
                width: 250px;
                height: 190px;
                text-align: center;
                @include radius(15px 100px 100px 15px);
                position: relative;
                margin-right: 30px;
                margin-left: -45px;
                svg {
                    height: 100%;
                }
            }
            .info-detail {
                width: calc(100% - 270px)
            }
            h3 {
                @include font(24);
                font-weight: 600;
                color:#28BBDD;
                line-height: 30px;
                margin-bottom: 10px;
            }
            p {
                line-height: 34px;
                margin-bottom: 0;
            }
            &:nth-child(2) {
                .icon-box {
                    background:#0BBCB7;
                }
                h3 {
                    color: #0BBCB7;
                }
            }
            &:nth-child(3) {
                .icon-box {
                    background:#EF654D;
                }
                h3 {
                    color: #EF654D;
                }
            }
            &:nth-child(4) {
                .icon-box {
                    background:#F6AE59;
                }
                h3 {
                    color: #F6AE59;
                }
            }
            &:nth-child(even) {
                flex-direction: row-reverse;
                @include radius(100px 15px 15px 100px);
                margin-left:0;
                margin-right: 25px;
                .icon-box {
                    margin-right:-45px;
                    margin-left: 30px;
                }
            }
        }
    }
}
.common-faq-sec {
    background:#fafafa;
    padding: 50px 0 60px;
    .sec-title {
        @include font(36);
        font-weight: 700;
        color: $blackColor;
        padding-bottom: 0;
        margin-bottom: 30px;
        text-align: left;
        &::before {
            display: none;
        }
    }
    .faq-listing {
        counter-reset: faq;
        li {
            @include transition();
            margin-bottom: 15px;
            border-bottom: 1.5px solid rgba($gray, .50%);
            padding-bottom: 20px;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .faq-title {
                @include font(18);
                color: $blackColor;
                position: relative;
                padding-right: 30px;
                line-height: 1.4;
                cursor: pointer;
                span {
                    display: inline-block;
                    vertical-align: middle;
                    color:$baseColor;
                    margin-right: 5px;
                }
                &:after {
                    content: '+';
                    font-size: 35px;
                    color: $blackColor;
                    display: block;
                    width: 28px;
                    height: 28px;
                    line-height: 28px;
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    text-align: center;
                    opacity: .15;
                }
                &.active {
                    color:$baseColor;
                    &:after {
                        content: "";
                        font-size: 0;
                    }
                    &:before {
                        content: "";
                        width: 18px;
                        position: absolute;
                        background:$baseColor;
                        height: 2.8px;
                        right: 0;
                        top: 7px;
                    }
                }
            }
            .faq-data {
                @include font(16);
                color: $black;
                display: none;
                padding-top: 15px;
                line-height: 1.6;
            }
        }
    }
}
.common-modal {
  padding-right: 0;
  .modal-content {
    border: none;
    box-shadow: 0px 3px 20px #00000080;
    @include radius(8px);
    .modal-header {
      padding: 1em 1.2em;
      border-bottom: 1px solid rgba($border, 0.4%);
      .modal-title {
        @include font(18);
        font-weight: 500;
        color: $blackColor;
      }
      .close {
        font-weight: 300;
        color: $gray;
      }
    }
    .modal-body {
      padding: 1.4em 1.2em 1.6em;
      p {
        @include font(14);
        font-weight: 400;
        color: $black;
        margin-bottom: 20px;
        text-align: center;
        line-height: 22px;
      }
      .claimprofile-password-box {
        display: block;
        text-align: center;
        div {
          justify-content: center;
          > div {
            display: inline-block;
            vertical-align: middle;
            margin: 5px;
            width: 45px;
            display: inline-block !important;
            input {
              @include font(18);
              font-weight: 400;
              color: $black;
              width: 100% !important;
              border: 1px solid $border;
              @include radius(4px);
              padding: 0.5em;
              height: 45px;
              text-align: center;
              box-shadow: none;
              line-height: 45px;
            }
          }
        }
        li {
          display: inline-block;
          vertical-align: middle;
          margin: 5px;
          width: 45px;

          input {
            @include font(18);
            font-weight: 400;
            color: $black;
            width: 100%;
            border: 1px solid $border;
            @include radius(4px);
            padding: 0.5em;
            height: 45px;
            text-align: center;
            box-shadow: none;
            line-height: 45px;
          }
        }
      }
      .form-group {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          .name-text {
            @include font(14);
            // font-weight: 400;
            // // padding: 0.6em 0;
            // display: inline-block;
            // visibility: middle;
          }
          label {
            color: $blackColor;
          }
        }
        label {
          display: block;
          @include font(14);
          font-weight: 400;
          color: $black;
          margin-bottom: 0.5em;
        }
        .form-control {
          @include font(14);
          font-weight: 400;
          color: $black;
          width: 100%;
          border: 1px solid $border;
          @include radius(4px);
          padding: 0 1em;
          height: 45px;
          box-shadow: none;
        }
        .date-close-icon {
          position: absolute;
          right: 45px;
          top: 34px;
          @include font(22);
          font-weight: 300;
          color:$blackColor;
          cursor: pointer;
          z-index: 1;
        }
        .countryPhoneFlexBox {
          input, select {
            height: 30px!important;
          }
        }
      }
    }
  }
  .modal-footer {
    border-top: none;
    padding: 0 1.2em 1.8em;
    justify-content: center;
    button {
      min-width: 100px;
      @include font(14);
      font-weight: 300;
    }
  }
}
@media screen and (max-width: 1365px) {
  .page-banner {
    height: 580px;
  }
}
@media screen and (max-width: 1279px) {
  .page-banner {
    height: 520px;
    .banner-content-box {
      .banner-title {
        @include font(45);
      }
      .banner-short-text {
        @include font(16);
      }
    }
  }
  .sec-title {
    @include font(35);
  }
  .how-it-work-sec  {
    .hw-work-listing  {
      li {
        .content-box {
          h3 {
            @include font(18);
          }
          p {
            @include font(15);
          }
        }
      }
    }
  }
  .college-plan-listing {
    li {
      .title {
        @include font(90);
      }
      .sub-title {
        @include font(20);
      }
      .short-content {
        @include font(15);
      }
    }
  }
  .success-stories-sec {
    .success-stories-listing {
      li {
        .success-stories-box {
          .success-stories-content {
            @include font(15);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .page-banner {
    height: 480px;
    .banner-content-box {
      .banner-title {
        @include font(35);
      }
      .banner-short-text {
        @include font(15);
      }
    }
  }
  .common-padding {
    padding-top: 35px;
    padding-bottom: 40px;
    .sec-title {
      @include font(30);
    }
  }
  .how-it-work-sec {
    .hw-work-listing {
      li {
        padding-left: 15px;
        padding-right: 15px;
        .icon-box {
          width: 100px;
          height: 100px;
        }
        .content-box {
          h3 {
            @include font(17);
          }
          p {
            @include font(13);
            line-height: 22px;
          }
        }
      }
    }
  }
  .dream-college-sec {
    &.common-padding {
      padding-bottom: 20px;
    }
    .dream-college-logos {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  .play-sport-sec  {
    .play-sport-listing {
      li {
        a {
          span {
            &.title {
              @include font(18);
            }
          }
        }
      }
    }
  }
  .college-plan-listing {
    li  {
      width: calc(30% - 10px);
      padding: 20px 25px 30px;
      .title {
        @include font(60);
      }
      .sub-title {
        @include font(18);
      }
      .short-content {
        @include font(14);
        line-height: 22px;
      }
    }
  }
  .success-stories-sec {
    .success-stories-listing {
      li {
        .success-stories-box {
          .success-stories-content {
            @include font(13);
            line-height: 22px;
          }
        }
      }
    }
  }
  .claimprofile-sec {
    .claimprofile-box {
      .claimprofile-detail {
        .flexbox {
          li {
            width: 23.5% !important;
            &:nth-child(4) {
              margin-right: 0;
            }
            &:last-child {
              width: 100%!important;
              clear: both;
              button {
                margin-top: 0;
              }
            }
            .form-control {
              @include font(13);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .page-banner {
    height: 300px;
    .banner-content-box {
      .banner-title {
        @include font(28);
      }
      .banner-short-text {
        @include font(14);
        margin-bottom: 20px;
      }
    }
  }
  .common-padding {
    .sec-title {
      @include font(26);
    }
  }
  .how-it-work-sec {
    .hw-work-listing {
      flex-wrap: wrap;
      li {
        width: 46%;
        margin: 2%;
        .icon-box {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
  .college-plan-listing {
    li {
      width: calc(46% - 10px);
    }
  }
  .success-stories-sec {
    .success-stories-listing {
      display: block;
      overflow: hidden;
      padding-top: 10px;
      li {
        width: 48.5%;
        float: left;
        margin-bottom: 30px;
        &:nth-child(2) {
          width: 48.5%;
          .success-stories-content {
            padding-left: 0;
            padding-right: 0;
          }
        }
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
        &:nth-child(2n + 3) {
          clear: both;
        }
        &:last-child {
          width: 100%;
          margin-bottom: 0;
        }
      }
    }
  }
  .claimprofile-sec {
    .claimprofile-box {
      .claimprofile-detail {
        .flexbox {
          li {
            width: 49%!important;
            &:nth-child(2n + 2) {
              margin-right: 0;
            }
            &:nth-child(2n + 3) {
              clear: both;
            }
            &:last-child {
              width: 100%!important;
              clear: both;
              button {
                margin-top: 0;
              }
            }
            .form-control {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  .claimprofile-sec {
    .claimprofile-box {
      .claim-profile-filter-data {
        .athlete-profile-item {
          li {
            .athlete-profile-info {
              .content-box {
                @include font(14);
              }
            }
          }
        }
      }
    } 
  }
  .common-modal {
    height: auto;
  }
  .pageContent {
    &.claimprofile-main-sec {
      padding: 1.5em 0;
    }
  }
}
@media screen and (max-width: 567px) {
  .common-padding {
    padding-top: 30px;
    padding-bottom: 35px;
    .sec-title {
      @include font(22);
    }
  }
  .page-banner {
    height: 280px;
    padding-bottom: 25px;
    .banner-content-box {
      .banner-title {
        @include font(22);
        margin-bottom: 10px;
      }
    }
  }
  .how-it-work-sec {
    .hw-work-listing {
      padding: 0;
      li {
        width: 100%;
        margin: 0 0 25px;
        padding-top: 20px;
        padding-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .icon-box {
          margin-bottom: 15px;
          img {
            width: 45px;
          }
        }
        .content-box {
          @include font(16);
          h3 {
            @include font(18);
          }
        }
      }
    }
  }
  .create-profile-sec {
    .create-profile-inner {
      width: 100%;
      .tabs-data-wrapper {
        width: 100%;
      }
    }
  }
  .how-it-work-sec {
    .hw-work-listing {
      li {
        .play-sport-sec {
          .play-sport-listing {
            display: block;
          }
        }
      }
    }
  }
  .play-sport-sec {
    .play-sport-listing {
      li {
        width: 155px;
        margin-left: 5px;
        margin-right: 5px;
        a {
          padding: 20px 15px ;
          span {
            &.title {
              @include font(16);
            }
            &.link-get-started {
              padding-right: 25px;
            }
          }
        }
      }
    }
  }
  .college-plan-listing {
    li {
      padding: 20px 15px 30px;
      box-shadow: 0 3px 16px #00000012;
      .sub-title {
        @include font(16);
      }
      .short-content {
        @include font(14);
      }
    }
  }
  .claimprofile-sec {
    .claimprofile-box {
      .claim-profile-filter-data {
        .athlete-profile-item {
          li {
            .athlete-profile-info {
              .content-box {
                @include font(12);
                margin-right: 10px;
                line-height: 1.3;
              }
              .img-box {
                margin-right: 10px;
                img {
                  width: 50px;
                  height: 50px;
                }
              }
            }
            .btn {
              @include font(13);
              padding: 10px 12px;
            }
          }
        } 
      }
    }
  }
  .common-modal {
    .modal-content {
      .modal-header {
        padding: 0.6em 1em;
      }
      .modal-body {
        padding: 1.2em 1em 1.3em;
        p {
          @include font(13);
        }
        .claimprofile-password-box {
          li {
            width: 40px;
            margin: 2px;
            input {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 0 1em 1.4em;
    }
  }
}
@media screen and (max-width: 479px) {
  .common-padding {
    .sec-title {
      @include font(20);
    }
  }
  .page-banner {
    height: 260px;
    padding-bottom: 25px;
    .banner-content-box {
      .banner-title {
        @include font(20);
      }
      .banner-button-box {
        .btn {
          @include font(14);
        }
      }
    }
  }
  .play-sport-sec {
    .play-sport-listing {
      display: block;
      li {
        width: 100%;
        text-align: center;
        margin-left: 0;
        margin-right: 0;
        min-height: 1px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          svg {
            margin-left: auto;
            margin-right: auto;
            min-height: 1px;
          }
        }
      }
    }
  }
  .college-plan-listing {
    display: block;
    margin: 0;
    li {
      width: 100%;
      margin: 0;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        @include font(40);
        margin-bottom: 10px;
      }
    }
  }
  .dream-college-sec {
    .sec-title {
      margin-bottom: 0;
    }
  }
  .success-stories-sec {
    .success-stories-listing {
      li {
        float: none;
        width: 100% !important;
        margin-right: 0;
        margin-bottom: 75px !important;
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}