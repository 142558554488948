@import '../../../../../../../assets/css/mixin';
@import '../../../../../../../assets/css/reset';
@import '../../../../../../../assets/css/variables';

.college-detail-banner-main {
  padding: 35px 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: relative;
  margin-top: 60px;
  &::after {
    content: '';
    background: rgba($blackColor, 0.7%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .banner-detail-box {
    @include flex();
    justify-content: space-between;
    align-items: start;
    position: relative;
    z-index: 11;
    .banner-detail-text {
      @include flex();
      align-items: start;
      width: 550px;
      .college-banner-logo {
        width: 160px;
        margin-right: 20px;
        margin-top: 5px;
        position: relative;
        img {
          @include flex();
          justify-content: center;
          align-items: center;
          background: $white;
          padding: 30px;
          max-width: 100%;
          width: 100%;
          @include radius(5px);
        }
        .college-count {
          @include flex();
          justify-content: center;
          align-items: center;
          background: $baseColor;
          padding: 5px;
          @include font(14);
          color: $white;
          width: 30px;
          height: 30px;
          position: absolute;
          left: -15px;
          top: -12px;
          z-index: 1;
          @include radius(50px);
        }
        .followBtn {
          display: block;
          background: $green;
          padding: 15px;
          @include font(14);
          color: $white;
          width: 100%;
          outline: none;
          border: none;
          @include radius(4px);
          margin-top: 12px;
          span {
            margin-right: 6px;
            svg {
              fill: $white;
            }
          }
        }
      }
      .college-banner-content {
        width: calc(100% - 180px);
        margin-right: 15px;
        .title {
          @include flex();
          align-items: center;
          @include font(22);
          font-weight: 400;
          color: $white;
          line-height: 28px;
          margin-bottom: 8px;
        }
        p {
          @include font(14);
          font-weight: 300;
          color: rgba($white, 0.88);
          margin-bottom: 15px;
          &:empty {
            display: none;
          }
        }
        .collegeButtonWrap {
          margin-bottom: 15px;
          p {
            margin-bottom: 15px;
          }
          .btn {
            padding: 6px 8px;
            @include font(14);
            font-weight: 300;
            min-height: 33px;
            margin: 0 3px 0;
            &.baseballButton {
              background: $yellow;
              padding-left: 15px;
              padding-right: 15px;
            }
            &.bg-none {
              background: transparent;
              padding: 0 0 0;
              margin: 0 8px;
              box-shadow: none;
              min-height: 25px;
              height: 25px;
              &:last-child {
                margin-right: 0;
              }
              &::before {
                display: none;
              }
              svg {
                width: 30px;
                height: 25px;
              }
              &:hover {
                background: transparent;
              }
              &.powerLinkUnfilled {
                svg {
                  path {
                    fill: transparent;
                    stroke: $white;
                    stroke-width: 1.5px;
                    &:hover {
                      fill: $baseColor;
                      stroke: $baseColor;
                      stroke-width: 1.5px;
                    }
                  }
                }
              }
              &.emailLink {
                svg {
                  &:hover {
                    path {
                      fill: $lightBlue;
                    }
                  }
                }
              }
            }
          }
        }
        .address-info {
          display: block;
          li {
            display: block;
            margin-bottom: 15px;
            a {
              display: -webkit-inline-box;
              display: -moz-inline-box;
              display: -webkit-inline-flex;
              display: -ms-inline-flexbox;
              display: inline-flex;
              text-decoration: none;
              @include font(16);
              color: $white;
              line-height: 1.333;
              cursor: pointer;
              svg {
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                margin-right: 10px;
                @include transition();
                &.location-icon {
                  .a {
                    fill: none;
                    stroke: $white;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.8px;
                    @include transition();
                  }
                }
                &.sdf-icon {
                  .a {
                    fill: $white;
                    stroke: $white;
                    stroke-width: 0.5px;
                    @include transition();
                  }
                }
              }
              &:hover {
                color: $lightBlue;
                svg {
                  path {
                    stroke: $lightBlue;
                  }
                  &.location-icon {
                    .a {
                      stroke: $lightBlue;
                    }
                  }
                  &.sdf-icon {
                    .a {
                      fill: $lightBlue;
                      stroke: $lightBlue;
                    }
                  }
                }
              }
            }
          }
        }
        p {
          &.info {
            @include font(14);
            color: $white;
            line-height: 22px;
            margin-bottom: 10px;
          }
          &.email {
            @include font(14);
            color: $white;
            line-height: 22px;
            margin-bottom: 10px;
          }
          &.social {
            display: inline-flex;
            align-items: center;
            padding: 6px 0;
            @include font(16);
            color: $white;
            margin-bottom: 10px;
            cursor: pointer;
            span {
              display: inline-flex;
              &.icon {
                margin-right: 10px;
                svg {
                  width: 35px;
                  height: auto;
                  .a {
                    fill: #34c7ff;
                  }
                }
              }
            }
          }
        }
        .collegefollowersInfo {
          @include flex();
          flex-wrap: wrap;
          margin-top: 5px;
          li {
            @include flex();
            align-items: center;
            list-style: none;
            padding-right: 10px;
            margin-right: 10px;
            border-right: 1px solid $white;
            margin-bottom: 5px;
            @include font(14);
            color: $white;
            &:last-child {
              padding-right: 0px;
              margin-right: 0px;
              border-right: none;
            }
            &.likes {
              span {
                display: inline-flex;
                font-weight: 600;
                &.icon {
                  margin-right: 10px;
                  svg {
                    .a {
                      fill: $white;
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &.follower {
              span {
                font-weight: 600;
                &.label {
                  font-weight: normal;
                  margin-left: 5px;
                }
              }
            }
            &.following {
              span {
                font-weight: 600;
                &.label {
                  margin-left: 5px;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }
  .infoBoxFlex {
    @include flex();
    flex-wrap: wrap;
    width: calc(100% - 550px);
    .item {
      @include flex();
      align-items: flex-start;
      justify-content: center;
      background-color: $white;
      padding: 15px 8px;
      width: calc(25% - 10px);
      height: 92px;
      border: 1px solid #ddd;
      @include radius(5px);
      margin-right: 10px;
      margin-bottom: 10px;
      span {
        display: block;
        @include font(15);
        font-weight: 700;
        color: $baseColor;
        text-align: center;
        &.label {
          margin-bottom: 10px;
          font-weight: normal;
          @include font(13);
          color: $black;
        }
        &.text {
          @include font(14);
          font-weight: 500;
          color: $black;
          line-height: 1.3;
          &.red {
            @include font(14);
            font-weight: 700;
            color: $baseColor;
          }
        }
      }
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
    }
    .showhideBox {
      display: none;
      width: 100%;
      text-align: center;
      a {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        padding: 5px 10px;
        background: $white;
        position: relative;
        color: $baseColor;
        @include radius(4px);
        cursor: pointer;
        &::before {
          content: 'Show more';
          display: inline-block;
          @include font(14);
          color: $baseColor;
        }
        span {
          &.icon {
            display: inline-block;
            vertical-align: middle;
            line-height: 4px;
            margin-left: 10px;
            svg {
              .a {
                fill: $baseColor;
              }
            }
          }
        }
      }
    }
    &.hide {
      .item {
        display: none;
        &:nth-child(1) {
          @include flex();
        }
        &:nth-child(2) {
          @include flex();
        }
      }
      .showhideBox {
        a {
          &::before {
            content: 'Show less';
            color: $baseColor;
          }
          span {
            &.icon {
              position: relative;
              transform: rotate(180deg);
              line-height: 0;
              bottom: -2px;
            }
          }
        }
      }
    }
  }
}
.collegeStripWrap {
  padding: 15px 0;
  background: $white;
  ul {
    @include flex();
    align-items: center;
    justify-content: center;
    li {
      margin-right: 45px;
      padding-right: 45px;
      border-right: 1px solid $gray;
      @include font(16);
      color: $black;
      line-height: 1.2;
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }
      span {
        color: $lightBlue;
      }
      svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }
}
.college-main-section {
  padding: 30px 0 40px;
  background: $bgLightGray;
  .college-tabs-wrapper {
    .nav-tabs {
      @include flex();
      justify-content: space-around;
      background: $white;
      padding: 0 15px;
      border: 1px solid $gray;
      @include radius(5px);
      margin-bottom: 20px;
      li {
        margin-right: 20px;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
        a {
          @include flex();
          justify-content: center;
          align-items: center;
          text-decoration: none;
          position: relative;
          padding: 15px 10px;
          @include font(18);
          color: $black;
          height: 100%;
          outline: 0;
          border: none;
          &:hover {
            color: $baseColor;
            border: 0;
            border-radius: 0 !important;
            cursor: pointer;
            &:after {
              content: '';
              display: inline-block;
              background: $baseColor;
              position: absolute;
              width: 100%;
              height: 1px;
              left: 0;
              bottom: 0;
            }
          }
          &.active {
            color: $baseColor;
            border: none;
            border-radius: 0 !important;
            cursor: pointer;
            &:after {
              content: '';
              display: inline-block;
              background: $baseColor;
              position: absolute;
              width: 100%;
              height: 1px;
              left: 0;
              bottom: 0;
            }
          }
        }
      }
    }
    .tab-content {
      .tab-pane {
        background: $white;
        padding: 15px 15px 25px;
        border: 1px solid $gray;
        @include radius(5px);
        &.none-border {
          background: none;
          padding: 0;
          border: none;
        }
      }
      .stepperBox {
        @include flex();
        justify-content: space-between;
        margin: 10px 0 25px;
        .item {
          width: 16.66%;
          position: relative;
          cursor: pointer;
          span {
            display: block;
            text-align: center;
            @include font(14);
            font-weight: 500;
            color: $black;
            &.circle {
              background-color: #e5e5e5;
              width: 14px;
              height: 14px;
              @include radius(50%);
              margin: 0 auto 6px auto;
              position: relative;
              z-index: 1;
            }
          }
          &::before {
            content: '';
            display: inline-block;
            background-color: #e5e5e5;
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            top: 7px;
          }
          &.passed {
            &::before {
              background-color: $lightBlue;
            }
            span {
              &.circle {
                background-color: $lightBlue;
              }
            }
          }
          &.current {
            &::before {
              background-color: $lightBlue;
              width: 50%;
            }
            &::after {
              content: '';
              display: inline-block;
              background-color: $lightBlue;
              position: absolute;
              width: 50%;
              height: 2px;
              left: 50%;
              top: 7px;
            }
            span {
              position: relative;
              &.circle {
                background-color: $lightBlue;
                &::after,
                &::before {
                  content: '';
                  position: absolute;
                  height: 0;
                  width: 0;
                  bottom: -46px;
                  left: 50%;
                  pointer-events: none;
                  border: solid transparent;
                }
                &::after {
                  border-bottom-color: $white;
                  border-width: 15px;
                  margin-left: -15px;
                }
                &::before {
                  border-bottom-color: $gray;
                  border-width: 16px;
                  margin-left: -16px;
                }
              }
            }
          }
          &:last-child {
            &.current {
              &::before {
                background-color: $lightBlue;
                width: 100%;
              }
              &::after {
                display: none;
              }
            }
          }
        }
      }
      .college-tabs-content {
        padding: 25px 20px 0;
        border: 1px solid $gray;
        @include radius(5px);
        .tab-content {
          display: none;
          padding: 0;
          border: none;
          border-radius: none;
          &.current {
            display: block;
          }
          .college-info-list {
            overflow: hidden;
            li {
              float: left;
              width: 49%;
              margin-right: 2%;
              margin-bottom: 25px;
              &:nth-child(2n + 2) {
                margin-right: 0;
              }
              &:nth-child(2n + 3) {
                clear: both;
              }
              .info-flex {
                @include flex();
                align-items: center;
                text-decoration: none;
                background: $bgLightGray;
                padding: 10px 55px 10px 15px;
                @include font(16);
                font-weight: 400;
                color: $lightBlue;
                line-height: 1.2;
                min-height: 50px;
                border: 0.5px solid #d0d0d0;
                @include radius(50px);
                position: relative;
                strong {
                  font-size: 1.125rem;
                  color: $lightBlue;
                  opacity: 1;
                  text-decoration: none !important;
                  padding: 0;
                  border: none;
                  font-weight: 500;
                }
                a {
                  color: $lightBlue;
                  opacity: 1;
                  text-decoration: none !important;
                  padding: 0;
                  border: none;
                  @include transition();
                  &:hover {
                    color: #0056b3;
                    opacity: 1;
                  }
                }
                &.underline-none {
                  &:hover {
                    text-decoration: none !important;
                    opacity: 1;
                  }
                }
                &:hover {
                  color: #0056b3;
                  opacity: 1;
                }
                span {
                  display: inline-block;
                  vertical-align: middle;
                  &.circle {
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    border: 2px solid $gray;
                    @include radius(50px);
                    position: relative;
                    img {
                      display: none;
                    }
                    input[disabled] {
                      cursor: default;
                    }
                    input {
                      position: absolute;
                      width: 30px;
                      height: 30px;
                      left: -2px;
                      right: 0;
                      top: -2px;
                      bottom: 0;
                      opacity: 0;
                      z-index: 1;
                      cursor: pointer;
                      &:checked {
                        + img {
                          display: block;
                          width: 20px;
                        }
                      }
                    }
                  }
                  &.ml-8 {
                    position: absolute;
                    right: 15px;
                    margin-left: 8px;
                  }
                  &.mr-8 {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
        .back-button {
          padding-bottom: 18px;
          text-align: right;
        }
      }
      .back-button {
        .btn {
          line-height: 1.1;
          cursor: pointer;
        }
      }
    }
  }
}
.recruiting-box-data {
  padding: 15px;
  .top-block {
    text-align: center;
    h3 {
      @include font(25);
      font-weight: 500;
      color: $blackColor;
      margin-bottom: 10px;
    }
    p {
      @include font(18);
      font-weight: 400;
      color: rgba($black, 0.4);
      line-height: 1.4;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .recruiting-list {
    overflow: hidden;
    li {
      float: left;
      width: 48.5%;
      margin-right: 3%;
      padding: 30px;
      background: $white;
      box-shadow: 0px 3px 6px #00000017;
      border: 1px solid rgba($gray, 0.55%);
      @include radius(4px);
      margin-top: 30px;
      min-height: 230px; //earlier it was 390px
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
      &:nth-child(2n + 3) {
        clear: both;
      }
      .recruiting-profile-info {
        @include flex();
        align-items: center;
        margin-bottom: 30px;
        .profile-img {
          @include flex();
          justify-content: center;
          align-items: center;
          @include font(18);
          font-weight: 600;
          text-transform: uppercase;
          width: 90px;
          height: 90px;
          border: 1px solid $gray;
          @include radius(50%);
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
            @include radius(50%);
          }
        }
        .profile-detail {
          width: calc(100% - 105px);
          @include font(16);
          color: $black;
          .name {
            @include font(16);
            font-weight: 600;
            color: $blackColor;
            margin-bottom: 10px;
            position: relative;
            padding-right: 22px;
            span {
              @include font(15);
              font-weight: 500;
              color: $green;
              margin-left: 10px;
            }
            .link-info {
              margin-left: 0;
              position: absolute;
              width: 22px;
              top: 0;
              right: 0;
              cursor: pointer;
              svg {
                width: 22px;
                path {
                  fill: $lightBlue;
                }
              }
            }
          }
          .position {
            display: block;
            margin-bottom: 8px;
            line-height: 1.333;
            .icon-box {
              display: inline-block;
              vertical-align: middle;
              margin-left: 10px;
              margin-right: 14px;
              @include transition();
              svg {
                path {
                  @include transition();
                }
              }
              &:hover {
                svg {
                  path {
                    stroke: $green;
                  }
                }
              }
            }
          }
          p {
            margin-bottom: 8px;
            line-height: 1.333;
            &:empty {
              display: none;
            }
            a {
              display: inline-block;
              vertical-align: middle;
              text-decoration: none;
              @include font(16);
              color: $black;
              &:hover {
                text-decoration: none;
              }
              &:empty {
                display: none;
              }
            }
          }
          .follow-links {
            @include flex();
            align-items: center;
            .title {
              display: block;
              width: 100%;
              @include font(16);
              color: $black;
            }
            a {
              display: inline-block;
              vertical-align: middle;
              margin-right: 15px;
              cursor: pointer;
              svg {
                display: inline-block;
                vertical-align: middle;
              }
              img {
                display: inline-block;
                vertical-align: middle;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
      .recruiting-detail-box {
        .item-box {
          @include font(14);
          color: $black;
          line-height: 1.6;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          h4 {
            @include font(16);
            font-weight: 500;
            color: $black;
            margin-bottom: 10px;
          }
          p {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .recent-activity-info {
            @include font(14);
            font-weight: 500;
            color: $black;
            p {
              margin-bottom: 5px;
              line-height: 1.4;
              &:last-child {
                margin-bottom: 0;
              }
              span {
                display: inline-block;
                vertical-align: middle;
                min-width: 160px;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
.committed-data {
  .box-title {
    padding: 0.8em 0.8em;
    background: rgba($gray, 0.2%);
    @include font(16);
    color: $green;
    border-left: 3px solid $green;
    margin-bottom: 20px;
    .checkIcon {
      margin-left: 10px;
    }
  }
  .committed-athlete-list {
    @include flex();
    flex-wrap: wrap;
    li {
      @include flex();
      align-items: center;
      float: left;
      width: 49%;
      margin-right: 2%;
      padding: 15px;
      border: 1px solid rgba($gray, 0.5%);
      @include radius(5px);
      margin-bottom: 20px;
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
      &:nth-child(2n + 3) {
        clear: both;
      }
      .pic-box {
        width: 80px;
        margin-right: 25px;
        position: relative;
        img {
          display: inline-block;
          vertical-align: middle;
          width: 80px;
          height: 80px;
          @include radius(50px);
        }
        .username {
          @include flex();
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 80px;
          @include font(18);
          font-weight: 600;
          text-transform: uppercase;
          border: 1px solid $gray;
          @include radius(50px);
        }
        .checkIcon {
          display: block;
          position: absolute;
          right: -12px;
          bottom: 2px;
          z-index: 1;
          background-color: $white;
          @include radius(50%);
          svg.verified {
            .a {
              fill: $lightBlue !important;
            }
            .b {
              fill: $white !important;
            }
          }
          svg.unverified {
            .a {
              fill: #ff4a31 !important;
            }
            .b {
              fill: $white !important;
            }
          }
          img {
            background-color: $white;
            width: 25px;
            height: 25px;
          }
        }
      }
      .detail-box {
        @include font(15);
        font-weight: 400;
        .title {
          @include font(16);
          font-weight: 600;
          color: $lightBlue;
          margin-bottom: 5px;
        }
        .description {
          @include font(15);
          color: rgba($black, 0.6%);
          line-height: 1.3;
          margin-bottom: 6px;
        }
        .commited-link {
          color: $green;
        }
      }
    }
  }
  .load-more-box {
    text-align: center;
    .loadmore-button {
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      padding: 12px 20px;
      @include font(15);
      font-weight: 400;
      color: $black;
      cursor: pointer;
      border: 1px solid rgba($black, 0.2%);
      @include radius(5px);
      @include transition();
      &:hover {
        background: $lightBlue;
        border-color: $lightBlue;
        color: $white;
      }
    }
  }
}
.page-with-sidebar {
  @include flex();
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse;
  .admissions-data {
    background: $white;
    padding: 15px;
    border: 1px solid $gray;
    @include radius(5px);
    width: 100%;
    .item {
      padding-bottom: 20px;
      &:last-child {
        padding-bottom: 0;
      }
      .box-title {
        padding: 0.8em 0.8em;
        background: rgba($gray, 0.2%);
        @include font(16);
        color: $green;
        border-left: 3px solid $green;
        margin-bottom: 20px;
        .checkIcon {
          margin-left: 10px;
        }
      }
      ul {
        li {
          @include flex();
          align-items: flex-start;
          margin-bottom: 25px;
          &:last-child {
            margin-bottom: 10px;
          }
          span {
            @include font(16);
            font-weight: 400;
            color: $black;
            svg {
              margin-right: 5px;
            }
            &:first-child {
              font-weight: 500;
              color: $black;
              min-width: 300px;
              margin-right: 10px;
            }
            &.gray-text {
              font-weight: 500;
              color: rgba($black, 0.4%);
            }
            &.address-link {
              position: relative;
              padding-left: 25px;
              .pin-icon {
                position: absolute;
                left: 0;
                top: 0;
              }
            }
            &.link {
              line-break: anywhere;
            }
            a {
              display: inline-block;
              vertical-align: middle;
              text-decoration: none;
              color: $baseColor;
              @include transition();
              &.phone-text {
                @include font(16);
                font-weight: 400;
                color: $black;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
          .map-wrap {
            img {
              display: block;
              width: 100%;
            }
            iframe {
              display: block;
              width: 100%;
              height: 300px;
            }
            .map-data {
              @include flex();
              justify-content: space-between;
              align-items: center;
              padding-top: 15px;
              @include font(15);
              span {
                font-weight: 400;
                cursor: pointer;
                &:last-child {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .sidebar-box {
    width: 480px;
    margin-left: 20px;
    .item-box {
      display: block;
      background: $white;
      border: 1px solid $gray;
      @include radius(5px);
      margin-bottom: 20px;
      .title {
        padding: 15px;
        @include flex();
        justify-content: space-between;
        @include font(16);
        border-bottom: 1px solid $gray;
        .more-link {
          display: block;
          @include font(16);
          font-weight: 400;
          color: $baseColor;
          margin-left: 10px;
          cursor: pointer;
        }
      }
      ul {
        padding: 15px 15px 20px;
        li {
          @include flex();
          align-items: center;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
          .pic-box {
            width: 70px;
            margin-right: 15px;
            position: relative;
            img {
              display: inline-block;
              vertical-align: middle;
              width: 70px;
              height: 70px;
              @include radius(50px);
            }
            .username {
              @include flex();
              justify-content: center;
              align-items: center;
              width: 70px;
              height: 70px;
              @include font(18);
              font-weight: 600;
              text-transform: uppercase;
              border: 1px solid $gray;
              @include radius(50px);
            }
          }
          .detail-box {
            @include font(15);
            font-weight: 400;
            h4 {
              @include font(15);
              font-weight: 600;
              color: $lightBlue;
              margin-bottom: 5px;
              a {
                color: #007bff;
                &:hover {
                  color: #0056b3;
                }
              }
            }
            p {
              @include font(15);
              color: rgba($black, 0.5%);
              line-height: 1.3;
              margin-bottom: 0;
              &.info-text {
                color: $blackColor;
              }
            }
            ul {
              padding: 0;
              li {
                display: inline-block;
                vertical-align: middle;
                padding-right: 20px;
                margin-bottom: 0;
                position: relative;
                &::after {
                  content: '';
                  position: absolute;
                  background: $black;
                  width: 5px;
                  height: 5px;
                  right: 5px;
                  top: 6px;
                  @include radius(50%);
                  z-index: 1;
                }
                &:last-child {
                  margin-right: 0;
                  padding-right: 0;
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      &.similar {
        ul {
          li {
            .pic-box {
              img {
                border-radius: 0;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
.MuiDialog-paperScrollPaper {
  width: 100%;
  .map-modal-wrap {
    background: $white;
    padding: 10px 15px 15px;
    position: relative;
    .mapclose-button {
      text-align: right;
      padding-bottom: 5px;
      button {
        background: none;
        padding: 0;
        outline: none;
        border: none;
        @include font(35);
        font-weight: 300;
        color: #cccccca6;
      }
    }
  }
}

.modal {
  &.myprogressmodal {
    padding-right: 0 !important;
    .modal-content {
      border: none;
      box-shadow: 0px 3px 20px #00000080;
      @include radius(8px);
      position: relative;
      .modal-body {
        padding: 25px 20px;
        .close {
          @include font(30);
          font-weight: 300;
          color: $black;
          margin: 0;
          outline: none;
          border: none;
          position: absolute;
          right: 15px;
          top: 15px;
          z-index: 1;
          span {
            display: inline-block;
            vertical-align: middle;
            line-height: 0;
          }
        }
        .form-group {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          &.flexbox {
            @include flex();
            align-items: center;
            padding-right: 20px;
            .logobox {
              width: 90px;
              margin-right: 20px;
              img {
                display: block;
                max-width: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
            .title {
              @include font(18);
              font-weight: 700;
              color: $blackColor;
              margin-bottom: 0;
            }
          }
          p {
            @include font(16);
            font-weight: 400;
            color: $black;
            line-height: 1.6;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            strong {
              font-weight: 700;
            }
            &.checkbox-group {
              .checkbox {
                display: inline-block;
                vertical-align: middle;
                position: relative;
                input[type='checkbox'] {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  opacity: 0;
                  z-index: 1;
                  @include radius(4px);
                  cursor: pointer;
                  &:checked {
                    + label {
                      &::before {
                        border-color: $lightBlue;
                      }
                      &::after {
                        content: '';
                        display: inline-block;
                        background: url(../../assets/img/checkBlue.png)
                          no-repeat;
                        background-size: cover;
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 3px;
                        top: 5px;
                      }
                    }
                  }
                }
                label {
                  padding-left: 30px;
                  position: relative;
                  @include font(16);
                  font-weight: 400;
                  color: $black;
                  line-height: 18px;
                  cursor: pointer;
                  margin-bottom: 0;
                  &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    left: 0;
                    top: 0;
                    border: 1px solid $border;
                    @include radius(2px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.recruiting-premium-modal {
    padding-right: 0 !important;
    .modal-content {
      border: none;
      box-shadow: 0px 3px 20px #00000080;
      @include radius(8px);
      position: relative;
      .modal-body {
        padding: 25px 20px;
        .close {
          @include font(30);
          font-weight: 300;
          color: $black;
          margin: 0;
          outline: none;
          border: none;
          position: absolute;
          right: 15px;
          top: 15px;
          z-index: 1;
          span {
            display: inline-block;
            vertical-align: middle;
            line-height: 0;
          }
        }
        .form-group {
          margin-bottom: 15px;
          text-align: center;
          &:last-child {
            margin-bottom: 0;
          }
          .logobox {
            padding: 0 0 15px;
            svg {
              display: inline-block;
              vertical-align: middle;
              width: 60px;
              margin: 0 auto;
            }
          }
          p {
            @include font(16);
            font-weight: 400;
            color: $black;
            line-height: 1.6;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .btn {
            padding: 10px 20px;
            min-width: 100px;
            @include font(16);
            font-weight: 400;
            margin: 0.35rem 0.35rem;
            &.lightblue {
              background: $lightBlue;
              color: $white;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .college-detail-banner-main {
    .banner-detail-box {
      .banner-detail-text {
        width: 480px;
        .college-banner-content {
          .title {
            @include font(21);
          }
        }
      }
    }
    .infoBoxFlex {
      width: calc(100% - 500px);
      .item {
        width: calc(33.333% - 7px);
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
        &:nth-child(4n + 4) {
          margin-right: 10px;
        }
      }
    }
  }
  .college-main-section {
    .college-tabs-wrapper {
      .tab-content {
        .college-tabs-content {
          .tab-content {
            .college-info-list {
              li {
                a {
                  @include font(16);
                }
              }
            }
          }
        }
      }
    }
  }
  .page-with-sidebar {
    .sidebar-box {
      .item-box {
        .title {
          @include font(15);
          line-height: 1.3;
        }
        ul {
          li {
            .detail-box {
              h4 {
                @include font(14);
              }
              ul {
                li {
                  @include font(13);
                  padding-right: 16px;
                  &::after {
                    right: 4px;
                    top: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .college-detail-banner-main {
    .banner-detail-box {
      display: block;
      .banner-detail-text {
        width: 100%;
      }
    }
    .infoBoxFlex {
      width: 100%;
      margin-top: 20px;
      .item {
        width: calc(25% - 8px);
        &:nth-child(4n + 4) {
          margin-right: 0;
        }
        &:nth-child(3n + 3) {
          margin-right: 10px;
        }
      }
    }
  }
  .collegeStripWrap {
    ul {
      li {
        margin-right: 20px;
        padding-right: 20px;
      }
    }
  }
  .college-main-section {
    .college-tabs-wrapper {
      .nav-tabs {
        li {
          margin-right: 5px;
          a {
            @include font(15);
          }
        }
      }
      .tab-content {
        .college-tabs-content {
          .tab-content {
            .stepperBox {
              .item {
                span {
                  @include font(12);
                }
              }
            }
            .college-info-list {
              li {
                a {
                  @include font(14);
                }
              }
            }
          }
        }
      }
    }
  }
  .recruiting-box-data {
    padding: 0;
    .top-block {
      h3 {
        @include font(22);
        margin-bottom: 5px;
      }
      p {
        @include font(16);
      }
    }
    .recruiting-list {
      li {
        padding: 20px 15px;
        .recruiting-profile-info {
          .profile-img {
            width: 60px;
            height: 60px;
          }
          .profile-detail {
            width: calc(100% - 75px);
          }
        }
        .recruiting-detail-box {
          .item-box {
            .recent-activity-info {
              @include font(12);
              p {
                @include flex();
                justify-content: space-between;
                span {
                  min-width: 1px;
                }
              }
            }
          }
        }
      }
    }
  }
  .page-with-sidebar {
    display: block;
    flex-direction: none;
    .sidebar-box {
      width: 100%;
      margin: 0 0 15px;
    }
  }
}
@media screen and (max-width: 767px) {
  .college-detail-banner-main {
    .container {
      max-width: 100%;
    }
    .banner-detail-box {
      .banner-detail-text {
        .college-banner-content {
          .title {
            @include font(20);
          }
          .address-info {
            li {
              margin-bottom: 14px;
              a {
                @include font(14);
              }
            }
          }
        }
      }
      .banner-wishlist-box {
        width: 60px;
      }
    }
  }
  .collegeStripWrap {
    ul {
      li {
        padding-right: 15px;
        margin-right: 15px;
        @include font(13);
      }
    }
  }
  .college-main-section {
    .college-tabs-wrapper {
      .nav-tabs {
        li {
          a {
            @include font(13);
            padding: 12px 0;
          }
        }
      }
      .tab-content {
        .stepperBox {
          .item {
            span {
              @include font(11);
            }
            &.current {
              span {
                &.circle {
                  &::after,
                  &::before {
                    bottom: -43px;
                  }
                }
              }
            }
          }
        }
        .college-tabs-content {
          padding: 15px 15px 0;
          .tab-content {
            .college-info-list {
              li {
                float: none;
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;
                .info-flex {
                  @include font(14);
                  line-height: 1.3;
                  span {
                    img {
                      height: 22px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .recruiting-box-data {
    padding: 10px;
    .top-block {
      h3 {
        @include font(20);
        margin-bottom: 5px;
      }
      p {
        @include font(15);
      }
    }
    .recruiting-list {
      li {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-top: 25px;
        min-height: 1px;
        .recruiting-profile-info {
          .profile-img {
            width: 80px;
            height: 80px;
          }
          .detail-box {
            width: calc(100% - 95px);
          }
        }
      }
    }
  }
  .committed-data {
    .committed-athlete-list {
      display: block;
      li {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        .pic-box {
          width: 70px;
          img {
            width: 70px;
            height: 70px;
          }
          .checkIcon {
            right: -10px;
            img {
              display: inline-block;
              vertical-align: middle;
              width: 22px;
              height: 22px;
            }
          }
        }
        .detail-box {
          @include font(14);
          .title {
            @include font(15);
          }
        }
      }
    }
  }
  .page-with-sidebar {
    .admissions-data {
      .item {
        ul {
          li {
            margin-bottom: 15px;
            @include font(14);
            line-height: 1.1;
            span {
              @include font(14);
              line-height: 1.1;
              &:first-child {
                min-width: 90px;
              }
            }
            .map-wrap {
              .map-data {
                line-height: 1.3;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 579px) {
  .college-detail-banner-main {
    padding: 25px 0;
    .banner-detail-box {
      .banner-detail-text {
        display: block;
        text-align: center;
        .college-banner-logo {
          width: 100px;
          margin: 0 auto 20px;
          img {
            @include radius(50%);
            padding: 15px;
          }
          .college-count {
            left: 15px;
            top: -8px;
          }
          .followBtn {
            margin-top: 20px;
          }
        }
        .college-banner-content {
          width: 100%;
          .title {
            @include font(16);
            text-align: center;
            justify-content: center;
          }
          .address-info {
            li {
              margin-bottom: 12px;
              a {
                @include font(13);
              }
            }
          }
          .collegefollowersInfo {
            justify-content: center;
          }
        }
      }
      .infoBoxFlex {
        justify-content: space-between;
        .item {
          display: none;
          width: calc(50% - 5px);
          margin-right: 0px;
          max-width: 100%;
          &:nth-child(1) {
            @include flex();
          }
          &:nth-child(2) {
            @include flex();
          }
        }
        &.hide {
          .item {
            display: flex !important;
          }
        }
        .showhideBox {
          display: block;
        }
      }
    }
  }
  .collegeStripWrap {
    ul {
      li {
        @include font(14);
        text-align: center;
        padding-right: 10px;
        margin-right: 10px;
        span {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
  .college-main-section {
    padding-bottom: 30px;
    .college-tabs-wrapper {
      .nav-tabs {
        overflow: hidden;
        li {
          float: left;
          width: 32%;
          margin-right: 2%;
          &:nth-child(3n + 3) {
            margin-right: 0;
          }
          &:nth-child(3n + 4) {
            clear: both;
          }
          a {
            @include font(13);
          }
        }
      }
      .tab-content {
        .tab-pane {
          overflow: hidden;
        }
        .college-tabs-content {
          .tab-content {
            .college-info-list {
              li {
                .info-flex {
                  @include font(13);
                }
              }
            }
          }
        }
        .stepperBox {
          width: 100%;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          overflow-y: scroll;
          padding-bottom: 10px;
          &::-webkit-scrollbar {
            width: 8px;
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba($gray, 0.8%);
          }
          .item {
            padding: 0 5px;
            min-width: 70px;
            &.current {
              span {
                &.circle::after,
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal {
    &.myprogressmodal {
      .modal-content {
        .modal-body {
          .form-group {
            margin-bottom: 15px;
            &.flexbox {
              .logobox {
                width: 60px;
                margin-right: 15px;
              }
              .title {
                @include font(16);
              }
            }
            p {
              @include font(15);
              &.checkbox-group {
                .checkbox {
                  label {
                    @include font(15);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .college-detail-banner-main {
    .banner-detail-box {
      .banner-detail-text {
        .college-banner-content {
          .title {
            @include font(16);
            margin-bottom: 10px;
          }
          .address-info {
            li {
              a {
                @include font(12);
              }
            }
          }
        }
      }
      .banner-wishlist-box {
        width: 30px;
      }
    }
  }
  .recruiting-box-data {
    padding-left: 0;
    padding-right: 0;
    .top-block {
      h3 {
        @include font(18);
      }
      p {
        @include font(14);
      }
    }
    .recruiting-list {
      li {
        .recruiting-profile-info {
          margin-bottom: 20px;
          .profile-img {
            width: 60px;
            height: 60px;
          }
          .profile-detail {
            @include font(13);
            width: calc(100% - 75px);
            .name {
              @include font(13);
              margin-bottom: 6px;
              span {
                @include font(13);
              }
            }
            .position {
              margin-bottom: 6px;
            }
          }
        }
        .recruiting-detail-box {
          .item-box {
            @include font(13);
            margin-bottom: 15px;
            h4 {
              @include font(14);
            }
            .recent-activity-info {
              @include font(12);
            }
          }
        }
      }
    }
  }
}
