/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
footer .contactSection {
  background-color: #343434;
  padding: 15px 0px;
}

footer .contactSection .logoSection {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

footer .contactSection .flexBox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer .contactSection .flexBox .social ul,
footer .contactSection .flexBox .contact ul {
  margin: 0px 0px;
  padding: 0px 0px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

footer .contactSection .flexBox .social ul li,
footer .contactSection .flexBox .contact ul li {
  list-style: none;
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}

footer .contactSection .flexBox .social ul li span,
footer .contactSection .flexBox .contact ul li span {
  display: inline-block;
}

footer .contactSection .flexBox .social ul li a,
footer .contactSection .flexBox .contact ul li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
}

footer .contactSection .flexBox .social ul li a:hover svg,
footer .contactSection .flexBox .contact ul li a:hover svg {
  fill: white;
}

footer .contactSection .flexBox .social ul li a:hover svg .a,
footer .contactSection .flexBox .contact ul li a:hover svg .a {
  fill: white;
}

footer .contactSection .flexBox .social ul li a svg,
footer .contactSection .flexBox .contact ul li a svg {
  fill: rgba(255, 255, 255, 0.5);
}

footer .contactSection .flexBox .social ul li a svg .a,
footer .contactSection .flexBox .contact ul li a svg .a {
  fill: rgba(255, 255, 255, 0.5);
}

footer .contactSection .flexBox .social ul li:last-child,
footer .contactSection .flexBox .contact ul li:last-child {
  margin-right: 0px;
}

footer .contactSection .flexBox .social ul li.phone span,
footer .contactSection .flexBox .contact ul li.phone span {
  font-size: 30px;
  font-weight: normal;
}

footer .comingSoonSection {
  background: #F5F5F5;
  padding-top: 25px;
}

footer .comingSoonSection .flexBox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer .comingSoonSection .flexBox .infoFlexBox {
  width: calc(100% - 275px);
  margin-left: auto;
  padding-left: 30px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

footer .comingSoonSection .flexBox .infoFlexBox .info {
  padding-bottom: 20px;
}

footer .comingSoonSection .flexBox .infoFlexBox .info h4 {
  font-size: 23px;
  color: #3e3e3e;
  margin: 0px 0px 10px 0px;
  font-weight: bold;
}

footer .comingSoonSection .flexBox .infoFlexBox .info h5 {
  font-size: 18px;
  color: #3e3e3e;
  margin: 0px 0px 10px 0px;
  font-weight: 500;
}

footer .comingSoonSection .flexBox .infoFlexBox .info p {
  font-size: 14px;
  color: #3e3e3e;
  font-weight: normal;
  margin: 0px 0px;
}

footer .comingSoonSection .flexBox .infoFlexBox .action {
  margin-left: auto;
}

footer .comingSoonSection .flexBox .infoFlexBox .action a {
  margin-right: 15px;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 15px;
}

footer .comingSoonSection .flexBox .infoFlexBox .action a:last-child {
  margin-right: 0px;
}

footer .footerMenuSection {
  background: #343434;
  padding-top: 40px;
}

footer .footerMenuSection .flexBox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -15px;
  padding: 0 15px;
}

footer .footerMenuSection .flexBox .menu {
  width: calc(25% - 10px);
  padding: 30px 15px;
}

footer .footerMenuSection .flexBox .menu .heading {
  font-size: 16px;
  margin: 0px 0px 10px 0px;
  color: #00B9FF;
  font-weight: normal;
}

footer .footerMenuSection .flexBox .menu ul {
  margin: 0px 0px;
  padding: 0px 0px;
}

footer .footerMenuSection .flexBox .menu ul li {
  list-style: none;
  margin: 0px 0px 10px 0px;
}

footer .footerMenuSection .flexBox .menu ul li a {
  display: inline-block;
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  padding: 10px 0px;
  color: #ffffff;
  font-size: 14px;
}

footer .footerMenuSection .flexBox .menu ul li a::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 1px;
  height: 1px;
  background-color: #ffffff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

footer .footerMenuSection .flexBox .menu ul li a:hover::after {
  width: 100%;
  opacity: 1;
}

footer .footerBottom {
  padding: 25px 0px;
  background: #F5F5F5;
}

footer .footerBottom .heading {
  font-size: 16px;
  text-align: center;
  font-weight: normal;
  color: #3e3e3e;
  margin: 0px 0px 20px 0px;
}

footer .footerBottom .flexBox {
  border-bottom: 1px solid #ccc;
}

footer .footerBottom .flexBox ul {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

footer .footerBottom .flexBox ul li {
  list-style: none;
  margin-right: 30px;
  margin-bottom: 20px;
}

footer .footerBottom .flexBox ul li span {
  display: inline-block;
}

footer .footerBottom .flexBox ul li span img {
  max-width: 100%;
  height: auto;
}

footer .footerBottom .flexBox ul li svg .a,
footer .footerBottom .flexBox ul li svg .b,
footer .footerBottom .flexBox ul li svg .c {
  fill: #345475;
}

footer .footerBottom .flexBox ul li svg .c {
  font-size: 18px;
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
  letter-spacing: 0.015em;
}

footer .footerBottom .flexBox ul li svg .d,
footer .footerBottom .flexBox ul li svg .e {
  stroke: none;
}

footer .footerBottom .flexBox ul li svg .e {
  fill: #f5f5f5;
}

footer .footerBottom .flexBox ul li:last-child {
  margin-right: 0px;
}

footer .footerBottom .contactEmailFlexBox {
  margin: 20px 0px 10px 0px;
}

footer .footerBottom .contactEmailFlexBox ul {
  margin: 0px 0px;
  padding: 0px 0px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer .footerBottom .contactEmailFlexBox ul li {
  list-style: none;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

footer .footerBottom .contactEmailFlexBox ul li span {
  display: inline-block;
  color: #3e3e3e;
  font-size: 14px;
}

footer .footerBottom .contactEmailFlexBox ul li span.label {
  white-space: nowrap;
  margin-right: 5px;
}

footer .footerBottom .rightsTerms ul {
  margin: 0px 0px;
  padding: 0px 0px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer .footerBottom .rightsTerms ul li {
  list-style: none;
  font-size: 14px;
  color: #3e3e3e;
  padding-right: 15px;
  padding-left: 15px;
  border-right: 1px solid #3e3e3e;
  margin-bottom: 15px;
}

footer .footerBottom .rightsTerms ul li a {
  text-decoration: none;
  display: inline-block;
  color: #3e3e3e;
}

footer .footerBottom .rightsTerms ul li a:hover {
  color: #c0321f;
}

footer .footerBottom .rightsTerms ul li:nth-child(1) {
  padding-left: 0px;
}

footer .footerBottom .phoneBlock {
  text-align: center;
}

footer .footerBottom .phoneBlock span {
  display: inline-block;
  font-size: 16px;
  color: #3e3e3e;
  line-height: 22px;
}

footer .footerBottom .phoneBlock span .phone {
  color: #c0321f;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}

footer .chatIcon {
  position: fixed;
  /*left: 10px;*/
  right: 10px;
  bottom: 10px;
  z-index: 2;
}

footer .chatIcon a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  background-color: #00B9FF;
}

footer .chatIcon a svg {
  width: 30px;
  height: auto;
  fill: #ffffff;
}

@media screen and (max-width: 991px) {
  footer .comingSoonSection .flexBox .infoFlexBox {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  footer .comingSoonSection .flexBox .infoFlexBox .action {
    margin-left: 0px;
  }
  footer .footerMenuSection .flexBox .menu {
    width: calc(33.333% - 10px);
  }
  footer .footerMenuSection .flexBox .menu .heading {
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  footer .contactSection .flexBox {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  footer .comingSoonSection .flexBox {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  footer .comingSoonSection .flexBox .phoneImg {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  footer .comingSoonSection .flexBox .infoFlexBox {
    width: 100%;
    padding-left: 0px;
    margin-bottom: 20px;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  footer .comingSoonSection .flexBox .infoFlexBox .info {
    width: 100%;
    text-align: center;
  }
  footer .comingSoonSection .flexBox .infoFlexBox .action {
    width: 100%;
    text-align: center;
  }
  footer .footerMenuSection .flexBox .menu {
    width: calc(50% - 10px);
  }
}

@media screen and (max-width: 575px) {
  footer .footerMenuSection {
    padding-top: 15px;
  }
  footer .contactSection .flexBox .contact ul li.phone span {
    font-size: 20px;
  }
  footer .footerBottom .contactEmailFlexBox ul li span {
    font-size: 13px;
  }
  footer .footerBottom .rightsTerms ul li {
    font-size: 13px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 479px) {
  footer .footerMenuSection .flexBox .menu {
    width: 100%;
    padding-top:25px;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  footer .footerMenuSection .flexBox .menu ul li:last-child {
    margin-bottom: 0px;
  }
  footer .footerMenuSection .flexBox .menu:last-child {
    border-bottom: none;
  }
  footer .contactSection .flexBox .social ul {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  footer .contactSection .flexBox .social ul li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  footer .contactSection .flexBox .social ul li:nth-child(1) {
    width: 100%;
    margin-right: 0px;
    text-align: center;
    margin-bottom: 0px;
  }
  footer .contactSection .flexBox .contact ul {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  footer .contactSection .flexBox .contact ul li {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  footer .contactSection .flexBox .contact ul li:nth-child(1) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  footer .footerBottom .flexBox ul li {
    margin-right: 15px;
  }
  footer .footerBottom .flexBox ul li span {
    max-width: 130px;
  }
  footer .footerBottom .phoneBlock span {
    max-width: 160px;
  }
}
