@import "../../../../assets/css/mixin";
@import "../../../../assets/css/reset";
@import "../../../../assets/css/variables";

.toast-top-full-width {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  right: 20%;
  top: 8%;
}

.toast-top-college-progress {
  width: 300px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;

  &.toast-college-error {
    transform: translate(-50%, 0);
    top: 0%;
  }
}

.toast-message {
  text-align: center;
}

.toast-success {
  text-align: center;
}

body #toast-container>.toast-success {
  background-image: none !important;
}

body #toast-container>.toast-success .toast-message {
  display: inline;
  padding: 0 40px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
  background-repeat: no-repeat;
  background-position: left center;
}

.dashHeaderSection {
  background: #040404;
  @include flex();
  min-height: 60px;
  padding: 0 15px;
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 2;

  div {
    &.logoPart {
      @include flex();
      align-items: center;

      a {
        display: inline-flex;
        text-decoration: none;
        cursor: pointer;

        img {
          max-width: 100%;
          height: auto;

          &.mobile {
            display: none;
          }
        }
      }
    }

    &.searchBar {
      @include flex();
      align-items: center;
      padding: 0 10px 0 20px;

      .searchBox {
        @include flex();
        background: $white;
        padding: 0 10px;
        max-width: 195px;
        min-width: 195px;
        align-items: center;
        @include radius(30px);

        .icon {
          a {
            display: inline-flex;

            svg {
              .a {
                opacity: 0.3;
              }

              .b {
                fill: none;
                stroke: $blackColor;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 1.5px;
              }
            }
          }
        }

        input {
          padding: 6px 10px;
          width: calc(100% - 0px);
          @include radius(10px);
          border: none;
          outline: none;
          @include font(14);
          color: $black;
        }
      }
    }
  }

  nav {
    margin-left: auto;

    >ul {
      @include flex();
      height: 100%;
      margin: 0;
      padding: 0;

      li {
        list-style: none;

        a {
          display: inline-flex;
          align-items: center;
          text-decoration: none;
          padding: 10px;
          color: $white;
          text-decoration: none;
          height: 100%;
          @include font(14);

          .icon {
            display: inline-block;
            margin-right: 15px;
          }

          &:hover {
            background: $baseColor;
          }

          &.upgradeLink {
            background: $baseColor;
            height: calc(100% - 20px);
            @include radius(5px);
            margin-left: 10px;
            margin: 10px 0;
            text-transform: uppercase;
          }
        }

        .active {
          background: $baseColor !important;
        }

        &.emailLink {
          margin-left: 5px;

          a {
            position: relative;

            &:hover {
              background: transparent;
            }

            span {
              &.count {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: $baseColor;
                position: absolute;
                right: 5px;
                top: 10px;
                width: 15px;
                height: 15px;
                @include font(10);
                color: $white;
                @include radius(2px);
              }
            }
          }
        }

        &.notifyLink {
          margin-left: 5px;

          a {
            position: relative;

            &:hover {
              background: transparent;
            }

            span {
              &.count {
                display: inline-flex;
                background-color: $baseColor;
                align-items: center;
                justify-content: center;
                @include font(10);
                color: $white;
                position: absolute;
                right: 5px;
                top: 10px;
                width: 15px;
                height: 15px;
                @include radius(2px);
              }
            }
          }
        }

        .avtaar {
          @include flex();
          align-items: center;
          justify-content: center;
          background: $white;
          width: 40px;
          height: 40px;
          margin: 10px 0 10px 10px;
          @include radius(50%);

          span {
            display: inline-flex;
            color: $baseColor;
            @include font(14);
          }
        }

        &.mobileSearchIcon {
          display: none;

          .searchBtn {
            border: none;
            outline: none;
            background: transparent;
            padding: 5px;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            @include radius(0);

            svg {
              .a {
                fill: $white;
              }
            }
          }
        }

        &.sideBarMenu {
          display: none;

          button {
            &.btn {
              background-color: transparent;
              border: none;
              outline: none;
              padding: 10px;
            }
          }

          .sideMenu {
            position: fixed;
            right: -300px;
            height: 100%;
            width: 100%;
            max-width: 250px;
            z-index: 2;
            background: $white;
            border-left: 1px solid $gray;
            transition: all 0.5s ease-in-out;

            div {
              &.closeBtnBox {
                padding: 10px 15px;
                position: relative;
                text-align: right;

                &::after {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  bottom: 0;
                  left: 15px;
                  width: calc(100% - 30px);
                  height: 1px;
                  background-color: $gray;
                }

                button {
                  padding: 5px;
                  background-color: transparent;
                  border: none;
                  outline: none;

                  svg {
                    fill: $black;

                    .a {
                      fill: $black;
                    }
                  }
                }
              }

              &.profileFlexBox {
                @include flex();
                align-items: center;
                padding: 0 15px 15px 15px;
                position: relative;

                &::after {
                  content: "";
                  display: inline-block;
                  position: absolute;
                  bottom: 0;
                  left: 15px;
                  width: calc(100% - 30px);
                  height: 1px;
                  background-color: $gray;
                }

                div {
                  &.avtaar {
                    margin: 10px 10px 0 7px;
                    background-color: $bgLightGray;
                    width: 40px;
                    height: 40px;
                  }

                  &.info {
                    h5 {
                      margin: 0 0 5px 0;
                      @include font(16);
                      color: $black;
                      font-weight: 600;
                      line-height: 22px;
                    }
                  }
                }
              }
            }

            >ul {
              display: block;
              padding: 0 15px;
              max-height: calc(100% - 115px);
              overflow-y: auto;

              li {
                display: block;
                width: 100%;
                margin-bottom: 10px;
                position: relative;
                border-bottom: 1px solid $gray;

                a {
                  color: $black;
                  width: 100%;

                  span {
                    display: inline-block;

                    &.icon {
                      svg {
                        fill: $black;

                        .a {
                          fill: $black;
                        }
                      }
                    }
                  }

                  &:hover {
                    color: $white;
                  }

                  &.upgradeLink {
                    margin-left: 10px;
                    width: auto;
                    color: $white;
                  }
                }

                &:last-child {
                  margin-bottom: 0;
                  border-bottom: none;
                }
              }
            }

            &::after {
              content: "";
              display: inline-block;
              right: 0;
              position: fixed;
              width: 0;
              top: 0;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              transition: all 0.3s ease-in-out;
            }

            &.slideMenu {
              right: 0;

              &::after {
                content: "";
                display: inline-block;
                right: 250px;
                position: fixed;
                width: calc(100% - 250px);
                top: 0;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
    }
  }
}

.headerSection {
  padding: 10px;
  background: #333333;
  text-align: center;
}

@media screen and (max-width: 1199px) {
  .dashHeaderSection {
    div {
      &.logoPart {
        a {
          max-width: 165px;
          min-width: 165px;
        }
      }
    }

    nav {
      >ul {
        li {
          a {
            @include font(13);
          }

          &.emailLink {
            a {
              svg {
                width: 20px;
                height: auto;
              }
            }
          }

          &.notifyLink {
            a {
              svg {
                width: 20px;
                height: auto;
              }
            }
          }

          .avtaar {
            width: 35px;
            height: 35px;
            margin: 10px 0 10px 7px;

            span {
              @include font(13);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .dashHeaderSection {
    div {
      &.searchBar {
        display: none;
      }
    }

    nav {
      >ul {
        li {
          &.mobileSearchIcon {
            display: inline-flex;
            margin-left: 10px;
            align-items: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .dashHeaderSection {
    nav {
      >ul {
        li {
          display: none;

          &.emailLink {
            display: inline-flex;
          }

          &.notifyLink {
            display: inline-flex;
          }

          &.mobileSearchIcon {
            display: inline-flex;
            margin-left: 10px;
            align-items: center;
          }

          &.sideBarMenu {
            display: inline-flex;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .dashHeaderSection {
    div {
      &.logoPart {
        a {
          min-width: auto;
          max-width: 100%;

          img {
            display: none;

            &.mobile {
              display: block;
            }
          }
        }
      }
    }
  }

  .footerSection {
    ul {
      li {
        margin-right: 0;
        @include font(14);
      }
    }
  }

  .toast-top-full-width {
    width: 100%;
    transform: translate(-50%, -10%);
    top: 10%;
    left: 50%;
    right: auto;
  }

  .toast-message {
    @include font(14);
  }
}