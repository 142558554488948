@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";

.onBoardAddProfileSection {
  min-height: calc(100vh - 120px);
  padding: 20px 15px;
  @include flex();
  justify-content: center;
  margin-top:0px;
  .addProfileBlock {
    width: 100%;
    max-width: 420px;
    margin: 0px auto;
    @include radius(6px);

    .formSection {
      padding: 30px 30px;
      border: 1px solid $gray;
      width: 100%;
      @include radius(6px);
      .heading {
        font-size: 20px;
        font-weight: 600;
        margin: 0px 0px 30px 0px;
        font-weight: 600;
        text-align: center;
        @include flex();
        align-items: center;
        justify-content: center;
        color: $black;
        span {
          &.icon {
            display: inline-flex;
            margin-left: 10px;
            .a {
              fill: #00b9ff !important;
              stroke: none;
            }
            .b {
              fill: #fafafa;
            }
          }
        }
      }
      .imageBox {
        .detailBox {
          width: 100%;
          max-width: 286px;
          background-color: rgba(233, 233, 233, 0.3);
          min-height: 175px;
          @include radius(8px);
          margin: 100px auto 30px auto;
          padding: 10px 10px;
          @include flex();
          align-items: center;
          flex-direction: column;
          .imgUploadBox {
            width: 160px;
            height: 160px;
            @include radius(50%);
            background-color: $white;
            margin: -80px auto 40px auto;
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .imgBlock {
              border: 2px solid #00b9ff;
              background-color: $white;
              width: 160px;
              height: 160px;
              @include radius(50%);
              position: relative;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              input {
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                z-index: 1;
                cursor: pointer;
                opacity: 0;
              }
              img {
                max-width: 100%;
                height: auto;
                width: auto;
                margin-top: 16%;
                &.uploadedImg {
                  max-height: 155px;
                  object-fit: cover;
                  width: 100%;
                }
              }
            }

            span {
              &.checkIcon {
                display: inline-flex;
                position: absolute;
                bottom: -22px;
                left: 50%;
                transform: translateX(-50%);
                cursor: pointer;
                svg {
                  .a {
                    fill: #00b9ff;
                    stroke: #fff;
                  }
                  .b {
                    fill: #fafafa;
                  }
                }
              }
              &.editIcon {
                position: absolute;
                right: -6px;
                top: 65px;
                @include radius(50%);
                display: inline-flex;
                background-color: $gray;
                align-items: center;
                justify-content: center;
                padding: 5px 5px;
                width: 25px;
                height: 25px;
                cursor: pointer;
              }
            }
          }
          h5 {
            text-align: center;
            font-size: 18px;
            color: $blackColor;
            margin: 0px 0px 10px 0px;
            font-weight: 500;
            padding: 0px 10px;
          }
          p {
            text-align: center;
            font-size: 14px;
            color: $blackColor;
            margin: 0px 0px 10px 0px;
            font-weight: normal;
            padding: 0px 10px;
          }
        }
      }
      .info {
        color: $black;
        font-size: 14px;
        text-align: center;
        line-height: 20px;
      }
    }
    .skip {
      text-align: center;
      margin-top: 10px;
      .sikpBtnBox{
        display: inline-block;
      }
    }
    .skip a {
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      color: #c0321f;
    }
    .continueBtnBox {
      text-align: center;
      margin-top: 35px;
      a {
        outline: none;
        box-shadow: none;
        cursor: pointer;
        background-color: $green;
        color: $white;
        padding: 10px 15px;
        @include radius(4px);
        border: none;
        font-size: 16px;
        min-width: 100px;
        min-height: 45px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .onBoardAddProfileSection {
    .addProfileBlock {
      .formSection {
        padding: 20px 20px;
      }
      .continueBtnBox {
        a {
          width: 100%;
        }
      }
    }
  }
}
