@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/reset";

.team-main-wrapper {
  .team-top-block {
    text-align: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $gray;
    h3 {
      @include font(14);
      font-weight: 500;
      color: $blackColor;
      margin-bottom: 10px;
    }
    .short-text {
      max-width: 560px;
      margin: 0 auto;
      @include font(13);
      font-weight: 300;
      color: $blackColor;
      line-height: 1.6;
      p {
        margin-bottom: 15px;
      }
      .btn {
        @include font(14);
        font-weight: 300;
        padding: 12px 16px;
        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
          path {
            fill: $white;
          }
        }
      }
    }
  }
  .team-coaches-wrapper {
    .team-coaches-top {
      margin-bottom: 25px;
      .team-flex-item {
        align-items: center;
      }
    }
    .team-flex-box {
      @include flex();
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      .team-button-wrapper {
        .btn {
          display: inline-block;
          vertical-align: middle;
          width: 36px;
          height: 36px;
          line-height: 45px;
          @include radius(36px);
          margin-right: 8px;
          text-align: center;
          padding: 0;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .team-flex-item {
      width: 80%;
      @include flex();
      .icon-box {
        width: 40px;
        height: 40px;
        @include radius(50px);
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content-box {
        @include font(12);
        width: calc(100% - 55px);
        h4 {
          @include font(14);
          font-weight: 400;
          color: $blackColor;
          margin-bottom: 5px;
        }
        .itemCheckbox {
          @include flex();
          align-items: center;
          margin-bottom: 5px;
          input {
            margin-right: 5px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .pending-text {
          background-position: left 7px;
          color: $baseColor;
          font-weight: 300;
          text-transform: uppercase;
          padding-top: 8px;
        }
      }
    }
  }
  .form-button-box {
    &.with-next-prev {
      margin-top: 20px;
      padding-top: 15px;
      border-top: 1px solid $gray;
    }
  }
  .next-prev-button {
    button {
      display: inline-block;
      vertical-align: middle;
      background: none;
      border: none;
      @include font(16);
      font-weight: 400;
      color:rgba($black, .6);
      padding: 0;
      margin-right: 10px;
      @include transition();
      svg {
        width: 18px;
        height: 18px;
        margin-top: -1px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: $baseColor;
      }
    }
  }
  .coaches-main-wrapper {
    background: rgba($gray, 0.2%);
    padding: 20px 25px;
    .coache-listing {
      .team-flex-item {
        margin-bottom: 20px;
      }
      .showMoreList{
        margin-left: 55px;
        font-size: 14px;
        color: #c0321f;
        cursor: pointer;
        margin-bottom: 20px;
        svg{
          width: 11px !important;
          height: 7px !important;
          margin-left: 5px;
          margin-bottom: 3px;
          fill: #c0321f;
        }
        .showLess{
          width: 11px !important;
          height: 7px !important;
          margin-left: 5px;
          margin-bottom: 3px;
          fill: #c0321f;
          transform: rotate(180deg);
        }
      }
      .show-less-link {
        margin-bottom: 25px;
        padding-left: 55px;
        a.showMore {
          display: inline-block;
          vertical-align: middle;
          @include font(14);
          font-weight: 400;
          color: $baseColor;
          text-decoration: none;
          position: relative;
          padding-right: 20px;

          &:after {
            content: "Show More";
          }

          &:before {
            content: "";
            position: absolute;
            top: 2px;
            right: 3px;
            width: 8px;
            height: 8px;
            border-right: 2px solid $baseColor;
            border-bottom: 2px solid $baseColor;
            display: inline-block;
            transform: rotate(45deg);
          }
          &.showLess:after {
            content: "Less More";
          }

          &.showLess:before {
            content: "";
            position: absolute;
            top: 5px;
            right: 3px;
            width: 8px;
            height: 8px;
            border-right: 2px solid $baseColor;
            border-bottom: 2px solid $baseColor;
            display: inline-block;
            transform: rotate(-135deg);
          }
        }
      }
    }
    .link-text {
      display: inline-block;
      vertical-align: middle;
      text-decoration: underline;
      cursor: pointer;
      @include font(14);
      color: $green;
      &:hover {
        color: $baseColor;
      }
    }
  }
}
.team-common-form {
  h3 {
    @include font(14);
    font-weight: 500;
    color: $blackColor;
    margin-bottom: 10px;
  }
  .form-button-box {
    .text-box {
      text-align: left;
      padding-bottom: 20px;
      @include font(14);
      color: $blackColor;
      p {
        line-height: 1.5;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .continue-link {
        display: inline-block;
        vertical-align: middle;
        text-decoration: underline;
        @include font(13);
        color: $green;
        @include transition();
        &.color-red {
          color:$baseColor;
        }
        &:hover {
          color: $orange;
        }
      }
    }
    .btn {
      @include font(14);
      font-weight: 300;
      padding: 11px 15px;
      &.orange {
        background: $orange !important;
        color:$white
      }
    }
  }
}
.connect-with-coaches-top-box {
  @include flex();
  align-items: center;
  margin-bottom: 20px;
  .icon-box {
    width: 40px;
    margin-right: 10px;
    img {
      max-width: 100%;
    }
  }
  .content-box {
    width: calc(100% - 40px);
    @include font(12);
    font-weight: 300;
    color: $blackColor;
    h4 {
      @include font(14);
      font-weight: 400;
      color: $blackColor;
      margin-bottom: 4px !important;
    }
  }
}
.coaching-staff-content-box {
  margin-bottom: 20px;
  h4 {
    @include font(14);
    font-weight: 400;
    color: $blackColor;
    margin-bottom: 5px !important;
  }
  p {
    @include font(12);
    font-weight: 300;
    color: $blackColor;
    line-height: 1.5;
  }
}
.invite-coach-wrap {
  .notification-setting-box {
    margin-bottom: 20px;
    .notification-content {
      padding-bottom: 20px !important;
    }
    .fieldBox {
      margin-bottom: 10px;
    }
    p {
      @include font(14);
      font-weight: 400;
      a {
        display: inline-block;
        vertical-align: middle;
        text-decoration: underline;
        color: $green;
        cursor: pointer;
        &:hover{
          text-decoration: none;
          color: $green;
        }
      }
    }
  }
  .button-box {
    @include flex();
    align-items: center;
    justify-content: space-between;
    .btn {
      @include font(15);
      font-weight: 300;
      color: $white;
      padding: 10px 15px;
      min-width: 100px;
      &:hover {
        color: $white;
      }
    }
  }
}
.coches-request-wrap {
  .connect-with-coaches-top-box {
    justify-content: space-between;
    .item {
      width: 60%;
      @include flex();
      justify-content: space-between;
      align-items: center;
      margin-right: 15px;
    }
    .request-massage-box {
      width: calc(100% - 60%);
      background: rgba($yellow, 0.7%);
      padding: 1em 3.5em 1em 1em;
      @include font(14);
      @include radius(4px);
      position: relative;
      p {
        line-height: 1.3;
      }
      .close-button {
        background: transparent;
        border: none;
        outline: none;
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -10px;
        z-index: 1;
      }
    }
  }
  .coache-listing {
    padding-top: 15px;
    .showMoreList{
      margin-left: 55px;
      font-size: 14px;
      color: #c0321f;
    }
    .team-flex-item {
      @include flex();
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .item-content-box {
        @include flex();
        margin-right: 15px;
        .icon-box {
          flex: 0 0 40px;
          width: 40px;
          height: 40px;
          margin-right: 10px;
          overflow: hidden;
          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
        .content-box {
          @include font(12);
          font-weight: 300;
          width: calc(100% - 40px);
          h4 {
            @include font(14);
            font-weight: 400;
            color: $black;
            margin-bottom: 2px !important;
            line-height: 1;
          }
          
          .gray-text {
            color: #9d9d9d;
            padding-top: 2px;
          }
        }
      }
      .resend-request-button {
        @include flex();
        justify-content: end;
        .btn {
          padding: 10px 15px;
          @include font(13);
          font-weight: 300;
          min-width: 90px;
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
          svg {
            display: inline-block;
            vertical-align: middle;
            height: 18px;
            margin-right: 5px;
          }

        }
      }
    }
  }
  &.teams-seach {
    padding: 15px;
    border: 1px solid $gray;
    @include radius(4px);
    .coache-listing {
      padding-right: 15px;
      overflow-y: scroll;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        border-radius: 10px;
        width: 8px;
        background: rgba($gray, 0.3%);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba($gray, 0.8%);
        cursor: pointer;
      }
      &.overflow-hidden {
        overflow: hidden;
        padding-right: 0;
      }
      padding-top: 0;
      .showMoreList{
        margin-left: 55px;
        font-size: 14px;
        color: #c0321f;
      }
      .team-flex-item {
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid $gray;
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        .item-content-box {
          align-items: center;
          margin-right: 15px;
          h4 {
            margin-bottom: 5px!important;
            font-weight: 500;
            span {
              display: inline-block;
              vertical-align: middle;
              @include font(13);
              color: rgba($blackColor, .5);
            }
          }
          p {
            margin-top:5px;
          }
        }
        .resend-request-button .btn {
          padding: 6px 15px;
          min-width: 70px;
        }
      }
    }
    &.events-search {
      padding: 0;
      border:none;
      border-radius: none;
      .title {
        @include font(15);
        font-weight:500;
        color:$blackColor;
        margin-bottom: 15px;
      }
      .coache-listing {
        height: 470px;
        .team-flex-item {
          padding: 15px;
          border:1px solid $gray;
          @include radius(4px);
          margin-bottom: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 579px) {
  .team-main-wrapper {
    .team-top-block {
      .short-text {
        p {
          br {
            display: none;
          }
        }
      }
    }
    .team-coaches-wrapper {
      .team-coaches-top {
        .team-flex-item {
          width: 60%;
        }
      }
    }
    .coaches-main-wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .coches-request-wrap {
    &.teams-seach {
      padding-left: 10px;
      padding-right: 10px;
      .coache-listing {
        padding-right: 10px;
        .team-flex-item {
          .item-content-box {
            margin-right: 15px;
            .content-box {
              p {
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
    .connect-with-coaches-top-box {
      display: block;
      .item {
        width: 100%;
      }
      .request-massage-box {
        width: 100%;
        margin-top: 15px;
      }
    }
    .coache-listing {
      .team-flex-item {
        .resend-request-button {
          flex-direction: column;
          .btn  {
            padding: 5px 10px;
            min-width: 80px;
            margin: 0 0 5px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .team-common-form {
    .form-button-box {
      .text-box {
        padding-bottom: 15px;
      }
    }
  }
  
}
