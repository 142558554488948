// @import "../../../assets/css/variables";
// @import "../../../assets/css/mixin";
// @import "../../../assets/css/reset";
@import "../../../../../assets/css/variables";
@import "../../../../../assets/css/mixin";
@import "../../../../../assets/css/reset";

.privacy-policy-content {
    padding: 45px 0 30px;
    margin-top: 60px;
    .pagelinks {
        padding-bottom: 15px;
        margin-bottom: 30px;
        border-bottom: 1px solid $gray;
        .links {
            @include flex();
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            li {
                margin: 0 15px;
                a {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: none;
                    @include font(20);
                    font-weight: 400;
                    color: $black;
                    position: relative;
                    @include transition();
                    &.active, &:hover {
                        font-weight: 500;
                        color:$baseColor;
                        &::after {
                            content:"";
                            background: $baseColor;
                            width: 100%;
                            height: 2px;
                            position: absolute;
                            right: 0;
                            left: 0;
                            bottom: -15px;
                            z-index:1;
                        }
                    }
                    &:hover {
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .contentwrap {
        @include font(16);
        font-weight: 400;
        color:$black;
        line-height: 30px;
        h1, h2, h3, h4, h5, h6, p, ul, ol {
            margin-bottom: 15px;
        }
        h1, h2, h3 {
            @include font(24);
            font-weight: 600;
            color:$blackColor;
        }
        h4, h5, h6 {
            @include font(18);
            font-weight: 600;
            color:$blackColor;
        }
        p {
            overflow-wrap: anywhere;
            a {
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
                color:$baseColor;
                font-weight: 500;
                &:hover {
                    color: #b12c1a;
                }
            }
        }
        ul {
            li {
                padding-left: 40px;
                margin-bottom: 10px;
                position: relative;
                &::after {
                    content:"";
                    background:rgba($black, .8);
                    position: absolute;
                    width:8px;
                    height: 8px;
                    left: 20px;
                    top: 10px;
                    @include radius(50px);
                    z-index: 1;
                }
                .list-type-point {
                    margin-top: 10px;
                }
                a {
                    font-weight: 500;
                    color:$baseColor;
                    &:hover {
                        color: #b12c1a;
                    }
                }
            }
            &.list-type-alphabetic {
                padding-left: 40px;
                li {
                    list-style-type: lower-alpha;
                    padding-left: 0;
                    &::after {
                        display: none;
                    }
                    &::marker {
                        font-weight: 700;
                    }
                }
            }
            &.list-type-point {
                padding-left: 40px;
                li {
                    list-style-type: lower-roman;
                    padding-left: 0;
                    &::after {
                        display: none;
                    }
                    &::marker {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:1199px) {
    .privacy-policy-content {
        .pagelinks {
            .links {
                li {
                    a {
                        @include font(17);
                    }
                }
            }
        }
    }
}
@media screen and (max-width:991px) {
    .privacy-policy-content {
        .pagelinks {
            .links {
                li {
                    margin: 0 10px;
                }
            }
        }
        .contentwrap {
            h1, h2, h3 {
                @include font(22);
            }
        }
    }
}
@media screen and (max-width:639px) {
    .privacy-policy-content {
        padding-top: 30px;
        .contentwrap {
            @include font(15);
            line-height: 26px;
            h1, h2, h3 {
                line-height:1.333;
                @include font(20);
            }
            h4, h5, h6 {
                line-height:1.333;
            }
            ul {
                li {
                    padding-left: 25px;
                    &:after {
                        left: 10px;
                    }
                }
                &.list-type-alphabetic {
                    padding-left: 25px;
                }
                &.list-type-point {
                    padding-left: 25px;
                }
            }
        }
    }
}