@import "../../../../../../../assets/css/mixin"; 
@import "../../../../../../../assets/css/reset";
@import "../../../../../../../assets/css/variables";

.preference-data-sec {
    margin-top: 60px;
    background:$bgLightGray;
    padding: 25px 0px;
    .headerFlex {
        @include flex();
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0px auto 20px;
        .tabs {
            width: calc(100% - 176px);
            ul {
                @include flex();
                margin:0px 0px;
                padding:0px 15px;
                justify-content: space-around;
                background: $white;
                @include radius(4px);
                border:1px solid $gray;
                width: 100%;
                height:100%;
                li {
                    list-style: none;
                    margin-right: 20px;
                    a {
                        text-decoration: none;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        font-size: 18px;
                        color: $black;
                        height:100%;
                        padding: 10px 10px;
                        span {
                            &.icon {
                                display: inline-flex;
                                position: absolute;
                                right: -5px;
                                top:5px;
                                svg {
                                    fill:$black;
                                    .a {
                                        fill:$black;  
                                    }
                                }
                            }
                        }
                        &:hover {
                            color:$baseColor;
                           &::after {
                               content:"";
                               display: inline-block;
                               position:absolute;
                               left:0px;
                               bottom:0px;
                               width:100%;
                               height:1px;
                               background: $baseColor;
                           }
                        }
                        &.selected {
                            color: $baseColor;
                            &::after {
                                content:"";
                                display: inline-block;
                                position:absolute;
                                left:0px;
                                bottom:0px;
                                width:100%;
                                height:1px;
                                background: $baseColor;
                            }
                            span {
                                svg {
                                    path {
                                        fill: $baseColor!important;
                                    }
                                }
                            }
                        }
                    }
                    
                    &:last-child {
                        margin-right: 0px;
                    }

                }
            }
            .mobileMenuBox {
                display: none;
                align-items: center;
                background: $white;
                @include radius(4px);
                border: 1px solid #00000038;
                padding: 0px 10px 0px 0px;
                div {
                    &.menuBtn {
                        .btn {
                            border: none;
                            outline: none;
                            padding: 5px 5px;
                            min-height: 20px;
                            min-width: 20px;
                            @include flex();
                            align-items: center;
                            justify-content: center;
                            background: transparent;
                            margin: 10px 15px 10px 0px;
                            svg {
                                .a {
                                    fill:#343434;
                                }
                            }
                        }
                    }
                    &.label {
                       span {
                        color: $baseColor;
                        display: inline-block;
                        font-size: 14px;
                       }
                    }
                    &.mobileMenu {
                        transition: all 0.5s ease-in-out;
                        position: fixed;
                        top: 0;
                        height: 100%;
                        left: -300px;
                        width: 100%;
                        max-width: 250px;
                        overflow-y: auto;
                        background: $white;
                        z-index: 999;
                        border-right: 1px solid $gray;
                        .closeBtnBox {
                            text-align: right;
                            padding: 15px 15px;
                            button {
                                &.closebtn {
                                    background: transparent;
                                    border:none;
                                    outline: none;
                                    padding: 5px 5px;
                                    svg {
                                        .a {
                                            opacity:0.7; 
                                            fill: $black;
                                        } 
                                    }
                                }
                            }
                        }
                        ul {
                            display: block;
                            padding: 0px 15px;
                            border: none;
                            @include radius(0px);
                            height: auto;
                            max-height: calc(100% - 60px);
                            overflow-y: auto;
                            li {
                                list-style: none;
                                padding-bottom: 10px;
                                margin-bottom: 15px;
                                border-bottom: 1px solid $gray;
                                margin-right: 0px;
                                a {
                                    text-decoration: none;
                                    @include flex();
                                    justify-content: flex-start;
                                    border: none;
                                    span {
                                        display: inline-flex;
                                        position: relative;
                                        line-height: 22px;
                                        &.icon{
                                            display: inline-flex;
                                            margin-top: -10px;
                                        }
                                    }
                                    &.selected {
                                        color: $baseColor;
                                    }
                                    &::after {
                                        display:none;
                                    }
                                    &:hover {
                                        color: $baseColor;
                                    }
                                }
                                &:last-child {
                                    margin-bottom: 0px;
                                    border-bottom: none;
                                }
                            }
                        }
                        &::after {
                            content: "";
                                display: inline-block;
                                position: fixed;
                                width: 0px;
                                left: 0px;
                                height: 100%;
                                top: 0;
                                background-color: rgba(0,0,0,0.5);
                                transition: all 0.5s ease-in-out;
                        }
                        &.expandMenu {
                            left: 0px;
                       
                            &::after {
                                content: "";
                                display: inline-block;
                                position: fixed;
                                width: calc(100% - 250px);
                                left: 250px;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                background-color: rgba(0,0,0,0.5);
                           
                            }
                        }
                    }
                }
            }
        }
        .prefer {
            a {
                text-decoration: none;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                font-size: 18px;
                color:$black;
                padding:15px 15px;
                @include radius(4px);
                border: 1px solid $gray;
                background-color:$white;
                margin-left: 10px;
                &.selected {
                    color: $baseColor;
                    span {
                        &.icon {
                            svg {
                                fill:$baseColor;
                                .a {
                                    fill:$baseColor; 
                                }
                            }   
                        }
                    }
                }
                span {
                    display: inline-block;
                    &.icon {
                        margin-right:10px;
                        svg {
                            fill:$black;
                            .a {
                                fill:$black; 
                            }
                        }  
                    }
                }
            }
        }
    }
    .preference-box-wrap {
        background: $white;
        padding: 20px;
        border:1px solid $gray;
        @include radius(5px);
        .top-block-wrap {
            @include font(15);
            font-weight: 300;
            padding-bottom: 20px;
            margin-bottom:20px;
            border-bottom: 1px solid $gray;
            position: relative;
            padding-right: 385px;
            .title {
                @include font(18);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 10px;
                + p {
                    line-height: 1.6;
                }
            }
            .btn-wrap {
                position: absolute;
                right: 0;
                top: 0;
                .btn {
                    padding: 6px 15px;
                    @include font(15);
                    font-weight: 300;
                    letter-spacing: 0.27px;
                    line-height: 26px;
                    min-width: 70px;
                    cursor: pointer;
                    &.blue {
                        background: $lightBlue;
                        //margin-right: 10px;
                    }
                    &.green {
                        margin-right: 10px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .preference-list {
            .field-box-item {
                padding-bottom: 25px;
                margin-bottom: 20px;
                border-bottom: 1px solid $gray;
                &:last-child{
                    padding-bottom: 5px;
                    margin-bottom: 0;
                    border-bottom: none;
                }
                h4 {
                    @include font(18);
                    font-weight: 500;
                    color: $black;
                    margin-bottom: 20px;
                }
                h5 {
                    @include font(16);
                    font-weight: 500;
                    color: $black;
                    margin-bottom: 20px;
                }
                .fieldBox {
                    margin-bottom: 20px;
                    width: 100%!important;
                    word-break: anywhere;
                    line-height: 1.333;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .more-text {
                        color: #00B9FF;
                    }
                    .inputfield {
                        max-width: 325px;
                        @include font(14);
                        font-weight: 300;
                        color: $black;
                        width: 100%;
                        border: 1px solid $border;
                        @include radius(4px);
                        padding: 0 1em;
                        height: 50px;
                        box-shadow: none;
                        overflow: scroll;
                    }
                    .search-box-wrap {
                        max-width: 325px;
                        position: relative;
                        input {
                            @include font(14);
                            font-weight: 300;
                            color: $black;
                            width: 100%;
                            border: 1px solid $border;
                            @include radius(4px);
                            padding: 0 1em;
                            height: 50px;
                            box-shadow: none;
                            padding-right: 40px;
                            background: url(../img/search-icon.svg) no-repeat;
                            background-position: bottom 17px right 12px;
                            &::placeholder {
                                color: $black;
                            } 
                        }
                    }
                    .checkbox-group {
                        li {
                            margin-bottom: 18px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .card-body{
                                border: 0;
                                padding-left: 25px;
                            }
                            .level {
                                display: inline-block;
                                vertical-align: middle;
                                position: relative;
                                padding-right: 35px;
                                .icon {
                                    position: absolute;
                                    right: 0;
                                    top: -8px;
                                    z-index: 1;
                                    transform: rotate(180deg);
                                    svg {
                                        width: 15px;
                                        height: 15px;
                                    }
                                }
                                &.collapsed{
                                    .icon{
                                        transform: rotate(0);
                                    }
                                }
                            } 
                        }
                        .checkbox {
                            display: inline-block;
                            vertical-align: middle;
                            position: relative;
                            input[type="checkbox"] {
                              position: absolute;
                              width: 100%;
                              height: 100%;
                              left: 0;
                              top: 0;
                              opacity: 0;
                              z-index: 1;
                              @include radius(4px);
                              cursor: pointer;
                              &:checked {
                                + label {
                                  &::before {
                                    border-color: $lightBlue;
                                  }
                                  &::after {
                                    content: "";
                                    display: inline-block;
                                    background: url(../img/checkBlue.png) no-repeat;
                                    background-size: cover;
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    left: 3px;
                                    top: 5px;
                                  }
                                }
                              }
                            }
                            label {
                              padding-left: 30px;
                              position: relative;
                              @include font(15);
                              font-weight: 300;
                              color: $black;
                              line-height: 18px;
                              cursor: pointer;
                              margin-bottom: 0;
                              &::before {
                                content: "";
                                display: inline-block;
                                position: absolute;
                                width: 18px;
                                height: 18px;
                                left: 0;
                                top: 0;
                                border: 1px solid $black;
                                @include radius(2px);
                              }
                            }
                        }
                    }
                }
            }
            .btn-wrap {
                .btn {
                    padding: 6px 15px;
                    @include font(15);
                    font-weight: 300;
                    letter-spacing: 0.27px;
                    line-height: 26px;
                    min-width: 70px;
                    &.blue {
                        background: $lightBlue;
                        //margin-right: 15px;
                    }
                    &.green {
                        margin-right: 10px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:1199px) {
    .preference-data-sec {
        .preference-box-wrap { 
            .top-block-wrap {
                .title {
                    + p {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:991px) {
    .preference-data-sec {
        .preference-box-wrap { 
            .top-block-wrap {
                padding-right: 0;
                .btn-wrap {
                    padding-top: 15px;
                    position: static!important;
                    top: auto!important;
                    right: auto!important;
                    z-index: 1;
                }
            }
        }
    }
}
@media screen and (max-width:767px) { 
    .pageContent {
        min-height: 1px!important;
    }
    .preference-data-sec {
        .headerFlex{
            .tabs{
                ul{
                    display: none;
                }
                .mobileMenuBox{
                    @include flex();
                }
            }
            .prefer{
                a{
                    padding: 12px 10px;
                }
            }
        }
        .preference-box-wrap {
            padding:20px 15px 15px;
        }
    }
}
@media screen and (max-width:579px) {
    .preference-data-sec {
        .headerFlex {
            .tabs{
                width: calc(100% - 55px);
                .mobileMenuBox{
                div{
                    &.menuBtn{
                        .btn{
                            margin: 9px 15px 9px 10px;
                        }
                    }
                }
               
            }
        }
            .prefer{
                a{
                    padding: 10px 10px;
                    min-width: 42px;
                    width: 42px;
                    height: 42px;
                    min-height: 42px;
                    justify-content: center;
                    span{
                        display: none;
                        &.icon{
                            display: inline-flex;
                            margin-right: 0px;
                        }
                    }
                }
            }  
        }
        .preference-box-wrap {
            .top-block-wrap {
                padding-right: 0;
                .btn-wrap {
                    .btn {
                        font-size: 13px!important;
                        padding: 6px 10px;
                        min-width: 50px;
                        margin-bottom: 5px;
                        &.green {
                            margin-right: 6px;
                            margin-left: 6px;
                        }
                    }
                }
            }
            .preference-list {
                .field-box-item {
                    h4 {
                        @include font(16);
                    }
                    h5 {
                        @include font(15);
                    }
                }
                .btn-wrap {
                    .btn {
                        font-size: 13px!important;
                        padding: 6px 10px!important;
                        min-width: 50px!important;
                        margin-bottom: 5px;
                        &.green {
                            margin-right: 6px;
                            margin-left: 6px;
                        }
                    }
                }
            }
        }
    }
}