@import '../../../../../../../assets/css/variables';
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.athleteDashFollowerSection{
    background-color: $bgLightGray;
    padding: 15px 0;
    margin-top: 60px;
    > .container{
        > .flexBox{
            @include flex();
            flex-wrap: wrap;
            .leftDataBox{
                width: 250px;
                .AthleteInfoBox{            
                    .AthleteInfo{
                        padding: 15px 15px;
                        background-image: url(../img/profileBg.png);
                        background-repeat: no-repeat;
                        background-size: auto;
                        position: relative;
                        .profileBox{
                            margin-top: 10px;
                            .imgBoxBlock{
                                background: $white;
                                width: 100px;
                                height: 100px;
                                margin: 0 auto;
                                @include radius(50%);
                                position: relative;
                                div{
                                    &.imgBox{
                                        width: 100px;
                                        height: 100px;
                                        overflow:hidden;
                                        @include radius(50%);
                                        border: 2px solid $white;
                                        cursor: pointer;
                                        a{
                                            @include flex();
                                            justify-content: center;
                                            align-items: center;
                                            text-decoration: none;
                                            @include radius(50%);
                                            img{
                                                width: 100px;
                                                height: 100px;                 
                                            }
                                        }
                                    }
                                    &.icon{
                                        position: absolute;
                                        right: -10px;
                                        bottom: 0;
                                        cursor: pointer;
                                        svg{
                                            .a {
                                                fill:#ff4a31;
                                            }
                                            .b {
                                                fill:$white;
                                            }
                                        }
                                    }
                                    .browseEditProfile{
                                        &.browse {
                                            position: absolute;
                                            bottom: 0;
                                            padding: 10px;
                                            left: 50%;
                                            @include translate(-50%, -35%);
                                            cursor: pointer;
                                            &:before {
                                                content: "+";
                                                position: absolute;
                                                background: $black;
                                                width: 17px;
                                                height: 17px;
                                                @include font(12);
                                                color: $white;
                                                font-weight: bold;
                                                right: 0;
                                                top: 0;
                                                line-height: 16px;
                                                text-align: center;
                                                @include radius(100%);
                                                z-index: 0;
                                            }
                                            input[type="file"] {
                                                position: absolute;
                                                width: 100%;
                                                height: 100%;
                                                opacity: 0;
                                                left: 0;
                                                top: 0;
                                                z-index: 3;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                            .playerName{
                                @include font(18);
                                color: $white;
                                text-align: center;
                                margin: 10px 0;
                            }
                            ul{
                                padding: 0;
                                margin: 0 0 20px 0 ;
                                li{
                                    @include flex();
                                    align-items: flex-start;
                                    justify-content: center;
                                    list-style: none;
                                    text-align: center;
                                    margin: 0 0 10px 0;
                                    span{
                                        display: inline-block;
                                        @include font(14);
                                        color: $white;
                                        line-height: normal;
                                        &.label{
                                            margin: 0 3px 0 0;
                                        }
                                    }
                                    &:last-child{
                                        margin: 0;  
                                    }
                                }
                            }
                        }
                    .flexBox{
                        @include flex();
                        justify-content: space-between;
                        border-top: 1px solid rgba(255, 255, 255,.5);
                        padding-top: 20px;
                        margin: 0;
                        li{
                            width: calc(33.3% - 10px);
                            list-style: none;
                            margin-right: 10px;
                            &:last-child{
                                margin-right: 0;
                            }
                            a {
                                text-decoration: none;
                            }
                            span{
                                display: block;
                                text-align: center;
                                &.label{
                                    @include font(13);
                                    font-weight: normal;
                                    color: $white;
                                    margin: 5px 0 0 0;
                                }
                                &.value{
                                    @include font(14);
                                    color: $white;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                    .profileLink{
                        display: inline-flex;
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        cursor: pointer;
                            svg{
                                .a{fill:#fdb944;}
                            }
                        }
                    }
                    .activityCollegeBox{
                        background-color: $white;
                        padding: 15px;
                        @include shadow(1px, 2px, 2px, #00000017);
                        @include radius(0 0 2px 2px);
                        div{
                            &.recuringActivity{
                                border-bottom: 1px solid rgba(112, 112, 112,0.3);
                                .heading{
                                    @include font(16);
                                    color: $blackColor;
                                    font-weight: 600;
                                    margin: 0 0;
                                }
                                ul{
                                    @include flex();
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    padding: 0;
                                    margin: 20px 0 10px 0;
                                    li{
                                        list-style: none;
                                        width: calc(25% - 10px);
                                        margin: 0 10px 10px 0; 
                                    span{
                                        display: block;
                                        text-align: center;
                                        &.value{
                                            @include font(14);
                                            font-weight: 600;
                                            color: $black;
                                        }
                                        &.label{
                                            @include font(12);
                                            color:$black;
                                            font-weight: normal;
                                            margin-top: 5px;
                                        }
                                    }
                                    &:last-child{
                                        margin: 0 10px 0 0;  
                                    }
                                    }
                                }
                            }
                            &.myCollege {
                                .heading {
                                    @include font(16);
                                    color: $blackColor;
                                    font-weight: 600;
                                    margin: 20px 0;
                                }
                                ul {
                                    margin: 0;
                                    padding: 0;
                                    @include flex();
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    li {
                                        list-style: none;
                                        width: calc(25% - 10px);
                                        margin: 0 10px 0 0;
                                        span {
                                            display: block;
                                            text-align: center;
                                            &.value {
                                                @include font(14);
                                                color: $black;
                                                font-weight: 600;
                                            }
                                            &.label {
                                                @include font(11);
                                                color: $black;
                                                font-weight: normal;
                                                letter-spacing: -0.3px;
                                                margin-top: 5px;
                                            }
                                        }
                                        &:last-child {
                                            margin: 0 10px 0 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
}
.profileDataBox{
    background-color: $white;
    padding: 15px;
    margin: 10px 0;
    @include shadow(1px, 2px, 2px, #00000017);
    div{
        &.head{
            @include flex();
            align-items: center;
            justify-content: space-between;
            .title{
                @include font(16);
                color: $black;
                font-weight: 600;
                margin: 0 0 15px 0;
            }
            .editLink{
                text-decoration: none;
                @include font(14);
                color: $baseColor;
                margin: 0 0 15px 0;
                cursor: pointer;
            }
        }
        &.porgress{
            @include flex();
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            label{
                display: inline-block;
                @include font(14);
                font-weight: 600;
                color: $green;
                margin-right: 10px;
            }
            .bar{
                display: inline-flex;
                position: relative;
                width: calc(100% - 45px);
                height: 8px;
                border:1px solid $green;
                @include radius(7px);
                &::before{
                    content: '';
                    display: inline-flex;
                    background: $green;
                    width: 70%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    @include radius(7px);
                }
            }
        }
        
    }
    .level{
        li{
            list-style: none;
            margin: 0 0 15px 0;
            &:last-child{
                margin: 0;    
            }
            &.passed{
                a{
                    color: $green;
                    &.checkList{
                        span {
                            &.icon {
                                position: relative;
                                svg {
                                    .a {
                                        fill: $green;
                                    }
                                }
                                .count {
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    width: 12px;
                                    height: 12px;
                                    @include radius(50%);
                                    background-color: $baseColor;
                                    @include font(8);
                                    color: $white;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                        }
                        span{
                            &.icon{
                                svg{
                                    .a{
                                        fill:none;
                                        stroke:$green;
                                        stroke-linecap:round;
                                        stroke-linejoin:round;
                                        stroke-width:1.5px;
                                    }
                                }
                            }
                            &.label{
                                color: $green;
                            }
                        }               
                }
            }
            a{
                display: inline-flex;
                text-decoration: none;
                cursor: pointer;
                &.video{
                    span{
                        &.icon{
                            svg{
                                .a,.b{
                                    fill:none;
                                    stroke:$black;
                                    stroke-linecap:round;
                                    stroke-width:1.5px;
                                }
                                .a{
                                    stroke-linejoin:round;
                                }
                            }
                        }
                    }
                }
                span{
                    display: inline-flex;
                    &.icon{
                        width: 20px;
                        margin-right: 10px;
                    }
                    &.label{
                        @include font(16);
                        font-weight: normal;
                        color: $black;
                    }
                }
            }
        }
    }
}
                .teamOfferBox {
                    background-color: $white;
                    @include radius(2px);
                    padding: 15px 15px;
                    margin-bottom: 10px;
                    div {
                        &.myTeam {
                            padding-bottom: 15px;
                            .head {
                                @include flex();
                                align-items: center;
                                justify-content: space-between;
                                .title {
                                    @include font(16);
                                    color: $blackColor;
                                    font-weight: 600;
                                    margin: 0 0 15px 0;
                                }
                                .editLink {
                                    text-decoration: none;
                                    display: inline-flex;
                                    cursor: pointer;
                                    color: $baseColor;
                                    margin: 0 0 15px 0;
                                }
                            }
                            ul {
                                margin: 0;
                                padding: 0;
                                li {
                                    list-style: none;
                                    @include flex();
                                    align-items: center;
                                    margin-bottom: 15px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    div {
                                        &.imgBox {
                                            width: 40px;
                                            height: 40px;
                                            margin-right: 10px;
                                            img {
                                                max-width: 100%;
                                                height: auto;
                                            }
                                            .image{
                                                max-width: 100%;
                                                width: 100px;
                                                height: auto;
                                            }
                                        }
                                        &.info {
                                            width: calc(100% - 50px);
                                            display: flex;
                                            align-items: center;
                                            line-height: 18px;
                                            span {
                                                display: inline-block;
                                                @include font(14);
                                                color: rgba(0, 0, 0, 0.7);
                                            }
                                        }
                                    }
                                }
                            }
                            .viewMore {
                                margin-top: 5px;
                                text-align: center;
                                a {
                                    display: inline-block;
                                    text-decoration: none;
                                    @include font(14);
                                    color: $baseColor;
                                    cursor: pointer;
                                }
                            }
                        }
                        &.offer {
                            padding-bottom: 15px;
                            .head {
                                @include flex();
                                align-items: center;
                                justify-content: space-between;
                                .title {
                                    @include font(16);
                                    color: $blackColor;
                                    font-weight: 600;
                                    margin: 0 0 15px 0;
                                }
                                .editLink {
                                    text-decoration: none;
                                    display: inline-flex;
                                    cursor: pointer;
                                    color: $baseColor;
                                    margin: 0 0 15px 0;
                                }
                            }
                            ul {
                                margin: 0;
                                padding: 0;
                                li {
                                    list-style: none;
                                    @include flex();
                                    margin-bottom: 15px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    div {
                                        &.imgBox {
                                            width: 40px;
                                            height: 40px;
                                            margin-right: 10px;
                                            img {
                                                max-width: 100%;
                                                height: auto;
                                            }
                                            .image{
                                                max-width: 100%;
                                                height: auto;
                                            }
                                        }
                                        &.info {
                                            width: calc(100% - 50px);
                                            .title {
                                                @include font(14);
                                                color: rgb(0, 123, 255);
                                                cursor: pointer;
                                                font-weight: 700;
                                                margin: 0 0 10px 0;
                                            }
                                            a {
                                                text-decoration: none
                                              }
                                            .date {
                                                @include flex();
                                                label {
                                                    @include font(12);
                                                    color: $blackColor;
                                                    display: inline-block;
                                                    margin-right: 3px;
                                                }
                                                span {
                                                    display: inline-block;
                                                    @include font(12);
                                                    color: $blackColor;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .viewMore {
                                margin-top: 10px;
                                text-align: center;
                                a {
                                    display: inline-block;
                                    color: $baseColor;
                                    text-decoration: none;
                                    @include font(14);
                                    cursor: pointer;
                                }
                            }
                        }
                        &.commitment {
                            .head {
                                @include flex();
                                align-items: center;
                                justify-content: space-between;
                                .title {
                                    margin: 0 0 15px 0;
                                    @include font(16);
                                    color: $blackColor;
                                    font-weight: 600;
                                }
                                .editLink {
                                    text-decoration: none;
                                    display: inline-flex;
                                    cursor: pointer;
                                    color: $baseColor;
                                    margin: 0 0 15px 0;
                                }
                            }
                            ul {
                                margin: 0;
                                padding: 0;
                                li {
                                    list-style: none;
                                    @include flex();
                                    margin-bottom: 15px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    div {
                                        &.imgBox {
                                            width: 40px;
                                            height: 40px;
                                            margin-right: 10px;
                                            img {
                                                max-width: 100%;
                                                height: auto;
                                            }
                                            .image{
                                                max-width: 100%;
                                                height: auto;
                                            }
                                        }
                                        &.info {
                                            width: calc(100% - 50px);
                                            .title {
                                                @include font(14);
                                                color: rgb(0, 123, 255);
                                                cursor: pointer;
                                                font-weight: 700;
                                                margin: 0 0 10px 0;
                                            }
                                            a {
                                                text-decoration: none
                                              }
                                            span {
                                                display: inline-block;
                                                @include font(12);
                                                color: $blackColor;
                                            }
                                        }
                                    }
                                }
                            }
                            .viewMore {
                                margin-top: 10px;
                                text-align: center;
                                a {
                                    display: inline-block;
                                    @include font(14);
                                    color: $baseColor;
                                    text-decoration: none;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .targetCollege {
                    background-color: $white;
                    @include radius(2px);
                    @include shadow(1px, 2px, 2px, #00000017);
                    .head {
                        padding: 20px 20px 0 20px;
                        @include flex();
                        align-items: center;
                        justify-content: space-between;
                        h5 {
                            @include font(16);
                            color: $blackColor;
                            font-weight: 600;
                            margin: 0 0 15px 0;
                        }
                        a {
                            @include font(14);
                            color: $baseColor;
                            font-weight: normal;
                            margin: 0 0 15px 0;
                        }
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        li {
                            list-style: none;
                            padding: 15px;
                            border-bottom: 1px solid $gray;
                            @include flex();
                            align-items: flex-start;
                            .count {
                                display: inline-block;
                                margin-right: 10px;
                                @include font(14);
                                color: $blackColor;
                                width: 30px;
                            }
                            .infoBox {
                                width: calc(100% - 40px);
                                @include flex();
                                align-items: flex-start;
                                .imgBox {
                                    width: 30px;
                                    height: 30px;
                                    @include radius(50%);
                                    margin-right: 10px;
                                    img {
                                        max-width: 100%;
                                        height: auto;
                                    }
                                    .image{
                                        max-width: 100%;
                                        height: auto;
                                        width: 100px;
                                    }
                                }
                                .data {
                                    width: calc(100% - 40px);
                                    .title {
                                        @include font(13);
                                        font-weight: 700;
                                        margin: 0 0 10px 0;
                                        color: rgb(0, 123, 255);
                                        cursor: pointer;
                                    }
                                    a {
                                        text-decoration: none
                                      }
                                    .flexBox {
                                        @include flex();
                                        align-items: center;
                                        justify-content: space-between;
                                        span {
                                            display: inline-block;
                                            @include font(13);
                                            color: $black;
                                            line-height: 1.4;
                                            width: calc(100% - 30px);
                                            word-break: break-all;
                                        }
                                        .forwardLink {
                                            text-decoration: none;
                                            display: inline-block;
                                            padding: 5px;
                                            svg {
                                                .a {
                                                    fill: $lightBlue;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .manageLink {
                        text-align: center;
                        padding: 15px;
                        a {
                            text-decoration: none;
                            @include font(14);
                            color: $baseColor;
                            display: inline-block;
                            cursor: pointer;
                        }
                    }
                }
.showMoreless{
    display: none;
    text-align: center;
    margin: 15px 0;
    a{
        display: inline-block;
        text-decoration: none;
        color: $baseColor;
        @include font(14);
        span{
            display: inline-block;
            &.icon{
                margin-left: 10px;
                svg{
                    .a{
                        fill: $baseColor;
                    }   
                }
            }
        }
    }
}
}
            .dataBox{
                width: calc(100% - 520px);
                margin: 0 10px;
             
                 .backHome{
                     padding-bottom: 10px;
                     border-bottom:1px solid $gray;
                     margin-bottom:15px;
                     a{
                         display:inline-flex;
                         align-items: center;
                         text-decoration:none;
                         padding:5px;
                         @include font(14);
                         color:$black;
                         cursor:pointer;
                         span{
                             display:inline-block;
                             &.icon{
                                 margin-right:10px;
                                 svg{
                                     fill:$black;
                                     width:15px;
                                     height:auto;
                                 }
                             }
                         }
                     }
                 }
                .tabsBox{ 
                        .tabFlex{
                            @include flex();
                            margin-bottom:15px;
                            .tab{
                                margin-right: 10px;
                                a{
                                    display: inline-flex;
                                    text-decoration: none;
                                    position: relative;
                                    padding: 10px;
                                    @include font(16);
                                    color: $black;
                                    &:hover{
                                        color: $baseColor;
                                    }
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                &.selected{
                                    a{
                                        color:$baseColor;
                                        border-bottom:1px solid $baseColor;
                                    }
                                }
                            }
                        }
                        
                        .followingDataBox{
                            background-color: $white;
                            padding: 15px;
                            @include shadow(1px, 2px, 2px, #00000017);
                            .item{
                                padding:15px 0;
                                border-bottom:1px solid $gray;
                                .imgDataFlexBox{
                                    @include flex();
                                    align-items: flex-start;
                                    margin-bottom: 10px;
                                    .imgBox{
                                        position:relative;
                                        width: 50px;
                                        height: 50px;
                                        margin-right: 10px;
                                        @include radius(50%);
                                        img{
                                            max-width: 100%;
                                            height: auto;
                                        }
                                        .initialLetter{
                                            width: 50px;
                                            height: 50px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                        }
                                        span{
                                            &.icon{
                                                display: inline-block;
                                                position: absolute;
                                                bottom:0;
                                                right:-4px;
                                                &.blueCheck{
                                                    background-color: $white;
                                                    @include radius(50%);
                                                    svg{
                                                        width:18px;
                                                        height:auto;
                                                        .a{
                                                            fill: $lightBlue;
                                                        }
                                                    }
                                                }
                                                &.redAlert{
                                                    svg{
                                                        width:18px;
                                                        height:auto;
                                                        .a{
                                                            fill: #ff4a31;
                                                        }
                                                        .b{
                                                            fill: $white;  
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .dataFlexBox{
                                        @include flex();
                                        align-items: flex-start;
                                        justify-content: space-between;
                                        width: calc(100% - 60px);
                                        div{
                                            width: calc(100% - 75px);
                                            padding-right:10px;
                                            h5{ 
                                                @include font(16);
                                                color: $blackColor;
                                                margin: 0 0 5px 0;
                                                span{
                                                    display: inline-block;
                                                    @include font(12);
                                                    color: $green;
                                                }
                                            }
                                            ul{
                                                @include flex();
                                                flex-wrap:wrap;
                                                padding:0;
                                                margin:0;
                                                li{
                                                    position:relative;
                                                    list-style:none;
                                                    padding-left: 8px;
                                                    margin-right:5px;
                                                    margin-bottom:5px;
                                                    span{
                                                        display:inline-block;
                                                        @include font(13);
                                                        color: $black;
                                                        line-height:16px;
                                                    }
                                                    &::before{
                                                        content : "";
                                                        display:inline-block;
                                                        background-color: $blackColor;
                                                        position: absolute;
                                                        left:0;
                                                        top:6px;
                                                        width:4px;
                                                        height:4px;
                                                        @include  radius(50%);
                                                    }
                                                }
                                            }
                                            p{
                                                @include font(13);
                                                color:$black;
                                                line-height:16px;
                                                margin-top:5px;
                                            }
                                            &.action{
                                                @include flex();
                                                align-items: center;
                                                position: relative;
                                                width: auto;
                                                padding-right:0;
                                                margin-top:10px;
                                                a{
                                                    text-decoration:none;
                                                    &.unFollowingLink{
                                                        background-color: $baseColor;
                                                        padding:3px 10px;
                                                        @include font(13);
                                                        color:$white;
                                                        @include radius(10px);
                                                        line-height:16px;
                                                    }
                                                    &.followingLink{
                                                        background-color: $lightBlue;
                                                        padding:3px 10px;
                                                        @include font(13);
                                                        color:$white;
                                                        line-height:16px;
                                                        @include radius(10px); 
                                                    }
                                                    &.followerLink{
                                                        padding:3px 10px;
                                                        @include font(13);
                                                        color:$lightBlue;
                                                        border:1px solid $lightBlue;
                                                        @include radius(10px); 
                                                        line-height:16px;  
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    .loadMoreBox{
                        display:none;
                        background-color: $white;
                        padding: 10px;
                        text-align: center;
                        @include shadow(1px, 2px, 2px, #00000017);
                        @include radius(4px);
                        a{
                            display: inline-block;
                            text-decoration: none;
                            @include font(14);
                            color: $black;
                            cursor: pointer;
                        }
                    }
                }    
            }
            .rightDataBox{
                width: 250px;
                .verificationBox{
                    background-color: $white;
                    margin-bottom: 15px;
                    @include radius(4px);
                    @include shadow(1px, 2px, 2px, #00000017);
                    .head{
                        @include flex();
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px 10px 0 10px;
                        cursor: pointer;
                        span{
                            &.icon{
                                display: inline-block;
                                margin-right: 10px;
                                svg{
                                    .a{
                                        fill:#ff4a31;
                                    }
                                    .b{
                                        fill:$white;
                                    }
                                }
                            }
                        }
                     .title{
                         @include font(14);
                         font-weight: 600;
                         color: $black;
                         margin: 0 auto 0 0; 
                     }
                     button{
                         &.btn{
                             background-color: transparent;
                             padding: 5px 5px;
                             min-width: auto;
                             max-width: 30px;
                             svg{
                                 .a{
                                     fill: $black;
                                 }
                             }
                         }
                     }   
                    }
                    .stepInfo{
                        padding: 10px;
                        .steps{
                            @include flex();
                            margin-bottom: 10px;
                            margin-left: 5px;
                            span{
                                background: $gray;
                                display: inline-block;
                                width: calc(20% + 5px);
                                height: 9px;
                                border: 1px solid $white;
                                @include radius(7px);
                                margin-left: -5px;
                                &.passed{
                                    background: $lightBlue;
                                    &.active{
                                        position: relative;
                                        z-index: 1;
                                    }
                                }
                            }
                        }
                        .flexBox{
                            @include flex();
                            justify-content: space-between;
                            align-items: center;
                        p{
                            width: calc(100% - 35px);
                            @include font(13);
                            color: $black; 
                        }
                        .icon{
                            svg{
                                .a{
                                    fill:$lightBlue;
                                }
                            }
                        }                     
                    }
                    }
                    .collapse{
                        .card{
                            padding: 10px;
                            border-left: none;
                            border-right: none;
                            border-bottom: none;
                            border-top: 1px solid $gray;
                            @include radius(0px);
                            .verificationSteps{
                                margin: 0 0 5px 0;
                                padding: 0;
                                li{
                                    @include flex();
                                    align-items: center;
                                    justify-content: space-between;
                                    list-style: none;
                                    margin-bottom: 10px;
                                    span{
                                        display: inline-block;
                                        @include font(13);
                                        color: $black;
                                        &.icon{
                                            svg{
                                                width: 18px;
                                                height: auto;
                                                .a{
                                                    fill:$lightBlue;
                                                }
                                                .b{
                                                    fill:#fafafa;
                                                }
                                            }
                                        }
                                    }
                                    &.completeStep{
                                        span{
                                            color: $baseColor;
                                            &.icon{
                                                svg{
                                                    width: 18px;
                                                    height: auto;
                                                    .a{
                                                        fill:$baseColor;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &.passed{
                                        span{
                                            position: relative;
                                            color: #999999;
                                            &::before{
                                                content: "";
                                                display: inline-block;
                                                background-color: #999;
                                                position: absolute;
                                                width: 100%;
                                                height: 1px;
                                                left: 0;
                                                top: 50%;
                                                transform: translateY(-50%);
                                            }
                                            &.icon{
                                                &::before{
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .whyverified{
                             background-color: $bgLightGray;
                             padding: 10px;
                             .title{
                                 @include flex();
                                 align-items: center;
                                 @include font(13);
                                 font-weight: 600;
                                 color: $black;
                                 margin: 0 0 10px 0;
                                 span{
                                     display: inline-block;
                                     &.icon{
                                         margin-left: 10px;
                                         svg{
                                             width: 22px;
                                             height: auto;
                                            .a{
                                                fill:$lightBlue;
                                            }
                                         }
                                     }
                                 }
                             }
                             
                             .info{
                                 @include font(12);
                                 color: $black;
                                 line-height: 16px;
                                 .learnMore{
                                     display: inline-block;
                                     text-decoration: none;
                                     color: $lightBlue;
                                     font-weight: 600;
                                     cursor: pointer;
                                 }
                             }   
                            }
                        }
                    }
                }
                .scoutRate{
                    background-color: $white;
                    padding: 15px;
                    @include radius(4px);
                    @include shadow(1px, 2px, 2px, #00000017);
                    margin-bottom: 15px;
                    .headFlex{
                        @include flex();
                        align-items: center;
                        margin-bottom: 10px;
                        div{
                            &.imgBox{
                                width: 50px;
                                height: 50px;
                                margin-right: 10px;
                                @include radius(50%);
                                img{
                                    max-width: 100%;
                                    height: auto;
                                }
                            }
                            h5{
                                @include font(16);
                                font-weight: 600;
                                color: $black;
                            }
                        }
                    }
                    .desc{
                        @include font(13);
                        color: $black;
                        line-height: 16px;
                        margin-bottom: 10px;
                    }
                    button{
                        &.scoutBtn{
                            
                            padding: 15px;
                            background-color: $yellow;
                            @include font(14);
                            color:$white;                        
                            outline: none;
                            box-shadow: none;
                            border: none;  
                            @include radius(4px);
                            cursor: pointer;
                        }
                    }
                }
                .recruitAdvisor{
                    background-color: $white;
                    padding: 15px;
                    @include radius(4px);
                    @include shadow(1px, 2px, 2px, #00000017);
                    margin-bottom: 15px;
                    .headFlex{
                        @include flex();
                        align-items: center;
                        margin-bottom: 10px;
                        div{
                            &.imgBox{
                                width: 50px;
                                height: 50px;
                                margin-right: 10px;
                                @include radius(50%);
                                img{
                                    max-width: 100%;
                                    height: auto;
                                }
                            }
                            h5{
                                @include font(16);
                                font-weight: 600;
                                color: $black;
                            }
                        }
                        
                    }
                    .desc{
                        @include font(13);
                        color: $black;
                        line-height: 16px;
                        margin-bottom: 10px;
                    }
                    button{
                        &.questionBtn{
                            padding: 15px;
                            background-color: $green;
                            @include font(14);
                            color:$white;
                            outline: none;
                            box-shadow: none;
                            border: none;
                            @include radius(4px);
                            cursor: pointer;
                        }
                    }
                }
                .importNotice{
                    background-color: $white;
                    padding: 15px;
                    @include radius(4px);
                    @include shadow(1px, 2px, 2px, #00000017);
                    margin-bottom: 15px;
                    .headFlex{
                        @include flex();
                        align-items: center;
                        margin-bottom: 10px;
                        div{
                            &.imgBox{
                                margin-right: 10px;
                                svg{
                                    .a{fill:url(#a);}.b{fill:url(#b);}.c{fill:url(#c);}.d{fill:url(#d);}.e{fill:url(#e);}.f{fill:$white;}
                                }
                            }
                            h5{
                                @include font(16);
                                font-weight: 600;
                                color: $black;
                            }
                        }
                        
                    }
                    .desc{
                        @include font(13);
                        color: $black;
                        line-height: 16px;
                        margin-bottom: 10px;
                    }
                 .knowMoreLink{
                     display: inline-block;
                     text-decoration: underline;
                     color: $lightBlue;
                     cursor: pointer;
                     @include font(14);
                 }
                }
                .verificationProcess{
                    background-color: #343434;
                    padding: 15px;
                    width: 100%;
                    @include shadow(1px, 2px, 2px, #00000017);
                    @include radius(4px);
                    @media screen and (min-width:1366px) {
                        width: calc(100% + 100px); 
                    }
                    .head{
                        @include flex();
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        .title{
                            @include flex();
                            align-items: center;
                            span{
                                display: inline-block;
                                @include font(16);
                                font-weight: 600;
                                color: $white;
                                &.icon{
                                    margin-right: 10px;
                                    svg{
                                        .a{fill:url(#a);}.b{fill:url(#b);}.c{fill:url(#c);}.d{fill:url(#d);}.e{fill:url(#e);}.f{fill:$white;}
                                    }
                                }
                            }
                        }
                        button{
                            &.closeBtn{
                                background-color: transparent;
                                outline: none;
                                border: none;
                                cursor: pointer;
                                svg{
                                    .a{
                                        fill:$white;
                                        opacity:0.5;
                                    }
                                }
                            }
                        }
                    }
                    .steps{
                        @include flex();
                        margin-right: 5px;
                        margin-bottom: 10px;
                        span{
                            display: inline-block;
                            background-color: #DDDDDD;
                            width: 15%;
                            height: 9px;
                            border: 1px solid #343434;
                            @include radius(7px);
                            margin-right: -5px;
                            &.passed{
                                background-color: $green;
                            }
                        }   
                    }
                    .setpsInfo{
                        display: block;
                        @include font(12);
                        color: $white;
                        line-height: 16px;
                        margin-bottom: 10px;
                    }
                    .info{
                        display: block;
                        @include font(12);
                        color: $white;
                        line-height: 16px;
                        margin-bottom: 10px;
                    }
                    .verifyProfileLink{
                        display: inline-block;
                        text-decoration: underline;
                        @include font(14);
                        color: #FDB944;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

/*************************getVerifiedModal css*******************************************/

.modal{
    &.getVerifiedModal{
        .modal-dialog{
            max-width: 400px;
            max-height: calc(100% - 30px);
            width: calc(100% - 30px);
            margin: 15px;
            .modal-content{
                .modal-header{
                    padding: 0 10px ;
                    border-bottom: none;
                    button{
                        &.close{
                            float: none;
                            min-width: auto;
                            margin: 0 0 0 auto;
                            outline: none;
                            svg{
                                .a{
                                    opacity:0.7;
                                    fill: $black;
                                }   
                            }
                        }
                    }
                }
                .modal-body{
                    padding: 0 15px 15px 15px;
                    .heading{
                        max-width: 230px;
                        @include font(18);
                        color: $blackColor;
                        font-weight: 600;
                        text-align: center;
                        margin: 0 auto 10px auto;
                    }
                    .profileBox{
                        .profileImgBox{
                            position: relative;
                            width: 100px;
                            height: 100px;
                            @include radius(50%);
                            margin: 0 auto;
                            .imgBox{
                                @include flex();
                                justify-content: center;
                                align-items: center;
                                width: 100px;
                                height: 100px;
                                border: 1px solid $gray;
                                @include radius(50%);
                                margin: 0 auto;
                                img{
                                    max-width: 100%;
                                    height: auto;
                                    @include radius(50%);
                                }  
                            }
                            .icon{
                                position: absolute;
                                bottom: 0;
                                right: -10px;
                                svg{
                                    .a{
                                        fill: #ff4a31;
                                    }
                                    .b{
                                        fill: $white;
                                    }
                                }
                            }
                        }
                        .name{
                            margin: 10px 0 10px 0;
                            color: $black;
                            @include font(16);
                            font-weight: 600;
                            text-align: center;
                        }
                        ul{
                            margin: 0 0 5px 0;
                            li{
                                list-style: none;
                                margin-bottom: 10px;
                                @include flex();
                                align-items: flex-start;
                                justify-content: center;
                                span{
                                    display: inline-block;
                                    @include font(14);
                                    color:$black;
                                }
                                label{
                                    margin-right: 5px;
                                    display: inline-block;
                                    @include font(14);
                                    color:$black;
                                }
                            }
                        }
                    }
                    p {
                        &.info{
                            @include font(14);
                            line-height: 18px;
                            color: $black;
                            text-align: center;
                            max-width: 300px;
                            margin: 0 auto 10px auto;
                        }
                    }
                    .imgBox {
                        text-align: center;
                    }
                    .btnBox{
                        margin: 20px 0;
                        text-align: center;
                        button{
                            &.verifyBtn{
                                background: $lightBlue;
                                color: $white;
                                @include radius(4px);
                                border: none;
                                @include font(14);
                                padding: 15px 20px;
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
        &.show{
            display: flex !important;
             align-items: center;
             justify-content: center;
         }
    }
    
} 

.swal-overlay {
    .swal-footer {
        text-align: center;
        margin-top: 0;
        .swal-button {
            background:$lightBlue;
            padding: 12px 30px;
            box-shadow: none;
            outline: none;
            border: none;
            &.swal-button--cancel {
                background: $baseColor;
                color: $white;
            }
            &:hover, &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}
/*************************shareUpdateModal css****************************************/

.modal{
    &.shareUpdateModal{
        .modal-dialog{
            max-width: 400px;
            margin: 15px 15px;
            max-height: calc(100% - 30px);
            width: calc(100% - 30px);
            .modal-content{
                .modal-header{
                    padding: 0 10px ;
                    border-bottom: none;
                    button{
                        &.close{
                            min-width: auto;
                            padding: 5px;
                            float: none;
                            margin: 0 0 0 auto;
                            outline: none;
                            svg{
                                .a{opacity:0.7; fill: $black;}   
                            }
                        }
                    }
                }
                .modal-body{
                    padding: 0 15px 15px 15px;
                    .heading{
                        margin: 0 auto 15px auto;
                        max-width: 230px;
                        @include font(18);
                        color: $blackColor;
                        font-weight: 600;
                        text-align: center;
                    }
                  .socialFlexBox{
                      @include flex();
                      max-width: 260px;
                      margin: 0 auto;
                      justify-content: space-between;
                      .twitter{
                        button{
                              width: 100px;
                              height: 100px;
                              @include flex();
                              align-items: center;
                              justify-content: center;
                              border: 1px solid $lightBlue;
                              @include radius(50%);
                              color: $lightBlue;
                              text-decoration: none;
                              cursor: pointer;
                              background-color: transparent;
                              outline: none;
                              div{
                                  span{
                                      @include font(14);
                                      display: block;
                                      text-align: center;
                                      &.icon{
                                          margin-bottom: 5px;
                                          svg{
                                              width: 30px;
                                              height: auto;
                                              fill: $lightBlue
                                          }
                                      }
                                  }
                              }
                          }
                      }
                      .fbook{
                        button{
                            width: 100px;
                            height: 100px;
                            @include flex();
                            align-items: center;
                            justify-content: center;
                            border: 1px solid $blue;
                            @include radius(50%);
                            color:$blue;
                             text-decoration: none;
                              cursor: pointer;
                              background-color: transparent;
                              outline: none;
                              div{
                                span{
                                    @include font(14);
                                    display: block;
                                    text-align: center;
                                    &.icon{
                                        margin-bottom: 5px;
                                        svg{
                                            width: 30px;
                                            height: auto;
                                            fill: $blue;
                                            path{
                                                fill: $blue;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                  }
                    p{
                        &.info{
                            @include font(14);
                            line-height: 18px;
                            color: $black;
                            text-align: center;
                            max-width: 300px;
                            margin: 20px auto;
                           
                        }
                    }
                    .linkBox{
                     width: 100%;
                     @include flex();
                     margin-bottom: 10px;
                     p{
                         @include font(13);
                         color:$black;
                         padding: 10px;
                         border: 1px solid $gray;
                         @include radius(4px 0 0 4px);
                         @include flex();
                         align-items: center;
                         width: calc(100% - 78px);
                     }
                        button{
                            &.copyBtn{
                                background: $lightBlue;
                                color: $white;
                                @include radius(4px);
                                border: none;
                                @include font(14);
                                padding: 15px 20px;
                                outline: none;
                                @include radius(0 4px 4px 0);
                            }
                        }
                    }
                }
            }
        }
        &.show{
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
    }  
} 
/**************************statusUpdateModal css*******************************/
.modal{
    &.statusUpdateModal{
        .modal-dialog{
            max-width: 540px;
            margin: 15px;
            max-height: calc(100% - 30px);
            width: calc(100% - 30px);
            .modal-content{
                .modal-header{
                    padding: 20px;
                    border-bottom: none;
                    align-items: center;
                    position: relative;
                    button{
                        &.close{
                            min-width: auto;
                            padding: 5px;
                            float: none;
                            margin: 0 0 0 auto;
                            outline: none;
                            svg{
                                .a{opacity:0.7; fill: $black;}   
                            }
                        }
                    }
                    &::after{
                        content: '';
                        display: inline-block;
                        width: calc(100% - 30px);
                        bottom: 0;
                        left: 15px;
                        position: absolute;
                        height: 1px;
                        background-color: $gray;
                    }
                }
                .modal-body{
                    padding: 20px;
                .profileFlexBox{
                    @include flex();
                    align-items: flex-start;
                    margin-bottom: 15px;
                    .imgBox{
                        width: 50px;
                        height: 50px;
                        @include radius(50%);
                        margin-right: 10px;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        img{
                            max-width: 100%;
                            height: auto;
                        }
                    }
                    .info{
                        width: calc(100% - 60px);
                        h5{
                            &.name{
                                @include font(16);
                                margin: 0 0 10px 0;
                                color: $blackColor;
                            }
                        }
                        .selectField{
                            select{
                                width: 100px;
                                @include radius(30px);
                                border: 1px solid #707070;
                                @include font(14);
                                padding: 3px 15px 3px 10px;
                                color: $black;
                                appearance:none;
                                background:url(../img/arrowdown.png) no-repeat;
                                background-size:10px;
                                background-position: 95% center;
                            }
                        }
                    }
                }    
               .textBox{
                margin-bottom: 15px;
                   textarea{
                       padding: 10px;
                       width: 100%;
                       height: 150px;
                       overflow-x: hidden;
                       overflow-y: auto;
                       background-color: #F9F9F9;
                       @include radius(4px);
                       @include font(16);
                       line-height: 22px;
                       color: $black;
                       border: none;
                       outline: none;
                       resize: none;
                   }
               }
               .flexBox{
                   @include flex();
                   align-items: center;
                   justify-content:space-between;
                   ul{
                       @include flex();
                       align-items: center;
                       margin: 0;
                       padding: 0;
                       li{
                           list-style: none;
                           margin-right: 15px;
                           @include flex();
                           align-items: center;
                           a{
                               text-decoration:none;
                               display: inline-block;
                               cursor: pointer;
                               padding:5px;
                               &.videoLink{
                                   span{
                                       &.icon{
                                           svg{
                                               .a{
                                                 fill:#19d7cd;
                                               }
                                           }
                                       }
                                   }
                               }
                               &.attachLink{
                                span{
                                    &.icon{
                                        svg{
                                            .a{
                                                fill: none;
                                                stroke: $baseColor;
                                                stroke-linecap: round;
                                                stroke-linejoin: round;
                                                stroke-width: 2px;
                                            }
                                        }
                                    }
                                }
                            }
                            &.photoLink{
                                span{
                                    &.icon{
                                        svg{
                                            .a{
                                                fill: #fdb944;
                                            }
                                        }
                                    }
                                } 
                            }
                           span{
                               @include font(14);
                               display: inline-block;
                               color: $black;
                               &.icon{
                                   margin-right: 10px;
                               }
                           }
                        }
                       }
                   }
                   button{
                       outline:none;
                       cursor:pointer;
                       &.postBtn{
                           color:$white;
                           background-color: $lightBlue;
                           @include radius(4px);
                           padding: 15px 20px;
                           border: none;
                           @include font(14);
                       }
                   }
               }
                }
            }
        }
        &.show{
            display: flex !important;
            align-items: center;
            justify-content: center;
        }
    } 
} 
/************************************************************/
@media screen and (min-width:1700px) {
    .athleteDashFollowerSection{
        > .container{
            max-width: 1600px;
        }
    }
}
@media screen and (max-width:991px) {
    .athleteDashFollowerSection{
        > .container{
            max-width: 100%;
            .flexBox{
                .leftDataBox{
                    width: 230px;
                    .AthleteInfoBox{
                        .AthleteInfo{
                            padding: 15px 10px;
                        }
                        .activityCollegeBox{
                            padding: 15px 10px;
                        }
                    }
                    .profileDataBox{
                        padding: 15px 10px;
                    }
                    .teamOfferBox{
                        padding: 15px 10px;
                    }
                    .targetCollege{
                        .head{
                            padding: 15px 10px 0 10px;
                        }
                        ul{
                            li{
                                padding: 10px;
                            }
                        }
                        .manageLink{
                            padding: 10px;
                        }
                    }
                }
                .dataBox{
                    width: calc(100% - 480px);
                    .tabsBox{
                        .followingDataBox{
                            padding:10px;
                            .item{
                                padding:10px 0;
                                .imgDataFlexBox{
                                    .imgBox{
                                        width: 40px;
                                        height: 40px;
                                    }
                                    .dataFlexBox{
                                        width: calc(100% - 50px);
                                        flex-wrap:wrap;
                                        div{
                                            width:100%;
                                            padding-right: 0;
                                            h5{
                                                @include font(13);
                                            }
                                            span{
                                                @include font(11);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .rightDataBox{
                    width: 230px;
                    .scoutRate{
                        padding: 10px;
                        button{
                            &.scoutBtn{
                                padding: 10px 15px;
                            }
                        }
                    }
                    .recruitAdvisor{
                        padding: 10px;
                        button{
                            &.questionBtn{
                                padding: 10px 15px; 
                            }
                        }
                    }
                    .importNotice{
                        padding: 10px;
                    }
                    .verificationProcess{
                        width: 100%;
                        padding: 10px;
                        .steps{
                            span{
                                width: 20%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:767px) {
    .athleteDashFollowerSection{
        > .container{
            .flexBox{
                .leftDataBox{
                    order: 1;
                    width: 100%;
                    max-width: 540px;
                    margin: 0 auto 10px auto;
                    .AthleteInfoBox{
                        .AthleteInfo{
                            background-size: cover;
                        }
                    }
                    .showMoreless{
                        display:block;
                    }
                }
                .dataBox{
                    order: 3;
                    width: 100%;
                    max-width: 540px;
                    margin: 0 auto;
                    .tabsBox{
                        .followingDataBox{
                            padding:10px;
                        }
                    }
                }
                .rightDataBox{
                    order: 2;
                    width: 100%;
                    max-width: 540px;
                    margin: 0 auto 10px auto;
                }
            }
        }
    }
}
@media screen and (max-width:575px){
    .athleteDashFollowerSection{
        > .container{
            .flexBox{
                .leftDataBox{
                    .AthleteInfoBox{
                        .AthleteInfo{
                            padding: 15px;
                            background-size: calc(100% + 5px);
                        }
                        .activityCollegeBox{
                            padding: 15px;
                        }
                    }
                    .profileDataBox{
                        padding: 15px;
                    }
                    .teamOfferBox{
                        padding: 15px;
                    }
                }
            }
        }
    }
}