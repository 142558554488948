@import "../../../assets/css/mixin";
@import "../../../assets/css/reset";
@import "../../../assets/css/variables";

/******************************************************/

header {
  &.dashboard {
    background-color: #333;
    padding: 0px 15px;
    height: 60px;
    @include flex();
    align-items: center;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1;
    .logoPart {
      a {
        cursor: pointer;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
@import "../../../assets/css/mixin";
@import "../../../assets/css/reset";
@import "../../../assets/css/variables";
/******************************************************/
header {
  &.dashboard {
    background: #040404;
    @include flex();
    display: block;
    padding: 0px 30px;
    position: fixed;
    left: 0;
    width: 100%;
    top: 0px;
    z-index: 999;
    div {
      &.logoPart {
        @include flex();
        align-items: center;
        a {
          text-decoration: none;
          display: inline-flex;
          cursor: pointer;
          img {
            max-width: 100%;
            height: auto;
            &.mobile {
              display: none;
            }
          }
        }
      }
      &.searchBar {
        @include flex();
        align-items: center;
        padding: 0px 10px 0px 20px;
        position: relative;
        input {
          @include radius(30px);
          border: none;
          outline: none;
          font-size: 14px;
          color: $black;
          padding: 9px 10px 9px 32px;
          height: 34px;
          width: 200px;
          background: rgba($white, 0.9%) url("../assets/Img/searchDark.svg")
            12px center no-repeat;
          @include transition();
          border: 1px solid transparent;
          &:focus {
            background: rgba($white, 1%) url("../assets/Img/searchDark.svg")
              12px center no-repeat;
            border-color: $lightBlue;
          }
        }
        .dropdown-menu {
          background: $white;
          width: 280px;
          padding: 15px 12px;
          box-shadow: 0px 5px 15px #0000003b;
          @include radius(4px);
          position: absolute !important;
          left: 0 !important;
          right: auto !important;
          top: 100% !important;
          min-width: 100%;
          transform: none !important;
          min-height: 1px;
          margin-right: 0;
          margin-top: 0;
          &.dropdownopen {
            display: block;
          }
          .title {
            @include font(15);
            font-weight: 400;
            color: $blackColor;
            padding-bottom: 12px;
          }
          .search-list {
            max-height: 520px;
            overflow-y: scroll;
            padding-right: 12px;
            &::-webkit-scrollbar {
              border-radius: 10px;
              width: 8px;
              background: rgba($gray, 0.3%);
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: rgba($gray, 0.8%);
              cursor: pointer;
            }
            a {
              @include flex();
              align-items: center;
              text-decoration: none;
              cursor: pointer;
            }
            li {
              @include flex();
              align-items: center;
              padding: 15px 10px;
              border-bottom: 1px solid $gray;
              @include radius(5px 5px 0 0);
              &:last-child {
                margin-bottom: 0;
                border-bottom: 0;
              }
              .pic {
                @include flex();
                justify-content: center;
                align-items: center;
                flex: 0 0 55px;
                width: 55px;
                height: 55px;
                @include radius(50px);
                margin-right: 10px;
                border: 1px solid $border;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                }
                span {
                  @include font(18);
                  font-weight: 500;
                  color:$black;
                  text-align: center;
                  text-transform: capitalize;
                }
              }
              .desgination {
                @include font(14);
                font-weight: 300;
                color: $black;
                line-height: 1.4;
                strong {
                  display: block;
                  font-weight: 600;
                  color: $blackColor;
                }
              }
              &:hover {
                background: rgba($lightBlue, 0.2);
              }
            }
          }
        }
      }
    }
    nav {
      margin-left: auto;
      @include flex();
      > ul {
        @include flex();
        align-items: center;
        @include flex();
        li {
          list-style: none;
          position: relative;
          &.dropdown {
            .dropdown-menu {
              background: $white;
              width: 300px;
              padding: 15px 15px 0;
              box-shadow: 0px 5px 15px #0000003b;
              @include radius(4px);
              position: absolute !important;
              left: auto !important;
              right: 0 !important;
              top: 100% !important;
              min-width: 100%;
              height: 475px;
              overflow-y: scroll;
              transform: none !important;
              min-height: 1px;
              margin-right: 0;
              margin-top: 0;
              > li {
                ul {
                  margin-bottom: 10px;
                }
              }
              .profile-box {
                margin-bottom: 16px;
                .profile-info {
                  @include flex();
                  align-items: center;
                  margin-bottom: 15px;
                  .pic {
                    flex: 0 0 55px;
                    width: 55px;
                    height: 55px;
                    @include radius(50px);
                    margin-right: 10px;
                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                  .desgination {
                    @include font(14);
                    font-weight: 300;
                    color: $black;
                    line-height: 1.4;
                    strong {
                      display: block;
                      font-weight: 500;
                      color: $blackColor;
                    }
                  }
                }
                .view-profile-btn {
                  display: block;
                  padding: 4px 15px;
                  @include font(14);
                  font-weight: 400;
                  color: $blue;
                  border: 1px solid $blue;
                  @include radius(3px);
                  text-align: center;
                  line-height: 24px;
                  &:hover {
                    background: $blue;
                    color: $white;
                  }
                  &.switch-accountProfile {
                    color: #2828ed;
                    margin-top: 15px;
                    &:hover {
                      background: $blue;
                      color: $white;
                      border-color: $blue;
                    }
                  }
                }
              }
              .menu-title {
                padding: 6px 15px;
                background: rgba($gray, 0.4%);
                @include font(14);
                font-weight: 500;
                color: $blackColor;
                margin: 0 -15px 10px;
              }
              ul {
                li {
                  margin-bottom: 10px;
                  a {
                    display: block;
                    @include font(14);
                    font-weight: 300;
                    color: $black;
                    letter-spacing: 0.21px;
                    padding: 0 0 0 30px;
                    line-height: 24px;
                    @include transition();
                    position: relative;
                    .icon {
                      display: inline-block;
                      vertical-align: middle;
                      position: absolute;
                      left: 0px;
                      top: 2px;
                      background-repeat: no-repeat;
                      background-position: center center;
                      width: 18px;
                      height: 18px;
                      &.settings {
                        background: url(../assets/Img/settings-icon.svg);
                      }
                      &.privacy {
                        background: url(../assets/Img/privacy-icon.svg);
                        width: 15px;
                        height: 19px;
                      }
                      &.price-tag {
                        background: url(../assets/Img/price-tag-icon.svg);
                      }
                      &.user {
                        background: url(../assets/Img/user-icon.svg);
                        width: 17px;
                        height: 17px;
                      }
                      &.dollar {
                        background: url(../assets/Img/dollar-icon.svg);
                        width: 17px;
                        height: 17px;
                      }
                      &.search {
                        background: url(../assets/Img/search-icon.svg);
                        width: 17px;
                        height: 17px;
                      }
                      &.book {
                        background: url(../assets/Img/book-icon.svg);
                        width: 23px;
                        height: 16px;
                      }
                      &.help {
                        background: url(../assets/Img/help-icon.svg);
                      }
                      &.chat {
                        background: url(../assets/Img/chat-icon.svg);
                        width: 22px;
                      }
                      &.signout {
                        background: url(../assets/Img/signout.svg);
                        width: 15px;
                        height: 17px;
                      }
                    }
                    svg {
                      .a {
                        opacity: 0.6;
                        @include transition();
                      }
                    }
                    &:hover {
                      background: none;
                      color: $blackColor;
                      svg {
                        .a {
                          fill: $baseColor;
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.avtaarmenudropdown {
              .dropdown-menu {
                display: block;
                @include scaleY(1);
              }
            }
          }
          // &.dropdown {
          //   &:hover {
          //     .dropdown-menu {
          //       display: block;
          //       @include scaleY(1);
          //     }
          //   }
          // }
          a {
            color: $white;
            text-decoration: none;
            padding: 10px 1em;
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            line-height: 40px;
            font-size: 16px;
            @include flex();
            align-items: center;
            img {
              height: 20px;
              display: block;
              margin: 0 10px;
            }
            &:hover,
            &.active {
              background: $baseColor;
            }
            &.upgradeLink {
              text-transform: uppercase;
              background: $baseColor;
              @include radius(5px);
              margin: 0 10px;
              padding: 0px 1em;
              text-align: center;
              &:hover {
                background: $lightBlue;
              }
            }
          }
          &.icon {
            @include flex();
            a {
              position: relative;
              padding: 10px 0.25em;
              height: 60px;
            }
            .count {
              &.active {
                color: $baseColor;
                background: $white;
              }
            }
            &:hover {
              .count {
                color: $baseColor;
                background: $white;
              }
            }
            &.notifyLink {
              img {
                height: 24px;
              }
            }
            span {
              display: inline-flex;
              @include transition();
              color: $white;
              @include radius(2px);
              position: absolute;
              right: 10px;
              top: 10px;
              width: 15px;
              height: 15px;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: bold;
              background-color: $baseColor;
            }
            &.notifyLink {
              svg {
                height: 30px;
              }
            }
          }
          .avtaar {
            width: 40px;
            height: 40px;
            cursor: pointer;
            @include radius(50%);
            background: $white;
            @include flex();
            align-items: center;
            justify-content: center;
            margin: 10px 0px 10px 10px;
            display: inline-flex;
            color: $baseColor;
            font-size: 16px;
            @include transition();
            font-weight: bold;
            &:hover {
              background: $baseColor;
              color: $white;
            }
            .avtaar-pic {
              img {
                display: block;
                object-fit: cover;
                max-width: 100%;
                width:40px;
                height:40px;
                @include radius(50%);
              }
            }
          }
          &.mobileSearchIcon {
            display: none;
            .searchBtn {
              border: none;
              outline: none;
              background: transparent;
              padding: 5px;
              align-items: center;
              justify-content: center;
              display: inline-flex;
              @include radius(0px);
              &::before {
                display: none;
              }
            }
          }
          &.sideBarMenu {
            display: none;
            width: 48px;
            height: 60px;
            position: relative;
            button {
              cursor: pointer;
              background: none;
              position: absolute;
              width: 100%;
              height: 100%;
              border: 0px;
              padding: 12px;
              @include flex();
              align-items: center;
              justify-content: center;
              &:hover {
                background: $baseColor;
                outline: 0px;
              }
              &:focus {
                outline: 0px;
              }
              span {
                width: 24px;
                height: 2px;
                background: $white;
                position: relative;
                @include transition();
                &:before,
                &:after {
                  content: "";
                  width: 100%;
                  height: 2px;
                  background: $white;
                  display: block;
                  left: 0;
                  position: absolute;
                  @include transition();
                }
                &:before {
                  top: -8px;
                  @include rotate(0deg);
                }
                &:after {
                  top: 8px;
                  @include rotate(0deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal {
  &.switch-user-modal {
    .modal-content {
      border: none;
      width: 500px;
    }
    .modal-header {
      padding: 15px;
      border-bottom: 1px solid rgba($gray, 0.4);
      .modal-title {
        @include font(16);
        font-weight: 500;
        color: $blackColor;
      }
      .close {
        font-weight: 400;
        color: $blackColor;
        outline: none;
      }
    }
    .modal-body {
      padding: 20px;
      margin-bottom: 25px;
      border-bottom: 1px solid rgba($gray, 0.4);
      .switch-AccountUser-list {
        list-style: none;
        padding-left: none;
        overflow: hidden;
        overflow-y: scroll;
        height: auto;
        max-height: 200px;
        // Window Scrolbar
        &::-webkit-scrollbar {
          border-radius: 10px;
          width: 8px;
          background: rgba($gray, 0.3%);
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba($gray, 0.8%);
          cursor: pointer;
        }
        .item {
          float: left;
          width: 30.333%;
          margin-right: 3%;
          padding: 10px;
          position: relative;
          &:nth-child(3n + 3) {
            margin-right: 0;
          }
          &:nth-child(3n + 4) {
            clear: both;
          }
          input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
            &:checked + label {
              color: $lightBlue;
              svg {
                path {
                  fill: $lightBlue;
                }
              }
              &:after {
                border: 1px solid $lightBlue;
              }
              &:before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-color: $lightBlue;
                right: 16px;
                top: 16px;
              }
            }
          }
          label {
            display: block;
            background: $white;
            padding: 40px 10px 20px;
            @include font(14);
            color: rgba($black, 0.8);
            line-height: 1.3;
            @include radius(6px);
            box-shadow: 0 0 18px #0000001a;
            text-align: center;
            cursor: pointer;
            position: relative;
            &:after {
              content: "";
              width: 18px;
              height: 18px;
              border: 1px solid $gray;
              @include radius(50%);
              position: absolute;
              right: 12px;
              top: 12px;
            }
            span {
              display: block;
              &:first-child {
                margin: 0 auto 15px;
              }
              &:last-child {
                min-height: 35px;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      justify-content: center;
      padding: 0 15px 25px;
      border-top: none;
      button {
        background: $lightBlue;
        padding: 10px 15px;
        @include font(14);
        font-weight: 300;
        min-width: 100px;
        margin: 0 0.35rem;
      }
    }
  }
}
@media screen and(max-width:1199px) {
  header {
    &.dashboard {
      padding: 0 1em;
      nav {
        > ul {
          li {
            a {
              padding-left: .5em;
              padding-right: .5em;
              @include font(15);
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:1023px) {
  header {
    &.dashboard {
      nav {
        > ul {
          li {
            &.mobileSearchIcon {
              display: block;
            }
            &.mobileSearchIcon {
              a {
                svg {
                  margin-right: 0px;
                }
              }
              &:hover {
                background: none;
              }
            }
          }
        }
      }
      div {
        &.searchBar {
          display: none;
        }
      }
    }
  }
  #searchModal {
    padding-right: 0px !important;
    bottom: auto !important;
    overflow: inherit!important;
    &.show {
      display: block;
    }
    .modal-dialog {
      max-width: 100%;
      margin: 0px;
    }
    .modal-content {
      @include radius(0);
      background: $blackBg;
      .modal-body {
        @include flex();
        padding: 0.517em 1em;
        width: 100%;
        max-width: 100%;
        .close {
          float: none;
          display: block;
          padding: 10px;
          margin-right: 1em;
          svg {
            height: 18px;
            width: auto;
          }
        }
        input[type="search"] {
          border: 0px;
          flex-grow: 1;
          padding: 0 1em;
          @include radius(30px);
          background: rgba($white, 0.9%);
          width: 100%;
          max-width: 100%;
          @include transition();
          &:focus {
            background: $white;
          }
        }
      }
    }
  }
}

@media screen and(max-width:992px) {
  header {
    &.dashboard {
      padding-right: 0px;
      nav {
        > ul {
          &:first-of-type {
            position: fixed;
            @include translateX(-110%);
            opacity: 0;
            visibility: hidden;
            @include transition();
            left: 0px;
            top: 0px;
            right: 48px;
            bottom: 0px;
            flex-direction: column;
            justify-content: flex-start;
            li {
              width: calc(100% - 2em);
              border-bottom: 1px dotted rgba($white, 0.2%);
              margin: 0;
              a {
                padding: 0.5em 1em;
                @include radius(4px);
                margin: 0.5em 0;
                display: block;
                &.upgradeLink {
                  background: $lightBlue;
                  &:hover {
                    background: $green;
                  }
                }
              }
              &.icon {
                a {
                  width: 100%;
                  img {
                    margin-top: 12px;
                  }
                }
              }
            }
          }
          li {
            &.sideBarMenu {
              display: block;
              margin-left: 10px;
              button {
                &.btn {
                  padding: 10px 1em;
                  height: 60px;
                }
              }
            }
            a {
              @include font(16);
            }
          }
        }
      }
    }
  }

  .menu-open {
    overflow: hidden;
    header {
      &.dashboard {
        nav {
          > ul {
            &:first-of-type {
              background: #040404;
              z-index: 99;
              opacity: 1;
              @include translateX(0);
              visibility: visible;
              overflow-y: auto;
            }
            li {
              &.sideBarMenu {
                button {
                  background: $baseColor;
                  span {
                    background: none;
                    &:before {
                      top: 0px;
                      @include rotate(45deg);
                    }
                    &:after {
                      top: 0px;
                      @include rotate(-45deg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:639px) {
  header {
    &.dashboard {
      div {
        &.logoPart {
          a {
            img {
              display: none;
              &.mobile {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 567px) {
  .modal {
    &.switch-user-modal {
      .modal-body {
        padding: 15px 5px;
        .switch-AccountUser-list {
          .item {
            padding: 8px 5px;
            label {
              padding-left: 10px;
              padding-right: 10px;
              @include font(13);
            }
          }
        }
      }
    }
  }
}
