@import  "../../assets/css/mixin";
@import "../../assets/css/variables";
@import "../../assets/css/reset";

.onBoardTwitterSection{
    @include flex();
    align-items: center;
    justify-content: center;
    padding: 50px 15px;
    min-height: calc(100vh - 120px);
    margin-top: 60px;
    .twitterBlock{
        width: 100%;
        max-width: 420px;
        margin: 0 auto; 
        @include radius(6px);
        .formSection{
            padding: 30px;
            width: 100%;
            border: 1px solid $gray;
            @include radius(6px);
            .heading{
                @include font(16);
                font-weight: 600;
                color:$black;
                line-height: 22px;
                margin: 0 0 20px 0;
            }
            .fieldBox{
                margin-bottom: 20px;
                label{
                    display: block;
                    @include font(16);
                    font-weight: 400;
                    color: $black;
                    margin: 0 0 10px 0;
                }
                input{
                    padding: 10px 10px;
                    width: 100%;
                    @include font(14);
                    color:$black;
                    outline: none;
                    // border: 1px solid $gray;
                    @include radius(4px);
                    &::placeholder{
                        color:$black; 
                    }
                }
                &.hide{
                    display: none;
                }
                .box-with-icon {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid $gray;
                    height: 43px;
                    @include radius(4px);
            
                    input {
                      border: none;
                      border-radius: none;
                      padding-top: 0;
                      padding-bottom: 0;
                    }
            
                    span {
                      @include flex();
                      align-items: center;
                      justify-content: space-between;
                      padding: 0 10px;
                      height: 43px;
                      @include font(16);
                      font-weight: 600;
                      color: $black;
                      text-align: center;
                      border-right: 1px solid $gray;
                    }
                  }
            }
            .skip {
                @include font(14);
                font-weight: 400;
                color: $black;
                text-align: center;
                a {
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: none;
                    color: $baseColor;
                    cursor: pointer;
                }
            }
        }
        .continueBtnBox {
            text-align: center;
            margin-top: 40px;
            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                background-color: $green;
                padding: 10px 15px;
                min-width: 100px;
                min-height: 45px;
                @include font(16);
                color: $white;
                @include radius(4px);
                border: none;
                outline: none;
                box-shadow: none;
                cursor: pointer;
            }
        }
    }
}
.modal {
    padding-right: 0!important;
    &.skip-popup {
        .modal-dialog {
            max-width: 580px;
        }
        .modal-content {
            border: none;
        }
        .modal-body {
            padding: 50px 45px;
            text-align: center;
            position: relative;
            button {
                &.close {
                    outline: none;
                    position: absolute;
                    top: 15px;
                    right:20px;
                    opacity: 1;
                }
            }
            .modal-title {
                @include font(30);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 15px;
                + p {
                    margin-bottom: 25px
                }
            }
            p {
                @include font(14);
                font-weight: 400;
                color: $black;
                margin-bottom: 15px;
            }
            .btn {
                padding: 12px 25px;
                @include font(14);
                font-weight: 400;
            }
            .skip {
                margin-top: 15px;
                margin-bottom: 0;
                a {
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: underline;
                    @include font(14);
                    color: $baseColor;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:567px) {
    .modal {
        &.skip-popup {
            .modal-body {
                padding: 45px 25px 35px;
                .modal-title {
                    @include font(20);
                }
            }
        }
    }
}
@media screen and (max-width:479px) {
    .onBoardTwitterSection{
        padding-top: 30px;
        padding-bottom: 30px;
        min-height: 1px;
        .twitterBlock{
            .formSection{
                padding: 20px;
            }
            .continueBtnBox{
                a{
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
    }  
}