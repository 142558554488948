@import "../../../../../../../../assets/css/variables";
@import "../../../../../../../../assets/css/mixin";
@import "../../../../../../../../assets/css/reset";

.faq-sec {
    background: $bgLightGray;
    padding: 30px 0 35px;
    margin-top: 60px;
    .faq-box-wrapper {
        background: $white;
        max-width: 900px;
        padding: 20px 30px;
        margin: 0 auto;
        border: 1px solid $gray;
        @include radius(5px);
        .sec-title {
            @include font(18);
            font-weight: 600;
            color: $black;
            margin-bottom: 25px;
            text-align: center;
            span {
                display: block;
                margin-bottom: 15px;
            }
        }
        .search-box-wrap {
            margin-bottom: 35px;
            .search-box {
                max-width: 380px;
                margin: 0 auto;
                position: relative;
                input {
                    padding: 10px 45px 10px 10px;
                    @include font(14);
                    font-weight: 400;
                    color: $black;
                    width: 100%;
                    height:45px;
                    border:1px solid $gray;
                    @include radius(4px);
                    &::placeholder {
                        color: $gray;
                    }
                }
                button {
                    display: inline-block;
                    vertical-align: middle;
                    background: none;
                    border: 0;
                    outline: none;
                    position: absolute;
                    width: 40px;
                    height: 45px;
                    right: 0;
                    top:0;
                    z-index: 11;
                    svg {
                        .a {
                            opacity:0.3;
                        }
                        .b {
                            fill:none;
                            stroke:$blackColor;
                            stroke-linecap:round;
                            stroke-linejoin:round;
                            stroke-width:1.5px;
                        }
                    }
                }
            }
            .btn {
                display: inline-block;
                vertical-align: middle;
                padding: 12px 15px;
                @include font(14);
                font-weight: 500;
                height: 45px;
                @include radius(4px);
                svg {
                    margin-right: 5px;
                    path {
                        fill: $white;
                    }
                }
            }
            &.with-button {
                display: block;
                margin: 0 auto 30px;
                .search-box {
                    max-width: 600px;
                    width: auto;
                    margin: 0 auto;
                    input {
                        padding: 10px 100px 10px 30px;
                    }
                    .search-icon {
                        position: absolute;
                        left: 10px;
                        top: 12px;
                        svg {
                            .a {
                                opacity:0.3;
                            }
                            .b {
                                fill:none;
                                stroke:$blackColor;
                                stroke-linecap:round;
                                stroke-linejoin:round;
                                stroke-width:1.5px;
                            }
                        }
                    }
                    .blue {
                        background: $lightBlue;
                        width: 90px;  
                    }
                }
            }
        }
    }
    .faq-listing {
        margin-bottom: 35px; 
        li {
            padding-bottom: 25px;
            margin-bottom: 15px;
            margin-bottom: 25px;
            border-bottom: 1.5px solid rgba($gray, .50%);
            @include transition();
            &:last-child {
                margin-bottom: 0;
            }
            .faq-title {
                position: relative;
                @include font(15);
                color: $blackColor;
                padding-right: 30px;
                line-height: 1.4;
                cursor: pointer;
                span {
                    display: inline-block;
                    vertical-align: middle;
                    color:$baseColor;
                    margin-right: 5px;
                }
                &:after {
                    content: '+';
                    @include font(35);
                    color: $baseColor;
                    display: block;
                    width: 28px;
                    height: 28px;
                    line-height: 28px;
                    position: absolute;
                    right: -5px;
                    top: -5px;
                    text-align: center;
                }
                &.active {
                    &:after {
                        content: "";
                        font-size: 0;
                    }
                    &:before {
                        content: "";
                        width: 18px;
                        position: absolute;
                        background:$baseColor;
                        height: 2.8px;
                        right: 0;
                        top: 7px;
                    }
                }
            }
            .faq-data {
                display: none;
                @include font(15);
                color: $blackColor;
                padding-top: 18px;
                line-height: 1.6;
            }
        }
    }
    .paginationBox {
        @include flex();
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 15px;
        div {
            &.perPage {
                @include flex();
                align-items: center;
                width: 120px;
                input {
                    padding: 5px;
                    width: 35px;
                    height: 26px;
                    @include font(14);
                    color: $black;
                    border: 1px solid $gray;
                    outline: none;
                    box-shadow: none;
                    @include radius(3px);
                    text-align: center;
                }
                label {
                    display: inline-flex;
                    @include font(14);
                    color: $black;
                    margin-left: 10px;
                }
            }
            &.pageNav {
                width: calc(100% - 240px);
                text-align: center;
                ul {
                    display: inline-flex;
                    justify-content: center;
                    border: 1px solid $gray;
                    @include radius(3px);
                    width: auto;
                    padding:0;
                    margin: 0;
                    li {
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        list-style: none;
                        width: 26px;
                        height: 26px;
                        border-right: 1px solid $gray;
                        span {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 26px;
                            height: 26px;
                            @include font(14);
                            color: #626262;
                            cursor: pointer;
                            &:hover {
                                background-color: $lightBlue;
                                color: $white;
                                svg {
                                    fill: $white;
                                }
                            }
                        }
                        &.backLink {
                            svg {
                                width: 10px;
                                height: auto;
                                fill:#626262 ;
                                transform: rotate(180deg);
                            }
                        }
                        &.forLink {
                            svg {
                                width: 10px;
                                height: auto;
                                fill:#626262 ;
                            } 
                        }
                        &:last-child {
                            border-right: none
                        }
                        &.active {
                            span {
                                background-color: $lightBlue;
                                color: $white;
                                svg {
                                    fill: $white;
                                }
                            }
                        }
                        a {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;
                            width: 26px;
                            height: 26px;
                            @include font(14);
                            color: #626262;
                            cursor: pointer;
                            &:hover {
                                background-color: $lightBlue;
                                color: $white;  
                            }
                        }
                        &.active {
                            a {
                                background-color: $lightBlue;
                                color: $white;  
                            }
                        }
                    }
                }
            }
            &.goBox {
                @include flex();
                justify-content: flex-end;
                align-items: center;
                width: 120px;
                input {
                padding: 5px;
                width: 35px;
                height: 26px;
                @include font(14);
                color: $black;
                text-align: center;
                border: 1px solid $gray;
                outline: none;
                box-shadow: none;
                @include radius(3px);
                }
                a {
                    display: inline-flex;
                    align-items: center;
                    text-decoration: none;
                    padding: 5px;
                    @include font(14);
                    color: $black;
                    margin-left: 10px;
                    cursor: pointer;
                    span {
                        display: inline-flex;
                        &.icon { 
                            margin-left: 5px;
                            svg {
                                fill: $black;
                                .a {
                                    opacity:1; 
                                    fill: $black
                                }  
                            }
                        }
                    }
                }  
            }
        }
    }
    .help-form-box {
        max-width: 600px;
        margin: 0 auto;
        .form-group {
            margin-bottom: 15px;
            position: relative;
            label {
                display: block;
                @include font(16);
                color: $gray;
                margin-bottom: 10px;
            }
            .form-control {
                display: block;
                width: 100%;
                padding: 15px;
                @include font(15);
                color: $black;
                border: 1px solid $gray;
                @include radius(4px);
                height: 60px;
                outline: none;
                box-shadow: none;
                &::placeholder {
                    color: $gray;
                }
            }
            textarea {
                position: relative;
                &.form-control {
                    @include flex;
                    min-height: 150px;
                    line-height: 1.6;
                    padding-right: 45px;
                    &::-webkit-scrollbar {
                        border-radius: 10px;
                        width: 10px;
                        height: 12px;
                        background: rgba($gray, 0.3%);
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background: rgba($gray, 0.8%);
                        cursor: pointer;
                    }
                }
                &::placeholder {
                    color: $gray;
                }
            }
            button {
                margin-top: 25px;
                &.lightblue {
                    @include font(14);
                    font-weight: 300;
                    background: $lightBlue;
                }
            }
            .file-upload-value {
                position: absolute;
                background:$bgLightGray;
                padding: 5px 10px;
                @include font(14);
                color: $black;
                font-weight: 500;
                left: 15px;
                bottom: 15px;
                border: none;
                outline: none;
                margin-top: 0;
                @include radius(50px);
            }
            .upload-file-box {
                position: absolute;
                background: url(../img/upload-icon.png) no-repeat;
                background-position: center center;
                width: 27px;
                height: 36px;
                right: 12px;
                bottom: 10px;
                cursor: pointer;
                z-index: 1;
                input {
                    position: absolute;
                    width: 27px;
                    height:36px;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 1;
                }
            }
        }
        .question-error {
            position: relative;
            margin-top:-8px!important;
        }
    }
}
@media screen and (max-width:767px) {
    .faq-sec {
        .faq-box-wrapper {
            padding-left: 15px;
            padding-right: 15px;
            .search-box-wrap {
                margin-bottom: 25px;
            }
        }
        .faq-listing {
            > li {
                padding-bottom: 18px;
                margin-bottom: 18px;
                .faq-title {
                    &:after {
                        @include font(32);
                        top: -2px;
                    }
                    &.active {
                        &:before {
                            width: 17px;
                            top: 10px;
                        }
                    }
                }
                .faq-data {
                    padding-top: 15px;
                    @include font(14);
                }
            }
        }
    }
}
@media screen and (max-width:579px) {
    .faq-sec {
        padding-bottom: 30px;
        .faq-box-wrapper {
            .sec-title {
                margin-bottom: 20px;
                span {
                    margin-bottom: 10px;
                }
            }
            .search-box-wrap {
                margin-bottom: 22px;
            }
        }
        .faq-listing {
            margin-bottom: 20px;
            > li {
                padding-bottom: 15px;
                margin-bottom: 15px;
                .faq-title {
                    @include font(14);
                    &:after {
                        @include font(32);
                        top: -2px;
                    }
                    &.active {
                        &:before {
                            width: 17px;
                            top: 10px;
                        }
                    }
                }
                .faq-data {
                    padding-top: 10px;
                    @include font(13);
                }
            }
        }
        .paginationBox {
            margin-bottom: 0;
            div {
                &.perPage {
                    order: 1;
                }
                &.goBox {
                    order: 2;
                    margin-left: auto;
                }
                &.pageNav {
                    order: 3;
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
        .help-form-box {
            .form-group {
                label {
                    @include font(16);
                }
                .form-control {
                    height: 45px;
                }
                textarea {
                    &.form-control {
                        min-height: 120px;
                    }
                }
                &:last-child {
                    margin-bottom: 5px;
                }
            }  
        }
    }
}