@import  "../../assets/css/mixin";
@import "../../assets/css/variables";
@import "../../assets/css/reset";

.onBoardCongratAthleteSection{
    min-height: calc(100vh - 120px);
    padding: 50px 15px;
    @include flex();
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    .congratAthleteBlock{
        max-width: 680px;
        border: 1px solid $gray;
        @include radius(4px);
        padding: 0px 30px 30px 30px;
        @include flex();
        div{
            &.medalImg{
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
            &.data{
                @include flex();
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                width: calc(100% - 250px);
                margin-left: auto;
                .heading{
                    @include font(25);
                    color: $blackColor;
                    margin: 0 0 20px 0;
                }
                .info{
                    @include font(14);
                    color: $black;
                    line-height: 22px;
                    margin: 0 0 20px 0;
                    b{
                        color: $blackColor;
                    }
                }
                .btnBox{
                    a{
                        background-color: $green;
                        padding: 15px 20px;
                        display: inline-flex;
                        text-decoration: none;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        cursor: pointer;
                        @include radius(4px);
                        @include font(14);
                        color: $white;
                        text-transform: none;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:767px) {
    .onBoardCongratAthleteSection{
        .congratAthleteBlock{
            div{
                &.data{
                    width: calc(100% - 230px);
                }
            }
        }
    }  
}

@media screen and (max-width:575px) {
    .onBoardCongratAthleteSection{
        padding-top: 35px;
        padding-bottom: 35px;
        .congratAthleteBlock{
            padding: 0 20px 20px 20px;
            flex-wrap: wrap;
            div{
                &.data{
                    width: calc(100% - 0px);
                    margin-top: 15px;
                    .heading {
                        @include font(20);
                        margin-bottom: 10px;
                    }
                }
                &.medalImg{
                    height: 150px;
                    img{
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;
                    }
                }
            }
        }  
    }
}