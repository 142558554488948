@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.modal {
  &.statusUpdateModal {
    .modal-dialog {
      max-width: 540px;
      margin: 15px 15px;
      max-height: calc(100% - 30px);
      width: calc(100% - 30px);
      .modal-content {
        .modal-header {
          padding: 20px;
          position: relative;
          border-bottom: none;
          align-items: center;
          button {
            &.close {
              min-width: auto;
              padding: 5px;
              float: none;
              margin: 0 0 0 auto;
              outline: none;
              svg {
                .a {
                  opacity: 0.7;
                  fill: $black;
                }
              }
            }
          }
          &::after {
            content: "";
            display: inline-block;
            width: calc(100% - 30px);
            bottom: 0;
            left: 15px;
            position: absolute;
            height: 1px;
            background-color: $gray;
          }
        }
        .modal-body {
          padding: 20px;
          .profileFlexBox {
            @include flex();
            align-items: flex-start;
            margin-bottom: 15px;
            .imgBox {
              width: 50px;
              height: 50px;
              @include radius(50%);
              margin-right: 10px;
              @include flex();
              align-items: center;
              justify-content: center;
              img {
                max-width: 100%;
                height: auto;
                &.defaultUserImg{
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  border: 1px solid $gray;
                  text-align: center;
                  padding: 14px;
                }
              }
            }
            .info {
              width: calc(100% - 60px);
              h5 {
                &.name {
                  @include font(16);
                  margin: 0 0 10px 0;
                  color: $blackColor;
                }
              }
              .selectField {
                select {
                  width: 100px;
                  @include radius(30px);
                  border: 1px solid #707070;
                  padding: 3px 15px 3px 10px;
                  @include font(14);
                  color: $black;
                  appearance: none;
                  background: url(../img/arrowdown.png) no-repeat;
                  background-size: 10px;
                  background-position: 95% center;
                }
              }
            }
          }
          .textBox {
            margin-bottom: 15px;
            textarea {
              padding: 10px;
              width: 100%;
              height: 150px;
              overflow-x: hidden;
              overflow-y: auto;
              background-color: $white;
              @include radius(4px);
              @include font(14);
              color: rgba($black, .50%);
              line-height: 22px;
              border: 1px solid $gray;
              outline: none;
              resize: none;
              &::placeholder {
                color: rgba($black, .50%);
              }
            }
            .linkText {
              padding: 10px;
              width: 100%;
              height: 73px;
              overflow-x: hidden;
              overflow-y: auto;
              background-color: $white;
              @include radius(4px);
              @include font(14);
              color: rgba($black, .50%);
              line-height: 22px;
              border: 1px solid $gray;
              outline: none;
              resize: none;
              &::placeholder {
                color: rgba($black, .50%);
              }
            }
            .imgWrap {
              @include flex();
              justify-content: flex-start;
              flex-wrap: wrap;
              img {
                width: 32.5%;
                height: 120px;
                object-fit: contain;
                object-position: center;
                margin-top: 0.50em;
                margin-right: 1.25%;
                background:rgba($gray, .4);
                &:nth-child(3n+3) {
                  margin-right: 0;
                }
              }
            }
          }
          .flexBox {
            @include flex();
            align-items: center;
            justify-content: space-between;
            ul {
              @include flex();
              align-items: center;
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                margin-right: 15px;
                @include flex();
                align-items: center;
                a {
                  text-decoration: none;
                  display: inline-block;
                  cursor: pointer;
                  padding: 5px;
                  &.videoLink {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: #19d7cd;
                          }
                        }
                      }
                    }
                  }
                  &.attachLink {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: none;
                            stroke: $baseColor;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 2px;
                          }
                        }
                      }
                    }
                  }
                  &.photoLink {
                    span {
                      &.icon {
                        svg {
                          .a {
                            fill: $yellow;
                          }
                        }
                      }
                    }
                  }
                  span {
                    @include font(14);
                    display: inline-block;
                    color: $black;
                    &.icon {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
            button {
              outline: none;
              cursor: pointer;
              &.postBtn {
                color: $white;
                background-color: $lightBlue;
                @include radius(4px);
                padding: 15px 20px;
                border: none;
                @include font(14);
              }
            }
          }
        }
      }
    }
    &.show {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
}

@media screen and (max-width:575px) {
  .modal {
    &.statusUpdateModal {
      .modal-dialog {
        .modal-content {
          .modal-header {
            padding-left: 15px;
            padding-right: 15px;
          }
          .modal-body {
            padding-left: 15px;
            padding-right: 15px;
            .textBox {
              .imgWrap {
                img {
                  width: 32%;
                  height: 80px;
                  margin-right: 2%;
                }
              }
            }
          }
          .modal-footer {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}