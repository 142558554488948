@import  "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";


.onBoardCongratAthleteSection{
    min-height: calc(100vh - 120px);
    padding: 50px 15px;
    @include flex();
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    .congratAthleteBlock{
        max-width: 680px;
        border: 1px solid $gray;
        @include radius(4px);
        padding: 0px 30px 30px 30px;
        @include flex();
        div{
            &.medalImg{
                img{
                    max-width: 100%;
                    height: auto;
                }
            }
            &.data{
                @include flex();
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                width: calc(100% - 250px);
                margin-left: auto;
                .heading{
                    font-size: 25px;
                    color: $blackColor;
                    margin: 0px 0px 25px 0px;
                }
                .info{
                    margin: 0px 0px 20px 0px;
                    color: $black;
                    line-height: 20px;
                    font-size: 14px;
                    b{
                        color: $blackColor;
                    }
                }
                .btnBox{
                    a{
                        padding: 15px 20px;
                        border: none;
                        outline: none;
                        box-shadow: none;
                        cursor: pointer;
                        color: $white;
                        background-color: $green;
                        @include radius(4px);
                        font-size: 14px;
                        text-decoration: none;
                        text-transform: none;
                        display: inline-flex;
                    }
                }
            }
        }
    }
}


@media screen and (max-width:767px) {
    .onBoardCongratAthleteSection{
        .congratAthleteBlock{
            div{
                &.data{
                    width: calc(100% - 230px);
                }
            }
        }
    }  
}


@media screen and (max-width:575px) {
    .onBoardCongratAthleteSection{
        .congratAthleteBlock{
            padding: 0px 20px 20px 20px;
            flex-wrap: wrap;
            div{
                &.data{
                    width: calc(100% - 0px);
                    margin-top: 30px;
                }
                &.medalImg{
                    height: 150px;
                    img{
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;
                    }
                }
            }
        }  
}
}