@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";
.clearfix {
  *zoom: 1;
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  &:before {
    content: " ";
    display: table;
  }
}
.MuiMenuItem-root {
  font-size: 14px!important;
  white-space: break-spaces!important;
  min-height: 1px!important;
  em {
    font-style: normal;
  }
}
.swal-title {
  @include font(24);
  line-break: anywhere;
}
.shortTextBox {
  background: $white;
  padding: 15px;
  line-height: 22px;
  border: 1px solid $gray;
  @include radius(5px);
  margin-top: 15px;
  margin-bottom: 15px;
}
.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 999;
  }
}
.editProfile {
  .flex {
    background: $white;
    width: 100%;
    @include flex();
    justify-content: flex-end;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
  }
  .subHeader {
    background: $blackBg;
    width: 100%;
    @include flex();
    justify-content: space-between;
    align-items: center;
    padding: 0.7em 1em;
    margin-bottom: 1px;
    min-height: 60px;
    .menu {
      button {
        background: none;
        @include flex();
        align-items: center;
        @include font(18);
        font-weight: 500;
        color: $white;
        border: 0;
        cursor: pointer;
        img {
          display: block;
          margin-right: 0.8em;
        }
        &:focus {
          outline: none;
        }
      }
      span {
        background-image:url(../img/menu-back-icon.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 30px;
        width: 32px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em;
        position: relative;
      }
    }
    .profile-verified-text {
      @include flex();
      align-items: center;
      @include font(14);
      font-weight: 300;
      color: $white;
      .icon {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        margin-right: 10px;
      }
      .verifiedIcon{
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        margin-right: 10px;
        svg{
          fill: #00B9FF;
          path{
            fill: #00B9FF;
          }
        }
      }
      a {
        display: inline-block;
        vertical-align: middle;
        text-decoration: underline;
        color: $orange;
        padding-left: 5px;
        &:hover {
          color: $baseColor;
        }
      }
    }
    .save {
      button {
        background: $lightBlue;
        padding: 0.6758em 1em;
        @include flex();
        align-items: center;
        @include font(16);
        font-weight: 500;
        color: $white;
        border: 0;
        @include radius(3px);
        @include transition();
        cursor: pointer;
        svg {
          margin-right: 10px;
          @include translateY(-1px);
          path {
            fill: $white;
            @include transition();
            fill: $white;
          }
          rect {
            fill: $white;
            @include transition();
            fill: $white;
          }
        }
        &:hover {
          background: $lightBlue;
          color: $white;
          svg {
            path {
              fill: $white;
            }
            rect {
              fill: $white;
            }
          }
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .sidebar {
    background: $white;
    position: absolute;
    width: 300px;
    left: 0;
    top: 61px;
    bottom: 0;
    z-index: 99;
    border: 1px solid $border;
    border-top: 0;
    overflow: hidden;
    @include transition(all, 0.5s, cubic-bezier(0.41, 0.18, 0.26, 0.99));
    .progressbar {
      background: rgba($green, 0.2%);
      height: 24px;
      position: relative;
      overflow: hidden;
      @include radius(20px);
      .progress {
        background: $green;
        @include flex();
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 65%;
        height: 100%;
        left: 0;
        top: 0;
        @include font(14);
        font-weight: 800;
        color: $white;
        @include radius(20px);
      }
      &.small {
        width: 46px;
        height: 10px;
      }
    }
    .profileImg {
      @include flex();
      justify-content: center;
      flex-wrap: wrap;
      background: url("../img/profileBg.png") center no-repeat;
      background-size: cover;
      min-height: 350px;
      padding: 2em;
      text-align: center;
      .profile {
        position: relative;
      }
      .pic {
        background: $white;
        @include flex();
        align-items: center;
        justify-content: center;
        position: relative;
        width: 140px;
        height: 140px;
        @include font(35);
        font-weight: 800;
        border: 2px solid $white;
        @include radius(100%);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
          &.verified {
            position: absolute;
            max-width: 100%;
            height: auto;
            width: auto;
            right: 4px;
            bottom: 2px;
            @include translate(50%, 0);
          }
          &.verified.new {
            width: 35px;
          }
        }
        span{
          svg{
            position: absolute;
            max-width: 100%;
            height: auto;
            width: 30px;
            right: 6px;
            bottom: 2px;
            @include translate(50%, 0);
          }
        }
      }
      .browse {
        position: absolute;
        left: 80%;
        top:5px;
        bottom: auto;
        transform: none;
        padding: 12px;
        cursor: pointer;
        label {
          background: $black;
          @include font(15);
          font-weight: bold;
          line-height: 24px;
          text-align: center;
          color: $white;
          position: absolute;
          width: 24px;
          height: 24px;
          right: 0;
          top: 0;
          @include radius(100%);
          z-index: 11;
          cursor: pointer;
        }
        input[type="file"] {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          z-index: 3;
          opacity: 0;          
          cursor: pointer;
        }
      }
      .name {
        width: 100%;
        margin: 1.5em 0 0.75em;
        h2 {
          @include font(18);
          font-weight: 600;
          color: $white;
          margin-bottom: 5px;
        }
        h3 {
          @include font(16);
          font-weight: 500;
          color: $white;
          + h3 {
            margin-bottom: 0;
          }
        }
      }
      .back {
        a {
          display: block;
          background: $yellow;
          padding: 1em 2em;
          color: $white;
          @include radius(30px);
          @include transition();
          img {
            margin-right: 10px;
          }
          &:hover {
            background: $lightBlue;
            text-decoration: none;
            img {
              animation: animArrowRev infinite 0.5s;
            }
          }
        }
      }
    }
    .profileCompletion {
      padding: 1.25em 1em;
      border-bottom: 1px solid $border;
      small {
        display: block;
        @include font(13);
        color: $blackColor;
      }
      .completion {
        @include font(15);
        font-weight: bold;
        margin: 0.25em 0 1em;
      }
    }
    .checklist {
      padding: 0.75em 0;
      li {
        @include flex();
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        padding: 0 1em;
        position: relative;
        @include transition();
        &.active {
          background: rgba($green, 0.1%);
          &::before {
            content: "";
            background: $green;
            position: absolute;
            width: 4px;
            height: 60%;
            left: 0;
            border: 0;
            @include radius(2px);
          }
        }
        &:hover {
          background: rgba($green, 0.1%);
        }
      }
      .title {
        flex-grow: 1;
      }
      a {
        padding: 1em 0;
        @include flex();
        align-items: center;
        text-decoration: none;
        color: $black;
        span {
          display: inline-block;
          position: relative;
          margin-left: 10px;
          em {
            @include flex();
            align-items: center;
            justify-content: center;
            background: $yellow;
            @include font(12);
            font-style: normal;
            font-weight: bold;
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            right: 0;
            @include radius(50%);
            @include translate(60%, -40%);
          }
        }
        &:hover {
          color: $green;
        }
      }
    }
  }
  .editSection {
    width: calc(100% - 300px);
    min-height: 1260px;
    @include transition(all, 0.5s, cubic-bezier(0.41, 0.18, 0.26, 0.99));
    position: relative;
    .subTitle {
      background: rgba($border, 0.2%);
      position: relative;
      padding: 15px 20px;
      @include font(18);
      color: $green;
      margin: 20px 20px 0.5em;
      &:before {
        content: "";
        background: $green;
        position: absolute;
        width: 4px;
        height: 100%;
        left: 0;
        top: 0;
        @include radius(4px);
      }
    }
    .flexRow {
      @include flex();
      justify-content: space-between;
      align-items: baseline;
      padding: 0.5em 1em;
      position: relative;
      &:first-of-type {
        padding: 0.75em 1em;
        align-items: center;
        border-bottom: 1px solid $border;
      }
      .title {
        @include font(20);
        font-weight: 500;
      }
      .btnWrap {
        @include flex();
        .btn {
          margin-left: 1em;
          &.btn-statreport {
            background:none;
            padding: 10px 15px;
            border:1px solid $baseColor;
            color:$baseColor;
            line-height:18px;
            .icon-box {
              margin-right:5px;
              @include transition();
              svg {
                @include transition();
                path {
                  fill:$baseColor;
                }
              }
            }
            &:hover {
              background:$baseColor;
              color:$white;
              .icon-box {
                svg {
                  path {
                    fill:$white;
                  }
                }
              }
            }
          }
        }
        .backprofileBtn {
          display: none;
        }
      }
      .col {
        flex-grow: 1;
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
        .date-close-icon {
          position: absolute;
          right: 48px;
          top: 14px;
          @include font(22);
          font-weight: 300;
          color:$blackColor;
          cursor: pointer;
          z-index: 1;
        }
      }
      label {
        display: block;
        margin-bottom: 0.5em;
        sup {
          display: inline-block;
          vertical-align: -5px;
          @include font(15);
          color: $baseColor;
          font-weight: 500;
        }
      }
      input[type="text"],
      input[type="date"] {
        background: none;
        padding: 0.65em 1em;
        width: 100%;
        border: 1px solid $border;
        @include radius(4px);
      }
      .note {
        color: rgba($black, 0.7%);
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
      &.subscription {
        background: $green;
        @include radius(4px);
        margin: 1.5em 1em 1em;
      }
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        + label {
          display: inline-block;
          padding: 0.65em 1em 0.65em 2em;
          color: $white;
          line-height: 18px;
          margin: 0;
          position: relative;
          cursor: pointer;
          &:before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            top: 50%;
            left: 0;
            border: 0.1em solid $white;
            @include radius(2px);
            @include translateY(-50%);
          }
          &:after {
            content: "";
            background: url("../img/check.svg") center no-repeat;
            width: 18px;
            height: 18px;
            position: absolute;
            left: 0;
            top: 50%;
            @include translateY(-50%);
            @include transition();
            opacity: 0;
          }
        }
        &:checked {
          + label {
            &:after {
              opacity: 1;
            }
          }
        }
      }
      &.link {
        justify-content: flex-start;
        a {
          display: inline-block;
          color: $baseColor;
          margin-left: 5px;
          &:hover {
            color: #b12c1a;
          }
        }
      }
    }
    .btnRow {
      @include flex();
      justify-content: center;
      padding: 1.5em 0;
      margin: 1em;
      border-top: 1px solid $border;
      &.with-next-prev {
        justify-content: inherit;
        align-items: center;
        padding-bottom: 15px;
        button {
          padding: 10px 25px;
          margin: 0;
        }
        .buttons {
          button {
            margin-right: 15px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .next-prev-button {
          margin-left: 15px;
          button {
            display: inline-block;
            vertical-align: middle;
            @include font(16);
            font-weight: 400;
            color: rgba($black, .6);
            padding: 0;
            background: none;
            border: none;
            margin-right: 10px;
            @include transition();
            &:last-child {
              margin-right: 0;
            }
            svg {
              width: 18px;
              height: 18px;
              margin-top: -1px;
            }
            &:hover {
              color: $baseColor;
            }
          }
        }
      }
      button {
        margin: 0 0.5em;
      }
    }
    .report-confirmationMessage {
      @include flex();
      align-items: center;
      justify-content: flex-start;
      background:$green;
      padding:10px 45px 10px 15px;
      width:520px;
      position: absolute;
      right: 15px;
      top: 8px;
      @include radius(6px);
      .checkIcon {
        display: inline-block;
        vertical-align: middle;
        margin-right:10px;
      }
      .text {
        @include font(15);
        font-weight:300;
        color:$white;
        line-height:1.5;
      }
      .closeBtn {
        background: none;
        padding:0;
        position: absolute;
        right:15px;
        outline: none;
        border: none;
      }
    }
    svg {
      vertical-align: middle;
    }
    .MuiOutlinedInput-input {
      padding: 13.5px 14px;
      &::placeholder {
        color: $black !important;
      }
    }
    [class*="PrivateNotchedOutline-legendNotched-"] {
      max-width: 0;
    }
    .heightFieldBox {
      label {
        width: 100%;
      }
      .form-group {
        position: relative;
        float: left;
        width: 50%;
        padding-left: 30px;
        label {
          &.MuiFormLabel-root {
            position: absolute;
            left: 8px;
            top: 11px;
          }
        }
      }
    }
    .MuiAutocomplete-endAdornment {
      background: $white;
    }
    .MuiSelect-select.MuiSelect-select {
      padding-right: 35px;
    }
  }
  .inactive {
    .sidebar {
      @include translateX(-100%);
    }
    .subHeader {
      .menu {
        span {
          background-image:url(../img/menu-icon.svg);
        }
      }
    }
    .editSection {
      width: 100%;
      .btnWrap {
        .backprofileBtn {
          display: block;
        }
      }
    }
  }
}
.check-list-sec {
  padding: 1.25em 1em;
  &.professional-video-sec {
    padding-top: 0;
    .video-and-photo-wrap {
      .video-and-photo-main {
        border-top: none;
        .sidebarbox {
          padding-top: 20px;
        }
        .content-box {
          padding-top: 20px;
        }
      }
    }
  }
  &.statRepotData {
    max-width:600px;
    margin: 0 auto;
    .fieldBox {
      &.flexBox {
        &.three-col-row {
          align-items: flex-start;
          justify-content: start;
          flex-wrap: wrap;
          margin-left: -8px;
          margin-right: -8px;
          margin-bottom: 0;
          .three-col {
            width: 33.333%;
            padding:0 8px;
            margin-bottom: 20px;
          }
          &.two-col-row {
            justify-content: space-between;
            margin:0;
            .half {
              margin-bottom: 20px;
            }
          }
        }
      }
      &.buttonBox {
        .btn {
          padding:10px 15px;
          @include font(16);
          font-weight:400;
          line-height:20px;
          margin:0 10px 0 0;
          &:last-child {
            margin-right: 0;
          }
          &.green {
            border:1px solid $green;
          }
          &.green-outline {
            background: none;
            color:$green;
            border:1px solid $green;
            &:hover {
              background: $green;
              color:$white;
            }
          }
        }
      }
      .error{
        @include font(13);
        color: $baseColor!important;
        margin-top: 8px;
        margin-bottom: 0!important;
        text-align: left;
        &:empty {
          display: none;
        }
      }
    }
    .form-button-box {
      .btn {
        &.orange {
          background: $orange;
        }
      }
    }
  }
  .profileCompletion {
    max-width: 460px;
    margin: 0 auto;
    text-align: center;
    small {
      display: block;
      @include font(16);
      font-weight: 500;
      color: $blackColor;
      text-align: center;
      margin-bottom: 15px;
    }
    .progressbar {
      position: relative;
      background: rgba($green, 0.2%);
      height: 24px;
      @include radius(20px);
      overflow: hidden;
      .progress {
        background: $green;
        @include flex();
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 100%;
        width: 65%;
        left: 0;
        top: 0;
        @include font(14);
        font-weight: 800;
        color: $white;
        @include radius(20px);
      }
      &.small {
        width: 46px;
        height: 10px;
      }
    }
  }
}
.strengthen-profile-box {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #d5d5d5;
  .common-box-list {
    margin-bottom: 25px;
    .title {
      @include font(14);
      font-weight: 500;
      color: $blackColor;
      margin-bottom: 15px;
    }
    .flex-box {
      @include flex();
      justify-content: space-between;
      align-items: center;
      background: $white;
      padding: 0.8em;
      border: 1px solid #70707033;
      box-shadow: 0 0 10px #0000000d;
      @include radius(4px);
      margin-bottom: 15px;
      .icon-box {
        @include flex();
        align-items: center;
        justify-content: center;
        text-align: center;
        background: $yellow;
        width: 40px;
        height: 40px;
        @include font(12);
        font-weight: 400;
        color: $white;
        @include radius(50px);
        margin-right: 10px;
        text-align: center;
        img {
          display: inline-block;
          vertical-align: middle;
        }
        &.green {
          background: $green;
          color: $white;
        }
      }
      .box.detail {
        @include flex();
        align-items: center;
        justify-content: center;
      }
      .content-box {
        margin-right: 10px;
        h3 {
          @include font(14);
          font-weight: 400;
          color: $blackColor;
          margin-bottom: 2px;
        }
        p {
          @include font(12);
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
      .box-buton-box .btn {
        padding: 10px 15px;
        @include font(14);
        font-weight: 300;
        margin: 0 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &.request-block-box .flex-box .icon-box {
      background: $blue;
    }
    &.verification-block-box {
      .flex-box .icon-box {
        background: transparent;
        border: 1px solid $border;
        span {
          display: block;
          width: 40px;
        }
      }
    }
    &.verification-block-box {
      &:first-child .flex-box .icon-box {
        background: transparent;
        border: 0;
      }
      .box-buton-box .btn {
        min-width: 125px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .show-less-link {
      text-align: center;
      a.showMore {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        position: relative;
        padding-right: 20px;
        @include font(14);
        font-weight: 400;
        color: $baseColor;
        &:after {
          content: "Show More";
        }
        &:before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 8px;
          height: 8px;
          top: 2px;
          right: 3px;
          border-right: 2px solid $baseColor;
          border-bottom: 2px solid $baseColor;
          transform: rotate(45deg);
        }
        &.showLess:after {
          content: "Show Less ";
        }
        &.showLess:before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 8px;
          height: 8px;
          top: 5px;
          right: 3px;
          border-right: 2px solid $baseColor;
          border-bottom: 2px solid $baseColor;
          transform: rotate(-135deg);
        }
      }
    }
  }
}
.profile-upload-main-box {
  @include flex();
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .profile-upload-box {
    @include flex();
    align-items: top;
    justify-content: start;
    width: 88%;
    .profile {
      position: relative;
      width: 110px;
      height: 110px;
      border: 2px solid $black;
      @include radius(50%);
      .pic {
        @include flex();
        align-items: center;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .user-picture {
          width: 110px;
          height: 110px;
          object-fit: cover;
          border-radius: 50%;
          overflow: hidden;
        }
      }
      .checkIcon {
        position: absolute;
        max-width: 100%;
        height: auto;
        width: auto;
        right: 0;
        bottom: 0;
        @include translate(50%, 0);
      }
    }
    .profile-detail {
      margin-left: 25px;
      h3 {
        @include font(14);
        font-weight: 500;
        color: $black;
        margin-bottom: 5px;
        img {
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          margin-left: 5px;
        }
        .checkIcon{
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          margin-left: 5px;
        }
      }
      p {
        @include font(13);
        font-weight: 300;
        color: $black;
        line-height: 1.4;
        margin-bottom: 10px;
      }
    }
  }
  .help-message-box {
    position: relative;
    background: $yellow;
    padding: 1em 2.5em 1em 1em;
    color: $black;
    @include radius(4px);
    .close-button {
      background: transparent;
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -8px;
      border: none;
      outline: none;
      z-index: 1;
      img {
        width: 15px;
      }
    }
    p {
      @include font(14);
      font-weight: 400;
      color: $black;
      line-height: 1.4;
    }
  }
}
.editprofile-common-form {
  h4 {
    @include font(14);
    font-weight: 400;
    color: $black;
    line-height: 1.4;
    margin-bottom: 10px;
  }
  .link-red {
    text-decoration: none;
    color: $baseColor;
    &:hover {
      color: #b12c1a;
    }
  }
  form {
    > div {
      margin-bottom: 20px;
    }
    .collage-insight-help {
      display: flex;
      justify-content: space-between;
      .help-message-box {
        position: relative;
        background: $yellow;
        padding: 1em 2.5em 1em 1em;
        color: $black;
        @include radius(4px);
        width: 50%;
        p {
          @include font(14);
          font-weight: 400;
          color: $black;
          line-height: 1.4;
        }
      }
    }
    .college-switch-button {
      margin-bottom: 20px;
      .switch-button {
        @include flex();
        align-items: center;
        justify-content: end;
        label {
          display: inline-block;
          vertical-align: middle;
          @include font(14);
          color: $black;
          font-weight: 500;
          margin-right: 6px;
        }
        input {
          position: relative;
          background-image: none;
          background: rgba($gray, 0.5%);
          width: 28px;
          height: 10px;
          border: none;
          appearance: none;
          border-width: 0;
          @include radius(50px);
          @include transition();
          margin-top: 0;
          margin-left: 0;
          cursor: pointer;
          &:after {
            content: "";
            background: $black;
            position: absolute;
            width: 14px;
            height: 14px;
            left: 0;
            top: -2px;
            border: none;
            z-index: 2;
            border-radius: 50%;
            @include transition();
          }
          &:checked[type="checkbox"] {
            background: rgba($green, 0.5%);
            &:after {
              background: $green;
              left: auto !important;
              right: 0 !important;
            }
          }
        }
      }
    }
    .fieldBox {
      &.flexBox {
        @include flex();
        justify-content: space-between;
        align-items: flex-start;
        .half {
          width: calc(50% - 10px);
          height: auto!important;
          .MuiFormControl-marginNormal {
            margin-top: 0;
            margin-bottom: 0;
          }
          .searchLocation {
            &.contactPage {
              .search {
                margin-bottom: 0!important;
              }
            }
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        @include radius(4px);
      }
      .box-with-icon {
        @include flex();
        align-items: center;
        justify-content: space-between;
        height: 43px;
        border: 1px solid $gray;
        @include radius(4px);
        input {
          padding-top: 0;
          padding-bottom: 0;
          border: none;
          border-radius: none;
        }
        span {
          @include flex();
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          height: 43px;
          @include font(16);
          font-weight: 600;
          color: $black;
          text-align: center;
          border-right: 1px solid $gray;
        }
      }
      .sf-link-with-closebtn {
        position: relative;
        input {
          padding-right: 50px !important;
        }
        .btn {
          background: transparent;
          padding: 0;
          position: absolute;
          right: 15px;
          top: 12px;
          min-width: 1px;
          z-index: 1;
        }
      }
      .flex-box-second {
        @include flex();
        align-items: center;
        justify-content: space-between;
      }
      &.mt25 {
        margin-top: 28px;
      }
      &.phone {
        .flexBox {
          @include flex();
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          border: 1px solid $gray;
          .countryFlag {
            width: 35px;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          select {
            padding: 5px 15px 5px 5px;
            background: none;
            @include font(14);
            color: $black;
            -webkit-appearance: button;
            appearance: button;
            width: 70px;
            outline: none;
            border: none;
          }
          input {
            width: calc(100% - 125px);
            border: none;
          }
        }
      }
      .stats-uploadbox {
        position: relative;
        input {
          display: none;
        }
        .uploadbtn {
          display: block;
          background:$white;
          padding: 8px 75px 8px 15px;
          @include font(16);
          font-weight:400;
          color: rgba($black, .5);
          border: 2px dashed #CCCCCC80;
          @include radius(4px);
          margin: 0;
          cursor: pointer;
        }
        .video-trashbtn {
          background: none;
          padding:0;
          position: absolute;
          right: 15px;
          top: 14px;
          @include font(14);
          font-weight:400;
          color: $baseColor;
          cursor: pointer;
          border: none;
          outline: none;
          z-index:11;
        }
      }
      label {
        position: relative;
        @include font(14);
        font-weight: 400;
        color: $black;
        line-height: 1.4;
        margin-bottom: 10px;
        sup {
          position: absolute;
          color: $baseColor;
          font-weight: 600;
          top: 6px;
          margin-left: 3px;
        }
        .checkIcon {
          margin-left: 10px;
        }
      }
      textarea {
        padding: 10px;
        width: 100%;
        min-height: 120px;
        @include font(14);
        color: $black;
        line-height: 1.5;
        @include radius(4px);
        border: 1px solid $gray;
        outline: none;
        box-shadow: none;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          border-radius: 10px;
          width: 8px;
          background: rgba($gray, 0.3%);
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba($gray, 0.8%);
          cursor: pointer;
        }
        &::placeholder {
          color: rgba($black, 0.5);
        }
      }
      .textarea-with-icon {
        position: relative;
        .tooltip-listing {
          @include flex();
          align-items: flex-end;
          justify-content: end;
          position: absolute;
          right: 10px;
          bottom: 10px;
          li {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            margin: 2px 4px;
            @include font(11);
            img {
              width: 24px;
            }
          }
        }
      }
      label + input,
      label + select {
        height: 46px;
      }
      input {
        padding: 10px;
        width: 100%;
        @include font(14);
        color: $black;
        line-height: 16px;
        border: 1px solid $gray;
        outline: none;
        box-shadow: none;
        @include radius(4px);
        &::placeholder {
          color: rgba($black, 0.5);
        }
      }
      select {
        padding: 14px 42px 14px 10px;
        width: 100%;
        -webkit-appearance: none;
        appearance: none;
        @include font(14);
        color: $black;
        line-height: 16px;
        background-image: url(../../../../../../../assets/img/arrowdown.png);
        background-repeat: no-repeat;
        background-position: right 15px center;
        background-size: 16px;
        border: 1px solid $gray;
        @include radius(4px);
      }
      .subtext-box {
        display: block;
        padding-top: 8px;
        @include font(13);
        color: $lightBlue;
        line-height: 1.4;
      }
      p {
        &.checkbox-list {
          &.mt35 {
            margin-top: 35px;
          }
          span {
            display: inline-flex;
            align-items: center;
            position: relative;
            input[type="checkbox"] {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              opacity: 0;
              z-index: 1;
              cursor: pointer;
              &:checked + label {
                &::before {
                  border-color: $lightBlue;
                }
                &::after {
                  content: "";
                  display: inline-block;
                  background: url(../img/checkBlue.png) no-repeat;
                  background-size: cover;
                  position: absolute;
                  width: 12px;
                  height: 12px;
                  left: 3px;
                  top: 4px;
                }
              }
            }
            label {
              position: relative;
              @include font(14);
              font-weight: normal;
              color: $black;
              line-height: 18px;
              padding-left: 30px;
              cursor: pointer;
              &::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 18px;
                height: 18px;
                left: 0;
                top: 0;
                border: 1px solid $black;
                @include radius(4px);
              }
            }
          }
        }
        &.info {
          @include font(14);
          color: $black;
          text-align: center;
          margin: 0;
        }
      }
    }
    .form-info-box {
      margin-bottom: 25px;
      .flexBox {
        margin-bottom: 15px;
      }
      .textBoxInfo {
        padding: 15px;
        line-height: 22px;
        border: 1px solid rgb(204, 204, 204);
        background-color: $white;
        @include radius(5px);
        margin-bottom: 15px;
      }
      .title {
        padding: 0.8em 0.8em;
        background: rgba($gray, 0.2%);
        @include font(16);
        color: $green;
        border-left: 3px solid $green;
        margin-bottom: 20px;
        .checkIcon {
          margin-left: 10px;
        }
      }
      .sub-text {
        @include font(12);
        color: $black;
        line-height: 1.4;
        margin-top: -10px;
        margin-bottom: 18px;
      }
      .fieldBox.full {
        margin-bottom: 15px;
      }
      .link-text-box {
        @include font(13);
        color: $black;
        padding-top: 10px;
        a {
          display: inline-block;
          vertical-align: middle;
          text-decoration: none;
          @include font(14);
          color: $lightBlue;
          &:hover {
            color:#0056b3;
          }
        }
      }
      .transcripts-toggle-button {
        padding: 8px 15px;
        @include font(14);
        min-width: 90px;
      }
    }
    .flex-three-col-row {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $gray;
      h4 {
        @include font(14);
        font-weight: 500;
        color: $blackColor;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .flex-three-column {
        @include flex();
        align-items: flex-start;
        justify-content: space-between;
        .item {
          width: calc(40% - 20px);
          &:last-child {
            width: 20%;
            button {
              &.button-total {
                display: block;
                padding: 10px ​15px;
                background: rgba($green, 0.05%);
                width: 100%;
                height: 44px;
                @include font(15);
                font-weight: 700;
                color: $green;
                border: 1px solid $green;
                border-radius: 0;   
              }
            }
          }
          .inner-item {
            margin-bottom: 15px;
          }
        }
      }
    }
    .address-box-wrap {
      background: $white;
      padding: 1em;
      border: 1px solid $gray;
      @include radius(5px);
      &.hide {
        display: none;
      }
      &.file {
        border: 2px solid $green;
        .form-button-box {
          button {
            padding: 10px 15px;
            background: transparent;
            color: $black;
            border: 1px solid $black;
            margin-right: 10px;
            &.blue {
              background: $lightBlue;
              border-color: $lightBlue;
              color: $white;
            }
            &:last-child {
              margin-right: 0;
              &:hover {
                background: $lightBlue;
                border-color: $lightBlue;
                color: $white;
              }
            }
          }
        }
      }
      .file-button-box {
        @include flex();
        align-items: center;
        margin-bottom: 25px;
        label {
          margin-bottom: 0;
          &.btn {
            color: $white;
          }
        }
        .gradeScore{
          min-width: 120px;
          position:relative;
          input{
            position:absolute;
            opacity:0;
            left:0px;
            top:0px;
            width:100%;
            height:100%;
          } 
        }
        span {
          @include font(14);
          font-style: italic;
          color: $yellow;
          margin-left: 10px;
        }
        .fileName {
          @include font(13);
          color: rgba($black, .60%);
          margin-left: 10px;
        }
      }
      .fieldBox {
        margin-bottom: 15px;
      }
      .title {
        padding: 0.8em 0.8em;
        background: rgba($gray, 0.2%);
        @include font(16);
        color: $green;
        border-left: 3px solid $green;
        margin-bottom: 20px;
        .checkIcon {
          margin-left: 10px;
        }
        &.flex-box {
          @include flex();
          align-items: center;
          justify-content: space-between;
          .half {
            .btn {
              padding: 4px 10px;
              background: $white;
              min-width: 70px;
              @include font(12);
              color: $green;
              border: 1px solid $green;
              @include radius(50px);
              margin: 0 2px;
              &:hover {
                background: $green;
                color: $white;
              }
              &.remove {
                border: 1px solid $baseColor;
                color: $baseColor;
                &:hover {
                  background: $baseColor;
                  color: $white;
                }
              }
            }
          }
        }
      }
      .form-button-box {
        padding-top: 0;
        border-top: none;
        text-align: left;
        .btn {
          background:$lightBlue;
        }
      }
    }
    li {
      .address-box-wrap {
        margin-bottom: 20px;
      }
    }
    .radioflexBox {
      @include flex();
      align-items: center;
      .MuiIconButton-label {
        svg {
          path {
            fill: $lightBlue!important;
          }
        }
      }
      .option {
        @include flex();
        align-items: center;
        position: relative;
        margin-right: 15px;
        margin-bottom: 0;
        input {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 1;
          opacity: 0;
          cursor: pointer;
          &:checked + label {
            &::before {
              border-color: $lightBlue;
            }
            &::after {
              content: "";
              background-color: $lightBlue;
              display: inline-block;
              position: absolute;
              width: 8px;
              height: 8px;
              left: 5px;
              top: 5px;
              @include radius(50%);
            }
          }
        }
        label {
          position: relative;
          @include font(14);
          font-weight: normal;
          color: $black;
          padding-left: 30px;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            left: 0;
            top: 0;
            @include radius(50%);
            border: 1px solid $black;
          }
        }
      }
    }
    .form-button-box {
      display: flex;
      align-items: center;
      text-align: center;
      border-top: 1px solid $gray;
      padding-top: 20px;
      .next-prev-button {
        margin-left: 15px;
        button {
          display: inline-block;
          vertical-align: middle;
          background: none;
          border: none;
          @include font(16);
          font-weight: 400;
          color: rgba($black, .6);
          padding: 0;
          margin-right: 10px;
          @include transition();
          svg {
            width: 18px;
            height: 18px;
            margin-top: -1px;
          }
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            color: $baseColor;
          }
        }
      }
    }
    .btn {
        background:$baseColor;
        padding: 10px 15px;
        min-width: 100px;
        &.blue {
          background:$lightBlue;
          color: $white;
        }
        &.green {
          background:$green;
          color: $white;
        }
      }
    }
    .address-listing-wrap li {
      margin-bottom: 20px;
      &.active {
        .notification-content {
          display: block;
        }
      }
    }
    .notification-setting-box {
      background: rgba($gray, 0.2%);
      .notification-title {
        padding: 15px 35px 15px 15px;
        @include font(14);
        font-weight: 500;
        color: $black;
        position: relative;
        cursor: pointer;
        &:after {
          content: "+";
          display: block;
          @include font(22);
          color: $black;
          line-height: 28px;
          position: absolute;
          width: 28px;
          height: 28px;
          right: 5px;
          top: 9px;
          text-align: center;
        }
        &.active:after {
          content: "";
          font-size: 0;
        }
        &.active:before {
          content: "";
          position: absolute;
          background: $black;
          width: 15px;
          height: 2.5px;
          right: 12px;
          top: 20px;
        }
        &.after-remove {
          &:after, &:before {
            display:none;
          }
        }
        .notification-btn-toggle {
          &:after {
            content: "+";
            display: block;
            @include font(22);
            color: $black;
            line-height: 28px;
            position: absolute;
            width: 28px;
            height: 28px;
            right: 5px;
            top: 9px;
            text-align: center;
          }
          &.active:after {
            content: "";
            font-size: 0;
          }
          &.active:before {
            content: "";
            position: absolute;
            background: $black;
            width: 15px;
            height: 2.5px;
            right: 12px;
            top: 20px;
          }
        }
      }
      .notification-content {
        display: none;
        padding: 15px;
        border-top: 1px solid $gray;
        &.active {
          display: block;
        }
        .flexbox {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 15px;
          @include font(12);
          color: $black;
          line-height: 1.5;
          &:first-child {
            @include font(14);
            font-weight: 500;
            line-height: 1.3;
          }
          .item {
            width: 15%;
            text-align: center;
            &:first-child {
              width: 70%;
              text-align: left;
            }
          }
        }
      }
      p {
        &.checkbox-list {
          span {
            @include flex();
            align-items: center;
            justify-content: center;
            position: relative;
            margin-left: 15px;
            input[type="checkbox"] {
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              opacity: 0;
              z-index: 1;
              cursor: pointer;
              &:checked + label {
                &::before {
                  border-color: $lightBlue;
                }
                &::after {
                  content: "";
                  display: inline-block;
                  background: url(../img/checkBlue.png) no-repeat;
                  background-size: 10px 10px;
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  left: 3px;
                  top: 4px;
                }
              }
            }
            label {
              position: relative;
              @include font(14);
              font-weight: normal;
              color: $black;
              padding-left: 30px;
              line-height: 18px;
              cursor: pointer;
              &::before {
                content: "";
                display: inline-block;
                position: absolute;
                width: 15px;
                height: 15px;
                left: 0;
                top: 0;
                border: 1px solid $black;
                @include radius(2px);
              }
            }
          }
        }
      }
    }
    .MuiOutlinedInput-root {
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: $gray;
          outline: none;
          box-shadow: none;
        }
      }
    }
    .MuiInputBase-formControl {
      .MuiOutlinedInput-input {
        padding: 13.5px 14px;
        @include font(14);
      }
      [class*="PrivateNotchedOutline-legendLabelled"] {
        max-width: 0;
      }
    }
    &.modal-content {
      .modal-body {
        p {
          &.checkbox-list {
            text-align: left;
            margin-bottom: 0;
          }
        }
      }
    }
    .legend-inner-none {
      fieldset {
        top: 0;
        bottom: -2px;
      } 
      legend {
        display: none;
      }
    }
  }
.academic-detail-listing {
  padding-top: 25px;
  .item-row {
    @include font(15);
    color: $black;
    border-bottom: 1px solid $gray;
    padding-bottom: 15px;
    margin-bottom: 15px;
    .item-row-inner {
      @include flex();
      justify-content: space-between;
      align-items: center;
      .item {
        &:first-child {
          min-width: 200px;
        }
      }
    }
    .address-box-wrap {
      margin-top: 20px;
    }
    .item {
      &.button-wrapper {
        a {
          display: inline-block;
          vertical-align: middle;
          padding: 10px 5px;
          width: 40px;
          border: 1px solid $yellow;
          color: $yellow;
          @include radius(4px);
          margin-right: 10px;
          text-align: center;
          &.delete-button {
            color: $baseColor;
            border-color: $baseColor;
          }
          &:last-child {
            margin-right: 0;
          }
          svg {
            margin: 0 auto;
          }
        }
      }
      .inactive  {
         .video-and-photo-wrap {
           .video-second-block {
             .video-tab-contents {
               .video-grid-list {
                 .video-box {
                   width: 31.333%;
                   &:nth-child(2n+2) {
                     margin-right: 3%;
                   }
                   &:nth-child(2n+3) {
                     clear: none;
                   }
                   &:nth-child(3n+3) {
                     margin-right: 0;
                   }
                   &:nth-child(3n+4) {
                     clear: both;
                   }
                 }
               }
             }
           }
         }
       }
    }
  }
}
.inactive {
  .video-and-photo-wrap {
    .video-second-block {
      .video-tab-contents {
        .video-grid-list {
          .video-box {
            width: 31.333%;
            &:nth-child(2n + 2) {
              margin-right: 3%;
            }
            &:nth-child(2n + 3) {
              clear: none;
            }
            &:nth-child(3n + 3) {
              margin-right: 0;
            }
            &:nth-child(3n + 4) {
              clear: both;
            }
          }
        }
      }
    }
  }
}
.academic-detail-listing {
  padding-top: 25px;
  .item-row {
    margin-bottom: 15px;
    padding-bottom: 15px;
    @include font(15);
    color: $black;
    border-bottom: 1px solid $gray;
    .item-row-inner {
      @include flex();
      justify-content: space-between;
      align-items: center;
    }
    .address-box-wrap {
      margin-top: 20px;
    }
    .item {
      &.button-wrapper {
        a {
          display: inline-block;
          vertical-align: middle;
          padding: 10px 5px;
          width: 40px;
          border: 1px solid $yellow;
          color: $yellow;
          margin-right: 10px;
          text-align: center;
          @include radius(4px);
          &.delete-button {
            color: $baseColor;
            border-color: $baseColor;
          }
          &:last-child {
            margin-right: 0;
            cursor: pointer;
          }
          svg {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
.video-and-photo-wrap {
  .video-share-box {
    text-align: right;
    margin-bottom: 1.25em;
    .share-btn {
      padding: 8px 16px;
      background: rgba($gray, 0.4%);
      @include font(13);
      color: $black;
      @include radius(4px);
      svg {
        margin-right: 5px;
        @include transition();
      }
      &:hover {
        background: $blue;
        color: $white;
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }
  .video-and-photo-main {
    @include flex();
    justify-content: space-between;
    border-top: 1px solid $gray;
    .sidebarbox {
      width: 200px;
      padding-top: 1.2em;
      padding-right: 1em;
      margin-right: 1em;
      border-right: 1px solid $gray;
      min-height: 1000px;
      .menu-links {
        li {
          display: block;
          margin-bottom: 20px;
          .videoTag{
            display: flex;
            align-items: center;
            span{
              margin-left: 10px;
            }
          }
          a {
            display: block;
            text-decoration: none;
            @include font(14);
            font-weight: 500;
            color: $black;
            position: relative;
            padding-right: 30px;
            @include transition();
            &:hover {
              color: $baseColor;
              svg{
                fill: $baseColor;
              }
              &:after {
                content: "";
                position: absolute;
                background: url("../img/arrow-right-red-icon.svg") no-repeat;
                width: 18px;
                height: 18px;
                right: 0;
                top: 0;
                z-index: 1;
              }
            }
          }
          &.active {
            a {
              color: $baseColor;
              svg{
                fill: $baseColor;
              }
              &:after {
                content: "";
                position: absolute;
                background: url("../img/arrow-right-red-icon.svg") no-repeat;
                width: 18px;
                height: 18px;
                right: 0;
                top: 0;
                z-index: 1;
              }
            }
          }
        }
      }
      .category-list {
        .item {
          padding: 1em 0;
          border-top: 1px solid $gray;
          &:empty {
            display: none;
          }
          a {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            &.active {
              color: $baseColor;
            }
          }
          .add-toggle-button {
            padding: 0 30px 0 22px;
            @include font(14);
            font-weight: 500;
            color: $black;
            position: relative;
            cursor: pointer;
            &:after {
              content: "";
              background: url("../img/minus-circle-icon.svg") no-repeat;
              background-position: center center;
              position: absolute;
              width: 16px;
              height: 16px;
              left: 0;
              top: -1px;
              z-index: 1;
            }
            .edit-button {
              display: block;
              background: rgba($gray, 0.5%);
              position: absolute;
              width: 20px;
              height: 20px;
              right: 0;
              top: -3px;
              z-index: 1;
              @include radius(50%);
              &:after {
                content: "";
                background: url("../img/edit-icon.svg") no-repeat;
                background-position: center center;
                position: absolute;
                width: 9px;
                height: 9px;
                right: 5px;
                top: 6px;
                z-index: 11;
              }
            }
          }
          .category-toggle-data {
            li {
              margin-top: 15px;
              .field-box {
                padding: 8px 32px 8px 10px;
                @include font(13);
                font-weight: 300;
                color: $black;
                border: 1px solid $gray;
                @include radius(4px);
                position: relative;
                input {
                  display: block;
                  width: 100%;
                  border: none;
                  @include font(13);
                  font-weight: 300;
                  color: $black;
                }
                .delete-button {
                  display: block;
                  background: url("../img/trash-red-icon.svg") no-repeat;
                  background-position: center center;
                  position: absolute;
                  width: 12px;
                  height: 15px;
                  right: 10px;
                  top: 10px;
                  z-index: 1;
                  cursor: pointer;
                }
              }
              .button-box {
                @include flex();
                justify-content: flex-end;
                align-items: center;
                padding-top: 15px;
                .close-button-c {
                  margin-right: 10px;
                  cursor: pointer;
                  svg {
                    width: 25px;
                    color:$baseColor!important;
                  }
                }
                .btn {
                  padding: 8px 10px;
                  @include font(12);
                  font-weight: 300;
                }
              }
            }
          }
          &:first-child {
            a {
              padding-right: 0;
            }
          }
        }
      }
    }
    .content-box {
      padding-top: 1.2em;
      width: calc(100% - 200px);
    }
    .video-top-block {
      @include flex();
      justify-content: space-between;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $gray;
      .video-links {
        @include flex();
        justify-content: space-between;
        width: 52%;
        .half {
          width: calc(50% - 8px);
        }
      }
      .video-help-text {
        width: calc(100% - 52%);
        @include font(13);
        font-weight: 300;
        color: $black;
        text-align: right;
        svg {
          width: 15px;
          margin-right: 5px;
        }
      }
      .video-links {
        @include flex();
        align-items: flex-start;
        justify-content: space-between;
      }
      .link-box {
        @include flex();
        justify-content: center;
        align-items: center;
        text-decoration: none;
        padding: 1em;
        @include font(12);
        font-weight: 400;
        color: $black;
        line-height: 1.5;
        border: 1px solid $gray;
        box-shadow: 0 1px 3px #00000029;
        @include radius(5px);
        min-height: 230px;
        text-align: center;
        overflow: hidden;
        position: relative;
        .icon-box {
          @include flex();
          justify-content: center;
          background: $green;
          width: 50px;
          height: 50px;
          @include radius(50%);
          margin: 0 auto 12px;
          img {
            width: 22px;
          }
        }
        .text {
          display: block;
          min-height: 58px;
        }
        .browse-img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
        }
      }
    }
  }
  .video-second-block {
    .top-block {
      @include flex();
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .tabs {
      @include flex();
      li {
        position: relative;
        @include font(14);
        color: rgba($black, 0.8%);
        padding-bottom: 10px;
        margin-right: 12px;
        cursor: pointer;
        &.current {
          color: $baseColor;
          &:after {
            content: "";
            position: absolute;
            background: $baseColor;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
          }
        }
      }
    }
    .video-grid-control {
      @include flex();
      li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        a {
          display: block;
          text-decoration: none;
          @include font(14);
          color: $black;
          span {
            display: inline-block;
            vertical-align: middle;
            border-bottom: 1px solid $black;
          }
          svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            path {
              fill: $black;
              @include transition();
            }
          }
          &:hover,
          &.active {
            color: $baseColor;
            span {
              border-bottom: 1px solid $baseColor;
            }
            svg {
              path {
                fill: $baseColor;
              }
            }
          }
        }
      }
    }
    .video-tab-contents {
      .tab-content {
        display: none;
        &.current {
          display: block;
        }
      }
      .video-grid-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        > div {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }
        .video-box {
          float: left;
          width: 48.5%;
          margin-right: 3%;
          background: $white;
          box-shadow: 0 3px 6px #00000017;
          border: 1px solid $gray;
          @include radius(5px);
          margin-bottom: 20px;
          position: relative;
          @include transition();
          &:nth-child(2n + 2) {
            margin-right: 0;
          }
          &:nth-child(2n + 3) {
            clear: both;
          }
          &:hover {
            .img-box .info-icon li {
              display: flex;
              opacity: 1;
            }
          }
          .img-box {
            background:rgba($gray, .2);
            position: relative;
            height: 200px;
            overflow: hidden;
            @include radius(4px 4px 0 0);
            video {
              position: relative;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            iframe {
              position: relative;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .video-play-button {
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              z-index: 1;
              cursor: pointer;
              .video-play-inner {
                @include flex();
                justify-content: center;
                align-items: center;
                height: 100%;
              }
              button {
                @include flex();
                justify-content: center;
                align-items: center;
                background: $baseColor;
                width: 36px;
                height: 36px;
                @include radius(50%);
                outline: none;
                border: none;
                svg path {
                  fill: $white;
                }
              }
            }
            img {
              display: inline-block;
              vertical-align: middle;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
            .info-icon {
              position: absolute;
              right: 10px;
              top: 15px;
              z-index: 1;
              li {
                @include flex();
                justify-content: center;
                align-items: center;
                background: $white;
                width: 23px;
                height: 23px;
                text-align: center;
                @include radius(50px);
                margin-bottom: 10px;
                display: none;
                opacity: 0;
                cursor: pointer;
                @include transition();
                &:first-child {
                  display: flex;
                  opacity: 1;
                }
                &.button-favorite {
                  svg {
                    path {
                      fill: $black;
                    }
                  }
                  &.active {
                    background: $yellow;

                    svg path {
                      fill: $white;
                    }
                  }
                }
                &.delete-button {
                  svg {
                    path {
                      fill: $baseColor;
                    }
                  }
                }
              }
            }
          }
          .video-detail-box {
            background: none!important;
            padding: 15px 10px;
            .title-box {
              @include flex();
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              .title {
                @include font(13);
                color: $blackColor;
                line-height: 1.3;
                width: 50%;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .switch-video-button {
                cursor: pointer;
                .form-check {
                  display: block;
                  padding-left: 0;
                  .form-check-label {
                    display: inline-block;
                    vertical-align: middle;
                    @include font(10);
                    color: $black;
                    margin-right: 5px;
                    cursor: pointer;
                  }
                  &.form-switch {
                    .form-check-input {
                      background-image: none;
                      border-width: 0;
                      width: 28px;
                      height: 10px;
                      background: rgba($green, 0.5%);
                      border: none;
                      margin-left: 0;
                      appearance: none;
                      @include radius(50px);
                      position: relative;
                      @include transition();
                      cursor: pointer;
                      &:after {
                        content: "";
                        background: $green;
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        right: 0;
                        top: -2px;
                        border: none;
                        border-radius: 50%;
                        z-index: 2;
                        @include transition();
                      }
                    }
                    input:checked[type="checkbox"]:after {
                      left: 0 !important;
                      right: auto !important;
                    }
                  }
                  .makeStyles-toggle-2 {
                    .Mui-checked {
                      color: $green;
                    }
                  }
                  .MuiSwitch-colorPrimary {
                    &.Mui-checked {
                      + .MuiSwitch-track {
                        background: rgba($green, 0.5%);
                      }
                    }
                  }
                }
              }
            }
            .video-info {
              @include flex();
              justify-content: space-between;
              align-items: center;
              margin-bottom: 5px;
              .half {
                @include font(11);
                color: $black;
                span {
                  display: inline-block;
                  vertical-align: middle;
                  margin-bottom: 5px;
                  width: 85%;
                  img {
                    display: block;
                    height: 28px;
                  }
                  &.ml-8 {
                    float: right;
                    margin-left: 8px;
                    text-align: right;
                  }
                  &.mr-8 {
                    margin-right: 8px;
                  }
                  &.shareLink {
                    display: block;
                    width: 100%;
                    border-top: 1px solid rgba($gray, 0.3%);
                    padding-top: 10px;
                    margin-top: 6px;
                    margin-bottom: 0;
                    text-align: center;
                    .share-btn {
                      display: inline-block;
                      vertical-align: middle;
                      background: transparent;
                      padding: 0;
                      @include font(14);
                      font-weight: 400;
                      color: $black;
                      border: none;
                      outline: none;
                      cursor: pointer;
                      @include transition();
                      .icon {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 6px;
                        @include transition();
                        .a {
                          fill:$black;
                        }
                      }
                      &:hover {
                        color:$baseColor;
                        .icon {
                          .a {
                            fill:$baseColor;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .video-select-options {
                @include flex();
                justify-content: center;
                align-items: center;
                span {
                  position: relative;
                  margin-right: 10px;
                  cursor: pointer;
                  img {
                    height: auto;
                  }
                  &:last-child {
                    margin-right: 0;
                  }
                }
                .video-settings-option {
                  display: none;
                  background: $white;
                  padding: 10px;
                  box-shadow: 0 1px 3px #00000029;
                  position: absolute;
                  width: 130px;
                  right: 20px;
                  top: 0;
                  @include radius(4px);
                  z-index: 11;
                  li {
                    display: block;
                    margin-bottom: 10px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                    a {
                      display: block;
                      text-decoration: none;
                      @include font(12);
                      font-weight: 400;
                      color: $black;
                      @include transition();
                      &:hover {
                        color: $baseColor;
                      }
                    }
                  }
                }
              }
            }
            .video-text {
              p {
                &.checkbox-list {
                  margin-bottom: 5px;
                  span {
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    input[type="checkbox"] {
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      top: 0;
                      opacity: 0;
                      z-index: 1;
                      cursor: pointer;
                      &:checked + label {
                        &::before {
                          border-color: $lightBlue;
                        }
                        &::after {
                          content: "";
                          display: inline-block;
                          background: url(../img/checkBlue.png) no-repeat;
                          background-size: cover;
                          position: absolute;
                          width: 8px;
                          height: 8px;
                          left: 3px;
                          top: 4px;
                        }
                      }
                    }
                    label {
                      position: relative;
                      @include font(12);
                      font-weight: normal;
                      color: $black;
                      line-height: 15px;
                      padding-left: 20px;
                      cursor: pointer;
                      &::before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        left: 0;
                        top: 0;
                        border: 1px solid $black;
                        @include radius(4px);
                      }
                    }
                  }
                  .btn {
                    padding:6px 20px;
                    @include font(13);
                  }
                  .btn-blue {
                    @extend .btn;
                    background: $lightBlue;
                  }
                  .btn-orange {
                    @extend .btn;
                    background: $yellow;
                    margin-right: 13px;
                  }
                }
              }
            }
          }
          &.active {
            .video-settings-option {
              display: block !important;
            }
            &:after {
              content: "";
              background: rgba($black, 0.3%);
              position: absolute;
              right: 0;
              left: 0;
              top: 0;
              bottom: 0;
              z-index: 1;
              @include radius(4px);
            }
          }
        }
        &.list {
          .video-box {
            @include flex();
            align-items: center;
            float: none;
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
            .img-box {
              width: 220px;
              height: 120px;
            }
            .video-detail-box {
              width: calc(100% - 220px);
              padding: 10px 10px 15px;
            }
          }
        }
      }
      .clear-filter-box {
        display: inline-block;
        padding-top: 20px;
        text-align: center;
        border-top: 1px solid $gray;
        width: 100%;
        .clear-filters-button {
          background: rgba($gray, 0.4%);
          padding: 8px 16px;
          @include font(13);
          color: $black;
          &:hover {
            background: $blue;
            color: $white;
          }
        }
      }
    }
  }
}
.modal {
  overflow-y: auto !important;
}
.common-modal {
  .refemailid{
    .MuiInput-underline {
      border: 1px solid $gray;
    @include radius(4px);
    padding: 0px;
    }
  }
  .MuiInput-underline {
    padding: 6px 15px;
    border: 1px solid $gray;
    @include radius(4px);
  }
  .modal-dialog {
    max-width: 480px;
  }
  &.coache-modal {
    .icon-box-first {
      width: 55px;
      height: 55px;
      margin: 0 auto 15px;
      img {
        max-width: 100%;
      }
    }
    .connect-with-coaches-top-box {
      justify-content: center;
      margin-bottom: 15px;
      .content-box {
        width: auto;
        text-align: left;
        p {
          margin-bottom: 0;
        }
      }
    }
    .short-content-box {
      @include font(14);
      font-weight: 300;
      color: $text;
      line-height: 1.5;
      p {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.delete-modal {
      .modal-content {
        .modal-body {
          p {
            text-align: center!important;
          }
        }
      }
    }
    .modal-body {
      padding-bottom: 20px;
    }
    .modal-footer {
      padding-bottom: 25px;
    }
  }
  .modal-content {
    border: none;
    box-shadow: 0 3px 20px #00000080;
    .modal-body {
      p {
        text-align: left!important;
      }
    }
    .stats-graph-box {
      .graph-progress-bar {
        p {
          text-align: center!important;
          margin-bottom: 0;
        }
      }
    }
  }
  .modal-header {
    justify-content: center;
    border-bottom: none;
    padding: 30px 35px 0;
  }
  .modal-body {
    padding: 20px 35px 25px;
    @include font(14);
    color: $black;
    text-align: center;
    position: relative;
    .form-group {
      margin-bottom: 0;
      label {
        display: block;
        @include font(14);
        color: $black;
        text-align: left;
        margin-bottom: 10px;
      }
      input {
        padding: 0 15px;
        width: 100%;
        @include font(13);
        font-weight: 300;
        color: $black;
       
        height: 50px;
      }
      .date-close-icon {
        top: 33px!important;
      }
      .MuiAutocomplete-endAdornment {
        
        z-index: 11;
      }
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
        padding-left: 15px;
        padding-right: 80px;
      }
    }
  }
  .modal-title {
    @include font(16);
    font-weight: 500;
    color: $black;
  }

  .modal-footer {
    border-top: none;
    justify-content: center;
    padding: 0 35px 35px;

    button, a {
      @include font(14);
      font-weight: 300;
      padding: 10px 15px;
      min-width: 100px;
      margin: 0 0.35rem;
      &.btn {
        color:$white
      }
      &.gray {
        background: rgba($gray, 0.4%);
        color: $blackColor;
      }
    }
  }

  .close {
    outline: none;
  }

  &.unsupport-modal {
    .modal-body {
      padding: 30px 60px 25px;
    }

    .modal-title {
      margin-bottom: 12px;
      line-height: 1.1;
    }

    p {
      line-height: 1.5;
      margin-bottom: 6px;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .icon-box {
      margin-bottom: 15px;

      img {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .close {
      position: absolute;
      right: 15px;
      top: 10px;
    }
  }
  &.trash-modal {
    padding-right: 0 !important;
    .modal-dialog {
      max-width: 450px;
    }
    .modal-content{
        width: 450px;
        @media screen and (max-width: 576px){
            width: 95%;
            margin: auto;
        }
    }
    
    .modal-body {
      padding: 25px 20px 25px;
      .icon-box-first{
        .imgName{
          width: 100px;
          height: 100px;
          margin-top: 28px;
        }
      }
      .content-box {
        padding-top: 15px;
        color: $blackColor;
        h4 {
          @include font(16);
          font-weight: 500;
          line-height: 1.4;
        }
        &.text-center {
          text-align: center!important;
          p {
            text-align: center!important;
          }
        }
      }
    }
    .modal-footer {
      button, a {
        font-weight: 400;
      }
    }
    .close {
      position: absolute;
      top:15px;
      right: 20px;
    }
  }
  &.form-modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .modal-lg {
      max-width: 800px;
    }
    .modal-content{
        width: 800px;
        margin: 20px auto;
        @media screen and (max-width: 820px){
            margin: 10px;
        }
    }
    .editprofile-common-form {
      form {
        > div {
          margin-bottom: 0;
          &.modal-header {
            &.border-none {
              border-bottom: none;
              padding-bottom: 0;
            }
          } 
        }
        .fieldBox {
          margin-bottom: 15px;
          label {
            display: block;
            @include font(14);
            font-weight: 400;
            color: $black;
            margin-bottom: 10px;
          }
          input {
            @include font(14);
            font-weight: 300;
            color: $black;
            line-height: 16px;
            width: 100%;
            border: 1px solid $border;
            @include radius(4px);
            padding: 0 1em;
            height: 46px;
            box-shadow: none;
            &#combo-box-demo {
              height: 42px;
            }
          }
          select {
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            padding: 14px 42px 14px 10px;
            @include font(14);
            font-weight: 300;
            color: $black;
            line-height: 16px;
            background-image: url(../img/arrowdown.png);
            background-repeat: no-repeat;
            background-position: right 15px center;
            background-size: 16px;
            height: 46px;
            border: 1px solid $border;
            @include radius(4px);
          }
          textarea {
            @include font(14);
            font-weight: 300;
            border: 1px solid $border;
            @include radius(4px);
          }
          &.phone {
            .flexBox {
              @include radius(4px);
              input,
              select {
                border: none;
                width: auto;
              }
            }
          }
          .box-with-icon {
            height: 47px;
            @include radius(4px);
            span {
              height: 45px;
            }
            input {
              border: none;
            }
          }
          .brief-text {
            @include font(12);
            font-weight: 300;
            columns: $black;
            line-height: 1.4;
            margin-top: -5px;
            margin-bottom: 15px;
          }
          .radioflexBox {
            &.d-block {
              .option {
                margin-right: 0;
                &:last-child {
                  margin-right: 0;
                  label {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          .file-button-box {
            @include flex();
            align-items: center;
            .green {
              @include font(14);
              font-weight: 300;
              color: $white;
              margin-right: 15px;
              margin-bottom: 0;
            }
            .gradeScore{
              min-width: 120px;
              position:relative;
              input{
                position:absolute;
                opacity:0;
                left:0px;
                top:0px;
                width:100%;
                height:100%;
              } 
            }
            span {
              display: inline-block;
              vertical-align: middle;
              @include font(14);
              color: $blackColor;
              .close-button {
                background: none;
                border: 0;
                outline: none;
                margin-left: 8px;
              }
            }
          }
        }
      }
      .modal-body {
        text-align: left;
      }
    }
    .modal-header {
      padding: 15px 25px;
      border-bottom: 1px solid rgba($border, 0.4%);
      .modal-title {
        @include font(16);
        font-weight: 500;
        color: $blackColor;
      }
      .close {
        font-weight: 300;
        color: $black;
      }
    }
    .modal-body {
      padding: 20px 25px 25px;
      &.bottom-border {
        margin-bottom: 25px;
        padding-bottom: 20px;
        border-bottom: 1px solid $gray;
      }
      .form-group {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        label {
          display: block;
          @include font(14);
          font-weight: 400;
          color: $black;
          margin-bottom: 10px;
        }
        input {
          @include font(14);
          font-weight: 300;
          color: $black;
          width: 100%;
          border: 1px solid $border;
          @include radius(4px);
          padding: 0 1em;
          height: 46px;
          box-shadow: none;
        }
        select {
          width: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          padding: 14px 42px 14px 10px;
          @include font(14);
          font-weight: 300;
          color: $black;
          background-image: url(../img/arrowdown.png);
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 16px;
          height: 46px;
          border: 1px solid $border;
          @include radius(4px);
        }
      }
    }
    .modal-footer {
      padding: 0 25px 25px;
      .lightblue {
        background: $lightBlue;
      }
    }
    &.commitment {
      .width-380 {
        max-width: 380px;
      }
      .modal-body {
        padding: 35px 15px 25px;
        position: relative;
      }
      .modal-footer {
        padding-left: 15px;
        padding-right: 15px;
      }
      .modal-title {
        @include font(16);
        font-weight: 500;
        color: $blackColor;
        margin-bottom: 20px;
      }
      .close {
        font-weight: 300;
        color: $black;
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }
  }
  &.submitRequest-modal {
    padding-right: 0!important;
    .modal-content {
      border: none;
      box-shadow: 0px 3px 20px #00000080;
      border-radius:12px;

      @media screen and (max-width: 576px){
        width: 95%;
        margin: auto;
      }
    }
    .modal-body {
      padding: 35px 25px 25px;
      @include font(16);
      font-weight:400;
      color:$black;
      text-align: left;
      h4 {
        @include font(16);
        font-weight:500;
        color:$black;
        margin-bottom: 20px;
      }
      p {
        line-height:1.6;
        margin-bottom:15px;
      }
      ul {
        display: block;
        li {
          display: block;
          margin-bottom:15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .close {
        position: absolute;
        top:15px;
        right: 20px;
      }
    }
    .modal-footer {
      border-top: none;
      justify-content: center;
      padding: 0 25px 25px;
      button {
        @include font(14);
        font-weight: 300;
        padding: 10px 15px;
        min-width: 100px;
        margin: 0 0.35rem;
      }
    }
  }
  .bluelight-bg-box {
    padding: 10px;
    background: rgba($lightBlue, 0.1%);
    @include font(14);
    line-height: 1.5;
    @include radius(4px);
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }

}
.video-post-modal {
  &.video-main {
    .modal-body {
      padding: 15px;

      iframe {
        width: 100% !important;
        display: inline-block;
        vertical-align: middle;
        height: 255px !important;
        overflow: hidden;
      }
    }
  }

  .modal-content {
    border: none;
    box-shadow: 0 3px 20px #00000080;
    width: 500px;
  }

  .modal-header {
    border-bottom: 1px solid $gray;
  }

  .modal-body {
    padding: 20px 30px 30px;
  }

  .profile-details-wrap {
    @include flex();
    align-items: center;
    margin-bottom: 15px;

    .profile-img {
      width: 50px;
      height: 50px;
      @include radius(50px);
      overflow: hidden;
      margin-right: 10px;

      img {
        object-fit: cover;
        width: 100%;
        height: 50px;
      }
    }

    .profile-detail {
      h3 {
        @include font(15);
        color: $blackColor;
        margin-bottom: 6px;
      }

      select {
        padding: 2px 10px;
        @include font(13);
        color: $black;
        border: 1px solid $gray;
        @include radius(50px);
        height: 25px;
      }
    }
  }

  .form-group {
    margin-bottom: 15px;

    textarea {
      padding: 10px;
      @include font(14);
      font-weight: 300;
      color: rgba($black, 0.8%);
      border: 1px solid $gray;
      width: 100%;
      height: 70px;
      @include radius(4px);
    }
  }

  .post-video-box {
    @include flex();
    justify-content: space-between;
    align-items: center;

    .img-box {
      width: 150px;
      height: 105px;
      margin-right: 15px;
      @include radius(4px);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content-box {
      width: calc(100% - 165px);
      @include font(13);
      font-weight: 300;
      color: $black;
      line-height: 1.5;

      h4 {
        @include font(15);
        color: $black;
        margin-bottom: 5px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-footer {
    border-top: none;
    justify-content: center;
    padding: 0 30px 30px;

    button {
      @include font(14);
      font-weight: 300;
      padding: 10px 15px;
      min-width: 100px;
      margin: 0 0.35rem;
    }
  }
}
.modal {
  .img-box {
    position: relative;
  }

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
  }
  iframe {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .video-play-button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;

    .video-play-inner {
      @include flex();
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    button {
      @include flex();
      justify-content: center;
      align-items: center;
      background: $baseColor;
      width: 32px;
      height: 32px;
      @include radius(50%);
      outline: none;
      border: none;

      svg path {
        fill: $white;
      }
    }
  }

  &.common-slide-modal {
    .video-detail-box {
      @include flex();
      justify-content: space-between;

      .img-box {
        width: 450px;
        height: 360px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .detail-box {
        width: calc(100% - 470px);
        position: relative;
        padding-bottom: 40px;
      }

      .video-share-box {
        @include flex();
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .share-btn {
          background: rgba($gray, 0.5%);
          padding: 7px 15px;
          @include font(13);
          color: $black;
          @include radius(4px);
          @include transition();

          svg {
            margin-right: 5px;
            @include transition();

            path {
              fill: $black;
            }
          }

          &:hover {
            background: $blue;
            color: $white;

            svg path {
              fill: $white;
            }
          }
        }

        .close {
          outline: none;
        }
      }

      .profile-info-box {
        height: 100%;

        .top-block {
          @include flex();
          justify-content: space-between;
          align-items: center;
          padding-bottom: 15px;
          margin-bottom: 15px;
          border-bottom: 1px solid $gray;

          span {
            @include font(14);
            color: $black;

            &.edit-button-toggle {
              @include font(13);
              color: $baseColor;
              cursor: pointer;
            }
          }
        }

        .profile-content {
          display: none;

          .form-group {
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid $gray;

            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }
          }

          input {
            font-family: 'Roboto';
            width: 100%;
            padding: 10px;
            color: $black;
            @include font(14);
            border: 1px solid $gray;
            @include radius(4px);
          }

          select {
            width: 100%;
            border: 1px solid $gray;
            @include radius(4px);
            -webkit-appearance: none;
            appearance: none;
            padding: 14px 42px 14px 10px;
            font-family: 'Roboto';
            color: $black;
            @include font(14);
            background-image: url(../../../../../../../assets/img/arrowdown.png);
            background-repeat: no-repeat;
            background-position: right 15px center;
            background-size: 14px;
            line-height: 24px;
          }
        }

        .button-box-info {
          position: absolute;
          right: 0;
          bottom: 0;

          button {
            padding: 8px 15px;
            @include font(14);
            min-width: 80px;
            margin-right: 12px;

            &.delete-button {
              background: transparent;
              @include font(13);
              color: $baseColor;

              svg {
                display: inline-block;
                vertical-align: 0;
                margin-right: 6px;

                path {
                  fill: $baseColor;
                }
              }
            }
            &.green {
              background: $lightBlue;
              color:$white;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .owl-nav {
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 0;
      right: 0;
      z-index: 1;
      margin-top: -30px;

      button {
        background: $white;
        width: 45px;
        height: 45px;
        @include radius(50px);
        box-shadow: 0 4px 15px #00000082;
      }

      button.owl-next {
        float: right;
        position: absolute;
        right: -70px;
        outline: none;

        span {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          right: 2px;
          left: auto;
          top: -5px;
        }
      }

      button.owl-prev {
        float: left;
        position: absolute;
        left: -70px;
        outline: none;
      }

      button span {
        font-size: 0;
        content: "";
        width: 14px;
        height: 14px;
        border-right: 2px solid $black;
        border-bottom: 2px solid $black;
        display: inline-block;
        position: relative;
        transform: rotate(-0deg);
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        left: 2px;
        top: -5px;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }
    }
  }
}
.professional-video-wrap {
  .tabs {
    @include flex();
    margin: 0 -17px 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $gray;
    li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
      @include font(16);
      color: $black;
      cursor: pointer;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      &.current {
        color: $baseColor;
        &::before {
          content: "";
          position: absolute;
          background: $baseColor;
          height: 2px;
          left: 0;
          right: 0;
          bottom: -16px;
          z-index: 1;
        }
      }
    }
  }
  .professional-video-tab-data {
    .tab-content {
      display: none;
      &.current {
        display: block;
      }
    }
    .question-mark-icon {
      display: inline-block;
      vertical-align: middle;
      &.vertical-middle {
        svg {
          vertical-align: middle;
        }
      }
      svg {
        display: inline-block;
        vertical-align: 1px;
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .video-button-box {
      text-align: right;
      margin-bottom: 15px;
      .btn {
        @include font(14);
        padding: 10px 15px;
        color: $white;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .professional-video-info {
    padding-bottom: 20px;
    margin-bottom: 35px;
    border-bottom: 1px solid $gray;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    h3 {
      @include flex();
      justify-content: space-between;
      @include font(18);
      color: $blackColor;
      font-weight: 500;
      margin-bottom: 10px;
      .number-of-edit {
        @include font(16);
        color: $green;
      }
    }
    .short-text {
      @include font(14);
      color: $blackColor;
      line-height: 1.5;
      margin-bottom: 10px;
      p {
        margin-bottom: 8px;
      }
      p:last-child {
        margin-bottom: 0;
      }
      .question-circle {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .professional-list {
      overflow: hidden;
      li {
        float: left;
        width: 31.333%;
        margin-right: 3%;
        margin-bottom: 30px;
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
        &:nth-child(3n + 4) {
          clear: both;
        }
        a {
          display: block;
          text-decoration: none;
          padding: 20px 15px;
          @include font(14);
          font-weight: 500;
          color: $blackColor;
          border: 1px solid $lightBlue;
          text-align: center;
          @include radius(4px);
          @include transition();
          span {
            display: block;
            &.icon-box {
              width: 40px;
              margin: 0 auto 10px;
              img {
                max-width: 100%;
              }
            }
          }
          &:hover {
            color: $lightBlue;
          }
        }
      }
    }
    .professional-video-profile {
      .professional-video-profile-list {
        li {
          @include flex();
          align-items: flex-start;
          justify-content: space-between;
          .img-box {
            width: 70px;
            height: 70px;
            @include radius(50px);
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content-box {
            @include font(14);
            font-weight: 400;
            color: $blackColor;
            font-style: italic;
            width: calc(100% - 100px);
            padding-top: 0;
            line-height: 1.6;
            margin-bottom: 20px;
            p {
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .button-box {
        margin-left: 100px;
        .button-item {
          display: block;
          margin-bottom: 20px;
          .view-example-toggle {
            @include font(14);
            font-weight: 500;
            color: $green;
            text-decoration: underline;
            @include transition();
            &:hover {
              color: $baseColor;
            }
          }
          .green {
            color: $white;
          }
        }
      }
    }
    .professional-video-example {
      overflow: hidden;
      padding-top: 5px;
      .item {
        float: left;
        width: 48%;
        margin-right: 4%;
        margin-bottom: 30px;
        text-align: center;
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
        &:nth-child(2n + 3) {
          clear: both;
        }
        a {
          display: block;
          text-decoration: none;
          @include font(14);
          color: $black;
          @include transition();
          span {
            display: block;
            &.img-box {
              margin-bottom: 10px;
              height: 140px;
              @include radius(4px);
              overflow: hidden;
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
          }
          &:hover {
            color: $baseColor;
          }
        }
      }
    }
  }
  .professional-video-edit-form {
    .item-row {
      h3 {
        @include font(18);
        color: $blackColor;
        margin-bottom: 15px;
      }
    }
    .fieldBox {
      margin-bottom: 20px;
    }
    .flexBox {
      margin-bottom: 20px;
      .three-col {
        width: calc(33.333% - 12px);
      }
    }
    .checkbox-block-item {
      @include font(15);
      color: $black;
      p {
        margin-bottom: 18px;
        &:last-child {
          margin-bottom: 0;
        }
        label {
          margin-bottom: 0;
        }
      }
    }
    .width150 {
      display: block;
      width: 150px !important;
    }
    .blue {
      background: $lightBlue;
    }
  }
  .add-special-instructions-sec {
    h3 {
      @include flex();
      justify-content: space-between;
      align-items: center;
    }
    ul {
      li {
        margin-bottom: 15px;
        padding-right: 70px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        .fieldBox.flexBox {
          display: block;
          overflow: hidden;
          .four-col {
            float: left;
            width: 23.5%;
            margin-right: 2%;
            &:nth-child(4n + 4) {
              margin-right: 0;
            }
            &:nth-child(3n + 5) {
              clear: both;
            }
          }
        }
        .button-box {
          position: absolute;
          right: 20px;
          top: 50%;
          margin-top: -10px;
          z-index: 1;
          button {
            cursor: pointer;
            background: none;
            border: none;
            outline: none;
            padding: 0;
            img {
              width: 14px;
            }
          }
        }
      }
    }
    .btn {
      padding: 5px 20px;
      @include font(14);
    }
  }
}
.flex-inline-items {
  @include flex();
  align-items: center;
  justify-content: space-between;
  li {
    width: 25%;
    &.flexbox {
      @include flex();
      align-items: center;
      justify-content: space-between;
      margin-top: 28px;
      width: 50%;
      label {
        margin: 0 10px;
        @include font(14);
        &:first-child {
          @include font(12);
          width: 100px;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.modal {
  &.common-slide-modal {
    .owl-nav {
      &.disabled{
        display: none;
      }
    }
  }
}
.professional-video-wrap {
  .tabs {
    @include flex();
    margin: 0 -17px 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $gray;
    li {
      display: inline-block;
      vertical-align: middle;
      margin-left: 20px;
      @include font(16);
      color: $black;
      cursor: pointer;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      &.current {
        color: $baseColor;
        &::before {
          content: "";
          position: absolute;
          background: $baseColor;
          height: 2px;
          left: 0;
          right: 0;
          bottom: -16px;
          z-index: 1;
        }
      }
    }
  }
  .professional-video-tab-data {
    .tab-content {
      display: none;
      &.current {
        display: block;
      }
    }
    .question-mark-icon {
      display: inline-block;
      vertical-align: middle;
      &.vertical-middle {
        svg {
          vertical-align: middle;
        }
      }
      svg {
        display: inline-block;
        vertical-align: 1px;
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .video-button-box {
      text-align: right;
      margin-bottom: 15px;
      .btn {
        @include font(14);
        padding: 10px 15px;
        color: $white;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .professional-video-info {
    padding-bottom: 20px;
    margin-bottom: 35px;
    border-bottom: 1px solid $gray;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    h3 {
      @include flex();
      justify-content: space-between;
      @include font(18);
      color: $blackColor;
      font-weight: 500;
      margin-bottom: 10px;
      .number-of-edit {
        @include font(16);
        color: $green;
      }
    }
    .short-text {
      @include font(14);
      color: $blackColor;
      line-height: 1.5;
      margin-bottom: 10px;
      p {
        margin-bottom: 8px;
      }
      p:last-child {
        margin-bottom: 0;
      }
      .question-circle {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
    }
    .professional-list {
      overflow: hidden;
      li {
        float: left;
        width: 31.333%;
        margin-right: 3%;
        margin-bottom: 30px;
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
        &:nth-child(3n + 4) {
          clear: both;
        }
        a {
          display: block;
          text-decoration: none;
          padding: 20px 15px;
          @include font(14);
          font-weight: 500;
          color: $blackColor;
          border: 1px solid $lightBlue;
          text-align: center;
          @include radius(4px);
          @include transition();
          span {
            display: block;
            &.icon-box {
              width: 40px;
              margin: 0 auto 10px;
              img {
                max-width: 100%;
              }
            }
          }
          &:hover {
            color: $lightBlue;
          }
        }
      }
    }
    .professional-video-profile {
      .professional-video-profile-list {
        li {
          @include flex();
          align-items: flex-start;
          justify-content: space-between;
          .img-box {
            width: 70px;
            height: 70px;
            @include radius(50px);
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content-box {
            @include font(14);
            font-weight: 400;
            color: $blackColor;
            font-style: italic;
            width: calc(100% - 100px);
            padding-top: 0;
            line-height: 1.6;
            margin-bottom: 20px;
            p {
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .button-box {
        margin-left: 100px;
        .button-item {
          display: block;
          margin-bottom: 20px;
          .view-example-toggle {
            @include font(14);
            font-weight: 500;
            color: $green;
            text-decoration: underline;
            @include transition();
            &:hover {
              cursor: pointer;
              color: $baseColor;
            }
          }
          .green {
            color: $white;
          }
        }
      }
    }
    .professional-video-example {
      overflow: hidden;
      padding-top: 5px;
      .item {
        float: left;
        width: 48%;
        margin-right: 4%;
        margin-bottom: 30px;
        text-align: center;
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
        &:nth-child(2n + 3) {
          clear: both;
        }
        a {
          display: block;
          text-decoration: none;
          @include font(14);
          color: $black;
          @include transition();
          span {
            display: block;
            &.img-box {
              margin-bottom: 10px;
              height: 140px;
              @include radius(4px);
              overflow: hidden;
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
            }
          }
          &:hover {
            color: $baseColor;
          }
        }
      }
    }
  }
  .professional-video-edit-form {
    .item-row {
      h3 {
        @include font(18);
        color: $blackColor;
        margin-bottom: 15px;
      }
    }
    .fieldBox {
      margin-bottom: 20px;
    }
    .flexBox {
      margin-bottom: 20px;
      .three-col {
        width: calc(33.333% - 12px);
      }
    }
    .checkbox-block-item {
      @include font(15);
      color: $black;
      p {
        margin-bottom: 18px;
        &:last-child {
          margin-bottom: 0;
        }
        label {
          margin-bottom: 0;
        }
      }
    }
    .width150 {
      display: block;
      width: 150px !important;
    }
    .blue {
      background: $lightBlue;
    }
  }
  .add-special-instructions-sec {
    h3 {
      @include flex();
      justify-content: space-between;
      align-items: center;
    }
    ul {
      li {
        margin-bottom: 15px;
        padding-right: 35px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        .fieldBox.flexBox {
          display: block;
          overflow: hidden;
          .four-col {
            float: left;
            width: 23.5%;
            margin-right: 2%;
            &:nth-child(4n + 4) {
              margin-right: 0;
            }
            &:nth-child(3n + 5) {
              clear: both;
            }
          }
        }
        .button-box {
          position: absolute;
          right: 5px;
          top: 50%;
          margin-top: -8px;
          z-index: 1;
        }
      }
    }
    .btn {
      padding: 5px 20px;
      @include font(14);
    }
  }
}
.flex-inline-items {
  @include flex();
  align-items: center;
  justify-content: space-between;
  li {
    width: 25%;
    &.flexbox {
      @include flex();
      align-items: center;
      justify-content: space-between;
      margin-top: 28px;
      width: 50%;
      label {
        margin: 0 10px;
        @include font(14);
        &:first-child {
          @include font(12);
          width: 100px;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.college-detail-banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  padding: 35px 0;
  margin-top: 60px;
  &::after {
    content: "";
    position: absolute;
    background: rgba($blackColor, 0.7%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .banner-detail-box {
    @include flex();
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 11;
    .banner-detail-text {
      @include flex();
      align-items: center;
      .college-banner-logo {
        @include flex();
        justify-content: center;
        align-items: center;
        background: $white;
        width: 200px;
        height: 200px;
        @include radius(50%);
        padding: 40px;
        margin-right: 20px;
        img {
          max-width: 100%;
        }
      }
      .college-banner-content {
        width: calc(100% - 220px);
        .title {
          @include font(24);
          font-weight: 300;
          color: $white;
          margin-bottom: 15px;
        }
        .address-info {
          display: block;
          li {
            display: block;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            a {
              display: inline-block;
              vertical-align: middle;
              text-decoration: none;
              @include font(16);
              color: $white;
              svg {
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                margin-right: 5px;
                @include transition();
                &.location-icon {
                  .a {
                    fill: none;
                    stroke: $white;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.8px;
                    @include transition();
                  }
                }
                &.sdf-icon {
                  .a {
                    fill: $white;
                    stroke: $white;
                    stroke-width: 0.5px;
                    @include transition();
                  }
                }
              }
              &:hover {
                color: $baseColor;
                svg {
                  path {
                    stroke: $baseColor;
                  }
                  &.location-icon {
                    .a {
                      stroke: $baseColor;
                    }
                  }
                  &.sdf-icon {
                    .a {
                      fill: $baseColor;
                      stroke: $baseColor;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .banner-wishlist-box {
    width: 75px;
    a {
      display: block;
      img {
        max-width: 100%;
      }
    }
  }
}
.college-main-section {
  padding: 30px 0 40px;
  background: $bgLightGray;
  .college-tabs-wrapper {
    .nav-tabs {
      background: $white;
      @include flex();
      justify-content: space-around;
      padding: 0 15px;
      border: 1px solid $gray;
      @include radius(5px);
      margin-bottom: 20px;
      li {
        margin-right: 20px;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
        a {
          @include flex();
          justify-content: center;
          align-items: center;
          text-decoration: none;
          position: relative;
          @include font(18);
          color: $black;
          height: 100%;
          padding: 15px 10px;
          outline: 0;
          border: none;
          &:hover {
            color: $baseColor;
            border: 0;
            border-radius: 0 !important;
            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              background: $baseColor;
            }
          }
          &.active {
            color: $baseColor;
            border: none;
            border-radius: 0 !important;
            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              background: $baseColor;
            }
          }
        }
      }
    }
    .tab-content {
      background: $white;
      padding: 15px 15px 25px;
      border: 1px solid $gray;
      @include radius(5px);
      .stepperBox {
        margin: 10px 0 25px;
        @include flex();
        justify-content: space-between;
        .item {
          width: 16.66%;
          position: relative;
          cursor: pointer;
          span {
            display: block;
            text-align: center;
            @include font(14);
            color: $black;
            &.circle {
              width: 14px;
              height: 14px;
              @include radius(50%);
              background-color: $bgLightGray;
              margin: 0 auto 6px auto;
              position: relative;
              z-index: 1;
            }
          }
          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            left: 0;
            top: 7px;
            width: 100%;
            height: 2px;
            background-color: #e5e5e5;
          }
          &.passed {
            &::before {
              background-color: $lightBlue;
            }
            span {
              &.circle {
                background-color: $lightBlue;
              }
            }
          }
          &.current {
            &::before {
              background-color: $lightBlue;
              width: 50%;
            }
            &::after {
              content: "";
              display: inline-block;
              position: absolute;
              left: 50%;
              top: 7px;
              width: 50%;
              height: 2px;
              background-color: $lightBlue;
            }
            span {
              position: relative;
              &.circle {
                background-color: $lightBlue;
                &::after,
                &::before {
                  bottom: -46px;
                  left: 50%;
                  border: solid transparent;
                  content: "";
                  height: 0;
                  width: 0;
                  position: absolute;
                  pointer-events: none;
                }
                &::after {
                  border-bottom-color: $white;
                  border-width: 15px;
                  margin-left: -15px;
                }
                &::before {
                  border-bottom-color: $gray;
                  border-width: 16px;
                  margin-left: -16px;
                }
              }
            }
          }
          &:last-child {
            &.current {
              &::before {
                background-color: $lightBlue;
                width: 100%;
              }
              &::after {
                display: none;
              }
            }
          }
        }
      }
      .college-tabs-content {
        padding: 25px 20px 0;
        border: 1px solid $gray;
        @include radius(5px);
        .tab-content {
          display: none;
          padding: 0;
          border: none;
          border-radius: none;
          &.current {
            display: block;
          }
          .college-info-list {
            overflow: hidden;
            li {
              float: left;
              width: 49%;
              margin-right: 2%;
              margin-bottom: 25px;
              &:nth-child(2n + 2) {
                margin-right: 0;
              }
              &:nth-child(2n + 3) {
                clear: both;
              }
              a {
                background: $bgLightGray;
                @include flex();
                align-items: center;
                text-decoration: none;
                padding: 10px 55px 10px 15px;
                @include font(18);
                font-weight: 500;
                color: $black;
                border: 0.5px solid #d0d0d0;
                @include radius(50px);
                line-height: 1.2;
                position: relative;
                &:hover {
                  opacity: 0.7;
                }
                span {
                  display: inline-block;
                  vertical-align: middle;
                  img {
                    display: block;
                  }
                  &.ml-8 {
                    margin-left: 8px;
                    position: absolute;
                    right: 15px;
                  }
                  &.mr-8 {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.videoOverlayWrap {
  .MuiDialog-paperScrollPaper {
    overflow: visible;
    max-width: 700px;
  }
  .closeBtnVideo {
    position: absolute !important;
    right: -20px;
    top: -20px;
    width: 50px;
    background: $white !important;
    &:focus, &:hover{
      outline: none;
    }
  }
}
.cardOverlayWrap {
  .MuiDialog-paperScrollPaper {
    overflow: visible;
  }
  .cardOverlayClose {
    position: absolute !important;
    right: -20px;
    top: -20px;
    width: 50px;
    background: #fff !important;
    &:focus,
    &:hover {
      outline: none;
    }
  }
  .payment-cart-box {
    max-width: 600px;
    margin: 30px auto 0;
    display: none;
    .payment-cart-inner {
      margin: 0 10px;
      border: 1px solid $gray;
      @include radius(4px);
      margin-bottom: 30px;
      .title-box {
        @include flex();
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid $gray;
        span {
          cursor: pointer;
          &.add-payment-button {
            @include font(15);
            font-weight: 500;
            color: $lightBlue;
          }
          &.close-payment-button {
            img {
              display: inline-block;
              vertical-align: middle;
              width: 12px;
            }
          }
        }
      }
      .payment-detail {
        padding: 20px;
        .flexbox {
          @include flex();
          justify-content: space-between;
          align-items: center;
          text-align: left;
          margin-bottom: 15px;
          padding-right: 90px;
          &:last-child {
            margin-bottom: 0;
          }
          &.full {
            display: block;
          }
          .half {
            width: calc(50% - 10px);
          }
          label {
            display: block;
            @include font(15);
            font-weight: 500;
            color: #666666;
            margin-bottom: 6px;
          }
          input {
            padding: 0 15px;
            color: $black;
            border: 1px solid $gray;
            width: 100%;
            height: 34px;
            @include radius(4px);
          }
          .security-code {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              top: 23px;
              right: -90px;
              background: url(../img/security-code.png) no-repeat;
              background-position: center center;
              width: 80px;
              height: 31px;
            }
          }
        }
      }
    }
    .payment-button {
      text-align: center;
      button {
        @include font(12);
        font-weight: 500;
        letter-spacing: 0.18px;
        border: none;
        outline: none;
        margin-bottom: 10px;
        &.cancel-link {
          background: none;
          color: $baseColor;
          cursor: pointer;
        }
      }
    }
  }
}
.padding-lf-none {
  padding-left: 0;
  padding-right: 0;
}
.event-main-wrapper {
  .event-top-block {
    text-align: center;
    margin-bottom: 20px;
    &.with-border {
      border-bottom: 1px solid $gray;
      padding: 0 15px 20px;
    }
    h3 {
      @include font(18);
      font-weight: 500;
      color: $blackColor;
      + .short-text {
        margin-top: 15px;
      }
      &.font15 {
        @include font(15);
      }
    }
    .short-text {
      @include font(16);
      font-weight: 400;
      color: $blackColor;
      line-height: 1.5;
      max-width: 430px;
      margin: 0 auto;
      &.w-100 {
        max-width: 100%;
      }
      p {
        margin-bottom: 15px;
      }
      .btn {
        @include font(14);
        font-weight: 300;
        padding: 12px 16px;
        color: $white;
        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px;
          path {
            fill: $white;
          }
        }
      }
    }
  }
  .reference-data-box {
    + .common-button-box {
      border-top: 1px solid $gray;
    }
  }
  .common-button-box {
    padding-bottom: 0;
    .next-prev-button {
      button {
        display: inline-block;
        vertical-align: middle;
        background: none;
        border: none;
        @include font(16);
        font-weight: 400;
        color: rgba($black, .6);
        padding: 0;
        margin-right: 10px;
        @include transition();
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        svg {
          width: 18px;
          height: 18px;
          margin-top: -1px;
        }
        &:hover {
          color: $baseColor;
        }
      }
    }
  }
  &.press-main-wrap {
    .event-top-block {
      .short-text {
        @include font(14);
      }
    }
  }
  .event-data-box {
    .tab-white-box {
      background: $white;
      padding: 0 15px;
      border: 1px solid $gray;
      @include radius(4px);
      margin: 0 auto 15px auto;
    }
    .tabs {
      @include flex();
      align-items: center;
      li {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        a {
          display: block;
          text-decoration: none;
          padding: 15px 10px;
          @include font(14);
          color: $blackColor;
          position: relative;
        }
        &.current {
          a {
            color: $baseColor;
            &:after {
              content: "";
              display: inline-block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background: $baseColor;
            }
          }
        }
      }
    }
    .event-tab-content {
      .tab-content {
        display: none;
        &.current {
          display: block;
        }
        .form-button-box {
          &.with-next-prev {
            margin-top: 20px;
            padding-top: 15px;
            border-top: 1px solid $gray;
          }
        }
        .next-prev-button {
          button {
            display: inline-block;
            vertical-align: middle;
            background: none;
            border: none;
            @include font(16);
            font-weight: 400;
            color:rgba($black, .6);
            padding: 0;
            margin-right: 10px;
            @include transition();
            svg {
              width: 18px;
              height: 18px;
              margin-top: -1px;
            }
            &:last-child {
              margin-right: 0;
            }
            &:hover {
              color: $baseColor;
            }
          }
        }
      }
    }
    .events-list {
      > li {
        @include flex();
        background: $white;
        border: 1px solid rgba($gray, 0.55%);
        @include radius(4px);
        padding: 15px;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .logo-box {
          width: 100px;
          margin-right: 15px;
          img {
            max-width: 100%;
          }
        }
        .detail-box {
          width: calc(100% - 100px);
          @include font(13);
          color: $black;
          h3 {
            @include flex();
            justify-content: space-between;
            align-items: center;
            @include font(14);
            font-weight: 500;
            color: $blackColor;
            margin-bottom: 8px;
            line-height: 1.3;
            span {
              font-weight: 400;
              color: $black;
              width: calc(100% - 180px);
            }
            strong {
              color: $blackColor;
            }
            .button-box {
              @include flex();
              justify-content: end;
              align-items: center;
            }
            .trash-button {
              @include flex();
              align-items: center;
              vertical-align: middle;
              background: none;
              padding: 0;
              color: $baseColor;
              border: none;
              outline: none;
              margin-right: 6px;
              cursor: pointer;
              .icon-box {
                display: inline-block;
                vertical-align: middle;
                width: auto;
                margin-right: 6px;
              }
              svg {
                .a {
                  fill: none;
                  stroke: $baseColor;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1.5px;
                  @include transition();
                }
              }
              &:hover {
                color: #b12c1a;
                svg {
                  .a {
                    stroke: #b12c1a;
                  }
                }
              }
            }
            .edit-event {
              display: inline-block;
              vertical-align: middle;
              text-decoration: none;
              @include font(13);
              color: $green;
              cursor: pointer;
              margin-left: 6px;
              .icon-box {
                display: inline-block;
                vertical-align: middle;
                margin-right: 4px;
                width: auto;
                svg {
                  path {
                    fill: $green;
                  }
                }
              }
            }
          }
          p {
            margin-bottom: 5px;
            line-height: 1.5;
            strong {
              color: $blackColor;
            }
            span {
              margin-right: 10px;
              &:last-child {
                margin-right: 0;
              }
            }
            a {
              display: inline-block;
              vertical-align: middle;
              @include transition();
              &:hover {
                color: $baseColor;
              }
            }
          }
          .share-box-wrap {
            @include flex();
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid $gray;
            padding-top: 10px;
            margin-top: 10px;
            .social-links {
              @include flex();
              align-items: center;
              li {
                @include font(13);
                font-weight: 500;
                color: $blackColor;
                margin-right: 15px;
                a {
                  display: block;
                  @include transition();
                  &.twitter {
                    svg {
                      .a {
                        fill: $lightBlue;
                      }
                    }
                  }
                  &.facebook {
                    svg {
                      .a {
                        fill: $blue;
                      }
                    }
                  }
                  &.message {
                    svg {
                      .a {
                        fill:$green;
                        stroke:$green;
                        stroke-width:0.5px;
                      }
                    }
                  }
                  &.email {
                    svg {
                      .a {
                        fill:$baseColor;
                      }
                    }
                  }
                  &:hover {
                    opacity: 0.8;
                  }
                }
              }
            }
            p {
              &.checkbox-list {
                span {
                  display: inline-flex;
                  align-items: center;
                  position: relative;

                  input[type="checkbox"] {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    z-index: 1;
                    cursor: pointer;

                    &:checked + label {
                      &::before {
                        border-color: $lightBlue;
                      }

                      &::after {
                        width: 12px;
                        height: 12px;
                        background: url(../img/checkBlue.png) no-repeat;
                        background-size: cover;
                        content: "";
                        display: inline-block;
                        position: absolute;
                        left: 3px;
                        top: 5px;
                      }
                    }
                  }
                  label {
                    position: relative;
                    font-size: 14px;
                    color: $black;
                    font-weight: normal;
                    padding-left: 30px;
                    cursor: pointer;
                    line-height: 18px;
                    color: $black;

                    &::before {
                      width: 18px;
                      height: 18px;
                      border: 1px solid $gray;
                      @include radius(4px);
                      content: "";
                      display: inline-block;
                      position: absolute;
                      left: 0;
                      top: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .reference-data-box {
    padding-left: 15px;
    padding-right: 15px;
    .reference-list {
      overflow: hidden;
      > li {
        float: left;
        width: 49%;
        margin-right: 2%;
        background: $white;
        border: 1px solid rgba($gray, 0.6%);
        box-shadow: 0 0 10px #0000000d;
        @include radius(4px);
        margin-bottom: 20px;
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
        &:nth-child(2n + 3) {
          clear: both;
        }
        .title-box {
          @include flex();
          justify-content: space-between;
          align-items: center;
          padding: 14px 15px;
          background: rgba($gray, 0.45%);
          @include font(14);
          color: $blackColor;
          h3 {
            margin-right: 10px;
          }
          .button-box {
            @include flex();
            align-items: center;
            justify-content: flex-end;
            button {
              @include flex();
              align-items: center;
              background: none;
              padding: 0;
              border: 0;
              outline: none;
              margin-right: 20px;
              @include transition();
              cursor: pointer;
              &:last-child {
                margin-right: 0;
              }
              .icon-box {
                margin-right: 8px;
                display: inline-block;
                vertical-align: middle;
              }
              &.trash-button {
                color: $baseColor;
                svg {
                  .a {
                    fill: none;
                    stroke: $baseColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }
                }
                &:hover {
                  color: $baseColor;
                }
              }
              &.edit-button {
                color: $green;
                svg {
                  .a {
                    fill: $green;
                  }
                }
                &:hover {
                  color: $green;
                }
              }
            }
          }
        }
        .reference-detail-box {
          margin: 0 15px;
          &.additional {
            li {
              &.last {
                display: block;
                span {
                  display: block;
                  margin-bottom: 5px;
                }
                p {
                  @include font(12);
                  color: $black;
                  line-height: 1.5;
                }
              }
            }
          }
          li {
            @include flex();
            justify-content: space-between;
            align-items: center;
            padding: 12px 0;
            @include font(13);
            font-weight: 400;
            color: $blackColor;
            line-height: 1.3;
            border-bottom: 1px solid rgba($gray, 0.4%);
            &:last-child {
              border-bottom: none;
            }
            &.flex-none {
              display: block;
            }
            .flex-box {
              @include flex();
              justify-content: space-between;
              align-items: center;
              margin-bottom: 5px;
            }
            .string-text {
              @include font(12);
              color: $lightBlue;
              a {
                display: inline-block;
                vertical-align: middle;
                text-decoration: underline;
                color: $lightBlue;
                &:hover {
                  color: $baseColor;
                }
              }
            }
            span {
              &:last-child {
                color: $black;
                margin-left: 10px;
              }
            }
            button {
              @include flex();
              align-items: center;
              background: none;
              border: none;
              outline: none;
              cursor: pointer;
              .icon-box {
                margin-right: 8px;
                display: inline-block;
                vertical-align: middle;
              }
              &.trash-button {
                color: $baseColor;
                svg {
                  .a {
                    fill: none;
                    stroke: $baseColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }
                }
                &:hover {
                  color: $baseColor;
                }
              }
              &.edit-button {
                color: $green;
                svg {
                  .a {
                    fill: $green;
                  }
                }
                &:hover {
                  color: $green;
                }
              }
            }
            .form-check {
              display: block;
              padding-left: 0;
              .form-check-label {
                display: inline-block;
                vertical-align: middle;
                @include font(12);
                color: $blackColor;
                margin-right: 5px;
                cursor: pointer;
              }
              &.form-switch {
                @include flex();
                align-items: center;
                .form-check-input {
                  background-image: none;
                  border-width: 0;
                  @include radius(50px);
                  width: 28px;
                  height: 10px;
                  background: rgba($gray, 0.5%);
                  border: none;
                  margin-left: 0;
                  appearance: none;
                  position: relative;
                  @include transition();
                  cursor: pointer;
                  margin-top: 0;
                  &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: -2px;
                    border: none;
                    z-index: 2;
                    border-radius: 50%;
                    width: 14px;
                    height: 14px;
                    background: $black;
                    @include transition();
                  }
                }
                input:checked[type="checkbox"] {
                  background: rgba($green, 0.5%);
                  &:after {
                    left: auto !important;
                    right: 0 !important;
                    background: $green;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.full {
      padding-left: 0;
      padding-right: 0;
      .reference-list {
        > li {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  .press-listing {
    .item-box {
      background: $white;
      @include flex();
      justify-content: space-between;
      align-items: center;
      padding: 12px 15px;
      border: 1px solid #0000000d;
      box-shadow: 0 0 10px #0000000d;
      @include radius(4px);
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .box-detail {
        @include flex();
        align-items: center;
        margin-right: 15px;
        .icon-box {
          background: $blue;
          @include flex();
          justify-content: space-between;
          align-items: center;
          text-align: center;
          flex: 0 0 40px;
          width: 40px;
          height: 40px;
          @include font(14);
          color: $white;
          @include radius(50px);
          margin-right: 10px;
          svg {
            display: block;
            margin: 0 auto;
            path {
              fill: $white;
            }
          }
        }
        .content-box {
          @include font(14);
          font-weight: 400;
          color: $blackColor;
          line-height: 1.4;
          margin-right: 10px;
          p {
            @include font(13);
            font-weight: 300;
            margin-top: 5px;
          }
        }
      }
      .buton-box {
        @include flex();
        button {
          padding: 0;
          width: 36px;
          height: 36px;
          @include radius(50px);
          margin-right: 10px;
          outline: none;
          &:last-child {
            margin-right: 0;
          }
          svg {
            display: block;
            margin: 0 auto;
            path {
              fill: $white;
            }
          }
          &.atached-button {
            background: none;
            border: none;
            outline: none;
            svg {
              display: block;
              margin: 0 auto;
              path {
                fill: $black;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}
.academic-list {
  .academic-box-item {
    padding: 15px;
    border-bottom: 1px solid $gray;
    margin-bottom: 10px;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
    .title {
      background: rgba($gray, 0.2%);
      padding: 0.8em 0.8em;
      @include font(16);
      color: $green;
      border-left: 3px solid $green;
      margin-bottom: 20px;
    }
    .academic-button-box {
      margin-bottom: 20px;
      .btn {
        padding: 10px 20px;
        @include font(14);
        font-weight: 300;
        svg {
          margin-right: 5px;
          fill: $white;
        }
      }
    }
    .reference-data-box {
      padding-left: 0;
      padding-right: 0;
    }
    p {
      &.checkbox-list {
        span {
          display: inline-flex;
          align-items: center;
          position: relative;

          input[type="checkbox"] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
            cursor: pointer;

            &:checked + label {
              &::before {
                border-color: $lightBlue;
              }
              &::after {
                width: 12px;
                height: 12px;
                background: url(../img/checkBlue.png) no-repeat;
                background-size: cover;
                content: "";
                display: inline-block;
                position: absolute;
                left: 3px;
                top: 5px;
              }
            }
          }

          label {
            position: relative;
            @include font(14);
            color: $black;
            font-weight: normal;
            padding-left: 16px;
            cursor: pointer;
            line-height: 18px;
            &::before {
              width: 18px;
              height: 18px;
              border: 1px solid $black;
              content: "";
              display: inline-block;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }
    }
  }
}
.common-button-box {
  border-top: 1px solid $gray;
  padding: 20px 15px;
  @include flex();
  justify-content: left;
  align-items: center;
  .btn {
    padding: 10px 20px;
    @include font(14);
    font-weight: 400;
    min-width: 80px;
    &.lightblue {
      background: $lightBlue;
    }
  }
  &.with-next-prev {
    justify-content: space-between;
    align-items: center;
    // &.center {
    //    justify-content: center;
    // }
    .next-prev-button {
      button {
        display: inline-block;
        vertical-align: middle;
        background: none;
        border: none;
        @include font(16);
        font-weight: 400;
        color:rgba($black, .6);
        padding: 0;
        margin-right: 10px;
        @include transition();
        svg {
          width: 18px;
          height: 18px;
          margin-top: -1px;
        }
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: $baseColor;
        }
      }
    }
  }
}
.addEditOverlay {
  .MuiOutlinedInput-input {
    padding: 14.5px 15px;
    text-align: left;
    font-size: 0.875rem;
  }
  [class*="PrivateNotchedOutline-legendNotched"] {
    max-width: 0;
  }
  .btn.blue{
    background: $lightBlue;
  }
  .modal-content {
    width: 480px;
    margin: 20px auto;
    @media screen and (max-width: 576px){
        margin: 10px;
    }
    .modal-body {
      .form-group {
        label {
          margin-bottom: 1em;
        }
      }
      .radioGroup {
        float: left;
        .MuiFormControlLabel-root {
          margin: 0;
          margin-bottom: 0.5rem;
          .MuiIconButton-root {
            background: none;
            padding: 0 10px 0 0;
          }
          .MuiFormControlLabel-label {
            font-size: 0.875rem;
          }
          .MuiRadio-colorSecondary.Mui-checked{
            color: $lightBlue;
          }
        }
      }
      .uploadFeature{
        margin-bottom: 20px;
        float: left;
        width: 100%;
        margin-top: 20px;
        label{
          margin-bottom: .5em;
        }
        h5 {
          @include font(12);
          text-align: left;
          margin-bottom: 15px;
          color: #666;
          font-weight: normal;
          line-height: 1.4;
        }
        .btn {
          max-width: 120px;
          color: $white !important;
          float: left;
        }
        .fileName {
          text-align: left;
          float: left;
          margin-top: 15px;
          margin-left: 15px;
          color: $blackColor;
          .close-button{
            border: none;
            background: none;
            padding-left: 15px;
            vertical-align: middle;
            cursor: pointer;
            svg{
              fill: $baseColor;
            }
          }
        }
        &.uploadList {
          h5 {
            @include font(12);
            text-align: left;
            margin: 5px 0 0 0;
            font-weight: normal;
            float: right;
            color: $baseColor;
            font-style: italic;
            line-height: 1.4;
            .uploadfilename {
              display: block;
              @include font(13);
              color:$black;
              line-height: 1.2;
              margin-bottom: 5px;
            }
          }
          .fileName {
            width: 100%;
            border-top: 1px solid $gray;
            margin: 0;
            padding: 8px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .close-button {
              float: right;
            }
            &:last-of-type{
              border-bottom: 1px solid $gray;
            }
            &:first-of-type {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  &.addReferenceOverlay {
    overflow: auto;
    .modal-dialog {
      max-width: 800px;
    }
    .modal-content {
        width: 800px;
        margin: 20px auto;
        @media screen and (max-width: 992px){
            width: 95%;
            margin: 10px auto;
        }
      .modal-body {
        .form-group {
          &:last-child {
            margin-bottom: 15px;
          }
          .countryPhoneFlexBox {
            input {
              height: 46px;
            }
            .css-1uccc91-singleValue {
              vertical-align: middle;
              justify-content: center;
              align-items: center;
              display: flex;
              transform: none;
              top: 13px;
            }
            .css-g1d714-ValueContainer {
              height: 46px;
            }
          }
          .autocomplete-class {
            input {
              padding: 0 15px;
              height: 46px;
              font-size: 0.875rem;
              font-weight: 400;
              color: $black;
            }
            .MuiInput-underline:before {
              display: none;
            }
          }
        }
        .phone.flexBox {
          @include flex();
          align-items: center;
          justify-content: space-between;
          border: 1px solid $gray;
          padding: 0 10px;
          border-radius: 4px;
          .countryFlag {
            width: 35px;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          select {
            outline: none;
            border: none;
            color: $black;
            @include font(14);
            padding: 5px 15px 5px 5px;
            -webkit-appearance: button;
            appearance: button;
            width: 70px;
            background: none;
          }
          input {
            width: calc(100% - 125px);
            border: none;
          }
        }
      }
      .modal-footer {
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid $gray;
      }
      .iconTwitter {
        position: absolute;
        left: 15px;
        width: 40px;
        height: 46px;
        border-right: 1px solid $gray;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font(16);
      }
    }
    textarea {
      width: 100%;
      min-height: 120px;
      padding: 10px 15px;
      resize: none;
      border: 1px solid $gray;
      border-radius: 5px;
      line-height: 1.4;
    }
  }
  &.addEditPressOverlay {
    .modal-dialog {
      max-width: 538px;
    }
  }
}
.team-common-form {
  form {
    .MuiInputBase-formControl {
      border-radius: 0;
      .MuiOutlinedInput-input {
        padding: 13.7px 15px;
        &::placeholder {
          color: $black !important;
        }
      }
    }
  }
  .invite-coach-wrap {
    .btn.orange {
      background: $orange !important;
    }
  }
}
.delete-modal {
  &.common-modal {
    &.coache-modal {
      .connect-with-coaches-top-box {
        align-items: normal;
      }
    }
  }
}
.TimelineData {
  .video-and-photo-wrap {
    .video-and-photo-main {
      .sidebarbox {
        width: 140px;
      }
      .content-box {
        width: calc(100% - 140px);
      }
    }
  }
  .video-second-block {
    .video-tab-contents {
      .video-grid-list {
        .video-box {
          width: 31.333%;
          &:nth-child(2n+2) {
            margin-right:3%;
          }
          &:nth-child(2n+3) {
            clear: none;
          }
          &:nth-child(3n+3) {
            margin-right: 0;
          }
          &:nth-child(3n+4) {
            clear: both;
          }
          .img-box {
            height: 160px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .editProfile {
    .editSection {
      .subTitle {
        padding: 1em;
      }
      .flexRow {
        flex-wrap: wrap;
        padding: 0 1em;

        &.subscription {
          margin: 1em;
        }
        .MuiFormControl-marginNormal {
          margin: 0;
        }
        .col {
          flex-basis: auto;
          padding:20px 0 0;
          .form-group {
            &:last-child {
              margin-bottom: 0;
            }
          }
          &:first-of-type {
            padding-right: 0;
          }
          &:last-of-type {
            padding-left: 0;
          }
        }
      }
    }
  }
  .profile-upload-main-box {
    .profile-upload-box {
      width: 100%;
      .profile {
        display: flex;
        justify-content: center;
        flex: 0 0 109px;
        align-items: center;
      }
      .profile-detail {
        .btn {
          padding: 8px 15px;
          @include font(15);
        }
      }
    }
  }
  .editprofile-common-form {
    form {
      .fieldBox {
        p {
          &.checkbox-list {
            span {
              label {
                @include font(13);
              }
            }
          }
        }
      }
      .notification-setting-box {
        .notification-content {
          .flexbox {
            &:first-child {
              @include font(12);
            }
          }
        }
      }
    }
    .notification-setting-box {
      .notification-content {
        .flexbox {
          .item {
            width: 17%;
            &:first-child {
              width: 70%;
            }
          }
        }
      }
    }
  }
  .video-and-photo-wrap {
    .video-and-photo-main {
      .content-box {
        width: calc(100% - 170px);
      }
      .sidebarbox {
        width: 170px;
      }
      .video-top-block {
        display: block;

        .video-help-text {
          width: 100%;
        }

        .video-links {
          width: 100%;

          .half {
            margin-bottom: 10px;
          }
        }
      }
    }
    .video-second-block {
      .video-grid-control {
        margin-top: -10px;
      }
      .video-tab-contents {
        .video-grid-list {
          &.list {
            .video-box {
              .img-box {
                width: 160px;
              }
              .video-detail-box {
                width: calc(100% - 160px);
                .video-text {
                  p {
                    &.checkbox-list {
                      span {
                        label {
                          &:after {
                            top: 7px;
                          }
                          &:before {
                            top: 3px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .title-box {
              .switch-video-button {
                width: auto;
              }
            }
          }
          .title-box {
            .switch-video-button {
              width: 88px;
            }
          }
        }
      }
    }
  }
  .professional-video-wrap {
    padding-right: 0;
    .professional-video-info {
      h3 {
        @include font(18);
      }
      .professional-list {
        li {
          a {
            padding: 20px 10px;
            line-height: 1.2;
            min-height: 125px;
          }
        }
      }
      .professional-video-profile {
        .professional-video-profile-list {
          li {
            .content-box {
              br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .college-main-section {
    .college-tabs-wrapper {
      .tab-content {
        .college-tabs-content {
          .tab-content {
            .college-info-list {
              li {
                a {
                  @include font(16);
                }
              }
            }
          }
        }
      }
    }
  }
  .event-main-wrapper {
    .event-data-box {
      .events-list {
        > li {
          .logo-box {
            width: 80px;
          }
          .detail-box {
            .share-box-wrap {
              p {
                &.checkbox-list {
                  span {
                    label {
                      @include font(13);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .reference-data-box {
      .reference-list {
        > li {
          .title-box {
            &.main {
              padding-left: 10px;
              padding-right: 10px;
            }
            .button-box {
              button {
                margin-right: 15px;
              }
            }
          }
          .reference-detail-box {
            &.main {
              margin: 0 10px;
              li {
                @include font(12);
                span {
                  &:last-child {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .editProfile {
    .sidebar {
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      z-index: 99;
    }
    .editSection {
      width: 100%;
    }
    .inactive {
      .sidebar {
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
      }
      .editSection {
        width: 100%;
      }
      
    }
    .subHeader  {
      .profile-verified-text {
        display: none;
      }
    }
  }
  .strengthen-profile-box {
    .common-box-list {
      &:last-child {
        margin-bottom: 0;
      }
      .flex-box {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .modal {
    padding-right: 0;
    &.common-slide-modal {
      .video-detail-box {
        .img-box {
          width: 200px;
          height: 200px;
        }
        .detail-box {
          width: calc(100% - 215px);
        }
        .profile-info-box {
          .button-box-info {
            button {
              margin-right: 5px;
              padding: 8px 12px;
              min-width: 70px;
            }
          }
        }
      }
    }
  }
  .video-and-photo-wrap {
    .video-second-block {
      .video-tab-contents {
        .video-grid-list {
          .title-box {
            .switch-video-button {
              width: auto;
            }
          }
        }
      }
    }
  }
  .professional-video-wrap {
    .professional-video-info {
      .professional-list {
        li {
          a {
            min-height: 1px;
          }
        }
      }
      .short-text br {
        display: none;
      }
    }
  }
  .college-detail-banner {
    .banner-detail-box {
      .banner-detail-text {
        .college-banner-logo {
          width: 180px;
          height: 180px;
        }
        .college-banner-content {
          width: calc(100% - 190px);
        }
      }
    }
  }
  .college-main-section {
    .college-tabs-wrapper {
      .nav-tabs {
        li {
          margin-right: 5px;
          a {
            @include font(15);
          }
        }
      }
      .tab-content {
        .college-tabs-content {
          .tab-content {
            .stepperBox {
              .item {
                span {
                  @include font(12);
                }
              }
            }
            .college-info-list {
              li {
                a {
                  @include font(14);
                }
              }
            }
          }
        }
      }
    }
  }
  .common-modal {
    &.form-modal {
      .modal-lg {
        max-width: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .TimelineData {
    .video-second-block {
      .video-tab-contents {
        .video-grid-list {
          .video-box {
            width: 48.5%;
            &:nth-child(3n+3) {
              margin-right: 3%;
            }
            &:nth-child(3n+4) {
              clear: none;
            }
            &:nth-child(2n+2) {
              margin-right:0;
            }
            &:nth-child(2n+3) {
              clear: both;
            }
            .img-box {
              height: 200px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .editProfile {
    .sidebar {
      position: absolute;
      z-index: 11;
      top: 61px;
      overflow-y: auto;
    }
    .editSection {
      width: 100%;
      min-height: 500px;
      .flexRow {
        .col {
          padding-top: 15px;
        }
      }
      .flexBox {
        &.heightFieldBoxMain {
          display: block;
          .half {
            width: 100%;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
            &.heightFieldBox {
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .strengthen-profile-box {
    .common-box-list {
      .flex-box {
        .icon-box {
          flex: 0 0 40px;
        }

        .box-buton-box {
          .btn {
            @include font(12);
          }
        }
      }

      &.request-block-box {
        .box-buton-box {
          @include flex();
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .profile-upload-main-box {
    display: block;
    .profile-upload-box {
      margin-bottom: 20px;
    }
    .help-message-box {
      padding: 0.7em 2.5em 0.7em 1em;
    }
  }
  .editprofile-common-form {
    form {
      h4 {
        margin-bottom: 15px;
      }
      .fieldBox.flexBox {
        &.phone {
          &.block-on-mobile {
            display: block;
            .half {
              width: 100%;
            }
          }
        }
        .half {
          width: calc(50% - 8px);
        }
      }
      .notification-setting-box {
        .notification-content {
          .flexbox {
            &:first-child {
              @include font(12);
            }
          }
        }
      }
      .address-box-wrap {
        .file-button-box {
          display: block;
          margin-bottom: 15px;
          span {
            display: block;
            @include font(13);
            line-height: 1.3;
            margin-top: 10px;
          }
        }
        .fileName {
          margin-top: 10px;
          margin-left: 0;
        }
      }
    }
    .notification-setting-box {
      .notification-content {
        .flexbox {
          .item {
            span {
              &.text {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .video-and-photo-wrap  {
    .video-and-photo-main {
      display: block;
      .sidebarbox {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
        border-bottom: 1px solid $gray;
        min-height: 1px;
        .menu-links li {
          margin-bottom: 15px;
        }
      }
      .content-box {
        width: 100%;
      }
      .video-top-block {
        .link-box {
          min-height: 170px;
        }
        .video-links {
          &.full {
            .half {
              width: 100%;
              .link-box {
                .text {
                  min-height: 1px;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal {
    &.common-slide-modal {
      .owl-nav {
        margin-top: -20px;
        button {
          width: 35px;
          height: 35px;
          span {
            width: 10px;
            height: 10px;
          }
          &.owl-next {
            right: -50px;
          }
          &.owl-prev {
            left: -50px;
          }
        }
      }
    }
  }
  .college-detail-banner {
    .container {
      max-width: 100%;
    }
    .banner-detail-box {
      .banner-detail-text {
        width: 85%;
        .college-banner-logo {
          width: 120px;
          height: 120px;
          padding: 30px;
        }
        .college-banner-content {
          .title {
            @include font(20);
          }
          .address-info {
            li {
              margin-bottom: 14px;
              a {
                @include font(14);
              }
            }
          }
        }
      }
      .banner-wishlist-box {
        width: 60px;
      }
    }
  }
  .college-main-section {
    .college-tabs-wrapper {
      .nav-tabs {
        li {
          a {
            @include font(13);
            padding: 12px 0;
          }
        }
      }
      .tab-content {
        .stepperBox {
          .item {
            span {
              @include font(11);
            }
            &.current {
              span {
                &.circle {
                  &::after,
                  &::before {
                    bottom: -43px;
                  }
                }
              }
            }
          }
        }
        .college-tabs-content {
          padding: 15px 15px 0;
          .tab-content {
            .college-info-list {
              li {
                float: none;
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;
                a {
                  @include font(14);
                  line-height: 1.3;
                  span {
                    img {
                      height: 22px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .event-main-wrapper {
    .event-top-block {
      text-align: center !important;
    }
    .event-data-box {
      .events-list {
        > li {
          .detail-box {
            width: 100%;
            h3 {
              align-items: flex-start;
            }
            .share-box-wrap {
              flex-direction: column;
              align-items: flex-start;
              .social-links {
                margin-top: 5px;
                order: 2;
                li {
                  margin-right: 10px;
                }
              }
              p {
                &.checkbox-list {
                  span {
                    label {
                      padding-left: 25px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .reference-data-box {
      .reference-list {
        > li {
          float: none;
          width: 100%;
          margin-right: 0;
        }
      }
    }
    .press-listing {
      .item-box {
        padding-left: 10px;
        padding-right: 10px;
        .box-detail {
          .content-box {
            @include font(13);
          }
        }
        .buton-box {
          button {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .common-modal {
    &.form-modal {
      .editprofile-common-form {
        form {
          .fieldBox {
            &.phone {
              .flexBox {
                overflow: hidden;
                .countryFlag {
                  img {
                    max-width: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .modal-content {
      .modal-body {
        .form-group {
          .row {
            .col-md-6 {
              margin-bottom: 15px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
  
  .ReactModalPortal {
    .ReactModal__Overlay {
      .ReactModal__Content {
        width: 100%;
      }
    }
  }
  .videoOverlayWrap {
    .MuiDialog-paperScrollPaper {
      iframe {
        width: 500px!important;
      }
    }
  }
  .academic-detail-listing {
    .item-row {
      .item-row-inner {
        .item {
          &:first-child {
            min-width: 120px;
          }
        }
      }
    }
  }
  .TimelineData {
    .video-and-photo-wrap {
      .video-and-photo-main {
        .sidebarbox {
          width: 100%;
        }
        .content-box {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 639px) { 
  .editProfile {
    .editSection {
      .btnRow {
        &.with-next-prev {
          &.buttons {
            display: block;
            padding-bottom: 25px;
            .buttons {
              margin-bottom: 15px;
            }
            .next-prev-button {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .event-main-wrapper {
    .event-data-box {
      .events-list {
        > li {
          .detail-box {
            h3 {
              position: relative;
              padding-right: 60px;
              span {
                width: auto;
              }
              .button-box {
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
                .trash-button,
                .edit-event {
                  font-size: 0;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .professional-video-wrap {
    .professional-video-info {
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 579px) {
  .editProfile {
    .sidebar {
      .profileImg {
        min-height: 1px;
        padding: 1.5em 1.8em;
        .pic {
          width: 80px;
          height: 80px;
          @include font(20);
          img.verified.new {
            width: 22px;
          }
        }
        .browse {
          -webkit-transform: translate(-50%, 20%);
          transform: translate(-50%, 20%);
          img {
            width: 15px;
          }
          label {
            top: -10px;
            right:-8px;
          }
        }
        .name {
          margin: 1.2em 0 0.75em;
          h2 {
            @include font(16);
          }
          h3 {
            @include font(14);
          }
        }
        .back a {
          padding: 0.8em 2em;
          @include font(14);
        }
      }
      .checklist a {
        @include font(14);
      }
    }
    .editSection {
      width: 100%;
      .subTitle {
        @include font(16);
        margin-top: 10px;
      }
      .flexRow {
        .title {
          @include font(18);
        }
        &.subscription {
          .col {
            padding-top: 5px;
          }
        }

        .col {
          .note {
            @include font(13);
            margin-top: 0.6em;
          }
        }

        &.link a {
          @include font(15);
        }

        label {
          @include font(14);
        }

        input {
          @include font(14);
        }

        &:first-of-type {
          flex-wrap: wrap;
          border: 0;
          padding: 1.2em 1em;

          .title {
            text-align: center;
            width: 100%;
          }

          .btnWrap {
            width: 100%;
            justify-content: center;

            button {
              margin: 1em 0.5em 0;
            }
          }
        }
      }
      .btnRow {
        flex-wrap: wrap;
        margin-bottom: 0;
        &.with-next-prev {
          .next-prev-button {
            margin-left: 0;
          }
        }
        .btn {
          width: 100%;

          &:first-of-type {
            margin-bottom: 1em;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .subHeader {
      .menu button {
        @include font(16);
      }
    }
  }
  .strengthen-profile-box {
    .common-box-list {
      .flex-box {
        display: block;
        justify-content: center;
        text-align: center;
        .box.detail {
          display: block;
        }
        .icon-box {
          margin: 0 auto 10px;
        }
        .content-box {
          margin: 0 auto 12px;

          h3 {
            @include font(16);
            margin-bottom: 5px;
          }

          p {
            @include font(13);
          }
        }
      }
    }
  }
  .profile-upload-main-box {
    .profile-upload-box {
      .profile {
        width: 100px;
        height: 100px;
        flex: 0 0 100px;
        .pic {
          .user-picture {
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
  .editprofile-common-form {
    form {
      > div {
        margin-bottom: 15px;
      }
      .collage-insight-help { 
        display: block;
        .help-message-box {
          width: 100%;
          margin-bottom: inherit;
        }
      }
      .fieldBox.flexBox {
        display: block;
  
        .half {
          width: 100%;
          margin-bottom: 15px;
  
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .fieldBox {
        label {
          @include font(13);
        }
        textarea {
          line-height: 1.5;
          min-height: 100px;
        }
        .flex-box-second {
          display: block;
        }
        .subtext-box {
          @include font(12);
        }
      }
      .fieldBox p.checkbox-list {
        &.mt35 {
          margin-top: 0;
        }
        span label {
          margin-bottom: 5px;
        }
      }
      .form-info-box .title,
      .address-box-wrap .title {
        @include font(15);
      }
      .address-box-wrap .title.flex-box {
        display: block;
        .half {
          &:last-child {
            margin-top: 12px;
            display: flex;
          }
          .btn {
            width: 48%;
            min-width: 1px;
            margin-right: 4%;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .btn {
        padding: 10px 15px;
      }
      .flex-three-col-row {
        padding-bottom: 20px;
        margin-bottom: 20px;
        .flex-three-column {
          display: block;
          .item {
            width: 100%;
            &:last-child {
              width: 100%;
            }
          }
        }
      }
      .notification-setting-box {
        .notification-content {
          .flexbox {
            margin-bottom: 8px;
            .item {
              width: 15%;
              &:first-child {
                width: 59%;
              }
              &:last-child {
                width: 26%;
              }
            }
          }
        }
      }
    }
  }  
  .video-and-photo-wrap {
    margin-top: -15px;
    .video-share-box {
      margin-bottom: 15px;
    }
    .video-and-photo-main {
      .sidebarbox {
        .menu-links {
          li {
            a {
              @include font(13);
            }
          }
        }
        .category-list  {
          .item {
            .add-toggle-button {
              @include font(13);
            }
          }
        }
      }
      .video-top-block {
        .link-box {
          min-height: 1px;
        }
      }
    }
    .video-second-block {
      .video-tab-contents {
        .video-grid-list {
          display: block;
          flex-wrap: nowrap;
          > div {
            display: block;
            flex-wrap: nowrap;
            width: 100%;
          }
          .video-box {
            float: none;
            width: 100%;
            margin-right: 0;
            .video-detail-box {
              min-height: 1px!important;
            }
          }
        }
      }
      .tabs {
        li {
          @include font(13);
          margin-right: 10px;
        }
      }
      .video-grid-control {
        margin-top: -10px;
        li {
          margin-right: 8px;

          a {
            @include font(12);
          }
        }
      }
    }
  }
  .common-modal {
    .modal-dialog {
      margin-left: 10px;
      margin-right: 10px;
      max-width: 100%;
    }
    .modal-header {
      padding: 15px 15px 0;
    }
    .modal-title {
      @include font(15);
      text-align: center;
    }
    .modal-body {
      padding: 15px 15px 20px;
    }
    .modal-footer {
      padding: 0 15px 25px;
    }
    &.coache-modal {
      .short-content-box {
        p {
          br {
            display: none;
          }
        }
      }
    }
    &.form-modal {
      .modal-header {
        padding: 10px 15px;
      }
      .modal-body {
        padding: 15px 15px 20px;
      }
      .modal-footer {
        padding: 0 15px 20px;
        &.threebtn {
          flex-wrap: wrap;
          .btn {
            &:first-child {
              order:2;
            }
            &:nth-child(2) {
              order:1;
              width: auto;
              margin-right: 0;
              margin-bottom: 15px;
            }
            &:last-child {
              order:3;
            }
          }
        }
      }
    }
  }
  .video-post-modal {
    .modal-body {
      padding: 20px 15px 25px;
    }
    &.video-main {
      .modal-body {
        iframe {
          height: 175px !important;
        }
      }
    }
  }
  .modal {
    &.common-slide-modal {
      .modal-dialog {
        margin: 15px 30px;
      }
      .owl-nav {
        button {
          &.owl-prev {
            left: -15px;
          }
          &.owl-next {
            right: -15px;
          }
        }
      }
      .video-detail-box {
        display: block;
        .detail-box {
          width: 100%;
          margin-top: 15px;
        }
        .img-box {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .professional-video-wrap {
    .tabs li {
      @include font(15);
    }
    .professional-video-info {
      h3 {
        @include font(16);
        .number-of-edit {
          @include font(15);
        }
      }
      .short-text {
        @include font(13);
      }
      .professional-video-profile {
        .professional-video-profile-list {
          li {
            .img-box {
              width: 60px;
              height: 60px;
            }
            .content-box {
              @include font(13);
              width: calc(100% - 80px);
              margin-bottom: 10px;
            }
          }
        }
        .button-box {
          margin-left: 80px;
          .btn {
            @include font(14);
            padding: 8px 15px;
          }
        }
      }
      .professional-list {
        li {
          a {
            @include font(14);
            min-height: 130px;
            line-height: 1.3;
          }
        }
      }
    }
    .professional-video-edit-form {
      .item-row {
        h3 {
          @include font(16);
        }
      }
      .fieldBox {
        .three-col {
          width: 100%;
          margin-bottom: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .checkbox-block-item {
        p {
          margin-bottom: 15px;
        }
      }
    }
    .add-special-instructions-sec {
      ul {
        li {
          padding-right: 25px;
          .fieldBox {
            .three-col {
              margin-bottom: 0;
            }
            &.flexBox {
              .four-col {
                input {
                  padding-left: 5px;
                  padding-right: 5px;
                  @include font(11);
                }
              }
            } 
          }
          .button-box {
            right: 0;
          }
        }
      }
    }
  }
  .hide-on-mobile {
    display: none;
  }
  .flex-inline-items {
    li {
      &.flexbox {
        label {
          &:first-child {
            @include font(11);
          }
        }
      }
    }
  }
  .check-list-sec {
    &.statRepotData {
      .fieldBox {
        &.flexBox {
          &.three-col-row {
            display: block;
            margin-left: 0;
            margin-right: 0;
            .three-col {
              width: 100%;
              padding-left: 0;
              padding-right: 0;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  .academic-detail-listing {
    .item-row {
      @include font(13);
      .item {
        &.button-wrapper {
          a {
            width: 35px;
            padding: 8px 5px;
          }
        }
      }
    }
  }
  .college-detail-banner {
    padding: 25px 0;
    .banner-detail-box {
      .banner-detail-text {
        .college-banner-logo {
          width: 80px;
          height: 80px;
          padding: 20px;
          margin-right: 15px;
        }
        .college-banner-content {
          width: calc(100% - 90px);
          .title {
            @include font(16);
          }
          .address-info {
            li {
              margin-bottom: 12px;
              a {
                @include font(13);
              }
            }
          }
        }
      }
      .banner-wishlist-box {
        width: 40px;
      }
    }
  }
  .college-main-section {
    .college-tabs-wrapper {
      .nav-tabs {
        overflow: hidden;
        li {
          float: left;
          width: 32%;
          margin-right: 2%;
          &:nth-child(3n + 3) {
            margin-right: 0;
          }
          &:nth-child(3n + 4) {
            clear: both;
          }
          a {
            @include font(14);
          }
        }
      }
      .tab-content {
        padding: 10px 10px 20px;
        .tab-pane {
          overflow: hidden;
        }
        .college-tabs-content {
          .tab-content {
            .college-info-list {
              li {
                a {
                  @include font(13);
                }
              }
            }
          }
        }
        .stepperBox {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          overflow-y: scroll;
          padding-bottom: 10px;
          &::-webkit-scrollbar {
            width: 8px;
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba($gray, 0.8%);
          }
          .item {
            &.current {
              span {
                &.circle::after,
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .event-main-wrapper {
    margin-top: -15px;
    .event-top-block {
      .short-text {
        @include font(14);
      }
    }
    .event-data-box {
      .events-list {
        > li {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .event-top-block {
      .short-text {
        .btn {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
  .videoOverlayWrap {
    .MuiDialog-paperScrollPaper {
      iframe {
        width: 300px!important;
      }
    }
  }
  .addEditOverlay {
    .modal-content {
      .modal-body {
        .uploadFeature {
          &.uploadList {
            h5 {
              float: none;
              padding-top: 8px;
              margin-top: 0;
              clear: both;
            }
          }
        }
      }
    }
  }
  .swal-title {
    @include font(20);
    line-break: anywhere;
  }
  .TimelineData {
    .video-second-block {
      .video-tab-contents {
        .video-grid-list {
          .video-box {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {  
  .editprofile-common-form {
    form {
      .notification-setting-box {
        .notification-content {
          .flexbox {
            .item {
              width: 20%;
              &:first-child {
                width: 54%;
              }
              &:last-child {
                width: 26%;
              }
            }
          }
        }
      }
    }
  }
  .video-and-photo-wrap {
    .video-second-block {
      .top-block {
        display: block;
        .tabs {
          li {
            @include font(14);
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .video-grid-control {
          display: none;
          margin-top: 15px;
          justify-content: flex-end;
        }
      }
      .video-tab-contents {
        .video-grid-list {
          &.list {
            .video-box {
              display: block;
              .img-box {
                width: 100%;
                height: 200px;
              }
              .video-detail-box {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .video-post-modal {
    .post-video-box {
      .img-box {
        width: 120px;
      }
      .content-box {
        width: calc(100% - 135px);
      }
    }
  }
  .college-detail-banner {
    .banner-detail-box {
      .banner-detail-text {
        .college-banner-logo {
          width: 60px;
          height: 60px;
          padding: 15px;
          margin-right: 10px;
        }
        .college-banner-content {
          width: calc(100% - 70px);
          .title {
            @include font(16);
            margin-bottom: 10px;
          }
          .address-info {
            li {
              a {
                @include font(12);
              }
            }
          }
        }
      }
      .banner-wishlist-box {
        width: 30px;
      }
    }
  }
  .event-main-wrapper {
    .event-data-box {
      .events-list {
        > li {
          display: block;
          padding-left: 15px;
          padding-right: 15px;
          .logo-box {
            margin: 0 auto 10px;
          }
        }
      }
    }
    .reference-data-box {
      .reference-list {
        > li {
          .title-box {
            @include font(13);
          }
        }
      }
    }
  }
  .common-modal {
    &.trash-modal {
      .modal-body {
        .content-box {
          h4 {
            br {
              display: none;
            }
          }
        }
      }
    }
  }
}