/* Mixin */
:-webkit-input-placeholder {
    color: #ccc;
  }
  
  ::-webkit-input-placeholder {
    color: #ccc;
  }
  
  :-ms-input-placeholder {
    color: #ccc;
  }
  
  ::-ms-input-placeholder {
    color: #ccc;
  }
  
  ::placeholder {
    color: #ccc;
  }
  
  :-moz-placeholder {
    color: #ccc;
  }
  
  :-moz-placeholder {
    color: #ccc;
  }
  
  :-ms-input-placeholder {
    color: #ccc;
  }
  
  .flex {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  @-webkit-keyframes hoverEffect {
    0% {
      transform: scaleX(0);
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
    }
    50% {
      transform: scaleX(1);
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes hoverEffect {
    0% {
      transform: scaleX(0);
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
    }
    50% {
      transform: scaleX(1);
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
    }
    100% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes animArrow {
    0% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
      -webkit-transform: translateX(5px);
      -moz-transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
  }
  
  @keyframes animArrow {
    0% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
      -webkit-transform: translateX(5px);
      -moz-transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
  }
  
  @-webkit-keyframes animArrowRev {
    0% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
    50% {
      transform: translateX(-5px);
      -webkit-transform: translateX(-5px);
      -moz-transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
  }
  
  @keyframes animArrowRev {
    0% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
    50% {
      transform: translateX(-5px);
      -webkit-transform: translateX(-5px);
      -moz-transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
    }
  }
  
  /* http://meyerweb.com/eric/tools/css/reset/ 
     v2.0 | 20110126
     License: none (public domain)
  */
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  
  *:focus, *:hover {
    outline: none;
  }
  
  a {
    text-decoration: none;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
  
  body {
    line-height: 1;
  }
  
  ol, ul, li {
    list-style: none;
  }
  
  blockquote, q {
    quotes: none;
  }
  
  blockquote:before, blockquote:after {
    content: '';
    content: none;
  }
  
  q:before, q:after {
    content: '';
    content: none;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  .preference-data-sec {
    margin-top: 60px;
    background: #F5F5F5;
    padding: 25px 0px;
  }
  
  .preference-data-sec .headerFlex {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0px auto 20px;
  }
  
  .preference-data-sec .headerFlex .tabs {
    width: calc(100% - 176px);
  }
  
  .preference-data-sec .headerFlex .tabs ul {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0px 0px;
    padding: 0px 15px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    background: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
  }
  
  .preference-data-sec .headerFlex .tabs ul li {
    list-style: none;
    margin-right: 20px;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a {
    text-decoration: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    font-size: 18px;
    color: #3E3E3E;
    height: 100%;
    padding: 10px 10px;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a span.icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: absolute;
    right: -5px;
    top: 5px;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a span.icon svg {
    fill: #3E3E3E;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a span.icon svg .a {
    fill: #3E3E3E;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a:hover {
    color: #C0321F;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a:hover::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background: #C0321F;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a.selected {
    color: #C0321F;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a.selected::after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background: #C0321F;
  }
  
  .preference-data-sec .headerFlex .tabs ul li a.selected span svg path {
    fill: #C0321F !important;
  }
  
  .preference-data-sec .headerFlex .tabs ul li:last-child {
    margin-right: 0px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox {
    display: none;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #00000038;
    padding: 0px 10px 0px 0px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.menuBtn .btn {
    border: none;
    outline: none;
    padding: 5px 5px;
    min-height: 20px;
    min-width: 20px;
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: transparent;
    margin: 10px 15px 10px 0px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.menuBtn .btn svg .a {
    fill: #343434;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.label span {
    color: #C0321F;
    display: inline-block;
    font-size: 14px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 0;
    height: 100%;
    left: -300px;
    width: 100%;
    max-width: 250px;
    overflow-y: auto;
    background: #ffffff;
    z-index: 999;
    border-right: 1px solid #ccc;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu .closeBtnBox {
    text-align: right;
    padding: 15px 15px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu .closeBtnBox button.closebtn {
    background: transparent;
    border: none;
    outline: none;
    padding: 5px 5px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu .closeBtnBox button.closebtn svg .a {
    opacity: 0.7;
    fill: #3E3E3E;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul {
    display: block;
    padding: 0px 15px;
    border: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    height: auto;
    max-height: calc(100% - 60px);
    overflow-y: auto;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li {
    list-style: none;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
    margin-right: 0px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a {
    text-decoration: none;
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    border: none;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a span {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    line-height: 22px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a span.icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-top: -10px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a.selected {
    color: #C0321F;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a::after {
    display: none;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li a:hover {
    color: #C0321F;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu ul li:last-child {
    margin-bottom: 0px;
    border-bottom: none;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu::after {
    content: "";
    display: inline-block;
    position: fixed;
    width: 0px;
    left: 0px;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu.expandMenu {
    left: 0px;
  }
  
  .preference-data-sec .headerFlex .tabs .mobileMenuBox div.mobileMenu.expandMenu::after {
    content: "";
    display: inline-block;
    position: fixed;
    width: calc(100% - 250px);
    left: 250px;
    width: 100%;
    height:100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .preference-data-sec .headerFlex .prefer a {
    text-decoration: none;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 18px;
    color: #3E3E3E;
    padding: 15px 15px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    margin-left: 10px;
  }
  
  .preference-data-sec .headerFlex .prefer a.selected {
    color: #C0321F;
  }
  
  .preference-data-sec .headerFlex .prefer a.selected span.icon svg {
    fill: #C0321F;
  }
  
  .preference-data-sec .headerFlex .prefer a.selected span.icon svg .a {
    fill: #C0321F;
  }
  
  .preference-data-sec .headerFlex .prefer a span {
    display: inline-block;
  }
  
  .preference-data-sec .headerFlex .prefer a span.icon {
    margin-right: 10px;
  }
  
  .preference-data-sec .headerFlex .prefer a span.icon svg {
    fill: #3E3E3E;
  }
  
  .preference-data-sec .headerFlex .prefer a span.icon svg .a {
    fill: #3E3E3E;
  }
  
  .preference-data-sec .preference-box-wrap {
    background: #ffffff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
  }
  
  .preference-data-sec .preference-box-wrap .top-block-wrap {
    font-size: 0.9375rem;
    font-weight: 300;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    position: relative;
    padding-right: 385px;
  }
  
  .preference-data-sec .preference-box-wrap .top-block-wrap .title {
    font-size: 1.125rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
  }
  
  .preference-data-sec .preference-box-wrap .top-block-wrap .title + p {
    line-height: 1.6;
  }
  
  .preference-data-sec .preference-box-wrap .top-block-wrap .btn-wrap {
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .preference-data-sec .preference-box-wrap .top-block-wrap .btn-wrap .btn {
    padding: 6px 15px;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0.27px;
    line-height: 26px;
    min-width: 70px;
  }
  
  .preference-data-sec .preference-box-wrap .top-block-wrap .btn-wrap .btn.blue {
    background: #00B9FF;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item {
    padding-bottom: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item:last-child {
    padding-bottom: 5px;
    margin-bottom: 0;
    border-bottom: none;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item h4 {
    font-size: 1.125rem;
    font-weight: 500;
    color: #3E3E3E;
    margin-bottom: 20px;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item h5 {
    font-size: 1rem;
    font-weight: 500;
    color: #3E3E3E;
    margin-bottom: 20px;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox {
    margin-bottom: 20px;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox:last-child {
    margin-bottom: 0;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .search-box-wrap {
    max-width: 325px;
    position: relative;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .search-box-wrap input {
    font-size: 0.875rem;
    font-weight: 300;
    color: #3E3E3E;
    width: 100%;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    padding: 0 1em;
    height: 50px;
    -webkit-box-shadow: none;
            box-shadow: none;
    padding-right: 40px;
    background: url(../img/search-icon.svg) no-repeat;
    background-position: bottom 17px right 12px;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .search-box-wrap input::-webkit-input-placeholder {
    color: #3E3E3E;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .search-box-wrap input:-ms-input-placeholder {
    color: #3E3E3E;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .search-box-wrap input::-ms-input-placeholder {
    color: #3E3E3E;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .search-box-wrap input::placeholder {
    color: #3E3E3E;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .checkbox-group li {
    margin-bottom: 18px;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .checkbox-group li:last-child {
    margin-bottom: 0;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .checkbox-group .checkbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .checkbox-group .checkbox input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    cursor: pointer;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .checkbox-group .checkbox input[type="checkbox"]:checked + label::before {
    border-color: #00B9FF;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .checkbox-group .checkbox input[type="checkbox"]:checked + label::after {
    content: "";
    display: inline-block;
    background: url(../img/checkBlue.png) no-repeat;
    background-size: cover;
    position: absolute;
    width: 12px;
    height: 12px;
    left: 3px;
    top: 5px;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .checkbox-group .checkbox label {
    padding-left: 30px;
    position: relative;
    font-size: 0.9375rem;
    font-weight: 300;
    color: #3E3E3E;
    line-height: 18px;
    cursor: pointer;
    margin-bottom: 0;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .field-box-item .fieldBox .checkbox-group .checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    top: 0;
    border: 1px solid #3E3E3E;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .btn-wrap .btn {
    padding: 6px 15px;
    font-size: 0.938rem;
    font-weight: 300;
    letter-spacing: 0.27px;
    line-height: 26px;
    min-width: 70px;
  }
  
  .preference-data-sec .preference-box-wrap .preference-list .btn-wrap .btn.blue {
    background: #00B9FF;
  }
  
  @media screen and (max-width: 991px) {
    .preference-data-sec .preference-box-wrap .top-block-wrap {
      padding-right: 0;
    }
    .preference-data-sec .preference-box-wrap .top-block-wrap .title + p br {
      display: none;
    }
  }
  
  @media screen and (max-width: 767px) {
    .preference-data-sec .headerFlex .tabs ul {
      display: none;
    }
    .preference-data-sec .headerFlex .tabs .mobileMenuBox {
      display: -webkit-box;
      display: -ms-box;
      display: -ms-flexbox;
      display: flex;
    }
    .preference-data-sec .headerFlex .prefer a {
      padding: 12px 10px;
    }
    .preference-data-sec .preference-box-wrap {
      padding: 20px 15px 15px;
    }
  }
  
  @media screen and (max-width: 579px) {
    .preference-data-sec .headerFlex .tabs {
      width: calc(100% - 55px);
    }
    .preference-data-sec .headerFlex .tabs .mobileMenuBox div.menuBtn .btn {
      margin: 9px 15px 9px 10px;
    }
    .preference-data-sec .headerFlex .prefer a {
      padding: 10px 10px;
      min-width: 42px;
      width: 42px;
      height: 42px;
      min-height: 42px;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    .preference-data-sec .headerFlex .prefer a span {
      display: none;
    }
    .preference-data-sec .headerFlex .prefer a span.icon {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      margin-right: 0px;
    }
    .preference-data-sec .preference-box-wrap .top-block-wrap {
      padding-right: 0;
    }
    .preference-data-sec .preference-box-wrap .top-block-wrap .btn-wrap {
      padding-top: 15px;
      position: static;
      top: auto;
      right: auto;
      z-index: 1;
    }
    .preference-data-sec .preference-box-wrap .top-block-wrap .btn-wrap .btn {
      font-size: 12px!important;
      padding: 6px 10px;
      min-width: 50px;
    }
    .preference-data-sec .preference-box-wrap .preference-list .field-box-item h4 {
      font-size: 1rem;
    }
    .preference-data-sec .preference-box-wrap .preference-list .field-box-item h5 {
      font-size: 0.9375rem;
    }
  }
  /*# sourceMappingURL=college-preference.css.map */