@import "../../../../../assets/css/variables";
@import "../../../../../assets/css/mixin";
@import "../../../../../assets/css/reset";

.evaluation-greenrope-sec {
    background: $white;
    padding: 60px 0;
    margin-top: 60px;
    .top-block {
        text-align: center;
        .title {
            @include font(42);
            font-weight: 700;
            color:$blackColor;
            line-height: 60px;
            text-transform: capitalize;
            margin-bottom: 20px;
            span {
                display: block;
                font-weight: 400;
                color: rgba($blackColor, .3);
            }
        }
        .short-text {
            @include font(18);
            font-weight:400;
            color:$black; 
            line-height: 35px;
            max-width: 1000px;
            margin: 0 auto;
            p {
                margin-bottom:15px;
                &:last-child {
                    margin-bottom:0;
                }
            }
        }
    }
}
.sfwebfrom-brand-lp-sec {
    background:#f9f9f9;
    padding: 40px 0;
    .brand-listing {
        @include flex();
        align-items: center;
        justify-content: center;
        .item {
            padding: 10px 20px;
            img {
                display: inline-block;
                vertical-align: middle;
                max-width: 100%;
            }
        }
    }
}
.sfwebfrom-interview-survey-sec {
    background: $white;
    padding: 60px 0;
    .top-block {
        margin-bottom: 40px;
        text-align: center;
        .sec-title {
            @include font(36);
            font-weight: 700;
            color: $blackColor;
            line-height: 1.333;
        }
    }
    .survey-form-box {
        body {
            overflow: hidden;
        }
        #signupForm {
            background:#F9F9F9 !important;
            padding:20px 40px !important;
            border-radius: 12px !important;
            max-width: 640px;
            max-height: 780px !important;
            width: 100% !important;
            border: none !important;
            margin: 0 auto 20px !important;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                border-radius: 10px;
                width: 10px;
                height:12px;
                background: rgba($gray, 0.3%);
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                height: 6px;
                background: rgba($gray, 0.8%);
                cursor: pointer;
            }
        }
        #mainForm {
            background:none !important;
            padding:0 !important;
            border-radius: 0 !important;
            max-width: 640px;
            width: 100% !important;
            height: 100% !important;
            border: none !important;
            margin: 0 auto !important;
            overflow: hidden !important;
            li {
                padding: 0 !important;
                margin: 0 0 20px !important;
                label {
                    display: block;
                    @include font(15);
                    font-weight: 400;
                    color: $black;
                    line-height: 1.4;
                    margin-bottom: 10px;
                    sup, .reqMarker {
                        display: inline-block;
                        vertical-align: top;
                        font-weight: 600;
                        color: $baseColor;
                        margin-left: 3px;
                    }
                }
                input {
                    padding: 10px 10px;
                    width: 100%;
                    @include font(15);
                    color: $black;
                    border: 1px solid rgba($black, .2);
                    outline: none;
                    box-shadow: none;
                    @include radius(4px);
                    &::placeholder {
                        color: rgba($black, .3);
                    }
                }
                select {
                    padding: 13px 45px 13px 10px;
                    background-image: url(../img/arrowdown.png);
                    background-repeat: no-repeat;
                    background-position: right 15px center;
                    background-size: 16px;
                    @include font(15);
                    color: $black;
                    width: 100%;
                    border: 1px solid rgba($black, .2);
                    -webkit-appearance: none;
                    appearance: none;
                    @include radius(4px);
                }
                textarea {
                    padding: 10px 10px;
                    width: 100%;
                    min-height: 90px;
                    @include font(15);
                    color: $black;
                    line-height: 1.5;
                    border: 1px solid rgba($black, .2);
                    outline: none;
                    box-shadow: none;
                    @include radius(4px);
                    &::placeholder {
                        color: rgba($black, .3);
                    }
                }
                #formSubmit {
                    background: $baseColor !important;
                    padding: 15px 15px !important;
                    font-size: 18px !important;
                    font-weight: 400 !important;
                    color:$white !important;
                    border: none;
                    height: auto;
                    @include radius(4px);
                    box-shadow: 0px 5px 15px #00000026;
                    margin-top: 10px;
                    cursor: pointer;
                }
            }
        }
    }
    .btn {
        padding: 10px 15px;
        min-width: 220px;
        @include font(18);
        font-weight: 400;
        @include radius(4px);
        box-shadow: 0px 5px 15px #00000026;
        margin-top: 25px;
        cursor: pointer;
    }
}
.sfwebfrom-testimonial-sec-athlete {
    background: url(../img/testimonial-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 60px 0;
    text-align: center;
    .top-block {
        max-width: 1080px;
        @include font(16);
        font-weight: 300;
        color: $white;
        line-height: 1.5;
        margin: 0 auto 30px;
        .title {
            @include font(40);
            font-weight: 600;
            color: $white;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 0;
        }
    }
    .slide-item {
        padding: 20px 25px;
        background: $white;
        box-shadow: 0 0 4px #00000029;
        @include radius(5px);
        .testimonial-img {
            @include flex();
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            border: 1px solid #F2F2F2;
            @include radius(50%);
            margin: 0 auto 10px;
            img {
                display: block;
                width: 100px;
                height: 100px;
                object-fit: cover;
                @include radius(50%);
            }
        }
        .testimonial-detail {
            @include font(14);
            font-weight: 400;
            color: $black;
            letter-spacing: 0.21px;
            line-height: 1.5;
            .short-text {
                position: relative;
                margin-bottom: 20px;
                min-height:90px;
                &:after {
                    content:"";
                    background: url(../img/quote-iocn-left.png) no-repeat;
                    position: absolute;
                    width: 90px;
                    height: 65px;
                    left: 0;
                    top: -15px;
                    z-index: 1;
                }
                &:before {
                    content:"";
                    background: url(../img/quote-iocn-right.png) no-repeat;
                    position: absolute;
                    width: 31px;
                    height: 21px;
                    right: 0;
                    bottom: -10px;
                    z-index: 1;
                }
                p {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .name {
                @include font(15);
                font-weight: 500;
                letter-spacing: 0.23px;
                margin-bottom: 5px;
            }
            .designation {
                @include font(15);
                font-weight: 500;
                color: $baseColor;
                letter-spacing: 0.18px;
            }
        }
    }
    .testimonial-slider {
        .owl-dots {
            display: block;
            text-align: center;
            margin-top: 20px;
            button {
                display: inline-block;
                vertical-align: middle;
                border: none;
                outline: none;
                span {
                    display: block;
                    background: $white;
                    width: 12px;
                    height: 12px;
                    margin: 3px 5px;
                    border: 1px solid $white;
                    border-radius: 50%;
                    opacity: 0.16;
                    transition: opacity .2s ease;
                }
                &.active {
                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.sfwebfrom-about-coaches-sec {
    background: #f9f9f9;
    padding: 65px 0;
    .sec-title {
        @include font(36);
        font-weight: 600;
        color:$black;
        line-height:1.333;
        margin-bottom: 45px;
        text-align: center;
    }
    .coaches-list {
        .item {
            background:$white;
            padding:25px 15px;
            border: 1px solid #DCDCDC;
            box-shadow: 0px 8px 15px #E2E2E2;
            @include radius(15px);
            text-align: center;
            .icon-box {
                width: 100px;
                margin: 0 auto 15px;
                img {
                    display: block;
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border: 4px solid $white;
                    box-shadow: 0px 0px 15px #00000029;
                    @include radius(100px);
                }
            }
            .short-text {
                @include font(16);
                font-weight: 400;
                color:$black;
                line-height: 35px;
                margin-bottom: 15px;
                position: relative;
                min-height: 210px;
                &::before {
                    content:'';
                    background: url(../img/quote-gray-icon.svg) no-repeat;
                    background-position: center left;
                    position: absolute;
                    width: 90px;
                    height: 68px;
                    left: 0;
                    top: 0;
                    z-index:1;
                }
                &::after {
                    content:'';
                    background: url(../img/quote-gray-small-icon.svg) no-repeat;
                    background-position: center left;
                    position: absolute;
                    width: 30px;
                    height: 22px;
                    right: 0;
                    bottom: 8px;
                    z-index:1;
                }
                p {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            h4 {
                @include font(18);
                font-weight: 500;
                color: $baseColor;
                margin-bottom: 10px;
            }
            h5 {
                @include font(16);
                font-weight: 500;
                color: rgba($blackColor, .5);
                margin-bottom: 0;
            }
        }
    }
    .owl-nav {
        display: block!important;
        margin:40px auto 0;
        text-align: center;
        button {
          background: $white;
          width: 44px;
          height: 44px;
          outline: none;
          margin: 0 10px;
          @include radius(50px);
          box-shadow: 0px 0px 6px #CBCBCB;
          cursor: pointer!important;
          &.owl-next {
            span {
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              right: 2px;
              left: auto;
            }
          }
          span {
            content: "";
            display: inline-block;
            position: relative;
            width: 10px;
            height: 10px;
            font-size: 0;
            border-right: 2px solid #BBBBBB;
            border-bottom: 2px solid #BBBBBB;
            transform: rotate(-0deg);
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            left: 2px;
            top: -6px;
            @include transition();
          }
        }
    }
}

@media screen and (max-width: 1439px) {
    .evaluation-greenrope-sec {
        .top-block {
            .title {
                @include font(32);
                line-height: 50px;
            }
        }
    }
    .sfwebfrom-interview-survey-sec {
        .top-block {
            .sec-title {
                @include font(32);
            }
        }
    }
    .sfwebfrom-testimonial-sec-athlete {
        .top-block {
            .title {
                @include font(32);
            }
        }
    }
    .sfwebfrom-about-coaches-sec {
        .sec-title {
            @include font(32);
        }
    }
}
@media screen and (max-width: 1199px) {
    .evaluation-greenrope-sec {
        .top-block {
            .title {
                @include font(32);
                line-height: 50px;
            }
        }
    }
    .sfwebfrom-testimonial-sec-athlete {
        .top-block {
            .title {
                @include font(32);
            }
        }
    }
    .sfwebfrom-about-coaches-sec {
        .coaches-list {
            .item {
                h4 {
                    @include font(15);
                }
                .short-text {
                    line-height: 30px;
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .evaluation-greenrope-sec {
        padding: 45px 0;
        .top-block {
            .title {
                @include font(26);
                line-height: 40px;
            }
            .short-text {
                @include font(16);
                line-height: 28px;
            }
        }
    }
    .sfwebfrom-interview-survey-sec {
        padding: 45px 0;
        .top-block {
            .sec-title {
                @include font(26);
            }
        }
    }
    .sfwebfrom-testimonial-sec-athlete {
        padding: 35px 30px;
        .top-block {
            .title {
                @include font(25);
            }
        }
    }
    .sfwebfrom-about-coaches-sec {
        padding: 50px 0;
        .sec-title {
            @include font(26);
            margin-bottom: 35px;
        }
        .coaches-list {
            .item {
                .short-text {
                    min-height: 180px;
                }
            }
        }
    }
}
@media screen and (max-width:767px) {
    .evaluation-greenrope-sec {
        padding: 35px 0;
        .top-block {
            .title {
                @include font(22);
                line-height: 32px;
            }
            .short-text {
                @include font(15);
                line-height: 26px;
            }
        }
    }
    .sfwebfrom-interview-survey-sec {
        padding: 35px 0;
        .top-block {
            margin-bottom: 30px;
            .sec-title {
                @include font(22);
                br {
                    display: none;
                }
            }
        }
    }
    .sfwebfrom-testimonial-sec-athlete {
        .top-block {
            .title {
                @include font(22);
            }
        }
    }
    .sfwebfrom-about-coaches-sec {
        padding: 40px 0;
        .coaches-list {
            .item {
                .short-text {
                    min-height: 1px;
                }
            }
        }
    }
}
@media screen and (max-width:639px) {
    .evaluation-greenrope-sec {
        padding: 30px 0;
        .top-block {
            .title {
                @include font(22);
                line-height: 30px;
                margin-bottom: 15px;
            }
        }
    }
    .sfwebfrom-brand-lp-sec {
        padding: 30px 0;
        .brand-listing {
            .item {
                padding: 0 10px;
            }
        }
    }
    .sfwebfrom-interview-survey-sec {
        .top-block {
            .sec-title {
                @include font(20);
            }
        }
    }
    .sfwebfrom-testimonial-sec-athlete {
        padding: 40px 15px;
        margin-bottom: 0;
        .top-block {
            margin-bottom: 20px;
            .title {
                @include font(20);
                line-height: 1.6;
                margin-bottom: 10px;
            }
            p {
                @include font(15);
            }
        }
    }
    .sfwebfrom-about-coaches-sec {
        padding: 35px 0;
        .sec-title {
            @include font(22);
            margin-bottom: 30px;
        }
        .coaches-list {
            .item {
                .short-text {
                    min-height:1px;
                }
            }
        }
        .owl-nav {
            margin-top: 25px;
        }
    }
}