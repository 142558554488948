/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.onBoardTwitterSection {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 50px 15px;
  min-height: calc(100vh - 120px);
  margin-top: 60px;
}

.onBoardTwitterSection .twitterBlock {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.onBoardTwitterSection .twitterBlock .formSection {
  padding: 30px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.onBoardTwitterSection .twitterBlock .formSection .heading {
  font-size: 1rem;
  font-weight: 600;
  color: #3E3E3E;
  line-height: 22px;
  margin: 0 0 20px 0;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox {
  margin-bottom: 20px;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox label {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: #3E3E3E;
  margin: 0 0 10px 0;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox input {
  padding: 10px 10px;
  width: 100%;
  font-size: 0.875rem;
  color: #3E3E3E;
  outline: none;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox input::-webkit-input-placeholder {
  color: #3E3E3E;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox input:-ms-input-placeholder {
  color: #3E3E3E;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox input::-ms-input-placeholder {
  color: #3E3E3E;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox input::placeholder {
  color: #3E3E3E;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox.hide {
  display: none;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox .box-with-icon {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #ccc;
  height: 43px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox .box-with-icon input {
  border: none;
  border-radius: none;
  padding-top: 0;
  padding-bottom: 0;
}

.onBoardTwitterSection .twitterBlock .formSection .fieldBox .box-with-icon span {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 10px;
  height: 43px;
  font-size: 1rem;
  font-weight: 600;
  color: #3E3E3E;
  text-align: center;
  border-right: 1px solid #ccc;
}

.onBoardTwitterSection .twitterBlock .formSection .skip {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3E3E3E;
  text-align: center;
}

.onBoardTwitterSection .twitterBlock .formSection .skip a {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #C0321F;
  cursor: pointer;
}

.onBoardTwitterSection .twitterBlock .formSection .skip a:hover {
  text-decoration: underline;
}

.onBoardTwitterSection .twitterBlock .continueBtnBox {
  text-align: center;
  margin-top: 40px;
}

.onBoardTwitterSection .twitterBlock .continueBtnBox a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
  background-color: #1CB79E;
  padding: 10px 15px;
  min-width: 100px;
  min-height: 45px;
  font-size: 1rem;
  color: #ffffff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
}

.modal {
  padding-right: 0 !important;
}

.modal.skip-popup .modal-dialog {
  max-width: 580px;
}

.modal.skip-popup .modal-content {
  border: none;
}

.modal.skip-popup .modal-body {
  padding: 50px 45px;
  text-align: center;
  position: relative;
}

.modal.skip-popup .modal-body button.close {
  outline: none;
  position: absolute;
  top: 15px;
  right: 20px;
  opacity: 1;
}

.modal.skip-popup .modal-body .modal-title {
  font-size: 1.875rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}

.modal.skip-popup .modal-body .modal-title + p {
  margin-bottom: 25px;
}

.modal.skip-popup .modal-body p {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3E3E3E;
  margin-bottom: 15px;
}

.modal.skip-popup .modal-body .btn {
  padding: 12px 25px;
  font-size: 0.875rem;
  font-weight: 400;
}

.modal.skip-popup .modal-body .skip {
  margin-top: 15px;
  margin-bottom: 0;
}

.modal.skip-popup .modal-body .skip a {
  display: inline-block;
  vertical-align: top;
  text-decoration: underline;
  font-size: 0.875rem;
  color: #C0321F;
  cursor: pointer;
}

.modal.skip-popup .modal-body .skip a:hover {
  text-decoration: none;
}

@media screen and (max-width: 567px) {
  .modal.skip-popup .modal-body {
    padding: 45px 25px 35px;
  }
  .modal.skip-popup .modal-body .modal-title {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 479px) {
  .onBoardTwitterSection {
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 1px;
  }
  .onBoardTwitterSection .twitterBlock .formSection {
    padding: 20px;
  }
  .onBoardTwitterSection .twitterBlock .continueBtnBox a {
    width: 100%;
    margin-top: 20px;
  }
}
