@import  "../../assets/css/mixin";
@import "../../assets/css/variables";
@import "../../assets/css/reset";
div{
    .css-1uccc91-singleValue {
        @include flex();
        align-items: center;
        @include font(14);
    }
    &.countryPhoneFlexBox{
        @include radius(4px);
        border: 1px solid $gray;
        @include flex();
        align-items: center;
        text-align: left;
        >div{
            &.countrySelect {
                width: 130px;
                .select__control {
                    border: none;
                }
                .select__single-value {
                    img {
                        width: 30px !important;
                        height: 20px;
                        object-fit: cover;
                        margin-right: 10px !important;
                    }
                }
                .select__indicator{
                    padding: 5px 5px;
                }
                .select__menu{
                    font-size:14px;
                    width:  calc(100% + 200px) !important;
                    max-width: calc(100% + 200px) !important;
                    span {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .select__option {
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        img {
                            display: block;
                            width:30px;
                            height:30px;
                            object-fit: cover;
                            margin-right:5px;
                        }
                        span {
                            width: calc(100% - 35px);
                            line-height: 1.2;
                        }
                    }
                }
            }
            &.textBox {
                width: calc(100% - 130px);
                .MuiFormControl-root {
                    input {
                        border: none;
                    }
                    fieldset {
                        display: none;
                    }
                }  
            }
        } 
    }
}
.editprofile-common-form {
    form {
        .address-box-wrap {
            .MuiInputBase-formControl {
                .MuiOutlinedInput-input {
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
            }
        }
    }
}
.modal {
    div {
        &.countryPhoneFlexBox {
            > div {
                &.countrySelect {
                    .select__menu {
                        display: block;
                        font-size:14px;
                        width:  calc(100% + 150px) !important;
                        max-width: calc(100% + 150px) !important;
                        span {
                            display: inline-block;
                            vertical-align: middle;
                        }
                        .select__option {
                            display:flex;
                            justify-content: space-between;
                            align-items: center;
                            img {
                                display: block;
                                width:30px;
                                height:30px;
                                object-fit: cover;
                                margin-right:5px;
                            }
                            span {
                                width: calc(100% - 35px);
                                line-height: 1.2;
                            }
                        }
                    }
                }
            } 
        }
    }
}
@media screen and (max-width:639px) {
    .modal {
        div {
            &.countryPhoneFlexBox {
                > div {
                    &.countrySelect {
                        .select__menu {
                            display: block;
                            width:  calc(100% + 200px) !important;
                            max-width: calc(100% + 200px) !important;
                        }
                    }
                } 
            }
        }
    }
}
@media screen and (max-width:479px) {
    div{
        &.countryPhoneFlexBox{
           >  div{
               &.countrySelect{
                width: 120px;
                .select__control{
                    border: none;
                }
                .select__single-value{
                     img{
                         width: 25px !important;
                         margin-right: 10px !important ;
                     }
                }
                .select__indicator{
                    padding: 5px 5px;
                }
                .select__menu{
                    width:  calc(100% + 120px) !important;
                    max-width: calc(100% + 120px) !important;
                }
               }
              
                &.textBox{
                    width: calc(100% - 120px);
                    .MuiFormControl-root{
                        input{
                            border: none;
                        }
                        fieldset{
                            display: none;
                        }
                    }
                   
                }
            } 
        }
    } 
}

.autocomplete-class {
    .MuiAutocomplete-hasClearIcon {
        .MuiAutocomplete-inputRoot {
            padding-right: 0px !important;
        }
    }
    .MuiAutocomplete-inputRoot {
        padding: 0px !important;
    } 
}