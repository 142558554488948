@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";

.onBoardBaseballSection {
  min-height: calc(100vh - 120px);
  padding: 20px 15px;
  @include flex();
  justify-content: center;
  margin-top: 0;
  .baseBallBlock {
    width: 100%;
    max-width: 420px;
    margin: 0 auto;
    @include radius(6px);
    .formSection {
      padding: 30px 30px;
      border: 1px solid $gray;
      width: 100%;
      @include radius(6px);
      .heading {
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 30px 0;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        color: $black;
      }
      div {
        &.heightFieldBox {
          margin-bottom: 25px;
          label {
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 10px 0;
            font-weight: 600;
            line-height: 20px;
            color: $black;
          }
          .flexBox {
            @include flex();
            align-items: center;
            justify-content: space-between;
            .fieldBox {
              width: calc(50% - 10px);
              @include flex();
              align-items: center;
              justify-content: space-between;
              select {
                outline: none;
                padding: 10px 25px 10px 10px;
                border: 1px solid $gray;
                @include radius(2px);
                color: $black;
                -webkit-appearance: none;
                background-image: url(../../assets/img/arrowdown.png);
                background-repeat: no-repeat;
                background-position: 95% center;
                background-size: 16px;
                width: calc(100% - 30px);
                font-size: 14px;
              }
              > span {
                display: inline-flex;
                margin-left: 10px;
                font-size: 14px;
                color: $black;
                margin-right: auto;
              }
            }
          }
        }
        &.weightFieldBox {
          margin-bottom: 10px;
          label {
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 10px 0;
            font-weight: 600;
            line-height: 20px;
            color: $black;
          }
          .fieldBox {
            @include flex();
            align-items: center;
            input {
              padding: 8px 10px;
              @include radius(2px);
              border: 1px solid $gray;
              outline: none;
              width: calc(100% - 22px);
              font-size: 14px;
              color: $black;
            }
            > div {
              width: 38%;
            }
            > span {
              margin-left: 10px;
              display: inline-flex;
              font-size: 14px;
              color: $black;
            }
          }
        }
      }
      p {
        &.prefer {
          margin: 0 0 30px 0;
          span {
            display: inline-flex;
            align-items: center;
            position: relative;
            input[type="checkbox"] {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              z-index: 1;
              cursor: pointer;
              &:checked + label {
                &::before {
                  border-color: $lightBlue;
                }
                &::after {
                  width: 12px;
                  height: 12px;
                  background: url(../../assets/img/checkBlue.png) no-repeat;
                  background-size: cover;
                  content: "";
                  display: inline-block;
                  position: absolute;
                  left: 3px;
                  top: 4px;
                }
              }
            }

            label {
              position: relative;
              font-size: 14px;
              color: $black;
              font-weight: normal;
              padding-left: 30px;
              cursor: pointer;
              line-height: 18px;
              color: $black;
              &::before {
                width: 18px;
                height: 18px;
                border: 1px solid $black;
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
        }
        &.info {
          font-size: 14px;
          margin: 0;
          color: $black;
          text-align: center;
        }
      }
    }
    .skip {
      text-align: center;
      margin-top: 15px;
      .sikpBtnBox{
        display: inline-block;
      }
    }
    .skip a {
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      color: #c0321f;
    }
    .continueBtnBox {
      text-align: center;
      margin-top: 35px;
      a {
        outline: none;
        box-shadow: none;
        cursor: pointer;
        background-color: $green;
        color: $white;
        padding: 10px 15px;
        @include radius(4px);
        border: none;
        font-size: 16px;
        min-width: 100px;
        min-height: 45px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .onBoardBaseballSection {
    .baseBallBlock {
      .formSection {
        padding: 20px 20px;
        div {
          &.heightFieldBox {
            margin-bottom: 0;
            .flexBox {
              flex-wrap: wrap;
              .fieldBox {
                width: 100%;
                margin-bottom: 25px;
              }
            }
          }
        }
      }
      .continueBtnBox {
        a {
          width: 100%;
        }
      }
    }
  }
}
