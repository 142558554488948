@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/reset";

.resources-main-sec {
  background-color: $bgLightGray;
  padding: 25px 0;
  margin-top: 60px;
  .container {
    max-width: 925px;
  }
  .tabBox {
    background-color: $white;
    padding: 0 15px;
    border: 1px solid #00000038;
    margin: 0 auto 15px auto;
    @include radius(4px);
    &.slidetab {
      padding-left: 30px;
      padding-right: 30px;
    }
    ul {
      @include flex();
      align-items: center;
      justify-content: space-around;
      margin: 0;
      padding: 0;
      border: none;
      li {
        list-style: none;
        margin-right: 30px;
        a {
          display: inline-block;
          text-decoration: none;
          padding: 15px 10px;
          @include font(16);
          color: $black;
          border: none !important;
          position: relative;
          &:hover {
            color: $baseColor;
            border: 0;
            border-radius: 0 !important;
          }
          &.active {
            color: $baseColor;
            &::after {
              content: "";
              background-color: $baseColor;
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              bottom: 0.5px;
              left: 0;
              width: 100%;
              height: 2px;
            }
          }
        }
        &.closeBtnBox {
          display: none;
          text-align: right;
          button {
            padding: 5px;
            background-color: transparent;
            outline: none;
            border: none;
            cursor: pointer;
            svg {
              .a {
                fill: $black;
                opacity: 0.7;
              }
            }
          }
        }
        &:last-child {
          margin-right: 0px;
        }
        &.reportLink {
          a {
            span {
              &.icon {
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px;
                svg {
                  .a {
                    fill: $black;
                    fill-rule: evenodd;
                  }
                }
              }
            }
            &:hover {
              span {
                &.icon {
                  .a {
                    fill: $baseColor;
                  }
                }
              }
            }
          }
        }
      }
      &.tabs-slide {
        display: block;
        li {
          margin: 0 !important;
          text-align: center;
          a {
            @include font(15);
          }
          &.current {
            a {
              color: $baseColor;
              &::after {
                content: "";
                background-color: $baseColor;
                display: inline-block;
                position: absolute;
                bottom: 0.5px;
                left: 0;
                width: 100%;
                height: 2px;
              }
            }
          }
        }
        .owl-nav {
          display: block;
          position: absolute;
          width: 100%;
          top: 5px;
          left: 0;
          right: 0;
          z-index: 1;
          button {
            @include transition();
            span {
              content: "";
              display: inline-block;
              position: relative;
              width: 10px;
              height: 10px;
              font-size: 0;
              border-right: 2px solid $black;
              border-bottom: 2px solid $black;
              transform: rotate(-0deg);
              -webkit-transform: rotate(135deg);
              -moz-transform: rotate(135deg);
              -ms-transform: rotate(135deg);
              -o-transform: rotate(135deg);
              -webkit-transition: all 0.3s ease-out;
              transition: all 0.3s ease-out;
            }
            &.owl-next {
              float: right;
              position: absolute;
              right: -18px;
              outline: none;
              span {
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
              }
            }
            &.owl-prev {
              float: left;
              position: absolute;
              left: -18px;
              outline: none;
            }
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
    .flexBox {
      display: none;
      div {
        &.menuBtnBox {
          display: block;
          width: auto;
          margin-right: 10px;
          .tabMenuBtn {
            background: transparent;
            padding: 5px;
            border: none;
            outline: none;
            cursor: pointer;
            svg {
              .a {
                fill: #343434;
              }
            }
          }
        }
        @include flex();
        align-items: center;
        width: calc(100% - 36px);
        p {
          @include font(15);
          color: $baseColor;
        }
      }
    }
  }
  .resources-join-video-call {
    @include flex;
    background: $white;
    padding: 15px;
    border: 1px solid $gray;
    @include radius(4px);
    margin-bottom: 20px;
    .detial-box {
      width: 68%;
      margin-right: 2%;
      @include font(14);
      color: rgba($black, 0.7);
      line-height: 1.4;
    }
    .join-video-box {
      width: calc(100% - 70%);
      @include radius(4px);
      overflow: hidden;
      a {
        display: block;
        position: relative;
        .play-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $baseColor;
          width: 48px;
          height: 48px;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -24px;
          margin-top: -24px;
          @include radius(50%);
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
      img {
        max-width: 100%;
      }
    }
    .video-text {
      @include flex();
      align-items: center;
      @include font(11);
      font-weight: 300;
      color: rgba($black, 0.5%);
      padding-top: 10px;
      .video-info-img {
        @include flex();
        align-items: center;
        margin-right: 10px;
        img {
          width: 14px;
          height: 14px;
          @include radius(50%);
          margin-left: -5px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  .resources-box-wrap {
    background: $white;
    padding: 15px;
    border: 1px solid $gray;
    @include radius(5px);
    .video-grid-list {
      .video-box {
        background: $white;
        box-shadow: 0 3px 6px #00000017;
        border: 1px solid $gray;
        border-radius: 5px;
        @include radius(5);
        position: relative;
        @include transition();
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
        &:nth-child(2n + 3) {
          clear: both;
        }
        .img-box {
          position: relative;
          height: 145px;
          border-radius: 4px 4px 0 0;
          video {
            object-fit: cover;
            width: 100%;
            height: 100%;
            position: relative;
            border-radius: 4px 4px 0 0;
          }
          .video-play-button {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            cursor: pointer;
            .video-play-inner {
              @include flex();
              justify-content: center;
              align-items: center;
              height: 100%;
            }
            button {
              @include flex();
              justify-content: center;
              align-items: center;
              background: $baseColor;
              width: 36px;
              height: 36px;
              @include radius(50%);
              outline: none;
              border: none;
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
          img {
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .video-tagmark {
            position: absolute;
            background: $blue;
            right: -5px;
            bottom: 20px;
            z-index: 1;
            &.premium {
              background: $yellow;
              &::after {
                border-color: transparent transparent $yellow transparent;
              }
              span {
                &:after {
                  border-color: transparent transparent transparent #c48a26;
                }
                &:before {
                  border-color: #c48a26 transparent transparent transparent;
                }
              }
            }
            &:after {
              content: "";
              position: absolute;
              width: 0;
              height: 0;
              left: -15px;
              top: 0;
              bottom: 0;
              border-style: solid;
              border-width: 0 0 25px 15px;
              border-color: transparent transparent $blue transparent;
            }
            span {
              position: relative;
              display: block;
              padding: 6px 8px;
              @include font(13);
              color: $white;
              letter-spacing: 0.2px;
              &:after {
                content: "";
                position: absolute;
                right: 0;
                top: -5px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 0 0 5px;
                border-color: transparent transparent transparent #0e4e95;
              }
              &:before {
                content: "";
                position: absolute;
                right: 0;
                bottom: -5px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 5px 0 0;
                border-color: #0e4e95 transparent transparent transparent;
              }
            }
          }
        }
        .video-detail-box {
          padding: 15px 10px;
          .title {
            @include font(14);
            font-weight: 400;
            color: $blackColor;
            line-height: 1.3;
            margin-bottom: 5px;
            min-height:40px;
            + p {
              @include font(12);
              font-weight: 400;
              color: $black;
            }
          }
          .video-info {
            display: block;
            padding-top: 12px;
            li {
              display: inline-block;
              vertical-align: middle;
              position: relative;
              padding-right: 20px;
              @include font(13);
              font-weight: 300;
              color: rgba($black, 0.5%);
              margin-bottom: 5px;
              &::after {
                content: "";
                background: rgba($black, 0.5%);
                position: absolute;
                width: 5px;
                height: 5px;
                right: 5px;
                top: 5px;
                @include radius(50%);
                z-index: 1;
              }
              &:last-child {
                padding-right: 0;
                &::after {
                  display: none;
                }
              }
            }
          }
          .video-text {
            @include flex();
            align-items: center;
            padding-top: 10px;
            @include font(10);
            color: rgba($black, 0.5%);
            min-height: 22px;
            .video-info-img {
              position: relative;
              width: 30px;
              margin-right: 5px;
              img {
                width: 15px;
                height: 15px;
                @include radius(50%);
              }
              .video-img {
                display: block;
                position: absolute;
                top: 0;
                left: 8px;
                z-index: 1;
              }
            }
            p {
              width: calc(100% - 30px);
            }
          }
          .video-button-box {
            margin-top: 15px;
            .btn {
              padding: 10px 20px;
              @include font(12);
              font-weight: 300;
              letter-spacing: 0.18px;
              &.blue-light {
                background: $lightBlue;
              }
            }
          }
        }
      }
    }
    .paginationBox {
      @include flex();
      flex-wrap: wrap;
      align-items: center;
      div {
        &.perPage {
          @include flex();
          align-items: center;
          width: 110px;
          input {
            padding: 5px;
            width: 35px;
            height: 26px;
            @include font(14);
            color: $black;
            border: 1px solid $gray;
            outline: none;
            box-shadow: none;
            @include radius(3px);
          }
          label {
            display: inline-flex;
            @include font(14);
            color: $black;
            margin-left: 10px;
          }
        }
        &.pageNav {
          width: calc(100% - 220px);
          text-align: center;
          ul {
            display: inline-flex;
            justify-content: center;
            width: auto;
            padding: 0;
            border: 1px solid $gray;
            @include radius(3px);
            margin: 0;
            li {
              list-style: none;
              @include flex();
              align-items: center;
              justify-content: center;
              width: 26px;
              height: 26px;
              border-right: 1px solid $gray;
              span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                @include font(14);
                color: #626262;
                width: 26px;
                height: 26px;
                cursor: pointer;
                &:hover {
                  background-color: $lightBlue;
                  color: $white;
                  svg {
                    fill: $white;
                  }
                }
              }
              &.backLink {
                svg {
                  width: 10px;
                  height: auto;
                  fill: #626262;
                  transform: rotate(180deg);
                }
              }
              &.forLink {
                svg {
                  width: 10px;
                  height: auto;
                  fill: #626262;
                }
              }
              &:last-child {
                border-right: none;
              }
            }
          }
        }
        &.goBox {
          @include flex();
          justify-content: flex-end;
          align-items: center;
          width: 110px;
          input {
            padding: 5px;
            width: 35px;
            height: 26px;
            @include font(14);
            color: $black;
            border: 1px solid $gray;
            outline: none;
            box-shadow: none;
            @include radius(3px);
          }
          a {
            display: inline-flex;
            align-items: center;
            @include font(14);
            color: $black;
            margin-left: 10px;
            padding: 5px;
            text-decoration: none;
            cursor: pointer;
            span {
              display: inline-flex;
              &.icon {
                margin-left: 5px;
                svg {
                  fill: $black;
                  .a {
                    fill: $black;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .para{
    @include flex();
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  @include font(16);
  color: $black;
  line-height: 26px;
  p {
      margin-bottom: 0;
  }
  .btn {
    width: 160px;
    display: flex;
    align-items: center;
    padding: 12px 25px;
    height: 45px;
    @include font(14);
    font-weight: 500;
    @include radius(4px);
    svg {
        margin-right: 5px;
    }
}
  }
  .search-box-wrap {
    @include flex();
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .search-box {
      width: 300px;
      margin-right: 15px;
      position: relative;
      input {
        padding: 10px 45px 10px 10px;
        @include font(14);
        font-weight: 300;
        color: $gray;
        width: 100%;
        height: 45px;
        border: 1px solid $gray;
        @include radius(4px);
      }
      button {
        display: inline-block;
        vertical-align: middle;
        background: none;
        position: absolute;
        width: 40px;
        height: 45px;
        right: 0;
        top: 0;
        border: 0;
        outline: none;
        z-index: 11;
        svg {
          .a {
            opacity: 0.3;
          }
          .b {
            fill: none;
            stroke: $blackColor;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        }
      }
    }
    .btn {
      @include flex();
      align-items: center;
      padding: 12px 15px;
      height: 45px;
      @include font(14);
      font-weight: 500;
      @include radius(4px);
      svg {
        margin-right: 5px;
        path {
          fill: $white;
        }
      }
    }
    &.with-button {
      display: block;
      margin: 0 auto 30px;
      .search-box {
        max-width: 600px;
        width: auto;
        margin: 0 auto;
        input {
          padding: 10px 100px 10px 30px;
        }
        .search-icon {
          position: absolute;
          left: 10px;
          top: 12px;
          svg {
            .a {
              opacity: 0.3;
            }
            .b {
              fill: none;
              stroke: $blackColor;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 1.5px;
            }
          }
        }
        .blue {
          background: $lightBlue;
          width: 90px;
        }
      }
    }
  }
  .resources-loadmore-box {
    padding-top: 20px;
    .load-more-button {
      display: block;
      text-decoration: none;
      width: 100%;
      background: $white;
      padding: 10px 15px;
      @include font(14);
      font-weight: 400;
      color: $black;
      border: 1px solid $gray;
      @include radius(5px);
      text-align: center;
      @include transition();
      cursor: pointer;
      &:hover {
        color: $baseColor;
        border-color: $baseColor;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .resources-content-flex {
    @include flex();
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
    @include font(16);
    color: $black;
    line-height: 26px;
    p {
        margin-bottom: 0;
    }
    .btn {
        width: 160px;
        margin-left: 15px;
        display: inline-block;
        vertical-align: middle;
        @include font(14);
        font-weight: 500;
        padding: 12px 15px;
        @include radius(4px);
        height: 45px;
        svg {
            margin-right: 5px;
        }
    }
}
  .resource-library-list {
    overflow: hidden;
    li {
        float: left;
        width: 31.333%;
        margin-right: 3%;
        margin-bottom: 15px;
        background: $white;
        padding: 20px;
        box-shadow: 0px 3px 6px #00000017;
        border: 1px solid rgba($gray, .2);
        @include radius(5px);
        &:nth-child(3n+3) {
            margin-right: 0;
        }
        &:nth-child(3n+4) {
            clear: both;
        }
        h4 {
            @include font(18);
            font-weight: 700;
            color: $baseColor;
            margin-bottom: 12px;
        }
        p {
            @include font(15);
            color: $black;
            line-height: 30px;
            margin-bottom: 15px;
        }
        .btn {
            padding: 10px 20px;
            cursor: pointer;
            &.gray {
                background: rgba($gray, .5);
                color:$black;
                &:hover {
                    background: $baseColor;
                    color:$white;
                }
            }
        }
    }
}
.resources-inner-list {
    .tab-content {
      display: none;
      &.current {
        display: block;
      }
    }
  }
}
.resources-video-modal {
  iframe {
      width: 100%;
      height: 260px;
  }
  .modal-dialog{
    margin: 0px;
    max-width: 100%;
  .modal-header {
      border-bottom: none;
      padding-bottom: 0;
      .close {
          padding: 10px 15px;
          @include font(30);
          color:$black;
          font-weight: 300;
          outline: none;
          border: none;
          cursor: pointer;
      }
  }
  .modal-body {
      p {
          @include font(18);
          font-weight: 500;
          color:$black;
          line-height: 28px;
          text-align: center;
          margin-bottom: 20px;
      }
      .button-wrap {
          @include flex();
          justify-content: center;
          align-items: center;
          .btn {
              padding: 10px 20px;
              @include font(15);
              margin: 5px 10px;
              &.blue {
                  background:$lightBlue;
                  color:$white;
              }
              &.gray {
                  background:rgba($gray, .4);
                  color:$black;
              }
          }
      }
  }
}
}
.video-box-row {
  .video-box-item {
    background: $white;
    padding: 20px 15px;
    border: 1px solid $gray;
    @include radius(5px);
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .sec-title {
      @include font(18);
      font-weight: 500;
      color: $blackColor;
      margin-bottom: 20px;
      padding-right: 150px;
      text-align: left;
      padding-bottom: 0;
      &::before {
        display: none;
      }
    }
  }
  .resources-video-slide {
    .owl-nav {
      display: block;
      position: absolute;
      top: -38px;
      right: 0;
      z-index: 1;
      button {
        position: relative;
        line-height: 1;
        outline: none;
        @include transition();
        span {
          font-size: 0;
        }
        &.owl-next {
          padding-right: 15px !important;
          &:after {
            content: "Next";
            @include font(14);
            font-weight: 400;
            color: $black;
          }
          &:before {
            content: ">";
            font-family: monospace;
            position: absolute;
            @include font(20);
            line-height: 0;
            right: 0;
            top: 8px;
            z-index: 1;
          }
        }
        &.owl-prev {
          padding-left: 15px !important;
          margin-right: 15px;
          &:after {
            content: "Previous";
            @include font(14);
            font-weight: 400;
            color: $black;
          }
          &:before {
            content: "<";
            font-family: monospace;
            position: absolute;
            @include font(20);
            line-height: 0;
            left: 0;
            top: 8px;
            z-index: 1;
          }
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
.back-link-box {
  padding-bottom: 12px;
  margin-bottom: 25px;
  border-bottom: 1px solid $gray;
  a {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    @include font(14);
    color: $black;
    @include transition();
    svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      path {
        fill: $black;
        @include transition;
      }
    }
    &:hover {
      color: $baseColor;
      svg {
        path {
          fill: $baseColor;
        }
      }
    }
  }
}
.resources-overview-detail {
  background: $white;
  border: 1px solid $gray;
  .video-overview-box {
    padding: 15px 20px;
    .sec-title {
      @include font(20);
      font-weight: 600;
      color: $black;
      margin-bottom: 15px;
    }
    .video-box {
      margin-bottom: 20px;
      .img-box {
        position: relative;
        video {
          display: block;
          width: 100%;
          object-fit: cover;
        }
      }
      .video-play-button {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        cursor: pointer;
        .video-play-inner {
          @include flex();
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        button {
          @include flex();
          justify-content: center;
          align-items: center;
          background: $baseColor;
          width: 60px;
          height: 60px;
          outline: none;
          border: none;
          @include radius(50%);
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
    .overview-flex-box {
      @include flex();
      .overview-content {
        width: 70%;
        margin-right: 2%;
        h3 {
          @include font(18);
          font-weight: 600;
          color: $black;
          margin-bottom: 10px;
        }
        p {
          @include font(15);
          font-weight: 300;
          color: #888888;
          line-height: 1.6;
          margin-bottom: 10px;
        }
      }
      .overview-price {
        width: calc(100% - 72%);
        text-align: right;
        .price {
          @include font(37);
          font-weight: 500;
          color: $black;
          line-height: 1;
          margin-bottom: 8px;
          span {
            display: inline-block;
            vertical-align: middle;
            @include font(22);
          }
        }
        .btn {
          padding: 10px 20px;
          @include font(12);
          font-weight: 500;
          letter-spacing: 0.18px;
        }
      }
    }
  }
  .overview-quote-box {
    background: $bgLightGray;
    padding: 20px 30px;
    text-align: center;
    .quote-text {
      @include font(15);
      color: #666666;
      font-style: italic;
      line-height: 1.5;
      margin-bottom: 15px;
      p {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .quote-name {
      @include font(12);
      font-weight: 600;
      color: #666666;
    }
  }
  .overview-bottom-box {
    padding: 25px 15px;
    text-align: center;
    p {
      @include font(16);
      font-weight: 600;
      color: $blackColor;
      line-height: 1.5;
      margin-bottom: 15px;
    }
    .btn {
      padding: 10px 20px;
      @include font(12);
      font-weight: 500;
      letter-spacing: 0.18px;
    }
  }
  .payment-cart-box {
    display: none;
    max-width: 600px;
    margin: 30px auto 0;
    .payment-cart-inner {
      border: 1px solid $gray;
      @include radius(4px);
      margin-bottom: 30px;
      .title-box {
        @include flex();
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid $gray;
        span {
          cursor: pointer;
          &.add-payment-button {
            @include font(15);
            font-weight: 500;
            color: $lightBlue;
          }
          &.close-payment-button {
            img {
              display: inline-block;
              vertical-align: middle;
              width: 12px;
            }
          }
        }
      }
      .payment-detail {
        padding: 20px;
        .flexbox {
          @include flex();
          justify-content: space-between;
          align-items: center;
          text-align: left;
          margin-bottom: 15px;
          padding-right: 90px;
          &:last-child {
            margin-bottom: 0;
          }
          &.full {
            display: block;
          }
          .half {
            width: calc(50% - 10px);
          }
          label {
            display: block;
            @include font(15);
            font-weight: 500;
            color: #666666;
            margin-bottom: 6px;
          }
          input {
            padding: 0 15px;
            color: $black;
            border: 1px solid $gray;
            width: 100%;
            height: 34px;
            @include radius(4px);
          }
          .security-code {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              top: 23px;
              right: -90px;
              background: url(../img/security-code.png) no-repeat;
              background-position: center center;
              width: 80px;
              height: 31px;
            }
          }
        }
      }
    }
    .payment-button {
      button {
        @include font(12);
        font-weight: 500;
        letter-spacing: 0.18px;
        border: none;
        outline: none;
        margin-bottom: 10px;
        &.cancel-link {
          background: none;
          color: $baseColor;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .resources-overview-detail {
    .video-overview-box {
      .overview-flex-box {
        .overview-content {
          width: 83%;
        }
        .overview-price {
          width: calc(100% - 85%);
          .price {
            @include font(30);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .resources-main-sec {
    .tabBox {
      ul {
        li {
          margin-right: 15px;
          a {
            @include font(15);
            padding-left: 5px;
            padding-right: 5px;
          }
        }
        &.tabs-slide {
          li {
            a {
              @include font(14);
            }
          }
        }
      }
    }
    .resources-join-video-call {
      .join-video-box {
        a {
          .play-button {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
    .resources-content-flex {
      display: block;
      @include font(15);
      p {
          margin-bottom: 15px;
      }
      .btn {
          margin-left: 0;
      }
  }
    .resource-library-list {
      li {
          p {
              @include font(14);
          }
      }
  }
  }
  .resources-overview-detail {
    .video-overview-box {
      .sec-title {
        @include font(18);
      }
    }
    .overview-quote-box {
      padding-left: 15px;
      padding-right: 15px;
      .quote-text {
        p {
          br {
            display: none;
          }
        }
      }
      .quote-name {
        line-height: 1.5;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .resources-main-sec {
    .tabBox {
      ul {
        li {
          margin-right: 10px;
        }
      }
    }
    .resources-box-wrap {
      .video-grid-list {
        .owl-item {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
    .resource-library-list {
      li {
        width: 49%;
        &:nth-child(3n + 3) {
          margin-right: 2%;
        }
        &:nth-child(3n + 4) {
          clear: none;
        }
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
        &:nth-child(2n + 3) {
          clear: both;
        }
        h4 {
          @include font(16);
        }
      }
    }
  }
  .resources-overview-detail {
    .video-overview-box {
      .overview-flex-box {
        .overview-content {
          width: 75%;
          p {
            @include font(14);
          }
        }
        .overview-price {
          width: calc(100% - 77%);
        }
      }
    }
    .overview-quote-box {
      .quote-text {
        @include font(14);
        br {
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 599px) {
  .resources-main-sec {
    .resources-box-wrap {
      .video-grid-list {
        .video-box {
          .video-detail-box {
            .title {
              min-height: 1px;
            }
            .video-text {
              min-height: 1px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 567px) {
  .resources-main-sec {
    .tabBox {
      &.first {
        padding: 10px;
        nav {
          background-color: $white;
          padding: 15px;
          position: fixed;
          left: -300px;
          width: 250px;
          height: 100%;
          top: 0;
          z-index: 9999;
          transition: all 0.5s ease-in-out;
          ul {
            display: block;
            li {
              border-bottom: 1px solid $gray;
              padding-bottom: 0;
              margin-bottom: 10px;
              margin-right: 0;
              a {
                width: 100%;
                padding: 15px 10px;
                &.active {
                  &:after {
                    height: 1px;
                    bottom: -1px;
                  }
                }
              }
              &.closeBtnBox {
                display: block;
                padding-bottom: 20px;
              }
            }
          }
          &::after {
            content: "";
            display: inline-block;
            background: rgba(0, 0, 0, 0.5);
            width: 0;
            left: -300px;
            height: 100%;
            position: fixed;
            top: 0;
            transition: all 0.5s ease-in-out;
          }
          &.show {
            left: 0;
            &::after {
              width: calc(100% - 250px);
              left: 250px;
            }
          }
        }
        .flexBox {
          @include flex();
        }
      }
    }
  }
  .resources-main-sec {
    .search-box-wrap {
      .search-box {
        width: auto;
      }
      &.with-button {
        margin-bottom: 25px;
      }
    }
    .resources-box-wrap {
      padding-left: 10px;
      padding-right: 10px;
    }
    .resources-join-video-call {
      display: block;
      .detial-box {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        @include font(14);
      }
      .join-video-box {
        width: 100%;
        a {
          img {
            width: 100%;
          }
          .play-button {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
  .video-box-row {
    .video-box-item {
      padding: 15px 10px;
      .sec-title {
        @include font(16);
        padding-right: 145px;
      }
    }
  }
  .resources-video-modal {
    iframe {
      height: 180px;
    }
  }
  .resources-overview-detail {
    .video-overview-box {
      padding: 15px 15px 25px;
      .sec-title {
        @include font(16);
      }
      .video-box {
        .video-play-button {
          button {
            width: 35px;
            height: 35px;
          }
        }
      }
      .overview-flex-box {
        display: block;
        .overview-content {
          width: 100%;
          margin-right: 0;
          h3 {
            @include font(15);
          }
        }
        .overview-price {
          width: 100%;
          text-align: center;
          .price {
            @include font(25);
            margin-bottom: 12px;
          }
        }
      }
    }
    .payment-cart-box {
      margin-top: 15px;
      .payment-cart-inner {
        margin-bottom: 20px;
        .title-box {
          padding: 15px;
        }
        .payment-detail {
          padding: 15px;
          .flexbox {
            display: block;
            padding-right: 0;
            label {
              @include font(13);
            }
            .half {
              width: 100%;
              margin-bottom: 15px;
              &.security-code {
                padding-right: 90px;
                &:after {
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
    .overview-bottom-box {
      padding-bottom: 25px;
      p {
        @include font(15);
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .resources-main-sec {
    .resource-library-list {
      li {
        float: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
