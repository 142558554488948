@import "../../assets/css/variables";
@import "../../assets/css/mixin";
@import "../../assets/css/reset";

.notification-with-sidebar {
    @include flex();
    background: $white;
    .notification-sidebar {
        width: 200px;
        ul {
            li {
                display: block;
                padding: 20px;
                border-bottom: 1px solid rgba($gray, .40%);
                a {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;
                    text-decoration: none;
                    @include font(16);
                    color: rgba($black, .40%);
                    @include transition();
                    &:hover {
                        color: $blue;
                    }
                }
                &.active {
                    a {
                        color: $blue;
                    }    
                }
                &.button-link {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    .btn {
                        display: block;
                        width: 100%;
                        background-image: linear-gradient(115deg, #00B9FF, #19D7CD);
                        @include radius(50px);
                        &:after {
                            display: none;
                        }
                        color: $white
                    }
                    .dropdown-menu {
                        background: $white;
                        padding: 15px;
                        min-width: 1px;
                        width: auto;
                        left: 20px!important;
                        right: 20px;
                        top:auto!important;
                        transform: none!important;
                        border:0;
                        @include radius(6);
                        box-shadow: -2px 3px 11px #00000029;
                        margin: 0;
                        li {
                            padding: 0;
                            @include font(15);
                            font-weight: 500;
                            color: $black;
                            margin-bottom: 12px;
                            border: none;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            a {
                                display: block;
                                padding: 0 5px;
                                background: none;
                                @include font(13);
                                font-weight: 400;
                                color: $black;
                                white-space: inherit;
                                line-height: 1.4;
                                &:hover {
                                    color: $baseColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .notification-detail-box {
        width: calc(100% - 200px);
        border-left: 1px solid rgba($gray, .40%);
        min-height: 460px;
        .notification-search-box {
            @include flex();
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px;
            border-bottom: 1px solid rgba($gray, .40%);
            .search-box {
                @include flex();
                align-items: center;
                margin-right: 15px;
                label {
                    @include font(18);
                    font-weight: 500;
                    color: $blackColor;
                    margin-right: 10px;
                }
                .checkbox-info {
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    input[type="checkbox"] {
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      top: 0;
                      opacity: 0;
                      z-index: 1;
                      cursor: pointer;
                      @include radius(2px);
                      &:checked {
                        + label {
                            &::before {
                                border-color: $lightBlue;
                            }
                            &::after {
                                content: "";
                                display: inline-block;
                                background: url(../img/checkBlue.png) no-repeat;
                                background-size: cover;
                                position: absolute;
                                width: 12px;
                                height: 12px;
                                left: 3px;
                                top: 5px;
                            }
                            &.singleSelect {
                                &::after {
                                    content: "";
                                    display: inline-block;
                                    background: $lightBlue;
                                    position: absolute;
                                    width: 10px;
                                    height: 2px;
                                    left: 4px;
                                    top: 8px;
                                }
                            }
                        }
                      }
                    }
                    label {
                      position: relative;
                      font-size: 14px;
                      color: $black;
                      font-weight: normal;
                      padding-left: 18px;
                      cursor: pointer;
                      line-height: 18px;
                      color: $black;
                      &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid $black;
                        @include radius(2px);
                      }
                    }
                }
                .delete-foreverBtn {
                    padding: 6px 8px;
                    background: rgba($gray, .2);
                    @include font(14);
                    font-weight: 400;
                    color: $blackColor;
                    border: none;
                    outline: none;
                    @include radius(2px);
                    margin-right: 15px;
                }
                .EmptyTrashNowBtn {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: none;
                    @include font(14);
                    font-weight: 400;
                    color: $baseColor;
                    &:hover {
                        opacity: .8;
                    }
                }
                h1 {
                    @include font(18);
                    font-weight: 500;
                    color: $black;
                    margin-right: 10px;
                }
                .search-inner {
                    position: relative;
                    input {
                        padding: 10px 15px 10px 35px;
                        width: 100%;
                        height: 40px;
                        font-size: 0.875rem;
                        font-weight: 300;
                        color: $gray;
                        border: 1px solid $gray;
                        @include radius(50px);
                    }
                    button {
                        display: inline-block;
                        vertical-align: middle;
                        background: none;
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        left: 0;
                        top: 0;
                        z-index: 11;
                        border: 0;
                        outline: none;
                        svg {
                            .a {
                                opacity: 0.3;
                            }
                            .b {
                                fill: none;
                                stroke: $blackColor;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 1.5px;
                            }
                        }
                    }
                }
            }
        }
        .notification-pagination {
            .back-link {
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
                position: relative;
                padding-left: 22px;
                @include font(14);
                font-weight: 400;
                color: rgba($black, .8);
                &::after {
                    content: "<";
                    position: absolute;
                    font-family: monospace;
                    @include font(26);
                    color: rgba($black, .8);
                    left: 0;
                    top: -7px;
                    z-index: 1;
                    @include transition();
                }
                &:hover {
                    color: $baseColor;
                    &::after {
                        color: $baseColor;
                    }
                }
            }
            > ul {
                @include flex();
                align-items: center;
                li {
                    @include font(15);
                    color: $black;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                    button {
                        background:transparent;
                        font-family: monospace;
                        @include font(25);
                        color: rgba($blackColor, .55%);
                        border: none;
                        outline: none;
                        @include transition();
                        &:hover {
                            color: $baseColor;
                        }
                    }
                    .notification-dropdown-info {
                        display: block;
                        text-align: right;
                        .dropdown {
                            li {
                                position: relative;
                                .dropdown-toggle {
                                    display: block;
                                    padding: 1px 6px 6px;
                                    @include radius(50px);
                                    img {
                                        transform: rotate(90deg);
                                    }
                                    &:after {
                                        display: none;
                                    }
                                }
                                .dropdown-menu {
                                    padding:15px;
                                    background: $white;
                                    width: auto;
                                    top:100%!important;
                                    right: 0;
                                    left: auto!important;
                                    transform: none!important;
                                    border:0;
                                    box-shadow: -2px 3px 11px #00000029;
                                    @include radius(6);
                                     li {
                                        margin-bottom: 15px;
                                        margin-right: 0;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        strong {
                                            font-weight: 500;
                                        }
                                        a {
                                            @include flex();
                                            background: none;
                                            width: 100%;
                                            padding:0 0 0 25px;
                                            @include font(14);
                                            color: rgba($blackColor, .60%);
                                            white-space: inherit;
                                            line-height: 1.4;
                                            position: relative;
                                            .icon-box {
                                                position: absolute;
                                                left: 0;
                                                svg {
                                                    path {
                                                        fill: rgba($blackColor, .50%);
                                                    }
                                                }
                                            }
                                            &:hover {
                                                color: $baseColor;
                                                svg {
                                                    path {
                                                        fill:$baseColor;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .notification-item {
            @include flex();
            align-items: center;
            padding: 12px 20px;
            background: rgba($gray, .09);
            border-bottom: 1px solid rgba($gray, .40%);
            @include transition();
            cursor: pointer;
            .button-boxinfo {
                @include flex();
                align-items: center;
                width: 70px;
                .item {
                    width: 22px;
                    margin-right: 15px;
                    &:first-child {
                        margin-right: 10px;
                    }
                    .button {
                        background: none;
                        outline: none;
                        border: none;
                        padding: 0 5px;
                    }
                    .checkbox-info {
                        display: inline-flex;
                        align-items: center;
                        position: relative;
                        input[type="checkbox"] {
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          left: 0;
                          top: 0;
                          opacity: 0;
                          z-index: 1;
                          cursor: pointer;
                          @include radius(2px);
                          &:checked {
                            + label {
                                &::before {
                                    border-color: $lightBlue;
                                }
                                &::after {
                                    content: "";
                                    display: inline-block;
                                    background: url(../img/checkBlue.png) no-repeat;
                                    background-size: cover;
                                    position: absolute;
                                    width: 12px;
                                    height: 12px;
                                    left: 3px;
                                    top: 5px;
                                }
                            }
                          }
                        }
                        label {
                          position: relative;
                          font-size: 14px;
                          color: $black;
                          font-weight: normal;
                          padding-left: 18px;
                          cursor: pointer;
                          line-height: 18px;
                          color: $black;
                          &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 18px;
                            height: 18px;
                            border: 1px solid $black;
                            @include radius(2px);
                          }
                        }
                    }
                }
            }
            .user-detial-box {
                @include flex();
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding-right: 100px;
                width: 100%;
                @include font(16);
                color: rgba($black, .60%);
                @include transition();
                .item {
                    &:last-child { 
                        position: absolute;
                        right: 0;
                        top: auto;
                        z-index: 1;
                        margin-right: 0;
                    }
                }
                .name {
                    margin-bottom: 5px;
                    font-weight: 500;
                }
                .text-box {
                    @include flex();
                    align-items: center;
                    .subject-line {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        width: 200px;
                        margin-right: 10px;
                        @include font(14);
                        font-weight: 500;
                        line-height: 1.3;
                        overflow: hidden;
                        text-transform: capitalize;
                    }
                    p {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        width: calc(100% - 200px);
                        @include font(15); 
                        line-height: 1.4;
                        overflow: hidden;
                    }
                }
                .time-text {
                    @include font(13);
                    color: $black;
                    text-align: right;
                }
            }
            &.whitebg {
                background: $white;
                .user-detial-box {
                    color: rgba($black, .60%);
                }
            }
            &.active {
                background: $white;
                .user-profile {
                    .initial {
                        color: $blackColor;
                        font-weight: 700;
                    }
                }
                .user-detial-box {
                    color: $blackColor;
                    font-weight: 700;
                    .text-box {
                        .subject-line {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) { 
    .notification-with-sidebar {
        .notification-sidebar {
            width: 180px;
        }
        .notification-detail-box {
            width: calc(100% - 180px);
            .notification-search-box {
                padding-left: 15px;
                padding-right: 15px;
                .search-box {
                    label {
                        @include font(16);
                    }
                }
            }
            .notification-pagination {
                > ul {
                    li {
                        margin-right: 5px;
                        button {
                            padding: 0;
                        }
                    }
                }
            }
            .notification-item {
                padding-left: 15px;
                padding-right: 15px;
                .user-detial-box {
                    @include font(15);
                    padding-right: 85px;
                    .text-box {
                        display: block;
                        .subject-line {
                            display: block;
                            width: 100%;
                            margin-bottom: 5px;
                        }
                        p {
                            @include font(13);
                            width: 100%;
                        }
                    }
                    .item {
                        &:last-child {
                            top: 22px;
                        }
                    }
                    .button-box {
                        .button {
                            margin-right: 10px;
                        }
                    }
                }
            }
        } 
    }
}
@media screen and (max-width: 767px) { 
    .pageContent {
        min-height: 1px!important;
    }
    .notification-with-sidebar {
        display: block;
        .notification-sidebar {
            width: 100%;
            ul {
                li {
                    padding: 15px;
                    &.button-link {
                        padding: 20px 15px;
                    }
                }
            }
        }
        .notification-detail-box {
            width: 100%;
            border-left: none;
            min-height: 1px;
        }
    }
}
@media screen and (max-width: 567px) {
    .notification-with-sidebar {
        .notification-sidebar {
            ul {
                li {
                    padding: 10px 15px;
                    a {
                        @include font(15);
                    }
                }
            }
        }
        .notification-detail-box {
            .notification-search-box {
                display: block;
                position: relative;
                .search-box {
                    display: block;
                    margin-right: 0;
                    label {
                        margin-bottom: 12px;
                    }
                }
            }
            .notification-pagination {
               display: block;
               > ul {
                   justify-content: space-between;
                   margin-top: 8px;
               }
            }
            .notification-item {
                align-items: flex-start;
                .user-detial-box {
                    @include font(14);
                    .text-box {
                        p {
                            -webkit-line-clamp: 2;
                        }
                    }
                    .item {
                        &:last-child {
                            top: 5px;
                        }
                    }
                }
            }
            &.with-back {
                .notification-search-box {
                    @include flex();
                    .notification-pagination {
                        position: static;
                        top: auto;
                    }
                }
            }
        }
    }
}