/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.onBoardCongratAthleteSection {
  min-height: calc(100vh - 120px);
  padding: 50px 15px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 60px;
}

.onBoardCongratAthleteSection .congratAthleteBlock {
  max-width: 680px;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  padding: 0px 30px 30px 30px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

.onBoardCongratAthleteSection .congratAthleteBlock div.medalImg img {
  max-width: 100%;
  height: auto;
}

.onBoardCongratAthleteSection .congratAthleteBlock div.data {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: calc(100% - 250px);
  margin-left: auto;
}

.onBoardCongratAthleteSection .congratAthleteBlock div.data .heading {
  font-size: 1.5625rem;
  color: #000;
  margin: 0 0 20px 0;
}

.onBoardCongratAthleteSection .congratAthleteBlock div.data .info {
  font-size: 0.875rem;
  color: #3E3E3E;
  line-height: 22px;
  margin: 0 0 20px 0;
}

.onBoardCongratAthleteSection .congratAthleteBlock div.data .info b {
  color: #000;
}

.onBoardCongratAthleteSection .congratAthleteBlock div.data .btnBox a {
  background-color: #1CB79E;
  padding: 15px 20px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: none;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  font-size: 0.875rem;
  color: #ffffff;
  text-transform: none;
}

@media screen and (max-width: 767px) {
  .onBoardCongratAthleteSection .congratAthleteBlock div.data {
    width: calc(100% - 230px);
  }
}

@media screen and (max-width: 575px) {
  .onBoardCongratAthleteSection {
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .onBoardCongratAthleteSection .congratAthleteBlock {
    padding: 0 20px 20px 20px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .onBoardCongratAthleteSection .congratAthleteBlock div.data {
    width: calc(100% - 0px);
    margin-top: 15px;
  }
  .onBoardCongratAthleteSection .congratAthleteBlock div.data .heading {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  .onBoardCongratAthleteSection .congratAthleteBlock div.medalImg {
    height: 150px;
  }
  .onBoardCongratAthleteSection .congratAthleteBlock div.medalImg img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}
