@import  "../../assets/css/mixin";
@import "../../assets/css/variables";
@import "../../assets/css/reset";

.stepper{
    margin-bottom: 30px;
    text-align: center;
    ul{
        @include flex();
        align-items: center;
        justify-content: center;
        padding: 0;
        margin:0;
        li{
            list-style: none;
            margin-right: 10px;
            span{
                display: inline-block;
                background-color: $gray;
                width: 16px;
                height: 16px;
                @include radius(50%);
            }
            &.active{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                border: 1px solid $green;
                @include radius(50%);
                @include shadow(0, 3px, 15px,#00000033);
                span{
                    background-color: transparent;
                    width: auto;
                    height: auto;
                }
            }
            &.passed{
                span{
                    background-color: $green;
                }
            }
            &:last-child{
                margin-right: 0px;
            }
        }
    }
}
@media  screen and (max-width:479px) {
    .stepper{
        ul{
            li{
                margin-right: 6px;
                span{
                    width: 15px;
                    height: 15px;
                }
                &.active{
                    width: 40px;
                    height: 40px;
                    min-height: 40px;
                    min-width: 40px;
                    svg{
                        width: 22px;
                        height: auto
                    }
                }
                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }   
}