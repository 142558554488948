@import "../../../../../../../assets/css/mixin"; 
@import "../../../../../../../assets/css/reset";
@import "../../../../../../../assets/css/variables";

.collegeListing {
  background-color: $bgLightGray;
  padding:0;
  .headerFlex {
    @include flex();
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;
    .tabs {
      width: calc(100% - 200px);
      ul {
        @include flex();
        justify-content: space-between;
        background: $white;
        width: 100%;
        height: 100%;
        padding: 0 5%;
        margin: 0;
        @include radius(4px);
        border: 1px solid $border;
        li {
          list-style: none;
          a {
            display: block;
            text-decoration: none;
            position: relative;
            @include font(18);
            color: $black;
            padding:20px;
            @include transition();
            span {
              &.icon {
                display: inline-flex;
                position: absolute;
                right: 0;
                top:10px;
                svg {
                  fill: $black;
                  width: auto;
                  height: 14px;
                  .a {
                    fill: $black;
                    @include transition();
                  }
                }
              }
            }
            &:after {
              content: "";
              display: block;
              background: $baseColor;
              position: absolute;
              left: 0;
              bottom: -1px;
              width: 100%;
              height: 2px;
              @include scaleX(0);
              @include transition();
            }
            &:hover {
              color: $baseColor;
              span{
                svg{
                  .a {
                    fill: $baseColor;
                  }
                }
              }
              &:after {
                @include scaleX(1);
              }
            }
          }
          .active {
            color: $baseColor;
            &:after {
              @include scaleX(1);
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .mobileMenuBox {
        display: none;
        align-items: center;
        background: $white;
        padding: 0 10px 0 0;
        @include radius(4px);
        border: 1px solid #00000038;
        div {
          &.menuBtn {
            .btn {
              @include flex();
              align-items: center;
              justify-content: center;
              background: transparent;
              min-height: 20px;
              min-width: 20px;
              padding: 5px 5px;
              margin: 10px 15px;
              border: none;
              outline: none;
              svg {
                .a {
                  fill: #343434;
                }
              }
            }
          }
          &.label {
            span {
              display: inline-block;
              @include font(14);
              color: $baseColor;
            }
          }
          &.mobileMenu {
            background: $white;
            position: fixed;
            top: 0;
            height: 100%;
            left: -300px;
            width: 100%;
            max-width: 250px;
            overflow-y: auto;
            border-right: 1px solid $gray;
            z-index: 999;
            transition: all 0.5s ease-in-out;
            .closeBtnBox {
              padding: 15px 15px;
              text-align: right;
              button {
                &.closebtn {
                  background: transparent;
                  padding: 5px 5px;
                  border: none;
                  outline: none;
                  svg {
                    .a {
                      fill: $black;
                      opacity: 0.7;
                    }
                  }
                }
              }
            }
            ul {
              display: block;
              padding: 0 15px;
              height: auto;
              max-height: calc(100% - 60px);
              overflow-y: auto;
              border: none;
              @include radius(0);
              li {
                list-style: none;
                padding-bottom: 10px;
                margin-bottom: 15px;
                border-bottom: 1px solid $gray;
                margin-right: 0;
                a {
                  @include flex();
                  justify-content: flex-start;
                  text-decoration: none;
                  border: none;
                  span {
                    display: inline-flex;
                    position: relative;
                    line-height: 22px;
                    &.icon {
                      display: inline-flex;
                      margin-top: -10px;
                    }
                  }
                  &.selected {
                    color: $baseColor;
                  }
                  &::after {
                    display: none;
                  }
                  &:hover {
                    color: $baseColor;
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                  border-bottom: none;
                }
              }
            }
            &::after {
              content: "";
              display: inline-block;
              position: fixed;
              width: 0;
              left: 0;
              height: 100%;
              top: 0;
              background-color: rgba(0, 0, 0, 0.5);
              transition: all 0.5s ease-in-out;
            }
            &.expandMenu {
              left: 0;
              &::after {
                content: "";
                display: inline-block;
                position: fixed;
                width: calc(100% - 250px);
                left: 250px;
                width: 100%;
                height: 100%;
                top: 0;
                background-color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
      }
    }
    .prefer {
      a {
        @include flex();
        align-items: center;
        text-decoration: none;
        background-color: $white;
        @include font(18);
        color: $black;
        padding: 20px;
        line-height: 18px;
        border: 1px solid $border;
        @include radius(4px);
        cursor: pointer;
        span {
          display: block;
          &.icon {
            margin-right: 10px;
            svg {
              display: block;
              .a {
                fill: $black;
                @include transition();
              }
            }
          }
        }
        &:hover{
          color:$baseColor;
          span {
            &.icon {
              svg {
                .a {
                  fill: $baseColor;
                }
              }
            }
          }
        }
      }
    }
  }
  .dataSection {
    padding: 20px;
    background: $white;
    max-width: 100%;
    border: 1px solid $gray;
    @include radius(4px);
    margin: 20px auto;
    .dataTabsHead {
      @include flex();
      justify-content: space-between;
      border-bottom: 1px solid $gray;
      width: 100%;
      padding: 0 20px;
      .item {
        width: calc(100% / 8);
        background: $bgLightGray;
        @include radius(4px);
        @include shadow(0, 3px, 5px, #0000001a);
        margin: 10px 0 30px;
        a{
          display: block;
          padding:10px;
          color: $black;
          &:hover{
            text-decoration: none;
            span {
              color: $baseColor;
            }
          }
        }
        .icon {
          text-align: right;
          svg {
            fill: $black;
            .a { 
              fill: $black; 
            }
          }
        }
        span {
          display: block;
          @include font(15);
          color: $black;
          font-weight: 600;
          margin: 0 0 5px 0;
          text-align: center;
          &.count {
            @include font(18);
            color: $black;
            font-weight: 600;
          }
        }
      }
      .activeCard {
        span {
          color: $baseColor !important;
        }
      }
    }
    .sortFilter {
      padding: 20px 0; 
      form {
        @include flex();
        align-items: center;
        label {
          display: inline-flex;
          @include font(14);
          color: $black;
          font-weight: 600;
          margin-right: 10px;
          span {
            display: inline-flex;
            &.icon {
              margin-right: 10px;
            }
          }
        }
        select {
          @include font(14);
          color: $black;
          appearance: none;
          background-image: url(../../../../../../../assets/img/arrowdown.png);
          background-repeat: no-repeat;
          background-size: 16px;
          background-position: 95% center;
          padding:0 25px 0 8px;
          outline: none;
          border: 1px solid $gray;
          @include radius(4px);
          min-width: 200px;
          height: 38px;
          line-height: 38px;
        }
      }
    }
    .dataBox {
      //height:550px;
      //overflow-y:auto;
      //overflow-x:hidden;
      //border-top: 0.5px solid #e7e7e7;
      //border-bottom: 0.5px solid #e7e7e7;
      //scroll-behavior:smooth;
      .dataItem {
        padding: 20px 12px;
        border-top: 1px solid $gray;
        background: rgba($white, 0.6);
        .dataFlex {
          @include flex();
          flex-wrap: wrap;
          align-items: flex-start;
          .leftData {
            @include flex();
            align-items: center;
            width: 80%;
            div {
              &.sort {
                padding:20px;
                margin:40px 0 0 -10px;
                cursor: grabbing;
              }
              &.count {
                @include font(18);
                color: $black;
                font-weight: 600;
                margin:40px 30px  0 0;
              }
              &.data {
                width: calc(100% - 60px);
                padding-right: 20px;
                .headFlex {
                  @include flex();
                  div {
                    &.icon {
                      max-width: 50px;
                      margin-right: 10px;
                      img {
                        max-width: 100%;
                        height: auto;
                      }
                    }
                    h5 {
                      @include font(14);
                      color: $blackColor;
                      font-weight: 600;
                      margin: 0 0 5px 0;
                      a {
                        display: inline-block;
                        vertical-align: middle;
                        text-decoration: none;
                        color:#007bff;
                        &:hover {
                          color:#0056b3;
                        }
                      }
                    }
                    p {
                      @include font(12);
                      font-weight: 400;
                      color: $black;
                      line-height: 1.33;
                    }
                    ul {
                      @include flex();
                      li {
                        list-style: none;
                        margin-right: 5px;
                        @include flex();
                        align-items: center;
                        span {
                          display: inline-block;
                          @include font(12);
                          color: $black;
                          &.circle {
                            background: $blackColor;
                            width: 5px;
                            height: 5px;
                            @include radius(50%);
                            margin-left: 5px;
                          }
                        }
                      }
                    }
                  }
                }
                .stepperBox {                  
                  @include flex();
                  justify-content: space-between;
                  margin: 20px 0;
                  .item {
                    position: relative;
                    width: 16.66%;
                    span {
                      display: block;
                      text-align: center;
                      @include font(12);
                      color: $black;
                      &.circle {
                        position: relative;
                        background-color: #e5e5e5;
                        width: 14px;
                        height: 14px;
                        @include radius(50%);
                        margin: 0 auto 5px auto;
                        z-index: 1;
                      }
                    }
                    &::before {
                      content: "";
                      display: inline-block;
                      background-color: #e5e5e5;
                      position: absolute;
                      left: 0;
                      top: 7px;
                      width: 100%;
                      height: 2px;
                    }
                    &.passed {
                      &::before {
                        background-color: $lightBlue;
                      }
                      span {
                        &.circle {
                          background-color: $lightBlue;
                        }
                      }
                    }
                    &.active {
                      &::before {
                        background-color: $lightBlue;
                        width: 50%;
                      }
                      &::after {
                        content: "";
                        display: inline-block;
                        background-color: #e5e5e5;
                        position: absolute;
                        left: 50%;
                        top: 7px;
                        width: 50%;
                        height: 2px;
                      }
                      span {
                        &.circle {
                          background-color: $lightBlue;
                        }
                      }
                    }
                    &:last-child {
                      &.active {
                        &::before {
                          background-color: $lightBlue;
                          width: 100%;
                        }
                        &::after {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .rightData {
            @include flex();
            width: 20%;
            .flexBox {
              width: calc(100% - 180px);
              margin-right: 10px;
              ul {
                margin: 0;
                padding: 0;
                li {
                  list-style: none;
                  @include flex();
                  margin: 0 0 10px 0;
                  label {
                    display: inline-block;
                    @include font(14);
                    color: $black;
                    min-width: 80px;
                    margin-right: 15px;
                  }
                  span {
                    display: inline-block;
                    @include font(14);
                    color: $black;
                    svg {
                      .a {
                        fill: $black;
                        fill-rule: evenodd;
                      }
                    }
                  }
                }
              }
            }
            .actionBox {
              @include flex();
              flex-direction: column;
              justify-content: space-between;
              margin-left: auto;
              .actionFlexBox {
                ul {
                  @include flex();
                  justify-content: space-between;
                  margin: 0;
                  li {
                    list-style: none;
                    @include flex();
                    align-items: center;
                    padding:0 6px;
                    position: relative;
                    &:last-of-type{
                      padding-right: 0;
                    }
                    button {
                      border: none;
                      background: transparent;
                      box-shadow: none;
                      outline: none;
                      padding: 0;
                      cursor: pointer;
                      @include transition();
                      &:hover{
                        opacity: .7;
                      }
                    }
                    a {
                      text-decoration: none;
                      display: inline-block;
                      padding: 0 5px;
                      @include transition();
                      &:hover{
                        opacity: .7;
                      }
                      svg{
                        .a{
                          fill:#59a7ff;
                        }
                        .b{
                          fill:#0082ff;
                        }
                      }
                    }
                    &.show {
                      position: relative;
                      .dropdown-menu {
                        min-width: 100px;
                        transform: none !important;
                        right: 0;
                        left: auto !important;
                        top: 39px !important;
                        @include shadow(0, 3px, 11px, #00000029);
                        border: none;
                        padding: 0;
                        a {
                          @include font(14);
                          color: $black;
                          padding: 10px;
                          @include transition();
                          &:hover{
                            @include scale(1);
                          }
                        }
                      }
                    }

                    &:last-child {
                      border-right: none;
                    }
                  }
                }
              }
              .badgeBox {
                text-align: center;
                a {
                  display: inline-block;
                  text-decoration: none;
                  cursor: pointer;
                  svg {
                    .a {
                      fill: $orange;
                    }
                  }
                }
                &.hollow {
                  a {
                    svg {
                      .a {
                        fill: none;
                        stroke: $orange;
                      }
                    }
                  }
                }
              }
            }
          }
          .UpdateFlexBox {
            @include flex();
            align-items: center;
            margin-left: 90px;
            li {
              @include flex();
              align-items: center;
              list-style: none;
              background: #00b9ff0d;
              padding: 5px 10px;
              @include font(12);
              @include radius(2px);
              margin-right: 10px;
              label {
                display: inline-block;
                line-height: 22px;
                margin-right: 5px;
              }
              select{
                background: none;
                padding: 0 5px;
                width: auto;
                border:0;
              }
              span {
                display: inline-block;
                line-height: 22px;
              }
            }
          }
        }
        .collapseBox {
          margin-top: 20px;
          .btn {
            @include flex();
            align-items: center;
            justify-content: space-between;
            background: #e7e7e7;
            width: 100%;
            h5 {
              @include font(14);
              font-weight: 600;
              color: $blackColor;
            }
            .closeIcon {
              @include rotate(90deg);
              transition: all 0.3s ease-in-out;
              svg {
                fill: rgba(0, 0, 0, 0.5);
                .a {
                  fill: rgba(0, 0, 0, 0.5);
                  opacity: 0.5;
                }
                width: 10px;
                height: auto;
              }
            }
            &.expand {
              @include radius(4px 4px 0 0);
              .closeIcon {
                @include transform(rotate(90deg) scaleX(-1));
              }
            }
          }
          .collapse {
            .card {
              background: #cccccc26;
              border: none;
              @include radius(0 0 4px 4px);
              padding: 15px;
              .actionOverView {
                .heading {
                  padding-bottom: 15px;
                  @include font(14);
                  font-weight: 600;
                  color: $blackColor;
                  border-bottom: 1px solid $gray;
                }
                .flexBox {
                  ul {
                    @include flex();
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;
                    li {
                      width: 33.3%;
                      margin: 15px 0;
                      label {
                        display: block;
                        @include font(14);
                        font-weight: 600;
                        color: $black;
                        margin: 0 0 10px 0;
                      }
                      span {
                        display: block;
                        @include font(14);
                        font-weight: normal;
                        color: $black;
                      }
                    }
                  }
                }
              }
              .collegeOverView {
                .heading {
                  padding-bottom: 15px;                
                  @include font(14);
                  font-weight: 600;
                  color: $blackColor;
                  border-bottom: 1px solid $gray;
                }
                .flexBox {
                  @include flex();
                  flex-wrap: wrap;
                  justify-content: space-between;
                  .academicData {
                    width: calc(50% - 10px);
                    ul {
                      margin: 0;
                      padding: 0;
                      li {
                        @include flex();
                        align-items: flex-start;
                        list-style: none;
                        margin: 15px 0;
                        label {
                          display: inline-block;
                          @include font(14);
                          color: $black;
                          font-weight: 600;
                          min-width: 160px;
                          margin: 0 10px 0 0;
                        }
                        span {
                          display: inline-block;
                          @include font(14);
                          font-weight: normal;
                          color: $black;
                        }
                      }
                    }
                  }
                  .tutionData {
                    width: calc(50% - 10px);
                    ul {
                      margin: 0;
                      padding: 0;
                      li {
                        list-style: none;
                        @include flex();
                        align-items: flex-start;
                        margin: 15px 0;
                        label {
                          display: inline-block;
                          @include font(14);
                          font-weight: 600;
                          color: $black;
                          min-width: 120px;
                          margin: 0 10px 0 0;
                        }
                        span {
                          display: inline-block;
                          @include font(14);
                          font-weight: normal;
                          color: $black;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .blankList {
        background: rgba($lightBlue,.1%);
        padding:1.5em;
        text-align: center;
        @include radius(8px);
        margin: 1.25em 0 0;
        h2 {
          padding: 1em 1.6em 1.6em;
          @include font (18);
          text-align: center;
        }
        a {
          display: inline-block;
          text-decoration: none;
          background: $lightBlue;
          text-decoration: none;
          padding: 10px 2em;
          width: auto;
          @include font (14);
          color: $white;
          height: 42px;
          &:focus {
            outline: none !important;
            border:0 !important;
          }
          &.searchCollege {
            text-transform: uppercase;
            height: calc(100% - 20px);
            background: $baseColor;
            @include radius(5px);
            margin: 10px 0;
            margin-left: 10px;
          }
        }
      }
    }
    //.dataBox::-webkit-scrollbar{
    //  display: none;
    //}
    .paginationBox {
      @include flex();
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      max-width: 100%;
      border-top: 1px solid $gray;
      padding: 20px 0 0 0;
      div {
        &.perPage {
          @include flex();
          align-items: center;
          width: 130px;
          input {
            padding: 5px;
            @include font(14);
            color: $black;
            width: 55px;
            height: 26px;
            text-align: center;
            border: 1px solid $gray;
            @include radius(3px);
            outline: none;
            box-shadow: none;
          }
          label {
            display: inline-flex;
            @include font(14);
            color: $black;
            margin-left: 10px;
          }
        }
        &.pageNav {
          width: calc(100% - 260px);
          text-align: center;
          ul {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            width: auto;
            border: 1px solid $gray;
            @include radius(3px);
            margin: 0;
            padding: 0;
            li {
              @include flex();
              align-items: center;
              justify-content: center;
              list-style: none;
              width: 32px;
              height: 26px;
              border-right: 1px solid $gray;
              a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                @include font(14);
                color: #626262;
                width: 32px;
                height: 26px;
                cursor: pointer;
                &:hover {
                  background-color: $lightBlue;
                  color: $white;
                  svg {
                    fill: $white;
                  }
                }
              }
              &.backLink {
                svg {
                  width: 10px;
                  height: auto;
                  fill: #626262;
                  transform: rotate(180deg);
                }
              }
              &.forLink {
                svg {
                  width: 10px;
                  height: auto;
                  fill: #626262;
                }
              }
              &:last-child {
                border-right: none;
              }
            }
            .disabled {
              a {
                &:hover {
                  background-color: $white;
                  color: #626262;
                  svg {
                    fill: #626262;
                  }
                }
              }
            }
            .active {
              a {
                background-color: $lightBlue;
                color: $white;
                svg {
                  fill: $white;
                }
              }
            }
          }
        }
        &.goBox {
          @include flex();
          align-items: center;
          justify-content: flex-end;
          width: 130px;
          input {
            padding: 5px;
            width: 55px;
            height: 26px;
            @include font(14);
            color: $black;
            border: 1px solid $gray;
            outline: none;
            box-shadow: none;
            text-align: center;
            @include radius(3px);
          }
          a {
            display: inline-flex;
            align-items: center;
            @include font(14);
            color: $black;
            padding: 5px;
            margin-left: 10px;
            text-decoration: none;
            cursor: pointer;
            span {
              display: inline-flex;
              &.icon {
                margin-left: 5px;
                svg {
                  fill: $black;
                  .a {
                    opacity: 1;
                    fill: $black;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.dropAreaMyClg {
  position: relative;
}
.dropAreaMyClg::before {
  content: '';
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}
/************************ headerSearchModal css***************/
.modal {
  &.headerSearchModal {
    .modal-dialog {
      width: 100%;
      max-width: 100% !important;
      margin: 0;
      .modal-content {
        flex-direction: row;
        padding: 15px;
        border: none;
        .modal-body {
          padding: 0 10px 0 0;
          .flexBox {
            @include flex();
            height: 100%;
            padding: 5px 15px;
            border: 1px solid $gray;
            @include radius(30px);
            input {
              width: calc(100% - 30px);
              height: 100%;
              border: none;
              outline: none;
            }
            .searchIcon {
              @include flex();
              align-items: center;
              justify-content: center;
              width: 30px;
              min-width: 30px;
              a {
                display: inline-flex;
                cursor: pointer;
                svg {
                  width: 20px;
                  height: auto;
                  .a {
                    opacity: 0.3;
                  }
                  .b {
                    fill: none;
                    stroke: $blackColor;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }
                }
              }
            }
          }
        }
        .modal-footer {
          border-top: none;
          padding: 0;
          .btn {
            background: transparent;
            padding: 5px;
            svg {
              width: 20px;
              height: auto;
              fill: $black;
            }
          }
        }
      }
    }
  }
  &.commitment-modal {
    .modal-dialog {
        max-width: 320px;
    }
    .modal-header {
        padding: 10px 15px;
        .close {
            @include font(30);
            font-weight: 300;
            color: $black;
            line-height: 20px;
            outline: none;
        }
    }
    .modal-body {
        padding: 20px 20px 20px;
        text-align: center;
        .img-box {
            max-width:100px;
            margin: 0 auto 15px;
            img {
              display: block;
              margin: 0 auto;
              max-width: 100%;
            }
        }
        h3 {
            @include font(18);
            font-weight: 500;
            color: $blackColor;
            margin-bottom: 10px;
        }
        p {
          @include font(13);
          font-weight: 400;
          color: $black;
          line-height: 1.33;
        }
        ul {
            margin-bottom: 12px;
            li {
                display: inline-block;
                vertical-align: middle;
                @include font(14);
                font-weight: 400;
                color: $black;
                position: relative;
                padding-left: 18px;
                margin-bottom: 8px;
                &::after {
                    content: "";
                    position: absolute;
                    background: $black;
                    width: 5px;
                    height: 5px;
                    left: 5px;
                    top: 5px;
                    @include radius(50%);
                    z-index: 1;
                }
                &:first-child {
                    padding-left: 0;
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .add-commitment-button {
          margin-top: 12px;
            a {
              display: inline-block;
              vertical-align: middle;
              text-decoration: none;
              padding: 10px 15px;
              @include font(16);
              color: $lightBlue;
              border: 1px solid $lightBlue;
              @include radius(5px);
              &:hover {
                text-decoration: none;
                background: $lightBlue;
                color: $white;
              }
            }
        }
    }
    .modal-footer {
        justify-content: center;
        border-top: none;
        padding-top: 0;
        padding-bottom: 30px;
        .btn {
            @include font(14);
            font-weight: 400;
            padding: 12px 20px;
            min-width: 100px;
        }
    }
  }

  &.unfavourite-progress-modal {
    .modal-dialog {
      max-width: 478px;
      margin-left: auto;
      margin-right: auto;
    }
    .modal-content {
      padding: 25px 40px;
      border: none;
      position: relative;
    }
    .modal-header {
      padding: 0 35px 10px 0;
      position: relative;
      border-bottom: 1px solid $border;
      margin-bottom: 20px;
      .modal-title {
        @include font(16);
        font-weight: 600;
        color: $black;
      }
    }
    .close {
      padding: 0;
      @include font(32);
      font-weight: 300;
      color: $black;
      outline: none;
      position: absolute;
      right: 15px;
      top: 20px;
      line-height: 15px;
      margin: 0;
      span {
        display: inline-block;
        vertical-align: middle;
        line-height: 15px;
      }
    }
    .modal-body {
      padding: 0 0 20px;
      p {
        @include font(16);
        font-weight: 400;
        color: rgba($blackColor, 0.64%);
        line-height: 26px;
        text-align: center;
        margin-bottom: 0;
        .icon {
          display: inline-block;
          vertical-align: middle;
          svg {
            display: inline-block;
            vertical-align: middle;
            width: 30px;
            height: 30px;
            .a {
              fill: #ffffff;
              stroke: #c0321f;
              stroke-width: 1.5px;
            }
          }
        }
      }
    }
    .modal-footer {
      padding: 0;
      border: none;
      justify-content: center;
      text-align: center;
      .btn {
        background: $lightBlue;
        padding: 8px 15px;
        @include font(15);
        font-weight: 500;
        color: $white;
        min-width: 80px;
        margin: 0;
      }
    }
  }
}
/***************************************/
@media screen and (min-width: 1700px) {
  .collegeListing {
    > .container {
      max-width: 1600px;
    }
  }
}
@media screen and (max-width: 1199px) {
  .collegeListing {
    .dataSection {
      .dataBox {
        .dataItem {
          .dataFlex {
            .leftData {
              width: 70%;
            }
            .rightData {
              width: 30%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .collegeListing {
    .dataSection {
      .dataTabsHead {
        padding:0;
        flex-wrap: wrap;
        .item {
          min-width: auto;
          max-width: 100%;
          width: calc(33.3% - 30px);
        }
      }
      .dataBox {
        .dataItem {
          .dataFlex {
            .leftData {
              width: 100%;
              flex-wrap: wrap;
              position: relative;
              div {
                &.sort {
                  display: block;
                  padding:0;
                  margin: 0;
                  position: absolute;
                  left: -10px;
                  top: 0;
                  svg {
                    width: 40px!important;
                  }
                }
                &.count {
                  position: absolute;
                  left: 0;
                  top: 10px;
                }
                &.data {
                  width: 100%;
                  padding-right: 0;
                  .headFlex {
                    width: calc(100% - 40px);
                    margin-left: auto;
                  }
                }
              }
            }
            .rightData {
              width: 100%;
              margin-top: 20px;
            }
            .UpdateFlexBox {
              margin-left: 0;
              li {
                flex-wrap: wrap;
                padding: 5px 5px;
                label {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
  .collegeListing{
    .headerFlex {
      .tabs {
        width: calc(100% - 50px);
        ul{
          display: none;
          li {
            a {
              padding: 5px 0;
              @include font(16);
            }
          }
        }
        .mobileMenuBox{
          display: block;
          div {
            &.mobileMenu {
              ul {
                li {
                  margin-bottom: 10px;
                  a {
                    span {
                      .icon {
                        margin-left: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
          .menuBtn{
            display: inline-block;
            button{
              &.btn{
                margin: 8px 10px;
              }
            }
            & + .label{
              display: inline-block;
            }
          }
        }
      }
      .prefer{
        a{
          display: -ms-box;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 10px;
          width: 40px;
          height: 40px;
          span{
            margin-right: 0 !important;
            &:not(.icon){
              display: none;
            }
          }
        }
      }
    }
    .dataSection{
      .dataBox {
        .dataItem {
          .dataFlex {
            .leftData {
              flex-wrap: wrap;
              position: relative;
              width: 100%;
              div {
                &.sort {
                  display: block;
                }
                &.count {
                  position: absolute;
                  left: 0;
                  top: 10px;
                }
                &.data {
                  width: 100%;
                  padding-right: 0;
                  .headFlex {
                    width: calc(100% - 40px);
                    margin-left: auto;
                  }
                }
              }
            }
            .rightData {
              width: 100%;
              margin-top: 15px;
              .actionBox {
                margin-top: 0;
              }
            }
            .UpdateFlexBox {
              padding-top: 20px;
              margin-left: 0;
              li {
                flex-wrap: wrap;
                padding: 5px;
                label {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
      .dataTabsHead {
        .item {
          span {
            @include font(13);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .collegeListing {
    .headerFlex {
      .tabs {
        ul {
          display: none;
        }
        .mobileMenuBox {
          @include flex();
        }
      }
      .prefer {
        a {
          padding: 12px 10px;
        }
      }
    }
    .dataSection {
      .dataBox {
        .dataItem {
          .collapseBox {
            .collapse {
              .card {
                .collegeOverView {
                  .flexBox {
                    .academicData {
                      width: 100%;
                      ul {
                        li {
                          label {
                            min-width: 90px;
                            max-width: 90px;
                            margin-right: 40px;
                          }
                          &:last-child {
                            margin-bottom: 0;
                          }
                        }
                      }
                    }
                    .tutionData {
                      width: 100%;
                      ul {
                        li {
                          label {
                            min-width: 90px;
                            max-width: 90px;
                            margin-right: 40px;
                          }
                          &:last-child {
                            margin-bottom: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .paginationBox {
        div {
          &.perPage {
            order: 1;
          }
          &.goBox {
            order: 2;
            margin-left: auto;
          }
          &.pageNav {
            order: 3;
            width: 100%;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .collegeListing{
    .dataSection{
      padding: 15px 15px 20px;
      .dataTabsHead{
        flex-wrap: wrap;
        padding:0 0 1em;
        .item{
          width:calc(33.333% - 10px);
          margin: 5px 0;
        }
      }
    }
  }
}
@media screen and (max-width:575px) {
  .collegeListing {
    .headerFlex {
      .tabs {
        width: calc(100% - 55px);
        .mobileMenuBox {
          div {
            &.menuBtn {
              .btn {
                margin: 9px 10px;
              }
            }
          }
        }
      }
      .prefer {
        a {
          padding: 10px;
          min-width: 42px;
          width: 42px;
          height: 42px;
          min-height: 42px;
          justify-content: center;
          span {
            display: none;
            &.icon {
              display: inline-flex;
              margin-right: 0;
            }
          }
        }
      }
    }
    .dataSection {
      padding: 10px 15px 15px;
      .dataBox {
        .dataItem {
          .dataFlex {
            .leftData {
              div {
                &.data {
                  .stepperBox {
                    .item {
                      span {
                        @include font(11);
                      }
                    }
                  }
                }
              }
            }
            .rightData {
              flex-wrap: wrap;
              flex-direction: column-reverse;
              margin-top: 0;
              .flexBox {
                width: 100%;
                margin-right: 0;
              }
              .actionBox {
                width: 100%;
                position: relative;
                margin-bottom: 20px;
                .actionFlexBox {
                  width: 100%;
                  ul {
                    justify-content: flex-start;
                    li {
                      border-right: none;
                      margin-right: 10px;
                      padding: 0;
                      > a {
                        width: 40px;
                        height: 40px;
                        @include radius(50%);
                        border: 1px solid $gray;
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        svg {
                          max-width: 30px;
                          height: auto;
                        }
                      }
                      &:last-child {
                        margin-left: auto;
                        margin-right: 0;
                      }
                    }
                  }
                }
                .badgeBox {
                  > a {
                    width: 40px;
                    height: 40px;
                    border: 1px solid $gray;
                    @include radius(50%);
                    display: flex!important;
                    align-items: center;
                    justify-content: center;
                    svg {
                      max-width: 20px;
                      height: auto;
                    }
                  }
                }
              }
            }
          }
          .collapseBox {
            .collapse {
              .card {
                .actionOverView {
                  .flexBox {
                    ul {
                      li {
                        width: 50%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .paginationBox {
        padding-top : 15px;
        div {
          &.perPage {
            input {
              width:40px;
            }
          }
          &.goBox {
            input {
              width:40px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .collegeListing {
    .dataSection {
      padding: 10px;
      .dataTabsHead {
        .item {
          min-width: auto;
          max-width: 100%;
          width: calc(33.3% - 15px);
        }
      }
      .sortFilter {
        form {
          label {
            white-space: nowrap;
          }
          select {
            min-width: auto;
            width: calc(100% - 82px);
            max-width: 200px;
          }
        }
      }
      .dataBox {
        .dataItem {
          .dataFlex {
            .UpdateFlexBox {
              display: block;
              width: 100%;
              li {
                width: 100%;
                margin-right: 0;
                margin-bottom: 10px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
            .leftData {
              div {
                &.data {
                  .stepperBox {
                    .item {
                      span {
                        @include font(10);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .collapseBox {
          .collapse {
            .card {
              .actionOverView {
                .flexBox {
                  ul {
                    li {
                      &:nth-child(1) {
                        order: 1;
                      }
                      &:nth-child(2) {
                        order: 2;
                      }
                      &:nth-child(3) {
                        order: 5;
                        width: 100%;
                      }
                      &:nth-child(4) {
                        order: 3;
                      }
                      &:nth-child(5) {
                        order: 4;
                      }
                      &:nth-child(6) {
                        order: 6;
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
