@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";

.welcomeModal {
    position: fixed;
    // width: 100%;
    // height: 100%;
    left: 50%;
    top: 50%;
    // right: 0;
    // bottom: 0;
    // background-color: rgba(0, 0, 0, 0.5);
    z-index: 1090;
    display: block;
    overflow-y: auto;
    @include flex();
    justify-content: center;
    align-items: center;
    transform: translate(-50%,-50%);

    @media screen and (max-width: 576px){
        width: 95%;
    }

    .modalWrapper {
        display: block;
        overflow-y: auto;
        width: 100%;
        height: 100%;
    }

    .modalContent {
        background: $white;
        @include shadow(0px, 6px, 15px, rgba($black, 30%));
        @include radius(5px);
        max-width: 480px;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        padding: 30px;
        // top: 50%;
        // @include translateY(-50%);
        .owl-item {
            position: relative;
            &.active {
                z-index: 99;
            }
        }
        button {
            &.cross {
                position: absolute;
                background: none;
                margin: 0;
                padding: .75em;
                @include radius(100%);
                border: 0;
                right: 20px;
                top: 20px;
                z-index: 2;
                opacity: .5;
                @include transition();

                &:hover {
                    background: #f1f1f1;
                    opacity: 1;
                }
            }
            &.btn-secondary {
                background: $lightBlue;
                position: relative;
                z-index: 7;
                margin-bottom: 20px;
            }
            &:focus {
                outline: 0px;
            }
        }
        .title {
            @include font(20);
            font-weight: 600;
            text-align: center;
            max-width: 300px;
            line-height: 1.4;
            margin: 1.25em auto;
        }
        p {
            text-align: center;
            @include font(14);
            line-height: 1.7;
            padding: 0 1em;
            &:not(:last-of-type) {
                margin-bottom: 1em;
            }
        }
        .graphics {
            padding: 2em;
        }
        ul {
            max-width: 340px;
            margin: 0 auto;
            padding: 1.5em 0;
        }
        li {
            @include flex();
            align-items: center;
            padding: 1em;
            @include font(14);
            line-height: 1.7;
            &:not(:last-of-type) {
                border-bottom: 1px solid $border;
            }
            span {
                margin-right: 1em;
                img {
                    height: 40px;
                }
            }
        }
        .item {
            text-align: center;

            img {
                max-height: 200px;
            }
        }
    }
    .owl-carousel {
        .owl-stage-outer {
            z-index: 99;
        }
        .owl-nav {
            position: absolute;
            z-index: 0;
            right: -30px;
            left: -30px;
            bottom: 0;
            @include flex();
            justify-content: space-between;
            button {
                background: $lightBlue;
                @include transition();
                span {
                    background: url('../img/next.svg') center no-repeat;
                    display: block;
                    @include font(0);
                    width: 52px;
                    height: 46px;
                }
                &.owl-prev {
                    opacity: 0;
                    visibility: hidden;
                    @include radius(0 26px 26px 0);
                    span {
                        @include rotate(180deg)
                    }
                    &.active {
                        opacity: 1;
                        visibility: visible;
                    }
                    &:hover {
                        background: $baseColor;
                        span {
                            animation: moveBack forwards .4s infinite;
                        }
                    }
                }
                &.owl-next {
                    width: 52px;
                    height: 46px;
                    background: $baseColor;
                    @include radius(26px 0 0 26px);

                    &:hover {
                      

                        span {
                            animation: moveNext forwards .4s infinite;
                        }
                    }
                }
                &.disabled {
                    display: none;
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        .owl-dots {
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 20px;
            @include flex();
            justify-content: center;
            z-index: 0;
            @include transition();
            &.hide {
                display: none;
                opacity: 0;
                visibility: hidden;
            }
            button {
                background: $lightBlue;
                width: 22px;
                height: 6px;
                background: rgba($black, .2%);
                @include transition();
                margin: 0 3px;

                &.active {
                    background: $lightBlue;
                    @include radius(3px);
                }
            }
        }
    }
}

@keyframes moveBack {
    0% {
        @include transform(translateX(0px) rotate(180deg));
    }

    50% {
        @include transform(translateX(-4px) rotate(180deg));
    }

    100% {
        @include transform(translateX(0px) rotate(180deg));
    }
}

@keyframes moveNext {
    0% {
        @include translateX(0px);
    }

    50% {
        @include translateX(4px);
    }

    100% {
        @include translateX(0px);
    }
}