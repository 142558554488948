@import  "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";


.onBoardCoachTeamSection{
    min-height: calc(100vh - 120px);
    padding: 20px 15px;
    @include flex();
    justify-content: center;
    margin-top: 0px;
    .coachTeamBlock{
        width: 100%;
        max-width: 420px;
        margin: 0px auto;   
        @include radius(6px);
        .formSection{
            padding: 30px 30px;
            border: 1px solid $gray;
            width: 100%;
            @include radius(6px);
            .heading{
                font-size: 20px;
                font-weight: 600;
                margin: 0px 0px 10px 0px;
                font-weight: 600;
                color:$black;
                @include flex();
                align-items: center;
                span{
                    &.checkIcon{
                        display:inline-flex;
                        margin-left:10px;
                        svg{
                            .a{fill:$lightBlue;}
                            .b{fill:#fafafa;}
                        }
                    }
                }
            }
            .coachSUbHead{
                font-size:14px;
                color:$black;
                margin: 0px 0px 30px 0px;
                font-weight: normal;
            }
            
            .checkbox{
                margin-bottom: 30px;
                > span{
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    input{
                        position: absolute;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        cursor: pointer;
                        &:checked + label{
                           &::before{
                               border-color:$lightBlue ;
                           }
                           &::after{
                            width: 12px;
                            height: 12px;
                          background: url(../../assets/img/checkBlue.png) no-repeat;
                         background-size: cover;
                            content: "";
                            display: inline-block;
                            position: absolute;
                            left: 3px;
                            top: 4px;
                           }
                        }
                    }
                    label{
                        display: inline-block;
                        padding-left: 30px;
                        position: relative;
                        color: $black;
                        cursor: pointer;
                        &::before{
                            width: 18px;
                            height: 18px;
                            border: 1px solid $black;
                            content: "";
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                    }
                }
            }
            .skip{
                text-align: center;
                a{
                    text-decoration: none;
                    display: inline-block;
                    cursor: pointer;
                    color: $baseColor;
                }
            }
            div{
                select{
                    border: 1px solid $gray;
                    @include radius(4px);
                    outline: none;
                    width: 100%;
                    padding: 8px 10px;
                    color: $black;
                    -webkit-appearance: none;
                    background-image:url(../../assets/img/arrowdown.png) ;
                    background-repeat: no-repeat;
                    background-position: 95% center;
                    background-size: 16px;
                   font-size: 14px;
                   line-height: 20px;
                }
                input{
                    outline: none;
                    border: 1px solid $gray;
                    @include radius(4px);
                    padding: 8px 10px;
                    width: 100%;
                    color: $black;
                    font-size: 14px;
                    &::placeholder{
                        color: $black;
                    }
                }
                &.coachFormSeciton{
                    form{
                        .fieldBox{
                            margin-bottom: 25px;
                            label{
                                font-size: 16px;
                                margin: 0px 0px 10px 0px;
                                display: block;
                                color: $black;
                                font-weight: 600;
                                line-height: 22px;
                            }
                        
                           .flexBox{
                               @include flex();
                               align-items: center;
                               border:1px solid $gray;
                               @include radius(4px);
                              .flag{
                                  width: 50px;
                                  min-width: 50px;
                                  padding: 0px 4px 0px 10px; 
                                  img{
                                      max-width: 100%;
                                      height: auto;
                                  }
                              }
                              select{
                                  outline: none;
                                  border: none;
                                  width: 60px;
                                  min-width: 60px;
                                
                                  background-size: 10px;
                                  @include radius(0px);
                              }
                              input{
                                  width: calc(100% - 110px);
                                  border: none;
                                  @include radius(4px);
                                  
                              }
                           }
                        }
                    }
                }
                &.teamFormSection{
                   
                    .subHead{
                        margin: 0px 0px 20px 0px;
                        color: $black;
                        font-weight: normal;
                        font-size: 14px;
                    }
                    div{
                        &.fieldBox{
                            margin-bottom: 25px;
                            label{
                                font-size: 16px;
                                margin: 0px 0px 10px 0px;
                                display: block;
                                color: $black;
                                font-weight: 600;
                                line-height: 22px;
                            }
                            .radioFlexBox{
                                @include flex();
                                align-items: center;
                                > div{
                                    margin-right: 20px;
                                    span{
                                        @include flex();
                                        align-items: center;
                                        position: relative;
                                        cursor: pointer;
                                        input{
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            width: 100%;
                                            height: 100%;
                                            cursor: pointer;
                                            z-index: 1;
                                            opacity: 0;
                                            &:checked + label{
                                                &::before{
                                                    border-color: lightBlue;
                                                }
                                                &::after{
                                                    content: "";
                                                    display: inline-block;
                                                    width: 8px;
                                                    height: 8px;
                                                    @include radius(50%);
                                                    position: absolute;
                                                    left: 5px;
                                                    top: 5px;
                                                    background-color: $lightBlue;
                                                }
                                            }
                                        }
                                        label{
                                            font-size: 16px;
                                            font-weight: normal;
                                            color: $black;
                                            line-height: 22px;
                                            margin: 0px 0px;
                                            @include flex();
                                            position: relative;
                                            padding-left: 30px;
                                            &::before{
                                                content: "";
                                                display: inline-block;
                                                position: absolute;
                                                left: 0;
                                                top: 0;
                                                width: 18px;
                                                height: 18px;
                                                @include radius(50%);
                                                border: 1px solid #707070;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .continueBtnBox{
            text-align: center;
            margin-top: 35px;
            a{
                outline: none;
                box-shadow: none;
                cursor: pointer;
                background-color: $green;
                color: $white;
                padding: 10px 15px;
                @include radius(4px);
                border: none;
                font-size: 16px;
                min-width: 100px;
                min-height: 45px;
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width:479px) {
    .onBoardCoachTeamSection{
        .coachTeamBlock{
            .formSection{
                padding: 20px 20px;
            }
            .continueBtnBox{
                a{
                    width: 100%;
                }
            }
        }
    }  
}