@import "../../../../../../assets/css/_mixin.scss";
@import "../../../../../../assets/css/_reset.scss";
@import "../../../../../../assets/css/_variables.scss";

header.claimProfileHeader2 {
    &.dashboardLogo {
      @include flex();
      align-items: center;
      background-color: #333;
      padding: 0 15px;
      height: 60px;
      position: sticky;
      left: 0;
      top: 0;
      z-index: 1;
      .logoPart {
        padding-left: 100px;
        a {
          cursor: pointer;
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  @import "../../../../../../assets/css/_mixin.scss";
  @import "../../../../../../assets/css/_reset.scss";
  @import "../../../../../../assets/css/_variables.scss";
  
  /******************************************************/
  header.claimProfileHeader2 {
    &.dashboardLogo {
      display: flex;
      justify-content: center;
      background-color:black;
      @include flex();
      display: block;
      padding: 0 30px;
      position: fixed;
      left: 0;
      width: 100%;
      top: 0;
      z-index: 99;
      div {
        &.logoP {
          @include flex();
          align-items: center;
          opacity: 0.6;
          a {
            text-decoration: none;
            display: inline-flex;
            cursor: pointer;
            img {
              max-width: 100%;
              height: auto;
              &.mobile {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  footer{
    &.claimProfileStep2Footer{
      background:$blackColor;
      float: left;
      width: 100%;
      padding: 20px;
      .navBar{
        text-align: center;
      }
      ul{
        margin: 0 auto;
        display: inline-block;
        li{
          float: left;
          padding: 0 15px;
          a{
            color: rgba(255, 255, 255, .45);
          }
        }
      }
    }
  }