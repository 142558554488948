@import "../../../../../../../../assets/css/variables";
@import "../../../../../../../../assets/css/mixin";
@import "../../../../../../../../assets/css/reset";

.pageContent {
  padding: 25px 0;
}
.coach-notification-setting {
  .notification-wrap {
    
    background: $white;
    padding: 20px 25px 30px;
    max-width: 1080px;
    margin: 0 auto;
    @include radius(4px);
    box-shadow: 0 5px 15px #0000000d;
    .title-box {
      @include flex();
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      margin-bottom: 22px;
      border-bottom: 1px solid $gray;
      .item {
        .title {
          @include font(18);
          font-weight: 600;
          color: $blackColor;
        }
      }
      .form-check {
        &.form-switch {
          .form-check-input {
            background-image: none;
            background: $green;
            width: 32px;
            height: 18px;
            border-width: 0;
            @include radius(50px);
            border: none;
            margin-left: 0;
            appearance: none;
            position: relative;
            @include transition();
            cursor: pointer;
            margin-top: 0;
            &:after {
              content: "";
              background: $white;
              position: absolute;
              width: 13px;
              height: 13px;
              left: 2px;
              top: 2.1px;
              border: none;
              z-index: 2;
              border-radius: 50%;
              @include transition();
            }
          }
          input:checked[type="checkbox"] {
            background: $green;
            &:after {
              left: auto !important;
              right: 2px !important;
              background: $white;
            }
          }
        }
      }
    }
    .notification-list-row {
      .notification-item {
          margin-bottom: 35px;
          &:last-child {
            margin-bottom: 0;
          }
          h4 {
            @include font(15);
            font-weight: 400;
            color: $lightBlue;
            margin-bottom: 15px;
          }
        .flexbox-row {
          @include flex();
          justify-content: space-between;
          align-items: center;
          @include font(14);
          font-weight: 400;
          line-height: 1.3;
          color: #666666;
          margin-bottom: 25px;
          &:last-child {
            margin-bottom: 0;
          }
          .radioflexBox {
            @include flex();
            align-items: center;
            justify-content: flex-end;
            padding-left: 20px;
            .option {
              @include flex();
              align-items: center;
              position: relative;
              margin-right: 20px;
              margin-bottom: 0;
              &:last-child {
                margin-right: 0;
              }
              input {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 1;
                cursor: pointer;
                opacity: 0;
                &:checked+label {
                  &::before {
                    border-color: $lightBlue;
                  }
                  &::after {
                    content: "";
                    display: inline-block;
                    background-color: $lightBlue;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    left: 4px;
                    top: 4px;
                    @include radius(50%);
                  }
                }
              }
              label {
                position: relative;
                @include font(14);
                font-weight: 300;
                color: $blackColor;
                padding-left: 25px;
                margin-bottom: 0;
                cursor: pointer;
                &::before {
                  content: "";
                  position: absolute;
                  width: 16px;
                  height: 16px;
                  left: 0;
                  top: 0;
                  border: 1px solid #707070;
                  @include radius(50%);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .coach-notification-setting {
    .notification-wrap {
      .notification-list-row {
        .notification-item {
          .flexbox-row {
            li {
              width: 50%;
            }
            .radioflexBox {
              padding-left: 10px;
              .option {
                margin-right: 15px;
                label {
                  @include font(13);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .coach-notification-setting {
    .notification-wrap {
      padding: 20px 20px 25px;
      .notification-list-row {
        .notification-item {
          margin-bottom: 25px;
          .flexbox-row {
            display: block;
            margin-bottom: 15px;
            li {
              width: 100%;
              margin-bottom: 12px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .radioflexBox {
              justify-content: start;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:639px) {
  .coach-notification-setting {
    .notification-wrap {
      padding-left: 15px;
      padding-right: 15px;
      .title-box {
        .item {
          .title {
            @include font(16);
          }
        }
      }
      .notification-list-row {
        .notification-item {
          .flexbox-row {
            .radioflexBox {
              .option {
                label {
                  @include font(14);
                }
              }
            }
          }
        }
      }
    }
  }
}