.text-field {
    width: 100%;
    background: white;
    .MuiInputBase-root {
        .MuiOutlinedInput-notchedOutline{
            border: 1px solid #dddddd;
        }
      }
    .MuiInputBase-root:hover {
      .MuiOutlinedInput-notchedOutline{
        border: 1px solid #dddddd;
      }
    }
    .MuiOutlinedInput-root.Mui-focused{
      .MuiOutlinedInput-notchedOutline{
        border: 1px solid #dddddd;
      }
    }
  }