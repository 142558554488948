/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

button {
  cursor: pointer;
  outline: none;
}

button:hover, button:focus {
  outline: none;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.MuiButtonBase-root {
  outline: none !important;
}

.modal iframe {
  width: 100% !important;
}
.notification-main-wrapper {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.notification-main-wrapper .notification-block-left {
  width: 260px;
  background: #ffffff;
  -ms-flex-item-align: start;
      align-self: flex-start;
  border: 1px solid #00B9FF;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  text-align: center;
}

.notification-main-wrapper .notification-block-left .notification-box {
  padding: 20px 15px;
}

.notification-main-wrapper .notification-block-left .notification-box .notification-val-icon {
  margin-bottom: 8px;
}

.notification-main-wrapper .notification-block-left .notification-box h3 {
  font-size: 1rem;
  font-weight: 500;
  color: #3e3e3e;
  margin-bottom: 5px;
}

.notification-main-wrapper .notification-block-left .notification-box p {
  font-size: 0.8125rem;
  margin-bottom: 6px;
  line-height: 1.3;
}

.notification-main-wrapper .notification-block-left .notification-box p:last-child {
  margin-bottom: 0;
}

.notification-main-wrapper .notification-block-left .notification-box p a {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  color: #00B9FF;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-main-wrapper .notification-block-left .notification-box p a:hover {
  color: #c0321f;
}

.notification-main-wrapper .notification-block-left .notification-box.second {
  background: rgba(0, 185, 255, 0.05);
}

.notification-main-wrapper .notification-block-right {
  width: calc(100% - 280px);
}

.notification-main-wrapper .notification-block-right .notification-detail-box {
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 18px 15px;
  border-bottom: 1px solid #ccc;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item:nth-child(1) {
  background: rgba(0, 185, 255, 0.05);
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item:nth-child(2) {
  background: rgba(0, 185, 255, 0.05);
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item:nth-child(3) {
  background: rgba(0, 185, 255, 0.05);
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 90%;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-profile {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin-right: 20px;
  border: 1px solid #ddd;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-profile img {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-profile .initial {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: 12px;
  text-align: center;
  margin-left: 3px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box {
  width: calc(100% - 80px);
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .name {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .name span {
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .day-ago-text {
  font-size: 0.8125rem;
  font-weight: 300;
  color: #3e3e3e;
  margin-bottom: 10px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .button-box .btn {
  padding: 5px 15px;
  font-size: 0.875rem;
  border-color: #00B9FF;
  color: #00B9FF;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .button-box .btn .fire-icon {
  display: inline-block;
  vertical-align: -3px;
  margin-left: 5px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .button-box .btn:hover {
  background: #00B9FF;
  color: #ffffff;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .content-with-img {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .content-with-img .img-box {
  width: 90px;
  margin-right: 10px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .content-with-img .text-box {
  font-size: 0.9375rem;
  color: #3e3e3e;
  width: calc(100% - 100px);
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .content-with-img .text-box p {
  margin-bottom: 8px;
  line-height: 1.4;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .content-with-img .text-box p:last-child {
  margin-bottom: 0;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .content-with-img .text-box p a {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  color: #00B9FF;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info {
  display: block;
  width: calc(100% - 90%);
  text-align: right;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li {
  position: relative;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-toggle {
  padding: 1px 6px 6px;
  display: inline;
  vertical-align: middle;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-toggle:after {
  display: none;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li.show .dropdown-toggle {
  background: rgba(204, 204, 204, 0.3);
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu {
  background: #ffffff;
  padding: 15px;
  width: 235px;
  right: 0;
  top: 100% !important;
  left: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
  border: 0;
  -webkit-box-shadow: -2px 3px 11px #00000029;
          box-shadow: -2px 3px 11px #00000029;
  border-radius: 6;
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li {
  margin-bottom: 15px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li:last-child {
  margin-bottom: 0;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  background: none;
  font-size: 0.8125rem;
  color: #3e3e3e;
  white-space: inherit;
  line-height: 1.4;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a .icon-box {
  width: 18px;
  margin-right: 10px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a .icon-box svg {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a .icon-box svg g {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a .icon-box svg path {
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a .text-box {
  margin-top: -4px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a .text-box strong {
  display: block;
  font-size: 0.9375rem;
  font-weight: 500;
  margin-bottom: 2px;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a:hover {
  color: #c0321f;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a:hover svg g {
  opacity: 1;
}

.notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info .dropdown li .dropdown-menu li a:hover svg path {
  stroke: #c0321f;
}

.notification-with-sidebar {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  background: #ffffff;
}

.notification-with-sidebar .notification-sidebar {
  width: 200px;
}

.notification-with-sidebar .notification-sidebar ul li {
  display: block;
  padding: 20px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  cursor: pointer;
}

.notification-with-sidebar .notification-sidebar ul li .link-item {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-decoration: none;
  font-size: 1rem;
  color: rgba(62, 62, 62, 0.5);
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-sidebar ul li .link-item:hover {
  color: #2177D6;
}

.notification-with-sidebar .notification-sidebar ul li.active .link-item {
  color: #2177D6;
}

.notification-with-sidebar .notification-sidebar ul li.button-link {
  padding-top: 30px;
  padding-bottom: 30px;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .btn {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: linear-gradient(115deg, #00B9FF, #19D7CD);
  width: 100%;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .btn:after {
  display: none;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu {
  background: #ffffff;
  padding: 15px;
  min-width: 1px;
  width: auto;
  left: 20px !important;
  right: 20px;
  top: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
  border: 0;
  -webkit-box-shadow: -2px 3px 11px #00000029;
          box-shadow: -2px 3px 11px #00000029;
  border-radius: 6;
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  margin: 0;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu li {
  font-size: 0.9375rem;
  font-weight: 500;
  color: #3e3e3e;
  padding: 0;
  margin-bottom: 12px;
  border: none;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu li:last-child {
  margin-bottom: 0;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu li a {
  display: block;
  padding: 0 5px;
  background: none;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #3e3e3e;
  white-space: inherit;
  line-height: 1.4;
}

.notification-with-sidebar .notification-sidebar ul li.button-link .dropdown-menu li a:hover {
  color: #c0321f;
}

.notification-with-sidebar .notification-detail-box {
  width: calc(100% - 200px);
  border-left: 1px solid rgba(204, 204, 204, 0.4);
}

.notification-with-sidebar .notification-detail-box .notification-search-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 15px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box label {
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
  margin-right: 10px;
  margin-bottom: 0;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box h1 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #3e3e3e;
  margin-right: 10px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner {
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner input {
  padding: 10px 15px 10px 35px;
  width: 100%;
  height: 40px;
  font-size: 0.875rem;
  font-weight: 300;
  color: #ccc;
  border: 1px solid #ccc;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner button {
  display: inline-block;
  vertical-align: middle;
  background: none;
  border: 0;
  outline: none;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0;
  top: 0;
  z-index: 11;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner button svg .a {
  opacity: 0.3;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .search-inner button svg .b {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info input[type="checkbox"]:checked + label::before {
  border-color: #00B9FF;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info input[type="checkbox"]:checked + label::after {
  content: "";
  display: inline-block;
  background: url(../img/checkBlue.png) no-repeat;
  background-size: cover;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3px;
  top: 5px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info label {
  position: relative;
  font-size: 14px;
  color: #3e3e3e;
  font-weight: normal;
  padding-left: 18px;
  cursor: pointer;
  line-height: 18px;
  color: #3e3e3e;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .checkbox-info label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #3e3e3e;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .delete-foreverBtn {
  padding: 6px 8px;
  background: rgba(204, 204, 204, 0.2);
  font-size: 0.875rem;
  font-weight: 400;
  color: #000;
  border: none;
  outline: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  margin-right: 15px;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .EmptyTrashNowBtn {
  background: none;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 400;
  color: #c0321f;
  outline: none;
  border: none;
}

.notification-with-sidebar .notification-detail-box .notification-search-box .search-box .EmptyTrashNowBtn:hover {
  opacity: .8;
}

.notification-with-sidebar .notification-detail-box .noMsg {
  padding: 15px 20px;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .back-link {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
  padding-left: 22px;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(62, 62, 62, 0.8);
}

.notification-with-sidebar .notification-detail-box .notification-pagination .back-link::after {
  content: "<";
  position: absolute;
  font-family: monospace;
  font-size: 1.625rem;
  color: rgba(62, 62, 62, 0.8);
  left: 0;
  top: -7px;
  z-index: 1;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .back-link:hover {
  color: #c0321f;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .back-link:hover::after {
  color: #c0321f;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .PaginationFlexWrap {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .PaginationFlexWrap .PaginationNumberText {
  font-size: 0.9375rem;
  color: #3e3e3e;
  margin-right: 10px;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .PaginationFlexWrap .PaginationNumberText span:first-child {
  padding-right: 5px;
}

.notification-with-sidebar .notification-detail-box .notification-pagination .PaginationFlexWrap .page-link {
  background: none;
  padding: 0;
  border: none;
  color: rgba(0, 0, 0, 0.55);
  line-height: 0;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li {
  margin-right: 10px;
  font-size: 0.9375rem;
  color: #3e3e3e;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li:last-child {
  margin-right: 0;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li button {
  background: transparent;
  font-size: 1.5625rem;
  font-family: monospace;
  color: rgba(0, 0, 0, 0.55);
  border: none;
  outline: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li button:hover {
  color: #c0321f;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info {
  display: block;
  text-align: right;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li {
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-toggle {
  display: block;
  padding: 1px 6px 6px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-toggle img {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-toggle:after {
  display: none;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu {
  padding: 15px;
  background: #ffffff;
  width: auto;
  top: 100% !important;
  right: 0;
  left: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
  border: 0;
  -webkit-box-shadow: -2px 3px 11px #00000029;
          box-shadow: -2px 3px 11px #00000029;
  border-radius: 6;
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li {
  margin-bottom: 15px;
  margin-right: 0;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li:last-child {
  margin-bottom: 0;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li strong {
  font-weight: 500;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  background: none;
  width: 100%;
  padding: 0 0 0 25px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  white-space: inherit;
  line-height: 1.4;
  position: relative;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a .icon-box {
  position: absolute;
  left: 0;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a .icon-box svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a:hover {
  color: #c0321f;
}

.notification-with-sidebar .notification-detail-box .notification-pagination > ul li .notification-dropdown-info .dropdown li .dropdown-menu li a:hover svg path {
  fill: #c0321f;
}

.notification-with-sidebar .notification-detail-box .notification-item {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 25px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  cursor: pointer;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-profile {
 display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45px;
          flex: 0 0 45px;
  width: 45px;
  height: 45px;
  font-size: 15px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin-right: 15px;
  border: 1px solid #ddd;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-profile img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-profile .initial {
  text-align: center;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding-right: 65px;
  width: 100%;
  font-size: 1rem;
  color: rgba(62, 62, 62, 0.6);
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .item:last-child {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  margin-right: 0;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .name {
  margin-bottom: 5px;
  font-weight: 500;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box .subject-line {
  width: 200px;
  margin-right: 10px;
  font-weight: 500;
  line-height: 1.3;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  width: calc(100% - 200px);
  font-size: 0.9375rem;
  line-height: 1.4;
  overflow: hidden;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .button-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 20px;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .button-box .button {
  display: none;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  margin-right: 15px;
  opacity: 0;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .button-box .button:last-child {
  margin-right: 0;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .button-box .button svg path {
  fill: #3e3e3e;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .button-box .button:hover svg path {
  fill: #c0321f;
}

.notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .time-text {
  font-size: 0.8125rem;
  color: #3e3e3e;
  margin-bottom: 12px;
  text-align: right;
}

.notification-with-sidebar .notification-detail-box .notification-item.graybg {
  background: rgba(204, 204, 204, 0.15);
}

.notification-with-sidebar .notification-detail-box .notification-item:hover, .notification-with-sidebar .notification-detail-box .notification-item.active {
  background: rgba(0, 185, 255, 0.06);
}

.notification-with-sidebar .notification-detail-box .notification-item:hover .user-detial-box .button-box .button, .notification-with-sidebar .notification-detail-box .notification-item.active .user-detial-box .button-box .button {
  display: block;
  opacity: 1;
}

.notification-with-sidebar .notification-detail-box .notification-item:hover .button-boxinfo .checkbox-info, .notification-with-sidebar .notification-detail-box .notification-item.active .button-boxinfo .checkbox-info {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
}

.notification-messageDetail .message-detailInfo {
  padding: 20px;
}

.notification-messageDetail .message-detailInfo .subject-lineInfo {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
}

.notification-messageDetail .message-detailInfo .subject-lineInfo h2 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  text-transform: capitalize;
}

.notification-messageDetail .message-detailInfo .subject-lineInfo .trash-button {
  background: none;
  border: none;
}

.notification-messageDetail .message-detailInfo .user-detial-box .msg-reply-trash-button {
  background: none;
  border: none;
  margin-top: 5px;
}

.notification-messageDetail .message-detailInfo .user-detial-box .msg-reply-trash-button svg path {
  fill: #3e3e3e;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-messageDetail .message-detailInfo .user-detial-box .msg-reply-trash-button:hover svg path {
  fill: #c0321f;
}

.notification-messageDetail .message-detailInfo .subject-lineInfo .trash-button svg path {
  fill: #3e3e3e;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.notification-messageDetail .message-detailInfo .subject-lineInfo .trash-button:hover svg path {
  fill: #c0321f;
}

.notification-messageDetail .message-infobox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
}

.notification-messageDetail .message-infobox .user-profile {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40px;
          flex: 0 0 40px;
  width: 40px;
  height: 40px;
  font-size: 15px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin-right: 20px;
  border: 1px solid #ddd;
}

.notification-messageDetail .message-infobox .user-profile img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
}

.notification-messageDetail .message-infobox .user-profile .initial {
  text-align: center;
}

.notification-messageDetail .message-infobox .user-detial-box {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 50px);
  position: relative;
  padding-right: 100px;
}

.notification-messageDetail .message-infobox .user-detial-box .item:last-child {
  position: absolute;
  right: 0;
  top: 2px;
}

.notification-messageDetail .message-infobox .user-detial-box .name {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.notification-messageDetail .message-infobox .user-detial-box .name span {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding: 0 12px;
  margin-left: 5px;
}

.notification-messageDetail .message-infobox .user-detial-box .name span::after {
  content: "(";
  font-family: monospace;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  line-height: 1;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.notification-messageDetail .message-infobox .user-detial-box .name span::before {
  content: ")";
  font-family: monospace;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  line-height: 1;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.notification-messageDetail .message-infobox .user-detial-box .text-box {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3e3e3e;
  line-height: 1.7;
  padding-top: 2px;
  text-align: justify;
}

.notification-messageDetail .message-infobox .user-detial-box .text-box p {
  margin-bottom: 15px;
}

.notification-messageDetail .message-infobox .user-detial-box .text-box p:last-child {
  margin-bottom: 0;
}

.notification-messageDetail .message-infobox .user-detial-box .time-text {
  font-size: 0.8125rem;
  font-weight: 400;
  color: #000;
  text-align: right;
}

.notification-messageDetail .message-hereInfo {
  padding-bottom: 20px;
}

.notification-messageDetail .message-hereInfo .message-infobox {
  padding: 15px;
  border-top: 1px solid rgba(204, 204, 204, 0.4);
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
}

.notification-messageDetail .message-hereInfo .message-infobox .user-profile {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40px;
          flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  overflow: hidden;
  margin-right: 20px;
  border: 1px solid #ddd;
  margin-top: 0px;
}

.notification-messageDetail .message-hereInfo .message-infobox .user-detial-box .item:last-child {
  top: auto;
}

.notification-messageDetail .message-hereInfo .message-infobox .time-text {
  top: auto;
}

.notification-messageDetail .message-hereInfo .message-replyInfo {
  padding: 25px 20px;
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group {
  margin-bottom: 20px;
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group .toName {
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 20px;
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group label {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(62, 62, 62, 0.7);
  margin-bottom: 12px;
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group textarea {
  display: block;
  width: 100%;
  min-height: 180px;
  padding: 18px 15px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #3e3e3e;
  border: 1px solid rgba(204, 204, 204, 0.4);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group textarea::-webkit-input-placeholder {
  color: rgba(62, 62, 62, 0.7);
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group textarea:-ms-input-placeholder {
  color: rgba(62, 62, 62, 0.7);
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group textarea::-ms-input-placeholder {
  color: rgba(62, 62, 62, 0.7);
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group textarea::placeholder {
  color: rgba(62, 62, 62, 0.7);
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group .btn {
  padding: 12px 20px;
  min-width: 100px;
  background: #00B9FF;
  font-size: 0.875rem;
  font-weight: 400;
}

.notification-messageDetail .message-hereInfo .message-replyInfo .form-group:last-child {
  margin-bottom: 0;
}

.notification-messageDetail .message-hereInfo .message-replyInfo.nomessage .form-group textarea {
  font-size: 1rem;
  font-weight: 700;
  color: #ccc;
  line-height: 1.4;
}

.notification-messageDetail .message-hereInfo .message-replyInfo.nomessage .form-group textarea::-webkit-input-placeholder {
  color: #ccc;
}

.notification-messageDetail .message-hereInfo .message-replyInfo.nomessage .form-group textarea:-ms-input-placeholder {
  color: #ccc;
}

.notification-messageDetail .message-hereInfo .message-replyInfo.nomessage .form-group textarea::-ms-input-placeholder {
  color: #ccc;
}

.notification-messageDetail .message-hereInfo .message-replyInfo.nomessage .form-group textarea::placeholder {
  color: #ccc;
}

.notification-messageDetail .message-hereInfo .message-replyInfo.nomessage .form-group .btn {
  background: #ccc;
  font-size: 1rem;
  font-weight: 700;
  color: rgba(62, 62, 62, 0.5);
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group {
  display: block;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group.flexbox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group.flexbox label {
  margin: 0 10px 0 0;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group label {
  font-size: 0.875rem;
  color: #3e3e3e;
  margin-bottom: 12px;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group input {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3e3e3e;
  border: none;
  width: 100%;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group input::-webkit-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group input:-ms-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group input::-ms-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group input::placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url(../img/arrowdown.png);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: right 15px center;
  width: 100%;
  padding: 10px 40px 10px 15px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #3e3e3e;
  border: 1px solid rgba(204, 204, 204, 0.4);
  height: 45px;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group select::-webkit-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group select:-ms-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group select::-ms-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group select::placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .form-group:last-child {
  margin-bottom: 0;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half {
  width: calc(50% - 10px);
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half label {
  margin-bottom: 12px;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half input {
  width: 100%;
  padding: 10px 15px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #3e3e3e;
  border: 1px solid rgba(204, 204, 204, 0.4);
  height: 45px;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half input::-webkit-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half input:-ms-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half input::-ms-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half input::placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url(../img/arrowdown.png);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: right 15px center;
  width: 100%;
  padding: 10px 40px 10px 15px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #3e3e3e;
  border: 1px solid rgba(204, 204, 204, 0.4);
  height: 45px;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half select::-webkit-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half select:-ms-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half select::-ms-input-placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half select::placeholder {
  color: #3e3e3e;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .error {
  padding-top: 15px;
  font-size: 0.875rem;
  font-weight: 400;
  color: red;
  line-height: 1.4;
}

.notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .error span {
  color: red;
}

@media screen and (max-width: 991px) {
  .notification-main-wrapper .notification-block-left {
    width: 230px;
  }
  .notification-main-wrapper .notification-block-right {
    width: calc(100% - 245px);
  }
  .notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .name {
    font-size: 0.875rem;
  }
  .notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .content-with-img .text-box p {
    font-size: 0.8125rem;
  }
  .notification-with-sidebar .notification-sidebar {
    width: 180px;
  }
  .notification-with-sidebar .notification-detail-box {
    width: calc(100% - 180px);
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box {
    padding-left: 15px;
    padding-right: 15px;
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box .search-box label {
    font-size: 1rem;
  }
  .notification-with-sidebar .notification-detail-box .notification-pagination > ul li {
    margin-right: 5px;
  }
  .notification-with-sidebar .notification-detail-box .notification-pagination > ul li button {
    padding: 0;
  }
  .notification-with-sidebar .notification-detail-box .notification-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 70px;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item {
    width: 22px;
    margin-right: 15px;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item:first-child {
    margin-right: 10px;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .button {
    background: none;
    outline: none;
    border: none;
    padding: 0 5px;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info input[type="checkbox"]:checked + label::before {
    border-color: #00B9FF;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info input[type="checkbox"]:checked + label::after {
    content: "";
    display: inline-block;
    background: url(../img/checkBlue.png) no-repeat;
    background-size: cover;
    position: absolute;
    width: 12px;
    height: 12px;
    left: 3px;
    top: 5px;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info label {
    position: relative;
    font-size: 14px;
    color: #3E3E3E;
    font-weight: normal;
    padding-left: 18px;
    cursor: pointer;
    line-height: 18px;
    color: #3E3E3E;
  }
  
  .notification-with-sidebar .notification-detail-box .notification-item .button-boxinfo .item .checkbox-info label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #3E3E3E;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box {
    font-size: 0.9375rem;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box {
    display: block;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box .subject-line {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box p {
    font-size: 0.8125rem;
    width: 100%;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .item:last-child {
    top: 22px;
  }
  .notification-messageDetail .message-infobox .user-profile {
    margin-right: 12px;
  }
  .notification-messageDetail .message-infobox .user-detial-box .name span {
    margin-left: 0;
  }
  .notification-messageDetail .message-infobox .user-detial-box .text-box {
    font-size: 0.8125rem;
  }
  .notification-messageDetail .message-infobox .user-detial-box .text-box p {
    margin-bottom: 8px;
  }
  .notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex {
    display: block;
  }
  .notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half {
    width: 100%;
    margin-bottom: 15px;
  }
  .notification-messageDetail .message-hereInfo.newMessage .message-replyInfo .two-column-flex .half:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .notification-main-wrapper {
    display: block;
  }
  .notification-main-wrapper .notification-block-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .notification-main-wrapper .notification-block-right {
    width: 100%;
  }
  .notification-with-sidebar {
    display: block;
  }
  .notification-with-sidebar .notification-sidebar {
    width: 100%;
  }
  .notification-with-sidebar .notification-sidebar ul li {
    padding: 15px;
  }
  .notification-with-sidebar .notification-sidebar ul li.button-link {
    padding: 20px 15px;
  }
  .notification-with-sidebar .notification-detail-box {
    width: 100%;
    border-left: none;
  }
  .notification-messageDetail .message-detailInfo {
    padding: 15px;
  }
  .notification-messageDetail .message-detailInfo .subject-lineInfo h2 {
    font-size: 1rem;
  }
  .notification-messageDetail .message-hereInfo {
    padding-bottom: 0;
  }
  .notification-messageDetail .message-hereInfo .message-replyInfo {
    padding: 20px 15px;
  }
  .notification-messageDetail .message-hereInfo .message-replyInfo.nomessage .form-group textarea {
    font-size: 0.875rem;
  }
  .notification-messageDetail .message-hereInfo .message-replyInfo .form-group.flexbox {
    margin-bottom: 15px;
    padding-bottom: 15px !important;
  }
  .notification-messageDetail .message-hereInfo .message-replyInfo .form-group textarea {
    min-height: 120px;
  }
}

@media screen and (max-width: 567px) {
  .notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item {
    width: 85%;
  }
  .notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .item .user-detial-box .content-with-img {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-right: -20%;
  }
  .notification-main-wrapper .notification-block-right .notification-detail-box .notification-item .notification-dropdown-info {
    width: calc(100% - 85%);
  }
  .notification-with-sidebar .notification-sidebar ul li {
    padding: 10px 15px;
  }
  .notification-with-sidebar .notification-sidebar ul li a {
    font-size: 0.9375rem;
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box {
    display: block;
    position: relative;
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box .search-box {
    display: block;
    margin-right: 0;
  }
  .notification-with-sidebar .notification-detail-box .notification-search-box .search-box label {
    margin-bottom: 12px;
  }
  .notification-with-sidebar .notification-detail-box .notification-pagination {
    position: absolute;
    right: 0;
    top: 10px;
  }
  .notification-with-sidebar .notification-detail-box .notification-item {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box {
    font-size: 0.875rem;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .text-box p {
    -webkit-line-clamp: 2;
  }
  .notification-with-sidebar .notification-detail-box .notification-item .user-detial-box .item:last-child {
    top: 35px;
  }
  .notification-with-sidebar .notification-detail-box.with-back .notification-search-box {
    display: -webkit-box;
    display: -ms-box;
    display: -ms-flexbox;
    display: flex;
  }
  .notification-with-sidebar .notification-detail-box.with-back .notification-search-box .notification-pagination {
    position: static;
    top: auto;
  }
}
