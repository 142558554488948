/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.onBoardCoachTeamSection {
  min-height: calc(100vh - 120px);
  padding: 50px 15px;
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 60px;
}

.onBoardCoachTeamSection .coachTeamBlock {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection {
  padding: 30px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .heading {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.25rem;
  font-weight: 600;
  color: #3E3E3E;
  margin: 0 0 10px 0;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .heading span.checkIcon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 10px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .heading span.checkIcon svg .a {
  fill: #00b9ff;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .heading span.checkIcon svg .b {
  fill: #fafafa;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .coachSUbHead {
  font-size: 0.875rem;
  font-weight: normal;
  color: #3E3E3E;
  margin: 0 0 30px 0;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .checkbox {
  margin-bottom: 20px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .checkbox > span {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .checkbox > span input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .checkbox > span input:checked + label::before {
  border-color: #00B9FF;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .checkbox > span input:checked + label::after {
  width: 12px;
  height: 12px;
  /* background: url(../../assets/img/checkBlue.png) no-repeat; */
  background-size: cover;
  content: "";
  display: inline-block;
  position: absolute;
  left: 3px;
  top: 4px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .checkbox > span label {
  display: inline-block;
  padding-left: 30px;
  position: relative;
  color: #3E3E3E;
  cursor: pointer;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .checkbox > span label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  border: 1px solid #3E3E3E;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .skip {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3E3E3E;
  text-align: center;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .skip a {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #C0321F;
  cursor: pointer;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection .skip a:hover {
  text-decoration: underline;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div select {
  padding: 8px 10px;
  -webkit-appearance: none;
  /* background-image: url(../../assets/img/arrowdown.png); */
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 16px;
  width: 100%;
  font-size: 0.875rem;
  color: #3E3E3E;
  line-height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div input {
  padding: 8px 10px;
  width: 100%;
  font-size: 0.875rem;
  color: #3E3E3E;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div input::-webkit-input-placeholder {
  color: #3E3E3E;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div input:-ms-input-placeholder {
  color: #3E3E3E;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div input::-ms-input-placeholder {
  color: #3E3E3E;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div input::placeholder {
  color: #3E3E3E;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox {
  margin-bottom: 25px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox p {
  font-size: 0.9375rem;
  font-weight: 400;
  color: #3E3E3E;
  line-height: 1.5;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox p:last-child {
  margin-bottom: 0;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox label {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: #3E3E3E;
  line-height: 22px;
  margin: 0 0 10px 0;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox label sup {
  display: inline-block;
  vertical-align: -8px;
  font-weight: 500;
  color: #C0321F;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox .flexBox {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox .flexBox .flag {
  padding: 0 4px 0 10px;
  width: 50px;
  min-width: 50px;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox .flexBox .flag img {
  max-width: 100%;
  height: auto;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox .flexBox select {
  width: 60px;
  min-width: 60px;
  background-size: 10px;
  outline: none;
  border: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.onBoardCoachTeamSection .coachTeamBlock .formSection div.coachFormSeciton form .fieldBox .flexBox input {
  width: calc(100% - 110px);
  border: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.onBoardCoachTeamSection .coachTeamBlock .continueBtnBox {
  text-align: center;
  margin-top: 40px;
}

.onBoardCoachTeamSection .coachTeamBlock .continueBtnBox a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
  background-color: #1CB79E;
  padding: 10px 15px;
  min-width: 100px;
  min-height: 45px;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  cursor: pointer;
}

.modal {
  padding-right: 0 !important;
}

.modal.skip-popup .modal-dialog {
  max-width: 580px;
}

.modal.skip-popup .modal-content {
  border: none;
}

.modal.skip-popup .modal-body {
  padding: 50px 45px;
  text-align: center;
  position: relative;
}

.modal.skip-popup .modal-body button.close {
  outline: none;
  position: absolute;
  top: 15px;
  right: 20px;
  opacity: 1;
}

.modal.skip-popup .modal-body .modal-title {
  font-size: 1.875rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}

.modal.skip-popup .modal-body .modal-title + p {
  margin-bottom: 25px;
}

.modal.skip-popup .modal-body p {
  font-size: 0.875rem;
  font-weight: 400;
  color: #3E3E3E;
  margin-bottom: 15px;
}

.modal.skip-popup .modal-body .btn {
  padding: 12px 25px;
  font-size: 0.875rem;
  font-weight: 400;
}

.modal.skip-popup .modal-body .skip {
  margin-top: 15px;
  margin-bottom: 0;
}

.modal.skip-popup .modal-body .skip a {
  display: inline-block;
  vertical-align: top;
  text-decoration: underline;
  font-size: 0.875rem;
  color: #C0321F;
  cursor: pointer;
}

.modal.skip-popup .modal-body .skip a:hover {
  text-decoration: none;
}

@media screen and (max-width: 567px) {
  .modal.skip-popup .modal-body {
    padding: 45px 25px 35px;
  }
  .modal.skip-popup .modal-body .modal-title {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 479px) {
  .onBoardCoachTeamSection .coachTeamBlock .formSection {
    padding: 20px;
  }
  .onBoardCoachTeamSection .coachTeamBlock .continueBtnBox a {
    width: 100%;
  }
}
