@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/reset";
.pageContent {
    min-height: 520px;
}
.claimprofile-sec {
    .claimprofile-box {
        background: $white;
        box-shadow: 1px 2px 2px #00000017;
        @include radius(4px);
        min-height: 350px;
        .claimprofile-title {
            @include flex();
            justify-content: space-between;
            align-items: center;
            padding: .9em 1em;
            @include font(18);
            font-weight: 600;
            color: $blackColor;
            position: relative;
            border-bottom: 1px solid rgba($border, .4%);
        }
        .coach-claimprofile-detail {
            padding: .9em 1em;
            .flexbox {
                overflow: hidden;
                li {
                    float: left;
                    width: 28%;
                    margin-right: 2%;
                    &:last-child {
                        width: 10%;
                        margin-right: 0;
                        button {
                            @include font(14);
                            margin-top: 20px;
                            width: 100%;
                            height: 45px;
                            &.blue {
                                background: $lightBlue;
                            }
                        }
                    }
                    label {
                        display: block;
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        margin-bottom: .5em;
                    }
                    .form-control {
                        padding: 0 1em;
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        width: 100%;
                        height: 45px;
                        border: 1px solid $border;
                        @include radius(4px);
                    }
                }
            }
        }
        .claim-profile-filter-data {
            padding-top: 1em;
            h3 {
                @include font(16);
                font-weight: 500;
                color: $blackColor;
                text-transform: capitalize;
                margin-bottom: 15px;
            }
            .athlete-profile-item {
                li {
                    @include flex();
                    justify-content: space-between;
                    align-items: center;
                    padding: .6em .8em;
                    background: rgba($gray, .1%);
                    margin-bottom: 15px;
                    @include radius(4px);
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .athlete-profile-info {
                        @include flex();
                        align-items: center;
                        .img-box {
                            margin-right: 15px;
                            img {
                                display: block;
                                width: 55px;
                                height: 55px;
                                @include radius(50px);
                            }
                        }
                        .content-box {
                            @include font(16);
                            font-weight: 400;
                            color: $black;
                        }
                    }
                    .btn {
                        @include font(14);
                        &.blue {
                            background: $lightBlue;
                        }
                    }
                }
            }
        }
    }
}
.common-modal {
    padding-right: 0;
    .modal-content {
        border: none;
        box-shadow: 0 3px 20px #00000080;
        @include radius(8px);
        .modal-header {
            padding: 1em 1.2em;
            border-bottom: 1px solid rgba($border, .4%);
            .modal-title {
                @include font(18);
                font-weight: 500;
                color: $blackColor;
            }
            .close {
                font-weight: 300;
                color: $black;
                outline: none;
            }
        }
        .modal-body {
            padding: 1.4em 1.2em 1.6em;
            p {
                @include font(14);
                font-weight: 400;
                color: $black;
                line-height: 22px;
                text-align: center;
                margin-bottom: 20px;
            }
            .claimprofile-password-box {
                display: block;
                text-align: center;
                li {
                    display: inline-block;
                    vertical-align: middle;
                    width: 45px;
                    margin: 5px;
                    input {
                        padding: .5em;
                        @include font(18);
                        font-weight: 400;
                        color: $black;
                        line-height: 45px;
                        width: 100%;
                        height: 45px;
                        border: 1px solid $border;
                        @include radius(4px);
                        text-align: center;
                        box-shadow: none;
                    }
                }
            }
            .form-group {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
                &:first-child {
                    .name-text {
                        display: inline-block;
                        visibility: middle;
                        padding: .6em 0;
                        @include font(14);
                        font-weight: 400;
                    }
                    label {
                        color: $blackColor;
                    }
                }
                label {
                    display: block;
                    @include font(14);
                    font-weight: 400;
                    color: $black;
                    margin-bottom: .5em;
                }
                .form-control {
                    padding: 0 1em;
                    @include font(14);
                    font-weight: 400;
                    color: $black;
                    width: 100%;
                    height: 45px;
                    border: 1px solid $border;
                    @include radius(4px);
                    box-shadow: none;
                }
            }
            .claimProfileVerifyData {
                padding: 15px;
                @include font(14);
                font-weight: 300;
                color: $black;
                border: 1px solid rgba($border, .4%);
                @include radius(4px);
                .VerifyProfile {
                    background: $lightBlue;
                    padding: 15px;
                    text-align: center;
                    @include radius(5px);
                    margin-bottom: 15px;
                    .profileImg {
                        width: 100px;
                        margin: 0 auto 10px;
                        img {
                            max-width: 100%;
                            width: 100%;
                            height: 100px;
                            border: 3px solid $white;
                            @include radius(50%);
                        }
                    }
                    .name {
                        @include font(16);
                        font-weight: 500;
                        color: $white;
                        margin-bottom: 8px;
                    }
                    .infotext {
                        @include font(14);
                        font-weight: 300;
                        color: $white;
                    }
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
    .modal-footer {
        padding: 0 1.2em 1.8em;
        justify-content: center;
        border-top: none;
        button {
            min-width: 100px;
            @include font(14);
            font-weight: 300;
            margin: 0 8px;
            &.blue {
                background: $lightBlue;
            }
        }
    }
}
@media screen and (max-width: 991px) {
    .claimprofile-sec {
        .claimprofile-box {
            .coach-claimprofile-detail {
                .flexbox {
                    li {
                        width: 32%;
                        &:nth-child(3) {
                            margin-right: 0;
                        }
                        &:last-child {
                            width: 100%;
                            clear: both;
                            button {
                                margin-top: 0;
                            }
                        }
                        .form-control {
                            @include font(13);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .claimprofile-sec  {
        .claimprofile-box {
            .coach-claimprofile-detail {
                .flexbox {
                    li {
                        width: 49%;
                        &:nth-child(2n+2) {
                            margin-right: 0;
                        }
                        &:nth-child(3) {
                            width: 100%;
                        }
                        &:nth-child(2n+3) {
                            clear: both;
                        }
                        &:last-child {
                            width: 100%;
                            clear: both;
                            button {
                                margin-top: 0;
                            }
                        }
                        .form-control {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
            }
            .claim-profile-filter-data {
                .athlete-profile-item {
                    li {
                        .athlete-profile-info {
                            .content-box {
                                @include font(14);
                            }
                        }
                    }
                }
            }
        }
    }
    .common-modal {
        height: auto;
    }
}

@media screen and (max-width: 567px) {
    .claimprofile-sec {
        .claimprofile-box {
            .coach-claimprofile-detail {
                .flexbox {
                    li {
                        width: 100%;
                    }
                }
            }
            .claim-profile-filter-data {
                .athlete-profile-item {
                    li {
                        .athlete-profile-info {
                            .content-box {
                                @include font(12);
                                line-height: 1.3;
                                margin-right: 10px;
                            }
                            .img-box {
                                margin-right: 10px;
                                img {
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                        }
                        .btn {
                            padding: 10px 12px;
                            @include font(13);
                        }
                    }
                }
            }
        }
    }
}