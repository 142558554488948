@import "../../../../../../../../assets/css/variables";
@import "../../../../../../../../assets/css/mixin";
@import "../../../../../../../../assets/css/reset";

.sf-plan-first-sec {
    background: $white;
    padding: 60px 0 45px;
    margin-top: 60px;
    .top-block {
        text-align: center;
        .tagline {
            @include font(18);
            font-weight: 400;
            color: $black;
            margin-bottom: 20px;
        }
        .sec-title {
            @include font(40);
            font-weight: 600;
            color: $blackColor;
            letter-spacing: -0.84px;
            line-height: 1.33;
            text-align: center;
            margin-bottom: 10px;
        }
        p {
            @include font(24);
            color: rgba($blackColor, 0.6);
            font-weight: 500;
            line-height: 1.4;
            margin-bottom: 0;
        }
        .pricing-unlock-feature {
            @include flex();
            justify-content: center;
            align-items: flex-start;
            max-width: 700px;
            margin: 0 auto;
            li {
                padding:30px 20px 0;
                a {
                    display: block;
                    text-decoration: none;
                    @include font(18);
                    font-weight: 500;
                    color:$lightBlue;
                    line-height: 1.4;
                    @include transition();
                    cursor: default;
                    span {
                        display: block;
                        &.icon-box {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}
.sf-plans-list-sec {
    background:#F3F3F3;
    padding: 60px 0 45px;
    .top-block {
        max-width: 1166px;
        margin: 0 auto 40px;
        text-align: center;
        .title {
            @include font(40);
            font-weight: 600;
            color:$blackColor;
            line-height: 40px;
            margin-bottom: 15px;
        }
        .plan-top-infoBox {
            padding-top: 30px;
            .flexbox {
                @include flex();
                justify-content: center;
                align-items: center;
                padding: 0 200px;
                position: relative;
                margin-bottom: 30px;
                .offer-save-btn {
                    @include flex();
                    align-items: center;
                    background:#FFA500;
                    padding: 10px 20px;
                    @include font(16);
                    font-weight: 500;
                    color: $blackColor;
                    text-transform: uppercase;
                    position: absolute;
                    width: 165px;
                    min-height: 50px;
                    outline: none;
                    border: none;
                    left: 0;
                    top: 0;
                    @include radius(4px);
                    cursor: pointer;
                    &::after {
                        content: "";
                        position: absolute;
                        right:-18px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 25px 0 25px 20px;
                        border-color: transparent transparent transparent #FFA500;
                        @include radius(4px);
                        z-index:1;
                    }
                }
                .number-payment-type {
                    width: 100%;
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    background:$white;
                    box-shadow: 0px 0px 4px #5A5A5A29;
                    @include radius(4px);
                    overflow: hidden;
                    .item {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        padding: 10px 15px;
                        @include font(18);
                        font-weight: 500;
                        color:$black;
                        width: 25%;
                        min-height: 50px;
                        text-transform: uppercase;
                        border-right:1px solid #F3F3F3;
                        cursor: pointer;
                        &.active {
                            background:$lightBlue;
                            color:$white;
                            border-right: $lightBlue;
                        }
                    }
                }
            }
            .info-detailBox {
                @include font(15px);
                font-weight:400;
                color:$black;
                padding-bottom: 15px;
                .checkbox-group{
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    padding-bottom: 10px;
                    input[type="checkbox"] {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: 1;
                        @include radius(4px);
                        cursor: pointer;
                        &:checked {
                          + label {
                            color:$lightBlue;
                            &::before {
                              border-color: $lightBlue;
                            }
                            &::after {
                              content: "";
                              display: inline-block;
                              //background: url(../../../assets/img/checkBlue.png) no-repeat;
                              background: url(../../../../../../../../assets/img/checkBlue.png) no-repeat;
                              background-size: cover;
                              position: absolute;
                              width: 12px;
                              height: 12px;
                              left: 4px;
                              top: 6px;
                            }
                          }
                        }
                    }
                    label {
                        padding-left: 30px;
                        position: relative;
                        @include font(20);
                        font-weight: 500;
                        color: $black;
                        line-height: 20px;
                        cursor: pointer;
                        &::before {
                          content: "";
                          display: inline-block;
                          position: absolute;
                          width: 20px;
                          height: 20px;
                          left: 0;
                          top: 0;
                          border: 2px solid #B1B1B1;
                          @include radius(4px);
                        }
                    }
                }
                p {
                    color:rgba($blackColor, 0.7);
                    line-height:1.33;
                    margin-bottom: 10px;
                }
                .plan-referral-box {
                    padding-top: 20px;
                    .btn-referral {
                        display: inline-block;
                        vertical-align: middle;
                        text-decoration: underline;
                        @include font(18);
                        font-weight:500;
                        color:$black;
                        border: none;
                        text-align: center;
                        outline: none;
                        border: none;
                        box-shadow: none;
                        cursor: pointer;
                        margin-bottom: 15px;
                    }
                    .plan-referral-flex {
                        display: none;
                        max-width: 550px;
                        margin: 0 auto;
                        .form-group {
                            position: relative;
                            padding-left: 75px;
                            padding-right: 75px;
                            margin-bottom: 0;
                            &.coupon-applied{
                                padding-left: 90px;
                                padding-right: 90px;
                            }
                            input {
                                background-color: $white;
                                background-repeat: no-repeat;
                                background-position: right 5px center;
                                padding: 10px 60px 10px 15px;
                                width: 100%;
                                height: 50px;
                                @include font(16);
                                color: $black;
                                border: 1px solid rgba($black, .5);
                                outline: none;
                                box-shadow: none;
                                @include radius(25px);
                                &::placeholder {
                                    color: rgba($black, .3);
                                }
                                &.vaild-referral-code {
                                    background-image: url(../img/check-circle-green.svg) ;
                                }
                                &.invalid-referral-code {
                                    background-image: url(../img/close-circle-red.svg);
                                }
                            }
                            .apply-btn {
                                display: inline-block;
                                vertical-align: middle;
                                text-decoration: underline;
                                @include font(18);
                                font-weight:500;
                                color:$black;
                                border: none;
                                text-align: center;
                                position: absolute;
                                right: 0;
                                top:15px;
                                z-index:1;
                                outline: none;
                                border: none;
                                box-shadow: none;
                                cursor: pointer;
                            }
                        }
                    }
                    &.show {
                        .btn-referral {
                            text-decoration: none;
                        }
                        .plan-referral-flex {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .plans-listing {
        @include flex();
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -8px;
        .item {
            width:33.333%;
            padding: 80px 8px 15px;
            @include transition();
            .plan-detail {
                background:$white;
                padding:100px 25px 30px;
                @include radius(12px);
                @include transition();
                text-align: center;
                position: relative;
                .icon-box {
                    background: $white;
                    @include flex();
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    width: 160px;
                    height: 160px;
                    transform: translate(-50%, 0);
                    left:50%;
                    top: -80px;
                    margin: 0 auto;
                    border: 5px solid #F3F3F3;   
                    @include radius(100%);
                    z-index:1;
                }
                .plan-title {
                    @include flex();
                    justify-content:center;
                    align-items: center;
                    @include font(26);
                    font-weight: 600;
                    color:$black;
                    line-height: 1.333;
                    text-transform: uppercase;
                    min-height: 75px;
                    margin-bottom: 20px;
                    &.height-auto {
                        min-height:1px;
                    }
                }
                .plan-type {
                    @include font(20);
                    font-weight: 400;
                    color:rgba($blackColor, 0.7);
                    line-height: 1.333;
                    text-transform: capitalize;
                    margin-bottom: 20px;
                    &.height55 {
                        min-height:55px;
                    }
                    span {
                        display: block;
                        @include font(16);
                        font-style: italic;
                        margin-top: 5px;
                    }
                }
                .short-text {
                    @include font(18);
                    font-weight: 400;
                    color:rgba($blackColor, 0.7);
                    line-height: 30px;
                    text-align: left;
                    .check-type-list {
                        @include font(16);
                        font-weight: 400;
                        color:$black;
                        line-height:26px;
                        min-height: 180px;
                        li {
                            display: block;
                            padding-left:30px;
                            position: relative;
                            margin-bottom:12px;
                            &::after {
                                content:'';
                                background:url(../img/check-icon.svg) no-repeat;
                                background-position: center center;
                                position: absolute;
                                width: 18px;
                                height: 18px;
                                left: 0;
                                top: 6px;
                                z-index:1;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                       
                    }
                    p {
                        margin-top:15px;
                    }
                }
                .plan-bottom-info {
                    @include flex();
                    justify-content: center;
                    align-items: flex-end;
                    margin-top: 20px;
                    padding-top:20px;
                    border-top: 1px solid rgba($gray, .4);
                    min-height: 160px;
                    .plan-price {
                        @include font(30);
                        font-weight: 600;
                        color:$black;
                        line-height:1.1;
                        margin-bottom: 15px;
                        sub {
                            display: inline-block;
                            vertical-align: 6px;
                            @include font(16);
                            font-weight: 400;
                            color:rgba($blackColor, .5);
                        }
                        span {
                            display: block;
                            @include font(18);
                            font-weight: 500;
                            line-height:1.333; 
                        }
                    }
                    .plan-price-seccond {
                        @include font(26);
                        font-weight: 600;
                        color:$black;
                        line-height:1.1;
                        margin-bottom: 15px;
                        sub {
                            display: inline-block;
                            vertical-align: 6px;
                            @include font(14);
                            font-weight: 400;
                            color:rgba($blackColor, .5);
                        }
                    }
                    .plan-total-price {
                        @include font(18);
                        font-weight: 500;
                        color:$baseColor;
                        margin-bottom: 18px;
                    }
                    .plan-button {
                        text-align: center;
                        .btn {
                            background: $lightBlue;
                            display: block;
                            padding: 10px 15px;
                            min-width:200px;
                            margin: 0 auto;
                            @include font(16);
                            font-weight: 500;
                            color: $white;
                            @include radius(4px);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    .plan-bottom-box {
        @include font(15);
        font-weight:400;
        color:$black;
        line-height:1.6;
        text-align: center;
        padding-top: 15px;
        p {
            margin-bottom: 5px;
            strong {
                @include font(16);
                font-weight:500;
            }
        }
    }
}
.sf-plan-feature-sec {
    background: $white;
    padding: 60px 0;
    .sec-title {
        @include font(40);
        font-weight: 700;
        color:$blackColor;
        margin-bottom: 30px;
        text-align: center;
    }
    .plan-feature-table {
        .table {
            @include font(16);
            font-weight: 400;
            color: #737373;
            line-height: 1.5;
            border-color: rgba($gray, .4)!important;
            td, th {
                width: 22%;
                border-color: rgba($gray, .4)!important;
                text-align: center;
                &:first-child {
                    text-align: left;
                    width: 34%;
                }
                &.title {
                    font-weight: 500;
                    color:$lightBlue;
                    text-transform: uppercase;
                }
                .value-price {
                    display: block;
                    @include font(15);
                    font-weight:500;
                    color:$baseColor;
                    text-transform: capitalize;
                    margin-top:0;
                }
                .table-plan-price {
                    @include font(20);
                    font-weight: 600;
                    sub {
                        display: inline-block;
                        vertical-align: 6px;
                        @include font(14);
                        font-weight: 400;
                        text-transform: lowercase;
                        color:rgba($blackColor, .5);
                    }
                }
                .table-plan-price-seccond {
                    @include font(18);
                    font-weight: 600;
                    sub {
                        display: inline-block;
                        vertical-align: 6px;
                        @include font(13);
                        font-weight: 400;
                        text-transform: lowercase;
                        color:rgba($blackColor, .5);
                    }
                }
                .btn {
                    display: block;
                    min-width:200px;
                    padding: 10px 15px;
                    background:$lightBlue;
                    @include font(16);
                    font-weight: 500;
                    color:$white;
                    border: 1px solid #F9F9F9;
                    @include radius(4px);
                    margin: 0 auto;
                    cursor: pointer;
                }
                &.active {
                    background:#F1FBFF;
                    .btn {
                        background: $baseColor;
                        border-color:$baseColor;
                        color:$white;
                    }
                }
                
            }
            th {
                @include font(18);
                font-weight: 600;
                color:$black;
                text-transform: uppercase;
            }
        }
    }
}
.sf-plan-testimonial-sec {
    background: url(../img/testimonial-bg.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 60px 0;
    text-align: center;
    .top-block {
        max-width: 1080px;
        @include font(16);
        font-weight: 300;
        color: $white;
        line-height: 1.5;
        margin: 0 auto 30px;
        .title {
            @include font(40);
            font-weight: 600;
            color: $white;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 0;
        }
    }
    .slide-item {
        padding: 20px 25px;
        background: $white;
        box-shadow: 0 0 4px #00000029;
        @include radius(5px);
        .testimonial-img {
            @include flex();
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            border: 1px solid #F2F2F2;
            @include radius(50%);
            margin: 0 auto 10px;
            img {
                display: block;
                width: 100px;
                height: 100px;
                object-fit: cover;
                @include radius(50%);
            }
        }
        .testimonial-detail {
            @include font(14);
            font-weight: 300;
            color: $black;
            letter-spacing: 0.21px;
            line-height: 1.5;
            .short-text {
                position: relative;
                margin-bottom: 20px;
                min-height:90px;
                &:after {
                    content:"";
                    background: url(../img/quote-iocn-left.svg) no-repeat;
                    position: absolute;
                    width: 90px;
                    height: 65px;
                    left: 0;
                    top: -15px;
                    z-index: 1;
                }
                &:before {
                    content:"";
                    background: url(../img/quote-iocn-right.svg) no-repeat;
                    position: absolute;
                    width: 31px;
                    height: 21px;
                    right: 0;
                    bottom: -10px;
                    z-index: 1;
                }
                p {
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .name {
                @include font(15);
                font-weight: 500;
                letter-spacing: 0.23px;
                margin-bottom: 5px;
            }
            .designation {
                @include font(15);
                font-weight: 500;
                color:$baseColor;
                letter-spacing: 0.18px;
            }
        }
    }
    .testimonial-slider {
        .owl-dots {
            display: block;
            text-align: center;
            margin-top: 20px;
            button {
                display: inline-block;
                vertical-align: middle;
                border: none;
                outline: none;
                span {
                    display: block;
                    background: $white;
                    width: 12px;
                    height: 12px;
                    margin: 3px 5px;
                    border: 1px solid $white;
                    border-radius: 50%;
                    opacity: 0.16;
                    transition: opacity .2s ease;
                }
                &.active {
                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.sf-plan-faq-sec {
    background: url(../img/faq-bg.jpg) no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    padding: 60px 0;
    .sec-title {
        @include font(36);
        font-weight: 600;
        color: $white;
        margin-bottom: 30px;
    }
    .faq-listing {
        counter-reset: faq; 
        > li {
            @include transition();
            margin-bottom: 15px;
            border-bottom: 1px solid rgba($gray, .50%);
            padding-bottom: 20px;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .faq-title {
                @include font(18);
                font-weight:300;
                color:#D4D4D4;
                position: relative;
                padding-right: 40px;
                line-height: 1.4;
                cursor: pointer;
                span {
                    display: inline-block;
                    vertical-align: middle;
                    color:#D4D4D4;
                    margin-right: 5px;
                }
                &:after {
                    content: '+';
                    font-size: 35px;
                    color: $white;
                    display: block;
                    width: 28px;
                    height: 28px;
                    line-height: 28px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    text-align: center;
                    opacity: 0.4;
                }
                &.active {
                    font-weight: 500;
                    color:$lightBlue;
                    &:after {
                        content: "";
                        font-size: 0;
                    }
                    &:before {
                        content: "";
                        width: 18px;
                        position: absolute;
                        background:$lightBlue;
                        height: 2.8px;
                        right: 5px;
                        top: 12px;
                    }
                }
            }
            .faq-data {
                @include font(16);
                font-weight:300;
                color: #D4D4D4;
                display: none;
                padding-top: 15px;
                padding-right: 30px;
                line-height: 1.6;
                ol {
                    display: block;
                    list-style-type: decimal;
                    margin-bottom: 10px;
                    padding-left: 0;
                    li {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
.sf-plan-recruiting-sec {
    padding: 60px 0;
    .content-with-video-sec {
        @include flex();
        justify-content: space-between;
        .help-box, .video-box {
            padding: 20px;
            background: $white;
            box-shadow: 0 0 4px #00000029;
            @include radius(10px);
            img {
                max-width: 100%;
            }
        }
        .help-box {
            position: relative;
            width: 345px;
            margin-right: 20px;
            .help-img {
                @include radius(4px);
                margin-bottom: 15px;
            }
            h3 {
                @include font(20);
                font-weight: 600;
                color: $black;
                line-height:18px;
                margin-bottom: 15px;
            }
            p {
                @include font(16);
                font-weight: 400;
                color: $black;
                line-height: 1.5;
                margin-bottom: 20px;
            }
            .button-box {
                .btn {
                    background:$baseColor;
                    padding: 10px 25px;
                    @include font(18);
                    color:$white;
                    font-weight: 400;
                    @include radius(4px);
                    box-shadow: 1px 2px 5px #00000017;
                }
            }
            .owl-nav {
                display: block;
                position: absolute;
                width: 100%;
                top: 50%;
                left: 0;
                right: 0;
                z-index: 1;
                margin-top: -15px;
                button {
                    background: $white;
                    width: 30px;
                    height: 30px;
                    @include radius(50px);
                    box-shadow: 0 4px 10px #00000082;
                }
                button.owl-next {
                    float: right;
                    position: absolute;
                    right: -15px;
                    outline: none;
                  span {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    right: 2px;
                    left: auto;
                    top: -5px;
                  }
                }
                button.owl-prev {
                    float: left;
                    position: absolute;
                    left: -15px;
                    outline: none;
                }
                button span {
                    content: '';
                    display: inline-block;
                    position: relative;
                    width: 8px;
                    height: 8px;
                    font-size: 0;
                    border-right: 2px solid $black;
                    border-bottom: 2px solid $black;
                    transform: rotate(-0deg);
                    -webkit-transform: rotate(135deg);
                    -moz-transform: rotate(135deg);
                    -ms-transform: rotate(135deg);
                    -o-transform: rotate(135deg);
                    left: 2px;
                    top: -5px;
                    @include transition();
                }
            }
        }
        .video-box {
            width: calc(100% - 365px);
            h3 {
                @include font(20);
                font-weight: 500;
                color: $black;
                line-height: 1.333;
                margin-bottom: 15px;
            }
            p {
                @include font(16);
                font-weight: 400;
                color: $black;
                line-height: 1.5;
                margin-bottom: 20px;
            }
            .img-box {
                position: relative;
                height: 350px;
                @include radius(5px);
                img {
                    display: block;
                    max-height: 100%;
                    height:350px;
                    width: 100%;
                    @include radius(4px);
                }
               .video-play-button {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                    cursor: pointer;
                    .video-play-inner {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                    }
                    button {
                        @include flex();
                        justify-content: center;
                        align-items: center;
                        background:$baseColor;
                        width: 60px;
                        height: 60px;
                        @include radius(50%);
                        outline: none;
                        border: none;
                        svg {
                            path {
                                fill: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
.sf-plan-video-modal {
    .modal-content {
        border: none;
        -webkit-box-shadow: 0px 3px 20px #00000080;
        box-shadow: 0px 3px 20px #00000080;
    }
    .modal-body {
        padding: 15px;
        iframe {
            width: 100% !important;
            display: inline-block;
            vertical-align: middle;
            height: 255px !important;
            overflow: hidden;
        }
    }
}
@media screen and (max-width:1365px) {
    .sf-plan-first-sec {
        .top-block {
            .sec-title {
                @include font(36);
            }
            p {
                @include font(18);
            }
            .pricing-unlock-feature {
                li {
                    a {
                        @include font(16); 
                    }
                }
            }
        }
    }
    .sf-plans-list-sec {
        .top-block {
            .title {
                @include font(36);  
            }
        }
    }
    .sf-plan-feature-sec {
        .sec-title {
            @include font(36);
        }
    }
    .sf-plan-testimonial-sec {
        .top-block {
            .title {
                @include font(36);
            }
        }
    }
}
@media screen and (max-width:1199px) {
    .sf-plan-first-sec {
        .top-block {
            .sec-title {
                @include font(32);
            }
        }
    }
    .sf-plans-list-sec {
        .top-block {
            .title {
                @include font(32);  
            }
            .plan-top-infoBox {
                .info-detailBox {
                    padding-bottom:0;
                    .checkbox-group {
                        label {
                            @include font(16);
                        }
                    }
                }
                .flexbox {
                    .number-payment-type {
                        .item {
                            @include font(16);
                            min-width:1px;
                        }
                    }
                }
            }
        }
        .plans-listing {
            .item {
                .plan-detail {
                    padding-left: 20px;
                    padding-right: 20px;
                    .plan-title {
                        @include font(22);
                        min-height: 60px;
                    }
                    .plan-type {
                        @include font(16);
                    }
                    .short-text {
                        @include font(16);
                    }
                    .plan-bottom-info {
                        min-height: 140px;
                        .plan-price {
                            @include font(22);
                        }
                        .plan-price-seccond {
                            @include font(20);
                        }
                    }
                }
            }
        }
    }
    .sf-plan-feature-sec {
        .sec-title {
            @include font(32);
        }
    }
    .sf-plan-testimonial-sec {
        .top-block {
            .title {
                @include font(32);
            }
        }
    }
    .sf-plan-faq-sec {
        .sec-title {
            @include font(32);
        }
    }
}
@media screen and (max-width: 991px) {
    .sf-plan-first-sec {
        padding-top: 45px;
        .top-block {
            margin-bottom: 0;
            .sec-title {
                @include font(25);
            }
            .pricing-unlock-feature {
                li {
                    a {
                        span {
                            &.icon-box {
                                margin-bottom: 10px;
                                cursor: default; 
                                svg {
                                    height: 100px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sf-plans-list-sec {
        padding: 45px 0;
        .top-block {
            .title {
                @include font(25); 
                line-height: 32px; 
            }
            .plan-top-infoBox {
                padding-top:15px;
                .flexbox {
                    flex-direction: column;
                    padding-left: 0;
                    padding-right: 0; 
                    .offer-save-btn {
                       position: relative;
                       margin-bottom: 30px;
                        &::after {
                            transform: rotate(90deg);
                            bottom: -26px;
                            right:50%;
                            margin-right: -12px;
                        }
                    }
                    .number-payment-type {
                        align-items: flex-start;
                        .item {
                            @include font(16);
                        }
                    }
                }
                .info-detailBox {
                    .plan-referral-box {
                        padding-top: 10px;
                    }
                }
            }
        }
        .plans-listing {
            .item {
                width: 50%;
                padding-top: 50px;
                .plan-detail {
                    padding-top: 65px;
                    .icon-box {
                        width: 100px;
                        height: 100px;
                        top: -50px;
                        svg {
                            width: 40px;
                        }
                    }
                    .plan-title {
                        @include font(18);
                        min-height: 50px;
                    }
                    .plan-bottom-info {
                        min-height: 145px;
                    }
                }
            }
        }
    }
    .sf-plan-feature-sec {
        padding: 45px 0;
        .plan-feature-table {
            .table {
                @include font(15);
                th {
                    @include font(16);
                }
            }
        }
    }
    .sf-plan-testimonial-sec {
        padding: 35px 30px;
        .top-block {
            .title {
                @include font(25);
            }
        }
    }
    .sf-plan-recruiting-sec {
        .content-with-video-sec {
            .video-box {
                h3 {
                    @include font(18);
                }
                .img-box {
                    height: 220px;
                    img {
                        height: 220px;
                    }
                }
            }
            .help-box {
                h3 {
                    @include font(18); 
                }
            }
        }
    }
    .sf-plan-faq-sec {
        padding: 45px 0;
        .sec-title {
            @include font(25);
        }
    }
}
@media screen and (max-width:767px) {
    .sf-plan-first-sec {
        .top-block {
            .sec-title {
                @include font(22);
            }
            .pricing-unlock-feature {
                li {
                    padding-left: 10px;
                    padding-right: 10px;
                    a {
                        span {
                            &.icon-box {
                                svg {
                                    height: 90px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sf-plans-list-sec{
        .top-block {
            margin-bottom: 25px;
            .title {
                @include font(22);
                line-height: 30px;
            }
        }
        .plans-listing {
            display: block;
            .item {
                width: 100%;
                .plan-detail {
                    .plan-title {
                        @include font(18);
                        min-height: 1px;
                        margin-bottom: 10px;
                    }
                    .short-text {
                        .check-type-list {
                            min-height: 1px;
                            li {
                                margin-bottom: 8px;
                            }
                        }
                    }
                    .plan-bottom-info {
                        min-height: 1px;
                        .plan-price {
                            @include font(20); 
                            margin-bottom: 10px; 
                        }
                        .plan-price-seccond {
                            @include font(18);
                        }
                        .plan-total-price {
                            @include font(16);
                        }
                    }
                }
            }
        }
    }
    .sf-plan-feature-sec {
        .sec-title {
            @include font(20); 
        }
    }
    .sf-plan-testimonial-sec {
        .top-block {
            .title {
                @include font(22);
            }
        }
    }
    .sf-plan-recruiting-sec{
        .content-with-video-sec {
            display: block;
            text-align: center;
            .help-box {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
                .button-box {
                    .btn {
                        @include font(15);
                    }
                }
                .owl-nav {
                    button {
                        &.owl-next {
                            right: 5px;
                        }
                        &.owl-prev {
                            left: 5px;
                        }
                    }
                }
            }
            .video-box {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width:639px) {
    .sf-plan-first-sec {
        padding: 40px 0;
        .top-block {
            .sec-title {
                @include font(20); 
                margin-bottom:10px;
            }
            p {
                @include font(15);  
            }
            .pricing-unlock-feature {
                li {
                    padding:20px 5px 0;
                    a {
                        @include font(13);
                        span {
                            &.icon-box {
                                svg {
                                    height: 60px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sf-plans-list-sec {
        padding: 35px 0 40px;
        .top-block {
            .plan-top-infoBox {
                .flexbox {
                    margin-bottom: 25px;
                    .offer-save-btn {
                        padding-left: 15px;
                        padding-right: 15px;
                        @include font(15);
                        width: auto;
                        min-height: 42px;
                    }
                }
                .info-detailBox {
                    .plan-referral-box {
                        padding-top: 0;
                        .btn-referral {
                            @include font(16);
                        }
                        .plan-referral-flex {
                            .form-group {
                                padding-left: 60px;
                                padding-right: 60px;
                                &.coupon-applied{
                                    padding-left: 80px;
                                    padding-right: 80px;
                                }
                                input {
                                    padding-right: 55px;
                                    background-size:26px;
                                    @include font(15);
                                    height: 45px;
                                }
                                .apply-btn {
                                    @include font(16);
                                    top:12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sf-plan-feature-sec {
        padding: 35px 0 40px;
        .sec-title {
            @include font(20); 
            margin-bottom: 20px;
        }
        .plan-feature-table {
            overflow-x: scroll;
            &::-webkit-scrollbar {
                border-radius: 10px;
                width: 8px;
                height: 12px;
                background: rgba($gray, 0.3%);
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: rgba($gray, 0.8%);
                cursor: pointer;
            }
            .table {
                width: 760px;
                td .btn,
                th .btn {
                    min-width: 150px;
                }
            }
        }
    }
    .sf-plan-testimonial-sec {
        padding: 40px 15px;
        margin-bottom: 0;
        .top-block {
            margin-bottom: 20px;
            .title {
                @include font(20);
                line-height: 1.6;
                margin-bottom: 10px;
            }
            p {
                @include font(15);
            }
        }
    }
    .sf-plan-recruiting-sec {
        padding:40px 0;
        .content-with-video-sec {
            .help-box, .video-box {
                padding:20px 15px;
                h3 {
                    @include font(16);
                    max-width:350px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .video-box {
                .img-box {
                    height: auto;
                    img {
                        height: auto;
                    }
                    .video-play-button {
                        button {
                            width: 40px;
                            height: 40px;
                            svg {
                                width: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .sf-plan-faq-sec {
        padding: 35px 0;
        .sec-title {
            @include font(20);
        }
        .faq-listing {
            > li {
                .faq-title {
                    @include font(17);
                }
            }
        }
    }
}
@media screen and (max-width:567px) {
    .sf-plans-list-sec {
        .top-block {
            .plan-top-infoBox {
                .flexbox {
                    .number-payment-type {
                        .item {
                            @include font(14);
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:479px) {
    .sf-plans-list-sec {
        .top-block {
            .plan-top-infoBox {
                .flexbox {
                    .number-payment-type {
                        .item {
                            width: 50%;
                            @include font(15);
                            border-bottom: 1px solid #f3f3f3;
                        }
                    }
                }
            }
        }
    }
}