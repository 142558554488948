// @import  "../../../../../../../assets/css/mixin";
@import '../../../../../../assets/css/mixin';
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";


.onBoardDOBSection{
    min-height: calc(100vh - 120px);
    padding: 20px 15px;
    @include flex();
    justify-content: center;
    margin-top: 0;
    .dobBlock{
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
        @include radius(6px);
        .formSection{
            padding: 30px;
            border: 1px solid $gray;
            width: 100%;
            @include radius(6px);
            .heading{
                font-size: 20px;
                font-weight: 600;
                margin: 0 0 30px 0;
                font-weight: 600;
                text-align: center;
                @include flex();
                align-self: center;
                justify-content: center;
                span{
                    &.icon{
                        display: inline-flex;
                        margin-left: 10px;
                    }
                }
            }
            .fieldBox{
                margin-bottom: 50px;
                label{
                    font-size: 16px;
                    margin: 0 0 10px 0;
                    display: block;
                    color: $black;
                    font-weight: 600;
                }
            
                input{
                    outline: none;
                    border: 1px solid $gray;
                    @include radius(4px);
                    padding: 10px;
                    width: 100%;
                }
              
            }
           
            .skip{
                text-align: center;
                .sikpBtnBox{
                    display: inline-block;
                }
                a{
                    text-decoration: none;
                    display: inline-block;
                    cursor: pointer;
                    color: $baseColor;
                }
            }
        }
        .continueBtnBox{
            text-align: center;
            margin-top: 35px;
            a{
                outline: none;
                box-shadow: none;
                cursor: pointer;
                background-color: $green;
                color: $white;
                padding: 10px 15px;
                @include radius(4px);
                border: none;
                font-size: 16px;
                min-width: 100px;
                min-height: 45px;
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width:479px) {
    .onBoardDOBSection{
        .dobBlock{
            .formSection{
                padding: 20px;
            }
            .continueBtnBox{
                a{
                    width: 100%;
                }
            }
        }
    }  
}