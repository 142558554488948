@import '../../../../../../../assets/css/mixin';
@import '../../../../../../../assets/css/reset';
@import '../../../../../../../assets/css/variables';

.coachProfileDarkTimelineSection {
  margin-top: 20px;
  background-color: $bgLightGray;
  padding: 15px 0;
  .topSection {
    background-image: url(https://cdn.sportsforce.io/Groundbg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    .container {
      .coachProfileFlexBox {
        @include flex();
        flex-wrap: wrap;
        padding: 20px 0 30px 0;
        margin-top: -15px;
        align-items: flex-start;
        .carousel {
          padding: 3px;
          @include radius(4px);
          width: 190px;
          height: 220px;
          margin-right: 15px;
          background-color: $white;
          @include shadow(0, 3px, 6px, #00000029);
          .owl-carousel {
            position: relative;
            .owl-stage-outer {
              height: calc(220px - 6px);
              .owl-stage {
                .owl-item {
                  .item {
                    img {
                      max-width: 100%;
                      height: auto;
                    }
                  }
                }
              }
            }
            .owl-nav {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 100%;
              z-index: 1;
              width: 100%;
              @include flex();
              align-items: center;
              justify-content: space-between;
              padding: 0 10px;
              button {
                outline: none;
                &.owl-prev {
                  width: 20px;
                  height: 20px;
                  @include flex();
                  align-items: center;
                  justify-content: center;
                  @include radius(50%);
                  @include font(25);
                  color: $white;
                  background: rgba(0, 0, 0, 0.5);
                  span {
                    display: inline-flex;
                  }
                }
                &.owl-next {
                  width: 20px;
                  height: 20px;
                  @include flex();
                  align-items: center;
                  justify-content: center;
                  @include radius(50%);
                  @include font(25);
                  color: $white;
                  background: rgba(0, 0, 0, 0.5);
                  span {
                    display: inline-flex;
                  }
                }
              }
            }
          }
        }
        .coachInfo {
          padding-right: 10px;
          width: calc(100% - 765px);
          .pName {
            @include font(20);
            color: $white;
            font-weight: 300;
            margin: 0 0 5px 0;
            @include flex();
            align-items: center;
            span {
              display: inline-flex;
              &.icon {
                margin-left: 10px;
                svg {
                  .a {
                    fill: $lightBlue;
                  }
                }
              }
            }
          }
          .designation {
            display: inline-block;
            color: $white;
            margin: 0 0 10px 0;
            background: $yellow;
            padding: 5px 10px;
            @include radius(2px);
            @include font(14);
          }
          p {
            &.info {
              @include font(14);
              margin: 0 0 10px 0;
              color: $white;
              line-height: 22px;
            }
            &.email {
              @include font(14);
              margin: 0 0 10px 0;
              color: $white;
              line-height: 22px;
            }
            &.social {
              display: inline-flex;
              align-items: center;
              @include font(16);
              color: $white;
              background: $blackColor;
              padding: 5px;
              margin: 0 0 10px 0;
              span {
                display: inline-flex;
                &.icon {
                  margin-right: 10px;
                  svg {
                    width: 22px;
                    height: auto;
                    .a {
                      fill: #34c7ff;
                    }
                  }
                }
              }
            }
          }
          ul {
            margin-top: 10px;
            padding: 0;
            @include flex();
            flex-wrap: wrap;
            li {
              list-style: none;
              padding-right: 10px;
              margin-right: 10px;
              border-right: 1px solid $white;
              margin-bottom: 5px;
              color: $white;
              @include font(14);
              @include flex();
              align-items: center;
              &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: none;
              }
              &.likes {
                span {
                  display: inline-flex;
                  font-weight: 600;
                  &.icon {
                    margin-right: 10px;
                    svg {
                      .a {
                        fill: $white;
                        opacity: 1;
                      }
                    }
                  }
                }
              }
              &.follower {
                span {
                  font-weight: 600;
                  &.label {
                    font-weight: normal;
                    margin-left: 5px;
                  }
                }
              }
              &.following {
                span {
                  font-weight: 600;
                  &.label {
                    margin-left: 5px;
                    font-weight: normal;
                  }
                }
              }
            }
          }
        }
        .infoBoxFlex {
          width: 560px;
          @include flex();
          flex-wrap: wrap;
          .item {
            margin-right: 10px;
            @include radius(5px);
            border: 1px solid $border;
            background-color: $white;
            width: calc(25% - 10px);
            margin-bottom: 10px;
            @include flex();
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 92px;
            max-width: 300px;
            width: 130px;
            background-color: $white;
            span {
              display: block;
              text-align: center;
              @include font(18);
              color: $baseColor;
              font-weight: 600;
              
              &.label {
                margin-bottom: 15px;
                @include font(14);
                font-weight: normal;
                color: $black;
              }
            }
            &.gpaScore {
              span {
                &.label {
                  color: $black;
                  margin-bottom: 10px;
                }
                &.infoIcon {
                  margin-top: 0;
                  text-align: right;
                  margin-left: auto;
                  margin-right: 7px;
                  margin-bottom: -15px;
                  svg {
                    .a {
                      opacity: 0.5;
                      fill: $black;
                    }
                  }
                }
                &.lockIcon {
                  svg {
                    width: 18px;
                    height: auto;
                    .a {
                      fill: $gray;
                      fill-rule: evenodd;
                    }
                  }
                }
              }
            }
            &.satAct {
              span {
                color: $baseColor;
                &.label {
                  color: $black;
                  margin-bottom: 15px;
                  margin-top: -15px;
                }
              }
            }
            &.rating {
              span {
                &.label {
                  color: rgba(62, 62, 62, 0.5);
                  margin-bottom: 10px;
                }
                &.infoIcon {
                  margin-top: 0;
                  text-align: right;
                  margin-left: auto;
                  margin-right: 7px;
                  margin-bottom: -15px;
                  svg {
                    .a {
                      opacity: 0.5;
                      fill: $black;
                    }
                  }
                }
                &.lockIcon {
                  svg {
                    width: 18px;
                    height: auto;
                    .a {
                      fill: $gray;
                      fill-rule: evenodd;
                    }
                  }
                }
              }
            }
            &.commmitBox {
              background-color: $white;
              border: none;

              span {
                font-weight: normal;
                margin-bottom: -10px;
                margin-top: 5px;
                @include font(14);
                color: $black;
                &.label {
                  @include font(14);
                  font-weight: 600;
                  margin-bottom: 5px;
                  margin-top: 0;
                  color: $black;
                }
                &.imgBox {
                  width: 25px;
                  height: 25px;
                  margin-bottom: 0;
                  margin-top: 0;
                  img {
                    max-width: 100%;
                    height: auto;
                  }
                }
              }
            }
            &:nth-child(3) {
              span {
                color: $black;
              }
            }
            &:nth-child(5) {
              span {
                color: $black;
              }
            }
          }
          .showhideBox {
            width: 100%;
            text-align: center;
            display: none;
            a {
              display: inline-flex;
              align-items: center;
              padding: 5px 10px;
              background: $white;
              color: $baseColor;
              @include radius(4px);
              cursor: pointer;
              text-decoration: none;
              position: relative;
              &::before {
                content: 'Show less';
                display: inline-block;
                @include font(14);
                color: $white;
                color: $baseColor;
              }
              span {
                &.icon {
                  display: inline-block;
                  margin-left: 10px;
                  svg {
                    .a {
                      fill: $baseColor;
                    }
                  }
                }
              }
            }
          }
          &.hide {
            .item {
              display: none;
              &:nth-child(1) {
                @include flex();
              }
              &:nth-child(2) {
                @include flex();
              }
            }
            .showhideBox {
              a {
                &::before {
                  content: 'Show more';
                  color: $baseColor;
                }
                span {
                  &.icon {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
        .actionBox {
          margin-top: 15px;
          @include flex();
          align-items: center;
          position: relative;
          button {
            outline: none;
            border: none;
            box-shadow: none;
            &.followBtn {
              @include radius(4px);
              padding: 10px 15px;
              background-color: $lightBlue;
              @include font(14);
              color: $white;
              @include flex();
              align-items: center;
              margin-right: 10px;
              span {
                display: inline-flex;
                &.icon {
                  margin-right: 10px;
                  svg {
                    width: 20px;
                    height: auto;
                    .a {
                      fill: $white;
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &.promoteBtn {
              @include radius(4px);
              padding: 10px 15px;
              background-color: $yellow;
              @include font(14);
              color: $white;
              @include flex();
              align-items: center;
              margin-right: 10px;
              span {
                display: inline-flex;
                &.icon {
                  margin-right: 10px;
                  svg {
                    width: 20px;
                    height: auto;
                    .a {
                      fill: $white;
                      opacity: 1;
                    }
                  }
                }
              }
            }
            &.menuBtn {
              width: 40px;
              height: 40px;
              @include radius(4px);
              padding: 10px;
              background-color: $green;
              @include font(14);
              color: $white;
              @include flex();
              align-items: center;
              margin-right: 0;
              justify-content: center;
              span {
                display: inline-flex;
                &.icon {
                  margin-right: 0;
                  svg {
                    width: 4px;
                    height: auto;
                    .a {
                      fill: $white;
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
          .dropdown-menu {
            right: 0;
            left: auto !important;
            transform: none !important;
            top: 45px !important;
            @include shadow(0, 3px, 13px, #30303042);
            border: none;
            a {
              padding: 5px 10px;
              span {
                display: inline-block;
                @include font(14);
                color: $black;
                &.icon {
                  margin-right: 10px;
                  svg {
                    .a {
                      fill: $black;
                    }
                  }
                }
              }
            }
          }
        }
        .metricCarouselBox {
          width: calc(100% - 310px);
          margin-left: 25px;
          margin-top: 15px;
          @include flex();
          align-items: center;
          .title {
            @include font(16);
            color: $white;
            font-weight: 600;
            margin-right: 20px;
          }
          .owl-carousel {
            padding: 0 30px;
            position: relative;
            width: calc(100% - 80px);
            .owl-stage-outer {
              position: relative;
              z-index: 2;
              .owl-stage {
                .item {
                  min-height: 30px;
                  > div {
                    min-height: 30px;
                    min-width: 144px;
                    justify-content: space-between;
                  }
                  .velocityMax {
                    display: inline-flex;
                    align-items: center;
                    padding: 3px 10px;
                    background-color: $white;
                    @include radius(20px);
                    span {
                      display: inline-block;
                      @include font(16);
                      color: $black;
                      font-weight: 600;
                      &.label {
                        @include font(12);
                        font-weight: normal;
                        margin-left: 10px;
                        max-width: 50px;
                        text-align: center;
                      }
                    }
                  }
                  .chValueBox {
                    display: inline-flex;
                    align-items: center;
                    padding: 3px 10px;
                    background-color: $white;
                    @include radius(20px);
                    .iconBox {
                      margin-right: 10px;
                      @include flex();
                      align-items: center;
                      span {
                        display: inline-block;
                        font-weight: 600;
                        color: $black;
                        &.icon {
                          margin-right: 5px;
                          svg {
                            width: 15px;
                            height: auto;
                            .a {
                              fill: none;
                              stroke: $baseColor;
                              stroke-linecap: round;
                              stroke-linejoin: round;
                              stroke-width: 1.5px;
                            }
                          }
                        }
                      }
                    }
                    .label {
                      @include font(12);
                      color: $black;
                      font-weight: normal;
                      margin-left: 10px;
                    }
                  }
                }
              }
            }
            .owl-dots {
              display: none;
            }
            .owl-nav {
              position: absolute;
              z-index: 1;
              left: 0;
              width: 100%;
              top: 50%;
              transform: translateY(-50%);
              @include flex();
              align-items: center;
              justify-content: space-between;
              button {
                outline: none;
                span {
                  font-family: monospace;
                }
                &.owl-prev {
                  span {
                    display: inline-flex;
                    @include font(40);
                    color: $white;
                  }
                }
                &.owl-next {
                  span {
                    display: inline-flex;
                    @include font(40);
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .bottomSection {
    padding: 20px 0;
    .container {
      .tabBox {
        @include radius(4px);
        background-color: $white;
        border: 1px solid #00000038;
        padding: 0 30px;
        max-width: 900px;
        margin: 0 auto 15px auto;
        nav {
          ul {
            margin: 0;
            padding: 0;
            @include flex();
            align-items: center;
            justify-content: center;
            li {
              list-style: none;
              margin-right: 70px;
              a {
                padding: 15px 10px;
                @include font(16);
                color: $black;
                display: inline-block;
                text-decoration: none;
                position: relative;
                &:hover {
                  color: $baseColor;
                }
              }
              &.selected {
                a {
                  color: $baseColor;
                  &::after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $baseColor;
                  }
                }
              }
              &.closeBtnBox {
                display: none;
                text-align: right;
                button {
                  padding: 5px;
                  outline: none;
                  background-color: transparent;
                  border: none;
                  cursor: pointer;
                  svg {
                    .a {
                      opacity: 0.7;
                      fill: $black;
                    }
                  }
                }
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .flexBox {
          display: none;
          div {
            &.menuBtnBox {
              margin-right: 10px;
              width: auto;
              display: block;
              .tabMenuBtn {
                border: none;
                padding: 5px;
                background: transparent;
                outline: none;
                cursor: pointer;
                svg {
                  .a {
                    fill: #343434;
                  }
                }
              }
            }
            width: calc(100% - 36px);
            @include flex();
            align-items: center;
            p {
              @include font(15);
              color: $baseColor;
            }
          }
        }
      }
      .TimelineData {
        max-width: 900px;
        margin: 0 auto;
        .item {
          padding: 15px;
          background-color: $white;
          border: 1px solid $gray;
          @include radius(4px);
          margin-bottom: 15px;
          .headFlex {
            @include flex();
            align-items: flex-start;
            margin-bottom: 15px;
            .imgBox {
              width: 34px;
              height: 40px;
              @include radius(2px);
              margin-right: 15px;
              overflow: hidden;
              img {
                max-width: 100%;
                height: auto;
              }
            }
            .info {
              width: calc(100% - 150px);
              .name {
                @include font(16);
                color: $blackColor;
                font-weight: 600;
                margin: 0 0 5px 0;
              }
              span {
                display: block;
                @include font(14);
                line-height: 18px;
                color: rgba(62, 62, 62, 0.7);
              }
            }
            .time {
              width: 100px;
              span {
                display: block;
                @include font(14);
                color: $black;
                color: rgba(62, 62, 62, 0.7);
                text-align: right;
              }
            }
          }
          .flexItem {
            @include flex();
            align-items: flex-start;
            padding-bottom: 15px;
            border-bottom: 1px solid $gray;
            margin-bottom: 15px;
            .imgBox {
              border: 1px solid $gray;
              @include radius(4px);
              width: 120px;
              height: 120px;
              margin-right: 15px;
              @include flex();
              align-items: center;
              justify-content: center;
              img {
                max-width: 100%;
                height: auto;
              }
            }
            .info {
              width: calc(100% - 135px);
              max-width: 650px;
              h5 {
                @include font(16);
                color: $blackColor;
                font-weight: 600;
                line-height: 22px;
                margin: 0 0 5px 0;
              }
              span {
                @include font(14);
                display: block;
                color: rgba(62, 62, 62, 0.7);
                line-height: 18px;
              }
              .videoBox {
                margin-top: 15px;
                img {
                  max-width: 100%;
                  height: auto;
                  max-height: 350px;
                }
              }
            }
            &:last-child {
              padding-bottom: 0;
              border: none;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &.coachProfileWrap{
    background-color: $white;
    padding-bottom: 0;
    .bottomSection {
      padding-bottom: 5px;
      .container {
        max-width: 100%;
        padding: 0;
        .tabBox, .TimelineData{
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .coachProfileDarkTimelineSection {
    .topSection {
      > .container {
        max-width: 1600px;
        .coachProfileFlexBox {
          .coachInfo {
            width: calc(100% - 905px);
            padding-right: 10px;
          }
          .infoBoxFlex {
            width: 700px;
            .item {
              margin-right: 15px;
              width: calc(25% - 15px);
              margin-bottom: 20px;
              max-width: 160px;
            }
          }
        }
      }
    }
    .bottomSection {
      > .container {
        max-width: 1600px;
        .tabBox {
          max-width: 1300px;
          margin: 0 auto 15px auto;
        }
        .TimelineData {
          max-width: 1300px;
          margin: 0 auto;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .coachProfileDarkTimelineSection {
    .topSection {
      .container {
        .coachProfileFlexBox {
          .coachInfo {
            width: calc(100% - 625px);
          }
          .infoBoxFlex {
            width: 420px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .coachProfileDarkTimelineSection {
    .topSection {
      > .container {
        max-width: 100%;
      }
    }
    .bottomSection {
      > .container {
        max-width: 100%;
        .tabBox {
          nav {
            ul {
              li {
                margin-right: 50px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 899px) {
  .coachProfileDarkTimelineSection {
    .topSection {
      > .container {
        max-width: 100%;
        .coachProfileFlexBox {
          flex-wrap: wrap;
          .carousel {
            order: 1;
          }
          .coachInfo {
            width: calc(100% - 205px);
            order: 2;
          }
          .infoBoxFlex {
            width: 100%;
            order: 4;
            margin-top: 15px;
            .item {
              width: calc(100% - 15px);
              margin-right: 15px;
              margin-bottom: 15px;
              max-width: 165px;
            }
          }
          .actionBox {
            order: 3;
          }
          .metricCarouselBox {
            order: 5;
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .coachProfileDarkTimelineSection {
    .topSection {
      > .container {
        max-width: 100%;
        .coachProfileFlexBox {
          flex-wrap: wrap;
          .infoBoxFlex {
            width: 100%;
            order: 4;
            margin-top: 15px;
            .item {
              width: calc(25% - 15px);
              margin-right: 15px;
              margin-bottom: 15px;
              max-width: 100%;
            }
          }
        }
      }
    }
    .bottomSection {
      > .container {
        .tabBox {
          padding: 0 15px;
          nav {
            ul {
              li {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .coachProfileDarkTimelineSection {
    .topSection {
      box-shadow: none;
      background-color: transparent;
      background-image: url(https://cdn.sportsforce.io/dark-mob-bg.svg);
      > .container {
        .coachProfileFlexBox {
          padding: 15px;
          justify-content: center;
          .carousel {
            order: 1;
            width: 160px;
            height: 160px;
            margin: 0 auto;
            @include radius(50%);
            overflow: hidden;
            .owl-carousel {
              width: 100%;
              height: 100%;
              margin: 0 auto;
              @include radius(50%);
              overflow: hidden;
            }
          }
          .coachInfo {
            margin-top: 15px;
            width: 100%;
            padding-right: 0;
            @include flex();
            align-items: center;
            flex-direction: column;
            .pName {
              justify-content: center;
            }
            .designation {
              text-align: center;
            }
            p {
              &.info {
                justify-content: center;
                text-align: center;
              }
              &.email {
                text-align: center;
              }
              &.social {
                justify-content: center;
              }
            }
            ul {
              justify-content: center;
              li {
                margin-right: 5px;
              }
            }
          }
          .infoBoxFlex {
            justify-content: space-between;
            .item {
              width: calc(50% - 10px);
              margin-right: 0;
              max-width: 100%;
            }
            .showhideBox {
              display: block;
            }
          }
          .actionBox {
            button {
              &.followBtn {
                padding: 10px;
              }
              &.promoteBtn {
                padding: 10px;
              }
            }
          }
          .metricCarouselBox {
            .title {
              margin-right: 10px;
            }

            .owl-carousel {
              width: calc(100% - 70px);
              .owl-stage-outer {
                .owl-stage {
                  .item {
                    > div {
                      min-width: 120px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .bottomSection {
      padding-bottom: 0;
      > .container {
        .tabBox {
          padding: 10px;
          nav {
            position: fixed;
            left: -300px;
            width: 250px;
            height: 100%;
            background-color: $white;
            padding: 15px;
            top: 0px;
            z-index: 3;
            transition: all 0.5s ease-in-out;
            ul {
              display: block;
              li {
                border-bottom: 1px solid $gray;
                padding-bottom: 0;
                margin-bottom: 10px;
                margin-right: 0;
                a {
                  width: 100%;
                  padding: 15px 10px;
                }
                &.closeBtnBox {
                  display: block;
                  padding-bottom: 20px;
                }
              }
            }
            &::after {
              content: '';
              display: inline-block;
              transition: all 0.5s ease-in-out;
              width: 0;
              left: -300px;
              height: 100%;
              position: fixed;
              top: 0;
              background: rgba(0, 0, 0, 0.5);
            }
            &.show {
              left: 0;
              &::after {
                width: calc(100% - 250px);
                left: 250px;
              }
            }
          }
          .flexBox {
            @include flex();
          }
        }
        .TimelineData {
          .item {
            .flexItem {
              .imgBox {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                img {
                  max-height: 40px;
                }
              }
              .info {
                width: calc(100% - 60px);
                h5 {
                  @include font(14);
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}