@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/reset";

.notification-read-box {
  padding: 20px 15px;
  background: $white;
  text-align: center;
  box-shadow: 1px 2px 2px #00000017;
  margin-bottom: 30px;
  .notification-title {
    @include font(16);
    font-weight: 500;
    color: $black;
    position: relative;
  }
  .notification-read {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    color: $lightBlue !important;
    position: absolute;
    right: 0;
    cursor: pointer;
  }
}
.notification-markread-main-box {
  max-width: 900px;
  margin: 0 auto;
  .tab-wrap {
    .tabs {
      padding: 0 0 20px;
      @include flex();
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
        li {
            .btn {
              display: inline-block;
              vertical-align: middle;
              background:$white;
              padding: 10px 15px;
              min-width: 90px;
              @include font(15);
              font-weight: 400;
              color:$blue;
              line-height: 15px;
              text-align: center;
              text-transform: capitalize;
              box-shadow: 0px 8px 8px #B1B1B129;
              border: 1px solid $blue;
              @include radius(4px);
              margin:5px;
              cursor: pointer;
              @include transition();
            }
        }
    }
  }
  > div > .item {
    @include flex();
    justify-content: space-between;
    align-items: center;
    .notification-date-item {
      width: 120px;
      margin-right: 30px;
      .date-text {
        position: relative;
        @include font(14);
        font-weight: 600;
        color: rgba($black, 0.5%);
        text-align: right;
        padding-right: 15px;
        margin-top: -30px;
        span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          + span {
            margin-top: 5px;
          }
        }
        &:after {
          content: "";
          position: absolute;
          background: $white;
          border: 3px solid #4169e1;
          width: 14px;
          height: 14px;
          @include radius(50px);
          right: 0;
          z-index:1;
        }
      }
    }
    .notification-item {
      @include flex();
      align-items: center;
      padding: 15px 25px 15px 35px;
      background: $gray;
      width: calc(100% - 150px);
      @include radius(4px);
      box-shadow: 1px 2px 2px #00000017;
      @include transition();
      margin-bottom: 15px;
      cursor: pointer;
      position: relative;
      
      &::before {
        content: "";
        background: $gray;
        position: absolute;
        width: 2px;
        height: 100%;
        top: 50%;
        left: -38px;
        margin-top: 6px;
      }
      .user-profile {
        @include flex();
        justify-content: center;
        align-items: center;
        flex: 0 0 45px;
        width: 45px;
        height: 45px;
        @include font(16);
        font-weight: 500;
        color:$black;
        border: 1px solid $border;
        @include radius(50px);
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .user-detial-box {
        @include flex();
        justify-content: space-between;
        align-items: center;
        @include font(15);
        color: rgba($black, 0.6%);
        width: 100%;
        position: relative;
        padding-right: 85px;
        .item {
          &:last-child {
            margin-right: 0;
            position: absolute;
            right: 0;
            top: 4px;
            z-index: 1;
          }
        }
        .name {
          margin-bottom: 5px;
          font-weight: 500;
          color: $blackColor;
        }
        .read
        {
          margin-bottom: 5px;
          font-weight: 400;
          color:$blackColor;
        }
        .text-box {
          @include flex();
          align-items: center;
          @include font(14);
          .subject-line {
            margin-right: 10px;
            font-weight: 500;
            line-height: 1.3;
          }
          a {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            color: $lightBlue;
          }
        }
        .time-text {
          @include font(13);
          color: rgba($black, 0.3%);
          font-weight: 600;
          text-align: right;
        }
      }
    }
    .notificationread{
      @include flex();
      align-items: center;
      padding: 15px 25px 15px 35px;
      background: $white;
      width: calc(100% - 150px);
      @include radius(4px);
      box-shadow: 1px 2px 2px #00000017;
      @include transition();
      margin-bottom: 15px;
      cursor: pointer;
      position: relative;
      
      &::before {
        content: "";
        background: $white;
        position: absolute;
        width: 2px;
        height: 100%;
        top: 50%;
        left: -38px;
        margin-top: 6px;
      }
      .user-profile {
        @include flex();
        justify-content: center;
        align-items: center;
        flex: 0 0 45px;
        width: 45px;
        height: 45px;
        @include font(16);
        color:$black;
        text-transform: uppercase;
        border: 1px solid $border;
        @include radius(50px);
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .user-detial-box {
        @include flex();
        justify-content: space-between;
        align-items: center;
        @include font(16);
        color: rgba($black, 0.6%);
        width: 100%;
        position: relative;
        padding-right: 65px;
        .item {
          &:last-child {
            margin-right: 0;
            position: absolute;
            right: 0;
            top: 4px;
            z-index: 1;
          }
        }
        .name {
          margin-bottom: 5px;
          font-weight: 500;
          color: $blackColor;
        }
        .read
        {
          margin-bottom: 5px;
          font-weight: 400;
          color:$blackColor;
        }
        .text-box {
          @include flex();
          align-items: center;
          @include font(14);
          .subject-line {
            margin-right: 10px;
            font-weight: 500;
            line-height: 1.3;
          }
          a {
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            color: $lightBlue;
          }
        }
        .time-text {
          @include font(13);
          color: rgba($black, 0.3%);
          font-weight: 600;
          text-align: right;
        }
      }
    }
    &:last-child {
      .notification-item {
        &::before {
          display: none;
        }
      }
    }
    &.active {
      .notification-item {
        &:after {
          content: "";
          background: #4169e1;
          position: absolute;
          width: 7px;
          height: 7px;
          top: 50%;
          left: 14px;
          @include radius(50%);
          margin-top: -3px;
        }
      }
    }
  }
  img {
    max-width: 100%
  }
}
.paginationBox {
  @include flex();
  flex-wrap: wrap;
  align-items: center;
  max-width: 900px;
  margin: 30px auto 0;
  padding-left: 15px;
  padding-right: 15px;
  div {
    &.perPage {
      @include flex();
      align-items: center;
      width: 120px;
      input {
        padding: 5px;
        @include font(14);
        color: $black;
        width: 35px;
        height: 26px;
        border: 1px solid $gray;
        @include radius(3px);
        outline: none;
        box-shadow: none;
      }
      label {
        display: inline-flex;
        @include font(14);
        color: $black;
        margin-left: 10px;
      }
    }
    &.pageNav {
      width: calc(100% - 240px);
      text-align: center;
      ul {
        display: inline-flex;
        justify-content: center;
        width: auto;
        border: 1px solid $gray;
        @include radius(3px);
        margin: 0;
        padding: 0;
        li {
          @include flex();
          align-items: center;
          justify-content: center;
          list-style: none;
          width: 32px;
          height: 26px;
          border-right: 1px solid $gray;
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            @include font(14);
            color: #626262;
            width: 32px;
            height: 26px;
            cursor: pointer;
            &:hover {
              background-color: $lightBlue;
              color: $white;
              svg {
                fill: $white;
              }
            }
          }
          &.backLink {
            svg {
              width: 10px;
              height: auto;
              fill: #626262;
              transform: rotate(180deg);
            }
          }
          &.forLink {
            svg {
              width: 10px;
              height: auto;
              fill: #626262;
            }
          }
          &:last-child {
            border-right: none;
          }
        }
        .disabled {
          a {
            &:hover {
              background-color: $white;
              color: #626262;
              svg {
                fill: #626262;
              }
            }
          }
        }
        .active {
          a {
            background-color: $lightBlue;
            color: $white;
            svg {
              fill: $white;
            }
          }
        }
      }
    }
    &.goBox {
      @include flex();
      align-items: center;
      justify-content: flex-end;
      width: 120px;
      input {
        padding: 5px;
        width: 35px;
        height: 26px;
        @include font(14);
        color: $black;
        border: 1px solid $gray;
        outline: none;
        box-shadow: none;
        @include radius(3px);
      }
      a {
        display: inline-flex;
        align-items: center;
        @include font(14);
        color: $black;
        padding: 5px;
        margin-left: 10px;
        text-decoration: none;
        cursor: pointer;
        span {
          display: inline-flex;
          &.icon {
            margin-left: 5px;
            svg {
              fill: $black;
              .a {
                opacity: 1;
                fill: $black;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .notification-markread-main-box {
    > div > .item {
      .notification-item {
        .user-detial-box {
          @include font(15);
          .text-box {
            display: block;
            .subject-line {
              display: block;
              width: 100%;
              margin-bottom: 5px;
            }
            p {
              @include font(13);
              width: 100%;
            }
          }
          .item {
            &:last-child {
              top: 5px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .pageContent {
    &.notification-pagecontent {
      min-height: 1px;
    }
  }
  .container {
    &.full-with {
      max-width: 100%;
    }
  }
  .notification-markread-main-box {
    .tab-wrap {
      .tabs {
        li {
          .btn {
            min-width: 75px;
          }
        }
      }
    }
    > div > .item {
      .notification-date-item {
        width: 120px;
        margin-right: 10px;
        .date-text {
          @include font(13);
        }
      }
      .notification-item {
        // width: calc(100% - 130px);
        &::before {
          left: -38px;
          margin-top: 7px;
        }
      }
    }
  }
}
@media screen and (max-width: 567px) {
  .notification-read-box {
    margin-bottom: 40px;
    .notification-title {
      @include flex();
      align-items: center;
      justify-content: space-between;
    }
    .notification-read {
      position: static;
      right: auto;
    }
  }
  .notification-markread-main-box {
    > div > .item {
      display: block;
      padding-bottom: 15px;
      margin-left: 25px;
      position: relative;
      &::after {
        content: "";
        background: $gray;
        position: absolute;
        width: 2px;
        height: 100%;
        top: 0;
        left: -19px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      .notificationread {
        width: 100%;
        padding: 15px 10px 15px 30px;
        .user-detial-box {
          @include font(13);
          padding-right: 85px;
        }
      }
      .notification-date-item {
        width: 100%;
        margin: 10px auto 15px;
        .date-text {
          text-align: left;
          &:after {
            right: auto;
            left: -25px;
            z-index: 1;
          }
        }
      }
      .notification-item {
        width: 100%;
        &::before {
          display: none;
        }
      }
    }
  }
  .paginationBox {
    margin-top: 10px;
    div {
      &.perPage {
        order: 1;
      }
      &.goBox {
        order: 2;
        margin-left: auto;
      }
      &.pageNav {
        order: 3;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .notification-read-box {
    .notification-title {
      @include font(14);
    }
  }
  .notification-markread-main-box {
    > div > .item {
      .notification-item {
        padding: 15px 15px 15px 25px;
        .user-detial-box {
          display: block;
          @include font(13);
          padding-right: 0;
          .item {
            .name {
              padding-right: 75px;
            }
            &:last-child {
              top: 3px;
            }
          }
          .text-box {
            @include font(13);
          }
          .time-text {
            @include font(12);
          }
        }
      }
      &.active {
        .notification-item {
          &::after {
            left: 10px;
          }
        }
      }
    }
  }
}
