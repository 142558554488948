@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/reset";

.notification-main-wrapper {
    @include flex();
    justify-content: space-between;
    .notification-block-left {
        width: 260px;
        background: $white;
        align-self: flex-start;
        border: 1px solid $lightBlue;
        @include radius(6px);
        text-align: center;
        .notification-box {
            padding: 20px 15px;
            .notification-val-icon {
                margin-bottom: 8px;
            }
            h3 {
                @include font(16);
                font-weight: 500;
                color:$black;
                margin-bottom: 5px
            }
            p {
                @include font(13);
                margin-bottom: 6px;
                line-height: 1.3;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: none;
                    @include font(14);
                    font-weight: 500; 
                    color: $lightBlue;
                    @include transition();
                    &:hover {
                        color: $baseColor;
                    }
                }
            }
            &.second {
                background: rgba($lightBlue, .05);
            }
        }
    }
    .notification-block-right {
        width: calc(100% - 280px);
        .notification-detail-box {
            background: $white;
            border:1px solid $gray;
            @include radius(6px);
            .notification-item {
                @include flex();
                justify-content: space-between;
                padding: 18px 15px;
                border-bottom: 1px solid $gray;
                &:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                }
                &:nth-child(1) {
                    background: rgba($lightBlue, .05);
                }
                &:nth-child(2) {
                    background: rgba($lightBlue, .05);
                }
                &:nth-child(3) {
                    background: rgba($lightBlue, .05);
                }
                .item {
                    @include flex();
                    justify-content: space-between;
                    width: 90%;
                    .user-profile {
                        @include flex();
                        align-items: center;
                        justify-content: center;
                        width: 60px;
                        height: 60px;
                        @include radius(50px);
                        overflow: hidden;
                        @include font(15);
                        color: $black;
                        margin-right: 20px;
                        border: 1px solid #ddd;
                        img {
                            width: 60px;
                            height: 60px;
                            object-fit: cover;
                        }
                        .initial{
                            text-align: center;
                        }
                    }
                    .user-detial-box {
                        width: calc(100% - 80px);
                        .name {
                            @include font(16);
                            font-weight: 500;
                            color: $blackColor;
                            margin-bottom: 5px;
                            text-transform: capitalize;
                            span {
                                display: inline-block;
                                vertical-align: middle;
                                font-weight: 300;
                            }
                        }
                        .day-ago-text {
                            @include font(13);
                            font-weight: 300;
                            color: $black;
                            margin-bottom: 10px;
                        }
                        .button-box {
                            .btn {
                                padding: 5px 15px;
                                @include font(14);
                                border-color: $lightBlue;
                                color: $lightBlue;
                                .fire-icon {
                                    display: inline-block;
                                    vertical-align: -3px;
                                    margin-left: 5px;
                                }
                                &:hover {
                                    background: $lightBlue;
                                    color: $white; 
                                }
                            }
                        }
                        .content-with-img {
                            @include flex();
                            align-items: center;
                            padding: 8px;
                            border: 1px solid $gray;
                            @include radius(6px);
                            .img-box {
                                width: 90px;
                                margin-right: 10px;
                            }
                            .text-box {
                                @include font(15);
                                color: $black;
                                width:calc(100% - 100px);
                                p {
                                    margin-bottom: 8px;
                                    line-height: 1.4;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    a {
                                        display: inline-block;
                                        vertical-align: middle;
                                        text-decoration: none;
                                        @include font(14);
                                        font-weight: 500;
                                        color:$lightBlue;
                                    }
                                }
                            }
                        }
                    }
                }
                .notification-dropdown-info {
                    display: block;
                    width: calc(100% - 90%);
                    text-align: right;
                    .dropdown {
                        li {
                            position: relative;
                            .dropdown-toggle {
                                padding: 1px 6px 6px;
                                display: inline;
                                vertical-align: middle;
                                @include radius(50px);
                                &:after {
                                    display: none;
                                }
                            }
                            &.show {
                                .dropdown-toggle {
                                    background: rgba($gray, .30%);
                                } 
                            }
                            .dropdown-menu {
                                background: $white;
                                padding:15px;
                                width: 235px;
                                right: 0;
                                top:100%!important;
                                left: auto!important;
                                transform: none!important;
                                border:0;
                                box-shadow: -2px 3px 11px #00000029;
                                @include radius(6);
                                li {
                                    margin-bottom: 15px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    a {
                                        @include flex();
                                        padding:0;
                                        background: none;
                                        @include font(13);
                                        color: $black;
                                        white-space: inherit;
                                        line-height: 1.4;
                                        .icon-box {
                                            width: 18px;
                                            margin-right: 10px;
                                            svg {
                                                @include transition();
                                                g {
                                                    @include transition();
                                                }
                                                path {
                                                    @include transition();
                                                }
                                            }
                                        }
                                        .text-box {
                                            margin-top: -4px;
                                            strong {
                                                display: block;
                                                @include font(15);
                                                font-weight: 500;
                                                margin-bottom: 2px;
                                            }
                                        }
                                        &:hover {
                                            color: $baseColor;
                                            svg {
                                                g {
                                                    opacity: 1;
                                                }
                                                path {
                                                    stroke:$baseColor;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.notification-with-sidebar {
    @include flex();
    background: $white;
    .notification-sidebar {
        width: 200px;
        ul {
            li {
                display: block;
                padding: 20px;
                border-bottom: 1px solid rgba($gray, .40%);
                cursor: pointer;
                .link-item {
                    @include flex();
                    align-items: center;
                    justify-content: space-between;
                    text-decoration: none;
                    @include font(16);
                    color: rgba($black, .50%);
                    @include transition();
                    &:hover {
                        color: $blue;
                    }
                }
                &.active {
                    .link-item {
                        color: $blue;
                    }    
                }
                &.button-link {
                    padding-top: 30px;
                    padding-bottom: 30px;
                    .btn {
                        @include flex();
                        align-items: center;
                        background-image: linear-gradient(115deg, #00B9FF, #19D7CD);
                        width: 100%;
                        @include radius(50px);
                        &:after {
                            display: none;
                        }
                    }
                    .dropdown-menu {
                        background: $white;
                        padding: 15px;
                        min-width: 1px;
                        width: auto;
                        left: 20px!important;
                        right: 20px;
                        top:auto!important;
                        transform: none!important;
                        border:0;
                        box-shadow: -2px 3px 11px #00000029;
                        @include radius(6);
                        margin: 0;
                        li {
                            @include font(15);
                            font-weight: 500;
                            color: $black;
                            padding: 0;
                            margin-bottom: 12px;
                            border: none;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            a {
                                display: block;
                                padding: 0 5px;
                                background: none;
                                @include font(13);
                                font-weight: 400;
                                color: $black;
                                white-space: inherit;
                                line-height: 1.4;
                                &:hover {
                                    color: $baseColor;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .notification-detail-box {
        width: calc(100% - 200px);
        border-left: 1px solid rgba($gray, .40%);
        .notification-search-box {
            @include flex();
            justify-content: space-between;
            align-items: center;
            padding: 15px 20px;
            border-bottom: 1px solid rgba($gray, .40%);
            .search-box {
                @include flex();
                align-items: center;
                margin-right: 15px;
                label {
                    @include font(18);
                    font-weight: 500;
                    color: $blackColor;
                    margin-right: 10px;
                    margin-bottom: 0;
                }
                h1 {
                    @include font(18);
                    font-weight: 500;
                    color: $black;
                    margin-right: 10px;
                }
                .search-inner {
                    position: relative;
                    input {
                        padding: 10px 15px 10px 35px;
                        width: 100%;
                        height: 40px;
                        font-size: 0.875rem;
                        font-weight: 300;
                        color: $gray;
                        border: 1px solid $gray;
                        @include radius(50px);
                    }
                    button {
                        display: inline-block;
                        vertical-align: middle;
                        background: none;
                        border: 0;
                        outline: none;
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        left: 0;
                        top: 0;
                        z-index: 11;
                        svg {
                            .a {
                                opacity: 0.3;
                            }
                            .b {
                                fill: none;
                                stroke: $blackColor;
                                stroke-linecap: round;
                                stroke-linejoin: round;
                                stroke-width: 1.5px;
                            }
                        }
                    }
                }
                .checkbox-info {
                    display: inline-flex;
                    align-items: center;
                    position: relative;
                    input[type="checkbox"] {
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      left: 0;
                      top: 0;
                      opacity: 0;
                      z-index: 1;
                      cursor: pointer;
                      @include radius(2px);
                      &:checked {
                        + label {
                            &::before {
                                border-color: $lightBlue;
                            }
                            &::after {
                                content: "";
                                display: inline-block;
                                background: url(../img/checkBlue.png) no-repeat;
                                background-size: cover;
                                position: absolute;
                                width: 12px;
                                height: 12px;
                                left: 3px;
                                top: 5px;
                            }
                        }
                      }
                    }
                    label {
                      position: relative;
                      font-size: 14px;
                      color: $black;
                      font-weight: normal;
                      padding-left: 18px;
                      cursor: pointer;
                      line-height: 18px;
                      color: $black;
                      &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 18px;
                        height: 18px;
                        border: 1px solid $black;
                        @include radius(2px);
                      }
                    }
                  }
                  .delete-foreverBtn {
                    padding: 6px 8px;
                    background: rgba($gray, .2);
                    @include font(14);
                    font-weight: 400;
                    color: $blackColor;
                    border: none;
                    outline: none;
                    @include radius(2px);
                    margin-right: 15px;
                  }
                  .EmptyTrashNowBtn {
                    background: none;
                      display: inline-block;
                      vertical-align: middle;
                      text-decoration: none;
                      @include font(14);
                      font-weight: 400;
                      color: $baseColor;
                      outline: none;
                      border: none;
                      &:hover {
                          opacity: .8;
                      }
                  }
            }
        }
        .noMsg {
            padding: 15px 20px;
          }
        .notification-pagination {
            .back-link {
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
                position: relative;
                padding-left: 22px;
                @include font(14);
                font-weight: 400;
                color: rgba($black, .8);
                &::after {
                    content: "<";
                    position: absolute;
                    font-family: monospace;
                    @include font(26);
                    color: rgba($black, .8);
                    left: 0;
                    top: -7px;
                    z-index: 1;
                    @include transition();
                }
                &:hover {
                    color: $baseColor;
                    &::after {
                        color: $baseColor;
                    }
                }
            }
            .PaginationFlexWrap {
                @include flex();
                align-items: center;
                .PaginationNumberText {
                  @include font(15);
                  color: $black;
                  margin-right: 10px;
                  span {
                    &:first-child {
                      padding-right: 5px;
                    }
                  }
                }
                .page-link {
                  background: none;
                  padding: 0;
                  border: none;
                  color: rgba(0, 0, 0, 0.55);
                  line-height: 0;
                  @include transition();
                }
              }
            > ul {
                @include flex();
                align-items: center;
                li {
                    margin-right: 10px;
                    @include font(15);
                    color: $black;
                    &:last-child {
                        margin-right: 0;
                    }
                    button {
                        background:transparent;
                        @include font(25);
                        font-family: monospace;
                        color: rgba($blackColor, .55%);
                        border: none;
                        outline: none;
                        @include transition();
                        &:hover {
                            color: $baseColor;
                        }
                    }
                    .notification-dropdown-info {
                        display: block;
                        text-align: right;
                        .dropdown {
                            li {
                                position: relative;
                                .dropdown-toggle {
                                    display: block;
                                    padding: 1px 6px 6px;
                                    @include radius(50px);
                                    img {
                                        transform: rotate(90deg);
                                    }
                                    &:after {
                                        display: none;
                                    }
                                }
                                .dropdown-menu {
                                    padding:15px;
                                    background: $white;
                                    width: auto;
                                    top:100%!important;
                                    right: 0;
                                    left: auto!important;
                                    transform: none!important;
                                    border:0;
                                    box-shadow: -2px 3px 11px #00000029;
                                    @include radius(6);
                                     li {
                                        margin-bottom: 15px;
                                        margin-right: 0;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        strong {
                                            font-weight: 500;
                                        }
                                        a {
                                            @include flex();
                                            background: none;
                                            width: 100%;
                                            padding:0 0 0 25px;
                                            @include font(14);
                                            color: rgba($blackColor, .60%);
                                            white-space: inherit;
                                            line-height: 1.4;
                                            position: relative;
                                            .icon-box {
                                                position: absolute;
                                                left: 0;
                                                svg {
                                                    path {
                                                        fill: rgba($blackColor, .50%);
                                                    }
                                                }
                                            }
                                            &:hover {
                                                color: $baseColor;
                                                svg {
                                                    path {
                                                        fill:$baseColor;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .notification-item {
            @include flex();
            align-items: center;
            padding: 20px 25px;
            border-bottom: 1px solid rgba($gray, .40%);
            @include transition();
            cursor: pointer;
            .user-profile {
                @include flex();
                align-items: center;
                justify-content: center;
                flex: 0 0 45px;
                width: 45px;
                height: 45px;
                @include font(15);
                @include radius(50px);
                overflow: hidden;
                margin-right: 15px;
                border: 1px solid #ddd;
                img {
                    width: 100%;
                    object-fit: cover;
                }
                .initial{
                    text-align: center;
                }
            }
            .user-detial-box {
                @include flex();
                justify-content: space-between;
                align-items: center;
                position: relative;
                padding-right: 100px;
                width: 100%;
                @include font(16);
                color: rgba($black, .60%);
                .item {
                    &:last-child { 
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 1;
                        margin-right: 0;
                    }
                }
                .name {
                    margin-bottom: 5px;
                    font-weight: 500;
                }
                .text-box {
                    @include flex();
                    align-items: center;
                    .subject-line {
                        width: 200px;
                        margin-right: 10px;
                        font-weight: 500;
                        line-height: 1.3;
                    }
                    p {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        width: calc(100% - 200px);
                        @include font(15); 
                        line-height: 1.4;
                        overflow: hidden;
                    }
                }
                .button-box {
                    @include flex();
                    align-items: center;
                    min-height: 20px;
                    .button {
                        display: none;
                        background: transparent;
                        outline: none;
                        border: none;
                        padding: 0;
                        margin-right: 15px;
                        opacity: 0;
                        @include transition();
                        &:last-child {
                            margin-right: 0;
                        }
                        svg {
                            path {
                                fill: $black;
                                @include transition();
                            }
                        }
                        &:hover {
                            svg {
                                path {
                                    fill: $baseColor;
                                }
                            }
                        }
                    }
                }
                .time-text {
                    @include font(13);
                    color: $black;
                    margin-bottom: 12px;
                    text-align: right;
                }
            }
            // &:nth-child(2), &:nth-child(3) {
            //     .user-detial-box {
            //         color: $blackColor!important;
            //     }
            // }
            &.graybg {
                background: rgba($gray, .15%);
            }
            &:hover, &.active {
                background: rgba($lightBlue, .06);
                .user-detial-box {
                    .button-box {
                        .button {
                            display: block;
                            opacity: 1;
                        }
                    }
                }
                .button-boxinfo{
                    .checkbox-info{
                        @include flex();
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
.notification-messageDetail {
    .message-detailInfo {
        padding: 20px;
        .subject-lineInfo {
            @include flex();
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid rgba($gray, .4);
            h2 {
                @include font(20);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 0;
                text-transform: capitalize;
            }
            .trash-button {
                background: none;
                border: none;
                svg {
                    path {
                        fill: $black;
                        @include transition();
                    }
                }
                &:hover {
                    svg {
                        path {
                            fill: $baseColor;
                        }
                    }
                }
            }
        }
    }
    .message-infobox {
        @include flex();
        padding-top: 10px;
        .user-profile {
            @include flex();
            align-items: center;
            justify-content: center;
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
            @include font(15);
            @include radius(50px);
            overflow: hidden;
            margin-right: 20px;
            border: 1px solid #ddd;
            img {
                width: 40px;
                height: 40px;
                object-fit: cover;
            }
            .initial{
                text-align: center;
            }
        }
        .user-detial-box {
            @include flex();
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 50px);
            position: relative;
            padding-right: 65px;
            .item {
                &:last-child {
                    position: absolute;
                    right: 0;
                    top: 2px;
                }
            }
            .name {
                @include font(14);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 5px;
                text-transform: capitalize;
                span {
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    padding: 0 12px;
                    margin-left: 5px;
                    &::after {
                        content: "(";
                        font-family: monospace;
                        @include font(16);
                        font-weight: 500;
                        color: $blackColor;
                        line-height: 1;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                    }
                    &::before {
                        content: ")";
                        font-family: monospace;
                        @include font(16);
                        font-weight: 500;
                        color: $blackColor;
                        line-height: 1;
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 1;
                    }
                }
            }
            .text-box {
                @include font(14);
                font-weight: 400;
                color: $black;
                line-height: 1.7;
                padding-top: 2px;
                text-align: justify;
                p {
                    margin-bottom: 15px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .time-text {
                @include font(13);
                font-weight: 400;
                color: $blackColor;
                text-align: right;
            }
            .trash-button {
                background: none;
                border: none;
                svg {
                    path {
                        fill: $black;
                        @include transition();
                    }
                }
                &:hover {
                    svg {
                        path {
                            fill: $baseColor;
                        }
                    }
                }
            }
        }
    }
    .message-hereInfo {
        padding-bottom: 20px;
        .message-infobox {
            padding: 15px;
            border-top: 1px solid rgba($gray, .4);
            border-bottom: 1px solid rgba($gray, .4);
            .user-profile {
                flex: 0 0 40px;
                width: 40px;
                height: 40px;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                overflow: hidden;
                margin-right: 20px;
                @include font(15);
                border: 1px solid #ddd;
                margin-top: 0px;
            }
            .user-detial-box {
                .item {
                    &:last-child {
                        top:auto;
                    }
                }
            }
            .time-text {
                top: auto;
            }
        }
        .message-replyInfo {
            padding: 25px 20px;
            .form-group {
                margin-bottom: 20px;
                .toName{
                    border: 1px solid #ddd;
                    padding: 8px;
                    border-radius: 20px;
                }
                label {
                    display: block;
                    @include font(16);
                    font-weight: 600;
                    color: rgba($black, .7);
                    margin-bottom: 12px;
                }
                textarea {
                    display: block;
                    width: 100%;
                    min-height: 180px;
                    padding:18px 15px;
                    @include font(14);
                    font-weight: 400;
                    color: $black;
                    border: 1px solid rgba($gray, .4);
                    @include radius(5px);
                    &::placeholder {
                        color: rgba($black, .7);
                    }
                }
                .btn {
                    padding: 12px 20px;
                    min-width: 100px;
                    background:$lightBlue;
                    @include font(14);
                    font-weight: 400;
                }
                &:last-child {
                    margin-bottom: 0;;
                }
            }
            &.nomessage {
                .form-group {
                    textarea {
                        @include font(16);
                        font-weight: 700;
                        color: $gray;
                        line-height: 1.4;
                        &::placeholder {
                            color: $gray;
                        }
                    }
                    .btn {
                        background:$gray;
                        @include font(16);
                        font-weight: 700;
                        color: rgba($black, .5);
                    }
                }
            }
        }
        &.newMessage {
            .message-replyInfo {
                .form-group {
                    display: block;
                    &.flexbox {
                        @include flex();
                        align-items: center;
                        padding-bottom: 20px;
                        border-bottom: 1px solid rgba($gray, .4);
                        label {
                            margin: 0 10px 0 0;                        
                        }
                    }
                    label {
                        @include font(14);
                        color: $black;
                        margin-bottom: 12px;
                    }
                    input {
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        border: none;
                        width: 100%;
                        &::placeholder {
                            color: $black;
                        }
                    }
                    select {
                        appearance: none;
                        background-image: url(../img/arrowdown.png);
                        background-repeat: no-repeat;
                        background-size: 14px;
                        background-position:right 15px center;
                        width: 100%;
                        padding: 10px 40px 10px 15px;
                        @include font(14);
                        font-weight: 400;
                        color: $black;
                        line-height: 22px;
                        border: 1px solid rgba($gray, .4);
                        height: 45px;
                        &::placeholder {
                            color: $black;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .two-column-flex {
                    @include flex();
                    justify-content: space-between;
                    align-items: center;
                    .half {
                        width: calc(50% - 10px);
                        label {
                            margin-bottom: 12px;
                        }
                        input {
                            width: 100%;
                            padding: 10px 15px;
                            @include font(14);
                            font-weight: 400;
                            color: $black;
                            border: 1px solid rgba($gray, .4);
                            height: 45px;
                            &::placeholder {
                                color: $black;
                            }
                        }
                        select {
                            appearance: none;
                            background-image: url(../img/arrowdown.png);
                            background-repeat: no-repeat;
                            background-size: 14px;
                            background-position:right 15px center;
                            width: 100%;
                            padding: 10px 40px 10px 15px;
                            @include font(14);
                            font-weight: 400;
                            color: $black;
                            border: 1px solid rgba($gray, .4);
                            line-height: 22px;
                            height: 45px;
                            &::placeholder {
                                color: $black;
                            }
                        }
                    }
                }
                .error {
                    padding-top: 15px;
                    @include font(14);
                    font-weight: 400;
                    color: red;
                    line-height: 1.4;
                    span {
                        color: red;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) { 
    .notification-main-wrapper {
        .notification-block-left {
            width: 230px;
        }
        .notification-block-right {
            width: calc(100% - 245px);
            .notification-detail-box {
                .notification-item {
                    .item {
                        .user-detial-box {
                            .name {
                                @include font(14);
                            }
                            .content-with-img {
                                .text-box {
                                    p {
                                        @include font(13); 
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
    .notification-with-sidebar {
        .notification-sidebar {
            width: 180px;
        }
        .notification-detail-box {
            width: calc(100% - 180px);
            .notification-search-box {
                padding-left: 15px;
                padding-right: 15px;
                .search-box {
                    label {
                        @include font(16);
                    }
                }
            }
            .notification-pagination {
                > ul {
                    li {
                        margin-right: 5px;
                        button {
                            padding: 0;
                        }
                    }
                }
            }
            .notification-item {
                padding-left: 15px;
                padding-right: 15px;
                .button-boxinfo {
                    @include flex();
                    align-items: center;
                    width: 70px;
                    .item {
                        width: 22px;
                        margin-right: 15px;
                        &:first-child {
                            margin-right: 10px;
                        }
                        .button {
                            background: none;
                            outline: none;
                            border: none;
                            padding: 0 5px;
                        }
                        .checkbox-info {
                            display: inline-flex;
                            align-items: center;
                            position: relative;
                            input[type="checkbox"] {
                              position: absolute;
                              width: 100%;
                              height: 100%;
                              left: 0;
                              top: 0;
                              opacity: 0;
                              z-index: 1;
                              cursor: pointer;
                              @include radius(2px);
                              &:checked {
                                + label {
                                    &::before {
                                        border-color: $lightBlue;
                                    }
                                    &::after {
                                        content: "";
                                        display: inline-block;
                                        background: url(../img/checkBlue.png) no-repeat;
                                        background-size: cover;
                                        position: absolute;
                                        width: 12px;
                                        height: 12px;
                                        left: 3px;
                                        top: 5px;
                                    }
                                }
                              }
                            }
                            label {
                              position: relative;
                              font-size: 14px;
                              color: $black;
                              font-weight: normal;
                              padding-left: 18px;
                              cursor: pointer;
                              line-height: 18px;
                              color: $black;
                              &::before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 18px;
                                height: 18px;
                                border: 1px solid $black;
                                @include radius(2px);
                              }
                            }
                        }
                    }
                }
                .user-detial-box {
                    @include font(15);
                    .text-box {
                        display: block;
                        .subject-line {
                            display: block;
                            width: 100%;
                            margin-bottom: 5px;
                        }
                        p {
                            @include font(13);
                            width: 100%;
                        }
                    }
                    .item {
                        &:last-child {
                            top: 22px;
                        }
                    }
                    .msg-reply-trash-button {
                        background: none;
                        border: none;
                        margin-top: 5px;
                    }
                }
            }
        } 
    }
    .notification-messageDetail {
        .message-infobox { 
            .user-profile {
                margin-right: 12px;
            }
            .user-detial-box {
                .name {
                    span {
                        margin-left: 0;
                    }
                }
                .text-box {
                    @include font(13);
                    p {
                        margin-bottom: 8px;
                    }
                }
            }
        }
        .message-hereInfo {
            &.newMessage {
                .message-replyInfo {
                    .two-column-flex {
                        display: block;
                        .half {
                            width: 100%;
                            margin-bottom: 15px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) { 
    .notification-main-wrapper {
        display: block;
        .notification-block-left {
            width: 100%;
            margin-bottom: 20px;
        }
        .notification-block-right {
            width: 100%;
        }
    }
    .notification-with-sidebar {
        display: block;
        .notification-sidebar {
            width: 100%;
            ul {
                li {
                    padding: 15px;
                    &.button-link {
                        padding: 20px 15px;
                    }
                }
            }
        }
        .notification-detail-box {
            width: 100%;
            border-left: none;
        }
    }
    .notification-messageDetail {
        .message-detailInfo {
            padding: 15px;
            .subject-lineInfo {
                h2 {
                    @include font(16);
                }
            }
        }
        .message-hereInfo {
            padding-bottom: 0;
            .message-replyInfo {
                padding: 20px 15px;
                &.nomessage {
                   .form-group {
                        textarea {
                            @include font(14);
                        }
                   }
                }
                .form-group {
                    &.flexbox {
                        margin-bottom: 15px;
                        padding-bottom: 15px!important;
                    }
                    textarea {
                        min-height: 120px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 567px) {
    .notification-main-wrapper {
        .notification-block-right {
            .notification-detail-box {
                .notification-item {
                    .item {
                        width: 85%;
                        .user-detial-box {
                            .content-with-img {
                                align-items: flex-start;
                                margin-right: -20%;
                            }
                        }
                    }
                    .notification-dropdown-info {
                        width: calc(100% - 85%);
                    }
                }
            }
        }
    }
    .notification-with-sidebar {
        .notification-sidebar {
            ul {
                li {
                    padding: 10px 15px;
                    a {
                        @include font(15);
                    }
                }
            }
        }
        .notification-detail-box {
            .notification-search-box {
                display: block;
                position: relative;
                .search-box {
                    display: block;
                    margin-right: 0;
                    label {
                        margin-bottom: 12px;
                    }
                }
            }
            .notification-pagination {
                position: absolute;
                right: 0;
                top: 10px;
            }
            .notification-item {
                align-items: flex-start;
                .user-detial-box {
                    @include font(14);
                    .text-box {
                        p {
                            -webkit-line-clamp: 2;
                        }
                    }
                    .item {
                        &:last-child {
                            top: 35px;
                        }
                    }
                }
            }
            &.with-back {
                .notification-search-box {
                    @include flex();
                    .notification-pagination {
                        position: static;
                        top: auto;
                    }
                }
            }
        }
    }
}