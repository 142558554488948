@import  "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/reset";


.onBoardBaseballSection{
    min-height: calc(100vh - 120px);
    padding: 20px 15px;
    @include flex();
    justify-content: center;
    margin-top: 0px;
    .baseBallBlock{
        width: 100%;
        max-width: 420px;
        margin: 0px auto; 
        @include radius(6px);
        
        .formSection{
            padding: 30px 30px;
            border: 1px solid $gray;
            width: 100%;
            @include radius(6px);
            .heading{
                font-size: 16px;
                font-weight: 600;
                margin: 0px 0px 30px 0px;
                font-weight: 600;
                line-height: 22px;
                color:$black;
            }
            .headingYear{
                font-size: 16px;
                margin: 0px 0px 10px 0px;
                display: block;
                color: #3e3e3e;
                font-weight: 600;
            }
            .MuiSelect-select{
                border: 1px solid #000;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    outline: none;
    width: 100%;
    padding: 12px 10px;
    color: #3e3e3e;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 16px;
    font-size: 14px;
    line-height: 20px;
            }
           .recruitProcessBox{
               ul{
                   margin:0px 0px;
                   padding:0px 0px;
                   li{
                       list-style: none;
                       margin:0px 0px 15px 0px;
                       span{
                           display: inline-flex;
                           align-items: center;
                           position: relative;
                           input[type=checkbox]{
                               position: absolute;
                               left:0px;
                               top:0px;
                               width:100%;
                               height: 100%;
                               opacity: 0;
                               z-index: 1;
                               cursor: pointer;
                               &:checked + label{
                                &::before{
                                    border-color:$lightBlue ;
                                }
                                &::after{
                                 width: 12px;
                                 height: 12px;
                               background: url(../../assets/img/checkBlue.png) no-repeat;
                              background-size: cover;
                                 content: "";
                                 display: inline-block;
                                 position: absolute;
                                 left: 3px;
                                 top: 4px;
                                }
                               }
                           }
                           
                           label{
                            position: relative;
                            font-size: 14px;
                            color:$black;
                            font-weight: normal;
                            padding-left: 30px;
                            cursor: pointer;
                            line-height:18px;
                            &::before{
                                width: 18px;
                                height: 18px;
                                border: 1px solid $black;
                                content: "";
                                display: inline-block;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                       }
                    
                   }
               }
           }
     
        }
        .continueBtnBox{
            text-align: center;
            margin-top: 35px;
            a{
                outline: none;
                box-shadow: none;
                cursor: pointer;
                background-color: $green;
                color: $white;
                padding: 10px 15px;
                @include radius(4px);
                border: none;
                font-size: 16px;
                min-width: 100px;
                min-height: 45px;
                text-decoration: none;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width:479px) {
    .onBoardBaseballSection{
        .baseBallBlock{
            .formSection{
                padding: 20px 20px;
            }
            .continueBtnBox{
                a{
                    width: 100%;
                }
            }
        }
    }  
}