@import '../../../../../../assets/css/mixin';
@import '../../../../../../assets/css/variables';
@import '../../../../../../assets/css/reset';

.signUpAthleteInfoSection{
    @include flex();
    justify-content: center;
    padding: 20px 15px;
    min-height: calc(100vh - 120px);
    margin-top: 0;
    .athleteInfoBlock{
        width: 100%;
        max-width: 560px;
        margin: 0 auto; 
        @include radius(6px);
        .formSection{
            padding: 20px 30px 30px 30px;
            border: 1px solid $gray;
            @include radius(6px);
            .heading{
                @include font(20);
                color: $black;
                font-weight: 600;
                margin: 0 0 20px 0;
                .checkIcon{
                    margin-left: 10px;
                }
            }
            .subHead{
                @include font(18);
                color: $black;
                font-weight: 600;
                text-align: center;
                margin: 0 0 30px 0;
            }
            form{
                > div{
                    margin-bottom: 20px;
                    input{
                        padding: 10px;
                        @include font(14);
                        color:$black;
                        width: 100%;
                        border: 1px solid $gray;
                        @include radius(4px);
                        outline: none;
                        box-shadow: none;
                        &::placeholder{
                            color: $black;
                        }
                    }
                    &.fieldBox{
                        label{
                            display: inline-flex;
                            align-items: center;
                            @include font(16);
                            color: $black;
                            font-weight: 600;
                            margin: 0 0 10px 0;
                            sup{
                                display: inline-flex;
                                color:$baseColor ;
                                margin-bottom: -10px;
                                margin-left: 3px;
                            }
                        }
                        select{
                            width: 100%;
                            -webkit-appearance: none;
                            appearance: none;
                            padding: 14px 15px 14px 10px;
                            @include font(14);
                            color: $black;
                            background-image: url(../img/arrowdown.png);
                            background-repeat: no-repeat;
                            background-position: 95% center;
                            background-size: 16px;
                            border: 1px solid $gray;
                            @include radius(4px);
                        }
                       &.flexBox{
                           @include flex();
                           align-items: center;
                           justify-content: space-between;
                           .half{
                               width: calc(50% - 15px);
                               input{
                                   width: 100%;
                               }
                           }
                       }
                       &.phone{
                           .flexBox{
                            @include flex();
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 10px;
                            @include radius(4px);
                            border: 1px solid $gray;
                             .countryFlag{
                                 width: 35px;
                                 img{
                                     max-width: 100%;
                                     height: auto;
                                 }
                             }
                              select{
                                  @include font(14);
                                  color: $black;
                                  padding: 5px 15px 5px 5px;
                                  -webkit-appearance: button;
                                  appearance: button;
                                  width: 70px;
                                  background: none;
                                  outline: none;
                                  border: none;
                              }
                              input{
                                  width: calc(100% - 125px);
                                  border: none;
                              } 
                           }
                       }
                    }
                    &.radioBox{
                        h5{
                            display: inline-flex;
                            align-items: center;
                            @include font(16);
                            color: $black;
                            font-weight: normal;
                            line-height: 22px;
                            margin: 0 0 15px 0;
                            span{
                                display: inline-block;
                            }
                            sup{
                                display: inline-flex;
                                color:$baseColor ;
                                margin-bottom: -10px;
                                margin-left: 3px;
                            }
                        }
                        .radioflexBox{
                            @include flex();
                            align-items: center;
                            .option{
                                @include flex();
                                align-items: center;
                                position: relative;
                                margin-right: 20px;
                                input{
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    opacity: 0;
                                    cursor: pointer;
                                    z-index: 1;
                                    &:checked + label{
                                        &::before{
                                            border-color: $lightBlue;
                                        }
                                        &::after{
                                            content: "";
                                            display: inline-block;
                                            background-color: $lightBlue;
                                            position: absolute;
                                            width: 8px;
                                            height: 8px;
                                            @include radius(50%);
                                            left: 5px;
                                            top: 5px;
                                        }
                                    }
                                }
                                label{
                                    position: relative;
                                    @include font(16);
                                    color: $black;
                                    font-weight: normal;
                                    padding-left: 30px;
                                    cursor: pointer;
                                    &::before{
                                        content: "";
                                        width: 18px;
                                        height: 18px;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        border: 1px solid $black;
                                        @include radius(50%);
                                    }
                                }
                            }
                        }
                    }
                    &.pleaseCheck{
                        @include flex();
                        align-items: center;
                        position: relative;
                        input{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            z-index: 1;
                            opacity: 0;
                            cursor: pointer;
                            &:checked + label{
                                &::before{
                                    border-color: $lightBlue;
                                }
                                &::after{
                                    content: "";
                                    display: inline-block;
                                    position: absolute;
                                    left: 3px;
                                    top: 4px;
                                    background-image: url(../img/checkBlue.png);
                                    background-repeat: no-repeat;
                                    background-size: 13px;
                                    width: 13px;
                                    height: 10px;
                                }
                            }
                        }
                        label{
                            display: inline-flex;
                            align-items: flex-start;
                            position: relative;
                            padding-left: 30px;
                            line-height: 22px;
                            &::before{
                                content: "";
                                display: inline-block;
                                width: 18px;
                                height: 18px;
                                position: absolute;
                                left: 0;
                                top: 0;
                                border: 1px solid $gray;
                                @include radius(2px);
                            }
                        }
                    }
                    &.continueBtnBox{
                        margin-top: 50px;
                        margin-bottom: 20px;
                        text-align: center;
                        a{
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            text-decoration: none;
                            padding: 15px 35px;
                            background-color: $green;
                            color: $white;
                            @include radius(4px);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:575px) {
    .signUpAthleteInfoSection{
        .athleteInfoBlock{
            .formSection{
                padding: 20px;
                form{
                    div{
                        &.continueBtnBox{
                            a{
                                width: 100%;
                            }
                        }
                    }
                } 
            }
        } 
    } 
}

@media screen and (max-width:479px){
    .signUpAthleteInfoSection{
        .athleteInfoBlock{
            .formSection{
                form{
                    div{
                        &.fieldBox{
                            &.flexBox{
                                flex-wrap: wrap;
                                .half{
                                    width: 100%;
                                    margin-bottom: 20px;
                                    &:last-child{
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                            &.phone{
                                .flexBox{
                                    .countryFlag{
                                        width: 25px;
                                    }
                                    select{
                                        width: 70px;
                                    }
                                    input{
                                        width: calc(100% - 95px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}