/* Mixin */
:-webkit-input-placeholder {
  color: #ccc;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-moz-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

.flex {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@keyframes hoverEffect {
  0% {
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrow {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

@keyframes animArrowRev {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
    -webkit-transform: translateX(-5px);
    -moz-transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

*:focus, *:hover {
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

a:hover {
  text-decoration: underline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.stepper {
  margin-bottom: 30px;
  text-align: center;
}

.stepper ul {
  display: -webkit-box;
  display: -ms-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  margin: 0;
}

.stepper ul li {
  list-style: none;
  margin-right: 10px;
}

.stepper ul li span {
  display: inline-block;
  background-color: #ccc;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.stepper ul li.active {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 50px;
  height: 50px;
  border: 1px solid #1CB79E;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-shadow: 0 3px 15px #00000033;
  -webkit-box-shadow: 0 3px 15px #00000033;
  -moz-box-shadow: 0 3px 15px #00000033;
}

.stepper ul li.active span {
  background-color: transparent;
  width: auto;
  height: auto;
}

.stepper ul li.passed span {
  background-color: #1CB79E;
}

.stepper ul li:last-child {
  margin-right: 0px;
}

@media screen and (max-width: 479px) {
  .stepper ul li {
    margin-right: 6px;
  }
  .stepper ul li span {
    width: 15px;
    height: 15px;
  }
  .stepper ul li.active {
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
  }
  .stepper ul li.active svg {
    width: 22px;
    height: auto;
  }
  .stepper ul li:last-child {
    margin-right: 0px;
  }
}
