@import "../../../../../../assets/css/variables";
@import "../../../../../../assets/css/mixin";
@import "../../../../../../assets/css/reset";

.leftDataBox {
  .AthleteInfoBox {
    .AthleteInfo {
      .profileBox {
        .imgBoxBlock {
          div {
            &.icon {
              display: inline-block;
              vertical-align: middle;
              background-color: $white;
              @include radius(50%);
              svg.verified {
                .a {
                  fill: $lightBlue !important;
                }
                .b {
                  fill: $white !important;
                }
              }
              svg.unverified {
                .a {
                  fill: #ff4a31 !important;
                }
                .b {
                  fill: $white !important;
                }
              }
            }
          }
          .imgBox{
            .initialLetter{
              background: #ffffff;
              @include flex();
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              @include font(30);
              font-weight: 700;
              border: 2px solid #ffffff;
              border-radius: 100%;
              padding: 10px 10px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:991px) {
  .leftDataBox {
    .AthleteInfoBox {
      .AthleteInfo {
        .profileBox {
          .imgBoxBlock {
            .imgBox{
              .initialLetter{
                @include font(26);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:579px) {
  .leftDataBox {
    .AthleteInfoBox {
      .AthleteInfo {
        .profileBox {
          .imgBoxBlock {
            .imgBox{
              .initialLetter{
                @include font(22);
              }
            }
          }
        }
      }
    }
  }
}
