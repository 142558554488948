@import "../../../../../../../assets/css/mixin";
@import "../../../../../../../assets/css/variables";
@import "../../../../../../../assets/css/reset";

.pageContent {
  &.height100 {
    min-height: 1px !important;
  }
}
.stats-main-sec {
  .stats-box-item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      background: rgba($gray, 0.2%);
      padding: 0.8em 0.8em;
      @include font(16);
      color: $green;
      border-left: 3px solid $green;
      margin-bottom: 20px;
      &.with-button {
        @include flex();
        justify-content: space-between;
        align-items: center;
        .button-box {
          .btn {
            @include font(14);
            padding: 7px 15px;
            font-weight: 400;
            .icon-box {
              margin-right: 5px;
            }
            &.btnReport {
              background:$white;
              margin-left: 10px;
              border: 1px solid $green;
              color:$green;
              &:hover {
                background:$green;
                color:$white;
                border-color:$green;
                .icon-box {
                  svg {
                    path {
                      fill:$white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .tabBox {
    @include radius(4px);
    background-color: $white;
    border: 1px solid #00000038;
    padding: 0 15px;
    margin: 0 auto 20px auto;
    ul {
      margin: 0;
      padding: 0;
      @include flex();
      align-items: center;
      border: none;
      li {
        list-style: none;
        margin-right: 20px;
        a {
          padding: 12px 8px;
          @include font(14);
          color: $black;
          display: inline-block;
          text-decoration: none;
          position: relative;
          border: none !important;
          &:hover {
            color: $baseColor;
            border: 0;
            border-radius: 0 !important;
          }
          &.active {
            color: $baseColor;
            &::after {
              content: "";
              display: inline-block;
              position: absolute;
              bottom: -1px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: $baseColor;
            }
          }
        }
        &.closeBtnBox {
          display: none;
          text-align: right;
          button {
            padding: 5px;
            outline: none;
            background-color: transparent;
            border: none;
            cursor: pointer;
            svg {
              .a {
                opacity: 0.7;
                fill: $black;
              }
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &.reportLink {
          a {
            span {
              &.icon {
                display: inline-block;
                margin-left: 5px;
                svg {
                  .a {
                    fill: $black;
                    fill-rule: evenodd;
                  }
                }
              }
            }
            &:hover {
              span {
                &.icon {
                  .a {
                    fill: $baseColor;
                  }
                }
              }
            }
          }
        }
      }
    }
    .flexBox {
      display: none;
      div {
        &.menuBtnBox {
          margin-right: 10px;
          width: auto;
          display: block;
          .tabMenuBtn {
            border: none;
            padding: 5px;
            background: transparent;
            outline: none;
            cursor: pointer;
            svg {
              .a {
                fill: #343434;
              }
            }
          }
        }
        width: calc(100% - 36px);
        @include flex();
        align-items: center;
        p {
          @include font(15);
          color: $baseColor;
        }
      }
    }
  }
  .stats-tab-with-button {
    @include flex();
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .tabBox {
      width: 100%;
      margin-right: 15px;
      margin-bottom: 0;
    }
    .button-box {
      .btn {
        @include font(14);
        padding: 10px 15px 9px;
        .icon-box {
          margin-right: 8px;
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
  }
  .stats-no-record {
    @include flex();
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    background: $white;
    @include font(20);
    color: $gray;
    border: 1px solid rgba($black, 0.1%);
    box-shadow: 0 0 10px #0000000d;
    @include radius(4px);
    line-height: 1.5;
    min-height: 220px;
    margin-bottom: 20px;
  }
  .stats-filter-box {
    @include flex();
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .item {
      @include flex();
      align-items: center;
      width: 45%;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
      .icon-box {
        margin-right: 10px;
      }
      .content-box {
        @include font(14);
        color: $blackColor;
        h4 {
          margin-bottom: 5px;
        }
        p {
          @include font(10);
          color: $black;
        }
      }
      select {
        width: 100%;
        border: 1px solid $gray;
        @include radius(4px);
        -webkit-appearance: none;
        appearance: none;
        padding: 14px 35px 14px 10px;
        background-image: url(../img/arrowdown.png);
        background-repeat: no-repeat;
        background-position: right 10px bottom 15px;
        background-size: 12px;
        @include font(14);
        color: $black;
        letter-spacing: 0.21px;
        cursor: pointer;
        &::placeholder {
          color: $black;
        }
      }
      .MuiInputBase-root {
        display: block;
        width: 100%;
        padding: 0px 35px 0px 10px;
        line-height: 40px;
        border: 1px solid $gray;
        @include radius(4px);
        @include font(14);
        color: $black;
        letter-spacing: 0.21px;
        cursor: pointer;
      }
      .MuiSelect-select {
        padding: 0;
      }
      .MuiInput-underline {
        &:before {
          display: none;
        }
      }
    }
  }
  
}
.stats-with-next-prev {
  display: flex;
  align-items: center;
  text-align: center;
  border-top: 1px solid $gray;
  padding: 20px ;
  .next-prev-button {
    button {
      display: inline-block;
      vertical-align: middle;
      background: none;
      border: none;
      @include font(16);
      font-weight: 400;
      color: rgba($black, .6);
      padding: 0;
      margin-right: 10px;
      @include transition();
      svg {
        width: 18px;
        height: 18px;
        margin-top: -1px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: $baseColor;
      }
    }
  }
}
.stats-graph-box {
  padding: 35px 50px 35px 65px;
  background: $white;
  border: 1px solid $gray;
  margin-bottom: 20px;
  @include radius(4px);
  .graph-inner {
    position: relative;
    .graph-flex-bar {
      @include flex();
      justify-content: space-around;
      align-items: center;
    }
    .progress-bar {
      background: transparent;
      width: 40px;
      height: 100%;
      position: absolute;
      left: auto;
      margin-left: -20px;
      bottom: 0;
      z-index: 1;
      text-align: center;
      .icon-box {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
        .iconbox {
          display: inline-block;
          vertical-align: middle;
          padding: 0 2px;
          svg {
            path {
              fill: $blue;
              fill-rule: evenodd;
            }
          }
          &.red {
            svg {
              path {
                fill: $baseColor;
              }
            }
          }
          &.yellow {
            svg {
              path {
                fill: $yellow;
              }
            }
          }
          &.custom-tooltip {
            position: relative;
            overflow: visible;
            .tooltip-data {
              display: none;
              padding:8px 10px;
              background: $baseColor;
              @include font(11);
              font-weight:400;
              color:$white;
              width: auto;
              transform: translate(-50%, 0);
              height: auto;
              line-height: 15px;
              text-align: center;
              margin-top: 6px;
              position: absolute;
              top: 100%;
              left: 50%;
              z-index: 2;
              @include radius(2px);
              @include transition();
              &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: -12px;
                margin: 0 auto;
                width: 0;
                height: 0;
                transform: rotate(180deg);
                border: 0.375rem solid transparent;
                border-top-color: $baseColor;
              }
            }
            &:hover {
              .tooltip-data {
                display: block;
              }
            }
          }
        }        
      }
      &:after {
        content: "";
        background: $green;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: calc(100% - 10%);
      
      }
      &.yellow {
        &:after {
          background: $yellow;
        }
      }
      &.no-data {
        transform: translate(-50%, 0%);
        width: auto;
        margin: 0 auto;
        bottom: 19px;
        &:after {
          display: none;
        }
        .btn {
          @include font(14);
          color: $blackColor;
          border-radius: 0;
          //border: 1px solid $green;
          border: none;
          padding: 0;
          span {
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
            background: $white;
            height: 35px;
            line-height: 35px;
            @include transition();
            &.icon {
              padding: 0 8px 0 8px;
              background: $green;
              svg {
                .a {
                  fill: none;
                  stroke: $white;
                  stroke-linecap: round;
                  stroke-width: 3px;
                }
              }
            }
          }
          &:hover {
            span {
              background: $green;
              color: $white;
            }
          }
          &.custom-tooltip {
            position: relative;
            overflow: visible;
            .tooltip-data {
              display: none;
              padding:8px 10px;
              background: $baseColor;
              @include font(11);
              font-weight:400;
              color:$white;
              min-width:140px;
              height: auto;
              line-height: 15px;
              text-align: center;
              margin-top: 6px;
              transform: translate(-50%, 0%);
              position: absolute;
              width: auto;
              top: 100%;
              left: 50%;
              z-index: 2;
              @include radius(2px);
              @include transition();
              &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: -12px;
                margin: 0 auto;
                width: 0;
                height: 0;
                transform: rotate(180deg);
                border: 0.375rem solid transparent;
                border-top-color: $baseColor;
              }
            }
            &:hover {
              .tooltip-data {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .graph {
    display: block;
    border-bottom: 1px solid rgba($gray, 0.5%);
    border-left: 1px solid rgba($gray, 0.5%);
    position: relative;
    .item {
      height: 35px;
      border-bottom: 1px solid rgba($gray, 0.5%);
      position: relative;
      &:last-child {
        border-bottom: none;
      }
      span {
        display: block;
        @include font(10);
        color: $black;
        position: absolute;
        left: -31px;
        top: -5px;
      }
    }
  }
  .graph-progress-bar {
    margin: 0 auto 0 20px;
    @include font(14);
    font-weight: 400;
    color: $blackColor;
    text-align: center;
    @include flex();
    justify-content: space-around;
    .progress-bar {
      background: transparent;
      width: 40px;
      height: 100%;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      bottom: 0;
      z-index: 1;
      text-align: center;
      .icon-box {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -8px;
        z-index: 1;
        svg {
          path {
            fill: $blue;
            fill-rule: evenodd;
          }
        }
        &.red {
          svg {
            path {
              fill: $baseColor;
            }
          }
        }
        &.yellow {
          svg {
            path {
              fill: $yellow;
            }
          }
        }
      }
      &:after {
        content: "";
        background: $green;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: calc(100% - 10%);
        z-index: 1;
      }
      &.no-data {
        transform: translate(-50%, 0%);
        width: auto;
        margin: 0 auto;
        bottom: 19px;
        &:after {
          display: none;
        }
        .btn {
          @include font(14);
          color: $blackColor;
          border-radius: 0;
          border: 1px solid $green;
          padding: 0;
          position: relative;
          span {
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
            background: $white;
            height: 35px;
            line-height: 35px;
            @include transition();
            &.icon {
              padding: 0 5px 0 8px;
              background: $green;
              svg {
                .a {
                  fill: none;
                  stroke: $white;
                  stroke-linecap: round;
                  stroke-width: 3px;
                }
              }
            }
          }
          &:hover {
            span {
              background: $green;
              color: $white;
            }
          }
          
        }
      }
    }
    .detial-box {
      margin-top: 20px;
    }
    h4 {
      margin-bottom: 8px;
    }
    h5 {
      @include font(18);
      margin-bottom: 8px;
      a {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        color: $blackColor;
        @include radius(1px);
        @include transition();
        line-height: 0;
        // span {
        //   border-bottom: 2px solid $green;
        // }
        svg {
          display: inline-block;
          vertical-align: middle;
          margin-left: 2px;
          path {
            fill: $green;
          }
        }
        &:hover {
          color: $green;
        }
      }
    }
    p {
      @include font(12);
      font-weight: 300;
      color: $black;
      text-transform: uppercase;
      line-height: 1.2;
    }
    .button-box {
      padding-top: 12px;
      @include flex();
      justify-content: center;
      align-items: center;
      button {
        background: none;
        padding: 0 5px;
        outline: none;
        border: none;
        position: relative;
        .videoclose-btn {
          display: inline-block;
          vertical-align: middle;
          background: $baseColor;
          width: 15px;
          height: 15px;
          line-height: 1;
          font-family: monospace;
          @include font(14);
          color: $white;
          text-align: center;
          position: absolute;
          right: -5px;
          bottom: 12px;
          @include radius(50%);
          cursor: pointer;
        }
        &.link-btn {
          svg {
            .a {
              fill: $white;
              stroke: $black;
              @include transition();
            }
            .b {
              fill: $black;
              @include transition();
            }
            .c {
              stroke: none;
            }
            .d {
              fill: none;
            }
          }
          &:hover {
            svg {
              .a {
                stroke: $baseColor;
              }
              .b {
                fill: $baseColor;
              }
            }
          }
        }
        &.video-btn {
          svg {
            .a {
              fill: $black;
              @include transition();
            }
          }
          &.trash {
            svg {
              .a,
              .b {
                fill: $baseColor;
                @include transition();
              }
              // .a {
                // opacity: 0.6;
              // }
              // .b {
                // opacity: 0.5;
              // }
              .c {
                fill: $baseColor;
                stroke: $baseColor;
              }
              .d {
                fill: $white;
              }
              .e {
                stroke: none;
              }
              .f {
                fill: none;
              }
            }
            &:hover {
              svg {
                .a,
                .b {
                  fill: $green;
                  opacity: 1;
                }
              }
            }
          }
          &:hover {
            svg {
              .a {
                fill: $baseColor;
              }
            }
          }
        }
        &.edit-btn {
          margin-bottom: 0;
          svg {
            .a {
              fill: $baseColor;
              @include transition();
            }
          }
          &:hover {
            svg {
              .a {
                fill: $green;
              }
            }
          }
        }
      }
    }
    &.no-data {
      p {
        @include font(14);
        font-weight: 400;
        color: $baseColor;
        text-transform: none;
        svg {
          margin-right: 5px;
          path {
            fill: $baseColor;
          }
        }
      }
    }
  }
}
.graph-edit-button {
  .edit-button {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    background: rgba($gray, 0.5%);
    @include font(14);
    color: $black;
    border: none;
    outline: none;
    @include transition();
    padding: 0;
    margin-right: 10px;
    &.green {
      background: $green;
      color: $white;
      .icon {
        border-right: 1px solid $white;
        svg {
          path {
            fill: $white;
          }
        }
        &.plus {
          svg {
            .a {
              stroke: $white;
            }
          }
        }
      }
      &:hover {
        background: $baseColor;
      }
    }
    span {
      display: inline-block;
      vertical-align: middle;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      &:first-child {
        min-width: 40px;
      }
      &:last-child {
        min-width: 100px;
      }
      svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        path {
          fill: $white;
        }
      }
    }
    .icon {
      border-right: 1px solid $white;
      svg {
        path {
          fill: $black;
          @include transition();
        }
      }
      &.plus {
        svg {
          .a {
            fill: none;
            stroke: $black;
            stroke-linecap: round;
            stroke-width: 3px;
          }
        }
      }
    }
    &:hover {
      background: $green;
      color: $white!important;
      .icon {
        border-right: 1px solid $white;
        svg {
          path {
            fill: $white;
          }
        }
        &.plus {
          svg {
            .a {
              stroke: $white;
            }
          }
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.edit-graph-flex {
  @include flex();
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .title-text {
    @include font(14);
    color: $blackColor;
    margin-right: 15px;
  }
  .button-box {
    .btn {
      margin-right: 10px;
      padding: 10px 20px;
      @include font(14);
      color: $white;
      &.gray {
        background: rgba($gray, 0.49%);
        color: $blackColor;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.stats-feature-list {
  .item {
    padding: 0;
    margin-bottom:25px;
    .detial-box {
      @include flex();
      align-items: center;
      padding: 15px;
      background: $white;
      @include font(14);
      font-weight: 400;
      color:#656565;
      text-align: center;
      border: 1px solid #E2E2E2;
      @include radius(12px);
      width: 100%;
      min-height: 165px;
      .inner-box {
        width: 100%;
      }
    }
    button.edit-btn {
      background: none;
      padding: 0;
      border: none;
      outline: none;
      margin-bottom: 10px;
      svg {
        .a {
          fill: $baseColor;
          @include transition();
        }
      }
      &:hover {
        svg {
          .a {
            fill: $green;
          }
        }
      }
    }
    &.stats-timeline-item {
      padding: 0!important;
      border: none!important;
      border-radius: 0!important;
      margin-bottom:25px !important;
      h5 {
        min-height:15px;
      }
      h6 {
        min-height:55px;
      }
    }
    h4 {
      @include font(15);
      font-weight: 500;
      color: $baseColor;
      text-transform: uppercase;
      line-height: 1.3;
      margin-bottom: 8px;
      .icon-box {
        @include flex();
        align-items: center;
        margin-left: 5px;
        img {
          width: 20px;
        }
      }
    }
    h5 {
      @include flex();
      justify-content: center;
      align-items: center;
      @include font(18);
      font-weight: 600;
      line-height: 0;
      min-height:25px;
      margin-bottom: 8px;
      span {
        @include flex();
        align-items: center;
      }
      .icon-box {
        margin-left: 8px;
        svg {
          path {
            fill: $blue;
            fill-rule: evenodd;
          }
        }
        &.red {
          svg {
            path {
              fill: $baseColor;
            }
          }
        }
        &.green {
          svg {
            path {
              fill: $green;
            }
          }
        }
        &.yellow {
          svg {
            path {
              fill: $yellow;
            }
          }
        }
        &.custom-tooltip {
          position: relative;
          overflow: visible;
          .tooltip-data {
            display: none;
            padding:5px 10px;
            background: $baseColor;
            @include font(11);
            font-weight:400;
            color:$white;
            width: 130px;
            transform: translate(-50%, 0);
            height: auto;
            line-height: 15px;
            text-align: center;
            margin-top: 6px;
            position: absolute;
            top: 100%;
            left: 50%;
            z-index: 2;
            @include radius(2px);
            @include transition();
            &:after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: -12px;
              margin: 0 auto;
              width: 0;
              height: 0;
              transform: rotate(180deg);
              border: 0.375rem solid transparent;
              border-top-color: $baseColor;
            }
          }
          &:hover {
            .tooltip-data {
              display: block;
            }
          }
        }
      }
    }
    h6 {
      margin-bottom: 6px;
      color:#656565;
      font-weight:400;
      min-height: 30px;
    }
    p {
      @include font(11);
      font-weight: 300;
      color: $black;
      line-height: 1.5;
      min-height:20px;
    }
    .button-box {
      padding-top: 18px;
      @include flex();
      justify-content: space-between;
      align-items: center;
      button {
        background: none;
        padding: 0 0;
        outline: none;
        border: none;
        position: relative;
        .number-of-value {
          display: inline-block;
          vertical-align: middle;
          padding: 4px;
          background: $lightBlue;
          @include font(11);
          color: $white;
          font-weight: 500;
          @include radius(50%);
          position: absolute;
          width: 18px;
          height: 18px;
          right: -12px;
          margin: 0 auto;
          top: -10px;
        }
        &.link-btn {
          svg {
            .a {
              fill: $white;
              stroke: $black;
              @include transition();
            }
            .b {
              fill: $black;
              @include transition();
            }
            .c {
              stroke: none;
            }
            .d {
              fill: none;
            }
          }
          &:hover {
            svg {
              .a {
                stroke: $baseColor;
              }
              .b {
                fill: $baseColor;
              }
            }
          }
        }
        &.video-btn {
          svg {
            .a {
              fill: $black;
              @include transition();
            }
          }
          &.red {
            svg {
              .a {
                fill: $baseColor;
              }
              path {
                fill: $baseColor;
              }
            }
          }
          &:hover {
            svg {
              .a {
                fill: $baseColor;
              }
            }
          }
        }
        &.edit-btn {
          margin-bottom: 0;
          svg {
            .a {
              fill: $baseColor;
              @include transition();
            }
          }
          &:hover {
            svg {
              .a {
                fill: $green;
              }
            }
          }
        }
      }
    }
    .plusbtn {
      @include font(14);
      color: $white;
      border-radius: 0;
      border: none;
      padding: 0;
      border-radius:3px;
      span {
        @include flex();
        align-items: center;
        justify-content: center;
        padding: 5px;
        background: $white;
        @include transition();
        &.icon {
          background: $green;
          svg {
            width: 14px;
            height: 14px;
            .a {
              fill: none;
              stroke: $white;
              stroke-linecap: round;
              stroke-width: 3px;
            }
          }
        }
      }
      &:hover {
        span {
          background: $green;
          color: $white;
        }
      }
    }
  }
  .owl-nav {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    button {
      background: $white;
      width: 30px;
      height: 30px;
      @include radius(50px);
      box-shadow: 0 0 3px #00000017;
      margin:0 8px;
      outline: none;
    }

    button.owl-next {
      span {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        right: 2px;
        left: auto;
        top: -6px;
      }
    }
    button span {
      font-size: 0;
      content: "";
      width: 10px;
      height: 10px;
      border-right: 2px solid $gray;
      border-bottom: 2px solid $gray;
      display: inline-block;
      position: relative;
      transform: rotate(-0deg);
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      left: 2px;
      top: -6px;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
    }
  }
}
.stats-toggle-wrapper {
  .toggle-block {
    margin-bottom: 15px;
    .toggle-title {
      background: rgba($gray, 0.2%);
      padding: 12px 15px 12px 42px;
      @include font(16);
      color: $blackColor;
      position: relative;
      cursor: pointer;
      &:after {
        content: "+";
        @include font(28);
        color: $black;
        display: block;
        width: 28px;
        height: 28px;
        line-height: 28px;
        position: absolute;
        left: 10px;
        top: 7px;
        text-align: center;
      }
      &.toggle-open {
        color: $green;
        &:after {
          content: "";
          font-size: 0;
        }
        &:before {
          content: "";
          width: 16px;
          position: absolute;
          background: $black;
          height: 2.4px;
          left: 16px;
          top: 19px;
          opacity: 0.8;
        }
      }
    }
    .toggle-content {
      padding: 20px 0 15px;
      display: none;
      @include font(14);
      color: $black;
      p {
        line-height: 1.5;
      }
    }
  }
}
.stats-position-catcher {
  margin-bottom: 20px;
  .position-catcher-flex {
    @include flex();
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .champions-title {
      @include font(14);
      color: $black;
      letter-spacing: 0.21px;
      margin-right: 15px;
    }
    .button-box {
      .btn {
        @include font(14);
        font-weight: 500;
        border-radius: 0;
      }
    }
  }
  .building-champions-chart {
    padding-bottom: 30px;
    margin-bottom: 30px;
    .table-responsive {
      margin-bottom: 30px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        border-radius: 10px;
        width: 10px;
        height: 12px;
        background: rgba($gray, 0.3%);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba($gray, 0.8%);
        cursor: pointer;
      }
    }
    .table {
      @include font(14);
      font-weight: 400;
      color: $black;
      margin-bottom: 30px;
      width: 800px;
      text-align: center;
      &.anchor-link {
        cursor: pointer;
      }
      tr {
        &.field-name {
          td,
          th {
            text-align: left;
          }  
        }
        td,
        th {
          padding: 10px;
          border-color: rgba($gray, 0.5%);
          span {
            display: block;
            padding: 5px 10px;
            border: 1px solid rgba($gray, 0.5%);
            @include radius(4px);
          }
          &:nth-child(2) {
            min-width: 100px;
          }
          input {
            padding: 5px 10px;
            border: 1px solid rgba($gray, 0.5%);
            @include radius(4px);
          }
        }
      }
      + table {
        margin-top: -10px;
      }
    }
  }
  .graph-edit-button {
    .edit-button {
      span {
        &:last-child {
          min-width: 50px;
        }
      }
    }
    .btn {
      padding: 10px 15px;
      @include font(14);
      font-weight: 400;
      margin-right: 10px;
      color:$white;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .stats-gray-box {
    background: rgba($gray, 0.1%);
    padding: 30px 15px;
    text-align: center;
    @include font(15);
    font-weight: 500;
    color: $blackColor;
    letter-spacing: 0.23px;
    p {
      margin-bottom: 15px;
      line-height: 1.5;
    }
    .btn {
      @include font(14);
      font-weight: 300;
      padding: 10px 20px;
    }
  }
  &.full-with {
    padding: 50px;
    background: $white;
    border: 1px solid $gray;
    margin-bottom: 0;
    .building-champions-chart {
      margin-bottom: 0;
      .table-item {
        position: relative;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        .table {
          margin-bottom: 0;
          td,
          th {
            min-width: 75px;
            &.last-button {
              position: -webkit-sticky;
              position: sticky;
              border-bottom: none;
              text-align: center;
            }
            .trash-stats-position-catcher {
              @include flex();
              justify-content: center;
              align-items: center;
              background: $white;
              width: 25px;
              height: 25px;
              border: 1px solid $baseColor;
              @include radius(50px);
              outline: none;
              position: relative;
              top: 22px;
              margin: 0 auto;
              svg {
                path {
                  fill: $baseColor;
                }
              }
            }
          }
        }
      }
    }
  }
}
.common-modal {
  &.stats-uploaded-video {
    .stats-video-thumbnail {
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-box-shadow: 0 1px 3px #00000029;
      box-shadow: 0 1px 3px #00000029;
      overflow: hidden;
      height: 250px!important;
    }
    .video-play-button {
      button {
        width: 60px;
        height: 60px;
      }
    }
  }
  &.stats-modal {
    &.common-slide-modal {
      .modal-header {
        padding: 20px 50px 0;
        display: block;
        text-align: center;
        border-bottom: none;
        .modal-title {
          @include font(14);
          font-weight: 500;
          color: $blackColor;
          margin-left: 0;
        }
        .close {
          @include font(25);
          font-weight: 300;
          color: $black;
          line-height: 12px;
          position: absolute;
          right: 25px;
          top: 25px;
          span {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
      .modal-body {
        padding: 15px 30px 25px;
        margin-bottom:0!important;
        border-bottom: none;
        .stats-graph-box {
          padding: 0;
          border: none;
          border-radius: none;
          margin-bottom: 0;
          .graph-inner {
            margin-left: 20px;
          }
          .graph {
            .item {
              &:first-child {
                span {
                  top: 0;
                }
              }
            }
          }
        }
      }
    }
    .modal-dialog {
      max-width: 540px;
    }
    .modal-body {
      .stats-uploadbox {
        position: relative;
        input {
          display: none;
        }
        .uploadbtn {
          display: block;
          background:$white;
          padding: 8px 75px 8px 15px;
          @include font(16);
          font-weight:400;
          color: rgba($black, .5);
          border: 2px dashed #CCCCCC80;
          @include radius(4px);
          margin: 0;
          cursor: pointer;
        }
        .video-trashbtn {
          background: none;
          padding:0;
          position: absolute;
          right: 15px;
          top: 14px;
          @include font(14);
          font-weight:400;
          color: $baseColor;
          cursor: pointer;
          border: none;
          outline: none;
          z-index:11;
        }
      }
      .fieldBox {
        &.flexBox {
          &.stat-three-col {
            .half {
              width: calc(33.33% - 12px);
            }
          }
        }
      }
    }
    .modal-footer {
      .btn {
        padding: 10px 15px;
        @include font(14);
        font-weight: 400;
        margin-left: 0;
        margin-right: 15px;
        min-width: 90px;
        &:last-child {
          margin-right: 0;
        }
      }
      &.with-trash {
        justify-content: space-between;
        .flex-item {
          margin: 0;
          .trash-button {
            @include flex();
            align-items: center;
            @include font(13);
            color: $baseColor;
            min-width: 1px;
            padding: 0;
            background: none;
            border: none;
            outline: none;
            margin: 0;
            padding-left: 20px;
            background: url(../img/trash-red-icon.svg) no-repeat;
            background-position: center left;
            min-height: 18px;
            .icon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
              svg {
                path {
                  fill: $baseColor;
                }
              }
            }
          }
        }
      }
    }
  }
  &.stats-video-modal {
    .modal-dialog {
      max-width: 540px;
    }
    .modal-content{
        width: 540px;
        margin: auto;
        @media screen and (max-width: 576px){
            width: 95%;
        }
    }
    .modal-body {
      padding: 40px 25px 25px;
      .stats-video-thumbnail {
        @include radius(5px);
        box-shadow: 0 1px 3px #00000029;
        video {
          display: inline-block;
          vertical-align: middle;
        }
        overflow: hidden;
        .video-play-button {
          button {
            width: 40px;
            height: 40px;
          }
        }
        .info-icon {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 11;
          li {
            @include flex();
            justify-content: center;
            align-items: center;
            background: $white;
            width: 33px;
            height: 33px;
            text-align: center;
            @include radius(50px);
            margin-bottom: 10px;
            cursor: pointer;
            display: none;
            opacity: 0;
            @include transition();

            &:first-child {
              display: flex;
              opacity: 1;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &.delete-button {
              svg {
                path {
                  fill: $baseColor;
                }
              }
            }
          }
        }
      }
    }
    .max-with250 {
      width: 280px;
      margin: 0 auto;
    }
    .close {
      @include font(35);
      font-weight: 300;
      color: $black;
      line-height: 12px;
      position: absolute;
      right: 25px;
      top: 20px;
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .modal-title {
      @include font(16);
      font-weight: 500;
      color: $blackColor;
      margin-bottom: 30px;
    }
    .item {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 10px;
      }
      label {
        display: block;
        @include font(14);
        color: $black;
        text-align: center;
        margin-bottom: 12px;
      }
      input,
      textarea {
        width: 100%;
        padding: 10px;
        color: $black;
        @include font(14);
        border: 1px solid $gray;
        @include radius(4px);
        height: 50px;
        &::placeholder {
          color: $black;
        }
      }
    }
    .link-box {
      @include flex();
      justify-content: center;
      align-items: center;
      padding: 2.8em 1em;
      @include font(12);
      font-weight: 400;
      color: $black;
      line-height: 1.5;
      border: 1px solid $gray;
      text-align: center;
      overflow: hidden;
      position: relative;
      box-shadow: 0px 1px 3px #00000029;
      @include radius(4px);
      .icon-box {
        @include flex();
        justify-content: center;
        background: $green;
        width: 50px;
        height: 50px;
        @include radius(50%);
        margin: 0 auto 12px;
        img {
          width: 22px;
        }
      }
      .text {
        display: block;
      }
      .browse-img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
      }
      &:hover {
        text-decoration: none;
        color: $black;
      }
    }
    &.successful {
      .modal-body {
        padding-top: 35px;
      }
      .circle-check-icon {
        margin-bottom: 15px;
        svg {
          path {
            fill: $green;
            stroke: $white;
          }
        }
      }
      p {
        color: $blackColor;
        font-weight: 500;
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .stats-main-sec {
    .tabBox {
      ul {
        li {
          margin-right: 10px;
        }
      }
    }
    .stats-no-record {
      min-height: 150px;
    }
  }
  .stats-feature-list {
    margin-right: 0;
  }
  .stats-graph-box {
    .graph {
      .item {
        height: 30px;
      }
    }
    .graph-inner {
      .progress-bar {
        &.no-data {
          bottom: 17px;
          .btn {
            span {
              height: 28px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
  .stats-toggle-wrapper {
    .toggle-block {
      .toggle-title {
        @include font(15);
      }
      .toggle-content {
        padding: 15px 0 0;
        @include font(13);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .stats-position-catcher {
    .position-catcher-flex {
      .button-box {
        .btn {
          padding: 5px 10px;
        }
      }
    }
    .building-champions-chart {
      padding-bottom: 15px;
      margin-bottom: 25px;
      .table {
        @include font(12);
        margin-bottom: 25px;
        tr {
          td,
          th {
            padding: 8px;
          }
        }
      }
    }
    .graph-edit-button {
      .btn {
        padding: 8px 15px;
      }
    }
    &.full-with {
      padding: 25px 22px;
    }
  }
}
@media screen and (max-width: 991px) {
  .stats-main-sec {
    .stats-no-record {
      @include font(16);
      min-height: 100px;
    }
  }
  .stats-graph-box {
    .graph-progress-bar {
      &.no-data {
        p {
          @include font(14);
        }
      }
    }
  }
  .stats-position-catcher {
    .stats-gray-box {
      padding: 25px 15px 30px;
    }
  }
  .stats-toggle-wrapper {
    .toggle-block {
      .toggle-title {
        @include font(14);
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .stats-main-sec {
    .tabBox {
      padding-left: 10px;
      padding-right: 10px;
      ul {
        li {
          margin-right: 2px;
          a {
            padding-left: 5px;
            padding-right: 5px;
            @include font(13);
          }
        }
      }
    }
    .stats-box-item {
      .title {
        @include font(14);
      }
    }
  }
  .stats-main-sec {
    .stats-box-item {
      &:nth-child(2) {
        margin-bottom: 10px;
      }
    }
  }
  .stats-graph-box {
    padding-top: 30px;
    padding-bottom: 30px;
    &.state-show-scroller {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        border-radius: 10px;
        width: 8px;
        height: 8px;
        background: rgba($gray, 0.3%);
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba($gray, 0.8%);
        cursor: pointer;
      }
      .graph-inner, 
      .graph-progress-bar {
        min-width: 650px;
      }
    }
  }
  .stats-position-catcher {
    .building-champions-chart {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
  .edit-graph-flex {
    display: block;
    text-align: center;
    .title-text {
      margin-bottom: 15px;
    }
  }
}
@media screen and (max-width: 639px) {
  .stats-main-sec {
    margin-top: -15px;
    .stats-filter-box {
      .item {
        width: auto;
      }
      .item {
        .MuiInputBase-root {
          width: 145px;
        }
      }
    }
    .stats-tab-with-button {
      .tabBox {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .tabBox {
      padding: 10px;
      nav {
        position: fixed;
        left: -300px;
        width: 250px;
        height: 100%;
        background-color: $white;
        padding: 15px 15px;
        top: 0px;
        overflow-y: scroll;
        z-index: 9999;
        transition: all 0.5s ease-in-out;
        ul {
          display: block;
          li {
            border-bottom: 1px solid $gray;
            padding-bottom: 0;
            margin-bottom: 0;
            margin-right: 0;
            a {
              width: 100%;
              padding: 15px 10px;
              &.active {
                &:after {
                  height: 1px;
                  bottom: -1px;
                }
              }
            }
            &.closeBtnBox {
              display: block;
              padding-bottom: 20px;
            }
          }
        }
        &::after {
          content: "";
          display: inline-block;
          transition: all 0.5s ease-in-out;
          width: 0;
          left: -300px;
          height: 100%;
          position: fixed;
          top: 0;
          background: rgba(0, 0, 0, 0.5);
        }
        &.show {
          left: 0;
          &::after {
            width: calc(100% - 250px);
            left: 250px;
          }
        }
      }
      .flexBox {
        @include flex();
        div {
          p {
            padding-top: 4px;
          }
        }
      }
    }
  }
  .stats-graph-box {
    padding: 25px 15px 20px 30px;
    .graph {
      .item {
        span {
          width: 19px;
          left: -24px;
          overflow: hidden;
        }
      }
    }
    .graph-progress-bar {
      h4 {
        @include font(14);
      }
      h5 {
        @include font(16);
      }
    }
  }
  .stats-position-catcher {
    .position-catcher-flex {
      .champions-title {
        @include font(13);
      }
      .button-box {
        .btn {
          @include font(13);
        }
      }
    }
    &.full-with {
      padding: 20px 15px;
      .building-champions-chart {
        .table-item {
          margin-bottom: 20px;
        }
      }
    }
  }
  .stats-toggle-wrapper {
    .toggle-block {
      .toggle-title {
        padding-left: 38px;
        &:after {
          left: 5px;
          top: 5px;
        }
        &.toggle-open {
          &:before {
            left: 10px;
            top: 18px;
          }
        }
      }
    }
  }
  .graph-edit-button {
    .edit-button {
      @include font(12);
      margin-right: 5px;
      margin-bottom: 10px;
      span {
        &:last-child {
          min-width: 95px;
        }
      }
    }
  }
  .common-modal {
    &.stats-video-modal {
      .max-with250 {
        width: 100%;
      }
      .modal-body {
        padding: 30px 20px 20px;
      }
      .modal-title {
        margin-bottom: 20px;
      }
      .link-box {
        padding: 1.2em 1em;
      }
      .item {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 579px) {
  .common-modal {
    &.stats-modal {
      .modal-body {
        .fieldBox {
          &.flexBox {
            &.stat-three-col {
              .half {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .stats-feature-list {
    .item {
      .detial-box {
        min-height: 150px;
        padding:15px;
      }
      h5 {
        @include font(18);
      }
      h6 {
        @include font(13);
        min-height:1px;
      }
    }
  }
}
@media screen and (max-width: 567px) {
  .stats-main-sec {
    .stats-tab-with-button {
      display: block;
      .tabBox {
        margin-bottom: 15px;
        padding: 0 10px;
      }
      .button-box {
        @include flex();
        justify-content: center;
        .btn {
          width: 100%;
        }
      }
    }
    .stats-box-item {
      .title {
        &.with-button {
          display: block;
          .button-box {
            display: flex;
            padding-top: 10px;
          }
        }
      }
    }
    .stats-no-record {
      @include font(15);
      min-height: 90px;
    }
    .stats-position-catcher {
      .stats-gray-box {
        padding: 15px 15px 20px;
        @include font(14);
      }
    }
  }
  .stats-feature-list {
    .item {
      &.stats-timeline-item {
        h6 {
          min-height: 1px;
        }
      }
    }
  }
  .common-modal {
    &.stats-modal {
      .modal-footer {
        .btn {
          @include font(13);
        }
      }
    }
  }
}

.matDialogCustomStyling{
    .MuiDialog-container {
        .MuiPaper-root {
            max-width: 800px;
        }
    }
}
.modal{
    &.common-slide-modal, &.common-modal{
        .modal-dialog{
            margin: 0 auto;
        }


    }
}

#videoPhotosModal{
    .modal-dialog{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1090;
        min-height: unset;
        @media screen and (max-width: 992px){
            width: 500px;
        }
        @media screen and (max-width: 576px){
            width: 95%;
        }
    }
    .modal-content{
        width: 800px;
        @media screen and (max-width: 992px){
            width: 95%;
            margin: auto;
        }
    }
}

#StatsVideoUploadModalTwo{
    .modal-content{
        width: 480px;
        margin: auto;
        @media screen and (max-width: 576px){
            width: 95%;
        }
        .modal-body{
            .content-box{
                width: 100%;
                .img-box {
                    width: 100%;
                    max-height: 250px;
                }
            }
        }
    }
}

#AddVideoModal{
    width: 480px;
    @media screen and (max-width: 576px){
        width: 100%;
        margin: auto;
    }
}

.matDialogCustom{
    .MuiDialog-container{
        .MuiPaper-root{
            background-color: transparent;
            box-shadow: none;
            width: -moz-fit-content;
            width: fit-content;
            margin: 0;
            .modal {
                position: unset;
                .modal-content{
                    box-shadow: none;
                }
            }
        }
    }

    &.matDialogVideoPhotos{
        .MuiDialog-container{
            .MuiPaper-root{
                max-width: 800px;
                width: 100%;
                @media screen and (max-width: 992px){
                    max-width: 500px;
                }
            }
        }
    }

    &.matDialogAddYard, &.matDialogCustomOverlay{
        .MuiDialog-container{
            .MuiPaper-root{
                max-height: 100%;
                @media screen and (max-width: 992px){
                    width: 100%;
                }
                .modal{
                    &::-webkit-scrollbar {
                       display: none;
                    }
                    &::-webkit-scrollbar-track {
                        display: none;
                    }
                }
            }
        }
    }

    &.matCustomSize{
        .MuiDialog-container {
            .MuiPaper-root {
                @media screen and (max-width: 600px){
                    width: 100%;
                    margin: auto;
                }
            }
        }
    }

    &.matDialogAddParent{
        .MuiDialog-container{
            .MuiPaper-root{
                justify-content: center;
                max-height: 100%;
                height: 100%;
            }
        }
    }
}

#statusUpdateModal{
    align-items: flex-start;
}

#pressOverlay{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    bottom: unset;
    right: unset;
    box-shadow: 0 3px 20px #00000080;
    @media screen and (max-width: 576px){
        width: 95%;
        margin: auto;
    }
    .modal-content{
        margin: 0;
        width: 538px;
        @media screen and (max-width: 576px){
            width: 95%;
            margin: auto;
        }
    }
}

#StatsGraphModal{
    .modal-dialog{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1090;
        min-height: unset;
        @media screen and (max-width: 660px){
            width: 100%;
        }
        .modal-content{
            width: 540px;
            @media screen and (max-width: 660px){
                width: 90%;
                margin: auto;
            }
        }
    }
}

.matCustomBackdrop{
    .MuiBackdrop-root{
        display: none;
    }
}

#StatsGraphModal{
    .modal-dialog{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1090;
        @media screen and (max-width: 576px){
            position: relative;
        }
        .modal-content{
            width: 540px;
            @media screen and (max-width: 660px){
                width: 90%;
                margin: auto;
            }
        }
    }
}

.matCustomBackdrop{
    .MuiBackdrop-root{
        display: none;
    }
}