@import  "../../assets/css/mixin";
@import "../../assets/css/variables";
@import "../../assets/css/reset";


.onBoardAddProfileSection{
    @include flex();
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 120px);
    padding: 40px 15px;
    margin-top: 60px;
    .addProfileBlock{
        width: 100%;
        max-width: 420px;
        margin: 0 auto; 
        @include radius(6px);
        .formSection{
            padding: 30px 30px;
            width: 100%;
            border: 1px solid $gray;
            @include radius(6px);
            .heading{
                @include flex();
                align-items: center;
                justify-content: center;
                text-align: center;
                @include font(20);
                font-weight: 600;
                color:$black;
                margin: 0 0 30px 0;
                span{
                    &.icon{
                        display:inline-flex;
                        margin-left: 10px;
                        .a{
                            fill:#00b9ff !important; 
                            stroke: none;
                        }
                        .b{
                            fill:#fafafa;
                        } 
                    }
                }
            }
            .imageBox{
               .detailBox{
                    @include flex();
                    align-items: center;
                    flex-direction: column;
                    background: rgba(233, 233, 233,0.3);
                    padding:10px;
                    width: 100%;
                    max-width:286px;
                    min-height: 175px;
                    @include radius(8px);
                    margin:100px auto 20px auto;
                    .imgUploadBox{
                        display:inline-flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        background-color:$white;
                        width: 160px;
                        height: 160px;
                        margin:-80px auto 40px auto;
                        border:2px solid #00B9FF;
                        @include radius(50%);
                        cursor: pointer;
                        input{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top:0;
                            z-index: 1;
                            opacity:0;
                            cursor: pointer;
                        }
                        img{
                            max-width: 100%;
                            max-height: 160px;
                            height:auto;
                        }
                        span{
                            &.checkIcon{
                                display: inline-flex;
                                position: absolute;
                                bottom: -22px;
                                left: 50%;
                                transform: translateX(-50%);
                                svg{
                                    .a{
                                        fill:#00b9ff;
                                        stroke:#fff;
                                    }
                                    .b{
                                        fill:#fafafa;
                                    }
                                }
                            }
                        }
                    }
                    h5{
                        padding:0 10px;
                        @include font(18);
                        font-weight: 500;
                        color: $blackColor;
                        text-align:center;
                        margin:0 0 10px 0;
                    }
                    p{
                        padding:0 10px;
                        @include font(14);
                        font-weight: 300;
                        color: $blackColor;
                        text-align:center;
                        margin:0 0 10px 0;
                    }
                } 
            }
            .info{ 
                @include font(14);
                color:$black;
                line-height: 20px;
                text-align: center;
            }
            .skip {
                @include font(14);
                font-weight: 400;
                color: $black;
                text-align: center;
                a {
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: none;
                    color: $baseColor;
                    cursor: pointer;
                }
            }
        }
        .continueBtnBox{
            text-align: center;
            margin-top: 40px;
            a{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                background-color: $green;
                padding: 10px 15px;
                min-width: 100px;
                min-height: 45px;
                @include font(16);
                font-weight: 300;
                color: $white;
                @include radius(4px);
                border: none;
                outline: none;
                box-shadow: none;
                cursor: pointer;
            }
        }
    }
}
.modal {
    padding-right: 0!important;
    &.skip-popup {
        .modal-dialog {
            max-width: 580px;
        }
        .modal-content {
            border: none;
        }
        .modal-body {
            padding: 50px 45px;
            text-align: center;
            position: relative;
            button {
                &.close {
                    outline: none;
                    position: absolute;
                    top: 15px;
                    right:20px;
                    opacity: 1;
                    svg {
                        fill: $black;
                        path {
                            stroke: none;
                        }
                        .a, .b {
                            fill: $black;
                            stroke: $black;
                        }
                    }
                }
            }
            .modal-title {
                @include font(30);
                font-weight: 500;
                color: $blackColor;
                margin-bottom: 15px;
                + p {
                    margin-bottom: 25px
                }
            }
            p {
                @include font(14);
                font-weight: 400;
                color: $black;
                margin-bottom: 15px;
            }
            .btn {
                padding: 12px 25px;
                @include font(14);
                font-weight: 400;
            }
            .skip {
                margin-top: 15px;
                margin-bottom: 0;
                a {
                    display: inline-block;
                    vertical-align: top;
                    text-decoration: underline;
                    @include font(14);
                    color: $baseColor;
                    cursor: pointer;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:639px) {
    .onBoardAddProfileSection {
        padding-top: 30px;
        padding-bottom: 30px;
        .addProfileBlock {
            .continueBtnBox {
                margin-top: 25px;
            }
        }
    }
}
@media screen and (max-width:567px) {
    .modal {
        &.skip-popup {
            .modal-body {
                padding: 45px 25px 35px;
                .modal-title {
                    @include font(20);
                }
            }
        }
    }
}
@media screen and (max-width:479px) {
    .onBoardAddProfileSection{
        .addProfileBlock{
            .formSection{
                padding: 20px 20px;
            }
            .continueBtnBox{
                a{
                    width: 100%;
                }
            }
        }
    }  
}